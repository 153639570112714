<div class="card card-default margin-bottom-10 p-3">
    <div role="tab" class="card-heading" id="chart{{index}}">
        <div class="card-title layout-table col-100 margin-bottom-5">
            <div class="layout-table-cell col-3  col-md-3">
                <a style="cursor:pointer;padding: 0;" class="action-link action-link-disabled padding0">
                    <i class="ion ion-arrow-up-a"></i>
                </a>
                <a style="cursor:pointer;padding: 0;" class="action-link action-link-disabled padding0">
                    <i class="ion-arrow-down-a"></i>
                </a>
            </div>
            <div class="layout-table-cell col-3  col-md-3">
                <select class="col-px-220 form-control" id="datasource{{index}}" [(ngModel)]="selectChartType" (change)="changeChart()">
                    <option *ngFor='let option of defaultConfig?.chartTemplates; let i = index' [value]="option.id">{{option.name}}</option>
                </select>
            </div>
            <div class="layout-table-cell col-3  col-md-3 padding-left-7">
                <input type="text" placeholder="Chart Title" class="ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="chartConfig.title">
            </div>
            <div class="layout-table-cell col-3  col-md-3 padding-left-7">
                <input type="text" placeholder="Chart Subtitle" class="ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="chartConfig.subtitle">
            </div>

            <div class="col-3  col-md-3 text-right margin-top-5 ng-scope">
                <button class="btn btn-square btn-xs btn-primary" type="button" (click)="addSerie()">
                    <span class=" ion-stats-bars" aria-hidden="true"></span>
                    Add series
                </button>
            </div>

            <div class="layout-table-cell text-right">
                <a aria-controls="collapseChart1" aria-expanded="false" data-parent="#chart-accordion" data-toggle="collapse" class="collapsed color-dark-grey">
                    <span class=" ion-chevron-down ng-hide" *ngIf="chartConfig.minified" (click)="chartConfig.minified = !chartConfig.minified"></span>
                    <span class=" ion-chevron-left" *ngIf="!chartConfig.minified" (click)="chartConfig.minified = !chartConfig.minified"></span>
                </a>
            </div>

            <div class="layout-table-cell col-3  col-md-3 text-right" (click)="removeChart()">
                <a class="color-dark-grey">
                    <span class=" ion-android-close"></span>
                </a>
            </div>
        </div>

        <div class="row margin-top-10 ng-scope" style="margin-left: 2px;font-weight: bold">
            <div class="col-9  col-md-9 line-height-28 margin-bottom-5">
                <span class="ng-scope">Y data series</span>
            </div>
        </div>

        <div class="row ng-scope">
            <div class="col-12  col-md-12">
                <div class="card card-default margin-top-0 padding-top-8 ng-scope">
                    <div class="card-body position-relative padding-top-5 padding-bottom-0" *ngFor="let serie of chartConfig.series; let i =index;">
                        <a id="remove_serie_{{i}}" class="color-grey column-remove" (click)="removeSerie(i)">
                            <span class=" ion-android-close"></span>
                        </a>
                        <div class="row margin-bottom-10">
                            <div class="col-lg-2 col-md-2">
                                <label for="data-column{{index}}">Data column</label>
                                <select class="form-control" id="data-column{{index}}" [(ngModel)]="chartConfig.series[i].columnName" (change)="updateChart()">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of supportedXColumns; let i = index' [value]="option.name">{{option.name}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2">
                                <label for="grouping-functions{{index}}">Grouping Functions</label>
                                <select class="form-control" id="grouping-functions{{index}}" [(ngModel)]="chartConfig.series[i].groupBy.groupingFunction" (change)="updateChart()">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of defaultConfig?.groupingFunctions; let i = index' [value]="option.id">{{option.name}}</option>
                                </select>
                            </div>

                            <!--TODO: FIX THIS-->
                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'weighted-sum'">
                                <label for="weighted-sum{{index}}">Weighted By</label>
                                <select class="form-control" id="weighted-sum{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].groupBy.weightByColumnName">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'delta-weighted-sum'">
                                <label for="weighted-sum{{index}}">Data column 2</label>
                                <select class="form-control" id="delta-weighted-column-2-{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].columnName2">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'delta-weighted-sum'">
                                <label for="weighted-sum{{index}}">Weighted By 2</label>
                                <select class="form-control" id="delta-weighted-by-column-2-{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].groupBy.weightByColumnName2">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>


                            <div class="col-lg-2 col-md-2">
                                <label for="line-width{{index}}">Line Width</label>
                                <input type="number" id="line-width{{index}}" class="form-control" [(ngModel)]="chartConfig.series[i].lineWidth" (change)="updateChart()" min="0" step="0.1"maxlength="4">
                            </div>

                            <div class="col-lg-2 col-md-2">
                                <label for="line-style{{index}}">Line Stylen</label>
                                <select class="form-control" id="line-style{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].lineStyle">
                                    <option *ngFor='let option of ["Solid", "Dashed", "Dotted"]; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2 ng-scope" *ngIf="chartConfig.series.length > 1 && i >0">
                                <div class="form-group">
                                    <label class="control-label">&nbsp;</label>
                                    <div class="checkbox checkbox-gray">
                                        <input id="useRightYAxis{{index}}" type="checkbox" [(ngModel)]="chartConfig.series[i].useRightYAxis" (change)="updateChart()">
                                        <label for="useRightYAxis{{index}}"><span><span></span></span>Right Y axis</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!chartConfig.minified" aria-labelledby="chart" role="tabpanel" class="card-collapse collapse show" id="collapseChart{{index}}">
        <div class="card-body" style="border-top: 0">
            <div class="row">
                <div class="col-5  col-md-5">
                    <div class="form-group  form-group-multiple-inputs">
                        <label class="control-label">&nbsp;</label>
                        <div class="layout-table layout-table-fixed">
                            <div class="checkbox checkbox-inline checkbox-gray layout-table-cell nowrap">
                                <input type="checkbox" [(ngModel)]="chartConfig.showResetButton" (change)="updateChart()" id="show-reset{{index}}"/>
                                <label for="show-reset{{index}}">
                                    <span><span></span></span>
                                    Show reset
                                </label>
                            </div>
                            <div class="checkbox checkbox-inline checkbox-gray layout-table-cell padding-left-20 nowrap">
                                <input type="checkbox" [(ngModel)]="chartConfig.showDataPointsButton" (change)="updateChart()" id="show-data-points{{index}}"/>
                                <label for="show-data-points{{index}}">
                                    <span><span></span></span>
                                    Show data points
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-3  col-md-3">
                    <div class="form-group">
                        <label class="control-label dynamic-font" for="x-axis-series{{index}}" >X axis series</label>
                        <select class="form-control" id="x-axis-series{{index}}" [(ngModel)]="chartConfig.xAxis.columnName" (change)="updateChart()">
                            <option *ngFor='let option of supportedXColumns; let i = index' [value]="option.name">{{option.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2  col-md-2">
                    <div class="form-group">
                        <label class="control-label dynamic-font" for="x-label-degrees{{index}}">X label degrees</label>
                        <select class="form-control" id="x-label-degrees{{index}}" [(ngModel)]="chartConfig.xAxisDegree" (change)="updateChart()">
                            <option *ngFor='let option of angles; let i = index' [value]="option">{{option}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2  col-md-2">
                    <div class="form-group">
                        <label class="control-label dynamic-font" for="x-label-offset{{index}}">X label offset</label>
                        <input type="number" step="0.1" class="form-control" id="x-label-offset{{index}}" [(ngModel)]="chartConfig.xAxisLabelOffset" (change)="updateChart()"/>
                    </div>
                </div>
                <div class="col-12  col-md-12">
                    <div class="col-3  col-md-3">
                        <div class="form-group">
                            <label class="control-label dynamic-font" for="sorting{{index}}" >Sorting</label>
                            <select class="form-control" id="sorting{{index}}" [(ngModel)]="chartConfig.sorting" (change)="updateChart()">
                                <option *ngFor='let option of sorting; let i = index' [value]="option">{{option}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-3  col-md-3" *ngIf="chartConfig.sorting == 'Alphabetically'">
                        <div class="form-group">
                            <label class="control-label dynamic-font" for="sorting-override" >Override Sorting</label>
                            <input type="text" class="form-control" id="sorting-override{{index}}" [(ngModel)]="chartConfig.overrideSorting" (change)="updateChart()"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3  col-md-3">
                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.renderVerticalGridLines" (change)="updateChart()" id="vertical-grid{{index}}" />
                        <label for="vertical-grid">
                            <span><span></span></span>
                            Vertical Grid
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.renderHorizontalGridLines" (change)="updateChart()" id="horizontal-grid{{index}}" />
                        <label for="horizontal-grid" >
                            <span><span></span></span>
                            Horizontal grid
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.autoWidth" (change)="updateChart()" id="auto-width{{index}}"/>
                        <label for="auto-width" >
                            <span><span></span></span>
                            Auto width
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" class="ng-pristine" [(ngModel)]="chartConfig.elastic" (change)="updateChart()" id="elastic{{index}}"/>
                        <label for="elastic{{index}}">
                            <span><span></span></span>
                            Elastic
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" class="ng-pristine" [(ngModel)]="chartConfig.showXTick" (change)="updateChart()" id="show-x-axis-tick{{index}}"/>
                        <label for="show-x-axis-tick" >
                            <span><span></span></span>
                            Show X Axis Tick
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" class="ng-pristine" [(ngModel)]="chartConfig.addInNewLine" (change)="updateChart()" id="add-in-new-line{{index}}" />
                        <label for="add-in-new-line">
                            <span><span></span></span>
                            Add in new line
                        </label>
                    </div>
                </div>

                <div class="col-9  col-md-9 layout-table">
                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label" for="width{{index}}">
                            <span><span></span></span>
                            Width
                        </label>
                        <input type="number" [(ngModel)]="chartConfig.width" (change)="updateChart()" class="form-control" id="width{{index}}"/>
                    </div>
                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label" for="height{{index}}">Height</label>
                        <input type="number" [(ngModel)]="chartConfig.height" (change)="updateChart()" class="form-control" id="height{{index}}" />
                    </div>

                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label"  for="margin-top">Top</label>
                        <input type="number" [(ngModel)]="chartConfig.margin.top" (change)="updateChart()" class="form-control" id="margin-top{{index}}"/>
                    </div>
                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label" for="margin-bottom">
                            <span><span></span></span>
                            Bottom
                        </label>
                        <input type="number" [(ngModel)]="chartConfig.margin.bottom" (change)="updateChart()" class="form-control" id="margin-bottom{{index}}"/>
                    </div>

                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label" for="margin-left" >Left</label>
                        <input type="number" [(ngModel)]="chartConfig.margin.left" (change)="updateChart()" class="form-control" id="margin-left{{index}}"/>
                    </div>
                    <div class="form-group layout-table-cell padding-right-10">
                        <label class="control-label" for="margin-right">Right</label>
                        <input type="number" [(ngModel)]="chartConfig.margin.right" (change)="updateChart()" class="form-control" id="margin-right{{index}}"/>
                    </div>
                </div>

                <div class="col-3  col-md-3">
                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.disableFiltering" (change)="updateChart()" id="disable-filtering{{index}}"/>
                        <label for="disable-filtering">
                            <span><span></span></span>
                            Disable filtering
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.showLegend" (change)="updateChart()" id="show-legend{{index}}"/>
                        <label for="show-legend" >
                            <span><span></span></span>
                            Show legend
                        </label>
                    </div>

                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" [(ngModel)]="chartConfig.renderLabel" id="render-label{{index}}" (change)="updateChart()"/>
                        <label for="render-label" >
                            <span><span></span></span>
                            Render label
                        </label>
                    </div>
                </div>

                <div>
                    <div class="col-lg-12" style="border-top: 1px solid #d7d7d7;margin: 10px 0 10px 0;padding: 10px;"></div>
                    <div class="col-lg-6 col-md-7 col-6">
                        <div class="layout-table">
                            <div class="layout-table-cell col-px-110">Colour palette</div>
                            <div 
                                *ngFor="let color of colors;let i = index"
                                data-ng-model="colorPalette"
                                data-ng-repeat="(key, color) in colorPaletteConstants"
                                class="layout-table-cell col-px-20 ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"
                            >
                                <div
                                    [ngStyle]="{backgroundColor: color.value}"
                                    class="color-marker" style="cursor: pointer"
                                    [ngClass]="{'color-marker-big': i <= 1}"
                                    (click)="setColorPalette(color)"
                                ></div>
                            </div>

                            <div class="layout-table">
                                <div class="layout-table-cell col-px-140">First and last series</div>
                                <div class="layout-table-cell">
                                    <div [ngStyle]="{backgroundColor: firstColor}" class="color-marker color-marker-big" (click)="editFirst = true"></div>
                                </div>
                                <div style="position: absolute; z-index: 10;" *ngIf="editFirst">
                                    <color-sketch [color]="firstColor" (onChangeComplete)="firstColor = $event.color.hex"></color-sketch>
                                </div>
                                <div class="layout-table-cell padding-left-7">
                                    <div [ngStyle]="{backgroundColor: secondColor}" class="color-marker color-marker-big" (click)="editSecond = true"></div>
                                </div>
                                <div style="position: absolute; z-index: 10;" *ngIf="editSecond">
                                    <color-sketch [color]="secondColor" (onChangeComplete)="firstColor = $event.color.hex"></color-sketch>
                                </div>
                            </div>
                            <div class="layout-table-cell col-px-140">
                                <span 
                                    style="font-size: 12px;text-decoration: underline;cursor: pointer"
                                    ng-click="chart.paletteLastColor = chart.paletteFirstColor;refreshChart(chart.id)"
                                >Set colors same</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin-top-5">
                <div class="col-3  col-md-3">
                    <div class="form-group">
                        <label  class="control-label" for="x-axis-tick-format">X axis tick format</label>
                        <input class="form-control" id="x-axis-tick-format{{index}}" [(ngModel)]="chartConfig.xAxis.overrideTickFormat" (change)="updateChart()">
                    </div>
                </div>
                <div class="col-3  col-md-3">
                    <div class="form-group">
                        <label class="control-label" for="y-axis-tick-format" >Y axis tick format</label> 
                        <input class="form-control" id="y-axis-tick-format{{index}}" [(ngModel)]="chartConfig.yAxis.overrideTickFormat" (change)="updateChart()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

