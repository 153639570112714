import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from '../../../../../services/dashboard.service';
import {ApiService} from '../../../../../services/api.service';
import {DataholderService} from '../../../../../services/dashboards/dataholder.service';
import {Subscription} from 'rxjs';
import * as JSZip from 'jszip';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    @Input() content: any;
    @Input() shareId: string = null;
    public configObject: { data: any, charts: [any] };
    private subscriptions: Subscription[] = [];
    public errorOccurred: boolean;
    public reportIsLoading: boolean;

    constructor(private apiService: ApiService,
                private dashboardService: DashboardService,
                private dataholderService: DataholderService) {
    }

    async ngOnInit() {
        this.reportIsLoading = true;
        this.errorOccurred = false;
        this.configObject = JSON.parse(this.content.config);
        this.dataholderService.enabled = true;
        let endpoint;
        if (this.shareId !== null){
            endpoint = this.apiService.getRemotePublicDataSourceZip(this.shareId, this.content.id, this.content.remote_id);
        } else {
            endpoint = this.apiService.getRemoteDataSourceZip(this.content.remote_id);
        }

        this.subscriptions.push(endpoint.subscribe((response: Blob) => {
            try {
                this.dashboardService.destroyAll();

                JSZip.loadAsync(response).then(async (res) => {
                    if (!res.files.data || !res.files.hash) {
                        this.errorOccurred = true;
                        return;
                    }

                    const txt = await res.files.data.async('text');
                    const hash = await res.files.hash.async('text');

                    if (!txt || !hash) {
                        this.errorOccurred = true;
                        return;
                    }

                    const remoteData = JSON.parse(txt);
                    this.dataholderService.init(remoteData.data, this.configObject.data.columns, this.content.config_hash, hash);
                    this.subscriptions.push(this.dashboardService.createDataCount());
                    this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
                    this.reportIsLoading = false;

                }).catch(e => {
                    console.log(e);
                });

            } catch (e) {
                this.errorOccurred = true;
                this.reportIsLoading = false;
                throw (e);
            }
        }, () => {
            this.reportIsLoading = false;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });

        this.dataholderService.clear();
        this.dataholderService.enabled = false;
        this.dashboardService.clear();
    }
}

