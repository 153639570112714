import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorService {
    private error: string = null;
    private errorCode: number = null;

    constructor() {
    }

    public setError(value: string) {
        this.error = value;
    }

    public getError() {
        return this.error;
    }

    getErrorCode(): number | null {
        return this.errorCode;
    }

    setErrorCode(value: number) {
        this.errorCode = value;
    }
}
