import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarLeftComponent} from './sidebar-left.component';
import {RouterModule} from '@angular/router';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TranslationsModule} from '../../modules/translations/translations.module';
import {CommonModalsModule} from '../../modules/common-modals/common-modals.module';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {FormsModule} from '@angular/forms';
import {ScrollbarModule} from '../../modules/scrollbar/scrollbar.module';
import {PublicMenuComponent} from './public-menu/public-menu.component';
import {MenuItemComponent} from './menu-item/menu-item.component';
import {CoursesMenuComponent} from './courses-menu/courses-menu.component';
import {BoardMenuComponent} from './board-menu/board-menu.component';
import { CompanyOnboardingComponent } from './company-onboarding/company-onboarding.component';
import { GlobalPagesComponent } from './global-pages/global-pages.component';

@NgModule({
    declarations: [
        SidebarLeftComponent,
        PublicMenuComponent,
        MenuItemComponent,
        CoursesMenuComponent,
        BoardMenuComponent,
        CompanyOnboardingComponent,
        GlobalPagesComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AccordionModule.forRoot(),
        TranslationsModule,
        CommonModalsModule,
        TooltipModule.forRoot(),
        SortableModule.forRoot(),
        FormsModule,
        ScrollbarModule,
    ],
    exports: [
        SidebarLeftComponent
    ]
})
export class SidebarLeftModule {
}
