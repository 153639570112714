import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig, ProvidedEntity} from '../../../../../models/models';

@Component({
    selector: 'app-provided-entity-select',
    templateUrl: './provided-entity-select.component.html',
    styleUrls: ['./provided-entity-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProvidedEntitySelectComponent),
            multi: true
        }
    ]
})
export class ProvidedEntitySelectComponent implements ControlValueAccessor {
    @Input() options: ProvidedEntity[] = [];
    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;
    @Input() config: FormInputConfig;

    public currentErrors: string[];
    public disabled = false;
    public touch: any;
    protected currentValue: number | null = null;

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: number | null) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
}
