import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {News} from '../../../../models/models';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-news-table-tr',
    templateUrl: './news-table-tr.component.html',
    styleUrls: ['./news-table-tr.component.scss']
})
export class NewsTableTrComponent implements OnInit {

    @Input() entity: News;
    @Output() openModal = new EventEmitter();
    @Output() updateContent = new EventEmitter();

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
    }

    toggleActiveContent() {
        this.apiService.toggleActiveNews(this.entity.id).subscribe(response => {
            this.updateContent.emit();
        });
    }

    deleteContent() {
        this.apiService.deleteNews(this.entity.id).subscribe(response => {
            this.updateContent.emit(true);
        });
    }

    editContent() {
        return this.openModal.emit();
    }

}
