<app-scheduling-modal
    *ngIf="addEditModal === true"
    (addComponent)="updateEntity()"
    [scheduling]="editEntity"
></app-scheduling-modal>
<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.accordion.scheduling'|translate"
        [buttonAdd]="'messages.admin.scheduling_page.add_schedule'|translate"
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th *ngFor="let field of fieldsName; let index = field">
                    <a>{{field.name|translate}}</a>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id"><span><span></span></span></label>
                    </div>
                </td>
                <td>
                    {{entity.type_as_text}}
                    <strong></strong>
                </td>
                <td>{{entity.time_of_delivery_as_text}} {{entity.day_of_delivery_as_text}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="1">
                    <app-scheduling-table-tr-info
                        [scheduling]="entity"
                        (openModal)="addEntity(entity)"
                        (refresh)="updateEntity()"
                    ></app-scheduling-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>

    <div class="actions text-center">
        <div class="text-danger">
            <i class="icon icon-size-9 ion-alert-circled color-light-green"></i>
            {{'messages.admin.immediate_actions'|translate}}
        </div>
        <br>

        <ul class="list-inline text-center">
            <li class="list-inline-item">
                <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    id="scheduling_flush_redis_cache"
                    (click)="flushRedisCache($event)"
                >
                    {{('messages.admin.scheduling_page.flush_redis'|translate).toUpperCase()}}
                </button>
            </li>
            <li class="list-inline-item">
                <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    id="scheduling_index_pages"
                    (click)="indexPages($event)"
                >
                    {{('messages.admin.scheduling_page.index_pages'|translate).toUpperCase()}} (long process)
                </button>
            </li>
        </ul>
    </div>
</div>
