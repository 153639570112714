<div class="page-title"><h1><i
    class="icon ion-ios-list color-light-green"
></i> Emails domains config</h1>
</div>

<form
    [formGroup]="form"
    class="form-style-bordered-inputs"
    name="myForm"
    (ngSubmit)="submit()"
>
    <div class="list-values">
        <div *ngFor="let controlItem of form.controls | keyvalue" class="list-values-container">
            <div class="heading">
                <span>{{('messages.emails_lists.' + controlItem.key) | translate}}</span>
            </div>
            <div class="body">
                <div
                    *ngFor="let list of controlItem.value.controls; index as i"
                >
                    <div class="form-group list-value">
                        <input
                            class="form-control"
                            type="text"
                            [formControl]="list"
                        >
                        <i
                            class="icon icon-size-9 ion-close-circled color-light-green"
                            (click)="removeValue(controlItem.key, i)"
                        ></i>
                    </div>

                    <div class="help-block" *ngIf="list.errors">
                        <ul class="list-unstyled">
                            <li *ngIf="list.errors?.pattern">
                                <span class="ion-android-alert"></span>&nbsp;
                                <span class="pre-line">Invalid domain value</span>
                            </li>
                            <li *ngIf="list.errors?.required">
                                <span class="ion-android-alert"></span>&nbsp;
                                <span class="pre-line">This value is required</span>
                            </li>
                        </ul>
                    </div>

                </div>
                <a class="btn btn-sm btn-primary" (click)="addValue(controlItem.key)">
                    <i class="icon icon-size-9 ion-plus-circled"></i>
                    {{'messages.global.add_new'|translate}}
                </a>
            </div>
        </div>
    </div>
    <app-form-submited-loading *ngIf="submitted"></app-form-submited-loading>
    <p class="alert alert-danger" *ngIf="error">{{error}}</p>
    <button class="btn btn-sm btn-primary" type="submit">
        {{'messages.global.submit'|translate}}
    </button>
</form>
