import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {environment} from '../../../../environments/environment';
import {version} from '../../../../../package.json';
import {version as aVersion} from '@angular/core/package.json';

@Component({
    selector: 'app-backend-env',
    templateUrl: './backend-env.component.html',
    styleUrls: ['./backend-env.component.scss']
})
export class BackendEnvComponent implements OnInit {

    addinEnv = {};
    backendEnv = {};

    version = `${version} (v${aVersion})`;

    devAddinVersion = '';
    devBackendVersion = '';
    stagAddinVersion = '';
    stagBackendVersion = '';
    prodAddinVersion = '';
    prodBackendVersion = '';

    constructor(private api: ApiService) {
    }

    ngOnInit(): void {
        this.api.getBackendEnv().subscribe(data => {
            this.backendEnv = data;
        });

        this.api.getAddinEnv().then(data => {
            this.addinEnv = data;
        });

        if (!environment.production) {
            return;
        }

        this.api.getDevAddinVersion().then(data => {
            this.devAddinVersion = data.version;
        }).catch(error => console.log(error));

        this.api.getDevBackendVersion().then(data => {
            this.devBackendVersion = data.version;
        }).catch(error => console.log(error));

        this.api.getStagingAddinVersion().then(data => {
            this.stagAddinVersion = data.version;
        }).catch(error => console.log(error));

        this.api.getStagingBackendVersion().then(data => {
            this.stagBackendVersion = data.version;
        }).catch(error => console.log(error));

        this.api.getProdAddinVersion().then(data => {
            this.prodAddinVersion = data.version;
        }).catch(error => console.log(error));

        this.api.getProdBackendVersion().then(data => {
            this.prodBackendVersion = data.version;
        }).catch(error => console.log(error));
    }

}
