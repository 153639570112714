import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../../../../models/models';
import * as moment from 'moment';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
    public disabled = false;
    public label: string;
    public id: string;
    public touch: any;
    public value: string;
    public format: any;
    public useUtc: boolean;
    public withTime = false;

    public date = null;
    public time = null;

    public isOpen = false;

    @Input() submitted: boolean;
    @Input() form: FormControl;
    @Input() isFieldValid: boolean;
    private previousDate: any;
    private previousTime: any;

    @Input() set config(value: FormInputConfig) {
        const {label, id, format, useUtc, withTime} = value;

        this.label = label || null;
        this.id = id || null;
        this.format = format || 'MMMM Do YYYY';
        this.useUtc = !!useUtc || false;
        this.withTime = withTime || false;
    }

    onChange: any = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (!obj) {
            this.value = null;
            return;
        }

        const mDate = this.useUtc ? moment.utc(obj) : moment(obj);
        const date = mDate.toDate();

        this.value = mDate.format(this.format);

        this.date = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };

        this.time = {
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: 0
        };
    }

    onSelected(e) {
        console.log(e);
    }

    ngOnInit(): void {
        const date = new Date();

        this.date = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };

        this.time = {hour: 9, minute: 0, second: 0};
    }

    ngOnDestroy(): void {
        document.body.removeEventListener('click', this.closePupover);
    }

    openForm() {
        this.previousDate = this.date ? {...this.date} : null;
        this.previousTime = this.time ? {...this.time} : null;

        this.isOpen = true;

        document.body.addEventListener('click', this.closePupover);
    }

    submitDatetime() {
        this.touch();
        this.isOpen = false;

        if (!this.date) {
            return;
        }

        this.previousDate = this.date ? {...this.date} : null;
        this.previousTime = this.time ? {...this.time} : null;

        const {year, month, day} = this.date;
        const {hour, minute} = this.time;

        const newDate = new Date();
        newDate.setFullYear(year, month - 1, day);
        newDate.setHours(hour, minute, 0);

        this.value = moment(newDate).format(this.format);
        this.onChange(this.value);
    }


    closePupover = () => {
        this.touch();
        this.isOpen = false;

        this.date = this.previousDate;
        this.time = this.previousTime;
    }
}

