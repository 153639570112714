import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {GitBoard} from '../../../models/models';
import {Router} from '@angular/router';

@Component({
    selector: 'app-git-board',
    templateUrl: './git-board.component.html',
    styleUrls: ['./git-board.component.scss']
})
export class GitBoardComponent implements OnInit {

    public expand = true;
    public boards: GitBoard[] = [];
    public busy = false;

    private closedIssuesPage = 1;
    private hasNext = true;

    constructor(private api: ApiService, public router: Router) {
    }

    ngOnInit(): void {
        this.initBoards();

    }

    initBoards() {
        this.closedIssuesPage = 1;
        this.hasNext = true;

        this.api.getGitBoards().subscribe(data => {
            this.boards = data;
            this.loadClosedIssues();
        });
    }

    loadNext() {
        if (!this.hasNext) {
            return;
        }

        if (this.busy) {
            return;
        }

        this.closedIssuesPage++;

        this.loadClosedIssues();
    }

    loadClosedIssues() {
        this.busy = true;

        this.api.getGitClosedIssues(this.closedIssuesPage).subscribe(data => {
            this.boards[this.boards.length - 1].issues.push(...data.issues);
            this.hasNext = data.has_next;
            this.busy = false;
        }, () => {
            this.busy = false;
        });
    }

    reloadClosed() {
        this.boards[this.boards.length - 1].issues = [];
        this.closedIssuesPage = 1;
        this.hasNext = true;

        this.loadClosedIssues();
    }
}
