<div class="fade modal-backdrop show" style="z-index: 1100;"></div>
<div class="modal fade show modal-confirm" style="z-index: 1110; display: block; overflow-y: auto">
    <div
        [ngClass]="{
            'modal-dialog': !expanded && !wideVariant,
            'modal-dialog-wide': wideVariant || expanded,
            'expanded': expanded
        }"
    >
        <div class="modal-content" [style.minHeight]="wideVariant || expanded ? minHeight + 'px' : null">
            <div class="modal-header">
                <h4 class="modal-title">
                    <i class="icon ion-person-stalker color-light-green"></i>
                    <span>{{mTitle}}</span>
                </h4>
                <div>
                    <span
                        aria-hidden="true"
                        *ngIf="!wideVariant"
                        (click)="expanded = !expanded"
                        class="mr-3"
                        style="cursor: pointer;"
                    >
                        <i
                            class="icon icon-size-11 color-light-green"
                            [ngClass]="{'ion-android-contract': expanded, 'ion-android-expand': !expanded}"
                        ></i>
                    </span>
                    <span aria-hidden="true" (click)="mClose.emit()" style="cursor: pointer;">
                        <i class="icon icon-size-11 ion-close-circled color-light-green"></i>
                    </span>
                </div>
            </div>

            <div class="modal-body">
                <ng-content></ng-content>
                <div class="busy" *ngIf="loading"></div>
            </div>

            <div class="modal-footer" *ngIf="!footer">
                <app-form-submited-loading *ngIf="loading"></app-form-submited-loading>
                <button
                    (click)="mSubmit.emit()"
                    class="btn btn-sm btn-primary"
                    type="button"
                    *ngIf="withSubmit"
                    [disabled]="loading"
                >
                    {{'messages.global.ok'|translate}}
                </button>
                <button (click)="mClose.emit()" class="btn btn-sm btn-default" type="button" [disabled]="loading">
                    {{(withSubmit ? 'messages.global.cancel' : 'messages.global.close')|translate}}
                </button>
            </div>

            <ng-content select=".modal-footer" *ngIf="footer">
            </ng-content>
        </div>
    </div>
</div>

