<accordion-group
    *ngIf="section"
    [isOpen]="section?.pages_ids?.includes(reportsService.getSelectedReport()?.id)"
>
    <a
        *ngIf="section.folders?.length > 0 || section.pages?.length > 0"
        accordion-heading
        [ngClass]="'lvl-'+ (level < 4 ? level : 4)"
        class="wave-ui-headline"
    >
        <span class="folder-title" [ngClass]="{'folder-title': level > 0}">
        </span>
        <span class="word-break">{{section.display_name || section.name}}</span>
        <span class="heading-actions">
            <span
                *ngIf="!section.is_company && auth.isGranted('ROLE_CONTENT_MANAGER')"
                class="superadmin-edit"
                (click)="editFolder($event, section.id)"
            >
                <i class="ion-ios-gear"></i>
            </span>
            <span class="acc-arrow">
                <i class="ion-ios-arrow-down"></i>
            </span>
        </span>
    </a>
    <div
        *ngIf="section.folders?.length > 0 || section.pages?.length > 0"
        class="panel-body"
        style="margin-top: 1px; width: 100%"
    >
        <accordion [isAnimated]="true" [closeOthers]="true">
            <ng-container *ngIf="section.folders?.length">
                <app-menu-item *ngFor="let folder of section.folders" [section]="folder" [level]="level + 1">
                </app-menu-item>
            </ng-container>
            <div
                *ngFor="let page of section.pages"
                [class]="'list-item lvl-' + (level < 4 ? level : 4)"
                [ngClass]="{'selected': reportsService.getSelectedReport()?.id === page.id}"
                (click)="reportsService.addReportToLocalReadList(page)"
                class="wave-ui-headline"

            >
                <span
                    class="list-item-dot"
                    [ngClass]="{'sr-only': page.checked_by_visitor || reportsService.isReportRead(page) || reportsService.getSelectedReport()?.id === page.id}"
                ></span>
                <a
                    class="list-item-link"
                    [href]="page.url || '/app/reports/show/'+page.folder.slug+'/'+page.slug+'.report'"
                    (click)="handlePageClick($event, page)"
                >

                    <span class="word-break">{{page.display_name || page.name}}</span>
                    <span class="heading-actions">
                <span
                    *ngIf="auth.isGranted('ROLE_CONTENT_MANAGER')"
                    class="superadmin-edit"
                    (click)="editPage($event, page.id)"
                >
                    <i class="ion-ios-gear"></i>
                </span>
                </span>
                </a>
            </div>
        </accordion>
    </div>
</accordion-group>
