import {Pipe, PipeTransform} from '@angular/core';
import {FormInputConfig, FormLists} from '../../../models/models';

@Pipe({
    name: 'controlListsValue'
})
export class ControlListsValuePipe implements PipeTransform {

    transform(value: FormLists, field: FormInputConfig): unknown {
        if (!value) {
            return null;
        }

        const listKey = field.listValuesKey || field.name;
        return value[listKey] || [];
    }

}
