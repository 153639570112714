<div class="form-check">
    <input
        (blur)="touch()"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="id"
        [style.color]="!isFieldValid? '#a94442' : null"
        class="form-check-input"
        type="checkbox"
    >
    <label
        [for]="id"
        class="form-check-label"
    >&nbsp;{{label|translate}}
    </label>
</div>
