import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/global/login/login.component';
import {RegisterComponent} from './pages/global/register/register.component';
import {PasswordResetComponent} from './pages/global/password-reset/password-reset.component';
import {PasswordResetFormComponent} from './pages/global/password-reset-form/password-reset-form.component';
import {RoleUserGuard} from './guards/role-user.guard';
import {ConfirmEmailComponent} from './pages/global/confirm-email/confirm-email.component';
import {PrivacyPolicyComponent} from './pages/global/privacy-policy/privacy-policy.component';
import {PublicPagesComponent} from "./pages/global/public-pages/public-pages.component";

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent
    },
    {
        path: 'password-reset/:token',
        component: PasswordResetFormComponent
    },
    {
        path: 'confirm-email/:token',
        component: ConfirmEmailComponent
    },
    {
        path: 'required-password-reset',
        component: PasswordResetFormComponent,
        canActivate: [RoleUserGuard],
    },
    {
        path: '',
        redirectTo: '/app/reports',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
