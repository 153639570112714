<div
    [ngClass]="{expanded: board.expanded, collapsed: !board.expanded}"
    [style.borderTopColor]="board.color"
    class="board card mr-4"
>
    <div [ngStyle]="{borderBottom: !board.expanded ? 'none': null}" class="card-header d-flex align-items-center">
        <div
            (click)="board.expanded = !board.expanded"
            [ngClass]="{'mr-3': board.expanded}"
            class="expand-toggle"
            style="cursor: pointer"
        >
            <i class="icon ion-chevron-right"></i>
        </div>
        <div class="issue-title">
            <span
                *ngIf="board.id"
                [style]="{backgroundColor: board.color, color: board.text_color}"
                class="label"
            >{{board.name}}</span>
            <span *ngIf="!board.id">{{board.name}}</span>
        </div>
        <div class="issues-counter">
            <div class="d-inline-block">
                <app-gl-icon
                    [left]="-2"
                    [rotate]="board.expanded ? 0 : 90"
                    [size]="14"
                    [top]="-1"
                    icon="issues"
                ></app-gl-icon>
                {{board.issues_count}}
            </div>
            <div *ngIf="withAdd && board.expanded" class="d-inline-block ml-2">
                <button (click)="addIssue.emit(true)" class="gl-btn">
                    <i class="icon ion-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div
        (scroll)="checkLoadNext($event)"
        *ngIf="board.expanded"
        class="card-body"
        style="overflow-y: auto"
        [sortablejs]="board.issues"
        [sortablejsOptions]="sortableOptions"
        [attr.data-board-name]="board.name"
    >
        <div
            *ngFor="let issue of board.issues, let last = last"
            class="issue card mb-2"
            [attr.data-labels]="issue.rawLabels?.join(separator)"
        >
            <div class="card-body p-3 pb-0">
                <h5><a [routerLink]="'/app/board/issues/' + issue.iid">{{issue.title}}</a></h5>
                <div *ngIf="issue.labels?.length" class="issue-labels mt-2 d-flex flex-wrap">
                    <ng-container *ngFor="let label of issue.labels">
                        <span
                            [style]="{backgroundColor: label.color, color: label.text_color}"
                            class="label"
                            *ngIf="label.name !== board.name && !busy"
                        >{{label.name}}</span>
                    </ng-container>
                </div>
                <div class="d-flex align-items-start mt-3">
                    <span class="d-inline-block mr-3">#{{issue.iid}}</span>
                    <span *ngIf="issue.time_stats.human_time_estimate" class="d-inline-block mr-3">
                            <app-gl-icon [size]="15" [top]="-1" class="mr-1" icon="hourglass"></app-gl-icon>
                            <span>{{issue.time_stats.human_time_estimate}}</span>
                        </span>
                    <span *ngIf="issue.due_date" class="d-inline-block mr-3">
                            <app-gl-icon [size]="15" [top]="-2" class="mr-1" icon="calendar"></app-gl-icon>
                            <time class="board-card-info-text" datetime="date">{{issue.due_date}}</time>
                        </span>
                    <span *ngIf="issue.assignee?.id" class="user-avatar" style="margin-left: auto;">
                        <img
                            [alt]="issue.assignee.name"
                            [title]="issue.assignee.name"
                            [src]="issue.assignee.avatar_url"
                            height="20"
                            width="20"
                        >
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="busy" class="text-center mt-3 mb-3">
            <strong>{{'messages.global.loading'|translate}}</strong>
        </div>
    </div>
</div>
