import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ContentModel, FormConfig, FormLists, News} from '../../../../models/models';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {ApiService} from '../../../../services/api.service';
import {passOnlyMimetypesValidator} from '../../../../validators/mimeTypesValidators';
import * as _ from 'lodash';

@Component({
    selector: 'app-news-form',
    templateUrl: './news-form.component.html',
    styleUrls: ['./news-form.component.scss'],
    providers: [FormLoadingServiceService]

})
export class NewsFormComponent implements OnInit {


    @Output() update = new EventEmitter<boolean | News>();
    @Input() content: News | null;
    public value = null;

    public existingFiles = [];
    public filesForm: FormArray;
    lists: FormLists = {};

    @ViewChild(FormComponent, {static: true}) form: FormComponent;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'name',
                label: 'messages.admin.pages_page.form.page.fields.name.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.name.label',
                id: 'name',
                type: 'text',
                validators: [Validators.required, Validators.maxLength(255)]
            },
            {
                name: 'title',
                label: 'messages.admin.pages_page.form.page.fields.title.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.title.label',
                id: 'title',
                type: 'text',
                validators: [Validators.required, Validators.maxLength(255)]
            },
            {
                name: 'shortDescription',
                label: 'Short description',
                id: 'shortDescription',
                type: 'textarea',
                propertyPath: 'short_description',
                validators: [Validators.maxLength(255)]
            },
            {
                name: 'author',
                label: 'messages.global.author',
                id: 'page_author',
                type: 'typeahead',
                listValuesKey: 'users'
            },
            {
                name: 'content',
                label: 'messages.admin.contents_page.form.content.fields.html.label',
                id: 'content',
                type: 'html-editor',
            }
        ],
    };

    fileControl: FormControl;

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
                private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.fileControl = this.formBuilder.control(null, [passOnlyMimetypesValidator(['image/png', 'image/jpeg'])]);

        this.formLoadingServiceService.init(1);

        this.entityProvider.getUsers().subscribe(data => {
            this.lists.users = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        if (this.content?.source) {
            setTimeout(() => {
                this.form.get('content').hidden = true;
                this.form.get('name').hidden = true;
                this.form.get('title').hidden = true;
                this.form.get('author').hidden = true;
            });
        }

        this.value = this.content ? _.cloneDeep(this.content) : {};
    }

    updateContent(data: ContentModel) {
        const request = this.content
            ? this.apiService.updateNews(this.content.id, data)
            : this.apiService.createNews(data);

        request.subscribe(async (response: any) => {
            if (this.fileControl.value) {
                const formData = new FormData();
                formData.append('file', this.fileControl.value, this.fileControl.value.name);
                await this.apiService.uploadNewsPreview(response.id, formData).toPromise().catch(() => {
                });
            }

            this.update.emit(response);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }
}
