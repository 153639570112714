import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {Injectable} from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.getApiKey()) {
            request = request.clone({
                setHeaders: {
                    'X-AUTH-TOKEN': this.authService.getApiKey()
                }
            });
        }

        if (localStorage.getItem('blackfire_token') && !request.url.includes('/ping')) {
            request = request.clone({
                setHeaders: {
                    BLACKFIRETRIGGER: localStorage.getItem('blackfire_token')
                }
            });
        }

        return next.handle(request);
    }
}
