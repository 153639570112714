import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
    AddinVersionModel,
    AdminAddinVersionsList,
    AdminAdminsList,
    AdminCompaniesList,
    AdminContentsList,
    AdminDepartmentsList,
    AdminFoldersList,
    AdminGroupRequestsList,
    AdminGroupsList,
    AdminLogsList,
    AdminMessagesList,
    AdminPagesList,
    AdminSchedulingList,
    AdminSubscriptionLogList,
    AdminSubscriptionsList,
    AdminUserRequestsList,
    AdminUsersList,
    AdminWorkbooksList,
    AdminWorkbookTemplatesList,
    CompanyManagersModel,
    CompanyModel,
    ContentModel,
    Course,
    CourseDashboardItem,
    CurseSidebarCategory,
    DashboardContentModel,
    DepartmentModel,
    FolderModel,
    FrontendPage,
    GitBoard,
    GitBoardIssue,
    GitIssue,
    GitLabel,
    GitUser,
    HolidayRequest,
    HolidaysRequestModel,
    LoginDataModel,
    LoginModel,
    MessageModel,
    MessagesListModel,
    News,
    PageComment,
    PageModel,
    Pager,
    ProfileData,
    ProfileListValues,
    ProfileListValuesModel,
    ProfilePasswordModel,
    ProfileSettingsModel,
    ProfileSubscriptionModel,
    ProvidedEntity,
    ReportsModel,
    RequestToJoinModel,
    ResetPasswordModel,
    SchedulingModel,
    SearchResult,
    SentEmail,
    SidebarLeftMenuModel,
    UserModel,
    UserProfileDocument,
    UserProfileDocumentModel,
    UserProfileListValue,
    UserProfileModel,
    UserReportsModel,
    UsersModel,
    WorkbookModel
} from '../models/models';
import {environment} from '../../environments/environment';
import {environment as devEnv} from '../../environments/environment.dev';
import {environment as stagEnv} from '../../environments/environment.staging';
import {environment as prodEnv} from '../../environments/environment.prod';
import {downloadContent} from '../helpers/DownloadContent';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    apiURL: string = environment.apiURL + '/api/v1';

    constructor(private httpClient: HttpClient) {
    }

    downloadFileClientSide(response, filename: string, dataType = 'text/csv') {
        const binaryData = [];
        binaryData.push(response);
        downloadContent(window.URL.createObjectURL(new Blob(binaryData, {type: dataType})), filename);
    }

    login(data: LoginDataModel): Observable<LoginModel> {
        return this.httpClient.post<LoginModel>(`${environment.apiURL}/login`, data);
    }

    getLoginInfo() {
        return this.httpClient.get<{ need_captcha: boolean }>(`${environment.apiURL}/login`);
    }

    requestToJoin(data: RequestToJoinModel) {
        return this.httpClient.post(`${environment.apiURL}/request-to-join`, data);
    }

    getRequestToJoinInfo() {
        return this.httpClient.get<{ need_captcha: boolean }>(`${environment.apiURL}/request-to-join`);
    }

    resetPassword(data: ResetPasswordModel, token: string = null) {
        let suffix = '';

        if (token) {
            suffix = '/' + token;
        }

        return this.httpClient.post(`${environment.apiURL}/reset-password${suffix}`, data);
    }

    getProfileDetails() {
        return this.httpClient.get<UserModel>(`${this.apiURL}/profile/details`);
    }

    getProfileSettings() {
        return this.httpClient.get<ProfileSettingsModel>(`${this.apiURL}/profile/settings`);
    }

    updateProfileSettings(data: ProfileSettingsModel) {
        return this.httpClient.put<ProfileSettingsModel>(`${this.apiURL}/profile/settings`, data);
    }

    updateProfileDetails(data: UserModel) {
        return this.httpClient.put<UserModel>(`${this.apiURL}/profile/details`, data);
    }

    updateProfilePassword(data: ProfilePasswordModel) {
        return this.httpClient.put<UserModel>(`${this.apiURL}/profile/password`, data);
    }

    getLastSession() {
        return this.httpClient.get<{ last_seen_report: string; }>(`${this.apiURL}/profile/last-session`);
    }

    setLastSession(url: string) {
        return this.httpClient.put(`${this.apiURL}/profile/last-session`, {last_seen_page: url}, {
            headers: {
                ignoreLoadingBar: ''
            }
        });
    }

    /**
     * TODO: Improve api endpoint
     */
    getUserDetails(): Observable<UserModel> {
        return this.httpClient.get<UserModel>(`${this.apiURL}/profile/me`);
    }

    /**
     * TODO: Improve api endpoint
     */
    getReports(): Observable<ReportsModel | UserReportsModel | SidebarLeftMenuModel> {
        return this.httpClient.get <ReportsModel | UserReportsModel | SidebarLeftMenuModel>(`${this.apiURL}/profile/reports`);
    }

    getProfileReports(): Observable<UserReportsModel> {
        return this.httpClient.get<UserReportsModel>(`${this.apiURL}/profile/reports`);
    }

    getGlobalPages() {
        return this.httpClient.get<UserReportsModel>(`${this.apiURL}/profile/reports-global`);
    }

    getProfileGroups() {
        return this.httpClient.get<{ name: string }>(`${this.apiURL}/profile/groups`);
    }

    getAvailableGroups() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/profile/groups/available/for/access`);
    }

    requestAccessToGroups(data: { requestedGroups: number[] }) {
        return this.httpClient.post(`${this.apiURL}/profile/groups/request_access`, data);
    }

    getMessages() {
        return this.httpClient.get<MessagesListModel[]>(`${this.apiURL}/profile/messages`);
    }

    markMessageAsRead(id: any) {
        return this.httpClient.put<MessagesListModel[]>(`${this.apiURL}/profile/message/mark_as_read/${id}`, {});
    }

    deleteProfileMessage(id: any) {
        return this.httpClient.put<MessagesListModel[]>(`${this.apiURL}/profile/message/delete/${id}`, {});
    }

    undeleteProfileMessage(id: any) {
        return this.httpClient.put<MessagesListModel[]>(`${this.apiURL}/profile/message/undelete/${id}`, {});
    }

    getSubscriptions(): Observable<AdminSubscriptionsList[]> {
        return this.httpClient.get<AdminSubscriptionsList[]>(`${this.apiURL}/profile/subscriptions`);
    }

    crateProfileSubscription(data: any): Observable<AdminSubscriptionsList> {
        return this.httpClient.post<AdminSubscriptionsList>(`${this.apiURL}/profile/subscription`, data);
    }

    updateProfileSubscription(id: number, data: any): Observable<AdminSubscriptionsList> {
        return this.httpClient.put<AdminSubscriptionsList>(`${this.apiURL}/profile/subscription/modal/${id}`, data);
    }

    toggleSubscriptionActive(id: number): Observable<ProfileSubscriptionModel> {
        return this.httpClient.put<ProfileSubscriptionModel>(`${this.apiURL}/profile/subscription/toggle_paused/${id}`, {});
    }

    deleteUsersSubscription(id: number): Observable<ProfileSubscriptionModel> {
        return this.httpClient.delete<ProfileSubscriptionModel>(`${this.apiURL}/profile/subscription/${id}`, {});
    }

    getNotifications() {
        return this.httpClient.get<{ pending_requests_count: number }>(`${this.apiURL}/profile/notifications?message=1`);
    }

    getUserMessages() {
        return this.httpClient.get<{ new_messages: number }>(`${this.apiURL}/profile/new-messages`);
    }

    getImpersonateList() {
        return this.httpClient.get<UsersModel[]>(`${this.apiURL}/impersonate-list`);
    }

    getUsers(pager: Pager): Observable<AdminUsersList[]> {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminUsersList[]>(`${this.apiURL}/users`, {params});
    }

    toggleStatus(id: number): Observable<object> {
        return this.httpClient.put(`${this.apiURL}/users/toggle-enabled/${id}`, {});
    }

    toggleShowInTeam(id: number): Observable<object> {
        return this.httpClient.put(`${this.apiURL}/users/toggle-show-in-team/${id}`, {});
    }

    createUser(data: any) {
        return this.httpClient.post<AdminUsersList>(`${this.apiURL}/users/create`, data);
    }

    updateUser(id: any, data: any) {
        return this.httpClient.put<AdminUsersList>(`${this.apiURL}/users/${id}`, data);
    }

    updateUserPassword(id: any, data: any) {
        return this.httpClient.put<AdminUsersList>(`${this.apiURL}/users/password-change/${id}`, data);
    }

    updateStartupSettings(id: any, data: any) {
        return this.httpClient.put<AdminUsersList>(`${this.apiURL}/users/settings/${id}`, data);
    }

    deleteUser(id: any) {
        return this.httpClient.delete(`${this.apiURL}/users/delete/${id}`);
    }

    unDeleteUser(id: any) {
        return this.httpClient.put(`${this.apiURL}/users/undelete/${id}`, {});
    }

    exportUsers() {
        return this.httpClient.get(`${this.apiURL}/users/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    exportSelectedUsers(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/users/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteSelectedUsers(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/users/delete-multiple?${queryParams}`);
    }

    getReportOne(route: string): Observable<FrontendPage> {
        return this.httpClient.get<FrontendPage>(`${this.apiURL}/profile/report/one/${route}`);
    }

    getPublicReport(shareId: string): Observable<FrontendPage> {
        return this.httpClient.get<FrontendPage>(`${this.apiURL}/public/report/${shareId}`);
    }

    getGlobalReportOne(route: string): Observable<FrontendPage> {
        return this.httpClient.get<FrontendPage>(`${this.apiURL}/profile/reports-global/one/${route}`);
    }

    getNetworkShareContents(path: string) {
        let suffix = '';

        if (path !== '.') {
            suffix = '/' + path;
        }

        return this.httpClient.get(`${this.apiURL}/network-share-contents` + suffix);
    }

    downloadNetworkShareFile(path: string) {
        return this.httpClient.get(`${this.apiURL}/network-share-contents/download/${path}`, {
            responseType: 'blob' as 'json'
        });
    }

    forgotPassword(email: string) {
        return this.httpClient.post(`${environment.apiURL}/forgot-password`, {username: email});
    }

    impersonateUser(id: number): Observable<UserModel> {
        const randomHash = Math.random().toString(36).substring(7);
        return this.httpClient.get<UserModel>(`${environment.apiURL}/impersonate/${id}/ngx-${randomHash}`);
    }

    createGroup(data: { folders: any; name: any; company: any; users: any }) {
        return this.httpClient.post<AdminGroupsList>(`${this.apiURL}/groups`, data);
    }

    updateGroup(id: number, data: { folders: any; name: any; company: any; users: any }) {
        return this.httpClient.put<AdminGroupsList>(`${this.apiURL}/groups/${id}`, data);
    }

    toggleDefaultGroup(id: any) {
        return this.httpClient.put<AdminGroupsList>(`${this.apiURL}/groups/toggle-default/${id}`, {});
    }

    getGroups(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminGroupsList[]>(`${this.apiURL}/groups`, {params});
    }

    deleteSelectedGroups(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/groups/delete-multiple?${queryParams}`);
    }

    exportSelectedGroups(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/groups/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportGroups() {
        return this.httpClient.get(`${this.apiURL}/groups/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteGroup(id: any) {
        return this.httpClient.delete(`${this.apiURL}/groups/${id}`);
    }

    // COMPANIES

    getCompanies(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminCompaniesList[]>(`${this.apiURL}/companies`, {params});
    }

    getCompaniesHavingDashboardDatasource() {
        return this.httpClient.get<AdminCompaniesList[]>(`${this.apiURL}/dashboards/companies-with-datasources`);
    }

    getDashboardDefaultConfig() {
        return this.httpClient.get(`${this.apiURL}/dashboards/config`);
    }

    createCompany(data: CompanyModel, createRoot = false) {
        let q = '';

        if (createRoot) {
            q = '?createRoot=1';
        }

        return this.httpClient.post(`${this.apiURL}/companies${q}`, data);
    }

    updateCompany(id: number, data: CompanyModel) {
        return this.httpClient.put(`${this.apiURL}/companies/${id}`, data);
    }

    updateCompanyManagers(id: number, data: CompanyManagersModel) {
        return this.httpClient.put(`${this.apiURL}/companies/${id}/managers`, data);
    }

    updateCompanyHRs(id: number, data) {
        return this.httpClient.put(`${this.apiURL}/companies/update-hrs/${id}`, data);
    }

    toggleActiveCompany(id: number) {
        return this.httpClient.put(`${this.apiURL}/companies/toggle-active/${id}`, {});
    }

    toggleShowLoginCount(id: number) {
        return this.httpClient.put(`${this.apiURL}/companies/toggle-show-login-stats/${id}`, {});
    }

    toggleWorkbookMaintenance(id: number) {
        return this.httpClient.put(`${this.apiURL}/companies/toggle-workbooks-maintenance/${id}`, {});
    }

    toggleBoardEnabled(id: number) {
        return this.httpClient.put(`${this.apiURL}/companies/toggle-board-enabled/${id}`, {});
    }

    exportCompanies() {
        return this.httpClient.get(`${this.apiURL}/companies/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    exportSelectedCompanies(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/companies/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteSelectedCompanies(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/companies/delete-multiple?${queryParams}`);
    }

    deleteCompany(id: any) {
        return this.httpClient.delete(`${this.apiURL}/companies/${id}`);
    }

    // FOLDERS
    getFolders(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminFoldersList[]>(`${this.apiURL}/folders`, {params});
    }

    getFolder(id: any) {
        return this.httpClient.get<AdminFoldersList>(`${this.apiURL}/folders/${id}`);
    }

    createFolder(data: FolderModel) {
        return this.httpClient.post<AdminFoldersList>(`${this.apiURL}/folders`, data);
    }

    copyFolder(id: number, data: FolderModel) {
        return this.httpClient.post<AdminFoldersList>(`${this.apiURL}/folders/copy/${id}`, data);
    }

    updateFolder(id: number, data: FolderModel) {
        return this.httpClient.put<AdminFoldersList>(`${this.apiURL}/folders/${id}`, data);
    }

    toggleActiveFolder(id: number) {
        return this.httpClient.put(`${this.apiURL}/folders/toggle-deleted/${id}`, {});
    }

    toggleFolderManagersOnly(id: number) {
        return this.httpClient.put(`${this.apiURL}/folders/toggle-managers-only/${id}`, {});
    }

    toggleFolderGlobal(id) {
        return this.httpClient.put(`${this.apiURL}/folders/toggle-global/${id}`, {});
    }

    updatePages() {
        return this.httpClient.put(`${this.apiURL}/folders/sync-pages`, {});
    }

    deleteFolder(id: any) {
        return this.httpClient.delete<AdminFoldersList>(`${this.apiURL}/folders/${id}`);
    }

    deleteSelectedFolders(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/folders/delete-multiple?${queryParams}`);
    }

    exportSelectedFolders(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/folders/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportFolders() {
        return this.httpClient.get(`${this.apiURL}/folders/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    // PAGES
    getPages(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminPagesList[]>(`${this.apiURL}/pages`, {params});
    }

    getPage(id: any) {
        return this.httpClient.get<AdminPagesList>(`${this.apiURL}/pages/${id}`);
    }

    createPage(data: PageModel) {
        return this.httpClient.post(`${this.apiURL}/pages`, data);
    }

    updatePage(id: number, data: PageModel) {
        return this.httpClient.put(`${this.apiURL}/pages/${id}`, data);
    }

    toggleActivePage(id: number) {
        return this.httpClient.put(`${this.apiURL}/pages/toggle-deleted/${id}`, {});
    }

    togglePageManagersOnly(id: number) {
        return this.httpClient.put(`${this.apiURL}/pages/toggle-managers-only/${id}`, {});
    }

    togglePageGlobal(id) {
        return this.httpClient.put(`${this.apiURL}/pages/toggle-global/${id}`, {});
    }

    deletePage(id: any) {
        return this.httpClient.delete<AdminPagesList>(`${this.apiURL}/pages/${id}`);
    }

    deleteSelectedPages(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/pages/delete-multiple?${queryParams}`);
    }

    exportSelectedPages(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/pages/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportPages() {
        return this.httpClient.get(`${this.apiURL}/pages/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    searchPage(q: string) {
        return this.httpClient.get<SearchResult[]>(`${this.apiURL}/pages/search`, {
            params: {q}
        });
    }

    // CONTENTS

    getContents(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminContentsList[]>(`${this.apiURL}/contents?`, {params});
    }

    getDataSources(id: number | string) {
        return this.httpClient.get<any>(`${this.apiURL}/dashboards/data-sources-for-company/${id}`);

    }

    createContent(data: ContentModel) {
        return this.httpClient.post(`${this.apiURL}/contents`, data);
    }

    manageContent(id: number | string) {
        return this.httpClient.get<ContentModel>(`${this.apiURL}/contents/content-management/${id}`);
    }

    manageDashboardContent(id: number | string) {
        return this.httpClient.get<DashboardContentModel>(`${this.apiURL}/dashboards/dashboard-builder-management/${id}`);
    }

    createContentForUser(data: ContentModel, type: string) {
        const params = {
            set_active: '1',
            type
        };

        return this.httpClient.post(`${this.apiURL}/contents/content-management`, data, {params});
    }

    updateContent(id: number, data: ContentModel, page?: number) {
        const params: { [param: string]: string | string[]; } = {};

        if (page) {
            params.page = page.toString();
        }

        return this.httpClient.put(`${this.apiURL}/contents/content-management/${id}`, data, {params});
    }

    updateDashboardContent(id: number, data: DashboardContentModel) {
        return this.httpClient.put(`${this.apiURL}/dashboards/save`, data);
    }

    toggleActiveContent(id: number) {
        return this.httpClient.put(`${this.apiURL}/contents/toggle-deleted/${id}`, {});
    }

    deleteContent(id: any) {
        return this.httpClient.delete<AdminContentsList>(`${this.apiURL}/contents/${id}`);
    }

    clearContentCache(id: any) {
        return this.httpClient.put(`${this.apiURL}/contents/clear-content-cache/${id}`, {});
    }

    copyContent(id: any) {
        return this.httpClient.put(`${this.apiURL}/contents/copy-content/${id}`, {});
    }

    deleteSelectedContents(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/contents/delete-multiple?${queryParams}`);
    }

    exportSelectedContents(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/contents/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportContents() {
        return this.httpClient.get(`${this.apiURL}/contents/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    // WORKBOOKS
    getWorkbooks(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminWorkbooksList[]>(`${this.apiURL}/workbooks`, {params});
    }

    createWorkbook(data: WorkbookModel) {
        return this.httpClient.post<AdminWorkbooksList>(`${this.apiURL}/workbooks`, data);
    }

    updateWorkbook(id: any, data: WorkbookModel) {
        return this.httpClient.put<AdminWorkbooksList>(`${this.apiURL}/workbooks/${id}`, data);
    }

    deleteWorkbook(id: any) {
        return this.httpClient.delete<AdminWorkbooksList>(`${this.apiURL}/workbooks/delete/${id}`);
    }

    deleteSelectedWorkbooks(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/workbooks/delete-multiple?${queryParams}`);
    }

    exportSelectedWorkbooks(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/workbooks/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportWorkbooks() {
        return this.httpClient.get(`${this.apiURL}/workbooks/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    getWorkbookOutput(id: any) {
        return this.httpClient.get(`${this.apiURL}/workbooks/view-output-file/${id}`, {
            responseType: 'text' as 'json'
        });
    }

    uploadWorkbookInput(id: any, formData: FormData) {
        return this.httpClient.post(`${this.apiURL}/workbooks/upload-input-file/${id}`, formData);
    }

    calculateWorkbookOutput(id: any) {
        return this.httpClient.get(`${this.apiURL}/workbooks/calculate-output-data/${id}`);
    }

    workbookToggleActive(id: any) {
        return this.httpClient.put(`${this.apiURL}/workbooks/toggle-enabled/${id}`, {});
    }

    workbookToggleManagersOnly(id: any) {
        return this.httpClient.put(`${this.apiURL}/workbooks/toggle-managers-only/${id}`, {});
    }

    workbookToggleUnderMaintenance(id: any) {
        return this.httpClient.put(`${this.apiURL}/workbooks/toggle-under-maintenance/${id}`, {});
    }

    workbookToggleCompressed(id: any) {
        return this.httpClient.put(`${this.apiURL}/workbooks/toggle-compressed/${id}`, {});
    }

    // WORKBOOK TEMPLATES

    getWorkbookTemplates(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminWorkbookTemplatesList[]>(`${this.apiURL}/workbook-templates`, {params});
    }

    uploadTemplateFile(formData: FormData) {
        return this.httpClient.post(`${this.apiURL}/workbook-templates/upload`, formData);
    }

    uploadDashboardConfig(id, formData: FormData) {
        return this.httpClient.post(`${this.apiURL}/dashboards/upload-config/${id}`, formData);
    }

    updateTemplateFile(formData: FormData, templateId: number) {
        return this.httpClient.post(`${this.apiURL}/workbook-templates/update-file/${templateId}`, formData);
    }

    updateWorkbookTemplate(id: number, data: { name: any; description: any }) {
        return this.httpClient.put(`${this.apiURL}/workbook-templates/${id}`, data);
    }

    toggleWorkbookTemplateCache(id: number) {
        return this.httpClient.put(`${this.apiURL}/workbook-templates/toggle-cache/${id}`, {});
    }

    downloadWorkbookTemplate(id: number) {
        return this.httpClient.get(`${this.apiURL}/workbook-templates/download/${id}`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteSelectedWorkbookTemplates(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/workbook-templates/delete-multiple?${queryParams}`);
    }

    exportSelectedWorkbookTemplates(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/workbook-templates/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteWorkbookTemplate(id: number) {
        return this.httpClient.delete(`${this.apiURL}/workbook-templates/${id}`);
    }

    exportWorkbookTemplates() {
        return this.httpClient.get(`${this.apiURL}/workbook-templates/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    // ADDIN VERSIONS

    getExcelVersions(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminAddinVersionsList[]>(`${this.apiURL}/excel-versions`, {params});
    }

    createVersion(data: AddinVersionModel) {
        return this.httpClient.post<AdminAddinVersionsList>(`${this.apiURL}/excel-versions`, data);
    }

    updateVersion(id: any, data: AddinVersionModel) {
        return this.httpClient.put<AdminAddinVersionsList>(`${this.apiURL}/excel-versions/${id}`, data);
    }

    toggleActiveVersion(id: number) {
        return this.httpClient.put(`${this.apiURL}/excel-versions/toggle-active/${id}`, {});
    }

    toggleSupportedVersion(id: number) {
        return this.httpClient.put(`${this.apiURL}/excel-versions/toggle-supported-version/${id}`, {});
    }

    deleteVersion(id: number) {
        return this.httpClient.delete(`${this.apiURL}/excel-versions/${id}`, {});
    }

    deleteSelectedExcelVersions(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.delete(`${this.apiURL}/excel-versions/delete-multiple?${queryParams}`);
    }

    exportSelectedExcelVersions(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/excel-versions/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportExcelVersions() {
        return this.httpClient.get(`${this.apiURL}/excel-versions/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    uploadVersion(id: any, file: any) {
        const form = new FormData();
        form.append('file', file);

        return this.httpClient.post<AdminAddinVersionsList>(`${this.apiURL}/excel-versions/upload/${id}`, form);
    }

    downloadVersion(id: any) {
        return this.httpClient.get(`${this.apiURL}/excel-versions/download/${id}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    getLastVersion() {
        return this.httpClient.get(`${this.apiURL}/excel-versions/last`);
    }

    // SCHEDULING

    getScheduling(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminSchedulingList[]>(`${this.apiURL}/scheduling/jobs?`, {params});
    }


    createScheduling(data: SchedulingModel) {
        return this.httpClient.post<AdminSchedulingList[]>(`${this.apiURL}/scheduling/job`, data);
    }

    updateScheduling(id: any, data: SchedulingModel) {
        return this.httpClient.put<AdminSchedulingList[]>(`${this.apiURL}/scheduling/job/${id}`, data);
    }

    deleteSelectedScheduling(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/scheduling/delete-multiple?${queryParams}`);
    }

    exportSelectedScheduling(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/scheduling/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportScheduling() {
        return this.httpClient.get(`${this.apiURL}/scheduling/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteScheduling(id: any) {
        return this.httpClient.delete(`${this.apiURL}/scheduling/job/${id}`);
    }

    // MESSAGES

    getAdminMessages(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminMessagesList[]>(`${this.apiURL}/messages?`, {params});
    }

    createMessage(data: MessageModel) {
        return this.httpClient.post<AdminMessagesList>(`${this.apiURL}/messages`, data);
    }

    updateMessage(id: any, data: MessageModel) {
        return this.httpClient.put<AdminMessagesList>(`${this.apiURL}/messages/${id}`, data);
    }

    deleteSelectedMessages(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/messages/delete-multiple?${queryParams}`);
    }

    exportSelectedMessages(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/messages/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportMessages() {
        return this.httpClient.get(`${this.apiURL}/messages/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteMessage(id: any) {
        return this.httpClient.delete(`${this.apiURL}/messages/${id}`);
    }

    // SUBSCRIPTIONS
    getAdminSubscriptions(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminSubscriptionsList[]>(`${this.apiURL}/subscriptions?`, {params});
    }

    createSubscription(data: any) {
        return this.httpClient.post(`${this.apiURL}/subscriptions`, data);
    }

    updateSubscription(id: any, data: any) {
        return this.httpClient.put(`${this.apiURL}/subscriptions/${id}`, data);
    }

    updateSubscriptionFollowers(id: any, data: any) {
        return this.httpClient.put(`${this.apiURL}/subscriptions/edit-followers/${id}`, data);
    }

    deleteSelectedSubscriptions(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/subscriptions/delete-multiple?${queryParams}`);
    }

    exportSelectedSubscriptions(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/subscriptions/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportSubscriptions() {
        return this.httpClient.get(`${this.apiURL}/subscriptions/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    toggleActiveSubscription(id: any) {
        return this.httpClient.put(`${this.apiURL}/subscriptions/toggle-pause/${id}`, {});
    }

    deleteSubscription(id: any) {
        return this.httpClient.delete(`${this.apiURL}/subscriptions/${id}`, {});
    }


    getAdminSubscriptionLog(id: any, pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminSubscriptionLogList[]>(`${this.apiURL}/subscriptions/view-log/${id}?`, {params});
    }

    // ADMINS

    getAdmins(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminAdminsList[]>(`${this.apiURL}/admins?`, {params});
    }

    demoteAdmin(id: number) {
        return this.httpClient.put(`${this.apiURL}/admins/demote/${id}`, {});
    }

    promoteAdmin(id: number) {
        return this.httpClient.put(`${this.apiURL}/admins/promote/${id}`, {});
    }

    deleteSelectedAdmins(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/admins/delete-multiple?${queryParams}`);
    }

    exportSelectedAdmins(selectedIds: any[]) {
        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);
        return this.httpClient.get(`${this.apiURL}/admins/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    exportAdmins() {
        return this.httpClient.get(`${this.apiURL}/admins/export?all=true`, {
            responseType: 'blob' as 'json'
        });
    }

    // LOGS

    getLogs(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminLogsList[]>(`${this.apiURL}/logs?`, {params});
    }

    deleteLogs() {
        return this.httpClient.get<AdminLogsList[]>(`${this.apiURL}/logs/purge`);
    }

    importUsersFromCSV(formData: FormData) {
        return this.httpClient.post(`${this.apiURL}/users/import`, formData);
    }

    importGroupsFromCSV(formData: FormData) {
        return this.httpClient.post(`${this.apiURL}/groups/import`, formData);
    }

    saveReport(params: HttpParams, reportId: string) {
        return this.httpClient.get(`${this.apiURL}/profile/report/download/${reportId}.report`, {
            params,
            responseType: 'blob' as 'json'
        });
    }

    emailReport(params: HttpParams, reportId: string) {
        return this.httpClient.get(`${this.apiURL}/profile/report/download/${reportId}.report`, {params});
    }

    getUserRequests() {
        return this.httpClient.get<AdminUserRequestsList[]>(`${this.apiURL}/pending-requests/users`);
    }

    getGroupRequests() {
        return this.httpClient.get<AdminGroupRequestsList[]>(`${this.apiURL}/pending-requests/groups`);
    }

    acceptGroupRequest(id: string) {
        return this.httpClient.put(`${this.apiURL}/pending-requests/groups/accept/${id}`, {});
    }

    denyGroupRequest(id: string) {
        return this.httpClient.put(`${this.apiURL}/pending-requests/groups/deny/${id}`, {});
    }

    acceptUserRequest(id: string) {
        return this.httpClient.put(`${this.apiURL}/pending-requests/users/accept/${id}`, {});

    }

    denyUserRequest(id: string) {
        return this.httpClient.put(`${this.apiURL}/pending-requests/users/deny/${id}`, {});
    }

    flushRedisCache() {
        return this.httpClient.get(`${this.apiURL}/scheduling/flush-redis-cache`);
    }

    indexPages() {
        return this.httpClient.get(`${this.apiURL}/scheduling/index-pages`);
    }

    updateMenuLabels(value: boolean) {
        return this.httpClient.put(`${this.apiURL}/profile/settings`, {menu_labels: value});
    }

    updateEmailNotifications(value: boolean) {
        return this.httpClient.put(`${this.apiURL}/profile/settings`, {email_notifications: value});
    }

    getRemoteDataSource(remoteDataSourceId: number | string) {
        return this.httpClient.get(`${this.apiURL}/profile/dashboards/data-sources/${remoteDataSourceId}`);
    }

    getRemoteDataSourceZip(remoteDataSourceId: number | string) {
        const date = Date.now().toString();

        return this.httpClient.get(`${this.apiURL}/profile/dashboards/data-sources/${remoteDataSourceId}.zip?_r=${date}`, {
            responseType: 'blob' as 'json'
        });
    }

    getRemotePublicDataSourceZip(shareId: string, contentId: number, remoteDataSourceId: number | string) {
        const date = Date.now().toString();

        return this.httpClient.get(`${this.apiURL}/public/dashboards/data-sources/${shareId}/${contentId}/${remoteDataSourceId}.zip?_r=${date}`, {
            responseType: 'blob' as 'json'
        });
    }

    getCompanyStatsDataSource(companyId: number | string) {
        return this.httpClient.get(`${this.apiURL}/profile/dashboards/data-sources/company-stats/${companyId}`);
    }

    ping() {
        return this.httpClient.get<{ expired_at: number, company_id: number }>(`${environment.apiURL}/ping`, {
            headers: {
                ignoreLoadingBar: ''
            }
        });
    }

    private getCommonParams(pager: Pager) {
        return new HttpParams()
            .set('page', pager.current_page.toString())
            .set('nr_of_items_per_page', pager.nr_of_items_per_page.toString())
            .set('sorting[column]', pager.sorting.column.toString())
            .set('sorting[direction]', pager.sorting.direction.toString());
    }

    // Departments

    getDepartments(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<AdminDepartmentsList[]>(`${this.apiURL}/departments`, {params});
    }

    createDepartment(data: DepartmentModel) {
        return this.httpClient.post(`${this.apiURL}/departments`, data);
    }

    updateDepartment(id: any, data: DepartmentModel) {
        return this.httpClient.put(`${this.apiURL}/departments/${id}`, data);
    }

    deleteDepartment(id: any) {
        return this.httpClient.delete(`${this.apiURL}/departments/${id}`);
    }

    exportDepartments(selectedIds: any[] = null) {
        let queryParams = 'all=true';

        if (selectedIds && selectedIds.length) {
            queryParams = this.makeIdsQuery(selectedIds);
        }

        return this.httpClient.get(`${this.apiURL}/departments/export?${queryParams}`, {
            responseType: 'blob' as 'json'
        });
    }

    deleteSelectedDepartments(selectedIds: any[]) {
        return this.httpClient.get(`${this.apiURL}/departments/delete-multiple?${this.makeIdsQuery(selectedIds)}`);
    }

    private makeIdsQuery(selectedIds: any[]) {
        let query = '';
        for (const id of selectedIds) {
            query += `ids[]=${id}&`;
        }
        return query.slice(0, -1);
    }

    getDefaultProfileValues() {
        return this.httpClient.get<ProfileListValues>(`${this.apiURL}/profile-values/default`);
    }

    updateDefaultProfileValues(data: ProfileListValuesModel) {
        return this.httpClient.put(`${this.apiURL}/profile-values/default`, data);
    }

    getUserProfile(id: any) {
        return this.httpClient.get<UserProfileModel>(`${this.apiURL}/users/profile/${id}`);
    }

    updateUserProfile(id: any, data: UserProfileModel) {
        return this.httpClient.post(`${this.apiURL}/users/profile/${id}`, data);
    }

    getUserDocuments(userId: any) {
        return this.httpClient.get<UserProfileDocument[]>(`${this.apiURL}/users-documents/for-user/${userId}`);
    }

    uploadUserDocuments(userId: any, data: FormData) {
        return this.httpClient.post<UserProfileDocument[]>(`${this.apiURL}/users-documents/for-user/${userId}/upload`, data);
    }

    updateUserDocument(id: any, data: UserProfileDocumentModel) {
        return this.httpClient.put(`${this.apiURL}/users-documents/${id}`, data);
    }

    deleteUserDocument(id: any) {
        return this.httpClient.delete(`${this.apiURL}/users-documents/${id}`, {});
    }

    downloadDocument(id: any) {
        return this.httpClient.get(`${this.apiURL}/users-documents/${id}/download`, {
            responseType: 'blob' as 'json'
        });
    }

    downloadVideo(id: any, videoId) {
        let params = new HttpParams();

        if (videoId) {
            params = params.set('videoId', videoId);
        }

        return this.httpClient.get(`${this.apiURL}/profile/team/${id}/video`, {
            responseType: 'blob' as 'json',
            params
        });
    }

    getTeam(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<UserProfileListValue[]>(`${this.apiURL}/profile/team`, {params});
    }

    getTeamPerson(id: any) {
        return this.httpClient.get<ProfileData>(`${this.apiURL}/profile/team/${id}`);
    }

    getTeamPersonAvatar(id: any) {
        const date = Date.now().toString();

        return this.httpClient.get(`${this.apiURL}/profile/team/${id}/avatar.jpg?_r=${date}`, {
            responseType: 'blob' as 'json'
        });
    }

    updateTeamPerson(id: any, data: any) {
        return this.httpClient.put(`${this.apiURL}/profile/team/${id}`, data);
    }

    uploadUserAvatar(id: any, data: FormData) {
        return this.httpClient.post(`${this.apiURL}/profile/team/${id}/upload-avatar`, data);
    }

    downloadDashboardConfig(id: string) {
        return this.httpClient.get(`${this.apiURL}/dashboards/download-config/${id}`, {
            responseType: 'blob' as 'json'
        });
    }

    updatePagesOrder(data: { pages: number[] | string[] }) {
        return this.httpClient.put(`${this.apiURL}/profile/reports/update-order`, data);
    }

    changeUiTheme(data: { theme: string }) {
        return this.httpClient.put(`${this.apiURL}/profile/change-ui-theme`, data);
    }

    getDevBackendVersion() {
        return fetch(`${devEnv.apiURL}/version`).then(res => res.json());
    }

    getDevAddinVersion() {
        return fetch(`${devEnv.addinURL}/api/version`).then(res => res.json());
    }

    getStagingBackendVersion() {
        return fetch(`${stagEnv.apiURL}/version`).then(res => res.json());
    }

    getStagingAddinVersion() {
        return fetch(`${stagEnv.addinURL}/api/version`).then(res => res.json());
    }

    getProdBackendVersion() {
        return fetch(`${prodEnv.apiURL}/version`).then(res => res.json());
    }

    getProdAddinVersion() {
        return fetch(`${prodEnv.addinURL}/api/version`).then(res => res.json());
    }

    uploadFile(data) {
        return this.httpClient.post<{ location: string; alt: string; text: string }>(`${this.apiURL}/files`, data);
    }

    uploadFileForMarkdown(data) {
        return this.httpClient.post<string[]>(`${this.apiURL}/files/markdown`, data);
    }

    getComments(pageId) {
        return this.httpClient.get<PageComment[]>(`${this.apiURL}/comments/for-page/${pageId}`);
    }

    addComment(data) {
        return this.httpClient.post(`${this.apiURL}/comments`, data);
    }

    updateComment(id, data) {
        return this.httpClient.put(`${this.apiURL}/comments/${id}`, data);
    }

    deleteComment(id) {
        return this.httpClient.delete(`${this.apiURL}/comments/${id}`, {});
    }

    uploadContentAttachmentFile(id, data) {
        return this.httpClient.post(`${this.apiURL}/contents/${id}/attachments`, data);
    }

    downloadContentAttachmentFile(file: { id: string; name: string; }) {
        return this.httpClient.get(`${this.apiURL}/attachments/content-attachment/${file.name}`, {
            responseType: 'blob' as 'json'
        })
            .subscribe((response: { size: number, type: string }) => {
                this.downloadFileClientSide(response, file.name, response.type);
            });
    }

    deleteContentAttachmentFile(ids) {
        return this.httpClient.delete(`${this.apiURL}/attachments/content-attachment`, {params: {'ids[]': ids}});
    }

    getComment(id) {
        return this.httpClient.get<PageComment>(`${this.apiURL}/comments/${id}`);
    }

    uploadCommentAttachmentFile(id, data) {
        return this.httpClient.post(`${this.apiURL}/comments/${id}/attachments`, data);
    }

    downloadCommentAttachmentFile(file: { id: string; name: string; }) {
        return this.httpClient.get(`${this.apiURL}/attachments/comment-attachment/${file.name}`, {
            responseType: 'blob' as 'json'
        })
            .subscribe((response: { size: number, type: string }) => {
                this.downloadFileClientSide(response, file.name, response.type);
            });
    }

    deleteCommentAttachmentFile(ids) {
        return this.httpClient.delete(`${this.apiURL}/attachments/comment-attachment`, {params: {'ids[]': ids}});
    }

    getBackendEnv() {
        return this.httpClient.get<{ [key: string]: string }[]>(`${this.apiURL}/get-env`);
    }

    getAddinEnv() {
        return fetch(`${environment.addinURL}/api/get-env?token=${environment.addinEnvToken}`).then(res => res.json());
    }

    getFoldersTree(companyId = null) {
        let params = new HttpParams();

        if (companyId) {
            params = params.set('company', companyId);
        }

        return this.httpClient.get(`${this.apiURL}/folders/folders-tree`, {params});
    }

    updateFoldersTree(data: any) {
        return this.httpClient.put(`${this.apiURL}/folders/folders-tree`, data);
    }

    activateUser(token: string) {
        return this.httpClient.get<{ success: boolean; on_moderation: boolean; api_key: string }>(`${environment.apiURL}/confirm-email/${token}`, {
            headers: {
                ignoreLoadingBar: ''
            }
        });
    }

    getCoursesSidebar() {
        return this.httpClient.get<CurseSidebarCategory[]>(`${this.apiURL}/university/sidebar`);
    }

    getCourses() {
        return this.httpClient.get<CourseDashboardItem[]>(`${this.apiURL}/university/courses`);
    }

    getCourse(id) {
        return this.httpClient.get<Course>(`${this.apiURL}/university/courses/${id}`);
    }

    getDashboard() {
        return this.httpClient.get<CourseDashboardItem[]>(`${this.apiURL}/university/dashboard`);
    }

    resendVerification(email: string) {
        return this.httpClient.post(`${environment.apiURL}/resend-verification-email`, {email});
    }

    getOrganizationChart() {
        // tslint:disable-next-line:ban-types
        return this.httpClient.get<Object[]>(`${this.apiURL}/profile/team/organization/chart`);
    }

    setUserChildOf(node, parent) {
        return this.httpClient.put(`${this.apiURL}/profile/team/organization/chart`, [node, parent]);
    }

    getSentEmails(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<SentEmail[]>(`${this.apiURL}/sent-emails`, {params});
    }

    getNews(pager: Pager) {
        let params = this.getCommonParams(pager);

        pager.filters.forEach(el => {
            if (el.value !== 'false') {
                params = params.set(`filters[${el.key}]`, el.value);
            }
        });

        return this.httpClient.get<News[]>(`${this.apiURL}/news`, {params});
    }

    createNews(data) {
        return this.httpClient.post<News[]>(`${this.apiURL}/news`, data);
    }

    updateNews(id, data) {
        return this.httpClient.put<News[]>(`${this.apiURL}/news/${id}`, data);
    }

    deleteNews(id) {
        return this.httpClient.delete(`${this.apiURL}/news/${id}`, {});
    }

    deleteSelectedNews(selectedIds) {

        let queryParams = '';
        for (const id of selectedIds) {
            queryParams += `ids[]=${id}&`;
        }
        queryParams = queryParams.slice(0, -1);


        return this.httpClient.delete(`${this.apiURL}/news/delete-multiple?${queryParams}`, {});
    }

    toggleActiveNews(id) {
        return this.httpClient.put(`${this.apiURL}/news/${id}/toggle-active`, {});
    }

    uploadNewsPreview(id: any, data: FormData) {
        return this.httpClient.post(`${this.apiURL}/news/${id}/upload-preview`, data);
    }

    getLatestNews(page = 1, term: string = null) {
        return this.httpClient.get<News[]>(`${this.apiURL}/latest-news?page=${page}&term=${term}`, {observe: 'response'});
    }

    getPublicNews(slug) {
        return this.httpClient.get<News[]>(`${this.apiURL}/latest-news/${slug}`);
    }

    getEmailsLists() {
        return this.httpClient.get<{ forbidden_emails: string[], limited_emails: string[] }>(`${this.apiURL}/emails-lists`);
    }

    updateEmailsLists(data) {
        return this.httpClient.put(`${this.apiURL}/emails-lists/update`, data);
    }

    getGitBoards() {
        return this.httpClient.get<GitBoard[]>(`${this.apiURL}/ticketing/boards`);
    }

    getGitClosedIssues(page = 1) {
        return this.httpClient.get<{ has_next: boolean, issues: any[] }>(`${this.apiURL}/ticketing/boards/closed?page=${page}`);
    }

    uploadFilesToGit(form: FormData) {
        return this.httpClient.post<string[]>(`${this.apiURL}/ticketing/boards/upload`, form);
    }

    createGitIssue(data: any) {
        return this.httpClient.post(`${this.apiURL}/ticketing/boards`, data);
    }

    getGitIssue(iid: number | string) {
        return this.httpClient.get<GitIssue>(`${this.apiURL}/ticketing/boards/issues/${iid}`);
    }

    addCommentToGitIssue(id, data: any) {
        return this.httpClient.post(`${this.apiURL}/ticketing/boards/issues/${id}/comments`, data);
    }

    updateCommentForGitIssue(id, commentId, data: any) {
        return this.httpClient.put(`${this.apiURL}/ticketing/boards/issues/${id}/comments/${commentId}`, data);
    }

    updateGitIssue(id, data: any, withNotes = false) {
        const q = '?with_notes=' + (withNotes ? 1 : 0);

        return this.httpClient.put<GitIssue | GitBoardIssue>(`${this.apiURL}/ticketing/boards/issues/${id}${q}`, data);
    }

    getGitProjectMembers() {
        return this.httpClient.get<GitUser[]>(`${this.apiURL}/ticketing/boards/project-members`);
    }

    getGitProjectLabels() {
        return this.httpClient.get<GitLabel[]>(`${this.apiURL}/ticketing/boards/labels`);
    }

    getYoutubeRegisterUrl() {
        return this.httpClient.get<{ redirect_url: string }>(`${environment.apiURL}/youtube/oauth/register-url`);
    }

    getDailymotionRegisterUrl() {
        return this.httpClient.get<{ redirect_url: string }>(`${environment.apiURL}/dailymotion/oauth/register-url`);
    }

    uploadProfileVideo(id, form: FormData) {
        return this.httpClient.post<{ video_id: string }>(`${this.apiURL}/profile/team/${id}/upload-video`, form);
    }

    getVideoQuota() {
        return this.httpClient.get<{ is_reached: boolean; reset_date: string }>(`${this.apiURL}/profile/team/video-quota`);
    }

    getHolidaysRequest(options: {
        only_possible?: boolean,
        statuses?: string[],
        from?: Date,
        to?: Date,
        max_results?: number
        group?: string
    } = null) {
        let params = new HttpParams();

        if (options?.only_possible) {
            params = params.set('only_possible', '1');
        }

        if (options?.statuses?.length) {
            options.statuses.forEach(v => {
                params = params.append('status[]', v);
            });
        }

        if (options?.from && options?.to) {
            params = params.set('from', options.from.toISOString())
                .set('to', options.to.toISOString());
        }

        if (options?.max_results) {
            params = params.set('max_results', options.max_results.toString());
        }

        if (options?.group) {
            params = params.set('group', options.group);
        }

        return this.httpClient.get<HolidayRequest[]>(`${this.apiURL}/hr/holidays-requests`, {
            params
        });
    }

    createHolidayRequest(data: HolidaysRequestModel) {
        return this.httpClient.post<HolidayRequest>(`${this.apiURL}/hr/holidays-requests`, data);
    }

    updateHolidayRequest(id, data: HolidaysRequestModel) {
        return this.httpClient.put<HolidayRequest>(`${this.apiURL}/hr/holidays-requests/${id}`, data);
    }

    checkAllowanceForUser(id, year = null) {
        let params = new HttpParams();

        if (year) {
            params = params.set('year', year.toString());
        }

        return this.httpClient.get<{
            holidays_remain: number;
            total_holidays_allowance: number;
        }>(`${this.apiURL}/hr/holidays-requests/check-allowance-for-user/${id}`, {
            params
        });
    }

    markHolidaysRequest(id: number, action: 'accept' | 'reject') {
        return this.httpClient.put<HolidayRequest>(`${this.apiURL}/hr/holidays-requests/${id}/${action}`, null);
    }

    deleteHolidaysRequest(id) {
        return this.httpClient.delete(`${this.apiURL}/hr/holidays-requests/${id}`);
    }
}
