import {Injectable} from '@angular/core';
import {FrontendPage, Page} from '../models/models';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {DEFAULT_PAGE_TITLE} from './constants';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private selectedReport: FrontendPage;
    private pagePostfix = ' — Evo';
    private exportableFormats = new Subject<{}>();
    private localReadList: number[] = [];
    private pagesCount: number;
    private refreshReports$ = new Subject();
    private defaultReportUrl: string = null;
    private groupsCount = 0;

    get reportsSubject() {
        return this.refreshReports$;
    }

    constructor(private titleService: Title, private api: ApiService) {
    }

    clearSelectedReport() {
        this.selectedReport = undefined;
        this.titleService.setTitle(DEFAULT_PAGE_TITLE);
    }

    getSelectedReport(): FrontendPage {
        return this.selectedReport;
    }

    setSelectedReport(report: FrontendPage) {
        this.selectedReport = report;
        const title = report.name ? report.name : 'Portal';
        this.titleService.setTitle(title + this.pagePostfix);

        this.exportableFormats.next(report.exportable_formats);
    }

    getExportedFormats(): Subject<{}> {
        return this.exportableFormats;
    }

    addReportToLocalReadList(page: Page) {
        this.localReadList.push(page.id);
    }

    isReportRead(page: Page) {
        return this.localReadList.indexOf(page.id) >= 0;
    }

    setPagesCount(count): void {
        this.pagesCount = count;
    }

    getPagesCount(): number {
        return this.pagesCount;
    }

    getGroupsCount(): number {
        return this.groupsCount;
    }

    setGroupsCount(value: number) {
        this.groupsCount = value;
    }

    async isOneReport() {
        if (this.pagesCount && this.defaultReportUrl) {
            return this.pagesCount === 1;
        }

        const reports = await this.api.getReports().toPromise().catch(e => {
            console.log(e);
        });

        if (!reports) {
            this.pagesCount = null;
            this.defaultReportUrl = null;

            return false;
        }

        this.pagesCount = reports.pagesCount;
        this.defaultReportUrl = reports.default_url;

        return this.pagesCount === 1;
    }

    getDefaultReportUrl() {
        return this.defaultReportUrl;
    }
}
