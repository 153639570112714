<div class="container-fluid cover-bg-container">
    <div class="panel-centered-wrapper">
        <div class="panel-centered" style="min-width: 800px; color: black;">

            <h1>Evo Privacy Policy</h1>

            <div class="post-entry">

                <p>Last updated January 24th, 2020</p>
                <p>Thank you for visiting Evo Europe Limited (“company”, “we”, “us”, or “our”). We are committed to
                    protecting
                    your personal information and your right to privacy. If you have any questions or concerns about our
                    policy,
                    or our practices with regards to your personal information, please contact us at
                    support@evopricing.com</p>
                <p>When you visit our websites https://evopricing.com and https://evouser.com (“Site”) and use our
                    services,
                    you
                    trust us with your personal information. We take your privacy very seriously. In this privacy
                    notice, we
                    describe our privacy policy. We seek to explain to you in the clearest way possible what information
                    we
                    collect, how we use it and what rights you have in relation to it. We hope you take some time to
                    read
                    through it carefully, as it is important. If there are any terms in this privacy policy that you do
                    not
                    agree with, please discontinue use of our site and our services.</p>
                <p>This privacy policy applies to all information collected through our website or mobile application,
                    and/or
                    any related services, sales, marketing or events (we refer to them collectively in this privacy
                    policy
                    as
                    the “Site“).</p>
                <p>Please read this privacy policy carefully as it will help you make informed decisions about sharing
                    your
                    personal information with us.</p>
                <p>1. WHAT INFORMATION DO WE COLLECT?<br>
                    Personal information you disclose to us</p>
                <p>In Short: We collect personal information that you provide to us such as name, address, contact
                    information,
                    passwords and security data.</p>
                <p>We collect personal information that you voluntarily provide to us when registering with us,
                    expressing
                    an
                    interest in obtaining information about us or our products and services, when participating in
                    activities on
                    the Site (such as using our portal) or otherwise contacting us.</p>
                <p>The personal information that we collect depends on the context of your interactions with us and the
                    Site,
                    the choices you make and the products and features you use. The personal information we collect can
                    include
                    the following:</p>
                <p>– Name and Contact Data. We collect your first and last name, email address, postal address, phone
                    number,
                    and other similar contact data.</p>
                <p>– Credentials. We collect passwords, password hints, and similar security information used for
                    authentication
                    and account access.</p>
                <p>All personal information that you provide to us must be true, complete and accurate, and you must
                    notify
                    us
                    of any changes to such personal information.</p>
                <p>Information automatically collected</p>
                <p>In Short: Some information – such as IP address and/or browser and device characteristics – is
                    collected
                    automatically when you visit our website.</p>
                <p>We automatically collect certain information when you visit, use or navigate the Site.<br>
                    This information does not reveal your specific identity (like your name or contact information) but
                    may
                    include device and usage information, such as your IP address, browser and device characteristics,
                    operating
                    system, language preferences, referring URLs, device name, country, location, information about how
                    and
                    when
                    you use our Site and other technical information.<br>
                    If you access our site with your mobile device, we may automatically collect device information
                    (such as
                    your mobile device ID, model and manufacturer), operating system, version information and IP
                    address.<br>
                    This information is primarily needed to maintain the security and operation of our Site, and for our
                    internal analytics and reporting purposes.</p>
                <p>Information collected from other Sources</p>
                <p>In Short: We may collect limited data from public databases, marketing partners, social media
                    platforms,
                    and
                    other outside sources.</p>
                <p>We may obtain information about you from other sources, such as public databases, joint marketing
                    partners,
                    social media platforms, as well as from other third parties.<br>
                    Examples of the information we receive from other sources include: social media profile information
                    (your
                    name, gender, birthday, email, current city, state and country, user identification numbers for your
                    contacts, profile picture URL and any other information that you choose to make public); marketing
                    leads
                    and
                    search results and links, including paid listings (such as sponsored links).</p>
                <p>2. HOW DO WE USE YOUR INFORMATION?</p>
                <p>In Short: We process your information for purposes based on legitimate business interests, the
                    fulfillment of
                    our contract with you, compliance with our legal obligations, and/or your consent.</p>
                <p>We use personal information collected via our Site for a variety of business purposes described
                    below.<br>
                    We process your personal information for these purposes in reliance on our legitimate business
                    interests
                    (“Business Purposes”), in order to enter into or perform a contract with you (“Contractual”), with
                    your
                    consent (“Consent”), and/or for compliance with our legal obligations (“Legal Reasons”).<br>
                    We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
                <p>We use the information we collect or receive:</p>
                <p>– To facilitate account creation and logon process with your Consent.<br>
                    – To send you marketing and promotional communications for Business Purposes and/or with your
                    Consent.
                    We
                    and/or our third party marketing partners may use the personal information you send to us for our
                    marketing
                    purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing
                    emails
                    at any time (see the “What are Your Privacy Rights?” section below).<br>
                    – To send administrative information to you for related to your account, our business purposes,
                    and/or
                    for
                    legal reasons. We may use your personal information to send you product, service and new feature
                    information
                    and/or information about changes to our terms, conditions, and policies.<br>
                    – Fulfill and manage your orders for Contractual reasons. We may use your information to fulfill and
                    manage
                    your orders, payments, returns, and exchanges made through the Site.<br>
                    – To post testimonials with your Consent. We post testimonials on our Site that may contain personal
                    information. Prior to posting a testimonial, we will obtain your consent to use your name and
                    testimonial.
                    If you wish to update, or delete your testimonial, please contact us at support@evopricing.com and
                    be
                    sure
                    to include your name, testimonial location, and contact information.<br>
                    – Deliver targeted advertising to you for our Business Purposes and/or with your Consent. We may use
                    your
                    information to develop and display content and advertising (and work with third parties who do so)
                    tailored
                    to your interests and/or location and to measure its effectiveness.<br>
                    – Administer prize draws and competitions for our Business Purposes and/or with your Consent. We may
                    use
                    your information to administer prize draws and competitions when you elect to participate in
                    competitions.<br>
                    – Request Feedback for our Business Purposes and/or with your Consent. We may use your information
                    to
                    request feedback and to contact you about your use of our Site.<br>
                    – To protect our Site for Business Purposes and/or Legal Reasons. We may use your information as
                    part of
                    our
                    efforts to keep our Site safe and secure (for example, for fraud monitoring and prevention).<br>
                    – To enable user-to-user communications with your consent. We may use your information in order to
                    enable
                    user-to-user communications with each user’s consent.<br>
                    – To enforce our terms, conditions and policies for our business purposes and as legally
                    required.<br>
                    – To respond to legal requests and prevent harm as legally required. If we receive a subpoena or
                    other
                    legal
                    request, we may need to inspect the data we hold to determine how to respond.<br>
                    – For other Business Purposes. We may use your information for other Business Purposes, such as data
                    analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and
                    to
                    evaluate and improve our Site, products, services, marketing and your experience.</p>
                <p>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
                <p>In Short: We only share information with your consent, to comply with laws, to protect your rights,
                    or to
                    fulfill business obligations.</p>
                <p>We only share and disclose your information in the following situations:</p>
                <p>– Compliance with Laws. We may disclose your information where we are legally required to do so in
                    order
                    to
                    comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
                    process,
                    such as in response to a court order or a subpoena (including in response to public authorities to
                    meet
                    national security or law enforcement requirements).<br>
                    – Vital Interests and Legal Rights. We may disclose your information where we believe it is
                    necessary to
                    investigate, prevent, or take action regarding potential violations of our policies, suspected
                    fraud,
                    situations involving potential threats to the safety of any person and illegal activities, or as
                    evidence in
                    litigation in which we are involved.<br>
                    – Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third
                    party
                    vendors, service providers, contractors or agents who perform services for us or on our behalf and
                    require
                    access to such information to do that work. Examples include: payment processing, data analysis,
                    email
                    delivery, hosting services, customer service and marketing efforts. We may allow selected third
                    parties
                    to
                    use tracking technology on the Site, which will enable them to collect data about how you interact
                    with
                    the
                    Site over time. This information may be used to, among other things, analyze and track data,
                    determine
                    the
                    popularity of certain content and better understand online activity. Unless described in this
                    Policy, we
                    do
                    not share, sell, rent or trade any of your information with third parties for their promotional
                    purposes.<br>
                    – Business Transfers. We may share or transfer your information in connection with, or during
                    negotiations
                    of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
                    business to
                    another company.<br>
                    – Business Partners. We may share your information with our business partners to offer you certain
                    products,
                    services or promotions.<br>
                    – With your Consent. We may disclose your personal information for any other purpose with your
                    consent.<br>
                    – Other Users. When you share personal information (for example, by posting comments, contributions
                    or
                    other
                    content to the Site) or otherwise interact with public areas of the Site, such personal information
                    may
                    be
                    viewed by all users and may be publicly distributed outside the Site in perpetuity.</p>
                <p>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
                <p>In Short: We may use cookies and other tracking technologies to collect and store your
                    information.</p>
                <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                    information. Specific information about how we use such technologies and how you can refuse certain
                    cookies
                    is set out in our Cookie Policy.</p>
                <p>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
                <p>In Short: If you choose to register or log in to our websites using a social media account, we may
                    have
                    access to certain information about you.</p>
                <p>Our Site may offer you the ability to register and login using your third party social media account
                    details
                    (like your Facebook login). Where you choose to do this, we will receive certain profile information
                    about
                    you from your social media provider. The profile Information we receive may vary depending on the
                    social
                    media provider concerned, but will often include your name, e-mail address, friends list, profile
                    picture as
                    well as other information you choose to make public. If you login using Facebook, we may also
                    request
                    access
                    to other permissions related to your account, such as friends, check-ins, and likes, and you may
                    choose
                    to
                    grant or deny us access to each individual permission.</p>
                <p>We will use the information we receive only for the purposes that are described in this privacy
                    policy or
                    that are otherwise made clear to you on the Site. Please note that we do not control, and are not
                    responsible for, other uses of your personal information by your third party social media provider.
                    We
                    recommend that you review their privacy policy to understand how they collect, use and share your
                    personal
                    information, and how you can set your privacy preferences on their sites and apps.</p>
                <p>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
                <p>In Short: We only transfer, store, and process your information in the European Economic Area.</p>
                <p>Our servers are located in the European Economic Area. If you are accessing our Site from outside the
                    European Economic Area, please be aware that your information may be transferred to, stored, and
                    processed
                    by us in our facilities and by those third parties with whom we may share your personal information
                    (see
                    “Will Your Information Be Shared With Anyone?” above), in the European Economic Area.</p>
                <p>If you are not a resident in the European Economic Area, then your country may not have data
                    protection
                    or
                    other laws as comprehensive as those in the European Economic Area. We will however take all
                    necessary
                    measures to protect your personal information in accordance with this privacy policy and applicable
                    law.</p>
                <p>7. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</p>
                <p>In Short: We are not responsible for the safety of any information that you share with third-party
                    providers
                    who advertise, but are not affiliated with, our websites.</p>
                <p>The Site may contain advertisements from third parties that are not affiliated with us and which may
                    link
                    to
                    other websites, online services or mobile applications. We cannot guarantee the safety and privacy
                    of
                    data
                    you provide to any third parties. Any data collected by third parties is not covered by this privacy
                    policy.
                    We are not responsible for the content or privacy and security practices and policies of any third
                    parties,
                    including other websites, services or applications that may be linked to or from the Site. You
                    should
                    review
                    the policies of such third parties and contact them directly to respond to your questions.</p>
                <p>8. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this
                    privacy
                    policy unless otherwise required by law.</p>
                <p>We will only keep your personal information for as long as it is necessary for the purposes set out
                    in
                    this
                    privacy policy, unless a longer retention period is required or permitted by law (such as tax,
                    accounting or
                    other legal requirements). No purpose in this policy will require us keeping your personal
                    information
                    for
                    longer than 90 days past the termination of your account.</p>
                <p>When we have no ongoing legitimate business need to process your personal information, we will either
                    delete
                    or anonymize it, or, if this is not possible (for example, because your personal information has
                    been
                    stored
                    in backup archives), then we will securely store your personal information and isolate it from any
                    further
                    processing until deletion is possible.</p>
                <p>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                <p>In Short: We aim to protect your personal information through a system of organizational and
                    technical
                    security measures.</p>
                <p>We have implemented appropriate technical and organizational security measures designed to protect
                    the
                    security of any personal information we process. However, please also remember that we cannot
                    guarantee
                    that
                    the internet itself is 100% secure. Although we will do our best to protect your personal
                    information,
                    transmission of personal information to and from our Site is at your own risk. You should only
                    access
                    the
                    services within a secure environment.</p>
                <p>10. DO WE COLLECT INFORMATION FROM MINORS?</p>
                <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
                <p>We do not knowingly solicit data from or market to children under 18 years of age.<br>
                    By using the Site, you represent that you are at least 18 or that you are the parent or guardian of
                    such
                    a
                    minor and consent to such minor dependent’s use of the Site.<br>
                    If we learn that personal information from users less than 18 years of age has been collected, we
                    will
                    deactivate the account and take reasonable measures to promptly delete such data from our
                    records.<br>
                    If you become aware of any data we have collected from children under age 18, please contact us at
                    support@evopricing.com</p>
                <p>11. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                <p>In Short: Evo cares about your privacy rights. We allow users to review, change, or terminate their
                    account
                    at any time.</p>
                <p>Account Information</p>
                <p>You may at any time review or change the information in your account or terminate your account
                    by:</p>
                <p>– Logging into your account settings and updating your account<br>
                    – Contacting us using the contact information provided below</p>
                <p>Upon your request to terminate your account, we will deactivate or delete your account and
                    information
                    from
                    our active databases. However, some information may be retained in our files to prevent fraud,
                    troubleshoot
                    problems, assist with any investigations, enforce our Terms of Use and/or comply with legal
                    requirements.</p>
                <p>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
                    prefer,
                    you
                    can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
                    remove
                    cookies or reject cookies, this could affect certain features or services of our Site. To opt-out of
                    interest-based advertising by advertisers on our Site visit http://www.aboutads.info/choices/. [For
                    further
                    information, please see our Cookie Policy]</p>
                <p>Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
                    clicking
                    on
                    the unsubscribe link in the emails that we send or by contacting us using the details provided
                    below.
                    You
                    will then be removed from the marketing email list – however, we will still need to send you
                    service-related
                    emails that are necessary for the administration and use of your account. You can also opt-out
                    by:</p>
                <p>– Noting your preferences at the time you register your account with the Site.<br>
                    – Logging into your account settings and updating your preferences.<br>
                    – Contacting us using the contact information provided below</p>
                <p>12. DO WE RESPOND TO DO-NOT-TRACK SIGNALS?</p>
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    (“DNT”)
                    feature or setting you can activate to signal your privacy preference not to have data about your
                    online
                    browsing activities monitored and collected.<br>
                    No uniform technology standard for recognizing and implementing DNT signals has been finalized.<br>
                    As such, we do not currently respond to DNT browser signals or any other mechanism that
                    automatically
                    communicates your choice not to be tracked online.<br>
                    If a standard for online tracking is adopted that we must follow in the future, we will inform you
                    about
                    that practice in a revised version of this Privacy Policy.</p>
                <p>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
                <p>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access
                    to
                    your
                    personal information.</p>
                <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who
                    are
                    California residents to request and obtain from us, once a year and free of charge, information
                    about
                    categories of personal information (if any) we disclosed to third parties for direct marketing
                    purposes
                    and
                    the names and addresses of all third parties with which we shared personal information in the
                    immediately
                    preceding calendar year. If you are a California resident and would like to make such a request,
                    please
                    submit your request in writing to us using the contact information provided below.</p>
                <p>If you are under 18 years of age, reside in California, and have a registered account with the Site,
                    you
                    have
                    the right to request removal of unwanted data that you publicly post on the Site. To request removal
                    of
                    such
                    data, please contact us using the contact information provided below, and include the email address
                    associated with your account and a statement that you reside in California. We will make sure the
                    data
                    is
                    not publicly displayed on the Site, but please be aware that the data may not be completely or
                    comprehensively removed from our systems.</p>
                <p>14. DO WE MAKE UPDATES TO THIS POLICY?</p>
                <p>In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
                <p>We may update this privacy policy from time to time. The updated version will be indicated by an
                    updated
                    “Revised” date and the updated version will be effective as soon as it is accessible. If we make
                    material
                    changes to this privacy policy, we may notify you either by prominently posting a notice of such
                    changes
                    or
                    by directly sending you a notification. We encourage you to review this privacy policy frequently to
                    be
                    informed of how we are protecting your information.</p>
                <p>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
                <p>If you have questions or comments about this policy, email us at support@evopricing.com or by post
                    to:</p>
                <p>Evo Europe Limited<br>
                    #8479882<br>
                    3rd Floor 207 Regent Street<br>
                    London W1B 3HH<br>
                    United Kingdom</p>

            </div>
            <div class="text-center">
                <a [routerLink]="'/login'" class="btn btn-very-dark-grey text-white">
                    {{'messages.resetting_page.go_back'|translate}}
                </a>
            </div>
        </div>
    </div>
</div>
