import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from '../services/api.service';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';
import qs from 'qs';

@Pipe({
    name: 'avatarUrl'
})
export class AvatarUrlPipe implements PipeTransform {

    constructor(private api: ApiService, private auth: AuthService) {
    }

    transform(id: number, noCache = false): unknown {
        const params: { [key: string]: any } = {};

        if (noCache) {
            params._r = Date.now().toString();
        }

        if (environment.production) {
            return `/team/avatar/${id}/avatar.jpg?${qs.stringify(params)}`;
        }

        return `${this.api.apiURL}/profile/team/${id}/avatar.jpg?${qs.stringify(params)}`;
    }
}
