import {Pipe, PipeTransform} from '@angular/core';
import marked from 'marked';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        if (!value) {
            return null;
        }

        return this.sanitized.bypassSecurityTrustHtml(`<div class="markdown">${marked(value)}</div>`);
    }
}
