<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.workbook_templates_page.edit_title' | translate"
>
    <app-form
        [formConfig]="formConfig"
        [value]="workbookTemplate"
        [hideButtons]="true"
        (submitForm)="editWorkbookTemplate($event)"
    >
    </app-form>
</app-modal>

