import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminSubscriptionsList, DisplayError, ProvidedEntity} from '../../../../../models/models';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {EntityProviderService} from '../../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../../services/form-loading-service.service';
import {ApiService} from '../../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../../services/form-errors-transformer.service';

@Component({
    selector: 'app-request-groups-modal',
    templateUrl: './request-groups-modal.component.html',
    styleUrls: ['./request-groups-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class RequestGroupsModalComponent implements OnInit {

    @Input() subscription: AdminSubscriptionsList;
    @Output() addComponent = new EventEmitter();

    public status: boolean;
    public submitted: boolean;
    public form: FormGroup;
    public errors: DisplayError[] = [];
    public groups: ProvidedEntity[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
        private errorsTransformer: FormErrorsTransformerService
    ) {
    }

    ngOnInit() {
        this.status = false;
        this.submitted = false;
        this.form = this.formBuilder.group({
            groups: this.formBuilder.array([])
        });


        this.formLoadingServiceService.init(1);

        this.apiService.getAvailableGroups().subscribe(data => {
            this.groups = data;
            const groups = this.form.get('groups') as FormArray;

            data.forEach(item => {
                groups.push(this.formBuilder.group({
                    id: [item.id],
                    checked: [false]
                }));
            });

            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    requestGroups(e: any = null) {
        if (e) {
            e.preventDefault();
        }

        if (this.submitted) {
            return;
        }

        this.errors = this.errorsTransformer.getErrors(this.form);

        if (this.form.status !== 'VALID') {
            return;
        }

        this.submitted = true;

        const {groups} = this.form.value;

        const data = {
            requestedGroups: groups.filter(item => item.checked).map(item => (item.id))
        };

        const request = this.apiService.requestAccessToGroups(data);

        request.subscribe(() => {
            this.closeModalWindow();
        }, error => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, error);
            this.submitted = false;
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }


    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

    get formData() {
        return this.form.get('groups') as FormArray;
    }
}
