<div class="form-group">
    <label
        [for]="config.id"
        [style.color]="!isFieldValid? '#a94442' : null"
        class="control-label"
    >{{config.label|translate}}</label>
    <select
        (blur)="touch()"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="config.id"
        [name]="config.name"
        [ngClass]="{'is-invalid' : !isFieldValid}"
        class="form-control"
    >
        <option [value]="''" hidden selected>{{(config.placeholder || '')|translate}}</option>
        <option *ngFor='let option of options; let i = index' [value]="option.id === null ? '' : option.id">{{option.stringified}}</option>
    </select>

    <ng-content>
    </ng-content>
</div>
