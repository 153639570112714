import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-folder-table-tr-info',
    templateUrl: './folder-table-tr-info.component.html',
    styleUrls: ['./folder-table-tr-info.component.scss']
})
export class FolderTableTrInfoComponent implements OnInit {

    @Input() folder: any;
    @Output() openModal = new EventEmitter();
    @Output() updateFolder = new EventEmitter();
    @Output() copyFolder = new EventEmitter();

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        },
        {
            id: 'pages',
            name: 'messages.admin.pages_page.pages',
            type: 'list'
        },
        {
            id: 'groups',
            name: 'messages.admin.users_page.table_column_groups',
            type: 'list'
        }
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
    }

    editFolder() {
        return this.openModal.emit();
    }

    deleteFolder() {
        this.apiService.deleteFolder(this.folder.id).subscribe(response => {
            this.updateFolder.emit();
        });
    }

    toggleActiveFolder() {
        this.apiService.toggleActiveFolder(this.folder.id).subscribe(response => {
            this.updateFolder.emit();
        });
    }

    toggleManagersOnly() {
        this.apiService.toggleFolderManagersOnly(this.folder.id).subscribe(response => {
            this.updateFolder.emit();
        });
    }

    toggleGlobal() {
        this.apiService.toggleFolderGlobal(this.folder.id).subscribe(response => {
            this.updateFolder.emit();
        });
    }

    copy() {
        this.copyFolder.emit();
    }
}
