<div class="evo-card p-4 mb-4">
    <div *ngIf="!note.system" class="d-flex">
        <div class="mr-4 d-none d-sm-block">
            <img
                [alt]="note.author.name"
                [src]="note.author.portal_user_id ? (note.author.portal_user_id|avatarUrl) :note.author.avatar_url"
                class="avatar"
                height="40"
                width="40"
            >
        </div>
        <div class="w-100">
            <ng-container *ngIf="!edit">
                <div class="mb-2">
                    <span><strong>{{note.author.name}}</strong> @{{note.author.username}} {{note.created_at|timeAgo}}</span>
                    <button
                        class="btn btn-primary btn-square btn-sm ml-4"
                        (click)="edit = true"
                        *ngIf="note.author.portal_user_id && note.author.portal_user_id === currentUserId && !edit"
                    >
                        <i class="ion-edit"></i> {{'messages.global.edit'|translate}}
                    </button>
                </div>

                <div [innerHTML]="note.body | markdown" class="content"></div>
                <div
                    class="mt-4"
                    *ngIf="note.updated_at > note.created_at"
                >{{'messages.git_boards.view.updated'|translate}} {{note.updated_at|timeAgo}}</div>
            </ng-container>

            <div *ngIf="edit">
                <app-markdown-editor [formControl]="commentControl" [allowMentions]="true" (mentionChanged)="mentionChangedHandler($event)"></app-markdown-editor>
                <div class="text-right">
                    <button
                        class="btn btn-outline-warning btn-square btn-sm mr-2"
                        (click)="cancel()"
                    >{{'messages.global.cancel'|translate}}</button>
                    <button
                        (click)="update()"
                        [disabled]="!commentControl.value || loading"
                        class="btn btn-square btn-primary btn-sm"
                    >
                        <span
                            *ngIf="loading"
                            aria-hidden="true"
                            class="spinner-border spinner-border-sm mr-2"
                            role="status"
                        ></span> {{'messages.global.submit'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="note.system" class="d-flex align-items-center">
        <div class="mr-4 d-none d-sm-block">
            <img [alt]="note.author.name" [src]="note.author.avatar_url" class="avatar" height="40" width="40">
        </div>
        <div>
            <span><strong>{{note.author.name}}</strong> @{{note.author.username}} {{note.body}} {{note.created_at|timeAgo}}</span>
        </div>
    </div>
</div>
