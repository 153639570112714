<div class="d-flex flex-column align-items-center" style="width: min-content">
    <div class="mb-2" *ngIf="localVideoId">
        <iframe
            *ngIf="videoService === 'youtube'"
            [src]="('https://www.youtube.com/embed/' + localVideoId) | safeResourceUrl"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
        <iframe
            *ngIf="videoService === 'dailymotion'"
            frameborder="0"
            [src]="('https://www.dailymotion.com/embed/video/' + localVideoId +'?queue-enable=false') | safeResourceUrl"
            width="100%"
            allowfullscreen
        ></iframe>
        <iframe
            [src]="('https://player.vimeo.com/video/' + localVideoId) | safeResourceUrl"
            *ngIf="videoService === 'vimeo'"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
        ></iframe>
        <video
            *ngIf="videoService === 'local' && videoSrc"
            [src]="videoSrc | safeResourceUrl"
            controls
        >
        </video>
    </div>
    <div *ngIf="canEdit">
        <button
            class="btn btn-primary btn-sm"
            style="max-width: min-content"
            (click)="startEdit()"
            *ngIf="uploadEnabled"
        >
            {{'messages.profile_page.video.upload'|translate}}
        </button>
    </div>
</div>

<div
    *ngIf="canEdit && !uploadEnabled && disabledMessage"
    class="alert alert-warning"
    role="alert"
    style="max-width: 270px"
>
    {{disabledMessage}}
</div>

<app-modal
    [mTitle]="'messages.profile_page.video.upload'|translate"
    (mClose)="cancelEdit()"
    (mSubmit)="handleFileUpload()"
    [loading]="submitted"
    *ngIf="edit"
>
    <div class="form-style-bordered-inputs">
        <div class="form-group">
            <label class="control-label" for="yt-v-name">{{'messages.profile_page.video.name'|translate}}</label>
            <input
                type="text"
                id="yt-v-name"
                [formControl]="uploadForm.controls.name"
                class="form-control"
            >
            <app-input-errors [control]="uploadForm.controls.name"></app-input-errors>
        </div>

        <div>
            <div class="mb-2">
                <input
                    (input)="handleFileInput($event.target.files)"
                    id="new_issue_file_input"
                    type="file"
                    accept="video/*"
                >
            </div>
        </div>
        <app-input-errors [control]="uploadForm.controls.file"></app-input-errors>
    </div>
</app-modal>
