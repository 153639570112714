<div
    class="search-bar align-items-baseline pr-2"
    [ngClass]="{'d-flex': menu?.sections?.length,'d-none':!menu?.sections?.length}"
>
    <label class="position-relative">
        <input
            type="text"
            [placeholder]="'messages.admin.search'|translate"
            (input)="searchInputChanged.next($event.target.value)"
            #search
            autocomplete="off"
        >
        <i
            *ngIf="search.value"
            class="ion-close"
            style="position: absolute; right: 15px; top: 50%;transform: translateY(-50%); color: #FFFFFF; cursor: pointer"
            (click)="cleanSearch(search)"
        ></i>
    </label>
    <div *ngIf="authService.isContentManager() || authService.isEvoEmployee()">
        <button type="button" class="btn btn-primary btn-sm btn-square margin-left-10 margin-bottom-0 margin-top-0" style="height: 48px;" (click)="addContent = true">
            <i class="icon icon-size-7 ion-android-add-circle"></i>
        </button>
    </div>
</div>

<div style="overflow-y: hidden; height: calc(100% - 47px)">
    <app-scrollbar>
        <div>
            <accordion
                [isAnimated]="true"
                [closeOthers]="true"
            >
                <ng-container *ngIf="menu?.sections?.length && !searchValue">
                    <app-menu-item *ngFor="let section of menu.sections" [section]="section"></app-menu-item>
                </ng-container>
                <app-global-pages [ngClass]="{'d-none':searchValue}"></app-global-pages>
<!--                Deprecated-->
<!--                <app-company-onboarding *ngIf="authService.canSeeOnboarding() && hideOnProd()" [ngClass]="{'d-none':searchValue}"></app-company-onboarding>-->
<!--                <a-->
<!--                    *ngIf="authService.isCompanyOwner() && hideOnProd()"-->
<!--                    routerLink="/app/company-plans"-->
<!--                    class="text-break menu-link"-->
<!--                >{{'messages.menu.plans'|translate}}</a>-->
<!--                <app-courses-menu [ngClass]="{'d-none':searchValue}" *ngIf="authService.getLoggedUser().moodle_webservice_available"></app-courses-menu>-->
<!--                <app-board-menu-->
<!--                    [ngClass]="{'d-none':searchValue}"-->
<!--                    *ngIf="authService.getLoggedUser().board_enabled"-->
<!--                ></app-board-menu>-->
<!--                <a-->
<!--                    *ngIf="authService.isCompanyOwner() || authService.isAdmin()"-->
<!--                    routerLink="/app/portal-n-addin-stats"-->
<!--                    class="text-break menu-link"-->
<!--                >{{'messages.menu.stats'|translate}}</a>-->
            </accordion>

            <div class="search-results text-break" *ngIf="searchValue">
                <ng-container *ngIf="searchResults?.length">
                    <a
                        *ngFor="let res of searchResults"
                        [routerLink]="['reports/show/'+res.url]"
                        (click)="handleClick(search, $event.target)"
                        class="text-break menu-link"
                    >
                        {{res.name}}
                    </a>
                </ng-container>
                <div *ngIf="!searchResults?.length" class="p-3 wave-ui-body wave-ui-positive-disabled">
                    {{'messages.global.no_results'|translate}}
                </div>
            </div>
        </div>
    </app-scrollbar>
</div>


<app-content-type-handbook
    (update)="handleSubmit($event)"
    [withFolder]="true"
    *ngIf="addContent"
>
</app-content-type-handbook>
