<div [ngSwitch]="field.type" class="mb-3">
    <app-input-text
        *ngSwitchCase="'text'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [submitted]="submitted"
    >
    </app-input-text>

    <app-auto-height-textarea
        *ngSwitchCase="'textarea'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [submitted]="submitted"
    >
    </app-auto-height-textarea>

    <app-html-editor
        *ngSwitchCase="'html-editor'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [submitted]="submitted"
    >
    </app-html-editor>

    <app-datepicker
        *ngSwitchCase="'date'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
    ></app-datepicker>

    <app-typeahead-input
        *ngSwitchCase="'typeahead'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [options]="options || []"
    >
    </app-typeahead-input>

    <app-select
        *ngSwitchCase="'select'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [options]="options || []"
    >
    </app-select>

    <app-provided-entity-select
        *ngSwitchCase="'provided-entity-select'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
        [options]="options || []"
    >
    </app-provided-entity-select>

    <app-checkbox
        *ngSwitchCase="'checkbox'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
    >
    </app-checkbox>

    <app-markdown
        *ngSwitchCase="'markdown'"
        [config]="field"
        [formControl]="form"
        [isFieldValid]="isFieldValid"
    >
    </app-markdown>

</div>
