<ng-container *ngIf="showModal">
    <div class="fade modal-backdrop show" style="z-index: 1040;"></div>
    <div class="modal fade show modal-confirm" style="z-index: 1050; display: block">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header text-left">
                    <h3 class="modal-title">
                        <span>{{'messages.global.confirm'|translate}}</span>
                    </h3>
                </div>
                <div class="modal-body text-left">
                    {{confirmMessage || ('messages.global.confirm_message'|translate)}}
                </div>
                <div class="modal-footer">
                    <img alt="Loading" class="float-left" hidden src="./../../../../../assets/img/loading.gif" style/>
                    <button
                        (click)="confirm()"
                        class="btn btn-lg btn-primary"
                    >{{'messages.global.ok'|translate}}</button>
                    <button
                        (click)="closeModalWindow()"
                        class="btn btn-lg btn-default"
                        type="button"
                    >{{'messages.global.cancel'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<span class="confirm-container">
    <span class="confirm-overlay" (click)="openModal($event)"></span>
    <span #wrapper>
        <ng-content>
        </ng-content>
    </span>
</span>
