<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.subscriptions_page.logs_page.title'|translate"
        search="true"
        (paginateFilterSort)="paginateFilterSort($event)"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-20">{{'messages.admin.messages_page.table_column_to'|translate}}</th>
                <th class="col-20">CC</th>
                <th class="col-20">BCC</th>
                <th class="col-20">{{'messages.admin.subscriptions_page.logs_page.table.th.sent_at'|translate}}</th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr>
                <td>{{entity.recipients.to}}</td>
                <td>{{entity.recipients.cc ? entity.recipients.cc.join(', ') : ''}}</td>
                <td>{{entity.recipients.bcc ? entity.recipients.bcc.join(', ') : ''}}</td>
                <td>{{entity.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        (paginateFilterSort)="paginateFilterSort($event)"
        [withExport]="false"
    ></app-table-footer>
</div>
