<app-modal
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [loading]="form?.submitted"
    [mTitle]="content ? 'Edit news' : 'Add news'"
    [wideVariant]="!content?.source"
>
    <app-form
        (submitForm)="updateContent($event)"
        [formConfig]="formConfig"
        [hideButtons]="true"
        [lists]="lists"
        [loading]="formLoadingServiceService.isLoading()"
        [value]="value"
    >
        <div class="mb-4">
            Focus editor and press CTRL+SHIFT+F to enter/exit fullscreen mode <br>
            Alt+0 for more shortcuts
        </div>

        <div *ngIf="content?.image?.id">
            <img
                [alt]="content.image.caption"
                [src]="'/static-content/news-preview/' + content.id + '.jpg'|apiKey:true"
                class="img-thumbnail"
                style="max-width: 300px; max-height: 300px;"
            >
        </div>


        <div *ngIf="fileControl" class="form-group">
            <label for="exampleFormControlFile1">{{'Preview image'|translate}}</label>
            <input
                (change)="fileControl.setValue($event.target.files.length ? $event.target.files[0] : null)"
                accept="image/png,image/jpeg"
                class="form-control-file"
                id="exampleFormControlFile1"
                type="file"
            >
            <div *ngIf="fileControl.invalid" class="help-block mt-2">
                <ul class="list-unstyled">
                    <li *ngIf="fileControl.errors.invalid_mime">
                        <i class="ion-android-alert"></i>&nbsp;
                        <span>This file type is not allowed!!</span>
                    </li>
                </ul>
            </div>
            <button
                (click)="fileControl.setValue(null)"
                *ngIf="fileControl.value"
                class="mt-2 btn btn-xs btn-danger"
                type="button"
            ><i
                class="icon ion-ios-trash"
            ></i> {{'messages.global.delete'|translate}}</button>
        </div>
    </app-form>
</app-modal>
