import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-message-table-tr-info',
    templateUrl: './message-table-tr-info.component.html',
    styleUrls: ['./message-table-tr-info.component.scss']
})
export class MessageTableTrInfoComponent implements OnInit {

    @Input() message: any;
    @Output() openModal = new EventEmitter();
    @Output() refresh = new EventEmitter();
    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'body',
            name: 'messages.admin.messages_page.table_column_body',
            type: 'string'
        },
        {
            id: 'users',
            name: 'messages.admin.messages_page.table_column_to',
            type: 'list'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        }
    ];

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    editMessage() {
        return this.openModal.emit();
    }

    delete() {
        this.apiService.deleteMessage(this.message.id).subscribe(() => {
            this.refresh.emit();
        });
    }
}
