import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminContentsList, Page, SearchResult, SidebarLeftMenuModel} from '../../../models/models';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {ReportsService} from '../../../services/reports.service';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ApiService} from '../../../services/api.service';

@Component({
    selector: 'app-public-menu',
    templateUrl: './public-menu.component.html',
    styleUrls: ['./public-menu.component.scss']
})
export class PublicMenuComponent implements OnInit, OnDestroy {

    @Input() menu: SidebarLeftMenuModel;
    public searchResults: SearchResult[] = [];
    public searchValue = null;
    public addContent = false;
    public searchInputChanged = new Subject<string>();

    private req: Subscription = null;

    constructor(
        public authService: AuthService,
        public router: Router,
        private reportsService: ReportsService,
        private api: ApiService
    ) {
    }

    ngOnDestroy(): void {
        this.searchInputChanged.complete();
    }

    ngOnInit(): void {
        this.searchInputChanged.pipe(debounceTime(500)).subscribe((value) => {
            if (!this.menu) {
                this.searchValue = value;
                return;
            }

            if (!value) {
                this.searchResults = [];
                this.searchValue = value;
                return;
            }

            if (this.req) {
                this.req.unsubscribe();
            }

            this.req = this.api.searchPage(value).subscribe(data => {
                this.searchValue = value;
                this.searchResults = data;
            }, () => {
                this.searchValue = value;
            });
        });
    }

    handleClick(searchEl, aEl) {
        searchEl.value = null;
        this.searchValue = null;
        this.searchResults = [];

        // const href = aEl.attributes.getNamedItem('href').value;
        //
        // setTimeout(() => {
        //     const link = document.querySelector(`a[href="${href}"]`);
        //
        //     if (link) {
        //         link.scrollIntoView({behavior: 'smooth', block: 'center'});
        //     }
        // });
    }

    handleSubmit(content: AdminContentsList) {
        if (content) {
            const url = '/app/reports/show/' + content.pages[0].folder.slug + '/' + content.pages[0].slug + '.report';
            this.router.navigateByUrl(url);
        }

        this.addContent = false;
        this.reportsService.reportsSubject.next();
    }

    cleanSearch(el: HTMLInputElement) {
        el.value = null;
        el.focus();

        if (this.req) {
            this.req.unsubscribe();
            this.req = null;
        }

        this.searchResults = [];
        this.searchValue = null;

        this.searchInputChanged.next(null);
    }

    hideOnProd() {
        return window.location.href.indexOf('https://evouser.com') === -1;
    }
}
