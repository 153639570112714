import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class IsGrantedGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (!this.auth.isAuthenticated()) {
            const queryParams = {
                redirect: this.router.getCurrentNavigation().finalUrl.toString()
            };

            this.auth.redirectCount++;
            this.router.navigate(['login'], {queryParams});

            return false;
        }

        if (next.data?.isEvoEmployee) {
            return this.auth.isEvoEmployee();
        }

        if (next.data?.canSeeOnboarding) {
            if (this.auth.canSeeOnboarding()) {
                return true;
            }
            this.router.navigate(['/404']);

            return false;
        }

        if (next.data?.role && next.data?.isCompanyOwner) {
            if (this.auth.isGranted(next.data.role)) {
                return true;
            }

            if (!this.auth.isCompanyOwner()) {
                this.router.navigate(['/404']);

                return false;
            }

            return true;
        }

        if (!this.auth.isGranted(next.data.role)) {
            this.router.navigate(['/404']);

            return false;
        }

        return true;
    }

}
