import {Component, Input, OnInit} from '@angular/core';
import {MenuSection, Page} from '../../../models/models';
import {AuthService} from '../../../services/auth.service';
import {ReportsService} from '../../../services/reports.service';
import {SidebarLeftService} from '../sidebar-left.service';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

    @Input() section: MenuSection;
    @Input() level = 0;

    constructor(
        public auth: AuthService,
        public reportsService: ReportsService,
        private sidebarLeftService: SidebarLeftService,
        private api: ApiService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    editFolder(event: Event, id: any) {
        event.preventDefault();
        event.stopPropagation();

        this.api.getFolder(id).subscribe(data => {
            this.sidebarLeftService.nextFolder(data);
        });
    }

    editPage(event: Event, id: any) {
        event.preventDefault();
        event.stopPropagation();

        this.api.getPage(id).subscribe(data => {
            this.sidebarLeftService.nextPage(data);
        });
    }


    handlePageClick(e, page: Page) {
        e.preventDefault();

        if (page.url?.match(/^http[s]?\:\/\//)) {
            window.location.href = page.url;
            return;
        }

        this.router.navigateByUrl(page.url || '/app/reports/show/' + page.folder.slug + '/' + page.slug + '.report');
    }
}
