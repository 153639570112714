import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormLoadingServiceService {
    loading = false;
    requestsLoaded = 0;
    allRequestsCount = 0;
    private loadingSubject = new Subject();

    constructor() {
        this.loadingSubject.subscribe(() => {
            this.requestsLoaded++;
            if (this.requestsLoaded === this.allRequestsCount) {
                this.loading = false;
            }
        });
    }

    init(numberOfRequests: number) {
        this.loading = true;
        this.requestsLoaded = 0;
        this.allRequestsCount = numberOfRequests;
    }

    isLoading() {
        return this.loading;
    }

    eventLoaded() {
        this.loadingSubject.next();
    }
}
