import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {FormErrors} from '../../../models/models';
import PropertyAccessor from '../../../helpers/PropertyAccessor';

const defaultErrors = {
    required: 'This field is required',
    mustMatch: 'This field must match',
    email: 'You need to provide a valid email',
    minlength: 'Field length is less then {{requiredLength}} chars',
    maxlength: 'Field length is more then {{requiredLength}} chars',
    pattern: 'The field does not match the required format',
    invalid_mime: 'This file type is not supported',
    maxFileSize: 'The file size can not be more than {{maxValue}}MiB',
    greaterThan: 'This value should be greater than "{{targetValue}}"',
    allowance: 'You have {{days}} allowed days left in {{year}}',
    lowerThanValue: 'This value should be lower than "{{targetValue}}"',
    min: 'This value should greater or equal to "{{min}}"',
    max: 'This value should lower or equal to "{{max}}"',
};

@Injectable({
    providedIn: 'root'
})
export class FormErrorsHandlerService {

    constructor() {
    }

    getErrors(form: FormGroup | FormArray) {
        const errors: FormErrors = {};

        for (const [key, control] of Object.entries(form.controls)) {
            if (!control.errors) {
                continue;
            }

            for (const [errorKey, errorObject] of Object.entries(control.errors)) {
                if (errorObject) {
                    if (!errors[key]) {
                        errors[key] = [];
                    }

                    errors[key].push(this.getErrorMessage(errorKey, errorObject));
                }
            }
        }

        return errors;
    }

    getErrorsFromResponse(form: FormGroup, errors: FormErrors) {
        const mappedErrors: FormErrors = {};
        const unmappedErrors: string[] = [];

        for (const [key, errList] of Object.entries(errors)) {
            if (PropertyAccessor.hasValue(form.value, key)) {
                mappedErrors[key] = errList;
            } else {
                unmappedErrors.push(...errList);
            }
        }

        if (!mappedErrors._global) {
            mappedErrors._global = [];
        }

        mappedErrors._global.push(...unmappedErrors);

        return mappedErrors;
    }

    getErrorMessage(error: string, options: any): string {
        let message = defaultErrors[error] || error;
        Object.entries(options).forEach(([key, value]) => {
                message = message.replace(`{{${key}}}`, value.toString());
            }
        );

        return message;
    }

    getNestedErrors(form: FormGroup | FormArray) {
        const errors: FormErrors = {};

        for (const [key, control] of Object.entries(form.controls)) {
            if (control.status === 'VALID') {
                continue;
            }

            if ((control instanceof FormGroup || control instanceof FormArray)) {
                const childrenErrors = this.getNestedErrors(control);

                for (const [errKey, errs] of Object.entries(childrenErrors)) {
                    if (!errors[key + '.' + errKey]) {
                        errors[key + '.' + errKey] = [];
                    }

                    errors[key + '.' + errKey].push(...errs);
                }

                continue;
            }

            if (!control.errors) {
                continue;
            }

            for (const [errorKey, errorObject] of Object.entries(control.errors)) {
                if (errorObject) {
                    if (!errors[key]) {
                        errors[key] = [];
                    }

                    errors[key].push(this.getErrorMessage(errorKey, errorObject));
                }
            }
        }

        return errors;
    }
}
