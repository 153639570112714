<app-modal
    [loading]="submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="addVersion(null)"
    [mTitle]="(version ? 'messages.admin.xls_page.form.version.title_edit' : 'messages.admin.xls_page.form.version.title_create')|translate"
>
    <form name="myForm" (ngSubmit)="addVersion($event)" [formGroup]="form" role="form" autocomplete="off" novalidate="novalidate" class="form-style-labels-inside-inputs-gray form-condensed">
        <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>
        <div class="form-group">
            <label class="control-label" [style.color]="submitted && isFieldValid('description') && '#a94442'" for="excel_version_caption">
                {{'messages.admin.xls_page.form.version.fields.caption.label'|translate}}
            </label>
            <textarea id="excel_version_caption" class="form-control" formControlName="description"></textarea>
        </div>
        <app-display-input-errors [errorsStrings]="getFieldErrors('description')"></app-display-input-errors>
        <div class="form-group">
            <label class="control-label" [style.color]="submitted && isFieldValid('version') && '#a94442'" for="excel_version_version">
                {{'messages.admin.xls_page.form.version.fields.version.label'|translate}}
            </label>
            <input type="text" id="excel_version_version" class="form-control" formControlName="version">
        </div>
        <app-display-input-errors [errorsStrings]="getFieldErrors('version')"></app-display-input-errors>
    </form>
</app-modal>
