<div class="markdown-editor card mb-4">
    <div class="card-header d-flex flex-wrap">
        <div class="btn-toolbar">
            <span
                (click)="showEditor = true"
                [ngClass]="{active: showEditor}"
                class="mr-1"
            >{{'messages.git_boards.markdown.write'|translate}}</span>
            <span
                (click)="showEditor = false"
                [ngClass]="{active: !showEditor}"
            >{{'messages.git_boards.markdown.preview'|translate}}</span>
        </div>
        <div *ngIf="showEditor" class="btn-toolbar markdown-toolbar ml-md-auto">
            <button
                (click)="$event.preventDefault();$event.stopPropagation();setBold()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="bold"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setItalic()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="italic"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setQuote()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="quote"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setCode()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="code"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setUrl()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="link"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setBulletList()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="list-bulleted"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setNumberedList()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="list-numbered"></app-gl-icon>
            </button>

            <button
                (click)="$event.preventDefault();$event.stopPropagation();setAddList()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="list-task"></app-gl-icon>
            </button>


            <button
                (click)="$event.preventDefault();$event.stopPropagation();setTable()"
                class="btn btn-square btn-sm"
            >
                <app-gl-icon icon="table"></app-gl-icon>
            </button>
        </div>
    </div>

    <div *ngIf="showEditor" class="card-body">
            <textarea
                #editor
                *ngIf="allowMentions"
                (drop)="handleFileDrop($event)"
                [(ngModel)]="value"
                [placeholder]="('messages.git_boards.markdown.placeholder'|translate) + '...'"
                (itemSelected)="onItemSelected($event)"
                [mention]="mentionItems"
                [mentionConfig]="{labelKey:'stringified'}">
            ></textarea>
            <textarea
                #editor
                *ngIf="!allowMentions"
                (drop)="handleFileDrop($event)"
                [(ngModel)]="value"
                [placeholder]="('messages.git_boards.markdown.placeholder'|translate) + '...'">
                ></textarea>
    </div>

    <div *ngIf="!showEditor" [innerHTML]="value|markdown" class="card-body">
    </div>

    <div class="card-footer text-right" *ngIf="showEditor">
        <label
            for="new_issue_file_input"
            style="cursor: pointer;margin:0"
        ><i class="icon ion-document mr-2"></i>{{'messages.git_boards.markdown.attach_file'|translate}}</label>
        <input
            (input)="handleFileInput($event)"
            id="new_issue_file_input"
            multiple
            style="display: none"
            type="file"
            [accept]="accept"
        >
    </div>
</div>
