import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FieldsService} from '../../services/fields.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DisplayError} from '../../models/models';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        FieldsService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true
        }
    ]
})
export class InputTextComponent implements OnInit, ControlValueAccessor {
    public field;
    public value;
    public currentErrors: string[];

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() isFieldValid: boolean;
    @Input() disabled: boolean;
    @Input() withLabel = true;

    constructor(public fieldService: FieldsService) {
    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.field = this.fieldService.getUserField(this.fieldName, this.formName);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

}
