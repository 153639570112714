import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {ApiService} from '../../../services/api.service';
import {ProfileSecurityService} from '../../../services/profile-security.service';
import {EvoChart} from './EvoChart';
import {ScreenshotService} from '../../../services/screenshot.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-org-chart',
    templateUrl: './org-chart.component.html',
    styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent implements OnInit {

    private chart: EvoChart;
    public editMode = false;
    @ViewChild('orgChart') orgChart: ElementRef;

    constructor(private layoutConfigService: LayoutConfigService,
                private apiService: ApiService,
                private profileSecurityService: ProfileSecurityService,
                private ngZone: NgZone,
                private screenshotService: ScreenshotService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        this.chart = new EvoChart(this.profileSecurityService.isCompanyOwner(), this.screenshotService, this.toastrService);

        const onDrop = (sender, draggedNodeId, droppedNodeId) => {
            this.apiService.setUserChildOf(draggedNodeId, droppedNodeId).subscribe((data) => {
                // @ts-ignore
                if (data.reload === true) {
                    this.apiService.getOrganizationChart().subscribe(chartData => {
                        this.ngZone.runOutsideAngular(() => {
                            this.chart.fillData(chartData);
                        });
                    });
                }
            });
        };

        const onEdit = () => {
            this.ngZone.run(() => {
                this.editMode = !this.editMode;
            });
        };

        setTimeout(async () => {
                if (this.layoutConfigService.sidebarLeft) {
                    this.layoutConfigService.sidebarLeftToggle();
                }

                const el = this.orgChart.nativeElement;
                const data = await this.apiService.getOrganizationChart().toPromise();

                this.ngZone.runOutsideAngular(() => {
                    this.chart.init(el, onDrop, onEdit);
                    this.chart.fillData(data);
                });
            }
        );
    }
}
