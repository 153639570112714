import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {AuthService} from '../../../../../services/auth.service';

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {

    public currentId = null;
    public url: string = null;

    @Input() set id(value) {
        this.currentId = value;
        this.url = environment.apiURL + '/anon/presentation/' + value + '?api_key=' + this.auth.getApiKey();
    }

    constructor(private auth: AuthService) {
    }

    ngOnInit(): void {
    }

    onResize() {
        document.getElementById(`iframe-html-${this.currentId}`).style.height = (document.body.scrollHeight - 150).toString() + 'px';
    }

    onLoad() {
        document.getElementById(`loading-${this.currentId}`).style.display = 'none';
        const iframe = document.getElementById(`iframe-html-${this.currentId}`);

        if (iframe !== null) {
            iframe.style.display = 'block';
            iframe.style.height = (document.body.scrollHeight - 150).toString() + 'px';
        }
    }
}
