import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {HttpCancelService} from '../services/http-cancel.service';
import {GlobalErrorService} from '../services/global-error.service';
import {Injectable} from '@angular/core';

@Injectable()
export class RouteChangeInterceptor implements HttpInterceptor {
    private loaded = false;
    private ignoredUrls = [
        '/translations/',
        '/ping',
        '/me',
        '/last-session',
        '/reports',
        '/sidebar'
    ];

    constructor(router: Router,
                private httpCancelService: HttpCancelService,
                private globalErrorService: GlobalErrorService
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                globalErrorService.setError('');
                // Cancel pending calls
                if (this.loaded) {
                    this.httpCancelService.cancelPendingRequests();
                } else {
                    this.loaded = true;
                }
            }
        });
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        for (const url of this.ignoredUrls) {
            if (req.url.includes(url)) {
                return next.handle(req);
            }
        }

        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
}
