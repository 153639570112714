<div class="text-center" style="margin-top: 20vw;" *ngIf="reportIsLoading">
    <img src="../../../../assets/img/loading.gif" alt="Loading"/>
    <p>{{'messages.global.loading'|translate}}</p>
</div>

<app-not-found *ngIf="reportSelected && !reportIsLoading && !reportData"></app-not-found>

<div *ngIf="reportsService.getPagesCount() > 1 && !reportIsLoading && !reportSelected">
    <h4 class="alert alert-warning alert-light-warning wave-ui-headline" style="display:flex; align-items: center">
        <i class="ion-android-alert"></i>
        {{'messages.web.report_page.select_report'|translate}}
    </h4>
</div>

<div *ngIf="reportsService.getPagesCount() == 0 && !reportIsLoading && !reportSelected">
    <h4 class="alert alert-danger">
        <i class="ion-android-alert"></i>
        {{(reportsService.getGroupsCount() < 1
            ? 'messages.web.report_page.no_groups_assigned'
            : 'messages.web.report_page.no_report_assigned'
    )|translate}}
    </h4>
</div>

<div
    [ngStyle]="{'width': width, 'height': height}"
    *ngIf="!reportIsLoading && reportSelected"
    [ngClass]="{'handbook-container': hasHandbooks}"
>
    <div class="alert alert-info border-0 manager-only" *ngIf="reportData?.managers_only === true && managerOnlyVisible === true">
        <i class="icon ion-flag mr-2 icon-size-10"></i>
        {{'messages.web.managers_only_content'|translate}}
        <div class="float-right">
            <a (click)="hideManagerOnlyNotification()">
                <i class="icon ion-close mr-2 icon-size-10"></i>
            </a>
        </div>
    </div>
    <div class="main-page-title" *ngIf="!hasHandbooks && title">{{ title }}</div>
    <div class="main-page-subtitle" *ngIf="subtitle">{{ subtitle }}</div>

    <ng-container *ngFor="let content of reportData?.contents">
        <ng-container *ngIf="auth.isContentManager() || (auth.isEvoEmployee() && auth.getLoggedUser().id === reportData.creator?.id)">
            <a
                class="btn btn-primary btn-sm float-right btn-square edit-content"
                [routerLink]="'/admin/contents/manage/'+ content.id"
                *ngIf="content.type =='dashboard'"
                (click)="setEditContent(content)"
            ><i class="icon ion-edit"></i> {{'messages.admin.contents_page.form.content.title_edit'|translate}}</a>

            <div
                class="btn btn-primary btn-sm float-right btn-square edit-content"
                *ngIf=" content.type !='dashboard'"
                (click)="setEditContent(content)"
            >
                <i class="icon ion-edit"></i> {{'messages.admin.contents_page.form.content.title_edit'|translate}}
            </div>
        </ng-container>

        <ng-container *ngIf="content.title">
            <h2 class="content-title" *ngIf="content.type=='handbook'">{{ content.title }}</h2>
            <div class="content-title" *ngIf="content.type!='handbook'">{{ content.title }}</div>
        </ng-container>

        <div
            *ngIf="content.type=='dashboard'"
            class="dc-data-count dc-chart"
            style="margin-left: 10px; margin-top: 17px;margin-bottom: 15px;"
            #dataCount
            (click)="resetAll()"
        >{{'messages.web.dashboards.records_selected' | translate}}</div>
        <div class="clearfix"></div>

        <div class="content-subtitle" *ngIf="content.subtitle">{{ content.subtitle }}</div>

        <app-html *ngIf="content.type=='html'" [html]="content.ngx_content">Html</app-html>
        <app-markdown *ngIf="content.type=='markdown'" [html]="content.ngx_content">Markdown</app-markdown>
        <app-handbook *ngIf="content.type=='handbook'" [content]="content" [creator]="reportData.creator">Handbook
        </app-handbook>
        <app-dashboard *ngIf="content.type=='dashboard'" [content]="content">Dashboard</app-dashboard>
        <app-iframe *ngIf="content.type=='shiny'" [id]="content.id" [url]="content.ngx_content">Shiny</app-iframe>
        <app-dynamic-html *ngIf="content.type=='dynamic-html'" [html]="content.ngx_content">Dynamic Html
        </app-dynamic-html>

        <app-iframe *ngIf="content.type=='iframe-html'" [id]="content.id" [url]="content.ngx_content">Iframe
        </app-iframe>
        <app-presentation *ngIf="content.type=='presentation'" [id]="content.id">Presentation</app-presentation>
        <app-network-share *ngIf="content.type=='network-share'" [initialFolder]="content.remote_id">Network Share
        </app-network-share>

        <app-course *ngIf="content.type=='course'" [courseId]="content.remote_id" [creator]="reportData.creator">
        </app-course>

        <app-web-component *ngIf="content.type=='web-app'" [content]="content.ngx_content">
        </app-web-component>

        <div *ngIf="content.type.indexOf('chart') >= 0" style="margin-top: 10px">
            <strong style="color: #b22222;">
                {{'messages.web.report_page.not_supported'|translate}}
            </strong>
        </div>
        <div *ngIf="content.type=='r-table'" style="margin-top: 10px">
            <strong style="color: #b22222">
                {{'messages.web.report_page.not_supported'|translate}}
            </strong>
        </div>
        <div *ngIf="content.type=='table'" style="margin-top: 10px">
            <strong style="color: #b22222">
                {{'messages.web.report_page.not_supported'|translate}}
            </strong>
        </div>
        <div *ngIf="content.type=='ssrs'" style="margin-top: 10px">
            <strong style="color: #b22222">
                {{'messages.web.report_page.not_supported'|translate}}
            </strong>
        </div>
    </ng-container>

    <app-comments *ngIf="reportData?.comments_enabled">
    </app-comments>
</div>

<ng-container *ngIf="editContent && (auth.isContentManager() || (auth.isEvoEmployee() && auth.getLoggedUser().id === reportData.creator?.id))">
    <div [ngSwitch]="editContent.type">
        <app-content-type-html-modal
            *ngSwitchCase="'html'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-html-modal>
        <app-content-type-dynamic-html-modal
            *ngSwitchCase="'dynamic-html'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-dynamic-html-modal>
        <app-content-type-iframe-modal
            *ngSwitchCase="'iframe-html'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-iframe-modal>
        <app-content-type-shiny-modal
            *ngSwitchCase="'shiny'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-shiny-modal>
        <app-content-type-network-share-modal
            *ngSwitchCase="'network-share'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-network-share-modal>
        <app-content-type-handbook
            *ngSwitchCase="'handbook'"
            [content]="editContent"
            (update)="handleModalClose()"
            [withFolder]="(auth.isEvoEmployee() && auth.getLoggedUser().id === reportData.creator?.id) || auth.isAdmin()"
            [page]="reportData.id"
        ></app-content-type-handbook>
        <app-content-type-course-modal
            *ngSwitchCase="'course'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-course-modal>
        <app-content-type-markdown
            *ngSwitchCase="'markdown'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-markdown>
        <app-content-type-presentation
            *ngSwitchCase="'presentation'"
            [content]="editContent"
            (update)="handleModalClose()"
        ></app-content-type-presentation>
        <app-content-type-web-app
            *ngSwitchCase="'web-app'"
            [content]="editContent"
            (update)="handleModalClose()"
        >
        </app-content-type-web-app>
    </div>
</ng-container>

