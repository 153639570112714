import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarRightComponent} from './sidebar-right.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonAppModule} from '../common-app.module';
import {SettingsComponent} from './settings/settings.component';
import {StartUpSettingsComponent} from './settings/start-up-settings/start-up-settings.component';
import {TranslateModule} from '@ngx-translate/core';
import {ScheduledDeliveriesComponent} from './settings/scheduled-deliveries/scheduled-deliveries.component';
import {ProfileSubscriptionModalComponent} from './settings/scheduled-deliveries/profile-subscription-modal/profile-subscription-modal.component';
import {MembershipComponent} from './settings/membership/membership.component';
import {RequestGroupsModalComponent} from './settings/membership/request-groups-modal/request-groups-modal.component';
import {MessagesComponent} from './messages/messages.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {RouterModule} from '@angular/router';
import {ThemeComponent} from './settings/theme/theme.component';
import {ScrollbarModule} from '../../modules/scrollbar/scrollbar.module';

@NgModule({
    declarations: [
        SidebarRightComponent,
        SettingsComponent,
        StartUpSettingsComponent,
        ScheduledDeliveriesComponent,
        ProfileSubscriptionModalComponent,
        MembershipComponent,
        RequestGroupsModalComponent,
        MessagesComponent,
        ThemeComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CommonAppModule,
        TranslateModule,
        AccordionModule,
        RouterModule,
        ScrollbarModule,
    ],
    exports: [
        SidebarRightComponent
    ]
})
export class SidebarRightModule {
}
