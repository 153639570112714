import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminSubscriptionsList, DisplayError, ProvidedEntity, SelectValue} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import * as moment from 'moment';

@Component({
    selector: 'app-subscription-modal',
    templateUrl: './subscription-modal.component.html',
    styleUrls: ['./subscription-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class SubscriptionModalComponent implements OnInit {

    @Input() subscription: AdminSubscriptionsList;
    @Output() addComponent = new EventEmitter();

    public formName: string;
    public status: boolean;
    public submitted: boolean;
    public form: FormGroup;
    public count;

    public subscribers: ProvidedEntity[] = [];
    public pages: ProvidedEntity[] = [];
    public formats: SelectValue[] = [];
    public timezones: SelectValue[] = [];
    public typesCC: SelectValue[] = [];
    public types: SelectValue[] = [];
    public timeHours: SelectValue[] = [];
    public timeMinutes: SelectValue[] = [];


    public days: SelectValue[] = [];
    public errors: DisplayError[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
        private errorsTransformer: FormErrorsTransformerService
    ) {
    }

    ngOnInit() {
        this.status = false;
        this.formName = 'subscriptionsFields';
        this.count = [];
        this.submitted = false;
        this.form = this.formBuilder.group({
            subscriber: ['', Validators.required],
            report: ['', Validators.required],
            email: ['', Validators.required],
            name: ['', Validators.required],
            type: ['', Validators.required],
            dayOfDelivery: [''],
            hourOfDelivery: ['', Validators.required],
            minuteOfDelivery: ['', Validators.required],
            timeZone: ['', Validators.required],
            format: ['', Validators.required],
            startDate: [''],
            endDate: [''],
            followers: this.formBuilder.array([])
        });

        const {subscription} = this;

        if (subscription) {
            this.form.get('subscriber').setValue(subscription.subscriber.id);
            this.form.get('report').setValue(subscription.report.id);
            this.form.get('email').setValue(subscription.email);
            this.form.get('name').setValue(subscription.name);
            this.form.get('type').setValue(subscription.type);
            this.form.get('dayOfDelivery').setValue(subscription.day_of_delivery_value);
            this.form.get('hourOfDelivery').setValue(subscription.hour_of_delivery);
            this.form.get('minuteOfDelivery').setValue(subscription.minute_of_delivery);
            this.form.get('timeZone').setValue(subscription.time_zone);
            this.form.get('format').setValue(subscription.format);
            this.form.get('startDate').setValue(subscription.start_date);
            this.form.get('endDate').setValue(subscription.end_date);

            subscription.followers.forEach(follower => {
                this.addFollower(follower);
            });
        }

        this.formLoadingServiceService.init(4);

        this.entityProvider.getTimezones().subscribe((data: SelectValue[]) => {
            this.timezones = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getSubscriptionFormConfig().subscribe(data => {
            this.formats = data.formats;
            this.days = data.days;
            this.timeHours = data.hours;
            this.types = data.types;
            this.typesCC = data.typesCC;
            this.timeMinutes = data.minutes;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getUsers().subscribe(data => {
            this.subscribers = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getPages().subscribe(data => {
            this.pages = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addSubscription(e: any = null) {
        if (e) {
            e.preventDefault();
        }

        if (this.submitted) {
            return;
        }

        this.form.get('dayOfDelivery').setValidators([]);

        if (this.form.value.type === 'weekly') {
            this.form.get('dayOfDelivery').setValidators(Validators.required);
        }

        this.errors = this.errorsTransformer.getErrors(this.form);

        if (this.form.status !== 'VALID') {
            return;
        }

        this.submitted = true;

        const {
            subscriber,
            report,
            email,
            name,
            type,
            dayOfDelivery,
            hourOfDelivery,
            minuteOfDelivery,
            timeZone,
            format,
            startDate,
            endDate,
            followers
        } = this.form.value;

        const data = {
            subscriber,
            report,
            email,
            name,
            type,
            dayOfDelivery,
            hourOfDelivery,
            minuteOfDelivery,
            timeZone,
            format,
            startDate,
            endDate,
            followers
        };

        const request = this.subscription
            ? this.apiService.updateSubscription(this.subscription.id, data)
            : this.apiService.createSubscription(data);

        request.subscribe(() => {
            this.closeModalWindow();
        }, error => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, error);
            this.submitted = false;
        });
    }

    isFieldValid(field: string) {
        return this.form.get(field).invalid;
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    addItem(event: any) {
        event.preventDefault();
        this.addFollower();
    }

    deleteItem(event: any, index) {
        event.preventDefault();
        this.removeFollower(index);
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

    addFollower(value: { email: string, type: string; } = null) {
        const followers = this.form.get('followers') as FormArray;

        followers.push(this.formBuilder.group({
            type: [value && value.type ? value.type : ''],
            email: [value && value.email ? value.email : '']
        }));
    }

    removeFollower(index: number) {
        const followers = this.form.get('followers') as FormArray;

        followers.removeAt(index);
    }

    getFollowersErrors() {
        return this.errors.filter(item => item.field === 'followers').map(item => item.message);
    }

    get formData() {
        return this.form.get('followers') as FormArray;
    }
}
