import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {LoadingEvent} from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    /** TODO:IMPROVE THIS WITH HTTP HEADERS LIKE NGX-LOADING */
    private events: LoadingEvent[] = [];
    private eventsCount = 0;
    private eventsSubject: Subject<LoadingEvent[]> = new Subject();

    constructor() {
    }

    addEvents(...events: string[]) {
        events.forEach((event) => {
            this.addEvent(event);
        });
    }

    getEvents(): Observable<LoadingEvent[]> {
        return this.eventsSubject;
    }

    getEventsCount(): number {
        return this.eventsCount;
    }

    addEventCount(): void {
        this.eventsCount++;
    }

    addEvent(eventName: string): void {
        this.addEventCount();
        this.events.push({
            name: eventName
        });
        this.eventsSubject.next(this.events);
    }

    removeEvent(eventName: string): void {
        this.events = this.events.filter(event => {
            return event.name !== eventName;
        });
        this.eventsSubject.next(this.events);
    }

    clearEvents() {
        this.events = [];
        this.eventsSubject.next(this.events);
    }
}
