import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ScrollbarComponent} from './scrollbar.component';
import {DraggableDirective} from './draggable.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [ScrollbarComponent, DraggableDirective],
    exports: [ScrollbarComponent]
})
export class ScrollbarModule {
}
