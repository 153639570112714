import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-profile-row-value',
    templateUrl: './user-profile-row-value.component.html',
    styleUrls: ['./user-profile-row-value.component.scss']
})
export class UserProfileRowValueComponent implements OnInit {

    @Input() key: string;
    @Input() label: string;
    @Input() itemValue: any;


    constructor() {
    }

    ngOnInit() {
    }
}
