<div *ngIf="loading" class='text-center' id='loading' style='padding:200px;'>
    <div>{{'messages.global.loading'|translate}}</div>
    <div>{{'messages.web.report_page.iframe.info'|translate}}</div>
</div>

<div *ngIf="!loading">

    <div *ngIf="!contents?.error">
        <div (click)="getParent()" style="margin-bottom: 20px;">
            <img
                *ngIf="showGetParent()"
                alt="Parent Folder"
                class="img-responsive"
                src="assets/img/folder-back.png"
                style="width: 40px;cursor: pointer;"
                title="Browse back"
            />
        </div>

        <table class='table table-hover table-bordered'>
            <thead>
            <tr>
                <th (click)="sortBy('name')" [ngClass]="{selected: sortColumn === 'name'}" class="pointer">
                    {{'messages.web.report_page.network_share.name'|translate}}
                    <span
                        *ngIf="sortColumn === 'name'"
                        [ngClass]="{'sort-desc': !sortAsc, 'sort-asc': sortAsc}"
                        class="sort"
                    ></span>
                </th>
                <th (click)="sortBy('size')" [ngClass]="{selected: sortColumn === 'size'}" class="pointer">
                    {{'messages.web.report_page.network_share.size'|translate}}
                    <span
                        *ngIf="sortColumn === 'size'"
                        [ngClass]="{'sort-desc': !sortAsc, 'sort-asc': sortAsc}"
                        class="sort"
                    ></span>
                </th>
                <th (click)="sortBy('time')" [ngClass]="{selected: sortColumn === 'time'}" class="pointer">
                    {{'messages.web.report_page.network_share.modified'|translate}}
                    <span
                        *ngIf="sortColumn === 'time'"
                        [ngClass]="{'sort-desc': !sortAsc, 'sort-asc': sortAsc}"
                        class="sort"
                    ></span>
                </th>
                <th>
                    {{'messages.web.report_page.network_share.action'|translate}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                (dblclick)="onDoubleClick(content)"
                *ngFor="let content of sortedItems"
                style="cursor: pointer"
                title="Double click to download or browse folder"
            >
                <td>
                    <img
                        alt="Action"
                        class="img-responsive float-left"
                        src="{{content.isDirectory ? 'assets/img/folder-browser.png' : 'assets/img/file-browser.png' }}"
                        style="width: 20px;margin-right: 20px;"
                    />
                    <span style="padding-top: 10px;">
                        {{content.name}}
                    </span>
                </td>
                <td class="text-right">{{content.isDirectory ? '-' : (content.size * 1 | bytes:2) }}</td>
                <td class="text-right">{{formatDate(content.time) }}</td>
                <td (click)="onDoubleClick(content)">
                    <a>{{ content.isDirectory ? 'Browse' : 'Download' }}</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="contents?.error">
        <p class="alert alert-danger">{{ contents?.error }}</p>
    </div>
</div>
