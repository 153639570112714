import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FormErrorsHandlerService} from '../../services/form-errors-handler.service';

@Component({
    selector: 'app-input-errors',
    templateUrl: './input-errors.component.html',
    styleUrls: ['./input-errors.component.scss']
})
export class InputErrorsComponent implements OnInit {

    @Input() control: AbstractControl;
    @Input() errors: string[] = null;

    constructor(private formErrorsHandler: FormErrorsHandlerService) {
    }

    ngOnInit() {
    }

    get controlErrors(): string[] {
        if (!this.control?.errors) {
            return [];
        }

        const errors = [];

        for (const [key, error] of Object.entries(this.control.errors)) {
            errors.push(this.formErrorsHandler.getErrorMessage(key, error));
        }

        return errors;
    }
}
