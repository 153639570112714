import {Component, OnInit, ViewChild} from '@angular/core';
import {WcResourcesInjectorService} from '../../../services/wc-resources-injector.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-data-import-page',
  templateUrl: './data-import-page.component.html',
  styleUrls: ['./data-import-page.component.scss']
})
export class DataImportPageComponent implements OnInit {

    public loaded = false;
    public failedLoad = false;

    @ViewChild('component_container') container;

    constructor(private wcResourcesInjector: WcResourcesInjectorService) {
    }

    ngOnInit(): void {
        const url = environment.webAppsDomain + '/data-import-app/data-import-app.min.js';

        this.wcResourcesInjector.addJs(url).then(() => {
            this.loaded = true;

            const el = document.createElement('data-import-app');
            this.container.nativeElement.appendChild(el);
        }).catch(() => {
            this.loaded = false;
            this.failedLoad = true;
        });

    }

}
