<div [ngClass]="{inline: inline}" class="form-group">
    <label
        *ngIf="withLabel"
        [for]="id"
        [style.color]="!isFieldValid ? '#a94442' : null"
        class="control-label"
    >{{label|translate}}</label>
    <select
        (blur)="touch()"
        *ngIf="!multiple"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="id"
        [ngClass]="{'is-invalid' : !isFieldValid}"
        [style.minHeight]="multiple? '200px': ''"
        class="form-control"
    >
        <option [value]="null" hidden selected>{{placeholder|translate}}</option>
        <option *ngFor='let option of options; let i = index' [value]="option.value">{{option.name}}</option>
    </select>

    <select
        (blur)="touch()"
        *ngIf="multiple"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="id"
        [style.minHeight]="multiple? '200px': ''"
        class="form-control"
        multiple
    >
        <optgroup *ngFor="let group of options" [label]="group.name">
            <option *ngFor="let item of group.values" [value]="item.value">
                {{item.name}}
            </option>
        </optgroup>
    </select>
    <ng-content>
    </ng-content>
</div>

