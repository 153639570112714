<div class="navbar-mobile-fake-bar"></div>
<div
    *ngIf="isUserAuthenticated"
    id="page-wrapper"
    class="page-wrapper"
    [ngClass]="
    {
        'sidebar-left-toggled' : isFramed || !layoutConfigService.getSidebarLeft(),
        'sidebar-right-toggled' : layoutConfigService.getSidebarRight(),
        'sidebar-left-panel-save-toggled': layoutConfigService.getLeftPanelSave(),
        'sidebar-left-panel-view-expanded-toggled': layoutConfigService.getLeftPanelView(),
        'sidebar-left-panel-view-collapsed-toggled': layoutConfigService.getLeftPanelView(),
        'sidebar-left-panel-subscribe-toggled': layoutConfigService.getLeftPanelSubscribe()
     }"
>

    <app-navbar-top [ngStyle]="{'visibility': isFramed ? 'hidden' : 'visible'}"></app-navbar-top>
    <app-sidebar-left [admin]="false" [ngStyle]="{'visibility': isFramed ? 'hidden' : 'visible'}"></app-sidebar-left>
    <app-sidebar-left-panel-subscribe
        *ngIf="reportsService.getSelectedReport()"
        [report]="reportsService.getSelectedReport()"
    ></app-sidebar-left-panel-subscribe>
    <div class="page-center-column-wrapper">
        <div class="page-center-column" #screenshotContent>
            <app-fatal-error *ngIf="globalErrorService.getError()"></app-fatal-error>
            <router-outlet *ngIf="!globalErrorService.getError()"></router-outlet>
        </div>
    </div>
    <app-sidebar-right></app-sidebar-right>
</div>

<app-fatal-error *ngIf="!isUserAuthenticated && globalErrorService.getError()"></app-fatal-error>
