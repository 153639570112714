import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AdminGroupsList} from '../../../../models/models';

@Component({
    selector: 'app-group-table-tr-info',
    templateUrl: './group-table-tr-info.component.html',
    styleUrls: ['./group-table-tr-info.component.scss']
})
export class GroupTableTrInfoComponent implements OnInit {

    @Input() group: AdminGroupsList;
    @Output() openModal = new EventEmitter();
    @Output() updateGroup = new EventEmitter();
    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'users',
            name: 'messages.admin.accordion.users',
            type: 'list'
        },
        {
            id: 'folders',
            name: 'messages.admin.accordion.folders',
            type: 'list'
        },
        {
            id: 'messages.global.table_column_created_by',
            name: 'By',
            type: 'string'
        },
        {
            id: 'total_portal_logins',
            name: 'messages.admin.users_page.total_number_of_portal_logins',
            type: 'number'
        },
        {
            id: 'portal_logins_last_30_days',
            name: 'messages.admin.users_page.number_of_portal_logins_last_30_days',
            type: 'number'
        },
        {
            id: 'total_addin_logins',
            name: 'messages.admin.users_page.total_number_of_addin_logins',
            type: 'number'
        },
        {
            id: 'addin_logins_last_30_days',
            name: 'messages.admin.users_page.number_of_addin_logins_last_30_days',
            type: 'number'
        },
        {
            id: 'last_login',
            name: 'messages.global.last_login',
            type: 'string'
        }
    ];

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    editGroup() {
        return this.openModal.emit();
    }

    deleteGroup() {
        this.apiService.deleteGroup(this.group.id).subscribe(response => {
            this.updateGroup.emit(response);
        });
    }

    toggleDefault() {
        this.apiService.toggleDefaultGroup(this.group.id).subscribe(response => {
            this.updateGroup.emit(response);
        });
    }
}
