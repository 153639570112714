<div class="page-title">
    <h1><i class="icon ion-person color-light-green"></i> Env variables</h1>
</div>

<div class="w-100">Frontend v{{version}}</div>

<div class="w-100">Dev: Backend v{{devBackendVersion}} | Addin API
    v{{devAddinVersion}}</div>
<div class="w-100">Staging: Backend v{{stagBackendVersion}} | Addin API
    v{{stagAddinVersion}}</div>
<div class="w-100">Production: Backend v{{prodBackendVersion}} | Addin API
    v{{prodAddinVersion}}</div>

<div class="d-flex mt-3 mb-5">
    <div class="mr-5">
        <h5>Backend env</h5>
        <table border="1">
            <thead>
            <tr>
                <td>Key</td>
                <td>Value</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let value of backendEnv|keyvalue">
                <td>{{value.key}}</td>
                <td>{{value.value}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div>
        <h5>Addin env</h5>
        <table border="1">
            <thead>
            <tr>
                <td>Key</td>
                <td>Value</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let value of addinEnv|keyvalue">
                <td>{{value.key}}</td>
                <td>{{value.value}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
