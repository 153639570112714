import {Component, ComponentFactoryResolver, OnInit, ViewChild} from '@angular/core';
import {AdminContentsList, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';
import {ContentTypeHtmlModalComponent} from '../../../modules/common-modals/components/content-type-html-modal/content-type-html-modal.component';
import {ContentsFormDirective} from './edit-modals/contents-form.directive';
import {ContentTypeShinyModalComponent} from '../../../modules/common-modals/components/content-type-shiny-modal/content-type-shiny-modal.component';
import {ContentTypeNetworkShareModalComponent} from '../../../modules/common-modals/components/content-type-network-share-modal/content-type-network-share-modal.component';
import {
    TYPE_COURSE,
    TYPE_DASHBOARD,
    TYPE_DYNAMIC_HTML,
    TYPE_HANDBOOK,
    TYPE_HTML,
    TYPE_IFRAME,
    TYPE_MARKDOWN,
    TYPE_NETWORK_SHARE,
    TYPE_PRESENTATION,
    TYPE_SHINY,
    TYPE_WEB_APPLICATION
} from '../../../services/constants';
import {ContentTypeDynamicHtmlModalComponent} from '../../../modules/common-modals/components/content-type-dynamic-html-modal/content-type-dynamic-html-modal.component';
import {ContentTypeIframeModalComponent} from '../../../modules/common-modals/components/content-type-iframe-modal/content-type-iframe-modal.component';
import {Router} from '@angular/router';
import {ContentTypeHandbookComponent} from '../../../modules/common-modals/components/content-type-handbook/content-type-handbook.component';
import {ContentTypeCourseModalComponent} from '../../../modules/common-modals/components/content-type-course-modal/content-type-course-modal.component';
import {ContentTypeMarkdownComponent} from '../../../modules/common-modals/components/content-type-markdown/content-type-markdown.component';
import {ContentTypePresentationComponent} from '../../../modules/common-modals/components/content-type-presentation/content-type-presentation.component';
import {ContentTypeWebAppComponent} from '../../../modules/common-modals/components/content-type-web-app/content-type-web-app.component';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    entryComponents: [
        ContentTypeHtmlModalComponent,
        ContentTypeShinyModalComponent,
        ContentTypeNetworkShareModalComponent,
        ContentTypeDynamicHtmlModalComponent,
        ContentTypeIframeModalComponent,
        ContentTypeCourseModalComponent,
        ContentTypePresentationComponent,
        ContentTypeWebAppComponent,
    ]
})
export class ContentComponent implements OnInit {
    @ViewChild(ContentsFormDirective) editFormPlaceholder: ContentsFormDirective;

    public inactive: boolean;
    public busy: boolean;
    public entities: AdminContentsList[];
    public selectedIds = [];
    public selectedEntity: AdminContentsList;
    public addModal: boolean;
    public editModal: boolean;
    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'full_name',
            name: 'messages.admin.contents_page.table_column_name',
            type: 'string'
        },
        {
            id: 'type',
            name: 'messages.admin.contents_page.table_column_type',
            type: 'string'
        },
        {
            id: 'status',
            name: 'messages.admin.contents_page.table_column_status',
            type: 'boolean'
        }
    ];
    private csvFile = 'evo-contents.csv';
    private entity: AdminContentsList | null;

    private editForms = {
        [TYPE_HTML]: ContentTypeHtmlModalComponent,
        [TYPE_SHINY]: ContentTypeShinyModalComponent,
        [TYPE_NETWORK_SHARE]: ContentTypeNetworkShareModalComponent,
        [TYPE_DYNAMIC_HTML]: ContentTypeDynamicHtmlModalComponent,
        [TYPE_IFRAME]: ContentTypeIframeModalComponent,
        [TYPE_HANDBOOK]: ContentTypeHandbookComponent,
        [TYPE_COURSE]: ContentTypeCourseModalComponent,
        [TYPE_MARKDOWN]: ContentTypeMarkdownComponent,
        [TYPE_PRESENTATION]: ContentTypePresentationComponent,
        [TYPE_WEB_APPLICATION]: ContentTypeWebAppComponent,
    };

    constructor(
        private apiService: ApiService,
        public paginationService: PaginationService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.addModal = false;
        this.editModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadComponent() {
        const {viewContainerRef} = this.editFormPlaceholder;
        viewContainerRef.clear();

        if (this.entity.type === TYPE_DASHBOARD) {
            this.router.navigate([`/admin/contents/manage/${this.entity.id}/`]);
            return;
        }

        const component = this.editForms[this.entity.type];

        if (!component) {
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = viewContainerRef.createComponent(componentFactory);

        // @ts-ignore
        componentRef.instance.content = this.entity;
        // @ts-ignore
        componentRef.instance.update.subscribe(
            data => this.updateEntity()
        );
    }

    loadList() {
        this.apiService.getContents(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminContentsList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity() {
        this.addModal = true;
    }

    editEntity(entity: AdminContentsList = null) {
        this.editModal = true;
        this.entity = entity;

        setTimeout(() => {
            this.loadComponent();
        }, 0);
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const user of this.entities) {
                this.selectedIds.push(user.id);
                user.checked = true;
            }
        } else {
            for (const user of this.entities) {
                user.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getContents(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity() {
        this.addModal = false;
        this.editModal = false;
        this.entity = null;
        this.loadList();
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getContents(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    closeModal(type: string) {
        this.entity = null;
        this.addModal = false;
        this.editModal = false;
        this.loadList();
    }

    exportAll() {
        this.apiService.exportContents().subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    exportSelected() {
        this.apiService.exportSelectedContents(this.selectedIds).subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    deleteSelected() {
        this.apiService.deleteSelectedContents(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.apiService.getContents(this.paginationService.pager).subscribe(response => {
                this.entities = response;
            });
        });
    }
}
