import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-onboarding',
  templateUrl: './company-onboarding.component.html',
  styleUrls: ['./company-onboarding.component.scss']
})
export class CompanyOnboardingComponent implements OnInit {

    constructor() {
    }

    public location = window.location;

    ngOnInit(): void {
    }

    isRootOpened() {
        return window.location.pathname.includes('/app/company-onboarding');
    }
}
