import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {GitUser, ProvidedEntity} from '../../../../models/models';

@Component({
    selector: 'app-new-issue-form',
    templateUrl: './new-issue-form.component.html',
    styleUrls: ['./new-issue-form.component.scss']
})
export class NewIssueFormComponent implements OnInit {
    private loading = false;
    public form: FormGroup;
    public members: GitUser[] = [];
    public labels = [];
    public mentions = [];

    public memberFilterFunction = (item, value) => {
        return item.name.includes(value) || item.username.includes(value);
    }

    public labelFilterFunction = (item, value) => {
        return item.name.includes(value);
    }

    constructor(
        private api: ApiService,
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService
    ) {
    }

    ngOnInit(): void {
        if (!this.auth.getLoggedUser().board_enabled) {
            this.router.navigateByUrl('/app/reports');
            return;
        }

        this.form = this.formBuilder.group({
            title: this.formBuilder.control('', [Validators.required]),
            description: this.formBuilder.control(''),
            assignee: this.formBuilder.control(null),
            due_date: this.formBuilder.control(null),
            labels: this.formBuilder.control(null),
        });

        this.api.getGitProjectMembers().subscribe(data => {
            this.members = data.map(item => ({...item, username: '@' + item.username}));
        });

        this.api.getGitProjectLabels().subscribe(data => {
            this.labels = data;
        });
    }

    isTitleInvalid() {
        const titleControl = this.form.get('title');
        return (titleControl.dirty || titleControl.touched) && titleControl.errors?.required;
    }

    submit() {
        if (this.loading) {
            return;
        }

        this.form.markAllAsTouched();

        if (this.form.status === 'INVALID') {
            return;
        }

        this.loading = true;

        const {assignee, labels, ...data} = this.form.value;
        data.assignee_id = assignee?.id || null;
        data.labels = labels?.length > 0 ? labels.map(item => item.name) : null;
        this.mentions = this.mentions.map((item => item.id));
        data.mentions = this.mentions.length > 0 ?   this.mentions.filter((item, index) => this.mentions.indexOf(item) === index ) : null;

        // const data = this.form.value;
        this.api.createGitIssue(data).subscribe(() => {
            this.loading = false;
            this.router.navigateByUrl('/app/board');
        }, (e) => {
            console.log(e);
            this.loading = false;
        });
    }

    onCancel() {
        this.router.navigateByUrl('/app/board');
    }

    mentionChangedHandler($event: ProvidedEntity[]) {
        this.mentions = $event;
    }
}
