import {Component, Input, OnInit} from '@angular/core';
import {SortableOptions} from 'sortablejs';

@Component({
    selector: 'app-folder-tree-node',
    templateUrl: './folder-tree-node.component.html',
    styleUrls: ['./folder-tree-node.component.scss']
})
export class FolderTreeNodeComponent implements OnInit {

    @Input() folder;
    @Input() lvl = 0;

    folderOptions: SortableOptions = {
        group: {
            name: 'folders'
        },
        fallbackOnBody: true,
        swapThreshold: 0.2,
    };

    pagesOptions: SortableOptions = {
        group: {
            name: 'pages'
        },
        fallbackOnBody: true,
        swapThreshold: 0.1,
    };

    ngOnInit(): void {
    }
}
