import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FolderModalComponent} from '../../pages/administration/folders/folder-modal/folder-modal.component';
import {CommonAppModule} from '../../common/common-app.module';
import {TranslationsModule} from '../translations/translations.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PageModalComponent} from '../../pages/administration/pages/page-modal/page-modal.component';
import {UserProfileDocumentsFormComponent} from './components/user-profile-documents-form/user-profile-documents-form.component';
import {FormGeneratorModule} from '../form-generator/form-generator.module';
import {ContentTypeShinyModalComponent} from './components/content-type-shiny-modal/content-type-shiny-modal.component';
import {ContentTypeNetworkShareModalComponent} from './components/content-type-network-share-modal/content-type-network-share-modal.component';
import {ContentTypeDynamicHtmlModalComponent} from './components/content-type-dynamic-html-modal/content-type-dynamic-html-modal.component';
import {ContentTypeIframeModalComponent} from './components/content-type-iframe-modal/content-type-iframe-modal.component';
import {ContentTypeHandbookComponent} from './components/content-type-handbook/content-type-handbook.component';
import {ContentTypeHtmlModalComponent} from './components/content-type-html-modal/content-type-html-modal.component';
import {ContentTypeCourseModalComponent} from './components/content-type-course-modal/content-type-course-modal.component';
import {ContentTypeMarkdownComponent} from './components/content-type-markdown/content-type-markdown.component';
import {ContentTypePresentationComponent} from './components/content-type-presentation/content-type-presentation.component';
import { ContentTypeWebAppComponent } from './components/content-type-web-app/content-type-web-app.component';


@NgModule({
    declarations: [
        FolderModalComponent,
        PageModalComponent,
        UserProfileDocumentsFormComponent,
        ContentTypeShinyModalComponent,
        ContentTypeNetworkShareModalComponent,
        ContentTypeDynamicHtmlModalComponent,
        ContentTypeIframeModalComponent,
        ContentTypeHandbookComponent,
        ContentTypeHtmlModalComponent,
        ContentTypeCourseModalComponent,
        ContentTypeMarkdownComponent,
        ContentTypePresentationComponent,
        ContentTypeWebAppComponent
    ],
    imports: [
        CommonModule,
        CommonAppModule,
        TranslationsModule,
        ReactiveFormsModule,
        FormGeneratorModule,
    ],
    exports: [
        FolderModalComponent,
        PageModalComponent,
        UserProfileDocumentsFormComponent,
        ContentTypeShinyModalComponent,
        ContentTypeNetworkShareModalComponent,
        ContentTypeDynamicHtmlModalComponent,
        ContentTypeIframeModalComponent,
        ContentTypeHandbookComponent,
        ContentTypeHtmlModalComponent,
        ContentTypeCourseModalComponent,
        ContentTypeMarkdownComponent,
        ContentTypePresentationComponent,
        ContentTypeWebAppComponent
    ]
})
export class CommonModalsModule {
}
