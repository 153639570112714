<div class="row-expansion-body">
    <div class="text-right">
        <a
            (click)="editContent()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_manage"
        >
            <i class="icon icon-size-9 ion-compose"></i>
            {{'messages.global.edit'|translate}}
        </a>
        <button
            (click)="toggleActiveContent()"
            *ngIf="!entity.source"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_toggle_{{entity.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-power"></i>
            {{(!entity.is_active ? 'messages.admin.contents_page.activate' : 'messages.admin.contents_page.deactivate')|translate}}

        </button>
        <app-modal-confirm *ngIf="auth.isSuperAdmin()">
            <button
                (click)="deleteContent()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="content_delete_{{entity.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
