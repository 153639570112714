<div *ngIf="issue" class="issue position-relative" [ngClass]="{infoExpanded: infoExpanded}">
    <div
        class="d-block d-md-flex row align-items-center mb-4"
        style="padding: 10px 30px 20px 30px;border-bottom: 2px solid #f3f3f3;"
    >
        <div class="title mb-2 mb-md-0">
            <h2 class="m-0">{{issue.title}}</h2>
        </div>
        <div class="status ml-md-auto">
            <span
                [ngClass]="{'btn-success': issue.state == 'opened', 'btn-danger': issue.state != 'opened'}"
                class="btn btn-primary btn-square btn-sm mr-2"
                style="pointer-events: none;"
            >{{issue.state == 'opened' ? 'Open' : 'Close'}}</span>
            {{issue.state|titlecase}}
            <span>{{(issue.state == 'opened' ? issue.created_at : issue.closed_at)|timeAgo}}</span>
            {{'messages.global.table_column_created_by'|translate|lowercase}}
            <img
                [alt]="(issue.closed_by || issue.author).name"
                [src]="(issue.closed_by || issue.author).avatar_url"
                class="avatar mr-2 ml-2"
                height="20"
                width="20"
            >
            <strong class="mr-2">{{(issue.closed_by || issue.author).name}}</strong>

                        <button
                            class="btn btn-outline-secondary btn-square btn-sm mr-2"
                            *ngIf="issue.state == 'closed'"
                            (click)="toggleStatus()"
                        >
                            <span
                                *ngIf="statusLoading"
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span> Reopen issue
                        </button>
                        <button
                            class="btn btn-outline-warning btn-square btn-sm mr-2"
                            *ngIf="issue.state == 'opened'"
                            (click)="toggleStatus()"
                        >
                            <span
                                *ngIf="statusLoading"
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span> Close issue
                        </button>
                        <button class="btn btn-outline-info btn-square btn-sm mr-2"
                                (click)="toggleInfo()"
                        >
                            <span
                                *ngIf="statusLoading"
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span> <i class="icon ion-information"></i>
                        </button>

        </div>
    </div>

    <div class="evo-card d-flex p-4 mb-4">
        <div class="mr-4 d-none d-sm-block">
            <img [alt]="issue.author.name" [src]="issue.author.avatar_url" class="avatar" height="40" width="40">
        </div>
        <div class="w-100">
            <ng-container *ngIf="!editDescription">
                <div class="mb-2">
                    <strong class="mr-2">{{issue.author.name}}</strong>
                    <span class="mr-2">@{{issue.author.username}}</span>
                    <span>{{'messages.git_boards.view.opened'|translate}} {{issue.created_at|timeAgo}}</span>
                                        <button
                                            class="btn btn-primary btn-square btn-sm ml-4"
                                            (click)="editDescription = true"
                                            *ngIf="!editDescription"
                                        >
                                            <i class="ion-edit"></i> Edit
                                        </button>
                </div>
                <div [innerHTML]="issue.description|markdown"></div>
                                <div *ngIf="issue.updated_at > issue.created_at" class="mt-4">Updated {{issue.updated_at|timeAgo}}</div>
            </ng-container>
            <div *ngIf="editDescription">
                <app-markdown-editor [formControl]="descriptionControl" [allowMentions]="true" (mentionChanged)="mentionChangedHandler($event)"></app-markdown-editor>
                <div class="text-right">
                    <button
                        class="btn btn-outline-warning btn-square btn-sm mr-2"
                        (click)="cancelEdit()"
                    >
                        {{'messages.global.cancel'|translate}}
                    </button>
                    <button
                        (click)="updateDescription()"
                        [disabled]="!descriptionControl.value || loading"
                        class="btn btn-square btn-primary btn-sm"
                    >
                        <span
                            *ngIf="loading"
                            aria-hidden="true"
                            class="spinner-border spinner-border-sm mr-2"
                            role="status"
                        ></span> {{'messages.global.submit'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="issue.notes?.length" class="notes">
        <app-issue-note *ngFor="let note of issue.notes" [note]="note" [issueIid]="issue.iid"></app-issue-note>
    </div>

    <div class="mb-4">
        <app-markdown-editor [formControl]="commentControl" [allowMentions]="true" (mentionChanged)="mentionChangedHandler($event)"></app-markdown-editor>
        <div class="text-right">
                        <button
                            class="btn btn-outline-secondary btn-square btn-sm mr-2"
                            *ngIf="issue.state == 'closed'"
                            (click)="toggleStatus()"
                        >
                            <span
                                *ngIf="statusLoading"
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span> Reopen issue
                        </button>
                        <button
                            class="btn btn-outline-warning btn-square btn-sm mr-2"
                            *ngIf="issue.state == 'opened'"
                            (click)="toggleStatus()"
                        >
                            <span
                                *ngIf="statusLoading"
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span> Close issue
                        </button>
            <button
                (click)="submit()"
                [disabled]="!commentControl.value || loading"
                class="btn btn-square btn-primary btn-sm"
            >
                <span
                    *ngIf="loading"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                ></span>
                {{'messages.global.submit'|translate}}
            </button>
        </div>
    </div>
</div>

<div class="issue-info" *ngIf="issue && infoExpanded">
    <div class="block">
        <div class="label mb-2">Assignee</div>
        <div class="d-flex" *ngIf="issue.assignee">
            <img
                [alt]="issue.assignee.name"
                [src]="issue.assignee.avatar_url"
                class="avatar mr-2"
                height="30"
                width="30"
            >
            <div class="d-inline-block">
                <div>{{issue.assignee.name}}</div>
                <div>@{{issue.assignee.username}}</div>
            </div>
        </div>
        <div *ngIf="!issue.assignee">None</div>
    </div>

    <div class="block">
        <div class="label mb-2">Milestone</div>
        <div *ngIf="issue.milestone">
            <strong>{{issue.milestone.title}}</strong>
        </div>

        <div *ngIf="!issue.milestone">None</div>
    </div>

    <div class="block">
        <div class="label mb-3">Labels</div>
        <div *ngIf="issue.labels?.length" class="issue-labels">
            <span
                *ngFor="let label of issue.labels"
                [style]="{backgroundColor: label.color, color: label.text_color}"
                class="label"
            >{{label.name}}</span>
        </div>
        <div *ngIf="!issue.labels?.length">None</div>
    </div>

    <div class="block">
        <div class="label mb-2">Due Date</div>
        <div *ngIf="issue.due_date">
            <strong>{{issue.due_date}}</strong>
        </div>

        <div *ngIf="!issue.due_date">None</div>
    </div>

    <div class="mt-auto text-center">
        <div class="btn btn-sm" (click)="infoExpanded = false">
            Collapse <i class="icon ion-android-arrow-forward"></i>
        </div>
    </div>
</div>
