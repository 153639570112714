import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {AdminWorkbooksList} from '../../../../models/models';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-workbook-table-tr-info',
    templateUrl: './workbook-table-tr-info.component.html',
    styleUrls: ['./workbook-table-tr-info.component.scss']
})
export class WorkbookTableTrInfoComponent implements OnInit {

    @Input() workbook: AdminWorkbooksList;
    @Output() openModal = new EventEmitter();
    @Output() updateWorkbook = new EventEmitter();
    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'workbook_template_id',
            name: 'messages.admin.workbooks_page.table.th.workbook_template_id',
            type: 'string'
        },
        {
            id: 'r_file_path',
            name: 'messages.admin.workbooks_page.table.th.r_file_path',
            type: 'string'
        },
        {
            id: 'r_file_name',
            name: 'messages.admin.xls_page.table.th.original_filename',
            type: 'string'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_at',
            type: 'string'
        },
        {
            id: 'groups',
            name: 'messages.admin.workbooks_page.table.th.groups',
            type: 'list'
        },
        {
            id: 'decimal_delimiter',
            name: 'messages.admin.workbooks_page.table.th.decimal_delimiter',
            type: 'string'
        },
        {
            id: 'chart',
            name: 'messages.admin.workbooks_page.table.th.chart',
            type: 'link'
        },
        {
            id: 'presentation',
            name: 'messages.admin.workbooks_page.table.th.presentation',
            type: 'link'
        },
        {
            id: 'dll_files',
            name: 'messages.admin.workbooks_page.table.th.workbook_dlls',
            type: 'list'
        },
        {
            id: 'last_committed_by',
            name: 'messages.admin.workbooks_page.table.th.last_committed_by',
            type: 'entity'
        },
        {
            id: 'last_committed_at',
            name: 'messages.admin.workbooks_page.table.th.last_committed_at',
            type: 'string'
        }
    ];

    public showModal = false;
    public output: any = '';

    constructor(
        private apiService: ApiService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        public auth: AuthService
    ) {
    }

    ngOnInit() {
    }

    editWorkbook() {
        return this.openModal.emit();
    }

    showOutput() {
        this.showModal = true;
        this.apiService.getWorkbookOutput(this.workbook.id).subscribe(data => {
            this.output = data;
        });
    }

    closeModalWindow() {
        this.showModal = false;
        this.output = '';
    }

    calculateOutput() {
        this.translateService.get('messages.admin.workbooks_page.growl_message.starting_to_calculate_output_for')
            .subscribe((res: string) => {
                this.toastrService.warning(res + ' ' + this.workbook.stringified);
            });

        this.apiService.calculateWorkbookOutput(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }

    onFileSelected(files: FileList) {
        const formData = new FormData();
        formData.append('file', files.item(0), files.item(0).name);
        this.apiService.uploadWorkbookInput(this.workbook.id, formData).subscribe(response => {
            this.updateWorkbook.emit();
        });
    }

    toggleActive() {
        this.apiService.workbookToggleActive(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }

    toggleManagersOnly() {
        this.apiService.workbookToggleManagersOnly(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }

    toggleUnderMaintenance() {
        this.apiService.workbookToggleUnderMaintenance(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }

    toggleCompressed() {
        this.apiService.workbookToggleCompressed(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }

    delete() {
        this.apiService.deleteWorkbook(this.workbook.id).subscribe(() => {
            this.updateWorkbook.emit();
        });
    }
}
