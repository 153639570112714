import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AdminContentsList} from '../../../../models/models';
import {CONTENT_TYPES_ARRAY} from '../../../../services/constants';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-content-table-tr-info',
    templateUrl: './content-table-tr-info.component.html',
    styleUrls: ['./content-table-tr-info.component.scss']
})
export class ContentTableTrInfoComponent implements OnInit {

    @Input() content: AdminContentsList;
    @Output() openModal = new EventEmitter();
    @Output() updateContent = new EventEmitter();

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'title',
            name: 'messages.admin.contents_page.table_column_title',
            type: 'string'
        },
        {
            id: 'subtitle',
            name: 'messages.admin.contents_page.table_column_subtitle',
            type: 'string'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        },
        {
            id: 'pages',
            name: 'messages.admin.pages_page.pages',
            type: 'list'
        }
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
    }

    toggleActiveContent() {
        this.apiService.toggleActiveContent(this.content.id).subscribe(response => {
            this.updateContent.emit();
        });
    }

    clearContentCache() {
        this.apiService.clearContentCache(this.content.id).subscribe(response => {
            this.updateContent.emit();
        });
    }

    copyContent() {
        this.apiService.copyContent(this.content.id).subscribe(response => {
            this.updateContent.emit();
        });
    }

    deleteContent() {
        this.apiService.deleteContent(this.content.id).subscribe(response => {
            this.updateContent.emit();
        });
    }

    editContent() {
        return this.openModal.emit();
    }

    isSupportedContentType(content: AdminContentsList) {
        return CONTENT_TYPES_ARRAY.indexOf(content.type) >= 0;
    }
}
