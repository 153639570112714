<form
    [formGroup]="form"
    class="form-style-bordered-inputs"
    name="myForm"
    (ngSubmit)="submit($event)"
>
    <div class="list-values">
        <div *ngFor="let controlItem of form.controls | keyvalue" class="list-values-container">
            <div class="heading">
                <span>{{('messages.list_values.' + controlItem.key) | translate}}</span>
                <span *ngIf="controlItem.key === 'badges'"> ({{'messages.list_values.multiple_values'|translate}}
                    )</span>
            </div>
            <div class="body">
                <div
                    *ngFor="let list of controlItem.value.controls; index as i"
                    [formArrayName]="controlItem.key"
                >
                    <div [formGroupName]="i" class="form-group list-value">
                        <ng-container *ngIf="controlItem.key !== 'badges'">
                            <input
                                class="form-control"
                                type="text"
                                formControlName="value"
                            >
                            <i
                                class="icon icon-size-9 ion-close-circled color-light-green"
                                (click)="removeValue(controlItem.key, i)"
                            ></i>
                        </ng-container>
                        <ng-container *ngIf="controlItem.key === 'badges'">
                            <div style="display: flex">
                                <input
                                    type="file"
                                    [id]="'badge_upload_'+i"
                                    (input)="handleUpload($event.target, list)"
                                    style="display: none"
                                >

                                <label [for]="'badge_upload_'+i" class="mb-0">
                                    <img
                                        [src]="list.get('icon')?.value || '../../../../../assets/img/no-avatar.png'"
                                        [alt]="list?.value?.value"
                                        width="40"
                                        height="40"
                                        class="thumbnail pointer"
                                        style="margin-bottom: 0!important; margin-right: 5px"
                                    >
                                </label>
                                <div style="position: relative">
                                    <input
                                        class="form-control mb-0"
                                        type="text"
                                        formControlName="value"
                                        style="margin-bottom: 0!important;"
                                    >
                                    <i
                                        class="icon icon-size-9 ion-close-circled color-light-green"
                                        (click)="removeValue(controlItem.key, i)"
                                    ></i>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <app-display-input-errors
                        [displayErrors]="localErrors"
                        [errorKey]="controlItem.key + '.' + i + '.value'"
                    >
                    </app-display-input-errors>
                </div>
                <a class="btn btn-sm btn-primary" (click)="addValue(controlItem.key)">
                    <i class="icon icon-size-9 ion-plus-circled"></i>
                    {{'messages.global.add_new'|translate}}
                </a>
            </div>
        </div>
    </div>
    <app-form-submited-loading *ngIf="loading"></app-form-submited-loading>
    <button class="btn btn-sm btn-primary" type="submit">
        {{'messages.global.submit'|translate}}
    </button>
    &nbsp;
    <a class="btn btn-sm btn-default" (click)="cancel($event)" type="button">
        {{'messages.global.cancel'|translate}}
    </a>
</form>
