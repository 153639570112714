import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutConfigService} from '../../services/layout-config.service';
import {GlobalErrorService} from '../../services/global-error.service';
import {PingService} from '../../services/ping.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit, OnDestroy {

    public isUserAuthenticated = false;
    private pingObs: any;
    private routeObs: any;

    constructor(
        public layoutConfigService: LayoutConfigService,
        private pingService: PingService,
        public globalErrorService: GlobalErrorService,
        private router: Router) {
    }

    ngOnInit() {
        this.layoutConfigService.hideSidebarRight();
        this.layoutConfigService.hideLeftPanelSubscribe();

        this.pingObs = this.pingService.onPing().subscribe((val) => {
            this.isUserAuthenticated = val;
        });

        this.pingService.start();

        this.routeObs = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.pingService.start();
            }
        });
    }

    ngOnDestroy(): void {
        this.pingService.stop();
        this.pingObs.unsubscribe();
        this.routeObs.unsubscribe();
    }
}
