import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../../services/loading.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    constructor(private loadingService: LoadingService) {
    }

    ngOnInit(): void {
        this.loadingService.clearEvents();
    }

}
