<!--suppress HtmlUnknownTarget --><!-- Top navbar -->
<nav
    [ngClass]="{'navbar-mobile': layoutConfigService.mobileMenuOpened, 'hidden-labels' : !layoutConfigService.getMenuLabels(), 'public-page': publicPage}"
    class="navbar navbar-expand-lg navbar-default navbar-fixed-top justify-content-between"
>
    <div *ngIf="!publicPage">
        <a class="logo" routerLink="/"><img alt="Tools group" src="assets/img/tg-logo.png"/></a>
        <a class="logo-mobile" routerLink="/"><img alt="Tools group" src="assets/img/tg-logo.png"/></a>
    </div>

    <div *ngIf="publicPage">
        <a class="logo" routerLink="/"><img alt="Tools group" src="assets/img/tg-logo.png"/></a>
        <a class="logo-mobile" routerLink="/"><img alt="Tools group" src="assets/img/tg-logo.png"/></a>
    </div>

    <div *ngIf="publicPage" class="public-pages-links">
        <div>
            <a target="_blank" href="https://evouser.com">Log in</a>
        </div>
        <div>
            <a target="_blank" href="https://evouser.com/register">Sign up</a>
        </div>
    </div>

    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
        <button
            (click)="layoutConfigService.mobileMenuToggle()"
            class="navbar-toggler navbar-toggle collapsed"
            type="button"
        >
            <span class="ion-navicon-round"></span>
            {{'messages.navigation.menu' | translate}}
        </button>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <ul *ngIf="hideDetails === false && (layoutConfigService.mobileMenuOpened || !isMobileView())" class="navbar-nav ml-auto mt-2 mt-lg-0 wave-ui-body wave-ui-color-primary">
        <li *ngIf="authService.isImpersonated()">
            <a
                (click)="exitImpersonate();layoutConfigService.setMobileMenuOpened(false)"
                target="_self"
            >({{'messages.navigation.exit_impersonation' | translate}})</a>
        </li>
        <li *ngIf="authService.canImpersonate() && !authService.isImpersonated()">
            <div class="btn-group" dropdown>
                <button
                    (click)="focusImpersonate()"
                    class="btn btn-primary btn-sm nav-link dropdown-toggle wave-ui-body wave-ui-color-primary"
                    dropdownToggle
                    id="impersonating-users-button"
                    type="button"
                >
                    {{'messages.navigation.impersonate_users' | translate}}
                    <span class="caret"></span>
                </button>
                <div
                    *dropdownMenu
                    aria-labelledby="impersonating-users-button"
                    class="dropdown-menu impersonating-users-button wave-ui-body wave-ui-color-primary"
                >
                    <input
                        #impersonate
                        (click)="$event.stopPropagation()"
                        (input)="searchUser($event.target.value)"
                        [placeholder]="'messages.admin.search'|translate"
                        autocomplete="off"
                        id="imp-search-bar"
                        type="text"
                    >
                    <ul class="wave-ui-body wave-ui-positive-default" style="padding:0; word-break: break-all; white-space: pre-line;">
                        <li *ngFor="let user of filteredUsers">
                            <a
                                (click)="impersonateUser(user.id);layoutConfigService.setMobileMenuOpened(false)"
                                target="_self"
                            >
                                <span>{{user.stringified}}</span><br>
                                <span style="font-size: 75%">{{user.email}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
        <!--        <li *ngIf="!layoutConfigService.isAdminRoute()">-->
        <!--            <a-->
        <!--                class="nav-link nav-link-admin"-->
        <!--                routerLink="/app/feed"-->
        <!--                target="_self"-->
        <!--                (click)="layoutConfigService.setMobileMenuOpened(false)"-->
        <!--            >-->
        <!--                <i class="icon icon-size-7 ion-android-globe"></i>-->
        <!--                <span-->
        <!--                    class="nav-link-label"-->
        <!--                    *ngIf="layoutConfigService.getMenuLabels() || isMobileView()"-->
        <!--                >Evo feed</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <li *ngIf="authService.canSeeAdmin() && !layoutConfigService.isAdminRoute()">
            <a
                (click)="layoutConfigService.setMobileMenuOpened(false);redirectTo()"
                [routerLink]="'/admin'"
                class="nav-link wave-ui-body wave-ui-color-primary" style="align-content: center"
            >
                <i class="icon icon-size-7 ion-wrench"></i>
                <span
                    *ngIf="layoutConfigService.getMenuLabels() || isMobileView()"
                    class="nav-link-label"
                >{{'messages.navigation.admin' | translate}}</span>
                <span *ngIf="layoutConfigService.getNotificationsCount() > 0" class="nav-link-admin-notifications">
                            <span class="badge badge-important">{{layoutConfigService.getNotificationsCount()}}</span>
                        </span>
            </a>
        </li>
        <li *ngIf="layoutConfigService.isAdminRoute()">
            <a
                (click)="layoutConfigService.setMobileMenuOpened(false)"
                [routerLink]="['']"
                class="nav-link nav-link-admin wave-ui-body wave-ui-color-primary"
                style="align-content: center"
                target="_self"
            >
                <i class="icon icon-size-7 ion-chevron-left"></i>
                <span
                    *ngIf="layoutConfigService.getMenuLabels() || isMobileView()"
                    class="nav-link-label"
                >{{'messages.navigation.return_to_site' | translate}}</span>
            </a>
        </li>
        <li>
            <div class="btn-group" dropdown>
                <button
                    aria-controls="dropdown-basic" class="btn btn-primary nav-link dropdown-toggle wave-ui-body wave-ui-color-primary" dropdownToggle
                    type="button"
                >
                    <img
                        *ngIf="authService.getLoggedUser()"
                        alt="avatar"
                        class="avatar"
                        src="{{authService.getLoggedUser().id|avatarUrl}}"
                    >
                    {{authService.getLoggedUser()?.firstName}} {{authService.getLoggedUser()?.lastName}}
                    <span
                        *ngIf="layoutConfigService.getNewMessages() > 0"
                        class="badge badge-important"
                    >{{layoutConfigService.getNewMessages()}}
                    </span>
                    <span class="caret"></span>
                </button>
                <ul
                    *dropdownMenu
                    aria-labelledby="button-basic"
                    class="dropdown-menu dropdown-menu-right"
                    id="user-dropdown"
                    role="menu"
                >
                    <li role="menuitem">
                        <a class="dropdown-item" routerLink="/app/me">
                            <i class="icon ion-person"></i> {{'messages.profile_page.my_profile'|translate}}
                        </a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item" routerLink="/app/team">
                            <i class="icon ion-person-stalker"></i> {{'messages.global.team'|translate}}
                        </a>
                    </li>
                    <ng-container *ngIf="authService.isEvoEmployee()">
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                routerLink="/app/organization-chart"
                            ><i class="icon ion-ios-people"></i> Org chart</a>
                        </li>
                        <li role="menuitem">
                            <a
                                class="dropdown-item"
                                routerLink="/app/gitlab"
                            ><i class="icon ion-social-github"></i> Gitlab</a>
                        </li>
                        <li role="menuitem">
                            <a
                                href="https://share.evopricing.com"
                                class="dropdown-item"
                                target="_blank"
                            ><i class="icon ion-share"></i> Synology</a>
                        </li>
                    </ng-container>
                    <li role="menuitem">
                        <a
                            (click)="layoutConfigService.showSidebarRight('messages');layoutConfigService.setMobileMenuOpened(false)"
                            class="dropdown-item"
                        >
                            <i class="icon ion-email"></i>
                            {{'messages.navigation.messages' | translate}}
                            <span
                                *ngIf="layoutConfigService.getNewMessages() > 0"
                                class="badge badge-important"
                            >{{layoutConfigService.getNewMessages()}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a
                            (click)="layoutConfigService.showSidebarRight('settings');layoutConfigService.setMobileMenuOpened(false)"
                            class="dropdown-item"
                        >
                            <i class="icon ion-ios-gear"></i>
                            {{'messages.navigation.settings' | translate}}
                        </a>
                    </li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem">
                        <a (click)="logout();layoutConfigService.setMobileMenuOpened(false)" class="dropdown-item">
                            <i class="icon ion-power"></i>
                            <span
                                class="nav-link-label"
                            >{{'messages.navigation.log_out' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li>

        </li>
    </ul>
</nav>
<div class="navbar-mobile-fake-bar"  [ngClass]="{'public-page': publicPage}"></div>
<!-- /Top navbar -->
