import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {DisplayError, UserProfileDocument} from '../../../../models/models';
import {FormArray, FormBuilder} from '@angular/forms';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {downloadContent} from '../../../../helpers/DownloadContent';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';

@Component({
    selector: 'app-user-profile-documents-form',
    templateUrl: './user-profile-documents-form.component.html',
    styleUrls: ['./user-profile-documents-form.component.scss'],
    providers: [FormLoadingServiceService]
})
export class UserProfileDocumentsFormComponent implements OnInit {

    @Input() userId: number;
    @Output() closeModal = new EventEmitter();

    public documents: UserProfileDocument[] = [];

    public errors: DisplayError[] = [];
    public submitted = false;
    public form: FormArray;

    constructor(
        private api: ApiService,
        private errorsTransformer: FormErrorsTransformerService,
        private formBuilder: FormBuilder,
        public formLoadingService: FormLoadingServiceService) {
    }

    ngOnInit() {
        if (!this.userId) {
            throw  new Error('"userId" must be setted!');
        }

        this.updateList();
    }

    updateList() {
        this.formLoadingService.init(1);

        this.api.getUserDocuments(this.userId).subscribe(data => {
            this.documents = data.map(item => ({...item}));
            this.form = this.formBuilder.array([]);

            this.documents.forEach(document => {
                this.form.push(this.formBuilder.group({
                        caption: [document.caption]
                    }),
                );
            });

            this.formLoadingService.eventLoaded();
        }, () => {
            this.formLoadingService.eventLoaded();
        });
    }

    uploadDocument(event) {
        if (this.submitted) {
            return;
        }

        if (!event.target.files) {
            return;
        }

        const data = new FormData();

        for (const file of event.target.files) {
            data.append(file.name, file);
        }

        event.target.files = null;
        event.target.value = null;
        this.submitted = true;
        this.errors = [];

        this.api.uploadUserDocuments(this.userId, data).subscribe(() => {
            this.updateList();
            this.submitted = false;
        }, response => {
            this.errors = this.errorsTransformer.getGlobalErrorsFromResponse(response);
            this.submitted = false;
            this.updateList();
        });
    }

    closeModalWindow() {
        return this.closeModal.emit();
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

    updateDocument(document: UserProfileDocument, caption: string) {
        if (this.submitted) {
            return;
        }

        this.submitted = true;
        this.errors = [];

        this.api.updateUserDocument(document.id, {caption}).subscribe(data => {
            document.caption = caption;
            this.submitted = false;
        }, (response) => {
            this.submitted = false;
            this.errors = this.errorsTransformer.getGlobalErrorsFromResponse(response);
        });
    }

    resetDocument(document: UserProfileDocument, control: any) {
        if (this.submitted) {
            return;
        }

        control.setValue(document.caption);
    }

    deleteDocument(document: UserProfileDocument) {
        if (this.submitted) {
            return;
        }

        this.submitted = true;
        this.errors = [];

        this.api.deleteUserDocument(document.id).subscribe(() => {
            this.updateList();
            this.submitted = false;
        }, (response) => {
            this.submitted = false;
            this.errors = this.errorsTransformer.getGlobalErrorsFromResponse(response);
        });
    }

    downloadDocument(document: UserProfileDocument) {
        this.api.downloadDocument(document.id).subscribe((response: Blob) => {
                const dataType = response.type;
                const binaryData = [];
                const fileName = `${document.caption}.${document.ext}`;
                binaryData.push(response);

                downloadContent(window.URL.createObjectURL(new Blob(binaryData, {type: dataType})), fileName);
            }
        );
    }

}
