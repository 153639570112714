import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminWorkbookTemplatesList, FormConfig} from '../../../../models/models';
import {Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

@Component({
    selector: 'app-workbook-template-modal',
    templateUrl: './workbook-template-modal.component.html',
    styleUrls: ['./workbook-template-modal.component.scss']
})
export class WorkbookTemplateModalComponent implements OnInit {

    @Output() closeModal = new EventEmitter();
    @Input() workbookTemplate: AdminWorkbookTemplatesList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<{ name: any; description: any }>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'name',
                label: 'messages.admin.workbook_templates_page.form.fields.name.label',
                id: 'workbooks_template_name',
                type: 'text',
                validators: [Validators.required]
            },
            {
                name: 'description',
                label: 'messages.admin.workbook_templates_page.form.fields.description.label',
                id: 'workbooks_template_description',
                placeholder: 'messages.admin.workbook_templates_page.form.fields.description.placeholder',
                type: 'textarea'
            },
        ],
    };

    constructor(private apiService: ApiService) {
    }

    ngOnInit(): void {
    }

    editWorkbookTemplate(data: { name: any; description: any }) {
        const request = this.apiService.updateWorkbookTemplate(this.workbookTemplate.id, data);

        request.subscribe((response) => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.closeModal.emit('add');
    }
}
