import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {FormConfig, FormErrors, FormInputConfig} from '../../../../models/models';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import PropertyAccessor from '../../../../helpers/PropertyAccessor';
import {FormErrorsHandlerService} from '../../services/form-errors-handler.service';
import {MapToTypeahead} from '../../../../helpers/MapToTypeahead';
import {CollectionComponent} from '../inputs/collection/collection.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    providers: [FormErrorsHandlerService]
})
export class FormComponent<T = {}> implements OnInit {
    @Input() lists: object;
    @Input() formConfig: FormConfig;
    @Input() hideButtons = false;
    @Input() loading = false;

    @Output() closeForm = new EventEmitter();
    @Output() submitForm = new EventEmitter<T>();

    @ViewChildren(CollectionComponent) collections: QueryList<CollectionComponent>;

    public form: FormGroup;
    public submitted: boolean;
    public errors: FormErrors = {};
    private currentValue;
    private isInited = false;

    constructor(private formBuilder: FormBuilder, private errorsHandler: FormErrorsHandlerService, private cdr: ChangeDetectorRef) {
    }

    get value() {
        return this.currentValue;
    }

    @Input() set value(value: any) {
        this.currentValue = value;

        if (this.isInited) {
            this.updateFieldsValue();
        }
    }

    ngOnInit(): void {
        if (!this.formConfig || !this.formConfig.fields || !this.formConfig.fields.length) {
            throw Error('Fields are required');
        }

        this.form = this.formBuilder.group({});

        this.formConfig.fields.forEach(field => {
            const currentValue = this.getFieldValue(field);

            let control;

            if (field.type === 'collection') {
                control = this.formBuilder.array([], field.validators);
            } else {
                control = this.formBuilder.control(currentValue, field.validators);
            }


            const action = field.disabled ? 'disable' : 'enable';
            control[action]();

            this.form.addControl(field.name, control);
        });

        this.form.setValidators(this.formConfig.validators);
        this.isInited = true;
    }

    close() {
        return this.closeForm.emit();
    }

    getListValues(key): [] {
        return this.lists ? (this.lists[key] || []) : [];
    }

    submit() {
        if (this.submitted) {
            return;
        }

        const {form} = this;
        form.markAllAsTouched();

        // this.errors = this.errorsHandler.getErrors(form);
        //
        // setTimeout(() => {
        //     for (const collection of this.collections) {
        //         collection.checkErrors();
        //     }
        // });

        if (form.status === 'INVALID') {
            this.submitted = false;
            return;
        }

        this.submitted = true;

        const data = {};

        this.formConfig.fields.forEach(field => {
            let value = this.form.get(field.name).value;

            if (value === undefined) {
                return;
            }

            if (value === null) {
                data[field.name] = null;
                return;
            }

            if (Array.isArray(value) && !value.length) {
                data[field.name] = null;
                return;
            }

            // if (field.type === 'provided-entity-select') {
            //     value = (value as ProvidedEntity).id;
            // }

            if (field.type === 'typeahead') {
                if (value.hasOwnProperty('id')) {
                    value = MapToTypeahead(value);
                } else if (Array.isArray(value)) {
                    value = field.multiple ? value.map(MapToTypeahead) : MapToTypeahead(value[0]);
                }
            }

            data[field.name] = value;
        });

        this.submitForm.emit(data as T);
        // this.updateDisabledState();
    }

    unsubmit(resp: { error: FormErrors } = null) {
        this.submitted = false;
        this.errors = resp && resp.error ? this.errorsHandler.getErrorsFromResponse(this.form, resp.error) : {};
        // this.updateDisabledState();
    }

    // updateDisabledState() {
    //     this.formConfig.fields.forEach(field => {
    //         const item = this.form.get(field.name) as FormControl;
    //         const action = field.disabled || this.submitted ? 'disable' : 'enable';
    //         item[action]();
    //     });
    // }

    getFieldValue(field: FormInputConfig) {
        if (!this.value) {
            return null;
        }

        return PropertyAccessor.getValue(this.value, field.propertyPath || field.name);
    }

    updateFieldsValue() {
        if (!this.form) {
            return;
        }

        this.formConfig.fields.forEach(field => {
            const currentValue = this.getFieldValue(field);
            const control = this.form.get(field.name);

            if (control && !(control instanceof FormArray)) {
                control.setValue(currentValue);
            }
        });
    }

    public get(fieldName: string): null | FormInputConfig {
        const [v] = this.formConfig.fields.filter(item => item.name === fieldName);

        return v;
    }
}
