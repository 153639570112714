<div id="sidebar-mail">
    <div class="sidebar-header first-section">
        <i class="icon icon-size-11 ion-email color-light-green"></i>
        {{'messages.right_side.messages.title'|translate}}
    </div>
    <div class="sidebar-body">
        <accordion [isAnimated]="true" [closeOthers]="true">
            <accordion-group
                *ngFor="let message of (showDeleted ? deletedMessages() : currentMessages())"
                [isOpen]="false"
                [panelClass]="{'new-message':!message.is_read}"
            >
                <span (click)="markMessageAsRead(message)" accordion-heading>
                    <span class="ng-scope">
                        <span class="dynamic-font word-break">{{message.title}}</span>
                        <span class="msg-date float-right dynamic-font">{{message.created_at}}</span>
                        <a (click)="deleteMessage($event, message)" *ngIf="!showDeleted">
                            <i class="icon icon-size-3 ion-close color-light-green"></i>
                        </a>
                        <a (click)="undeleteMessage($event, message)" *ngIf="showDeleted" class="ng-hide">
                            <i class="icon icon-size-5 ion-refresh color-light-green"></i>
                        </a>
                    </span>
                </span>
                <div>
                    <span class="word-break" [innerHTML]="message.body"></span>
                </div>
            </accordion-group>
        </accordion>

        <div class="sidebar-right-nav margin-top-5">
            <a (click)="showDeleted=true" *ngIf="!showDeleted">
                &gt;&gt; {{'messages.right_side.messages.go_to_deleted_messages'|translate}}
            </a>
            <a (click)="showDeleted=false" *ngIf="showDeleted">
                &lt;&lt; {{'messages.right_side.messages.back_to_inbox'|translate}}
            </a>
            <div class="custom-control custom-switch float-right">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="menu_labels_switch"
                    [checked]="layoutConfigService.getEmailNotifications()"
                    (change)="onEmailNotificationChange($event.target.checked)"
                >
                <label
                    class="custom-control-label padding-top-5"
                    for="menu_labels_switch"
                >{{'messages.right_side.settings.enable_email_label' | translate}}</label>
            </div>
        </div>
    </div>
</div>
