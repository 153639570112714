<div class="page-title"><h1><i
    class="icon ion-ios-list color-light-green"
></i> {{'messages.admin.accordion.profiles_lists_values'|translate}}</h1>
</div>

<app-profile-values-form
    (formSubmit)="updateValues($event)"
    (formCancel)="onCancel()"
    *ngIf="loaded"
    [errors]="errors"
    [profileListValues]="profileListValues"
    [loading]="requestLoading"
>
</app-profile-values-form>
