<div class="welcome-page">
    <div class="text-center mb-4">
        <h1>Welcome to <span style="color: #1F8EF9;">Evo</span></h1>
        <div class="underline"></div>
        <p>Your Evo account has been confirmed: it is now active and ready to use!</p>
        <p>Our self-service proposition is still in BETA and subject to active development. Feedback will therefore be
            really appreciated.
        </p>
    </div>

    <div class="evo-card">
        <h5 class="font-weight-bold mb-3">Already starting from today, you now have free access to:</h5>
        <ul>
            <li>
                Evo University platform with 20+ courses at <a href="https://university.evouser.com/">https://university.evouser.com</a>
            </li>
            <li>
                Download link to the Excel add-in <a href="https://evo.ltd/addin">https://evo.ltd/addin</a><br>
            </li>
            <li>
                Monthly email summary of Evo insights and new content
            </li>
        </ul>
        Read about the Evo Excel add-in functionality available to you today and learn how to make the most of it
        already:
        <a href="https://resources.evopricing.com/intelligence/how-to-draw-9-professional-chart-types-with-zero-effort/">https://resources.evopricing.com/intelligence/how-to-draw-9-professional-chart-types-with-zero-effort/</a>
        <br>
        We are adding new Business Science tools and demo content
        on <a href="https://evouser.com/">https://evouser.com</a> - stay tuned for updates, expect to hear us
        about once per calendar month.
        <br>
        Welcome to our little Evo world, we are here to help! For any questions or
        feedback, please email <a href="mailto:support@evopricing.com">support@evopricing.com</a>
        <br>
        <br>
        <strong style="color: #1F8EF9;">NOTE</strong>: The Excel add-in can be installed only in Microsoft Windows.
    </div>
</div>
