import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {DisplayError, ProvidedEntity} from '../../models/models';
import {FieldsService} from '../../services/fields.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

// TODO: IMPROVE TO WORK WITH HTTP CALLS AND NOT LOAD ALL LIST AND FILTER CLIENT SIDE
@Component({
    selector: 'app-typeahead-input',
    templateUrl: './typeahead-input.component.html',
    styleUrls: ['./typeahead-input.component.scss'],
    providers: [
        FieldsService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeaheadInputComponent),
            multi: true
        }
    ]
})
export class TypeaheadInputComponent implements OnInit, ControlValueAccessor {

    field;
    value;
    currentErrors: string[];
    selectedItems: ProvidedEntity[];
    onChange: (values: ProvidedEntity[] | ProvidedEntity) => void;
    filteredOptions: ProvidedEntity[] = [];

    @ViewChild('typeaheadInput') typeaheadInput;

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() data: ProvidedEntity[];
    @Input() isFieldValid: boolean;
    @Input() disabled: boolean;
    @Input() multiple = false;

    constructor(public fieldService: FieldsService) {
    }

    filterOptions(term: string) {
        if (!term) {
            this.filteredOptions = [];
            return;
        }

        this.filteredOptions = this.data.filter(v => v.stringified.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
    }

    ngOnInit() {
        this.selectedItems = [];
        this.field = this.fieldService.getUserField(this.fieldName, this.formName);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(values: ProvidedEntity[]): void {
        this.value = '';
        this.selectedItems = [];

        if (!Array.isArray(values)) {
            return;
        }

        this.selectedItems = values.map(item => ({...item}));

        if (!this.multiple) {
            const [currentValue] = this.selectedItems;
            this.value = currentValue ? currentValue.stringified : '';
        }
    }

    onItemSelect(item: ProvidedEntity) {
        this.filteredOptions = [];

        if (!this.selectedItems.filter(selectedItem => item.id === selectedItem.id).length) {
            if (this.multiple) {
                this.selectedItems.push({...item});
                this.onChange(this.selectedItems);
            } else {
                this.selectedItems = [{...item}];
                this.onChange([{...item}]);
                this.value = item.stringified;
            }
        }

        if (this.multiple) {
            setTimeout(() => {
                this.typeaheadInput.nativeElement.value = '';
            }, 10);
        }
        // this.data.splice(this.data.indexOf($event.item), 1);
    }

    removeItem(item: ProvidedEntity) {
        // this.data.push(item);
        this.selectedItems = this.selectedItems.filter(obj => obj.id !== item.id);
        this.onChange(this.selectedItems);
    }

    checkSelection(event) {
        if (this.multiple) {
            this.value = '';
            event.target.value = '';
            return;
        }

        if (!this.selectedItems.filter(selectedItem => event.target.value === selectedItem.stringified).length) {
            this.value = '';
            event.target.value = '';
            this.selectedItems = [];
            this.onChange([]);
        }
    }
}
