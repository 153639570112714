import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-gl-icon',
    templateUrl: './gl-icon.component.html',
    styleUrls: ['./gl-icon.component.scss']
})
export class GlIconComponent implements OnInit, OnChanges {

    @Input() icon: string = null;
    @Input() size = 17;
    @Input() rotate = 0;
    @Input() left = 0;
    @Input() top = 0;

    public styles = {};

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.styles = {
            height: this.size + 'px',
            width: this.size + 'px',
            transform: 'rotate(' + this.rotate + 'deg)',
            top: this.top + 'px',
            left: this.left + 'px'
        };
    }

    get inner() {
        return `<use href="/img/gitlab-icons.svg#${this.icon}"></use>`;
    }
}
