<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [wideVariant]="true"
    [mTitle]="'messages.admin.contents_page.form.handbook_type.title_edit'|translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        [value]="value"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="updateContent($event)"
        [lists]="lists"
    >
        <div class="mb-4">
            Focus editor and press CTRL+SHIFT+F to enter/exit fullscreen mode <br>
            Alt+0 for more shortcuts
        </div>

        <div class="attachments">
            <div>
                <div *ngFor="let file of existingFiles, index as i">
                    <a [href]="file|contentAttachmentUlr" download><i class="icon ion-document-text"></i> {{file.name}}
                    </a>
                    <button type="button" class="ml-3 btn btn-xs btn-danger" (click)="removeExistingFile(file.id, i)"><i
                        class="icon ion-ios-trash"
                    ></i> {{'messages.global.delete'|translate}}</button>
                </div>
            </div>

            <div class="form-group" *ngFor="let control of filesForm.controls, index as i">
                <label [for]="'exampleFormControlFile' + i">{{'messages.attachments.new'|translate}}</label>
                <input
                    type="file"
                    class="form-control-file"
                    [id]="'exampleFormControlFile' + i"
                    (change)="control.setValue($event.target.files.length ? $event.target.files[0] : null)"
                >
                <div class="help-block mt-2" *ngIf="(errors && errors[i]) || control.invalid">
                    <ul class="list-unstyled">
                        <li *ngIf="control.errors.invalid_mime">
                            <i class="ion-android-alert"></i>&nbsp;
                            <span>This file type is not allowed!!</span>
                        </li>
                        <li *ngIf="errors[i]">
                            <i class="ion-android-alert"></i>&nbsp;
                            <span>{{errors[i]}}</span>
                        </li>
                    </ul>
                </div>
                <button type="button" class="mt-2 btn btn-xs btn-danger" (click)="removeFile(i)"><i
                    class="icon ion-ios-trash"
                ></i> {{'messages.global.delete'|translate}}</button>
            </div>

            <button
                type="button"
                class="btn btn-xs btn-primary"
                (click)="addFile()"
                *ngIf="existingFiles.length + filesForm.controls.length < 10"
            >
                <i
                    class="icon ion-plus-circled mr-1"
                ></i> {{'messages.attachments.add'|translate}}
            </button>

            <ng-container *ngIf="form.form.value?.published">
                <div *ngIf="content?.news?.with_image" class="mt-4">
                    <img
                        alt="preview"
                        [src]="'/static-content/news-preview/' + content.news.id + '.jpg'|apiKey:true"
                        class="img-thumbnail"
                        style="max-width: 300px; max-height: 300px;"
                    >
                </div>

                <div *ngIf="fileControl" class="form-group mt-4">
                    <label for="preview_image">{{'Preview image'|translate}}</label>
                    <input
                        (change)="fileControl.setValue($event.target.files.length ? $event.target.files[0] : null)"
                        accept="image/png,image/jpeg"
                        class="form-control-file"
                        id="preview_image"
                        type="file"
                    >
                    <div *ngIf="fileControl.invalid" class="help-block mt-2">
                        <ul class="list-unstyled">
                            <li *ngIf="fileControl.errors.invalid_mime">
                                <i class="ion-android-alert"></i>&nbsp;
                                <span>This file type is not allowed!!</span>
                            </li>
                        </ul>
                    </div>
                    <button
                        (click)="fileControl.setValue(null)"
                        *ngIf="fileControl.value"
                        class="mt-2 btn btn-xs btn-danger"
                        type="button"
                    ><i
                        class="icon ion-ios-trash"
                    ></i> {{'messages.global.delete'|translate}}</button>
                </div>
            </ng-container>
        </div>
    </app-form>
</app-modal>
