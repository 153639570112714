import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-page-table-tr-info',
    templateUrl: './page-table-tr-info.component.html',
    styleUrls: ['./page-table-tr-info.component.scss']
})
export class PageTableTrInfoComponent implements OnInit {

    @Input() page: any;
    @Output() openModal = new EventEmitter();
    @Output() updatePage = new EventEmitter();

    public host = window.location.protocol + '//' + window.location.host + '/share/';

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'title',
            name: 'messages.admin.pages_page.title',
            type: 'string'
        },
        {
            id: 'subtitle',
            name: 'messages.admin.pages_page.subtitle',
            type: 'string'
        },
        {
            id: 'contents',
            name: 'messages.admin.pages_page.contents',
            type: 'list'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        },
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
        if (this.page.share_id !== null) {
            this.fields.push({
                id: 'share_id',
                name: 'messages.global.table_column_share_link',
                type: 'string'
            });
        }
    }

    editPage() {
        return this.openModal.emit();
    }

    toggleActivePage() {
        this.apiService.toggleActivePage(this.page.id).subscribe(response => {
            this.updatePage.emit();
        });
    }

    deletePage() {
        this.apiService.deletePage(this.page.id).subscribe(response => {
            this.updatePage.emit();
        });
    }

    toggleManagersOnly() {
        this.apiService.togglePageManagersOnly(this.page.id).subscribe(response => {
            this.updatePage.emit();
        });
    }

    toggleGlobal() {
        this.apiService.togglePageGlobal(this.page.id).subscribe(response => {
            this.updatePage.emit();
        });
    }
}
