import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    AdminContentsList,
    Content,
    ContentModel,
    FormConfig,
    FormInputConfig,
    WebAppSrc
} from '../../../../models/models';
import {FormComponent} from '../../../form-generator/components/form/form.component';
import * as _ from 'lodash';
import {ContentFormConfig} from '../formConfigs';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-content-type-web-app',
    templateUrl: './content-type-web-app.component.html',
    styleUrls: ['./content-type-web-app.component.scss']
})
export class ContentTypeWebAppComponent implements OnInit {
    @Output() update = new EventEmitter();
    @Input() content: AdminContentsList | Content;
    public value = null;

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentWebApplication)
    };

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(1);

        this.apiService.manageContent(this.content.id).subscribe(data => {
            this.value = data;
            this.value.tag = '';
            this.value.js = [];
            this.value.css = [];
            this.value.attribute = [];

            if (data.html) {
                try {
                    const jsonVal = JSON.parse(data.html) as WebAppSrc;
                    this.value.tag = jsonVal.tag || '';
                    this.value.js = jsonVal.js || [];
                    this.value.css = jsonVal.css || [];
                    this.value.attribute = jsonVal.attribute || [];
                } catch (e) {

                }
            }

            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    updateContent(data: ContentModel) {
        const {tag, js, css, attribute, ...rest} = data;
        const reqValue = {
            ...rest,
            html: JSON.stringify({tag, js, css, attribute})
        };

        this.apiService.updateContent(this.content.id, reqValue).subscribe((response) => {
            this.update.emit(true);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }
}
