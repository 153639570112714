import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminFoldersList, FolderModel, FormConfig, FormLists, ProvidedEntity} from '../../../../models/models';
import {FormBuilder, Validators} from '@angular/forms';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

@Component({
    selector: 'app-folder-copy-modal',
    templateUrl: './folder-copy-modal.component.html',
    styleUrls: ['./folder-copy-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class FolderCopyModalComponent implements OnInit {
    @Output() addComponent = new EventEmitter();
    @Input() folder: AdminFoldersList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<FolderModel>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'parent',
                label: 'messages.admin.folders_page.form.folder.fields.parent.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.parent.placeholder',
                id: 'folder_copy_parent',
                type: 'typeahead',
                validators: [Validators.required],
            },
            {
                name: 'displayName',
                label: 'messages.admin.folders_page.form.folder.fields.display_name.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.display_name.label',
                id: 'folder_copy_name',
                type: 'text',
                validators: [Validators.required],
            },
            {
                name: 'groups',
                label: 'messages.admin.folders_page.form.folder.fields.groups.label',
                placeholder: '',
                id: 'folder_copy_group',
                type: 'typeahead',
                multiple: true
            },
            {
                name: 'toCopy',
                label: 'messages.admin.folders_page.form.folder.fields.to_copy.label',
                placeholder: '',
                id: 'folder_copy_toCopy',
                type: 'select',
                validators: [Validators.required, Validators.minLength(1)],
                multiple: true
            }
        ],
    };

    lists: FormLists = {};

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        if (!this.folder) {
            throw new Error('Folder is required!');
        }

        this.formLoadingServiceService.init(3);

        this.entityProvider.getFolders().subscribe((data: ProvidedEntity[]) => {
            this.lists.parent = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getGroups().subscribe((data: ProvidedEntity[]) => {
            this.lists.groups = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getCopyFolder(this.folder.id).subscribe(data => {
            this.lists.toCopy = data.toCopy;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addFolder(data: FolderModel) {
        const request = this.apiService.copyFolder(this.folder.id, data);

        request.subscribe((response) => {
            this.addComponent.emit();
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }
}
