import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationService} from '../../../services/pagination.service';

@Component({
    selector: 'app-table-footer',
    templateUrl: './table-footer.component.html',
    styleUrls: ['./table-footer.component.scss']
})
export class TableFooterComponent implements OnInit {
    public inactive: boolean;
    public value: boolean;

    @Input() elements: any;
    @Input() showInactiveUsersButton = false;
    @Input() withDeleteButton = true;
    @Input() withExport = true;
    @Input() withSelectAll = true;
    @Input() selectedIds: [];
    @Output() checkAllElements = new EventEmitter();
    @Output() paginateFilterSort = new EventEmitter();
    @Output() exportAll = new EventEmitter();
    @Output() exportSelected = new EventEmitter();
    @Output() deleteSelected = new EventEmitter();

    constructor(public pagerService: PaginationService) {
    }

    checkAll() {
        this.value = !this.value;
        if (this.value) {
            return this.checkAllElements.emit(true);
        } else {
            return this.checkAllElements.emit(false);
        }
    }

    ngOnInit() {
        this.inactive = false;
        this.value = false;
    }

    showInactiveUsers() {
        this.inactive = !this.inactive;
        if (this.pagerService.pager.filters) {
            this.pagerService.pager.filters = this.pagerService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }
        this.pagerService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    onPaginationChange(event) {
        this.pagerService.pager.nr_of_items_per_page = event.target.value;
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    gotToNextPage() {
        this.pagerService.pager.current_page += 1;
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    gotToLastPage() {
        this.pagerService.pager.current_page = this.pagerService.pager.last_page;
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    gotToPreviousPage() {
        this.pagerService.pager.current_page -= 1;
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    gotToFirstPage() {
        this.pagerService.pager.current_page = 1;
        this.paginateFilterSort.emit(this.pagerService.pager);
    }

    exportAllClicked() {
        this.exportAll.emit();
    }

    deleteSelectedClicked() {
        this.deleteSelected.emit();
    }

    exportSelectedClicked() {
        this.exportSelected.emit();
    }
}
