import {NgModule} from '@angular/core';
import {CommonModule as BaseModule} from '@angular/common';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {DisplayInputErrorsComponent} from './display-input-errors/display-input-errors.component';
import {FormLoadingComponent} from './form-loading/form-loading.component';
import {InputPasswordComponent} from './input-password/input-password.component';
import {InputTextComponent} from './input-text/input-text.component';
import {LoadingComponent} from './loading/loading.component';
import {ModalConfirmComponent} from './modal-confirm/modal-confirm.component';
import {ProvidedEntitySelectComponent} from './provided-entity-select/provided-entity-select.component';
import {SelectComponent} from './select/select.component';
import {SidebarLeftPanelSubscribeComponent} from './sidebar-left-panel-subscribe/sidebar-left-panel-subscribe.component';
import {TableFooterComponent} from './tableList/table-footer/table-footer.component';
import {TableTitleComponent} from './tableList/table-title/table-title.component';
import {TextareaComponent} from './textarea/textarea.component';
import {TypeaheadInputComponent} from './typeahead-input/typeahead-input.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RadioSelectComponent} from './radio-select/radio-select.component';
import {TranslationsModule} from '../modules/translations/translations.module';
import {FormSubmitedLoadingComponent} from './form-submited-loading/form-submited-loading.component';
import {FatalErrorComponent} from '../pages/common/error-pages/fatal-error/fatal-error.component';
import {TextAreaDirective} from './texarea-directive/text-area.directive';
import {AutoHeightTextareaComponent} from './auto-height-textarea/auto-height-textarea.component';
import {SafeResourceUrlPipe} from '../pipes/safe-resource-url.pipe';
import {ModalComponent} from './modal/modal.component';
import {StatusIconComponent} from './status-icon/status-icon.component';
import {CommentAttachmentUrlPipe} from '../pipes/comment-attachment-url.pipe';
import {ContentAttachmentUlrPipe} from '../pipes/content-attachment-ulr.pipe';
import {AvatarUrlPipe} from '../pipes/avatar-url.pipe';
import {StringifiedPipe} from '../pipes/stringified.pipe';
import {SentMailAttachmentUrlPipe} from '../pipes/sent-mail-attachment-url.pipe';
import {ApiKeyPipe} from '../pipes/api-key.pipe';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {GlIconComponent} from './gl-icon/gl-icon.component';
import {MarkdownPipe} from '../pipes/markdown.pipe';
import {MarkdownEditorComponent} from './markdown-editor/markdown-editor.component';
import {Select2Component} from './select2/select2.component';
import {ScrollbarModule} from '../modules/scrollbar/scrollbar.module';
import {IsUrlPipe} from '../pipes/is-url.pipe';
import {MentionModule} from "angular-mentions";


@NgModule({
    declarations: [
        DatepickerComponent,
        DisplayInputErrorsComponent,
        FormLoadingComponent,
        InputPasswordComponent,
        InputTextComponent,
        LoadingComponent,
        ModalConfirmComponent,
        ProvidedEntitySelectComponent,
        SelectComponent,
        SidebarLeftPanelSubscribeComponent,
        TableFooterComponent,
        TableTitleComponent,
        TextareaComponent,
        TypeaheadInputComponent,
        RadioSelectComponent,
        FormSubmitedLoadingComponent,
        FatalErrorComponent,
        TextAreaDirective,
        AutoHeightTextareaComponent,
        SafeResourceUrlPipe,
        ModalComponent,
        ModalComponent,
        StatusIconComponent,
        CommentAttachmentUrlPipe,
        ContentAttachmentUlrPipe,
        AvatarUrlPipe,
        SentMailAttachmentUrlPipe,
        StringifiedPipe,
        ApiKeyPipe,
        SafeHtmlPipe,
        GlIconComponent,
        MarkdownPipe,
        MarkdownEditorComponent,
        Select2Component,
        IsUrlPipe
    ],
    exports: [
        DatepickerComponent,
        DisplayInputErrorsComponent,
        FormLoadingComponent,
        InputPasswordComponent,
        InputTextComponent,
        LoadingComponent,
        ModalConfirmComponent,
        ProvidedEntitySelectComponent,
        SelectComponent,
        SidebarLeftPanelSubscribeComponent,
        TableFooterComponent,
        TableTitleComponent,
        TextareaComponent,
        TypeaheadInputComponent,
        RadioSelectComponent,
        FormSubmitedLoadingComponent,
        FatalErrorComponent,
        TextAreaDirective,
        AutoHeightTextareaComponent,
        SafeResourceUrlPipe,
        ModalComponent,
        StatusIconComponent,
        CommentAttachmentUrlPipe,
        ContentAttachmentUlrPipe,
        AvatarUrlPipe,
        SentMailAttachmentUrlPipe,
        StringifiedPipe,
        ApiKeyPipe,
        SafeHtmlPipe,
        GlIconComponent,
        MarkdownPipe,
        MarkdownEditorComponent,
        Select2Component,
        IsUrlPipe,
    ],
    imports: [
        BaseModule,
        BsDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationsModule,
        ScrollbarModule,
        MentionModule
    ]
})

export class CommonAppModule {
}
