import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';

export function MatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): ValidatorFn => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function greaterThan(targetControlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidatorFn => {
        const targetControl = formGroup.controls[targetControlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (targetControl.errors && !targetControl.errors.greaterThan) {
            return;
        }

        if (targetControl.value <= matchingControl.value) {
            targetControl.setErrors({greaterThan: {targetValue: matchingControl.value}});
        } else {
            targetControl.setErrors(null);
        }
    };
}

export function lowerThanValue(value): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return control.value > value ? {lowerThanValue: {targetValue: value}} : null;
    };
}
