import {Component, OnInit} from '@angular/core';
import {News, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    public inactive: boolean;
    public busy: boolean;
    public entities: News[];
    public selectedIds = [];
    public selectedEntity: News;
    public addEditModal: boolean;
    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'name',
            name: 'Name',
            type: 'string'
        },
        {
            id: 'slug',
            name: 'Slug',
            type: 'string'
        },
        {
            id: 'source',
            name: 'Handbook',
            type: 'string'
        },
        {
            id: 'status',
            name: 'messages.admin.pages_page.status',
            type: 'boolean'
        },
    ];
    public editEntity: News | null;
    private csvFile = 'evo-pages.csv';

    constructor(private apiService: ApiService, public paginationService: PaginationService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadList() {
        this.apiService.getNews(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: News) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity(entity: News = null) {
        this.addEditModal = true;
        this.editEntity = entity;
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const entity of this.entities) {
                this.selectedIds.push(entity.id);
                entity.checked = true;
            }
        } else {
            for (const entity of this.entities) {
                entity.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getNews(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity(entity, deleted = false) {
        this.addEditModal = false;
        this.editEntity = null;
        this.loadList();

        if (deleted) {
            const index = this.selectedIds.indexOf(entity.id);

            if (index !== -1) {
                this.selectedIds.splice(index, 1);
            }
        }
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getNews(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    closeModal() {
        this.editEntity = null;
        this.addEditModal = false;
        this.loadList();
    }

    deleteSelected() {
        this.apiService.deleteSelectedNews(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.loadList();
        });
    }
}
