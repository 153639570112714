<accordion-group
    *ngIf="categories?.length"
    [isOpen]="isRootOpened()"
>
    <a
        class="lvl-0"
        accordion-heading
    >
        <span>{{'messages.university.evo_university'|translate}}</span>
        <span class="heading-actions">
            <span class="acc-arrow">
                <i class="ion-ios-arrow-down"></i>
            </span>
        </span>
    </a>
    <div
        class="panel-body"
        style="margin-top: 1px; width: 100%"
    >
        <a
            routerLink="/app/university" class="text-break menu-link"
        >
            {{'messages.university.dashboard_label'|translate}}
        </a>
        <accordion [isAnimated]="true" [closeOthers]="true">
            <accordion-group *ngFor="let category of categories" [isOpen]="categorySlug == category.slug">
                <a
                    accordion-heading
                    class="lvl-1"
                >
                    <span class="folder-title">
                    </span>
                    <span class="word-break">{{category.name}}</span>
                    <span class="heading-actions">
                        <span class="acc-arrow">
                            <i class="ion-ios-arrow-down"></i>
                        </span>
                    </span>
                </a>
                <div
                    class="list-item lvl-1" *ngFor="let item of category.courses"
                    [ngClass]="{'selected': item.slug == courseSlug}"
                >
                    <a
                        class="list-item-link"
                        [routerLink]="'/app/courses/'+ category.slug +'/' + item.slug"
                        (click)="updSlugs(category.slug, item.slug)"
                    >
                        <span class="word-break">{{item.name}}</span>
                    </a>
                </div>
            </accordion-group>
        </accordion>
    </div>
</accordion-group>
