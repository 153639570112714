import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {
    AdminWorkbooksList,
    ContentModel,
    FormConfig,
    FormLists,
    ProvidedEntity,
    SelectValue,
    WorkbookModel
} from '../../../../models/models';
import {
    WORKBOOK_ACCESS_COMMIT_ONLY,
    WORKBOOK_ACCESS_DOWNLOAD_ONLY,
    WORKBOOK_ACCESS_REFRESH_ONLY,
    WORKBOOK_ACCESS_STANDARD,
    WORKBOOK_COMMA_CSV_SEPARATOR,
    WORKBOOK_DECIMAL_DELIMITER_COMMA,
    WORKBOOK_DECIMAL_DELIMITER_DOT,
    WORKBOOK_DOT_CSV_SEPARATOR,
    WORKBOOK_SEMICOLON_CSV_SEPARATOR
} from '../../../../services/constants';
import {TranslateService} from '@ngx-translate/core';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

@Component({
    selector: 'app-workbook-modal',
    templateUrl: './workbook-modal.component.html',
    styleUrls: ['./workbook-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class WorkbookModalComponent implements OnInit {
    @Output() addComponent = new EventEmitter();
    @Input() workbook: AdminWorkbooksList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'workbookTemplate',
                label: 'messages.admin.workbooks_page.form.workbook.fields.workbook_template.label',
                placeholder: 'messages.admin.workbooks_page.form.workbook.fields.workbook_template.placeholder',
                id: 'workbook_workbookTemplate',
                type: 'typeahead',
                validators: [Validators.required],
                listValuesKey: 'templates',
                propertyPath: 'workbook_template'
            },
            {
                name: 'accessType',
                label: 'messages.admin.workbooks_page.form.workbook.fields.read_only.label',
                id: 'workbook_accessType',
                type: 'select',
                validators: [Validators.required],
                listValuesKey: 'types',
                propertyPath: 'access_type'
            },
            {
                name: 'csvSeparator',
                label: 'messages.admin.workbooks_page.form.workbook.fields.csv_separator.label',
                id: 'workbook_csvSeparator',
                type: 'select',
                validators: [Validators.required],
                listValuesKey: 'csvSeparators',
                propertyPath: 'csv_separator'
            },
            {
                name: 'rFilePath',
                label: 'messages.admin.workbooks_page.form.workbook.fields.r_file_path.label',
                placeholder: 'messages.admin.workbooks_page.form.workbook.fields.r_file_path.placeholder',
                id: 'workbook_rFilePath',
                type: 'text',
                validators: [Validators.required],
                propertyPath: 'r_file_path'
            },
            {
                name: 'rFileName',
                label: 'messages.admin.workbooks_page.form.workbook.fields.r_file_name.label',
                id: 'workbook_rFileName',
                type: 'text',
                placeholder: 'messages.admin.workbooks_page.form.workbook.fields.r_file_name.placeholder',
                validators: [Validators.required],
                propertyPath: 'r_file_name'
            },
            {
                name: 'v2ErrorPattern',
                label: 'messages.admin.workbooks_page.form.workbook.fields.v2_error_pattern.label',
                id: 'workbook_v2ErrorPattern',
                type: 'text',
                placeholder: '',
                validators: [Validators.required],
                propertyPath: 'v2_error_pattern'
            },
            {
                name: 'executionPattern',
                label: 'messages.admin.workbooks_page.form.workbook.fields.execution_pattern.label',
                id: 'workbook_executionPattern',
                type: 'select',
                placeholder: '',
                listValuesKey: 'execPatterns',
                propertyPath: 'execution_pattern'
            },
            {
                name: 'decimalDelimiter',
                label: 'messages.admin.workbooks_page.form.workbook.fields.decimal_delimiter.label',
                id: 'workbook_decimalDelimiter',
                type: 'select',
                validators: [Validators.required],
                listValuesKey: 'delimiters',
                propertyPath: 'decimal_delimiter'
            },
            {
                name: 'company',
                label: 'messages.admin.workbooks_page.form.workbook.fields.company.label',
                placeholder: 'messages.admin.workbooks_page.form.workbook.fields.company.placeholder',
                id: 'workbook_company',
                type: 'provided-entity-select',
                listValuesKey: 'companies',
                propertyPath: 'company.id'
            },
            {
                name: 'groups',
                label: 'messages.admin.workbooks_page.form.workbook.fields.groups.label',
                placeholder: 'messages.admin.workbooks_page.form.workbook.fields.groups.placeholder',
                id: 'workbook_groups',
                type: 'typeahead',
                multiple: true
            },
            {
                name: 'name',
                label: 'messages.admin.workbooks_page.form.workbook.fields.name.label',
                placeholder: '',
                id: 'workbook_name',
                type: 'text',
                validators: [Validators.required]
            }
        ]
    };

    public isInited = false;
    public isChartLink = false;
    public isPresentationLink = false;
    public portalUrl = window.location.origin;

    lists: FormLists = {
        types: [
            {
                value: WORKBOOK_ACCESS_STANDARD,
                name: 'messages.admin.workbooks_page.form.workbook.fields.read_only.choices.standard'
            },
            {
                value: WORKBOOK_ACCESS_REFRESH_ONLY,
                name: 'messages.admin.workbooks_page.form.workbook.fields.read_only.choices.refresh_only'
            },
            {
                value: WORKBOOK_ACCESS_DOWNLOAD_ONLY,
                name: 'messages.admin.workbooks_page.form.workbook.fields.read_only.choices.download_only'
            },
            {
                value: WORKBOOK_ACCESS_COMMIT_ONLY,
                name: 'messages.admin.workbooks_page.form.workbook.fields.read_only.choices.commit_only'
            }
        ],
        delimiters: [
            {
                value: WORKBOOK_DECIMAL_DELIMITER_COMMA,
                name: ','
            },
            {
                value: WORKBOOK_DECIMAL_DELIMITER_DOT,
                name: '.'
            }
        ],
        csvSeparators: [
            {
                value: WORKBOOK_SEMICOLON_CSV_SEPARATOR,
                name: ';'
            },
            {
                value: WORKBOOK_COMMA_CSV_SEPARATOR,
                name: ','
            },
            {
                value: WORKBOOK_DOT_CSV_SEPARATOR,
                name: '.'
            },
        ]
    };

    constructor(private formBuilder: FormBuilder,
                private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                public apiService: ApiService,
                private errorsTransformer: FormErrorsTransformerService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(4);

        this.entityProvider.getCompanies().subscribe((data: ProvidedEntity[]) => {
            this.lists.companies = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getWorkbookTemplates().subscribe((data: ProvidedEntity[]) => {
            this.lists.templates = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getGroups().subscribe((data: ProvidedEntity[]) => {
            this.lists.groups = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getWorkbookExecPatterns().subscribe((data: SelectValue[]) => {
            this.lists.execPatterns = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.lists.types.forEach(item => {
            this.translateService.get(item.name).subscribe(text => {
                item.name = text;
            });
        });

        setTimeout(() => {
            const {form} = this.form;

            form.addControl('presentation', this.formBuilder.control(this.workbook ? this.workbook.presentation : ''));
            form.addControl('chart', this.formBuilder.control(this.workbook ? this.workbook.chart : ''));

            this.isInited = true;
            const {workbook} = this;

            this.changeStatusChartLink(workbook && workbook.chart);
            this.changeStatusPresentationLink(workbook && workbook.presentation);
        });
    }

    addWorkbook(data: WorkbookModel) {
        data.version = 'V2';
        data.rScriptRemoteId = '';

        if (this.form) {
            const {chart, presentation} = this.form.form.value;
            data.presentation = presentation;
            data.chart = chart;
        }

        const request = this.workbook
            ? this.apiService.updateWorkbook(this.workbook.id, data)
            : this.apiService.createWorkbook(data);

        request.subscribe(response => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    changeStatusChartLink(checked: boolean = false) {
        this.isChartLink = checked;
        const action = checked ? 'enable' : 'disable';
        this.form.form.get('chart')[action]();
    }

    changeStatusPresentationLink(checked: boolean = false) {
        this.isPresentationLink = checked;
        const action = checked ? 'enable' : 'disable';
        this.form.form.get('presentation')[action]();
    }
}
