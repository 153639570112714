import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../../../../models/models';
import {AutoHeight} from '../../../../../helpers/AutoHeight';

@Component({
    selector: 'app-auto-height-textarea',
    templateUrl: './auto-height-textarea.component.html',
    styleUrls: ['./auto-height-textarea.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AutoHeightTextareaComponent),
        multi: true
    }]
})
export class AutoHeightTextareaComponent implements OnInit, ControlValueAccessor {
    @ViewChild('area') area;
    @Input() config: FormInputConfig;
    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;

    public disabled = false;
    public touch: any;
    private currentValue = '';

    constructor() {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: any) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    ngOnInit() {
        if (this.config.autoheight) {
            this.autoHeight = AutoHeight;

            setTimeout(() => {
                this.autoHeight(this.area.nativeElement, true);
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange: any = () => {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    autoHeight = (e: any, init: boolean = false) => {
    }
}
