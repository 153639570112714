import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    AdminContentsList,
    Content,
    ContentModel,
    FormConfig,
    FormInputConfig,
    FormLists
} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../form-generator/components/form/form.component';
import * as _ from 'lodash';
import {ContentFormConfig} from '../formConfigs';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-content-type-dynamic-html-modal',
    templateUrl: './content-type-dynamic-html-modal.component.html',
    styleUrls: ['./content-type-dynamic-html-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ContentTypeDynamicHtmlModalComponent implements OnInit, OnDestroy {

    @Output() update = new EventEmitter();
    @Input() content: AdminContentsList | Content;
    public value = null;

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentDynamicHtml)
    };

    lists: FormLists = {};
    sub$: Subscription;

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
    ) {
    }

    ngOnDestroy(): void {
        if (this.sub$) {
            this.sub$.unsubscribe();
        }
    }

    ngOnInit() {
        this.formLoadingServiceService.init(2);
        this.entityProvider.getCompanies().subscribe(data => {
            this.lists.company = data;
            this.formLoadingServiceService.eventLoaded();

        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.apiService.manageContent(this.content.id).subscribe(data => {
            this.value = data;
            this.form.get('remoteId').hidden = !data.company;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        setTimeout(() => {
            const {form} = this.form;

            this.sub$ = form.get('company').valueChanges.subscribe((value) => {
                this.lists.contents = [];

                if (!value) {
                    return;
                }

                this.entityProvider.getDynamicHtml(value).subscribe(contents => {
                    this.lists = {...this.lists, contents};
                    this.form.get('remoteId').hidden = !contents || !contents.length;

                    if (form.value.remoteId
                        && !contents.filter(item => item.id.toString() === form.value.remoteId.toString()).length) {
                        form.get('remoteId').setValue(null);
                    }

                    this.checkContents();
                }, () => {
                    this.lists = {...this.lists, contents: []};
                    this.checkContents();
                });
            });
        });
    }

    updateContent(data: ContentModel) {
        this.apiService.updateContent(this.content.id, data).subscribe((response) => {
            this.update.emit(true);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }

    private checkContents() {
        if (!this.lists.contents.length) {
            this.form.unsubmit({
                error: {
                    company: ['There is no any item associated to select']
                }
            });
        } else {
            this.form.unsubmit();
        }
    }
}
