import {Component, OnInit} from '@angular/core';
import {Pager, SentEmail} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';

@Component({
    selector: 'app-sent-messages',
    templateUrl: './sent-messages.component.html',
    styleUrls: ['./sent-messages.component.scss']
})
export class SentMessagesComponent implements OnInit {
    public inactive: boolean;
    public busy: boolean;
    public entities: SentEmail[];
    public selectedIds = [];
    public selectedEntity: SentEmail;
    public addEditModal: boolean;
    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'subject',
            name: 'messages.messaging.emails.subject',
            type: 'string'
        },
    ];

    constructor(private apiService: ApiService, public paginationService: PaginationService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        this.loadList();
    }

    loadList() {
        this.busy = true;

        this.apiService.getSentEmails(this.paginationService.pager).subscribe(response => {
            this.entities = response;
            this.busy = false;
        }, () => {
            this.busy = false;
        });
    }

    selectEntity(entity: SentEmail) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getSentEmails(pager).subscribe(response => {
            this.entities = response;
        });
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getSentEmails(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }
}
