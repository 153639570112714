import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {FrontendComponent} from './frontend.component';
import {NavbarTopModule} from '../../common/navbar-top/navbar-top.module';
import {SidebarLeftModule} from '../../common/sidebar-left/sidebar-left.module';
import {SidebarRightModule} from '../../common/sidebar-right/sidebar-right.module';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from '../../pages/common/error-pages/not-found/not-found.component';
import {RoleUserGuard} from '../../guards/role-user.guard';
import {ReportComponent} from '../../pages/frontend/report/report.component';
import {CommonAppModule} from '../../common/common-app.module';
import {TranslationsModule} from '../translations/translations.module';
import {UsersProfilesListComponent} from '../../pages/frontend/users-profiles-list/users-profiles-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserProfileComponent} from '../../pages/frontend/user-profile/user-profile.component';
import {CommonModalsModule} from '../common-modals/common-modals.module';
import {ProfileValueFormComponent} from '../../pages/frontend/user-profile/profile-value-form/profile-value-form.component';
import {UserProfileRowValueComponent} from '../../pages/frontend/user-profile/user-profile-row-value/user-profile-row-value.component';
import {PasswordChangeModalComponent} from '../../pages/frontend/user-profile/password-change-modal/password-change-modal.component';
import {ProfileColumnFormComponent} from '../../pages/frontend/user-profile/profile-column-form/profile-column-form.component';
import {ProfileWorkingHoursColumnComponent} from '../../pages/frontend/user-profile/profile-working-hours-column/profile-working-hours-column.component';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {FormGeneratorModule} from '../form-generator/form-generator.module';
import {CommentsComponent} from '../../pages/frontend/report/comments/comments.component';
import {CommentFormComponent} from '../../pages/frontend/report/comments/comment-form/comment-form.component';
import {CommentComponent} from '../../pages/frontend/report/comments/comment/comment.component';
import {NgDatePipesModule, NgPipesModule} from 'ngx-pipes';
import {TeamMapComponent} from '../../pages/frontend/team-map/team-map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {CourseComponent} from '../../pages/frontend/course/course.component';
import {UniversityDashboardComponent} from '../../pages/frontend/university-dashboard/university-dashboard.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {WelcomePageComponent} from '../../pages/frontend/welcome-page/welcome-page.component';
import {OrgChartComponent} from '../../pages/administration/org-chart/org-chart.component';
import {NewsListComponent} from '../../pages/frontend/news/news-list/news-list.component';
import {NewsPageComponent} from '../../pages/frontend/news/news-page/news-page.component';
import {HandbookComponent} from '../../pages/frontend/report/types/handbook/handbook.component';
import {IframeComponent} from '../../pages/frontend/report/types/iframe/iframe.component';
import {HtmlComponent} from '../../pages/frontend/report/types/html/html.component';
import {DashboardComponent} from '../../pages/frontend/report/types/dashboard/dashboard.component';
import {DynamicHtmlComponent} from '../../pages/frontend/report/types/dynamic-html/dynamic-html.component';
import {NetworkShareComponent} from '../../pages/frontend/report/types/network-share/network-share.component';
import {DashboardChartsRendererModule} from '../dashboard-charts-renderer/dashboard-charts-renderer.module';
import {GitBoardComponent} from '../../pages/frontend/git-board/git-board.component';
import {GitColumnComponent} from '../../pages/frontend/git-board/git-column/git-column.component';
import {NewIssueFormComponent} from '../../pages/frontend/git-board/new-issue-form/new-issue-form.component';
import {IssueViewComponent} from '../../pages/frontend/git-board/issue-view/issue-view.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {IssueNoteComponent} from '../../pages/frontend/git-board/issue-view/issue-note/issue-note.component';
import {MarkdownComponent} from '../../pages/frontend/report/types/markdown/markdown.component';
import {RatingModule} from 'ngx-bootstrap/rating';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {PresentationComponent} from '../../pages/frontend/report/types/presentation/presentation.component';
import {GitlabFrameComponent} from '../../pages/frontend/gitlab-frame/gitlab-frame.component';
import {WebComponentComponent} from '../../pages/frontend/report/types/web-component/web-component.component';
import {UserVideoComponent} from '../../pages/frontend/user-profile/user-video/user-video.component';
import {PortalNAddinStatsComponent} from '../../pages/frontend/portal-n-addin-stats/portal-n-addin-stats.component';
import {IsGrantedGuard} from '../../guards/is-granted.guard';
import {ROLE_ADMIN, ROLE_USER} from '../../services/constants';
import {PortalAddinStatsComponent} from '../../pages/frontend/report/types/portal-addin-stats/portal-addin-stats.component';
import {PlansPageComponent} from '../../pages/frontend/plans-page/plans-page.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid';
import {WorkCalendarComponent} from '../../pages/frontend/work-calendar/work-calendar.component';
import {HolidaysRequestsListComponent} from '../../pages/frontend/holidays-requests-list/holidays-requests-list.component';
import {HolidayRequestFormComponent} from '../../pages/frontend/holidays-requests-list/holiday-request-form/holiday-request-form.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {IntroductionPageComponent} from '../../pages/frontend/introduction-page/introduction-page.component';
import {DataImportPageComponent} from '../../pages/frontend/data-import-page/data-import-page.component';
import {DataValidationPageComponent} from '../../pages/frontend/data-validation-page/data-validation-page.component';
import {PublicPagesComponent} from '../../pages/global/public-pages/public-pages.component';

FullCalendarModule.registerPlugins([
    dayGridPlugin,
]);

const routes: Routes = [
    {
        path: 'share/:shareId',
        component: PublicPagesComponent,
    },
    {
        path: 'app',
        component: FrontendComponent,
        canActivate: [RoleUserGuard],
        children: [
            {
                path: 'reports',
                component: ReportComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'reports/show/:folderSlug/:pageSlug',
                component: ReportComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'courses/:categorySlug/:courseSlug',
                component: CourseComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'team/calendar',
                component: WorkCalendarComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_USER,
                    isEvoEmployee: true
                }
            },
            {
                path: 'team/holidays-requests',
                component: HolidaysRequestsListComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_USER,
                    isEvoEmployee: true
                }
            },
            {
                path: 'team',
                component: UsersProfilesListComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'organization-chart',
                component: OrgChartComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'me',
                component: UserProfileComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'team/:id',
                component: UserProfileComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'university',
                component: UniversityDashboardComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'feed',
                component: NewsListComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'feed/:slug',
                component: NewsPageComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'board',
                component: GitBoardComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'board/new-request',
                component: NewIssueFormComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'board/issues/:id',
                component: IssueViewComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'gitlab',
                component: GitlabFrameComponent,
                canActivate: [RoleUserGuard]
            },
            {
                path: 'portal-n-addin-stats',
                component: PortalNAddinStatsComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_ADMIN,
                    isCompanyOwner: true
                }
            },
            {
                path: 'company-plans',
                component: PlansPageComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_ADMIN,
                    isCompanyOwner: true
                }
            },
            {
                path: 'company-onboarding/introduction-app',
                component: IntroductionPageComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_USER,
                    canSeeOnboarding: true
                }
            },
            {
                path: 'company-onboarding/data-import-app',
                component: DataImportPageComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_USER,
                    canSeeOnboarding: true
                }
            },
            {
                path: 'company-onboarding/data-validation-app',
                component: DataValidationPageComponent,
                canActivate: [IsGrantedGuard],
                data: {
                    role: ROLE_USER,
                    canSeeOnboarding: true
                }
            },
            {
                path: '**',
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    declarations: [
        FrontendComponent,
        NotFoundComponent,
        UsersProfilesListComponent,
        UserProfileComponent,
        ProfileValueFormComponent,
        UserProfileRowValueComponent,
        PasswordChangeModalComponent,
        ProfileColumnFormComponent,
        ProfileWorkingHoursColumnComponent,
        CommentsComponent,
        CommentFormComponent,
        CommentComponent,
        TeamMapComponent,
        CourseComponent,
        UniversityDashboardComponent,
        WelcomePageComponent,
        NewsListComponent,
        NewsPageComponent,
        HandbookComponent,
        ReportComponent,
        IframeComponent,
        HtmlComponent,
        DashboardComponent,
        DynamicHtmlComponent,
        NetworkShareComponent,
        PortalAddinStatsComponent,
        GitBoardComponent,
        GitColumnComponent,
        NewIssueFormComponent,
        IssueViewComponent,
        IssueNoteComponent,
        MarkdownComponent,
        PresentationComponent,
        GitlabFrameComponent,
        WebComponentComponent,
        UserVideoComponent,
        PortalNAddinStatsComponent,
        PlansPageComponent,
        WorkCalendarComponent,
        HolidaysRequestsListComponent,
        HolidayRequestFormComponent,
        IntroductionPageComponent,
        DataImportPageComponent,
        DataValidationPageComponent,
        PublicPagesComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NavbarTopModule,
        SidebarLeftModule,
        SidebarRightModule,
        CommonAppModule,
        TranslationsModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModalsModule,
        TimepickerModule.forRoot(),
        FormGeneratorModule,
        NgDatePipesModule,
        LeafletModule,
        TooltipModule,
        NgPipesModule,
        DashboardChartsRendererModule,
        SortablejsModule,
        RatingModule.forRoot(),
        ProgressbarModule.forRoot(),
        FullCalendarModule,
        NgbPaginationModule,
        PopoverModule
    ],
    providers: [],
    exports: [
        NotFoundComponent,
        CommentsComponent,
        CourseComponent,
    ],
    bootstrap: [FrontendComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class FrontendModule {
}
