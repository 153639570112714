import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-scheduling-table-tr-info',
    templateUrl: './scheduling-table-tr-info.component.html',
    styleUrls: ['./scheduling-table-tr-info.component.scss']
})
export class SchedulingTableTrInfoComponent implements OnInit {

    @Input() scheduling: any;
    @Output() openModal = new EventEmitter();
    @Output() refresh = new EventEmitter();

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    editScheduling() {
        return this.openModal.emit();
    }

    delete() {
        this.apiService.deleteScheduling(this.scheduling.id).subscribe(() => {
            this.refresh.emit();
        });
    }
}
