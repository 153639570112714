<app-user-modal
    (closeModal)="closeModal($event)"
    *ngIf="addEditModal === true"
    [modalType]="modalType"
    [user]="editEntity"
></app-user-modal>

<app-user-change-password-modal
    (changePasswordSubmit)="changePasswordSubmit($event)"
    (closeModal)="closeModal($event)"
    *ngIf="changePasswordModal === true"
    [user]="editEntity"
></app-user-change-password-modal>

<app-user-change-startup-settings-modal
    (changeStartupSettingsSubmit)="changeStartupSettings($event)"
    (closeModal)="closeModal($event)"
    *ngIf="changeStartUpSettingsModal === true"
    [user]="editEntity"
></app-user-change-startup-settings-modal>

<app-user-profile-modal
    *ngIf="editUserProfileModal === true"
    [user]="editEntity"
    (updateProfileSubmit)="updateProfileSettings()"
    (closeModal)="closeModal($event)"
>
</app-user-profile-modal>

<app-user-profile-documents-form
    *ngIf="editUserProfileDocumentsModal === true"
    [userId]="editEntity?.id"
    (closeModal)="closeModal({'type': 'documents'})"
>
</app-user-profile-documents-form>

<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.users_page.title'|translate"
        [buttonAdd]="'messages.admin.users_page.add_user'|translate"
        [importButton]="'messages.admin.users_page.import'|translate"
        search="true"
        (addComponent)="addEntity('add')"
        (paginateFilterSort)="paginateFilterSort($event)"
        (importComponent)="import($event)"
        [selectCompany]="auth.isSuperAdmin()"
    >
    </app-table-title>
    <div class="ng-binding">
        <table
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            class="table table-expandable expand-single"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                    class="col-id selected"
                >
                    <a (click)="sortByColumn('id')" style="cursor: pointer">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{
                             'selected': paginationService.pager.sorting.column === field.id,
                             'col-status': field.type === 'boolean' && field.id !== 'show_in_team',
                             'text-center': field.id === 'show_in_team',
                             'col-15': field.id === 'email',
                             'col-10': ['company_name', 'show_in_team'].includes(field.id)
                         }"
                >
                    <a (click)="sortByColumn(field.id)" style="cursor: pointer">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                    class="col-10"
                >
                    <a (click)="sortByColumn('created_at')" style="cursor: pointer">
                        {{'messages.global.table_column_created_at'|translate}}
                    </a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let entity of entities;">
                <tr
                    (click)="selectEntity(entity)"
                    [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                    class="clickable collapsed ng-scope"
                >
                    <td (click)="$event.stopPropagation()" class="col-checkbox">
                        <div class="checkbox checkbox-gray no-label">
                            <input
                                (change)="checkEntity(entity)"
                                [checked]="entity.checked"
                                [id]="'ck_' + entity.id"
                                type="checkbox"
                            >
                            <label [for]="'ck_' + entity.id">
                                            <span>
                                                <span></span>
                                            </span>
                            </label>
                        </div>
                    </td>
                    <td>{{entity.id}}</td>
                    <td>
                        {{entity.stringified}}
                        <strong *ngIf="entity.deleted_by_user">({{entity.deleted_by_user}})</strong>
                    </td>
                    <td>{{entity.company.stringified}}</td>
                    <td class="col-status">
                        <app-status-icon [status]="entity.enabled"></app-status-icon>
                    </td>
                    <td class="col-status">
                        <app-status-icon [status]="entity.from_ldap"></app-status-icon>
                    </td>
                    <td class="col-status">
                        <app-status-icon [status]="entity.show_in_team"></app-status-icon>
                    </td>
                    <td>{{entity.email}}</td>
                    <td>{{entity.created_at}}</td>
                </tr>
                <tr [ngClass]="{'expanded': selectedEntity?.id === entity.id}" class="row-expansion ng-scope">
                    <td></td>
                    <td></td>
                    <td colspan="7">
                        <app-user-table-tr-info
                            (openChangePasswordModal)="openChangePasswordModal(entity)"
                            (openModal)="addEntity('edit', entity)"
                            (openStartUpSettingsModal)="openStartUpSettingsModal(entity)"
                            (openProfileModal)="openProfileModal(entity)"
                            (openProfileDocumentsModal)="openProfileDocumentsModal(entity)"
                            (updateUser)="updateEntity()"
                            [user]="entity"
                        >

                        </app-user-table-tr-info>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="entities?.length === 0">
                <tr>
                    <td colspan="2"></td>
                    <td colspan="7">
                                <span class="text-danger">
                                    <strong>{{'messages.admin.users_page.not_found'|translate}}</strong>
                                </span>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        (checkAllElements)="checkAllEntities($event)"
        (deleteSelected)="deleteSelected()"
        (exportAll)="exportAll()"
        (exportSelected)="exportSelected()"
        (paginateFilterSort)="paginateFilterSort($event)"
        [elements]="entities"
        [selectedIds]="selectedIds"
        [showInactiveUsersButton]="auth.isSuperAdmin()"
        [withDeleteButton]="auth.isSuperAdmin()"
    ></app-table-footer>
</div>
