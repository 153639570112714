<app-addin-version-modal
    *ngIf="addEditModal === true"
    (addComponent)="closeModal('')"
    [version]="editEntity"
>
</app-addin-version-modal>

<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.xls_page.title'|translate"
        [buttonAdd]="'messages.admin.xls_page.add_version'|translate"
        search="true"
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
        [api]="true"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    class="col-id selected"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn('id')">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-status': field.id === 'status',
                        'col-10': field.id === 'description' || field.id === 'is_supported_version', 'text-center': field.id === 'is_supported_version'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn(field.id)">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    class="col-10"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                >
                    <a
                        style="cursor: pointer"
                        (click)="sortByColumn('created_at')"
                    >{{'messages.admin.xls_page.table.th.created_at'|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id">
                                        <span>
                                            <span></span>
                                        </span>
                        </label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>
                    {{entity.version}}
                </td>
                <td>{{entity.description}}</td>
                <td class="col-status">
                    <app-status-icon [status]="entity.is_active"></app-status-icon>
                </td>
                <td class="col-status">
                    <app-status-icon [status]="entity.is_supported_version"></app-status-icon>
                </td>
                <td>{{entity.created_at}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="5">
                    <app-addin-version-table-tr-info
                        [version]="entity"
                        (openModal)="addEntity(entity)"
                        (refresh)="updateEntity()"
                    ></app-addin-version-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>
</div>
