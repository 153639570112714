import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {AdminAddinVersionsList, DisplayError} from '../../../../models/models';
import {FieldsService} from '../../../../services/fields.service';

@Component({
    selector: 'app-addin-version-modal',
    templateUrl: './addin-version-modal.component.html',
    styleUrls: ['./addin-version-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class AddinVersionModalComponent implements OnInit {

    @Input() version: AdminAddinVersionsList;
    @Output() addComponent = new EventEmitter();

    public errors: DisplayError[] = [];
    public submitted: boolean;
    public form: FormGroup;
    private formName = 'version';

    constructor(private formBuilder: FormBuilder,
                private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
                private errorsTransformer: FormErrorsTransformerService,
                private fieldsService: FieldsService) {
    }

    ngOnInit() {
        this.submitted = false;
        this.form = this.formBuilder.group({
            description: ['', [Validators.required]],
            version: ['', [Validators.required]]
        });

        const {version} = this;

        if (version) {
            this.form.get('description').setValue(version.description);
            this.form.get('version').setValue(version.version);
        } else {
            this.apiService.getLastVersion().subscribe((data: { version: string }) => {
                this.form.get('version').setValue('- ().' + (parseInt(data.version, 10) + 1));
            });
        }
    }

    isFieldValid(field: string) {
        return this.form.get(field).invalid;
    }

    addVersion(e: any) {
        if (e) {
            e.preventDefault();
        }

        if (this.submitted) {
            return;
        }

        this.errors = this.errorsTransformer.getErrors(this.form);

        if (this.form.status !== 'VALID') {
            return;
        }

        this.submitted = true;

        const {description, version} = this.form.value;
        const data = {caption: description, version};

        const request = this.version
            ? this.apiService.updateVersion(this.version.id, data)
            : this.apiService.createVersion(data);

        request.subscribe((response) => {
            this.closeModalWindow();
        }, (response) => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, response);
            this.submitted = false;
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

    getFieldErrors(fieldName: string) {
        return this.fieldsService.getFieldsErrors(this.errors, fieldName, this.formName);
    }
}
