import * as d3 from 'd3';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GroupByFunctionsService {
    private functions = d3.map([], (d: any) => {
        return d.name;
    });

    constructor() {
        this.functions.set('sum', {
            fn(xDimension: any, dataSerie: any) {
                return {
                    group: xDimension.group().reduceSum((d: any) => {
                        return d[dataSerie.columnName];
                    }),
                    valueAccessor(d: any) {
                        const value = d.value;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('avg', {
            fn(xDimension: any, dataSerie: any) {
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            ++p.count;
                            p.total += v[dataSerie.columnName];
                            p.finalVal = p.count > 0 ? (p.total / p.count) : 0;
                            return p;
                        },
                        function reduceRemove(p: any, v: any) {
                            --p.count;
                            p.total -= v[dataSerie.columnName];
                            p.finalVal = p.count > 0 ? (p.total / p.count) : 0;
                            return p;
                        },
                        function reduceInitial() {
                            return {count: 0, total: 0, finalVal: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        const value = d.value.count > 0 ? (d.value.total / d.value.count) : 0;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('min', {
            fn(xDimension: any, dataSerie: any) {
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            if (v[dataSerie.columnName] < p.min) {
                                p.min = v[dataSerie.columnName];
                            }
                            p.finalVal = p.min;
                            return p;
                        },
                        function reduceRemove(p: any) {
                            return p;
                        },
                        function reduceInitial() {
                            return {min: Number.MAX_VALUE, finalVal: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        const value = d.value.min;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('max', {
            fn(xDimension: any, dataSerie: any) {
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            if (v[dataSerie.columnName] > p.max) {
                                p.max = v[dataSerie.columnName];
                            }
                            p.finalVal = p.max;
                            return p;
                        },
                        function reduceRemove(p: any) {
                            return p;
                        },
                        function reduceInitial() {
                            return {max: Number.MIN_VALUE, finalVal: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        const value = d.value.max;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('count', {
            fn(xDimension: any) {
                return {
                    group: xDimension.group().reduceCount(),
                    valueAccessor(d: any) {
                        const value = d.value;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('count-distinct', {
            fn(xDimension: any, dataSerie: any) {
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            p.objects.push(v);
                            if (p.uniques.indexOf(v[dataSerie.columnName]) < 0) {
                                p.uniques.push(v[dataSerie.columnName]);
                            }
                            p.count = p.uniques.length;
                            p.finalVal = p.count;
                            return p;
                        },
                        function reduceRemove(p: any, v: any) {
                            let i;
                            for (i = 0; i < p.objects.length; i++) {
                                if (p.objects[i] === v) {
                                    p.objects.splice(i, 1);
                                }
                            }

                            p.uniques = _.uniqBy(p.objects, dataSerie.columnName);
                            p.count = p.uniques.length;
                            p.finalVal = p.count;
                            return p;
                        },
                        function reduceInitial() {
                            return {count: 0, uniques: [], objects: [], finalVal: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        const value = d.value.count;
                        return Math.round(value * 100) / 100;
                    }
                };
            }
        });
        this.functions.set('weighted-sum', {
            fn(xDimension: any, dataSerie: any) {
                const weightedColumnName = dataSerie.groupBy.weightByColumnName;
                if (!weightedColumnName) {
                    throw new Error('Weight by column name is missing');
                }
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            p.multipliedSum += (v[dataSerie.columnName] * v[weightedColumnName]);
                            p.sum = parseFloat(p.sum.toFixed(GroupByFunctionsService.countDecimals(p.sum))) +
                                +parseFloat(v[weightedColumnName].toFixed(GroupByFunctionsService.countDecimals(p.sum)));
                            if (p.sum === 0) {
                                p.finalVal = 0;
                            } else {
                                p.finalVal = p.multipliedSum / p.sum;
                            }
                            return p;
                        },
                        function reduceRemove(p: any, v: any) {
                            p.multipliedSum -= (v[dataSerie.columnName] * v[weightedColumnName]);
                            p.sum = parseFloat(p.sum.toFixed(GroupByFunctionsService.countDecimals(p.sum))) -
                                +parseFloat(v[weightedColumnName].toFixed(GroupByFunctionsService.countDecimals(p.sum)));
                            if (p.sum === 0) {
                                p.finalVal = 0;
                            } else {
                                p.finalVal = p.multipliedSum / p.sum;
                            }
                            return p;
                        },
                        function reduceInitial() {
                            return {multipliedSum: 0, sum: 0, finalVal: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        /** if is exponential **/
                        let exponentialRegEx = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g
                        if(d.value.finalVal.toString().match(exponentialRegEx)) {
                            d.value.finalVal = 0;
                        }
                        return d.value.finalVal;
                    }
                };
            }
        });
        this.functions.set('delta-weighted-sum', {
            fn(xDimension: any, dataSerie: any) {
                const weightedColumnName = dataSerie.groupBy.weightByColumnName;
                const weightedColumnName2 = dataSerie.groupBy.weightByColumnName2;
                if (!weightedColumnName) {
                    throw new Error('Weight by column name is missing');
                }
                if (!weightedColumnName2) {
                    throw new Error('Weight by column name 2 is missing');
                }
                return {
                    group: xDimension.group().reduce(
                        function reduceAdd(p: any, v: any) {
                            p.multipliedSum += (v[dataSerie.columnName] * v[weightedColumnName]);
                            p.sum = parseFloat(p.sum.toFixed(GroupByFunctionsService.countDecimals(p.sum))) +
                                +parseFloat(v[weightedColumnName].toFixed(GroupByFunctionsService.countDecimals(p.sum)));
                            if (p.sum === 0) {
                                p.finalVal = 0;
                            } else {
                                p.finalVal = p.multipliedSum / p.sum;
                            }

                            p.multipliedSum2 += (v[dataSerie.columnName2] * v[weightedColumnName2]);
                            p.sum2 = parseFloat(p.sum2.toFixed(GroupByFunctionsService.countDecimals(p.sum2))) +
                                +parseFloat(v[weightedColumnName2].toFixed(GroupByFunctionsService.countDecimals(p.sum2)));
                            if (p.sum2 === 0) {
                                p.finalVal2 = 0;
                            } else {
                                p.finalVal2 = p.multipliedSum2 / p.sum2;
                            }
                            return p;
                        },
                        function reduceRemove(p: any, v: any) {
                            p.multipliedSum -= (v[dataSerie.columnName] * v[weightedColumnName]);
                            p.sum = parseFloat(p.sum.toFixed(GroupByFunctionsService.countDecimals(p.sum))) -
                                +parseFloat(v[weightedColumnName].toFixed(GroupByFunctionsService.countDecimals(p.sum)));
                            if (p.sum === 0) {
                                p.finalVal = 0;
                            } else {
                                p.finalVal = p.multipliedSum / p.sum;
                            }

                            p.multipliedSum2 -= (v[dataSerie.columnName2] * v[weightedColumnName2]);
                            p.sum2 = parseFloat(p.sum2.toFixed(GroupByFunctionsService.countDecimals(p.sum2))) -
                                +parseFloat(v[weightedColumnName2].toFixed(GroupByFunctionsService.countDecimals(p.sum2)));
                            if (p.sum2 === 0) {
                                p.finalVal2 = 0;
                            } else {
                                p.finalVal2 = p.multipliedSum2 / p.sum2;
                            }
                            return p;
                        },
                        function reduceInitial() {
                            return {multipliedSum: 0, sum: 0, finalVal: 0, multipliedSum2: 0, sum2: 0, finalVal2: 0};
                        }
                    ),
                    valueAccessor(d: any) {
                        /** if is exponential **/
                        let exponentialRegEx = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g
                        if(d.value.finalVal.toString().match(exponentialRegEx)) {
                            d.value.finalVal = 0;
                        }
                        /** if is exponential **/
                        if(d.value.finalVal2.toString().match(exponentialRegEx)) {
                            d.value.finalVal2 = 0;
                        }
                        return d.value.finalVal - d.value.finalVal2;
                    }
                };
            }
        });
    }

    private static countDecimals(value) {
        if ((value % 1) !== 0) {
            return value.toString().split('.')[1].length;
        }
        return 0;
    }

    public has(name: string): boolean {
        return this.functions.has(name);
    }

    public get(name: string) {
        return this.functions.get(name);
    }
}
