import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class GaRouteTrackerService {

    constructor(private router: Router, private  googleAnalytics: GoogleAnalyticsService, private auth: AuthService) {
    }

    public startTrack() {
        this.router.events.subscribe((event: RouterEvent) => {
                if (event instanceof NavigationEnd) {
                    const userId = this.auth.getLoggedUser() ? this.auth.getLoggedUser().id : null;
                    this.googleAnalytics.pageView(event.urlAfterRedirects, null, window.location.href, {userId});
                }
            }
        );
    }
}
