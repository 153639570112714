import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './auth.service';
import {DEFAULT_LOCALE} from './constants';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    constructor(private translate: TranslateService, private authService: AuthService) {
        this.translate.setDefaultLang('en');
        this.authService.loggedUserSubject.subscribe(() => {
            this.syncLocale();
        });

        this.syncLocale();
    }

    syncLocale() {
        if (this.authService.getLoggedUser()) {
            this.translate.use(this.authService.getLoggedUser().locale || DEFAULT_LOCALE);
        }
    }

    setLocale(locale: string) {
        this.translate.use(locale);
    }
}
