import {Pipe, PipeTransform} from '@angular/core';
import {FormInputConfig} from '../../../models/models';
import PropertyAccessor from '../../../helpers/PropertyAccessor';

@Pipe({
    name: 'controlValue'
})
export class ControlValuePipe implements PipeTransform {

    transform(value: any, field: FormInputConfig): unknown {
        if (!value) {
            return null;
        }

        return PropertyAccessor.getValue(value, field.propertyPath || field.name);
    }

}
