<ng-template #popref>
    <div class="datetime-picker" (click)="$event.stopPropagation()">
        <ngb-datepicker [(ngModel)]="date"></ngb-datepicker>
        <ngb-timepicker *ngIf="withTime" [(ngModel)]="time"></ngb-timepicker>
        <div class="text-center mt-2">
            <button
                class="btn btn-primary btn-square btn-sm"
                (click)="$event.stopPropagation(); submitDatetime()"
            >Ok
            </button>
        </div>
    </div>
</ng-template>

<div class="mb-8">
    <label
        [for]="id"
        [style.color]="!isFieldValid ? '#a94442' :null"
        class="control-label"
    >{{(label)|translate}}</label>
    <div class="input-group datepicker-container" (click)="$event.stopPropagation(); openForm()">
        <input
            class="form-control datepicker-input"
            [id]="id"
            [value]="value"
            [popover]="popref"
            [isOpen]="isOpen"
            disabled
        >
        <div class="input-group-btn" style="width: auto">
            <button
                class="btn btn-default"
                style="margin: 0; border: 0"
                type="button"
            >
                <i class="ion-ios-calendar"></i>
            </button>
        </div>
    </div>

    <ng-content>
    </ng-content>
</div>
