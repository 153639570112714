import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {AdminGroupsList, FormConfig, FormLists, ProvidedEntity} from '../../../../models/models';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

interface GroupForm {
    folders: any;
    name: any;
    company: any;
    users: any;
}

@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class GroupModalComponent implements OnInit {
    @Input() modalType;
    @Input() group: AdminGroupsList;
    @Output() closeModal = new EventEmitter();
    @ViewChild(FormComponent, {static: true}) form: FormComponent<GroupForm>;

    public errors;
    public formConfig: FormConfig = {
        fields: [
            {
                name: 'company',
                label: 'messages.admin.groups_page.form.group.fields.company.label',
                placeholder: 'messages.admin.groups_page.form.group.fields.company.placeholder',
                id: 'select_company',
                type: 'provided-entity-select',
                propertyPath: 'company.id'
            },
            {
                name: 'folders',
                label: 'messages.admin.groups_page.form.group.fields.folders.label',
                placeholder: '',
                id: 'group_folders',
                type: 'typeahead',
                multiple: true
            },
            {
                name: 'name',
                label: 'messages.admin.groups_page.form.group.fields.name.label',
                placeholder: 'messages.admin.groups_page.form.group.fields.name.label',
                id: 'group_name',
                type: 'text'
            },
            {
                name: 'users',
                label: 'messages.admin.groups_page.form.group.fields.users.label',
                placeholder: '',
                id: 'group_users',
                type: 'typeahead',
                multiple: true
            }
        ],
    };
    lists: FormLists = {};

    constructor(private formBuilder: FormBuilder,
                private entityProvider: EntityProviderService,
                private errorsTransformer: FormErrorsTransformerService,
                private apiService: ApiService,
                public  formLoadingServiceService: FormLoadingServiceService) {
    }

    ngOnInit() {

        this.formLoadingServiceService.init(3);
        this.entityProvider.getCompanies().subscribe((data: ProvidedEntity[]) => {
            this.lists.company = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
        this.entityProvider.getFolders().subscribe((data: ProvidedEntity[]) => {
            this.lists.folders = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
        this.entityProvider.getUsers().subscribe((data: ProvidedEntity[]) => {
            this.lists.users = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

    }

    addGroup(data: GroupForm) {
        const request = this.group
            ? this.apiService.updateGroup(this.group.id, data)
            : this.apiService.createGroup(data);

        request.subscribe((response) => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.closeModal.emit('add');
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

}
