import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ReportsService} from '../../../../services/reports.service';
import {PageComment} from '../../../../models/models';
import {AuthService} from '../../../../services/auth.service';
import {CommentsService} from '../../../../services/comments.service';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
    comments: PageComment[] = [];
    fetchInterval = null;

    constructor(private api: ApiService,
                private reportsService: ReportsService,
                public auth: AuthService,
                private commentsService: CommentsService) {
    }

    ngOnInit(): void {
        this.fetchComments(true);
        // this.fetchInterval = setInterval(() => this.fetchComments(), 60000);
    }

    ngOnDestroy(): void {
        // clearInterval(this.fetchInterval);
    }

    fetchComments(init = false) {
        if (this.commentsService.replying) {
            return;
        }

        this.api.getComments(this.reportsService.getSelectedReport().id).subscribe(data => {
            this.comments = data;

            if (init) {
                setTimeout(() => {
                    if (window.location.hash) {
                        const el = document.querySelector('.page-center-column ' + window.location.hash);

                        if (!el) {
                            return;
                        }

                        el.scrollIntoView();
                        el.classList.add('highlight');

                        setTimeout(() => {
                            el.classList.remove('highlight');
                        }, 7000);
                    }
                });
            }
        });
    }

    handleChildUpdate(data: PageComment, index: number) {
        this.comments.splice(index, 1, data);
    }

    handleChildDelete(child: PageComment) {
        if (!Array.isArray(this.comments)) {
            return;
        }

        this.comments = this.comments.filter(item => item !== child);
    }

    handleClose(data = null) {
        if (!this.comments) {
            this.comments = [];
        }

        this.comments.push(data);
    }
}
