<div class="row-expansion-body">
    <div *ngFor="let field of fields; let index = field" class="form-group">
        <label class="control-label">{{field.name|translate}}:</label>
        <p *ngIf="field.id !== 'settings.remember_session'" class="form-control-static">
            {{user[field.id] !== null ? user[field.id] : ' -- '}}
        </p>
        <p *ngIf="field.id === 'settings.remember_session'" class="form-control-static">
            <span *ngIf="user[field.id]">
                {{'messages.admin.users_page.form.settings.fields.remember_session.choices.remember_last_session'|translate}}
            </span>
            <span *ngIf="!user[field.id]">
                {{'messages.admin.users_page.form.settings.fields.remember_session.choices.open_this_item'|translate}}
            </span>
        </p>
    </div>
    <div class="text-right">
        <button
            (click)="editUser()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_edit_{{user.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <ng-container *ngIf="auth.isSuperAdmin() || auth.isCompanyOwner()">
            <button
                (click)="editProfile()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="users_edit_profile_{{user.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-person"></i>
                {{'messages.admin.users_page.edit_profile'|translate}}
            </button>
            <button
                (click)="editDocuments()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="users_edit_profile_documents_{{user.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-document-text"></i>
                {{'messages.profile_documents.documents'|translate}}
            </button>
        </ng-container>
        <button
            (click)="changePassword()"
            [disabled]="user.email.indexOf('.local') > 0"
            [title]="user.email.indexOf('.local') > 0 ? 'LDAP accounts cannot change password from the Portal' : 'Change user password'"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_changes_password_{{user.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-locked"></i>
            {{'messages.admin.users_page.form.user.title_change_password'|translate}}
        </button>
        <button
            (click)="toggleStatus()"
            [disabled]="toggleStatusDisabled"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_toggle_status_{{user.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.users_page.toggle_status'|translate}}
        </button>
        <button
            (click)="toggleShowInTeam()"
            [disabled]="toggleStatusDisabled"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_toggle_show_in_team_{{user.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.users_page.toggle_show_in_team'|translate}}
        </button>
        <button
            (click)="changeStartUpSettings()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_change_startup_settings_{{user.id}}"
            type="button"
        >
            <i class="icon icon-size-9 ion-ios-gear"></i>
            {{'messages.admin.users_page.change_startup_setttings'|translate}}
        </button>
        <app-modal-confirm
            *ngIf="auth.isSuperAdmin()"
            [confirmMessage]="user.deleted_by_user != null ? ('messages.global.confirm_undelete_message'|translate) : null"
        >
            <button
                (click)="deleteUser()"
                *ngIf="user.deleted_by_user == null"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="users_delete_{{user.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.admin.users_page.delete_user'|translate}}
            </button>
            <button
                (click)="unDeleteUser()"
                *ngIf="user.deleted_by_user != null"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="users_undelete_{{user.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.admin.users_page.undelete_user'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
