<app-modal
    [mTitle]="'messages.profile_documents.documents'|translate"
    (mClose)="closeModalWindow()"
>
    <app-form-loading [loading]="formLoadingService.isLoading()"></app-form-loading>
    <div class="form-style-bordered-inputs" *ngIf="!formLoadingService.isLoading()">
        <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>
        <div
            *ngFor="let list of form.controls; index as i"
            class="form-group"
        >
            <div class="file-row">
                <input
                    type="text"
                    class="form-control"
                    [formControl]="list.controls.caption"
                >
                <ng-container *ngIf="list.value.caption !== documents[i].caption">
                    <i
                        class="icon icon-size-10 ion-checkmark-circled color-light-green"
                        (click)="updateDocument(documents[i], list.controls.caption.value)"
                    ></i>
                    <i
                        class="icon icon-size-10 ion-close-circled color-red"
                        (click)="resetDocument(documents[i], list.controls.caption)"
                    ></i>
                </ng-container>
            </div>
            <button
                class="btn btn-square btn-xs btn-primary margin-left-5"
                type="button"
                (click)="downloadDocument(documents[i])"
            >
                <i class="icon ion-android-download"></i> {{'messages.admin.xls_page.table.td.download'|translate}}
            </button>
            <app-modal-confirm>
                <button
                    class="btn btn-square btn-xs btn-danger margin-left-5"
                    type="button"
                    (click)="deleteDocument(documents[i])"
                ><i class="icon ion-trash-a"></i> {{'messages.global.delete'|translate}}
                </button>
            </app-modal-confirm>
        </div>
    </div>

    <div class="modal-footer" #modalFooter>
        <app-form-submited-loading *ngIf="submitted"></app-form-submited-loading>
        <input type="file" style="display: none" multiple #uploadFile (change)="uploadDocument($event)">
        <button class="btn btn-sm btn-primary" (click)="uploadFile.click()" type="button">
            <i class="icon ion-plus"></i> {{'messages.admin.xls_page.table.td.upload'|translate}}
        </button>
        <button class="btn btn-sm btn-warning" (click)="closeModalWindow()" type="button">
            {{'messages.global.close'|translate}}
        </button>
    </div>
</app-modal>
