import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ROLE_SUPER_ADMIN} from '../services/constants';

@Injectable()
export class IsSuperAdminOrCompanyOwnerGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (!this.auth.isAuthenticated()) {
            const queryParams = {
                redirect: this.router.getCurrentNavigation().finalUrl.toString()
            };

            this.auth.redirectCount++;
            this.router.navigate(['login'], {queryParams});

            return false;
        }

        const passed = this.auth.isGranted(ROLE_SUPER_ADMIN) || (this.auth.getLoggedUser() && this.auth.getLoggedUser().isCompanyOwner);

        if (!passed) {
            this.router.navigate(['/404']);
        }

        return passed;
    }
}
