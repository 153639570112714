import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {InfoColumn, ProfileData, UserProfileDocument} from '../../../models/models';
import {ActivatedRoute} from '@angular/router';
import {downloadContent} from '../../../helpers/DownloadContent';
import {AuthService} from '../../../services/auth.service';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {Title} from '@angular/platform-browser';
import {DEFAULT_PAGE_TITLE} from '../../../services/constants';
import {ProfileSecurityService} from '../../../services/profile-security.service';
import {AvatarUrlPipe} from '../../../pipes/avatar-url.pipe';
import {WcResourcesInjectorService} from "../../../services/wc-resources-injector.service";
import {environment} from "../../../../environments/environment";

const NUMBER_TYPE = 'number';
const LIST_TYPE = 'list';
const STRING_TYPE = 'string';
const DATE_TYPE = 'date';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    providers: [
        AvatarUrlPipe
    ]
})
export class UserProfileComponent implements OnInit, OnDestroy {

    profileId: any = null;
    profile: ProfileData = null;
    showDocumentsFrom = false;
    showPasswordUpdate = false;
    submitted = false;
    avatar = null;
    currentUserEmail = '';

    infoColumns: InfoColumn[] = [
        {
            label: 'messages.profile_page.about_me',
            icon: 'ion-android-person',
            type: 'form',
            rows: [
                {key: 'firstName', type: STRING_TYPE, editable: true},
                {key: 'lastName', type: STRING_TYPE, editable: true},
                {key: 'gender', listValuesKey: 'genders', type: LIST_TYPE, editable: true},
                {key: 'department', type: LIST_TYPE, listValuesKey: 'departments'},
                {key: 'role', type: LIST_TYPE, listValuesKey: 'roles'},
                {key: 'level', listValuesKey: 'levels', type: LIST_TYPE},
                {key: 'intensity', listValuesKey: 'intensities', type: LIST_TYPE, editable: true},
                {key: 'commitment', listValuesKey: 'commitments', type: LIST_TYPE},
                {key: 'joiningDate', type: DATE_TYPE},
                {key: 'leaveDate', type: DATE_TYPE},
                {key: 'sourceOfHiring', listValuesKey: 'sourcesOfHiring', type: LIST_TYPE, private: true},
                {key: 'lineManager', type: LIST_TYPE, listValuesKey: 'lineManagers'},
                {key: 'hiringManager', listValuesKey: 'hiringManagers', type: LIST_TYPE, private: true},
                {key: 'dateOfBirth', type: DATE_TYPE, editable: true},
                {key: 'maritalStatus', listValuesKey: 'maritalStatuses', type: LIST_TYPE, editable: true},
                {key: 'dietary', listValuesKey: 'dietaries', type: LIST_TYPE, editable: true, private: true},
                {key: 'tenure', calculated: true},
                {key: 'bio', type: STRING_TYPE},
                {key: 'review', type: STRING_TYPE, forManagers: true},
            ],
        },
        {
            label: 'messages.profile_page.my_contacts',
            icon: 'ion-ios-telephone',
            type: 'form',
            rows: [
                {key: 'evoEmail', type: STRING_TYPE, editable: true},
                {key: 'personalEmail', type: STRING_TYPE, private: true, editable: true},
                {key: 'phone', type: STRING_TYPE, editable: true},
                {key: 'mobile', type: STRING_TYPE, editable: true},
                {key: 'linkedInProfile', type: STRING_TYPE, editable: true},
                {key: 'googleAccount', type: STRING_TYPE, editable: true},
                {key: 'gitlabUsername', type: STRING_TYPE, editable: true},
                {key: 'githubAccount', type: STRING_TYPE, editable: true},
                {key: 'skype', type: STRING_TYPE, editable: true},
            ]
        },
        {
            label: 'messages.profile_page.workingHours',
            icon: 'ion-ios-calendar-outline',
            type: 'workingHours',
        },
        {
            label: 'messages.profile_page.where_i_live',
            icon: 'ion-android-globe',
            type: 'form',
            rows: [
                {key: 'country', type: LIST_TYPE, listValuesKey: 'countries', editable: true},
                {key: 'state', type: STRING_TYPE, editable: true},
                {key: 'province', type: STRING_TYPE, editable: true},
                {key: 'town', type: STRING_TYPE, editable: true},
                {key: 'zipCode', type: STRING_TYPE, editable: true},
                {key: 'address', type: STRING_TYPE, editable: true, info: 'Address format is "Street Name + Street Number"'},
                {key: 'address2', type: STRING_TYPE, editable: true, info: 'Apartment Number if exists'},
            ]
        },
        {
            label: 'messages.profile_page.expertise',
            icon: 'ion-android-happy',
            type: 'form',
            rows: [
                {key: 'capabilityOfSkills', type: STRING_TYPE, editable: true},
                {key: 'topicOfExpertise', type: STRING_TYPE, editable: true},
                {key: 'achievements', type: STRING_TYPE, editable: true},
                {key: 'badges', listValuesKey: 'badges', type: LIST_TYPE, multiple: true},
                {key: 'hobbies', type: STRING_TYPE, editable: true},
            ]
        },
        {
            label: 'messages.profile_page.learning',
            icon: 'ion-ios-book',
            type: 'form',
            rows: [
                {key: 'studies', type: STRING_TYPE, editable: true},
                {key: 'highestDegree', type: STRING_TYPE, editable: true},
                {key: 'degreeScore', type: STRING_TYPE, editable: true},
                {key: 'degreeMaxScore', type: STRING_TYPE, editable: true},
                {key: 'degreeAchievementPlace', type: STRING_TYPE, editable: true},
                {key: 'degreeTown', type: STRING_TYPE, editable: true},
                {key: 'degreeDate', type: DATE_TYPE, editable: true},
                {key: 'languages', type: STRING_TYPE, editable: true},
                {key: 'learningCourses', type: STRING_TYPE, editable: true},
                {key: 'webinarsSeminars', type: STRING_TYPE, editable: true},
                {key: 'daysOfLearning', type: NUMBER_TYPE},
                {key: 'daysOfDissemination', type: NUMBER_TYPE},
                {key: 'budgetSpend', type: NUMBER_TYPE},
            ]
        },
        {label: 'messages.profile_documents.documents', type: 'documents'},
        {
            label: 'messages.profile_page.emergency_contact',
            icon: 'ion-ios-contact',
            type: 'form',
            rows: [
                {key: 'emergencyPerson', type: STRING_TYPE, editable: true, private: true},
                {key: 'emergencyPersonPhone', type: STRING_TYPE, editable: true, private: true},
                {
                    key: 'emergencyPersonRelationship',
                    listValuesKey: 'emergencyPersonRelationships',
                    type: LIST_TYPE,
                    private: true
                },
            ]
        },
        {
            label: 'messages.profile_page.system_info',
            icon: 'ion-android-settings',
            type: 'form',
            rows: [
                {key: 'id', calculated: true, private: true},
                {key: 'status', calculated: true, private: true},
                {key: 'locale', type: LIST_TYPE, listValuesKey: 'locales', editable: true},
                {key: 'createdBy', calculated: true, private: true},
                {key: 'createdAt', calculated: true, private: true},
                {key: 'updatedAt', calculated: true, private: true},
                {key: 'owningGroups', calculated: true, type: LIST_TYPE},
            ]
        }
    ];

    constructor(
        public auth: AuthService,
        private api: ApiService,
        private layoutConfigService: LayoutConfigService,
        private titleService: Title,
        private activeRoute: ActivatedRoute,
        public profileSecurity: ProfileSecurityService,
        private avatarUrlPipe: AvatarUrlPipe,
        private wcResourcesInjectorService: WcResourcesInjectorService
    ) {
    }

    ngOnInit() {
        this.profileId = window.location.pathname === '/app/me'
            ? this.auth.getLoggedUser().id
            : this.activeRoute.snapshot.paramMap.get('id');

        if (!this.profileId) {
            throw new Error('User id is required!');
        }

        setTimeout(() => {
                if (this.layoutConfigService.sidebarLeft) {
                    this.layoutConfigService.sidebarLeftToggle();
                }
            }
        );

        this.wcResourcesInjectorService.addJs(`${environment.webAppsDomain}/person-kpi-app/person-kpi-app.min.js`).then();

        this.loadProfile();
    }

    ngOnDestroy(): void {
        this.titleService.setTitle(DEFAULT_PAGE_TITLE);
        if (!this.layoutConfigService.sidebarLeft) {
            this.layoutConfigService.sidebarLeftToggle();
        }
    }

    openDocumentsForm() {
        this.showDocumentsFrom = true;
    }

    closeModal(refresh = false) {
        this.showDocumentsFrom = false;
        this.showPasswordUpdate = false;

        if (refresh) {
            this.updateData();
        }
    }

    closeForm(refresh = false) {
        if (refresh) {
            this.updateData();
        }
    }

    downloadDocument(document: UserProfileDocument) {
        this.api.downloadDocument(document.id).subscribe((response: Blob) => {
                const dataType = response.type;
                const binaryData = [];
                const fileName = `${document.caption}.${document.ext}`;
                binaryData.push(response);

                downloadContent(window.URL.createObjectURL(new Blob(binaryData, {type: dataType})), fileName);
            }
        );
    }

    uploadAvatar(event) {
        if (this.submitted) {
            return;
        }

        if (!event.target.files) {
            return;
        }

        const data = new FormData();
        data.append('file', event.target.files[0]);

        event.target.files = null;
        event.target.value = null;
        this.submitted = true;

        this.api.uploadUserAvatar(this.profile.id, data).subscribe(() => {
            this.loadProfile();
            this.submitted = false;
        }, () => {
            this.submitted = false;
        });
    }

    goToLink(url: string){
        window.open(url, '_blank');
    }

    private updateData() {
        this.loadProfile();

        if (this.profileId.toString() === this.auth.getLoggedUser().id.toString()) {
            this.auth.refreshUserInfo();
        }
    }

    private loadProfile() {
        this.avatar = this.avatarUrlPipe.transform(this.profileId, true);

        this.api.getTeamPerson(this.profileId).subscribe(data => {
            this.profileSecurity.init(data);

            this.profile = data;
            this.titleService.setTitle(data.name + ' — Evo');
            this.currentUserEmail = data.email.replace('.local', '.com');

            // this.profile.documents?.filter(item => item.mime.includes('video/'))
            //     .forEach(item => {
            //         this.api.downloadDocument(item.id).subscribe((response: Blob) => {
            //                 item.isVideo = true;
            //                 item.src = window.URL.createObjectURL(response);
            //             }
            //         );
            //     });
        });
    }
}
