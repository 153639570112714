<div id='loading-{{currentId}}' class='text-center' style='padding:200px;'>
    <div>{{'messages.global.loading'|translate}}</div>
    <div>{{'messages.web.report_page.iframe.info'|translate}}</div>
</div>

<iframe
    id='iframe-html-{{currentId}}'
    (load)="onLoad()"
    name='iframe-html'
    style='width:100%;height: 100%; border: 0;display: none; overflow: auto;'
    [src]='url | safeResourceUrl'
    (window:resize)="onResize()"
>
    {{'messages.web.report_page.iframe.not_supported'|translate}}
</iframe>
