<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="
    (company ? 'messages.admin.companies_page.form.company.title_edit' : 'messages.admin.companies_page.form.company.title_create' )
    | translate"
    [ngStyle]="{
        display: addNewUser ? 'none' : ''
    }"
>
    <app-form
        [formConfig]="formConfig"
        [value]="companyData"
        [lists]="lists"
        [loading]="formLoadingServiceService.isLoading()"
        [hideButtons]="true"
        (submitForm)="addCompany($event)"
    >
    </app-form>
</app-modal>

<app-user-modal
    (closeModal)="userAdded($event)"
    *ngIf="addNewUser"
    modalType="add"
></app-user-modal>
