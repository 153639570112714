import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ChartConfig} from '../../../../models/models';
import {HttpClient} from '@angular/common/http';
import {DashboardService} from '../../../../services/dashboard.service';
import * as dc from 'dc';
import * as d3 from 'd3';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-number-chart',
    templateUrl: './number-chart.component.html',
    styleUrls: ['./number-chart.component.scss']
})
export class NumberChartComponent implements OnInit, OnDestroy {

    @ViewChild('chart') private chartContainer: ElementRef;
    @ViewChild('resetButton') private resetButton: ElementRef;
    @Input() private crossFilter: any;
    @Input() public chartConfig: ChartConfig;
    public chartId: string;
    private dcChart;
    private subscription: Subscription;
    public loading: boolean;

    constructor(private httpClient: HttpClient,
                private dashboardService: DashboardService,
                private elRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.subscription = this.dashboardService.getCrossFilterSubject().subscribe((crossFilter: any) => {
            this.dashboardService.setElementRef(this.elRef);
            this.chartId = this.generateRandomId();
            this.dashboardService.destroyAllBuilder( this.chartId);
            const xDimension = this.dashboardService.createXDimension(crossFilter, this.chartConfig);
            const series = this.dashboardService.createSeries(xDimension, this.chartConfig);
            this.chartConfig = this.dashboardService.associateDataWithAxis(this.chartConfig, series);

            try {
                const chart = dc.numberDisplay(this.chartContainer.nativeElement);
                chart.on('renderlet', () => {
                    setTimeout(() => {
                        d3.select(`#${this.chartId}` + ' .number-display')
                            // tslint:disable-next-line:max-line-length
                            .attr('style', `;float:left;text-align:${this.chartConfig.textAlign};color: ${this.chartConfig.paletteLastColor};width:${this.chartConfig.width}px;height:${this.chartConfig.height}px;font-size: ${this.chartConfig.fontSize}px;padding-left:47px`);
                    }, 100);
                });
                chart.valueAccessor(series[0].valueAccessor);
                chart.formatNumber(d3.format(this.chartConfig.numberFormat));
                chart.group(series[0].group);

                // this.dashboardService.handleNewLine(this.chartConfig, `#${this.chartId}`);
                // this.dashboardService.setChartTitle(this.chartConfig, `#${this.chartId}`, {left: 4});
                // tslint:disable-next-line:max-line-length
                // this.dashboardService.setChartSubtitle(this.chartConfig, `#${this.chartId}`, {left: 4}); // Leave here, do not move, reset position will break otherwise
                chart.render();
                this.loading = false;
            } catch (ex) {
                throw ex;
            }
        });
    }

    generateRandomId() {
        return `${this.dashboardService.getOptions().defaultChartIdPrefix}-${this.chartConfig.id}`;
    }

    resetChart() {
        this.dcChart.filterAll();
        this.dashboardService.redrawAll();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
