import * as d3 from 'd3';
import {DashboardService} from '../dashboard.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DashboardLocaleService {

    defaultLocale = 'computer';

    constructor() {
        this.locales.set('computer', {
            groupingSeparator: '',
            decimalSeparator: ''
        });
        this.locales.set('en-us', {
            groupingSeparator: ',',
            decimalSeparator: '\\.'
        });
        this.locales.set('ru', {
            groupingSeparator: ' ',
            decimalSeparator: ','
        });
    }

    private locales = d3.map([], (d: any) => {
        return d.name;
    });

    public has(value: string) {
        return this.locales.has(value);
    }

    public get(value: string) {
        return this.locales.get(value);
    }

    public applyLocale(value: string, locale: string) {
        let localeName = locale;
        if (!this.locales.has(locale)) {
            localeName = this.defaultLocale;
        }
        const loadedLocale = this.locales.get(localeName);
        value = value.replace(new RegExp(loadedLocale.groupingSeparator, 'g'), ''); // TODO performance issue
        value = value.replace(new RegExp(loadedLocale.decimalSeparator, 'g'), '.'); // TODO performance issue
        return value;
    }
}
