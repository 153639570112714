<div class="mb-3">
    <div class="comment-list-box">
        <div class="comment-thumbnail">
            <img
                [alt]="comment.created_by.stringified"
                [src]="comment.created_by.id | avatarUrl"
            >
        </div>
        <div class="comment-data" [id]="'comment_' + comment.id">
            <div *ngIf="!edit">
                <p class="userName">{{comment.created_by.stringified}}
                    <span [title]="createdAt | date:'dd LLLL yyyy, HH:mm z'" class="time">
                        {{createdAt|timeAgo}}
                    </span>
                    <span
                        *ngIf="comment.is_edited"
                        [title]="comment.updated_at | date:'dd LLLL yyyy, HH:mm z'"
                        class="time"
                    >({{'messages.comments.updated'|translate}})</span>
                </p>
                <p class="userMessage text-break" [innerHTML]="body" (click)="handleMention($event)"></p>
                <div *ngIf="comment?.attachments?.length" class="mt-2 mb-2">
                    <div class="mb-2">{{'messages.attachments.label_2'|translate}}:</div>
                    <div class="mb-2" *ngFor="let file of comment.attachments, index as i">
                        <a
                            [href]="file|commentAttachmentUrl"
                            download
                        ><i class="icon ion-document-text"></i> {{file.name}}</a>
                    </div>
                </div>

                <div class="reply-tool">
                    <ng-container *ngIf="!reply && !edit">
                        <p class="reply-text">
                            <a
                                (click)="copyUrl($event,comment)"
                                [href]="location.origin + location.pathname + '#comment_' + comment.id"
                            >
                                <i class="icon ion-ios-copy mr-1"></i>
                                {{'messages.comments.copy_url'|translate}}
                            </a>
                        </p>
                        <p (click)="handleReplyClick($event)" class="reply-text">
                            <i class="icon ion-reply mr-1"></i>
                            {{'messages.global.reply'|translate}}
                        </p>
                        <ng-container *ngIf="auth.isSuperAdmin() || auth.getLoggedUser().id === comment.created_by.id">
                            <p (click)="handleEditClick($event)" class="reply-text">
                                <i class="icon ion-edit mr-1"></i>
                                {{'messages.global.edit'|translate}}
                            </p>
                            <app-modal-confirm [confirmMessage]="'messages.comments.delete_confirm'|translate">
                                <p
                                    (click)="deleteComment()"
                                    class="reply-text"
                                >
                                    <i class="icon ion-ios-trash mr-1"></i>
                                    {{'messages.global.delete'|translate}}
                                </p>
                            </app-modal-confirm>
                        </ng-container>
                    </ng-container>
                    <app-comment-form
                        (closeForm)="handleClose($event)"
                        *ngIf="reply"
                        [parent]="comment.id"
                        [withAvatar]="false"
                    >
                    </app-comment-form>
                </div>
            </div>

            <app-comment-form
                (closeForm)="handleClose($event)"
                *ngIf="edit"
                [comment]="comment"
                [withAvatar]="false"
            >
            </app-comment-form>
        </div>
    </div>

    <div *ngIf="level < 10 && comment?.children" class="comment-replies">
        <app-comment
            (deleted)="handleChildDelete($event)"
            *ngFor="let child of comment?.children;index as i"
            [comment]="child"
            (updated)="handleChildUpdate($event, i)"
            [level]="level + 1"
        >
        </app-comment>
    </div>
</div>

<ng-container *ngIf="level >= 10 && comment?.children">
    <app-comment
        (deleted)="handleChildDelete($event)"
        *ngFor="let child of comment?.children"
        [comment]="child"
        [level]="level + 1"
    >
    </app-comment>
</ng-container>
