<!--Left sidebar menu subscribe panel-->
<div
    *ngIf="currentReport"
    class="sidebar-left-panel sidebar-left-panel-subscribe"
    style="height: calc(100% - 64px); overflow: hidden; min-width: 275px;"
>
    <app-scrollbar [vertical]="true" [horizontal]="false">
        <div>
            <form
                class="form-uppercase"
                novalidate=""
                [formGroup]="form"
                (ngSubmit)="addSubscription($event)"
            >
                <h1>
                    <i class="icon ion-ios-email"></i>
                    {{'messages.web.report_page.subscription.title'|translate}}
                    <br><span class="subtitle ng-binding">{{currentReport?.name}}</span>
                </h1>
                <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

                <app-input-text
                    [submitted]="submitted"
                    fieldName="email"
                    formControlName="email"
                    [formName]="formName"
                    [errors]="errors"
                ></app-input-text>
                <app-input-text
                    [submitted]="submitted"
                    fieldName="name"
                    formControlName="name"
                    [formName]="formName"
                    [errors]="errors"
                ></app-input-text>

                <div class="section-extra-margin-top form-group">
                    <app-select
                        [submitted]="submitted"
                        fieldName="type"
                        formControlName="type"
                        [formName]="formName"
                        [options]="types"
                        [errors]="errors"
                    ></app-select>

                    <app-select
                        *ngIf="form.value.type == 'weekly'"
                        [submitted]="submitted"
                        fieldName="dayOfDelivery"
                        formControlName="dayOfDelivery"
                        [formName]="formName"
                        [options]="days"
                        [errors]="errors"
                        [withLabel]="false"
                    ></app-select>

                    <div class="row m-0">
                        <div class="col-lg-6 pl-0 pr-0 pr-md-2">
                            <app-select
                                [submitted]="submitted"
                                fieldName="hourOfDelivery"
                                formControlName="hourOfDelivery"
                                [formName]="formName"
                                [options]="timeHours"
                                [errors]="errors"
                                [inline]="true"
                            ></app-select>
                        </div>
                        <div class="col-lg-6 pl-0 pr-0">
                            <app-select
                                [submitted]="submitted"
                                fieldName="minuteOfDelivery"
                                formControlName="minuteOfDelivery"
                                [formName]="formName"
                                [options]="timeMinutes"
                                [errors]="errors"
                                [inline]="true"
                            ></app-select>
                        </div>
                    </div>
                    <app-select
                        [submitted]="submitted"
                        fieldName="timeZone"
                        formControlName="timeZone"
                        [formName]="formName"
                        [options]="timezones"
                        [withLabel]="false"
                        [errors]="errors"
                    ></app-select>
                </div>

                <div class="form-collection form-group clearfix">
                    CC
                    <a class="btn btn-xs btn-primary collection-add-button" (click)="addItem($event)">
                        <i class="icon icon-size-9 ion-plus-circled"></i>
                        {{'messages.global.add_new'|translate}}
                    </a>

                    <div
                        class="clearfix form-collection-row ng-scope"
                    >
                        <div
                            *ngFor="let item of formData.controls; index as i"
                            formArrayName="followers"
                        >
                            <div [formGroupName]="i" style="position: relative;">
                                <app-input-text
                                    [submitted]="submitted"
                                    fieldName="email"
                                    formControlName="email"
                                    [formName]="formName"
                                    [withLabel]="false"
                                ></app-input-text>
                                <a
                                    (click)="deleteItem($event, i)"
                                    class="collection-remove-button col-1 padding-horizontal-0"
                                >
                                    <span class="icon icon-size-9 ion-minus-circled" aria-hidden="true"></span>
                                </a>
                            </div>
                        </div>
                        <app-display-input-errors [errorsStrings]="getFollowersErrors()"></app-display-input-errors>

                    </div>
                </div>


                <app-provided-entity-select
                    [submitted]="submitted"
                    fieldName="report"
                    formControlName="report"
                    [formName]="formName"
                    [options]="pages"
                    [errors]="errors"
                ></app-provided-entity-select>

                <app-select
                    [submitted]="submitted"
                    fieldName="format"
                    formControlName="format"
                    [formName]="formName"
                    [options]="formats"
                    [errors]="errors"
                ></app-select>

                <div class="form-group">
                    {{'messages.web.report_page.subscription.info_message'|translate}}
                </div>

                <div class="btn-container">
                    <button
                        class="btn btn-lg btn-primary"
                        type="submit"
                        style="padding: 7px 15px 5px"
                    >{{'messages.web.report_page.subscription.setup_email_delivery'|translate}}</button>
                </div>
                <div class="collapse-arrow-container">
                    <a (click)="layoutConfigService.leftPanelSubscribeToggle()"><i class="ion-chevron-left"></i></a>
                </div>
            </form>
        </div>
    </app-scrollbar>
</div><!--/Left sidebar menu view panel expanded-->
