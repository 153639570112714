<accordion-group
    [isOpen]="isRootOpened()"
>
    <a
        class="lvl-0"
        accordion-heading
    >
        <span>{{'messages.company_onboarding.menu.label'|translate}}</span>
        <span class="heading-actions">
            <span class="acc-arrow">
                <i class="ion-ios-arrow-down"></i>
            </span>
        </span>
    </a>
    <div
        class="panel-body"
        style="margin-top: 1px; width: 100%"
    >
        <div class="list-item lvl-1" [ngClass]="{selected: location.pathname == '/app/company-onboarding/introduction-app'}">
            <a
                routerLink="/app/company-onboarding/introduction-app" class="text-break list-item-link"
            >
                {{'messages.company_onboarding.menu.introduction_app'|translate}}
            </a>
        </div>
        <div class="list-item lvl-1" [ngClass]="{selected: location.pathname == '/app/company-onboarding/data-import-app'}">
            <a
                routerLink="/app/company-onboarding/data-import-app" class="text-break list-item-link"
            >
                {{'messages.company_onboarding.menu.data_import_app'|translate}}
            </a>
        </div>
        <div class="list-item lvl-1" [ngClass]="{selected: location.pathname == '/app/company-onboarding/data-validation-app'}">
            <a
                routerLink="/app/company-onboarding/data-validation-app" class="text-break list-item-link"
            >
                {{'messages.company_onboarding.menu.data_validation_app'|translate}}
            </a>
        </div>
    </div>
</accordion-group>
