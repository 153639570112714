import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {FormErrors, ProfileListValues, ProfileListValuesModel} from '../../../models/models';
import {Router} from '@angular/router';

@Component({
    selector: 'app-default-profile-values',
    templateUrl: './default-profile-values.component.html',
    styleUrls: ['./default-profile-values.component.scss']
})
export class DefaultProfileValuesComponent implements OnInit {
    public profileListValues: ProfileListValues;
    public errors: FormErrors;
    public loaded = false;
    public requestLoading = false;

    constructor(private api: ApiService, private router: Router) {
    }

    ngOnInit() {
        this.api.getDefaultProfileValues().subscribe(data => {
            this.profileListValues = data;
            this.loaded = true;
        });
    }

    updateValues(data: ProfileListValuesModel) {
        this.errors = null;
        this.requestLoading = true;

        this.api.updateDefaultProfileValues(data).subscribe(() => {
            this.requestLoading = false;
        }, resp => {
            this.errors = resp.error.errors as FormErrors;
            this.requestLoading = false;
        });
    }

    onCancel() {
        this.router.navigate(['/admin/users']);
    }
}
