import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DisplayError, ProvidedEntity} from '../../models/models';
import {FieldsService} from '../../services/fields.service';

@Component({
    selector: 'app-provided-entity-select',
    templateUrl: './provided-entity-select.component.html',
    styleUrls: ['./provided-entity-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProvidedEntitySelectComponent),
            multi: true
        }
    ]
})
export class ProvidedEntitySelectComponent implements OnInit, ControlValueAccessor {
    protected currentValue: number | null = null;
    public field;

    public currentErrors: string[];

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    @Input() options: ProvidedEntity[] = [];
    @Input() control: FormControl;
    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() isFieldValid: boolean;

    constructor(public fieldService: FieldsService) {
    }


    public get value() {
        return this.currentValue;
    }

    public set value(newValue: number | null) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.field = this.fieldService.getUserField(this.fieldName, this.formName);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
}
