import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../../services/loading.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
    public loading = true;
    public onModeration = false;

    constructor(private loadingService: LoadingService,
                private route: ActivatedRoute,
                private api: ApiService,
                private authService: AuthService,
                private router: Router,
                private toastService: ToastrService) {
    }

    ngOnInit(): void {
        this.loadingService.clearEvents();
        const token = this.route.snapshot.paramMap.get('token') || null;

        this.api.activateUser(token).subscribe((data) => {
            this.loading = false;
            this.onModeration = data.on_moderation;
            if (data.api_key) {
                this.authService.setApiKey(data.api_key);

                this.api.getLastSession().subscribe(lastSession => {
                    const url = lastSession.last_seen_report || 'app/reports';

                    this.loading = false;
                    this.router.navigate([url]).then(() => {
                        this.toastService.success('Thank you, the account is verified!');
                    });
                }, () => {
                    this.loading = false;
                    this.router.navigate(['app/reports']).then(() => {
                        this.toastService.success('Thank you, the account is verified!');
                    });
                });
            }
        }, () => {
            this.loading = false;
        });
    }

}
