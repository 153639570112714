<div class="page-title">
    <h1>
        <i
            class="icon ion-folder color-light-green"
        ></i> {{'messages.reports_tree_page.reports_tree'|translate}}
    </h1>
</div>

<div class="form-style-bordered-inputs">
    <div class="form-group">
        <select class="form-control" [(ngModel)]="company" (change)="fetchTree($event.target.value)">
            <option value="global">{{'messages.admin.pages_page.form.page.fields.global.label'|translate}}</option>
            <option *ngFor="let company of companies" [value]="company.id">{{company.stringified}}</option>
        </select>
    </div>
</div>

<div class="mb-4">
    {{'messages.reports_tree_page.desc'|translate}}
</div>

<div class="mb-4" *ngIf="folders?.length">
    <app-folder-tree-node *ngFor="let folder of folders" [folder]="folder">
    </app-folder-tree-node>

    <app-modal-confirm [confirmMessage]="'messages.global.confirm_reports_sort'|translate">
        <button
            class="btn btn-primary btn-sm btn-square"
            (click)="handleSubmit()"
            [disabled]="loading"
        >
            <span
                *ngIf="loading"
                aria-hidden="true"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
            ></span>
            {{'messages.global.submit'|translate}}</button>
    </app-modal-confirm>
</div>
<div class="mb4" *ngIf="!loading && company && !folders?.length">
    <strong>No folders found for this selection</strong>
</div>
