import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl} from '@angular/forms';
import {GitIssue, GitUser, ProvidedEntity} from '../../../../models/models';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-issue-view',
    templateUrl: './issue-view.component.html',
    styleUrls: ['./issue-view.component.scss']
})
export class IssueViewComponent implements OnInit {
    loading: boolean;
    statusLoading = false;
    editDescription = false;
    infoExpanded = false;
    members: GitUser[] = [];

    issue: GitIssue;

    public commentControl: FormControl;
    public descriptionControl: FormControl;
    public mentions = [];

    constructor(
        private api: ApiService,
        private activeRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private auth: AuthService,
        private router: Router) {
    }

    ngOnInit(): void {
        if (!this.auth.getLoggedUser().board_enabled) {
            this.router.navigateByUrl('/app/reports');
            return;
        }

        this.commentControl = this.formBuilder.control('');
        this.descriptionControl = this.formBuilder.control('');

        this.activeRoute.params.subscribe((params) => {
            this.issue = null;
            this.loadIssue(params.id);
        });
    }

    loadIssue(id) {
        this.loading = true;

        this.api.getGitProjectMembers().subscribe(data => {
            this.members = data;
        });

        this.api.getGitIssue(id).subscribe(data => {
            this.loading = false;
            this.issue = data;

            this.processIssue();
        });
    }

    private processIssue() {
        this.issue.created_at = new Date(this.issue.created_at);
        this.issue.updated_at = new Date(this.issue.updated_at);
        this.issue.closed_at = new Date(this.issue.closed_at);

        this.issue.notes.forEach(item => {
            if (item.created_at) {
                item.created_at = new Date(item.created_at);
            }

            if (item.updated_at) {
                item.updated_at = new Date(item.updated_at);
            }
        });

        this.descriptionControl.setValue(this.issue.description);
    }

    submit() {
        if (this.loading) {
            return;
        }

        this.commentControl.markAsTouched();

        if (this.commentControl.status === 'INVALID') {
            return;
        }

        this.loading = true;

        const data = {
            body: this.commentControl.value,
            mentions: []
        };

        this.mentions = this.mentions.map((item => item.id));
        data.mentions = this.mentions.length > 0 ?
            this.mentions.filter((item, index) => this.mentions.indexOf(item) === index ) :
            null;

        this.api.addCommentToGitIssue(this.issue.iid, data).subscribe(() => {
            this.loading = false;
            this.commentControl.reset('');
            this.loadIssue(this.issue.iid);
        }, (e) => {
            console.log(e);
            this.loading = false;
        });
    }

    async toggleStatus() {
        if (this.statusLoading) {
            return;
        }

        this.statusLoading = true;

        await this.updateIssue({
            state_event: this.issue.state === 'closed' ? 'reopen' : 'close'
        });

        this.statusLoading = false;
    }

    cancelEdit() {
        this.descriptionControl.setValue(this.issue.description);
        this.editDescription = false;
    }

    async updateDescription() {
        if (this.loading) {
            return;
        }

        this.descriptionControl.markAsTouched();

        if (this.descriptionControl.status === 'INVALID') {
            return;
        }

        this.loading = true;

        const data = {
            description: this.descriptionControl.value
        };

        await this.updateIssue(data);

        this.cancelEdit();
        this.loading = false;
    }

    async updateIssue(issueData: any) {
        this.mentions = this.mentions.map((item => item.id));
        issueData.mentions = this.mentions.length > 0 ?
            this.mentions.filter((item, index) => this.mentions.indexOf(item) === index ) :
            null;
        const data = await this.api.updateGitIssue(this.issue.iid, issueData, true)
            .toPromise()
            .catch(() => {
            });

        if (data) {
            this.issue = data as GitIssue;
            this.processIssue();
        }
    }

    mentionChangedHandler($event: ProvidedEntity[]) {
        this.mentions = $event;
    }

    toggleInfo() {
        this.infoExpanded = !this.infoExpanded;
    }
}
