import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-scrollbar',
    templateUrl: './scrollbar.component.html',
    styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent implements AfterViewInit, OnDestroy {
    @ViewChild('content') content: ElementRef;
    @HostBinding('class.vertical') @Input() vertical = true;
    @HostBinding('class.horizontal') @Input() horizontal = false;

    public hasVerticalBar = false;
    public hasHorizontalBar = false;
    public verticalSize;
    public horizontalSize;
    public verticalPosition;
    public horizontalPosition;
    subscription: any;
    private resizeObserver;
    private resize$ = new Subject();
    private es = null;

    constructor(@Inject(ElementRef) readonly elementRef: ElementRef<HTMLElement>) {
    }

    get verticalScrolled(): number {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = this.elementRef.nativeElement;

        return scrollTop / (scrollHeight - clientHeight);
    }

    get horizontalScrolled(): number {
        const {
            scrollLeft,
            scrollWidth,
            clientWidth
        } = this.elementRef.nativeElement;

        return scrollLeft / (scrollWidth - clientWidth);
    }

    ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.getElementWithSizes());
        this.resizeObserver = null;
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            this.resize$.next();
        });

        this.resizeObserver.observe(this.getElementWithSizes());
        this.subscription = this.resize$.subscribe(() => {
            this.es = null;
            this.handleSizeChange();
        });

        this.handleSizeChange();
    }

    handleSizeChange() {
        this.onScroll();
        this.updateHorizontalSize();
        this.updateVerticalSize();
        this.updateVerticalPosition();
        this.updateHorizontalPosition();
    }

    updateVerticalPosition() {
        this.verticalPosition = this.verticalScrolled * (100 - this.verticalSize);
    }

    updateHorizontalPosition() {
        this.horizontalPosition = this.horizontalScrolled * (100 - this.horizontalSize);
    }

    updateVerticalSize() {
        const el = this.getElementWithSizes() || this.elementRef.nativeElement;
        this.verticalSize = Math.ceil((this.elementRef.nativeElement.clientHeight / el.scrollHeight) * 100);
    }

    updateHorizontalSize() {
        const el = this.getElementWithSizes() || this.elementRef.nativeElement;
        this.horizontalSize = Math.ceil((this.elementRef.nativeElement.clientWidth / el.scrollWidth) * 100);
    }

    checkVerticalBar() {
        const child = this.getElementWithSizes();

        if (!child) {
            return false;
        }

        return child.clientHeight > this.elementRef.nativeElement.parentElement.clientHeight;
    }

    checkHorizontalBar() {
        const child = this.getElementWithSizes();

        if (!child) {
            return false;
        }

        return child.clientWidth > this.elementRef.nativeElement.parentElement.clientWidth;
    }

    @HostListener('scroll')
    onScroll() {
        setTimeout(() => {
            this.hasHorizontalBar = this.checkHorizontalBar();
            this.hasVerticalBar = this.checkVerticalBar();
            this.updateVerticalPosition();
            this.updateHorizontalPosition();
        });
    }

    onVertical(scrollTop: number) {
        this.elementRef.nativeElement.scrollTop = scrollTop;
    }

    onHorizontal(scrollLeft: number) {
        this.elementRef.nativeElement.scrollLeft = scrollLeft;
    }

    private getElementWithSizes() {
        if (this.es) {
            return this.es;
        }

        let [child] = this.content.nativeElement.children;

        if (!child) {
            return false;
        }

        while (!child.clientWidth) {
            [child] = child.children;

            if (!child) {
                return false;
            }
        }

        this.es = child;

        return child;
    }
}
