<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="(page ? 'messages.admin.pages_page.form.page.title_edit' : 'messages.admin.pages_page.form.page.title_create') | translate"
>
    <app-form
        [formConfig]="formConfig"
        [value]="page"
        [lists]="lists"
        [hideButtons]="true"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="addPage($event)"
    >
    </app-form>
</app-modal>
