import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../../../../models/models';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true
        }
    ]
})
export class InputTextComponent implements ControlValueAccessor {
    public value;
    public disabled = false;
    public touch: any;

    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;
    @Input() withLabel = true;
    @Input() config: FormInputConfig;

    onChange: any = () => {
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
