import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../../services/auth.service';
import {Course} from '../../../models/models';

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnDestroy {

    @Input() courseId = null;
    @Input() creator: {
        id: number;
        name: string;
        department: string;
    };
    course: Course = null;
    loading = true;
    public windowWidth = 9999;
    private resizeListener: any;


    constructor(private api: ApiService, private activeRoute: ActivatedRoute, public sanitized: DomSanitizer, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.resizeListener = () => {
            this.windowWidth = window.innerWidth;
        };

        window.addEventListener('resize', this.resizeListener);
        this.windowWidth = window.innerWidth;

        if (this.courseId) {
            this.fetchCourse(this.courseId);
            return;
        }

        this.activeRoute.params.subscribe((params) => {
            this.course = null;
            this.loading = true;

            const courseSlug = params.courseSlug;

            const segments = courseSlug.split('_');

            if (segments.length < 2) {
                return;
            }

            this.fetchCourse(segments[0]);
        });
    }

    fetchCourse(id) {
        this.api.getCourse(id).subscribe(data => {
            this.course = data;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    canEnroll() {
        return !this.isEnrolled();
    }

    isEnrolled() {
        if (!this.course) {
            return false;
        }

        const moodleId = this.auth.getLoggedUser().moodle_id;

        return this.course.students.map(item => item.id).includes(moodleId);
    }

    mapUsers(users) {
        return users.map(item => item.name).join(', ');
    }

    ngOnDestroy(): void {
        if (this.resizeListener) {
            window.removeEventListener('resize', this.resizeListener);
        }
    }
}
