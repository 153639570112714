<div class="item-row word-break">
    <span class="item-label">{{label || (('messages.list_values.fields.' + key) | translate)}}:</span>
    <span *ngIf="!(itemValue|isArray)" class="pre-wrap">
        <a *ngIf="itemValue|isUrl" [href]="itemValue" target="_blank">{{itemValue}}</a>
        <span *ngIf="!(itemValue|isUrl)" [innerHTML]="itemValue"></span>
    </span>
    <span *ngIf="(itemValue|isArray) && itemValue.length">
        <ng-container *ngFor="let item of itemValue; let i = index">
            <img
                *ngIf="item.icon"
                [src]="item.icon"
                [alt]="item.value"
                [title]="item.value"
                width="20"
                height="20"
                style="margin-right: 5px"
            >
            <span *ngIf="!item.icon">
                <ng-container *ngIf="!item.url">{{item.value}}</ng-container>
                <a
                    *ngIf="item.url"
                    [href]="item.url"
                    target="_blank"
                >{{item.value}}</a>{{i < itemValue.length - 1 ? ', ' : ''}}
            </span>
        </ng-container>
    </span>
</div>
