import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LayoutConfigService {
    public sidebarLeft: boolean;
    public leftPanelSave: boolean;
    public sidebarRight: boolean;
    public leftPanelSubscribe: boolean;
    public leftPanelView: boolean;
    public mobileMenuOpened: boolean;
    private notificationsCount: number;
    private newMessagesCount: number;
    public showUserProfilePanel: boolean;
    public showMessagePanel: boolean;
    public showDeletedMessagesPanel: boolean;
    public showSettingsPanel: boolean;
    private selectedPanel: string;
    private menuLabels: boolean;
    private emailNotifications: boolean;

    constructor(private router: Router) {
        this.sidebarLeft = true;
        this.sidebarRight = false;
        this.leftPanelSave = false;
        this.leftPanelView = false;
        this.leftPanelSubscribe = false;
        this.mobileMenuOpened = false;
        this.showMessagePanel = false;
        this.showDeletedMessagesPanel = false;
        this.showSettingsPanel = false;
        this.menuLabels = true;
        this.newMessagesCount = 0;
    }

    sidebarLeftToggle() {
        this.sidebarLeft = !this.sidebarLeft;
        return this.sidebarLeft;
    }

    sidebarRightToggle() {
        this.sidebarRight = !this.sidebarRight;
        return this.sidebarRight;
    }

    showSidebarRight(type: string) {
        this.sidebarRight = true;

        switch (type) {
            case 'messages':
                this.setShowMessagePanel(true);
                break;
            case 'deletedMessages':
                this.setShowDeletedMessagesPanel(true);
                break;
            case 'settings':
                this.setShowSettingsPanel(true);
                break;
        }

        this.selectedPanel = type;
    }

    hideSidebarRight() {
        this.sidebarRight = false;
        return this.sidebarRight;
    }

    leftPanelSaveToggle() {
        this.leftPanelSave = !this.leftPanelSave;
        return this.leftPanelSave;
    }

    leftPanelSubscribeToggle() {
        this.leftPanelSubscribe = !this.leftPanelSubscribe;
        return this.leftPanelSubscribe;
    }

    hideLeftPanelSubscribe() {
        this.leftPanelSubscribe = false;

        return this.leftPanelSubscribe;
    }

    leftPanelViewToggle() {
        this.leftPanelView = !this.leftPanelView;
        return this.leftPanelView;
    }

    mobileMenuToggle() {
        this.mobileMenuOpened = !this.mobileMenuOpened;
        return this.mobileMenuOpened;
    }

    getSidebarLeft(): boolean {
        return this.sidebarLeft;
    }

    getLeftPanelSave(): boolean {
        return this.leftPanelSave;
    }

    getSidebarRight(): boolean {
        return this.sidebarRight;
    }

    getLeftPanelSubscribe(): boolean {
        return this.leftPanelSubscribe;
    }

    getLeftPanelView(): boolean {
        return this.leftPanelView;
    }

    getMobileMenuOpened(): boolean {
        return this.mobileMenuOpened;
    }

    setMobileMenuOpened(value) {
        this.mobileMenuOpened = value;
    }

    isAdminRoute(): boolean {
        return this.router.url.indexOf('/admin') === 0;
    }

    getNotificationsCount(): number {
        return this.notificationsCount;
    }

    setNotificationsCount(count: number) {
        this.notificationsCount = count;
    }

    setShowMessagePanel(val: boolean) {
        this.showMessagePanel = val;
        this.showDeletedMessagesPanel = false;
        this.showSettingsPanel = false;
    }

    setShowDeletedMessagesPanel(val: boolean) {
        this.showMessagePanel = false;
        this.showDeletedMessagesPanel = val;
        this.showSettingsPanel = false;
    }

    setShowSettingsPanel(val: boolean) {
        this.showMessagePanel = false;
        this.showDeletedMessagesPanel = false;
        this.showSettingsPanel = val;
    }

    getMenuLabels() {
        return this.menuLabels;
    }

    setMenuLabels(value) {
        this.menuLabels = value;
    }

    getEmailNotifications() {
        return this.emailNotifications;
    }

    setEmailNotifications(value) {
        this.emailNotifications = value;
    }

    getNewMessages() {
        return this.newMessagesCount;
    }

    setNewMessages(value: number) {
        this.newMessagesCount = value;

        return this;
    }
}
