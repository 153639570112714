<div *ngIf="data" class="container-fluid p-0">
    <h3 class="mb-4">{{'messages.university.dashboard_label'|translate}}</h3>
    <div class="row">
        <div class="col-12 course-nav">
            <div>
                <label class="search" style="">
                    <input
                        type="text"
                        placeholder="Search"
                        (input)="handleSearch($event.target.value)"
                    >
                    <i class="ion ion-search"></i>
                </label>
            </div>
            <div class="courses-switch">
                <span
                    class="m-2"
                    [ngClass]="{selected: showMy}"
                    (click)="selectMy()"
                >{{'messages.university.enrolled_courses'|translate}}</span>
                <span (click)="selectAll()" [ngClass]="{selected: showAll}">{{'messages.global.all'|translate}}</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div
            class="col-12 col-md-6 col-lg-4 p-0" *ngFor="let item of filteredData; last as l"
            [ngClass]="{'mb-2': !l}"
        >
            <div class="row course-info flex-column">
                <a [routerLink]="'/app/courses/'+item.category_slug + '/' + item.slug">
                    <div
                        class="p-0 c-image-container"
                        *ngIf="item.image"
                        [ngStyle]="{backgroundImage: 'url(data:image/png;base64,' + item.image + ')'}"
                    >
                        <div class="badges" *ngIf="item.completed && item.badges">
                            <div *ngFor="let badge of item.badges" class="mb-2">
                                <img
                                    src="data:image/png;base64,{{badge.image}}"
                                    [tooltip]="badge.description"
                                    [tooltipAppendToBody]="true"
                                    triggers="hover"
                                    [alt]="badge.name"
                                >
                            </div>
                        </div>
                    </div>
                </a>
                <div class="p-3 w-100">
                    <a [routerLink]="'/app/courses/'+item.category_slug + '/' + item.slug">
                        <h5>
                            <strong>{{item.name}}</strong>
                        </h5>
                    </a>
                    <div class="rate mb-2" style="font-size: 12px;">
                        <span style="color: #ffbf00">{{item?.rating?.rating || ''}}</span>
                        <rating
                            class="mb-2"
                            style="color: #ffbf00"
                            [readonly]="true"
                            [ngModel]="item?.rating?.rating || 0"
                            [max]="5"
                        ></rating>
                        <span class="mr-3">({{item?.rating?.votes || 0}} ratings)</span>
                    </div>
                    <div class="summary mb-2" [innerHTML]="sanitized.bypassSecurityTrustHtml(item.summary)">
                    </div>
                </div>

                <div class="row mr-0 ml-0 mt-auto">
                    <div class="col-12 d-flex" *ngIf="item.hasOwnProperty('progress') && !item.completed">
                        <div class="w-75" style="margin-right: 15px;">
                            <progressbar [value]="item.progress">{{item.progress ? item.progress + '%' : '' }}
                            </progressbar>
                        </div>
                        <div>
                            <a
                                [href]="'https://university.evouser.com/course/view.php?id='+item.id"
                                class="btn btn-primary btn-sm"
                                style="min-width: min-content"
                            >{{'messages.global.continue'|translate}}</a>
                        </div>
                    </div>
                    <div class="col-12 text-center" *ngIf="!item.hasOwnProperty('progress')">
                        <a
                            [href]="'https://university.evouser.com/enrol/index.php?&auto_enroll&id=' + item.id"
                            class="btn btn-primary btn-sm"
                            style="min-width: min-content"
                        >{{'messages.university.enroll'|translate}}</a>
                    </div>
                    <div class="col-12 d-flex align-items-center completed" *ngIf="item.completed">
                        <span class="text-success">
                            <i class="ion-ios-checkmark-outline mr-2"></i>
                            <strong>{{'messages.global.congratulations'|translate}}!</strong>
                            {{'messages.university.course_completed'|translate}}.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
