import {Pipe, PipeTransform} from '@angular/core';
import {Attachment} from '../models/models';
import {environment} from '../../environments/environment';
import {ApiService} from '../services/api.service';
import {AuthService} from '../services/auth.service';

@Pipe({
    name: 'contentAttachmentUlr'
})
export class ContentAttachmentUlrPipe implements PipeTransform {

    constructor(private api: ApiService, private auth: AuthService) {
    }

    transform(file: Attachment): unknown {
        if (environment.production) {
            return '/attachments/content-attachment/' + file.name;
        }

        return `${this.api.apiURL}/attachments/content-attachment/${file.name}?api_key=${this.auth.getApiKey()}`;
    }

}
