import {Injectable} from '@angular/core';
import {DisplayError} from '../models/models';
import {FormArray, FormGroup} from '@angular/forms';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class FormErrorsTransformerService {

    constructor() {
    }

    public getErrors(form: FormGroup | FormArray): DisplayError[] {
        const errors = [];

        for (const [key, control] of Object.entries(form.controls)) {
            if (!control.errors) {
                continue;
            }

            for (const [errorKey, errorObject] of Object.entries(control.errors)) {
                if (errorObject) {
                    const error: DisplayError = {field: key, type: errorKey, options: {}};

                    if (!_.isBoolean(errorObject)) {
                        error.options = {...errorObject};
                    }

                    errors.push(error);
                }
            }
        }

        return errors;
    }

    public getNestedFormErrors(formKey: string, form: FormGroup | FormArray): DisplayError[] {
        const errors = [];

        for (const [key, control] of Object.entries(form.controls)) {
            if (control.status === 'INVALID' && !control.errors) {
                const childErrors = this.getNestedFormErrors(key, control as FormGroup);
                childErrors.forEach(item => {
                    item.field = `${formKey}.${item.field}`;
                });

                errors.push(...childErrors);
            }

            if (!control.errors) {
                continue;
            }

            for (const [errorKey, errorObject] of Object.entries(control.errors)) {
                if (errorObject) {
                    const error: DisplayError = {field: `${formKey}.${key}`, type: errorKey, options: {}};

                    if (!_.isBoolean(errorObject)) {
                        error.options = {...errorObject};
                    }

                    errors.push(error);
                }
            }
        }

        return errors;
    }

    public getGlobalErrors(errors: DisplayError[]): string[] {
        if (!errors) {
            return [];
        }

        return errors.filter((item: DisplayError) => item.field === '_global').map(item => item.message);
    }

    public getGlobalErrorsFromResponse(response: any): DisplayError[] {
        const errors = [];
        const dataObj = response.error.errors || response.error;
        this.parseErrors(errors, dataObj, '_global');

        return errors;
    }

    public getErrorsFromResponse(form: FormGroup, response: any): DisplayError[] {
        const errors = [];
        const dataObj = response.error.errors || response.error;

        Object.entries<any>(dataObj).forEach(([errorKey, data]) => {
            const formKey = form.get(errorKey) ? errorKey : '_global';

            this.parseErrors(errors, data, formKey);
        });

        return errors;
    }

    private parseErrors(errors, data, formKey) {
        if (!data) {
            return;
        }

        if (_.isArray(data)) {
            if (!data.length) {
                return;
            }

            errors.push(...this.arrayToErrors(data, formKey));
        } else {
            if (typeof data === 'string') {
                errors.push({type: 'custom', message: data, field: formKey, options: {}});
                return;
            }

            Object.entries<string[]>(data).forEach(([key, value]) => {
                this.parseErrors(errors, value, formKey);
            });
        }
    }

    private arrayToErrors(data: string[] | object[], field: string): DisplayError[] {
        const errors = [];

        data.forEach((error: string | object) => {
            if (typeof error === 'string') {
                errors.push({type: 'custom', message: error, field, options: {}});
            } else {
                this.parseErrors(errors, error, field);
            }
        });

        return errors;
    }
}
