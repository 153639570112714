<div class="page-title">
    <h1>
        <i class="icon ion-person color-light-green"></i>
        {{titleTable}}
        <a
            *ngIf="api"
            [href]="apiUrl + '/api/doc'"
            target="_blank"
        >{{'messages.admin.xls_page.read_api_documentation'|translate}}</a>
        <a class="icon ion-loop "></a>
        <a>
            {{'messages.global.help'|translate}}
            <i class="ion-help"></i>
        </a>
    </h1>
    <div class="action-buttons form-style-bordered-inputs">
        <select
            (change)="changedCompany($event.target.value) //noinspection UnresolvedVariable"
            *ngIf="selectCompany"
            [value]="valueCompany"
            id="select_company_filter"
            style="padding: 6px; margin-top: 1px;"
        >
            <option value="">{{'messages.admin.workbooks_page.form.workbook.fields.company.placeholder'|translate}}</option>
            <option
                *ngFor="let company of companies; let index = company"
                [value]="company.id"
            >{{company.stringified}}</option>
        </select>
        <select
            (change)="changedType($event.target.value) //noinspection UnresolvedVariable"
            *ngIf="selectType"
            [value]="valueType"
            class="margin-left-5"
            id="select_type_filter"
            style="padding: 6px; margin-top: 1px;"
        >
            <option value="">{{'messages.admin.contents_page.form.content.fields.type.placeholder'|translate}}</option>
            <option *ngFor="let type of types; let index = type" [value]="type.value">{{type.name}}</option>
        </select>
        <input
            (input)="executeSearch($event)" *ngIf="search" [(ngModel)]="searchInput"
            [placeholder]="'messages.admin.search'|translate"
            class="input-search ng-pristine ng-untouched ng-valid ng-empty margin-left-5"
            id="admin_filters_term"
            type="text"
        >
        <button
            (click)="add()"
            *ngIf="buttonAdd"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="admin_add"
            type="button"
        >
            <i class="icon icon-size-9 ion-plus-circled"></i>
            {{buttonAdd}}
        </button>
        <button
            (click)="uploadFileAction()"
            *ngIf="buttonUploadFile"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="admin_upload_file"
            type="button"
        >
            <i class="icon icon-size-9 ion-plus-circled"></i>
            {{buttonUploadFile}}
            <input
                #uploadFile
                (change)="onUploadFileSelected($event.target.files) //noinspection UnresolvedVariable"
                style="display: none"
                type="file"
            />
        </button>
        <button
            (click)="importButtonClicked()"
            *ngIf="importButton"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="admin_import"
            type="button"
        >
            <i class="icon icon-size-9 ion-plus-circled"></i>
            {{importButton}}
            <input
                #fileInput
                (change)="onImportFileSelected($event.target.files) //noinspection UnresolvedVariable"
                style="display: none"
                type="file"
            />
        </button>
        <a
            (click)="delete()"
            *ngIf="deleteButton"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="admin_delete"
            style="color: #ffffff !important;"
            type="button"
        >
            <i class="icon icon-size-9 ion-plus-circled"></i>
            {{deleteButton}}
        </a>
        <a *ngIf="updateButton" class="btn btn-square btn-xs btn-warning color-blue margin-left-5" id="admin_refresh">
            <i class="icon icon-size-9 ion-refresh"></i>
            {{updateButton}}
        </a>
        <ng-content>
        </ng-content>
    </div>
</div>
<div *ngIf="isOpenHelp" class="admin-help-box">
    <div class="admin-help-title"></div>
    <div class="admin-help-body alert alert-info"></div>
    <div class="admin-help-footer"></div>
</div>
