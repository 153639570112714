<div class="mb-8">
    <label
        class="control-label"
        [style.color]="submitted && statusError && '#a94442'"
        [for]="id"
    >{{(label)|translate}}</label>
    <div style="display: flex" class="input-group datepicker-container">
        <input
            type="text"
            class="form-control datepicker-input"
            [id]="id"
            #datepickerStart="bsDatepicker"
            bsDatepicker
            [value]="value"
            (bsValueChange)="checkDate($event)"
            [bsConfig]="{
                dateInputFormat: (customConfig?.format || defaultDateFormat),
                isAnimated: true,
                adaptivePosition: true,
                customTodayClass: 'datepicker',
                useUtc: (!!customConfig?.useUtc)
               }"
        >
        <div class="input-group-btn" style="width: auto">
            <button
                class="btn btn-default"
                type="button"
                style="margin: 0"
                (click)="datepickerStart.toggle()"
                [attr.aria-expanded]="datepickerStart.isOpen"
            >
                <i class="ion-ios-calendar"></i>
            </button>
        </div>
    </div>
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
