<app-news-form
    (update)="updateEntity(editEntity)"
    *ngIf="addEditModal === true"
    [content]="editEntity"
></app-news-form>
<div>
    <app-table-title
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
        [buttonAdd]="'Add news'"
        [titleTable]="'Feed'"
        class="w-100"
        search="true"
    ></app-table-title>
    <div class="ng-binding">
        <table
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            class="table table-expandable expand-single"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                    class="col-id selected"
                >
                    <a (click)="sortByColumn('id')" style="cursor: pointer">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-status': field.type === 'boolean', 'col-15': field.id === 'folder_path'}"
                >
                    <a (click)="sortByColumn(field.id)" style="cursor: pointer">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                    class="col-15"
                >
                    <a (click)="sortByColumn('created_at')" style="cursor: pointer">
                        {{'messages.global.table_column_created_at'|translate}}
                    </a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                (click)="selectEntity(entity)"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                class="clickable collapsed ng-scope"
            >
                <td (click)="$event.stopPropagation()" class="col-checkbox">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            (change)="checkEntity(entity)"
                            [checked]="entity.checked"
                            [id]="'ck_' + entity.id"
                            type="checkbox"
                        >
                        <label [for]="'ck_' + entity.id">
                            <span><span></span></span>
                        </label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>{{entity.name}}</td>
                <td>{{entity.slug}}</td>
                <td>{{entity.source}}</td>
                <td class="col-status">
                    <app-status-icon [status]="entity.is_active"></app-status-icon>
                </td>
                <td>{{entity.created_at}}</td>
            </tr>
            <tr [ngClass]="{'expanded': selectedEntity?.id === entity.id}" class="row-expansion ng-scope">
                <td></td>
                <td></td>
                <td colspan="5">
                    <app-news-table-tr
                        (openModal)="addEntity(entity)"
                        (updateContent)="updateEntity(entity, $event)"
                        [entity]="entity"
                    >
                    </app-news-table-tr>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        (checkAllElements)="checkAllEntities($event)"
        (deleteSelected)="deleteSelected()"
        (paginateFilterSort)="paginateFilterSort($event)"
        [elements]="entities"
        [selectedIds]="selectedIds"
        [showInactiveUsersButton]="false"
        [withDeleteButton]="true"
        [withExport]="false"
        class="w-100"
    ></app-table-footer>
</div>
