import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AdminUsersList} from '../../../../models/models';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-user-table-tr-info',
    templateUrl: './user-table-tr-info.component.html',
    styleUrls: ['./user-table-tr-info.component.scss']
})
export class UserTableTrInfoComponent implements OnInit {

    @Input() user: AdminUsersList;
    @Output() openModal = new EventEmitter();
    @Output() openChangePasswordModal = new EventEmitter();
    @Output() openStartUpSettingsModal = new EventEmitter();
    @Output() openProfileDocumentsModal = new EventEmitter();
    @Output() openProfileModal = new EventEmitter();
    @Output() updateUser = new EventEmitter();

    public toggleStatusDisabled = false;

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'locale',
            name: 'messages.admin.users_page.table_column_locale',
            type: 'string'
        },
        {
            id: 'time_zone',
            name: 'messages.admin.users_page.table_column_time_zone',
            type: 'string'
        },
        {
            id: 'nr_of_groups',
            name: 'messages.admin.users_page.table_column_groups',
            type: 'number'
        },
        {
            id: 'settings.remember_session',
            name: 'messages.admin.users_page.change_startup_setttings',
            type: 'string'
        },
        {
            id: 'phone',
            name: 'messages.global.phone',
            type: 'string'
        },
        {
            id: 'mobile',
            name: 'messages.global.mobile',
            type: 'string'
        },
        {
            id: 'address',
            name: 'messages.global.address',
            type: 'string'
        },
        {
            id: 'subscriptions_count',
            name: 'messages.admin.users_page.subscriptions',
            type: 'number'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        },
        {
            id: 'total_portal_logins',
            name: 'messages.admin.users_page.total_number_of_portal_logins',
            type: 'number'
        },
        {
            id: 'portal_logins_last_30_days',
            name: 'messages.admin.users_page.number_of_portal_logins_last_30_days',
            type: 'number'
        },
        {
            id: 'total_addin_logins',
            name: 'messages.admin.users_page.total_number_of_addin_logins',
            type: 'number'
        },
        {
            id: 'addin_logins_last_30_days',
            name: 'messages.admin.users_page.number_of_addin_logins_last_30_days',
            type: 'number'
        },
        {
            id: 'last_login',
            name: 'messages.global.last_login',
            type: 'string'
        }
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
    }

    editUser() {
        return this.openModal.emit();
    }

    editProfile() {
        this.openProfileModal.emit();
    }

    editDocuments() {
        this.openProfileDocumentsModal.emit();
    }

    unDeleteUser() {
        this.apiService.unDeleteUser(this.user.id).subscribe(response => {
            this.updateUser.emit(response);
        });
    }

    deleteUser() {
        this.apiService.deleteUser(this.user.id).subscribe(response => {
            this.updateUser.emit('');
        });
    }

    changeStartUpSettings() {
        return this.openStartUpSettingsModal.emit();
    }

    toggleStatus() {
        this.toggleStatusDisabled = true;
        this.apiService.toggleStatus(this.user.id).subscribe(response => {
                this.updateUser.emit(response);
                this.toggleStatusDisabled = false;
            },
            () => {
                this.toggleStatusDisabled = false;
            });
    }

    toggleShowInTeam() {
        this.toggleStatusDisabled = true;
        this.apiService.toggleShowInTeam(this.user.id).subscribe(response => {
                this.updateUser.emit(response);
                this.toggleStatusDisabled = false;
            },
            () => {
                this.toggleStatusDisabled = false;
            });
    }

    changePassword() {
        return this.openChangePasswordModal.emit();
    }
}
