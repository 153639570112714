import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DisplayError, FormConfig, FormLists, ProfileData} from '../../../../models/models';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {FormBuilder, Validators} from '@angular/forms';
import {MatchValidator} from '../../../../validators/matchValidators';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

interface PasswordForm {
    current_password: string;
    password: string;
    repeatPassword: string;
}

@Component({
    selector: 'app-password-change-modal',
    templateUrl: './password-change-modal.component.html',
    styleUrls: ['./password-change-modal.component.scss']
})
export class PasswordChangeModalComponent implements OnInit {
    @Input() profile: ProfileData;
    @Output() closeModal = new EventEmitter();
    @Output() updateProfileSubmit = new EventEmitter();

    @ViewChild(FormComponent, {static: true}) form: FormComponent<PasswordForm>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'current_password',
                label: 'messages.right_side.account_form.password',
                placeholder: '',
                id: 'profile_currentPassword',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required]
            },
            {
                name: 'password',
                label: 'messages.right_side.account_form.new_password',
                placeholder: '',
                id: 'profile_password',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required, Validators.minLength(8)]
            },
            {
                name: 'repeatPassword',
                label: 'messages.right_side.account_form.confirm_password',
                placeholder: '',
                id: 'profile_repeatPassword',
                type: 'text',
                inputType: 'password'
            },
        ],
        validators: [MatchValidator('password', 'repeatPassword')]
    };

    lists: FormLists = {};
    public formName = 'profile';

    constructor(
        private formBuilder: FormBuilder,
        private errorsTransformer: FormErrorsTransformerService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit() {
    }

    closeModalWindow() {
        this.closeModal.emit();
    }

    updateSubmit(formData: PasswordForm) {
        const {current_password, password, repeatPassword} = formData;
        const data = {
            current_password,
            plainPassword: {
                first: password,
                second: repeatPassword
            }
        };

        this.apiService.updateProfilePassword(data).subscribe(() => {
            this.updateProfileSubmit.emit();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    formGlobalErrors(errors: DisplayError[]): string[] {
        return this.errorsTransformer.getGlobalErrors(errors);
    }
}
