import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {HttpResponse} from '@angular/common/http';
import {AdminAddinVersionsList} from '../../../../models/models';
import PropertyAccessor from '../../../../helpers/PropertyAccessor';
import {downloadContent} from '../../../../helpers/DownloadContent';

@Component({
    selector: 'app-addin-version-table-tr-info',
    templateUrl: './addin-version-table-tr-info.component.html',
    styleUrls: ['./addin-version-table-tr-info.component.scss']
})
export class AddinVersionTableTrInfoComponent implements OnInit {

    @Input() version: AdminAddinVersionsList;
    @Output() openModal = new EventEmitter();
    @Output() refresh = new EventEmitter();
    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'original_filename.value',
            name: 'messages.admin.xls_page.table.th.original_filename',
            type: 'string'
        },
        {
            id: 'filename',
            name: 'messages.admin.xls_page.table.th.filename',
            type: 'string'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        }
    ];

    public propertyAccessor = PropertyAccessor;

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    editVersion() {
        return this.openModal.emit();
    }

    toggleStatus() {
        this.apiService.toggleActiveVersion(this.version.id).subscribe(() => {
            this.refresh.emit();
        });
    }

    toggleSupported() {
        this.apiService.toggleSupportedVersion(this.version.id).subscribe(() => {
            this.refresh.emit();
        });
    }

    delete() {
        this.apiService.deleteVersion(this.version.id).subscribe(() => {
            this.refresh.emit();
        });
    }

    downloadVersion() {
        this.apiService.downloadVersion(this.version.id).subscribe(
            (response: HttpResponse<Blob>) => {
                const dataType = 'application/octet-stream';
                const binaryData = [];
                const fileName = response.headers.get('filename');
                binaryData.push(response.body);

                downloadContent(window.URL.createObjectURL(new Blob(binaryData, {type: dataType})), fileName);
            }
        );
    }

    uploadVersion(e: any) {
        const [file] = e.target.files;

        this.apiService.uploadVersion(this.version.id, file).subscribe(() => {
            this.refresh.emit();
        });
    }
}
