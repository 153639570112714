import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AdminMessagesList, FormConfig, FormLists, MessageModel} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class MessageModalComponent implements OnInit, OnDestroy {

    @Output() addComponent = new EventEmitter();
    @Input() message: AdminMessagesList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<MessageModel>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'title',
                label: 'messages.admin.messages_page.form.message.fields.title.label',
                placeholder: 'messages.admin.messages_page.form.message.fields.title.label',
                id: 'message_title',
                type: 'text'
            },
            {
                name: 'body',
                label: 'messages.admin.messages_page.form.message.fields.body.label',
                placeholder: 'messages.admin.messages_page.form.message.fields.body.label',
                id: 'message_body',
                type: 'html-editor'
            },
            {
                name: 'company',
                label: 'messages.admin.messages_page.form.message.fields.company.label',
                placeholder: 'messages.admin.messages_page.form.message.fields.company.placeholder',
                id: 'message_company',
                type: 'typeahead',
            },
            {
                name: 'users',
                label: 'messages.admin.messages_page.form.message.fields.users.label',
                placeholder: '',
                id: 'message_users',
                type: 'typeahead',
                multiple: true
            }
        ],
    };

    lists: FormLists = {};
    private subs$: [Subscription?] = [];

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService) {
    }

    ngOnDestroy(): void {
        for (const sub$ of this.subs$) {
            sub$.unsubscribe();
        }
    }

    ngOnInit() {
        this.formLoadingServiceService.init(2);
        this.entityProvider.getUsers().subscribe(data => {
            this.lists.users = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
        this.entityProvider.getCompanies().subscribe(data => {
            this.lists.company = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
        setTimeout(() => {
            const {form} = this.form;

            this.subs$.push(form.get('company').valueChanges.subscribe((value) => {
                if (value.length > 0) {
                    this.entityProvider.getCompanyUsersEmail(value[0].id).subscribe(data => {
                        form.get('users').setValue(data);
                    });
                }
            }));
        });
    }

    addMessage(data: MessageModel) {
        const request = this.message
            ? this.apiService.updateMessage(this.message.id, data)
            : this.apiService.createMessage(data);

        request.subscribe(response => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }
}
