<div class="form-group">
    <label
        *ngIf="withLabel"
        class="control-label"
        [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
        [for]="field.id"
    >{{field.label|translate}}</label>
    <input
        class="form-control"
        [type]="field.type"
        [id]="field.id"
        [name]="field.name"
        [placeholder]="(field.placeholder || '') | translate"
        [value]="value"
        (change)="onChange($event.target.value)"
        [disabled]="disabled"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
    >
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
