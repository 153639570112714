import {Component, Input, OnInit} from '@angular/core';
import {DisplayError, FrontendPage, ProvidedEntity, SelectValue} from '../../models/models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EntityProviderService} from '../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../services/form-loading-service.service';
import {ApiService} from '../../services/api.service';
import {FormErrorsTransformerService} from '../../services/form-errors-transformer.service';
import {LayoutConfigService} from '../../services/layout-config.service';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-sidebar-left-panel-subscribe',
    templateUrl: './sidebar-left-panel-subscribe.component.html',
    styleUrls: ['./sidebar-left-panel-subscribe.component.scss'],
    providers: [FormLoadingServiceService]
})
export class SidebarLeftPanelSubscribeComponent implements OnInit {
    @Input() set report(value: FrontendPage) {
        this.currentReport = value;
        this.updateFormValues();
    }

    public currentReport: FrontendPage;
    public formName: string;
    public status: boolean;
    public submitted: boolean;
    public form: FormGroup;
    public count;

    public subscribers: ProvidedEntity[] = [];
    public pages: ProvidedEntity[] = [];
    public formats: SelectValue[] = [];
    public timezones: SelectValue[] = [];
    public types: SelectValue[] = [];
    public timeHours: SelectValue[] = [];
    public timeMinutes: SelectValue[] = [];


    public days: SelectValue[] = [];
    public errors: DisplayError[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
        private authService: AuthService,
        private errorsTransformer: FormErrorsTransformerService,
        public layoutConfigService: LayoutConfigService
    ) {
    }

    ngOnInit() {
        this.status = false;
        this.formName = 'subscriptionsFields';
        this.count = [];
        this.submitted = false;

        this.formLoadingServiceService.init(4);

        this.entityProvider.getTimezones().subscribe((data: SelectValue[]) => {
            this.timezones = data;
            this.form.get('timeZone').setValue(data[0].value);
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getSubscriptionFormConfig().subscribe(data => {
            this.formats = data.formats;
            this.days = data.days;
            this.types = data.types;
            this.timeMinutes = data.minutes;
            this.timeHours = data.hours;

            this.form.get('format').setValue(data.formats[0].value);
            this.form.get('dayOfDelivery').setValue(data.days[0].value);
            this.form.get('hourOfDelivery').setValue(data.hours[0].value);
            this.form.get('minuteOfDelivery').setValue(data.minutes[0].value);
            this.form.get('type').setValue(data.types[0].value);

            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getUsers().subscribe(data => {
            this.subscribers = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getPages().subscribe(data => {
            this.pages = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    updateFormValues() {
        const {currentReport} = this;

        if (!this.form) {
            this.initForm();
        }

        this.form.get('report').setValue(currentReport ? currentReport.id : '');
        this.form.get('name').setValue(currentReport ? currentReport.name : '');
    }

    initForm() {
        this.form = this.formBuilder.group({
            report: ['', Validators.required],
            email: ['', Validators.required],
            name: ['', Validators.required],
            type: ['', Validators.required],
            dayOfDelivery: [''],
            hourOfDelivery: ['', Validators.required],
            minuteOfDelivery: ['', Validators.required],
            timeZone: ['', Validators.required],
            format: ['', Validators.required],
            followers: this.formBuilder.array([])
        });
        this.authService.getLoggedUserObservable().subscribe(user => {
            this.form.get('email').setValue(user.email);
        });
    }

    addSubscription(e: any = null) {
        if (e) {
            e.preventDefault();
        }
        this.submitted = true;

        this.form.get('dayOfDelivery').setValidators([]);

        if (this.form.value.type === 'weekly') {
            this.form.get('dayOfDelivery').setValidators(Validators.required);
        }

        this.errors = this.errorsTransformer.getErrors(this.form);

        if (this.form.status !== 'VALID') {
            return;
        }

        const {
            report,
            email,
            name,
            type,
            dayOfDelivery,
            hourOfDelivery,
            minuteOfDelivery,
            timeZone,
            format,
            followers
        } = this.form.value;

        const data = {
            report,
            email,
            name,
            type,
            dayOfDelivery,
            hourOfDelivery,
            minuteOfDelivery,
            timeZone,
            format,
            ccFollowers: followers
        };

        const request = this.apiService.crateProfileSubscription(data);

        request.subscribe(() => {
            this.closeForm();
        }, error => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, error);
        });
    }

    isFieldValid(field: string) {
        return this.form.get(field).invalid;
    }

    closeForm() {
        return this.layoutConfigService.leftPanelSubscribeToggle();
    }

    addItem(event: any) {
        event.preventDefault();
        this.addFollower();
    }

    deleteItem(event: any, index) {
        event.preventDefault();
        this.removeFollower(index);
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }

    addFollower(value: { email: string, type: string; } = null) {
        const followers = this.form.get('followers') as FormArray;

        followers.push(this.formBuilder.group({
            email: [value && value.email ? value.email : '']
        }));
    }

    removeFollower(index: number) {
        const followers = this.form.get('followers') as FormArray;

        followers.removeAt(index);
    }

    getFollowersErrors() {
        return this.errors.filter(item => item.field === 'ccFollowers').map(item => item.message);
    }

    get formData() {
        return this.form.get('followers') as FormArray;
    }
}
