<div class="container-fluid cover-bg-container request-to-join-page">
    <div class="panel-centered-wrapper">

        <form (ngSubmit)="onSubmit()" [formGroup]="registerForm" class="form-style-labels-inside-inputs panel-centered">
            <a class="no-pointer">
                <img alt="Tools group" class="panel-centered-logo" src="./../../../../assets/img/tg-logo.png">
            </a>

            <h1 class="panel-centered-header">
                Request access
            </h1>

            <p class="mb-2" style="font-size: 13px;">
                Register to access to your ToolsGroup solutions
            </p>

            <div *ngIf="loading" class="text-center">
                <img alt="loading" src="./../../../../assets/img/loading.gif">
            </div>

            <div *ngIf="!loading">
                <ng-container *ngIf="!requested">
                    <div *ngIf="error" class="alert alert-danger">
                        <button class="close" data-dismiss="alert" type="button">
                            <i class="ace-icon fa fa-times"></i>
                        </button>
                        <strong>
                            <i class="ace-icon fa fa-times"></i>
                            {{ 'messages.login_page.error'|translate }}:
                        </strong>
                        {{error}}
                    </div>
                    <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

                    <div class="form-group">
                        <label class="control-label required" for="login_form_first_name">
                            {{'messages.admin.users_page.form.user.fields.first_name.label'|translate}}
                        </label>

                        <input
                            class="form-control"
                            formControlName="firstName"
                            id="login_form_first_name"
                            name="firstName"
                            required="required"
                            type="text"
                        >
                        <div class="focus-highlight"></div>

                    </div>
                    <app-display-input-errors [errorsStrings]="getFieldErrors('firstName')"></app-display-input-errors>

                    <div class="form-group">
                        <label class="control-label required" for="login_form_last_name">
                            {{'messages.admin.users_page.form.user.fields.last_name.label'|translate}}
                        </label>

                        <input
                            class="form-control"
                            formControlName="lastName"
                            id="login_form_last_name"
                            name="lastName"
                            required="required"
                            type="text"
                        >
                        <div class="focus-highlight"></div>
                    </div>
                    <app-display-input-errors [errorsStrings]="getFieldErrors('lastName')"></app-display-input-errors>

                    <p style="font-size: 13px;">
                        {{'messages.request_to_join_page.use_work_email'|translate}}
                    </p>

                    <div class="form-group">
                        <label class="control-label required" for="login_form_email">
                            {{'messages.global.email'|translate}}
                        </label>

                        <input
                            class="form-control"
                            formControlName="email"
                            id="login_form_email"
                            name="email"
                            required="required"
                            type="text"
                            (blur)="checkEmail()"
                        >
                        <div class="focus-highlight"></div>
                    </div>

                    <div class="alert alert-warning" *ngIf="showLimited">
                        You can create an account with this email address, but it will have limited access rights and
                        cannot be promoted to full trial. We strongly encourage you to use a professional email address
                        such as youraddress@yourcompany.com - To avoid abuses of our system, accounts created with
                        webmails such as (NAME OF DOMAIN eg gmx.de) can access a restricted feature-limited version
                        only.
                    </div>


                    <div class="alert alert-danger" *ngIf="showForbidden">
                        You cannot create an account with this email address, please use a professional email address
                        such as youraddress@yourcompany.com instead of a webmail service like (NAME OF DOMAIN eg
                        mailinator.com).
                    </div>

                    <app-display-input-errors [errorsStrings]="getFieldErrors('email')"></app-display-input-errors>

                    <div class="form-group">
                        <label class="control-label required" for="login_form_password">
                            {{'messages.global.password'|translate}}
                        </label>

                        <input
                            class="form-control"
                            formControlName="password"
                            id="login_form_password"
                            name="password"
                            required="required"
                            type="password"
                        >
                        <div class="focus-highlight"></div>
                    </div>

                    <app-display-input-errors [errorsStrings]="getFieldErrors('password')"></app-display-input-errors>

                    <div class="form-group">
                        <label class="control-label required" for="login_form_password_repeat">
                            {{'messages.right_side.account_form.confirm_password'|translate}}
                        </label>

                        <input
                            class="form-control"
                            formControlName="passwordRepeat"
                            id="login_form_password_repeat"
                            name="password"
                            required="required"
                            type="password"
                        >
                        <div class="focus-highlight"></div>
                    </div>

                    <app-display-input-errors [errorsStrings]="getFieldErrors('passwordRepeat')"></app-display-input-errors>

                    <p style="font-size: 13px">Questions or feedback? Please contact our support</p>

                    <div *ngIf="needCaptcha" class="recaptcha-container mt-4 margin-bottom-10">
                        <re-captcha [siteKey]="recaptchaKey" formControlName="captcha"></re-captcha>
                    </div>

                    <div class="panel-centered-actions">
                        <button class="btn btn-lg btn-primary text-capitalize w-100" type="submit" [disabled]="showForbidden">
                            {{'messages.global.submit'|translate}}
                        </button>
                        <div class="margin-top-25">
                            <a
                                [routerLink]="'/login'"
                                class="w-100 text-secondary"
                            >
                                {{'messages.request_to_join_page.back'|translate}}
                            </a>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="requested">
                    <div class="alert alert-info">
                        {{'messages.request_to_join_success_page.message'|translate}}
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center">
                            <a
                                [routerLink]="'/login'"
                                class="btn btn-lg btn-very-dark-grey text-capitalize w-100 text-white"
                                role="button"
                            >
                                {{'messages.request_to_join_page.back'|translate}}
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div>

            </div>
        </form>
    </div>
</div>
