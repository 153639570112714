<div class="actions-footer-bar">
    <div class="actions-bar-panel-left" style="width: 50%">
        <div class="checkbox checkbox-inline checkbox-gray" *ngIf="withSelectAll">
            <input type="checkbox" id="ck_all" (change)="checkAll()" [value]="value">
            <label for="ck_all" class="ng-binding">
                        <span>
                            <span></span>
                        </span>
                {{'messages.admin.multi_actions.select_all'|translate}}
                <strong *ngIf="selectedIds?.length > 0">({{selectedIds?.length}})</strong>
            </label>
        </div>
        <span class="ng-hide" *ngIf="selectedIds?.length > 0">
                <app-modal-confirm *ngIf="withDeleteButton">
                    <a class="action-link action-link-active" id="ck_delete_selected" (click)="deleteSelectedClicked()">
                        <i class="icon icon-size-9 ion-trash-a"></i>
                        {{'messages.admin.multi_actions.delete'|translate}}
                    </a>
                </app-modal-confirm>
                <a
                    *ngIf="withExport"
                    class="action-link action-link-active"
                    id="ck_export_selected"
                    (click)="exportSelectedClicked()"
                >
                    <i class="icon icon-size-9 ion-share"></i>
                    {{'messages.admin.multi_actions.export'|translate}}
                </a>
        </span>

        <span *ngIf="withExport">
            <a class="action-link" id="ck_export_all" (click)="exportAllClicked()">
                <i class="icon icon-size-9 ion-share"></i>
                {{'messages.admin.multi_actions.export_all'|translate}}
            </a>
        </span>
    </div>
    <div class="actions-bar-panel-right">
        <a (click)="showInactiveUsers()" style="cursor: pointer" *ngIf="showInactiveUsersButton">
            <span *ngIf="!inactive">
                {{'messages.admin.users_page.show_inactive_users'|translate}}
            </span>
            <span *ngIf="inactive">
                <i class="ion-eye-disabled color-red"></i>
                {{'messages.admin.users_page.hide_inactive_users'|translate}}
            </span>
        </a>
        {{'messages.admin.multi_actions.showing'|translate}}
        <div class="form-group no-background">
            <select class="form-control" (change)="onPaginationChange($event)">
                <option *ngFor="let paginationOption of pagerService.paginationOptions" [value]="paginationOption">
                    {{paginationOption}}
                </option>
            </select>
        </div>
        {{'messages.admin.multi_actions.per_page'|translate}}
        <div class="table-page-selector">
            <a
                (click)="gotToFirstPage()"
                [attr.disabled]="pagerService.pager?.current_page === 1 ? 'disabled' :'false'"
            >
                <<
            </a>
            <a
                (click)="gotToPreviousPage()"
                [attr.disabled]="pagerService.pager?.current_page === 1 ? 'disabled' :'false'"
            >
                {{'messages.global.back'|translate}}
            </a>
            {{pagerService.pager.first_item_number}}-{{pagerService.pager.last_item_number}}
            {{'messages.global.of'|translate}}
            {{pagerService.pager.nr_of_items}}
            <a
                (click)="gotToNextPage()"
                [attr.disabled]="pagerService.pager?.last_item_number === pagerService.pager.nr_of_items ? 'disabled' :'false'"
            >
                {{'messages.global.next'|translate}}
            </a>
            <a
                (click)="gotToLastPage()"
                [attr.disabled]="pagerService.pager?.last_item_number === pagerService.pager.nr_of_items ? 'disabled' :'false'"
            >
                >>
            </a>
        </div>
    </div>

</div>
<hr class="my-0">
<div class="actions text-center">
    <br>
    <div class="row"></div>
</div>
