<div class="form-group">
    <label
        [for]="config.id || ''"
        [style.color]="!isFieldValid ? '#a94442' :null"
        class="control-label"
    >{{ config.label|translate}}</label>
    <textarea
        #area
        (blur)="touch()"
        (input)="autoHeight($event.target)"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="config.id || ''"
        [name]="config.id || ''"
        [ngClass]="{'is-invalid' : !isFieldValid, 'auto-height': !!config.autoheight}"
        [ngStyle]="{
            height: config.autoheight ? null: 'auto',
            resize: config.autoheight ? null: 'vertical'
        }"
        [placeholder]="(config.placeholder || '')|translate"
        [rows]="config.autoheight ? 1 : 4"
        class="form-control"
    ></textarea>

    <ng-content>
    </ng-content>
</div>
