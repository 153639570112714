<div class="evo-card">
    <div class="card-header p-4">
        <h3 class="p-0 m-0">{{'messages.git_boards.form.header'|translate}}</h3>
    </div>
    <div class="mb-2 form-style-bordered-inputs p-4">
        <div class="form-group mb-4">
            <label
                [style.color]="isTitleInvalid() ? '#a94442': null"
                class="control-label"
                for="issue_title"
            ><h4 class="m-0">{{'messages.git_boards.form.title'|translate}}</h4></label>
            <input
                [formControl]="form.controls.title"
                [ngClass]="{'is-invalid': isTitleInvalid()}"
                class="form-control"
                id="issue_title"
                name="title"
                [placeholder]="'messages.git_boards.form.title'|translate"
                type="text"
            >
            <div *ngIf="isTitleInvalid()" class="help-block">
                <ul class="list-unstyled">
                    <span class="ion-android-alert"></span>&nbsp;
                    <span class="pre-line">{{'messages.git_boards.form.required'|translate}}</span>
                </ul>
            </div>
        </div>

        <h4>{{'messages.git_boards.form.description'|translate}}</h4>

        <app-markdown-editor [formControl]="form.controls.description" [allowMentions]="true" (mentionChanged)="mentionChangedHandler($event)">
        </app-markdown-editor>

        <div class="row form-style-bordered-inputs">
            <div class="col-12 col-md-6">
                <ng-template #member let-item="item">
                    <div class="d-flex">
                        <img [src]="item.avatar_url" alt="avatar" class="avatar mr-2">
                        <div>
                            <div>{{item.name}}</div>
                            <div>{{item.username}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template #label let-item="item">
                    <div class="d-flex align-items-center">
                        <div class="mr-2" style="height: 10px; width: 10px;" [style.backgroundColor]="item.color"></div>
                        <div>{{item.name}}</div>
                    </div>
                </ng-template>

                <app-select2
                    [itemTemplate]="member"
                    [filterFunction]="memberFilterFunction"
                    [options]="members"
                    [controlLabel]="'Assignee'"
                    [formControl]="form.controls.assignee"
                    buttonLabelPath="name"
                ></app-select2>

                <app-select2
                    [itemTemplate]="label"
                    [filterFunction]="labelFilterFunction"
                    [options]="labels"
                    [controlLabel]="'Labels'"
                    [formControl]="form.controls.labels"
                    buttonLabelPath="name"
                    [multiple]="true"
                ></app-select2>
            </div>
            <div class="col-12 col-md-6 form-group">
                <app-datepicker
                    [customConfig]="{
                                id: 'due_date',
                                label: 'Due date',
                                format: 'YYYY-MM-DD',
                                useUtc: true
                             }"
                    [formControl]="form.controls.due_date"
                ></app-datepicker>
            </div>
        </div>

        <div class="text-right">
            <button
                (click)="submit()"
                class="btn btn-square btn-primary btn-sm mr-4"
            >{{'messages.global.submit'|translate}}</button>
            <button
                (click)="onCancel()"
                class="btn btn-square btn-default btn-sm"
            >{{'messages.global.cancel'|translate}}</button>
        </div>
    </div>
</div>
