<div class="form-group">
    <label
        *ngIf="withLabel"
        [for]="config.id"
        [style.color]="!isFieldValid? '#a94442' : null"
        class="control-label"
    >{{config.label|translate}}</label>
    <input
        (blur)="touch()"
        (input)="onChange($event.target.value)"
        [disabled]="disabled"
        [id]="config.id"
        [name]="config.name"
        [ngClass]="{'is-invalid' : !isFieldValid}"
        [placeholder]="(config.placeholder || '') | translate"
        [type]="config.inputType || 'text'"
        [value]="value"
        class="form-control"
    >
    <ng-content>
    </ng-content>
</div>
