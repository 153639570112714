import {Component, OnInit} from '@angular/core';
import {LEAVE_REQUEST_STATUSES, LEAVE_REQUEST_TYPES} from '../../../services/constants';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {HolidayRequest} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';

interface TableCol {
    id: string;
    label: string;
    selected?: boolean;
    order?: string;
}

@Component({
    selector: 'app-holidays-requests-list',
    templateUrl: './holidays-requests-list.component.html',
    styleUrls: ['./holidays-requests-list.component.scss']
})
export class HolidaysRequestsListComponent implements OnInit {
    public cols: TableCol[] = [
        {id: 'id', label: '#'},
        {id: 'start', label: 'messages.holidays_requests.th.start_date'},
        {id: 'end', label: 'messages.holidays_requests.th.end_date'},
        {id: 'cause', label: 'messages.holidays_requests.th.cause'},
        {id: 'type', label: 'messages.holidays_requests.th.type'},
        {id: 'duration', label: 'messages.holidays_requests.th.duration'},
        {id: 'remain_holidays_allowance', label: 'messages.holidays_requests.th.remain'},
        {id: 'status', label: 'messages.holidays_requests.th.status'},
        // {id: 'created_at', label: 'Requested at'},
        // {id: 'updated_at', label: 'Last change'},
    ];

    public filteredRequests: HolidayRequest[] = [];
    public requests: HolidayRequest[] = [];
    public isHr = false;
    public userId = null;

    requestStatuses: { name: string; color: string; value: string; enabled: boolean }[] = [];
    leaveFilterTypes = [];
    requestType = null;
    typesTranslations = {};
    term = '';
    showModal = false;
    editItem = null;

    page = 1;
    perPage = 10;
    selectedCol = null;

    public holidaysUsed = 0;
    public holidaysRemain = 0;
    public holidaysTotal = 0;

    constructor(private layoutConfigService: LayoutConfigService, private api: ApiService, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.isHr = this.auth.isHr();
        this.userId = this.auth.getLoggedUser()?.id;

        this.requestStatuses = LEAVE_REQUEST_STATUSES.map(item => ({
            ...item,
            value: item.name,
            enabled: item.name === 'requested'
        }));

        this.leaveFilterTypes = [
            {name: '---Select type---', value: null},
            ...LEAVE_REQUEST_TYPES.map(item => ({...item}))
        ];

        LEAVE_REQUEST_TYPES.forEach(item => {
            this.typesTranslations[item.value] = item.name;
        });

        if (this.isHr) {
            this.cols.splice(1, 0, {
                id: 'stringified',
                label: 'User',
            });
        }

        this.loadList();

        setTimeout(() => {
                if (this.layoutConfigService.sidebarLeft) {
                    this.layoutConfigService.sidebarLeftToggle();
                }
            }
        );
    }

    loadList() {
        const requestOptions = {
            statuses: this.requestStatuses.filter(i => i.enabled).map(i => i.value),
            group: this.isHr ? undefined : 'me'
        };

        this.api.getHolidaysRequest(requestOptions).subscribe(data => {
            this.requests = data.map(this.mapRequest);
            this.filterRequests();
        });

        this.api.checkAllowanceForUser(this.auth.getLoggedUser().id, (new Date()).getFullYear())
            .subscribe(data => {
                this.holidaysUsed = data.total_holidays_allowance - data.holidays_remain;
                this.holidaysRemain = data.holidays_remain;
                this.holidaysTotal = data.total_holidays_allowance;
            });
    }

    filterRequests() {
        let requests = [...this.requests];

        if (this.requestType !== null) {
            requests = this.requests.filter(item => item.type === this.requestType);
        }

        // const selectedStatuses = this.requestStatuses.filter(item => item.enabled).map(item => item.value);
        // requests = requests.filter(item => selectedStatuses.includes(item.status));

        if (this.term) {
            const term = this.term.toLowerCase();

            requests = this.requests.filter(item => {
                const keys = Object.keys(item);

                for (const key of keys) {
                    if (item[key]?.toString().toLowerCase().includes(term)) {
                        return true;
                    }
                }

                return false;
            });
        }

        if (this.selectedCol) {
            const {id, order} = this.selectedCol;

            requests.sort((a, b) => {
                const aValue = a[id];
                const bValue = b[id];

                if (aValue === bValue) {
                    return 0;
                }

                let result = aValue > bValue ? 1 : -1;

                if (order === 'DESC') {
                    result *= -1;
                }

                return result;
            });
        }

        this.filteredRequests = requests;
    }


    markHolidaysRequest(request: HolidayRequest, action: 'accept' | 'reject') {
        request.loading = true;

        this.api.markHolidaysRequest(request.id, action).subscribe(data => {
            request.loading = false;
            const [currentItem] = this.requests.filter(item => item.id === data.id);

            if (!this.requestStatuses.filter(st => st.enabled && st.value === data.status).length) {
                if (currentItem) {
                    this.requests.splice(this.requests.indexOf(currentItem), 1);
                }

                this.filterRequests();

                return;
            }

            const newRequest = this.mapRequest(data);

            if (!currentItem) {
                this.requests.push(newRequest);
            } else {
                this.requests.splice(this.requests.indexOf(currentItem), 1, newRequest);
            }

            this.filterRequests();
        }, (err) => {
            request.loading = false;
        });
    }

    deleteRequest(request: HolidayRequest) {
        request.loading = true;

        this.api.deleteHolidaysRequest(request.id).subscribe(() => {
            this.loadList();
        }, () => {
            request.loading = false;
        });
    }

    handleThClick(col) {
        this.cols.forEach(i => {
            if (i !== col) {
                i.selected = false;
                i.order = null;
            }
        });

        col.selected = true;
        col.order = col.order === 'DESC' ? 'ASC' : 'DESC';
        this.selectedCol = col;

        this.filterRequests();
    }

    back() {
        window.history.back();
    }

    private mapRequest = (data: HolidayRequest) => {
        const [status] = LEAVE_REQUEST_STATUSES.filter(i => i.name === data.status);

        return {
            ...data,
            status: status?.name,
            status_color: status?.color,
            stringified: data.user_name + '\n' + data.user_email,
            loading: false,
        };
    }

    handleCbClick() {
        this.page = 1;
        this.filteredRequests = [];
        this.requests = [];

        this.loadList();
    }
}
