import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'isUrl'
})
export class IsUrlPipe implements PipeTransform {

    transform(value: string): boolean {
        if (!value) {
            return false;
        }

        value = value.toString();
        return value.search(/^http(s)?:\/\//) !== -1;
    }

}
