<div class="row-expansion-body">
    <div class="form-group">
        <label class="control-label float-left">{{'messages.admin.workbooks_template_page.table.th.uploaded_at'|translate}}
            :</label>
        <p class="form-control-static">{{workbookTemplate.created_at !== null ? workbookTemplate.created_at : ' -- '}}</p>
    </div>
    <div class="form-group">
        <label class="control-label float-left">{{'messages.admin.workbooks_template_page.table.th.uploaded_by'|translate}}
            :</label>
        <p class="form-control-static">{{workbookTemplate.created_by !== null ? workbookTemplate.created_by : ' -- '}}</p>
    </div>
    <div class="form-group" *ngIf="workbookTemplate.created_at != workbookTemplate.updated_at">
        <label class="control-label float-left">{{'messages.admin.workbooks_template_page.table.th.updated_at'|translate}}
            :</label>
        <p class="form-control-static">{{workbookTemplate.updated_at !== null ? workbookTemplate.updated_at : ' -- '}}</p>
    </div>
    <div
        class="form-group"
        *ngIf="workbookTemplate.updated_by != '' && workbookTemplate.created_by != workbookTemplate.updated_by"
    >
        <label class="control-label float-left">{{'messages.admin.workbooks_template_page.table.th.updated_by'|translate}}
            :</label>
        <p class="form-control-static">{{workbookTemplate.updated_by !== null ? workbookTemplate.updated_by : ' -- '}}</p>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbooks_edit_{{workbookTemplate.id}}"
            (click)="editWorkbookTemplate()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary help-target margin-left-5"
            id="workbooks_update_{{workbookTemplate.id}}"
            (click)="updateWorkbookTemplateAction()"
        >
            <i class="icon icon-size-9 ion-document"></i>
            {{'messages.admin.workbook_templates_page.update_template_file'|translate}}
            <input
                type="file"
                #updateWorkbookTemplateInput
                style="display: none"
                (change)="onFileSelected($event.target.files) //noinspection UnresolvedVariable"
            />
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5 admin-workbooks-edit-button help-target"
            id="workbooks_download_{{workbookTemplate.id}}"
            (click)="download()"
        >
            <i class="icon icon-size-9 ion-ios-download"></i>
            {{'messages.admin.workbook_templates_page.download_file'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5 admin-workbooks-edit-button help-target"
            id="workbooks_toggle_cache_{{workbookTemplate.id}}"
            (click)="toggleCache()"
        >
            <i
                class="icon icon-size-9"
                [ngClass]="{
                    'ion-android-radio-button-on': workbookTemplate.is_cache_enabled,
                    'ion-android-radio-button-off': !workbookTemplate.is_cache_enabled
                }"
            ></i>
            {{'messages.admin.workbook_templates_page.toggle_cache'|translate}}
        </button>
        <app-modal-confirm>
            <button
                (click)="deleteWorkbook()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="workbooks_delete_{{workbookTemplate.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
