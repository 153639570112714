import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appContentsForm]'
})
export class ContentsFormDirective {

    constructor(public viewContainerRef: ViewContainerRef) {
    }

}
