import {Component, forwardRef, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import PropertyAccessor from '../../helpers/PropertyAccessor';

@Component({
    selector: 'app-select2',
    templateUrl: './select2.component.html',
    styleUrls: ['./select2.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Select2Component),
            multi: true
        }
    ]
})
export class Select2Component implements OnInit, OnDestroy, ControlValueAccessor {
    private change: (value: any) => void;
    private touched: () => void;

    @Input() set options(value: any[]) {
        this.currentOptions = value;
        this.filterResults(this.currentSearchValue);
    }

    @Input() itemTemplate: TemplateRef<any>;
    @Input() filterFunction: (item: any, value: any) => boolean;
    @Input() cardTitle: string;
    @Input() controlLabel: string;
    @Input() buttonLabelPath: string = null;
    @Input() multiple = false;

    public filteredOptions: any[] = [];
    public selectedValue = new Set();
    public currentSearchValue = '';
    public buttonLabel = '';
    public showForm = false;

    private currentOptions: any[] = [];

    select(value) {
        const {multiple, selectedValue} = this;

        if (multiple) {
            if (selectedValue.has(value)) {
                selectedValue.delete(value);
            } else {
                selectedValue.add(value);
            }
        } else {
            selectedValue.clear();
            selectedValue.add(value);
        }

        this.change(multiple ? [...selectedValue] : value);

        if (!multiple) {
            this.buttonLabel = this.buttonLabelPath ? PropertyAccessor.getValue(value, this.buttonLabelPath) : value;

            this.currentSearchValue = '';
            this.filterResults('');
            this.closeForm();

            return;
        }

        let label = this.buttonLabelPath ? PropertyAccessor.getValue([...selectedValue][0], this.buttonLabelPath) : value;

        if (selectedValue.size > 1) {
            label += ' +' + (selectedValue.size - 1);
        }

        this.buttonLabel = label;
    }

    constructor() {
    }

    ngOnDestroy(): void {
        this.closeForm();
    }

    ngOnInit(): void {
    }

    filterResults(value) {
        if (!value) {
            this.filteredOptions = [...this.currentOptions];
            return;
        }

        this.filteredOptions = this.currentOptions.filter(item => this.filterFunction(item, value));
    }

    registerOnChange(fn: any): void {
        this.change = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    writeValue(obj: any): void {
        if (!obj) {
            this.selectedValue.clear();
            return;
        }

        if (this.multiple && Array.isArray(obj)) {
            this.selectedValue = new Set<any>(obj);
            return;
        }

        this.selectedValue.add(obj);
    }


    private closeFormCb = () => {
        this.closeForm();
    }

    openForm() {
        this.showForm = true;

        document.body.addEventListener('click', this.closeFormCb);
    }

    closeForm() {
        this.showForm = false;
        document.body.removeEventListener('click', this.closeFormCb);
    }
}
