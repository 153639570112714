import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminUsersList, DisplayError, ProvidedEntity} from '../../../../models/models';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-user-change-startup-settings-modal',
    templateUrl: './user-change-startup-settings-modal.component.html',
    styleUrls: ['./user-change-startup-settings-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class UserChangeStartupSettingsModalComponent implements OnInit {
    @Input() user: AdminUsersList;
    @Output() changeStartupSettingsSubmit = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    public form: FormGroup;
    public submitted: boolean;

    public rememberLastSession = {value: 'true', name: ''};
    public openThisItem = {value: 'false', name: ''};

    public errors: DisplayError[] = [];
    public folders: ProvidedEntity[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private api: ApiService,
        private errorsTransformer: FormErrorsTransformerService,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.submitted = false;

        this.form = this.formBuilder.group({
            rememberSession: [this.user.settings.remember_session ? 'true' : 'false', [Validators.required]],
            favoriteReportPath: [this.user.settings.favoriteReportPath]
        });

        this.formLoadingServiceService.init(1);
        this.entityProvider.getFolders().subscribe((data: ProvidedEntity[]) => {
            this.folders = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.translateService.get('messages.admin.users_page.form.settings.fields.remember_session.choices.remember_last_session')
            .subscribe(text => {
                this.rememberLastSession.name = text;
            });

        this.translateService.get('messages.admin.users_page.form.settings.fields.remember_session.choices.open_this_item')
            .subscribe(text => {
                this.openThisItem.name = text;
            });
    }


    closeModalWindow() {
        return this.closeModal.emit({type: 'start-up'});
    }

    changeStartupSettings() {
        if (this.submitted) {
            return;
        }

        this.errors = this.errorsTransformer.getErrors(this.form);
        const {form} = this;

        if (form.status === 'INVALID') {
            return;
        }

        this.submitted = true;

        this.api.updateStartupSettings(this.user.id, {
            rememberSession: form.value.rememberSession === 'true' ? 1 : 0,
            favoriteReportPath: form.value.favoriteReportPath,
            // favoriteReport: ''
        }).subscribe(() => {
            this.closeModalWindow();
        }, response => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, response);
            this.submitted = false;
        });
    }

    isFieldValid(fieldName: string): boolean {
        return !this.errors.filter(item => item.field === fieldName).length;
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }
}
