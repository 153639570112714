<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
        >{{folder[field.id] !== null ? folder[field.id] : ' -- '}}</p>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto" *ngIf="field.type === 'list'"
            [ngStyle]="{'max-width': field.id === 'folders' && '600px'}"
        >
            <p class="form-control-static" *ngIf="folder[field.id].length === 0"> -- </p>
            <ul class="comma-separated-list" *ngIf="folder[field.id].length !== 0">
                <li *ngFor="let item of folder[field.id]; let index = item">{{item.stringified}}</li>
            </ul>
        </div>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="folders_copy_{{folder.id}}"
            (click)="copy()"
        >
            <i class="icon icon-size-9 ion-ios-copy"></i>
            {{'messages.admin.folders_page.copy_folder'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="folders_edit_{{folder.id}}"
            (click)="editFolder()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="folders_toggle_{{folder.id}}"
            (click)="toggleActiveFolder()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.folders_page.toggle_active'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="folders_toggle_managers_only_{{folder.id}}"
            (click)="toggleManagersOnly()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.workbooks_page.toggle_managers_only'|translate}}
        </button>
        <app-modal-confirm *ngIf="auth.isSuperAdmin()">
            <button
                (click)="deleteFolder()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="folders_delete_{{folder.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
