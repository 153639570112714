import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
    FolderCopyModel,
    ProfileListsValues,
    ProvidedEntity,
    SelectValue,
    Stringified,
    UserForMention
} from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class EntityProviderService {
    apiURL: string = environment.apiURL + '/api/v1';

    constructor(private httpClient: HttpClient) {
    }

    public getCompanies() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/companies`);
    }

    public getDomains() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/domains`);
    }

    public getPages() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/pages`);
    }

    public getContents() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/content`);
    }

    public getDynamicHtml(companyId: any = null) {
        const q = companyId ? '?company=' + companyId : '';

        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/dynamic-html${q}`);
    }

    public getContentTypes() {
        return this.httpClient.get<SelectValue[]>(`${this.apiURL}/provider/supported-content-types`);
    }

    public getAllContentTypes() {
        return this.httpClient.get<SelectValue[]>(`${this.apiURL}/provider/all-content-types`);
    }

    public getFolders({companyId = null, asRoot = null, global = null} = {}) {
        let params = new HttpParams();

        if (companyId) {
            params = params.set('company', companyId);
        }

        if (asRoot) {
            params = params.set('as_root', '1');
        }

        if (global) {
            params = params.set('is_global', '1');
        }

        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/folders`, {params});
    }

    public getFoldersWithCompanies() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/folders-having-company`);
    }

    public getGroups() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/groups`);
    }

    public getLocales() {
        return this.httpClient.get<SelectValue[]>(`${this.apiURL}/provider/locales`);
    }

    public getUsers(companyId: any = null) {
        let q = '';

        if (companyId) {
            q = `?company=${companyId}`;
        }

        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/users${q}`);
    }

    public getPossibleOwners(companyId = null) {
        let params = new HttpParams();

        if (companyId) {
            params = params.set('company', companyId);
        }

        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/possible-owners`, {params});
    }

    public getTimezones(fullNames: boolean = false) {
        return this.httpClient.get<SelectValue[]>(`${this.apiURL}/provider/timezones`, {
            params: {
                fullNames: fullNames ? '1' : '0'
            }
        });
    }

    public getWorkbookTemplates() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/workbook-templates`);
    }

    public getCompanyManagers() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/company-managers`);
    }

    public getSchedulingFormConfig() {
        return this.httpClient.get<{
            types: SelectValue[];
            days: SelectValue[];
            times: SelectValue[];
        }>(`${this.apiURL}/provider/cron-form-config`);
    }

    public getSubscriptionFormConfig() {
        return this.httpClient.get<{
            types: SelectValue[];
            days: SelectValue[];
            hours: SelectValue[];
            formats: SelectValue[];
            typesCC: SelectValue[];
            minutes: SelectValue[];
        }>(`${this.apiURL}/provider/subscription-form-config`);
    }

    public getCopyFolder(id: any) {
        return this.httpClient.get<FolderCopyModel>(`${this.apiURL}/provider/folder/copy/${id}`, {});
    }

    public getWorkbookExecPatterns() {
        return this.httpClient.get<SelectValue[]>(`${this.apiURL}/provider/workbook-exec-patterns`, {});
    }

    public getProfileListsValues(userId: any = null, companyId: any = null) {
        let q = '';

        if (userId) {
            q += '/' + userId;
        }

        if (companyId) {
            q += '/' + companyId;
        }

        return this.httpClient.get<ProfileListsValues>(`${this.apiURL}/provider/profile-lists-values${q}`);
    }

    public getDepartments() {
        return this.httpClient.get<Stringified[]>(`${this.apiURL}/provider/departments`);
    }

    public getUsersForMentions() {
        return this.httpClient.get<UserForMention[]>(`${this.apiURL}/provider/users-for-mentions`);
    }

    public getCompanyUsersEmail(id: number) {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/companies/${id}/users`);
    }

    public getUserMentions() {
        return this.httpClient.get<ProvidedEntity[]>(`${this.apiURL}/provider/user-mentions`);
    }
}
