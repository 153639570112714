export const ROLE_USER = 'ROLE_USER';
export const ROLE_CONTENT_MANAGER = 'ROLE_CONTENT_MANAGER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

export const ROLE_HIERARCHY = [ROLE_USER, ROLE_CONTENT_MANAGER, ROLE_ADMIN, ROLE_SUPER_ADMIN];

export const TYPE_HTML = 'html';
export const TYPE_DASHBOARD = 'dashboard';
export const TYPE_SHINY = 'shiny';
export const TYPE_DYNAMIC_HTML = 'dynamic-html';
export const TYPE_IFRAME = 'iframe-html';
export const TYPE_HANDBOOK = 'handbook';
export const TYPE_NETWORK_SHARE = 'network-share';
export const TYPE_COURSE = 'course';
export const TYPE_MARKDOWN = 'markdown';
export const TYPE_PRESENTATION = 'presentation';
export const TYPE_WEB_APPLICATION = 'web-app';

export const CONTENT_TYPES_ARRAY = [
    TYPE_HTML,
    TYPE_MARKDOWN,
    TYPE_DASHBOARD,
    TYPE_SHINY,
    TYPE_DYNAMIC_HTML,
    TYPE_IFRAME,
    TYPE_NETWORK_SHARE,
    TYPE_HANDBOOK,
    TYPE_COURSE,
    TYPE_PRESENTATION,
    TYPE_WEB_APPLICATION
];

export const WORKBOOK_ACCESS_STANDARD = 'standard';
export const WORKBOOK_ACCESS_DOWNLOAD_ONLY = 'download-only';
export const WORKBOOK_ACCESS_REFRESH_ONLY = 'refresh-only';
export const WORKBOOK_ACCESS_COMMIT_ONLY = 'commit-only';
export const WORKBOOK_VERSION_1 = 'V1';
export const WORKBOOK_VERSION_2 = 'V2';

export const WORKBOOK_SEMICOLON_CSV_SEPARATOR = '1';
export const WORKBOOK_DOT_CSV_SEPARATOR = '2';
export const WORKBOOK_COMMA_CSV_SEPARATOR = '3';
export const WORKBOOK_DEFAULT_CSV_SEPARATOR = WORKBOOK_COMMA_CSV_SEPARATOR;

export const WORKBOOK_DECIMAL_DELIMITER_COMMA = 'comma';
export const WORKBOOK_DECIMAL_DELIMITER_DOT = 'dot';

export const WORKBOOK_ACCESS_TYPES = [
    WORKBOOK_ACCESS_STANDARD,
    WORKBOOK_ACCESS_DOWNLOAD_ONLY,
    WORKBOOK_ACCESS_REFRESH_ONLY,
    WORKBOOK_ACCESS_COMMIT_ONLY
];

export const PING_INTERVAL = 10000;
export const DEFAULT_PAGE_TITLE = 'Portal — Evo';
export const DEFAULT_LOCALE = 'en';


export const LEAVE_REQUEST_TYPES = [
    {name: 'Compensate', value: 0},
    {name: 'Paid leave', value: 1},
    {name: 'Maternity leave', value: 2},
    {name: 'Paternity leave', value: 3},
    {name: 'Public holiday', value: 4},
    {name: 'Sick leave', value: 5},
    {name: 'Exam leave', value: 6},
    {name: 'Unpaid leave', value: 7},
];

export const LEAVE_REQUEST_STATUSES = [
    // {name: 'planned', color: '#999'},
    {name: 'requested', color: '#f89406'},
    {name: 'accepted', color: '#468847'},
    {name: 'rejected', color: '#ff0000'},
    // {name: 'cancellation', color: '#ff0000'},
    // {name: 'canceled', color: '#ff0000'},
];

export const MAX_INT = 2147483647;
