<app-message-modal
    *ngIf="addEditModal === true"
    (addComponent)="updateEntity()"
    [message]="editEntity"
></app-message-modal>
<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.messages_page.title'|translate"
        [buttonAdd]="'messages.admin.messages_page.add_message'|translate"
        search="true"
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    class="col-id selected"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn('id')">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-5': field.id === 'users'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn(field.id)">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    class="col-20"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                >
                    <a
                        style="cursor: pointer"
                        (click)="sortByColumn('created_at')"
                    >{{'messages.global.table_column_created_at'|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id"><span><span></span></span></label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>
                    {{entity.title}}
                    <strong></strong>
                </td>
                <td>{{entity.users.length}}</td>
                <td>{{entity.created_at_cet|date:'yyyy-MM-dd @ HH:mm'}} {{'messages.admin.messages_page.local_time'|translate }}
                </td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="3">
                    <app-message-table-tr-info
                        [message]="entity"
                        (openModal)="addEntity(entity)"
                        (refresh)="updateEntity()"
                    ></app-message-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>
</div>
