import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';

@Component({
    selector: 'app-global-pages',
    templateUrl: './global-pages.component.html',
    styleUrls: ['./global-pages.component.scss']
})
export class GlobalPagesComponent implements OnInit {
    public publicPages;

    constructor(public apiService: ApiService) {
    }

    ngOnInit(): void {
        this.apiService.getGlobalPages().subscribe(response => {
            this.publicPages = response;
        });
    }
}
