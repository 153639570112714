import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-gitlab-frame',
    templateUrl: './gitlab-frame.component.html',
    styleUrls: ['./gitlab-frame.component.scss']
})
export class GitlabFrameComponent implements OnInit, OnDestroy {

    constructor(public layoutConfigService: LayoutConfigService, private auth: AuthService, private router: Router) {
    }

    private el = null;
    private prevSidebarValue = true;

    ngOnInit(): void {
        if (!this.auth.getLoggedUser().is_evo_employee) {
            this.router.navigateByUrl('/404');
            return;
        }

        this.el = document.querySelector('.page-center-column');

        if (this.el) {
            this.el.style.padding = '5px 0 0 5px';
            this.el.style.overflowY = 'hidden';
        }

        this.prevSidebarValue = this.layoutConfigService.sidebarLeft;
        this.layoutConfigService.sidebarLeft = false;
    }

    ngOnDestroy(): void {
        if (!this.el) {
            return;
        }

        this.el.style.padding = null;
        this.el.style.overflowY = null;
        this.layoutConfigService.sidebarLeft = !!this.prevSidebarValue;
    }

}
