import {Injectable} from '@angular/core';
import {EntityProviderService} from './entity-provider.service';
import {UserForMention} from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class CommentsService {
    get users(): UserForMention[] {
        return this.mentionUser;
    }

    public replying = false;
    private mentionUser: UserForMention[] = [];

    constructor(private provider: EntityProviderService) {
    }

    refreshUsers() {
        this.provider.getUsersForMentions().subscribe((users) => {
            this.mentionUser = users;
        });
    }
}
