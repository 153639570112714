import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {ApiService} from '../../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectValue} from '../../../../models/models';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit, OnDestroy {
    public themeOptions: SelectValue[] = [
        {name: 'Light theme', value: 'default'},
        {name: 'Dark theme', value: 'dark'}
    ];

    public form: FormGroup;
    private subscriptions = [];

    constructor(
        private auth: AuthService,
        private api: ApiService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            theme: [this.auth.getLoggedUser().theme, [Validators.required]]
        });

        this.subscriptions.push(this.form.valueChanges.subscribe(() => {
            this.switchTheme(this.form.value.theme === 'dark');
        }));
    }

    switchTheme(darkTheme = false) {
        const el = document.querySelector('body');
        const data = {theme: 'default'};

        // Disable temporary dark theme - https://gitlab.evouser.com/portal/evo-frontend-issues/-/issues/2042
        // if (darkTheme) {
        //     if (!el.classList.contains('theme-dark')) {
        //         el.classList.add('theme-dark');
        //     }
        //     data.theme = 'dark';
        // } else {
        //     el.classList.remove('theme-dark');
        // }

        this.api.changeUiTheme(data).subscribe(() => {
            this.auth.refreshUserInfo();
        }, error => {
            console.log(error);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
