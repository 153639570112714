import {Component, OnInit} from '@angular/core';
import {GlobalErrorService} from '../../../../services/global-error.service';

@Component({
    selector: 'app-fatal-error',
    templateUrl: './fatal-error.component.html',
    styleUrls: ['./fatal-error.component.scss']
})
export class FatalErrorComponent implements OnInit {

    constructor(public globalErrorService: GlobalErrorService) {
    }

    ngOnInit() {
    }

    reload() {
        window.location.reload();
    }

}
