<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
        >{{group[field.id] !== null ? group[field.id] : ' -- '}}</p>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto" *ngIf="field.type === 'list'"
            [ngStyle]="{'max-width': field.id === 'folders' && '600px'}"
        >
            <p class="form-control-static" *ngIf="group[field.id].length === 0"> -- </p>
            <ul class="comma-separated-list" *ngIf="group[field.id].length !== 0">
                <li *ngFor="let item of group[field.id]; let index = item">{{item.stringified}}</li>
            </ul>
        </div>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="groups_edit_{{group.id}}"
            (click)="editGroup()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="groups_change_{{group.id}}"
            (click)="toggleDefault()"
        >
            <i class="icon icon-size-9 ion-android-star"></i>
            {{'messages.admin.groups_page.toggle_is_default'|translate}}
        </button>
        <app-modal-confirm>
            <button
                (click)="deleteGroup()"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="groups_delete_{{group.id}}"
                type="button"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
