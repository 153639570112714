import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
    @ViewChild('wrapper') wrapper: ElementRef;
    @Input() confirmMessage = null;
    @Output() confirmed = new EventEmitter();

    showModal = false;

    constructor() {
    }

    ngOnInit() {
    }

    openModal(event: Event) {
        event.preventDefault();
        this.showModal = true;
    }

    confirm() {
        this.showModal = false;
        const [element] = this.wrapper.nativeElement.children;

        if (element) {
            element.click();
        }

        this.confirmed.emit();
    }

    closeModalWindow() {
        this.showModal = false;
    }
}
