import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {environment} from '../../../../environments/environment';
import {LoginDataModel} from '../../../models/models';
import {FormErrorsTransformerService} from '../../../services/form-errors-transformer.service';
import {FieldsService} from '../../../services/fields.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    errors: any = [];
    needCaptcha = false;
    showResetButton = false;
    recaptchaKey: string = environment.recaptchaKey;
    makeRedirect: () => void = null;

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private authService: AuthService,
                private router: Router,
                private loadingService: LoadingService,
                private errorsTransformerService: FormErrorsTransformerService,
                private fieldsService: FieldsService) {
        this.createForm();
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.redirectAfterLogin();
        } else {
            this.loadingService.clearEvents();
            this.apiService.getLoginInfo().subscribe(data => {
                this.needCaptcha = data.need_captcha;
            });

            const currentUrl = this.router.parseUrl(this.router.url);
            const redirect = currentUrl.queryParamMap.get('redirect');

            if (redirect) {
                try {
                    const r = new URL(redirect);
                    this.makeRedirect = () => {
                        window.location.href = redirect;
                    };
                } catch (e) {
                    if (this.authService.redirectCount < 2) {

                        this.makeRedirect = () => {
                            this.router.navigate([redirect]);
                        };
                    }
                }
            }
        }
    }

    createForm() {
        this.loginForm = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            captcha: [''],
            rememberMe: [false]
        });
    }

    onSubmit() {
        this.loginForm.get('captcha').setValidators(this.needCaptcha ? Validators.required : []);

        this.errors = this.errorsTransformerService.getErrors(this.loginForm);

        if (this.loginForm.status !== 'VALID') {
            return;
        }

        const {username, password, captcha, rememberMe} = this.loginForm.value;
        const data: LoginDataModel = {username, password, rememberMe};

        if (this.needCaptcha) {
            data['g-recaptcha-response'] = captcha;
        }
        this.loading = true;

        this.apiService.login(data).subscribe(response => {
            this.authService.setApiKey(response.api_key);

            this.authService.refreshUserInfo(() => {
                this.redirectAfterLogin();
            });

        }, error => {
            this.loading = false;
            this.errors = this.errorsTransformerService.getErrorsFromResponse(this.loginForm, error);
            this.needCaptcha = error.error.need_captcha;
        });
    }

    getErrors(fieldName: string) {
        return this.fieldsService.getFieldsErrors(this.errors, fieldName, '');
    }

    getGlobalErrors() {
        const data = this.errorsTransformerService.getGlobalErrors(this.errors);

        if (data.length) {
            this.showResetButton = !!data.filter(item => item.includes('Your account is not verified!')).length;
        }

        return data;
    }

    redirectAfterLogin() {
        if (this.makeRedirect) {
            this.makeRedirect();
            this.makeRedirect = null;
            return;
        }

        this.authService.redirectCount = 0;

        this.apiService.getLastSession().subscribe(lastSession => {
            const url = lastSession.last_seen_report || 'app/reports';
            console.log(url);

            this.loading = false;
            this.router.navigate([url]);
        }, () => {
            this.loading = false;
            this.router.navigate(['app/reports']);
        });
    }

    resendVerification() {
        this.loading = true;
        this.errors = [];

        this.apiService.resendVerification(this.loginForm.value.username).subscribe(() => {
            this.loading = false;
        }, (err) => {
            console.log(err);
            this.loading = false;
        });
    }
}
