import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-board-menu',
    templateUrl: './board-menu.component.html',
    styleUrls: ['./board-menu.component.scss']
})
export class BoardMenuComponent implements OnInit {

    constructor() {
    }

    public location = window.location;

    ngOnInit(): void {
    }

    isRootOpened() {
        return window.location.pathname.includes('/app/board');
    }
}
