<div class="help-block" *ngIf="errors?.length || ((control?.dirty || control?.touched) && control?.errors)">
    <ul class="list-unstyled">
        <li *ngFor="let error of controlErrors">
            <i class="ion-android-alert"></i>&nbsp;
            <span>{{error}}</span>
        </li>

        <li *ngFor="let error of errors">
            <i class="ion-android-alert"></i>&nbsp;
            <span>{{error}}</span>
        </li>
    </ul>
</div>
