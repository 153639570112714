import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {AdminUsersList, Pager} from '../../../models/models';
import {PaginationService} from '../../../services/pagination.service';
import {FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    public inactive: boolean;
    public busy: boolean;
    public entities: AdminUsersList[];
    public selectedIds = [];
    public selectedEntity: AdminUsersList;
    public modalType: string;
    public addEditModal: boolean;
    public changeStartUpSettingsModal: boolean;
    public changePasswordModal: boolean;

    public editUserProfileModal = false;
    public editUserProfileDocumentsModal = false;

    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'full_name',
            name: 'messages.admin.users_page.table_column_name',
            type: 'string'
        },
        {
            id: 'company_name',
            name: 'messages.admin.users_page.table_column_company',
            type: 'string'
        },
        {
            id: 'status',
            name: 'messages.admin.users_page.table_column_status',
            type: 'boolean'
        },
        {
            id: 'from_ldap',
            name: 'messages.admin.users_page.table_column_from_ldap',
            type: 'boolean'
        },
        {
            id: 'show_in_team',
            name: 'messages.admin.users_page.table_column_show_in_team',
            type: 'boolean'
        },
        {
            id: 'email',
            name: 'messages.global.email',
            type: 'text'
        }
    ];
    public editEntity: AdminUsersList | null;
    private csvFile = 'evo-users.csv';

    constructor(private apiService: ApiService, public paginationService: PaginationService, public auth: AuthService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.changePasswordModal = false;
        this.changeStartUpSettingsModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadList() {
        this.apiService.getUsers(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminUsersList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity(modalType, entity: AdminUsersList = null) {
        this.modalType = modalType;
        this.addEditModal = !this.addEditModal;
        this.editEntity = entity;
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const user of this.entities) {
                this.selectedIds.push(user.id);
                user.checked = true;
            }
        } else {
            for (const user of this.entities) {
                user.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getUsers(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity() {
        this.loadList();
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getUsers(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    openChangePasswordModal(user: AdminUsersList) {
        this.editEntity = user;
        this.changePasswordModal = true;
    }

    openStartUpSettingsModal(user: AdminUsersList) {
        this.editEntity = user;
        this.changeStartUpSettingsModal = true;
    }

    openProfileModal(user: AdminUsersList) {
        this.editEntity = user;
        this.editUserProfileModal = true;
    }

    openProfileDocumentsModal(user: AdminUsersList) {
        this.editEntity = user;
        this.editUserProfileDocumentsModal = true;
    }

    closeModal(event: { type: string; value?: any }) {
        this.editEntity = null;

        switch (event.type) {
            case 'add':
            case 'edit':
                this.addEditModal = false;
                this.loadList();
                break;
            case 'password':
                this.changePasswordModal = false;
                break;
            case 'start-up':
                this.changeStartUpSettingsModal = false;
                this.loadList();
                break;
            case 'profile':
                this.editUserProfileModal = false;
                this.loadList();
                break;
            case 'documents':
                this.editUserProfileDocumentsModal = false;
                break;
        }
    }

    changePasswordSubmit(form: FormGroup) {
        this.closeModal({type: 'password'});
    }

    changeStartupSettings(form: FormGroup) {
        this.closeModal({type: 'start-up'});
    }

    updateProfileSettings() {
        this.closeModal({type: 'profile'});
    }

    exportAll() {
        this.apiService.exportUsers().subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    exportSelected() {
        this.apiService.exportSelectedUsers(this.selectedIds).subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    deleteSelected() {
        this.apiService.deleteSelectedUsers(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.apiService.getUsers(this.paginationService.pager).subscribe(response => {
                this.entities = response;
            });
        });
    }

    import(files: FileList) {
        const formData = new FormData();
        formData.append('file', files.item(0), files.item(0).name);
        return this.apiService.importUsersFromCSV(formData).subscribe();
    }
}
