import {Pipe, PipeTransform} from '@angular/core';
import {Stringified} from '../models/models';

@Pipe({
    name: 'stringified'
})
export class StringifiedPipe implements PipeTransform {

    transform(value: any | Stringified, ...args: unknown[]): unknown {
        return value.hasOwnProperty('stringified') ? value.stringified : value;
    }

}
