<div class="select2-container position-relative" (click)="$event.stopPropagation()">
    <div class="card" *ngIf="showForm">
        <div class="card-header text-center">
            {{cardTitle || controlLabel || 'Select value'}}
            <i
                class="ion-close"
                style="position: absolute; right: 10px; cursor: pointer"
                (click)="closeForm()"
            ></i>
        </div>
        <div class="card-body">
            <div class="form-group position-relative">
                <input
                    type="text"
                    [placeholder]="'messages.admin.search'|translate"
                    class="form-control"
                    [(ngModel)]="currentSearchValue"
                    (input)="filterResults($event.target.value)"
                    #searchInput
                >
                <i
                    *ngIf="currentSearchValue"
                    class="ion-close"
                    style=" position: absolute; right: 10px; cursor: pointer; top: 13px"
                    (click)="currentSearchValue = '';searchInput.focus()"
                ></i>
            </div>
            <div class="results" style="overflow-y: auto; height: calc(100% - 40px);">
                <div
                    class="result-item position-relative p-2 pl-4"
                    *ngFor="let item of filteredOptions"
                    (click)="select(item)"
                >
                    <i
                        *ngIf="selectedValue.has(item)"
                        class="ion-checkmark"
                        style="position: absolute; left: 5px; top: 50%; transform: translateY(-50%);"
                    ></i>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label">{{controlLabel}}</label>
        <button
            class="btn btn-square btn-outline-dark w-100 text-left"
            (click)="openForm()"
        >{{buttonLabel || 'Select value'}} <i class="ion-chevron-down float-right"></i></button>
    </div>
</div>
