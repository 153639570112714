import {Component, OnInit} from '@angular/core';
import {FormLoadingServiceService} from '../../../services/form-loading-service.service';
import {ApiService} from '../../../services/api.service';
import {MessagesListModel} from '../../../models/models';
import {LayoutConfigService} from '../../../services/layout-config.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    providers: [FormLoadingServiceService]
})
export class MessagesComponent implements OnInit {
    public messages: MessagesListModel[] = [];
    public showDeleted = false;

    constructor(private formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
                public layoutConfigService: LayoutConfigService,
    ) {
    }

    ngOnInit() {
        this.loadList();
    }

    loadList() {
        this.apiService.getMessages().subscribe(data => {
            this.messages = data;
        });
    }

    currentMessages() {
        return this.messages.filter(item => !item.is_deleted);
    }

    deletedMessages() {
        return this.messages.filter(item => item.is_deleted);
    }

    markMessageAsRead(message: MessagesListModel) {
        if (message.is_read) {
            return;
        }

        message.is_read = true;
        this.apiService.markMessageAsRead(message.id).subscribe(() => {
            this.layoutConfigService.setNewMessages(this.layoutConfigService.getNewMessages() - 1);
        });
    }

    deleteMessage(event: Event, message: MessagesListModel) {
        event.preventDefault();
        event.stopPropagation();

        this.apiService.deleteProfileMessage(message.id).subscribe(() => {
            this.loadList();
            this.layoutConfigService.setNewMessages(this.layoutConfigService.getNewMessages() - 1);
        });
    }

    undeleteMessage(event: Event, message: MessagesListModel) {
        event.preventDefault();
        event.stopPropagation();

        this.apiService.undeleteProfileMessage(message.id).subscribe(() => {
            this.loadList();
        });
    }

    onEmailNotificationChange(value: boolean) {
        this.apiService.updateEmailNotifications(value).subscribe();
    }
}
