import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {PaginationService} from '../../../services/pagination.service';
import {EntityProviderService} from '../../../services/entity-provider.service';
import {ProvidedEntity, SelectValue} from '../../../models/models';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-table-title',
    templateUrl: './table-title.component.html',
    styleUrls: ['./table-title.component.scss']
})

export class TableTitleComponent implements OnInit {

    public isOpenHelp: boolean;
    public valueCompany;
    public valueType;
    @ViewChild('fileInput') file;
    @ViewChild('uploadFile') uploadFile;
    @Input() titleTable: string;
    @Input() buttonAdd: string;
    @Input() buttonUploadFile: string;
    @Input() api: boolean;
    @Input() importButton: string;
    @Input() updateButton: string;
    @Input() selectCompany: boolean;
    @Input() deleteButton: string;
    @Input() search: boolean;
    @Input() selectType: boolean;
    @Output() addComponent = new EventEmitter();
    @Output() paginateFilterSort = new EventEmitter();
    @Output() deleteComponent = new EventEmitter();
    @Output() importComponent = new EventEmitter();
    @Output() uploadComponent = new EventEmitter();
    public companies: ProvidedEntity[];
    public types: SelectValue[];
    searchInput: string;
    searchInputChanged = new Subject<string>();
    apiUrl = environment.apiURL;

    constructor(public paginationService: PaginationService, public entityProviderService: EntityProviderService) {
        this.searchInputChanged
            .pipe(
                debounceTime(500))
            .subscribe(() => {
                if (this.paginationService.pager.filters) {
                    this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'term');
                }
                this.paginationService.pager.filters.push({key: 'term', value: this.searchInput.toString().trim()});
                this.paginateFilterSort.emit(this.paginationService.pager);
            });

    }

    ngOnInit() {
        this.isOpenHelp = false;

        if (this.selectType) {
            this.entityProviderService.getAllContentTypes().subscribe(response => {
                this.types = response;
            });
        }

        if (this.selectCompany) {
            this.entityProviderService.getCompanies().subscribe(response => {
                this.companies = response;
            });
        }
    }

    add() {
        return this.addComponent.emit();

    }

    openHelp() {
        this.isOpenHelp = !this.isOpenHelp;
    }

    changedCompany(idCompany: string) {
        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'company_id');
        }
        if (idCompany !== '') {
            this.paginationService.pager.current_page = 1;
            this.paginationService.pager.filters.push({key: 'company_id', value: idCompany});
        }
        this.paginateFilterSort.emit(this.paginationService.pager);
    }

    changedType(type) {
        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'type');
        }
        if (type !== '') {
            this.paginationService.pager.current_page = 1;
            this.paginationService.pager.filters.push({key: 'type', value: type});
        }
        this.paginateFilterSort.emit(this.paginationService.pager);
    }

    executeSearch($event: any) {
        this.searchInputChanged.next();
    }

    delete() {
        this.deleteComponent.emit();
    }

    importButtonClicked() {
        this.file.nativeElement.click();
    }

    onUploadFileSelected(files: FileList) {
        this.uploadComponent.emit(files);
    }

    onImportFileSelected(files: FileList) {
        this.importComponent.emit(files);
    }

    uploadFileAction() {
        this.uploadFile.nativeElement.click();
    }
}
