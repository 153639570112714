<app-request-groups-modal *ngIf="showModal" (addComponent)="closeModal()"></app-request-groups-modal>

<div class="sidebar-header">
    <i class="icon icon-size-10 ion-android-list color-light-green"></i>
    {{'messages.right_side.groups.membership'|translate}}
</div>

<div class="sidebar-body">
    <ul class="item-list" *ngIf="groups?.length">
        <li *ngFor="let group of groups">
            <a class="ng-binding">
                {{'messages.right_side.groups.group_label'|translate}}: {{group.name}}
            </a>
        </li>
    </ul>

    <div class="sidebar-right-bottom-nav" *ngIf="availableGroups">
        <a (click)="openModal()">
            <span class=" ion-android-add-circle"></span>
            {{'messages.right_side.groups.request_new_group_membership'|translate}}
        </a>
    </div>
</div>

<div class="sidebar-body" *ngIf="!availableGroups">
    {{'messages.right_side.groups.no_available_groups'|translate}}
</div>
