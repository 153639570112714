import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {DisplayError} from '../../../models/models';
import {FormErrorsTransformerService} from '../../../services/form-errors-transformer.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

    passwordResetForm: FormGroup;
    loading = false;
    error: string;
    success = false;
    errors: DisplayError[] = [];

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private authService: AuthService,
                private fromErrors: FormErrorsTransformerService,
                private router: Router,
                private loadingService: LoadingService) {
        this.createForm();
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['app/reports']);
        } else {
            this.loadingService.clearEvents();
        }
    }

    createForm() {
        this.passwordResetForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    onSubmit() {
        if (this.loading) {
            return;
        }

        this.errors = this.fromErrors.getErrors(this.passwordResetForm);

        if (this.passwordResetForm.status === 'INVALID') {
            return;
        }

        this.loading = true;
        const email = this.passwordResetForm.value.email as string;

        if (email.includes('@evopricing.local')) {
            window.location.href = 'https://reset-t1uvtrf8l9.evouser.com/';
            return;
        }

        this.apiService.forgotPassword(email).subscribe(response => {
            this.loading = false;
            this.success = true;
        }, error => {
            this.loading = false;
            this.errors = this.fromErrors.getErrorsFromResponse(this.passwordResetForm, error);
        });
    }


}
