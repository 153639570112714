<app-folder-modal
    *ngIf="addEditModal === true"
    (addComponent)="modalSubmitted()"
    [folder]="editEntity"
></app-folder-modal>

<app-folder-copy-modal
    *ngIf="copyModal === true"
    [folder]="copyEntity"
    (addComponent)="closeModal('')"
></app-folder-copy-modal>

<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.folders_page.title'|translate"
        [buttonAdd]="'messages.admin.folders_page.add_folder'|translate"
        search="true"
        selectCompany="true"
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
    >
        <button
            class="btn btn-square btn-xs btn-warning color-blue margin-left-5"
            id="folders_refresh"
            [popover]="'messages.admin.folders_page.update_pages_info'|translate"
            placement="bottom"
            triggers="hover"
            (click)="updatePages()"
        >
            <i class="icon icon-size-9 ion-refresh"></i>
            {{'messages.admin.folders_page.update_pages'|translate}}
        </button>
        <button
            [routerLink]="'/admin/folders-tree'"
            class="btn btn-square btn-xs btn-info color-blue margin-left-5"
            type="button"
        >
            <i class="icon icon-size-9 ion-folder"></i>
            {{'messages.reports_tree_page.reports_tree'|translate}}
        </button>
    </app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    class="col-id selected"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn('id')">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-status': field.type === 'boolean', 'col-15': field.id === 'full_name'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn(field.id)">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    class="col-10"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn('created_at')">
                        {{'messages.global.table_column_created_at'|translate}}
                    </a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id">
                                        <span>
                                            <span></span>
                                        </span>
                        </label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>
                    {{entity.name}}
                    <strong></strong>
                </td>
                <td>
                    {{entity.display_name}}
                    <strong></strong>
                </td>
                <td>{{entity.path}}</td>
                <td>{{entity.nr_of_child_folders}}</td>
                <td>{{entity.pages.length}}</td>
                <td class="col-status">
                    <app-status-icon [status]="!entity.is_deleted"></app-status-icon>
                </td>
                <td class="col-status">
                    <app-status-icon [status]="entity.is_managers_only"></app-status-icon>
                </td>
                <td class="col-status">
                    <app-status-icon [status]="entity.global"></app-status-icon>
                </td>
                <td>{{entity.created_at}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="9">
                    <app-folder-table-tr-info
                        [folder]="entity"
                        (openModal)="addEntity(entity)"
                        (updateFolder)="updateEntity()"
                        (copyFolder)="openCopyModal(entity)"
                    ></app-folder-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>
</div>
