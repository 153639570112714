<div class="navbar-mobile-fake-bar"></div>
<div
    id="page-wrapper"
    class="page-wrapper"
    *ngIf="isUserAuthenticated"
    [ngClass]="
    {
        'sidebar-left-toggled' : !layoutConfigService.sidebarLeft,
        'sidebar-right-toggled' : layoutConfigService.sidebarRight,
        'sidebar-left-panel-save-toggled': layoutConfigService.leftPanelSave,
        'sidebar-left-panel-view-expanded-toggled': layoutConfigService.leftPanelView,
        'sidebar-left-panel-view-collapsed-toggled': layoutConfigService.leftPanelView,
        'sidebar-left-panel-subscribe-toggled': layoutConfigService.leftPanelSubscribe
     }"
>

    <app-navbar-top></app-navbar-top>
    <app-sidebar-left [admin]="true"></app-sidebar-left>
    <div class="page-center-column-wrapper">

        <div class="page-center-column">
            <div class="col-lg-12 col-md-12 col-min-width-1200">
                <app-fatal-error *ngIf="globalErrorService.getError()"></app-fatal-error>
                <router-outlet *ngIf="!globalErrorService.getError()"></router-outlet>
            </div>
        </div>

        <!--        <div class="text-center" style="margin-top: 20vw;"-->
        <!--             ng-if="busy || client.layout.pageLoading">-->
        <!--            <img src="../../../assets/img/loading.gif"/>-->
        <!--            <p>Loading...</p>-->
        <!--        </div>-->

    </div>
    <app-sidebar-right></app-sidebar-right>
</div>
<app-fatal-error *ngIf="!isUserAuthenticated && globalErrorService.getError()"></app-fatal-error>
