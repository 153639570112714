import * as d3 from 'd3';
import * as _ from 'lodash';
import * as crossFilter from 'crossfilter2';
import {ColumnTypesService} from './column-types.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataholderService {
    protected data!: Array<string>;
    private columnDefinitions!: Array<string>;
    private crossFilter: any;
    private knownColumns = d3.map([], DataholderService.valueAccessor);
    private dataParsed = false;
    protected dataHash = '';
    protected configHash = '';
    public enabled = false;

    constructor(private ct: ColumnTypesService) {
        this.ct = ct;
    }

    private static valueAccessor(d: any) {
        return d.name;
    }

    private static applyReplaceRules(value: any, replaceRules: any) {
        if (!_.isEmpty(replaceRules)) {
            return value.replace(new RegExp(replaceRules.search), replaceRules.replace);
        }
        return value;
    }

    public init(data: any, columnDefinitions: any, configHash: string, dataHash: string) {
        if (!this.enabled) {
            return;
        }

        const sameDefinitions = this.configHash === configHash;
        const sameData = this.dataHash === dataHash;

        if (this.crossFilter && sameDefinitions && sameData) {
            return;
        }

        this.configHash = configHash;
        this.dataHash = dataHash;

        this.columnDefinitions = columnDefinitions;
        this.data = data;
        this.updateKnownColumns(columnDefinitions);
        try {
            this.parseData(data);
        } catch (e) {
            console.error(e);
        }

        if (!sameDefinitions || !sameData) {
            this.crossFilter = crossFilter(data);
        }
    }

    public getCrossFilter(): any {
        return this.crossFilter;
    }

    public getKnownColumns() {
        return this.knownColumns;
    }

    public getData() {
        return this.data;
    }

    public clear() {
        this.data = undefined;
        this.crossFilter = undefined;
        this.configHash = undefined;
        this.dataHash = undefined;
    }

    private parseData(data: any) {
        data.forEach((d: any) => {
            this.knownColumns.keys().forEach((columnName: string) => {
                d[columnName] = this.parseValue(d[columnName], columnName);
            });
        });
    }

    private parseValue(value: any, columnName: any) {
        value = _.trim(value);
        const column = this.getKnownColumns().get(columnName);
        const replaced = DataholderService.applyReplaceRules(value, column.replaceRules);
        return this.ct.convertValueViaContext(replaced, column);
    }

    private updateKnownColumns(columnDefinitions: any) {
        this.knownColumns = d3.map([], DataholderService.valueAccessor);
        (columnDefinitions || []).forEach((column: any) => {
            this.knownColumns.set(column.name, column);
        });
    }
}
