import {AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {ChartConfig} from '../../../../models/models';
import {HttpClient} from '@angular/common/http';
import * as dc from 'dc';
import {DashboardService} from '../../../../services/dashboard.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-dropdown-chart',
    templateUrl: './dropdown-chart.component.html',
    styleUrls: ['./dropdown-chart.component.scss']
})
export class DropdownChartComponent implements AfterViewInit, OnDestroy {

    @ViewChild('chart') private chartContainer: ElementRef;
    @Input() private crossFilter: any;
    @Input() public chartConfig: ChartConfig;
    public chartId: string;
    private subscription: Subscription;
    private dcChart;

    constructor(private httpClient: HttpClient,
                private dashboardService: DashboardService,
                private elRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        this.subscription = this.dashboardService.getCrossFilterSubject().subscribe((crossFilter: any) => {
            this.dashboardService.setElementRef(this.elRef);
            this.chartId = this.generateRandomId();
            this.dashboardService.destroyAllBuilder(this.chartId);
            const xDimension = this.dashboardService.createXDimension(crossFilter, this.chartConfig);
            const series = this.dashboardService.createSeries(xDimension, this.chartConfig);
            this.chartConfig = this.dashboardService.associateDataWithAxis(this.chartConfig, series);

            try {
                const chart = dc.selectMenu(this.chartContainer.nativeElement);
                chart.dimension(xDimension).promptText('Filter by');
                this.dcChart = chart;
                chart.group(series[0].group);
                chart.title((d: any) => {
                    return d.key;
                });
                // this.dashboardService.handleNewLine(this.chartConfig, `#${this.chartId}`);
                // this.dashboardService.setChartTitle(this.chartConfig, `#${this.chartId}`, {left: 4});
                // tslint:disable-next-line:max-line-length
                // this.dashboardService.setChartSubtitle(this.chartConfig, `#${this.chartId}`, {left: 4}); // Leave here, do not move, reset position will break otherwise
                chart.render();
            } catch (ex) {
                throw ex;
            }
        });
    }

    generateRandomId() {
        return `${this.dashboardService.getOptions().defaultChartIdPrefix}-${this.chartConfig.id}`;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
