<!--Left sidebar menu expanded-->
<div class="sidebar-left-expanded">
    <div class="sidebar-left-expanded-content-wrapper" [ngClass]="{'actions-visible': exportableFormats}">
        <div
            *ngIf="admin"
            id="sidebar-left-accordion-admin"
            class="panel-group sidebar-nav"
            role="tablist"
            aria-multiselectable="true"
        >
            <app-scrollbar>

                <!--level 1 -->
                <accordion [isAnimated]="true" [closeOthers]="false">
                    <accordion-group *ngFor="let item of (menu?.length ? menu: currentMenu )" [isOpen]="true">
                        <!--suppress AngularUndefinedBinding -->
                        <a accordion-heading>{{ item.label|translate }}</a>
                        <div class="panel-body">
                            <ng-container *ngFor="let child of item.children">
                                <div
                                    class="list-item"
                                    [ngClass]="{selected: this.router.url === '/admin/'+child.route}"
                                    *ngIf="layoutConfigService.getNotificationsCount() > 0 || child.route != 'pending-requests'"
                                >
                                    <a
                                        class="list-item-link word-breakg"
                                        [routerLink]="[child.route]"
                                    >
                                    <span
                                        *ngIf="child.route == 'pending-requests'"
                                        style="margin-right: 5px"
                                    >
                                        <span *ngIf="layoutConfigService.getNotificationsCount() == 0">
                                            {{'messages.admin.accordion.pending_requests.none'|translate }}
                                        </span>

                                        <span *ngIf="layoutConfigService.getNotificationsCount() == 1">
                                            {{'messages.admin.accordion.pending_requests.single'|translate }}
                                        </span>

                                        <span *ngIf="layoutConfigService.getNotificationsCount() > 1">
                                            {{layoutConfigService.getNotificationsCount()}} {{'messages.admin.accordion.pending_requests.multiple'|translate }}
                                        </span>

                                    </span>
                                        <span *ngIf="child.route !== 'pending-requests'">
                                        {{child.label|translate }}
                                    </span>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </accordion-group>
                </accordion>
            </app-scrollbar>
        </div>

        <div *ngIf="admin" class="actions-bar">
            <ul>
                <li>
                    <a class="list-item toggle-expansion" (click)="layoutConfigService.sidebarLeftToggle()">
                        <i class="icon icon-size-8 ion-android-arrow-back"></i>
                        {{'messages.global.collapse'|translate}}
                    </a>
                </li>
            </ul>
        </div>


        <div
            *ngIf="!admin"
            id="sidebar-left-accordion"
            class="panel-group sidebar-nav"
            role="tablist"
            aria-multiselectable="true"
        >
            <app-public-menu *ngIf="reports" [menu]="reports">
            </app-public-menu>

            <app-folder-modal
                *ngIf="currentEditFolder"
                [folder]="currentEditFolder"
                (addComponent)="closeForm($event)"
            >
            </app-folder-modal>

            <app-page-modal
                *ngIf="currentEditPage"
                [page]="currentEditPage"
                (addComponent)="closeForm($event)"
            >
            </app-page-modal>
        </div>

        <div *ngIf="!admin" class="actions-bar">
            <ul>
                <li *ngIf="getSelectedReport() && (hasExportableFormats() || !hasExtensionPromt())">
                    <div class="dropup">
                        <a id="saveReportAs" class="action-link dropdown-toggle">
                            <i class="icon icon-size-8 ion-archive"></i>
                            {{'messages.global.download'|translate}}
                        </a>
                        <ul *ngIf="exportableFormats" class="dropdown-menu" aria-labelledby="saveReportAs">
                            <ng-container *ngIf="hasExtensionPromt()">
                                <li *ngFor="let format of exportableFormats | keyvalue">
                                    <a (click)="saveReport(format.key)">{{format.value}}</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!hasExtensionPromt()">
                                <li>
                                    <a (click)="screenshotService.downloadImage(getSelectedReport() ? getSelectedReport().name : '', 'png')">PNG</a>
                                </li>
                                <li>
                                    <a (click)="screenshotService.downloadImage(getSelectedReport() ? getSelectedReport().name : '', 'jpeg')">JPEG</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <i
                        *ngIf="hasExtensionPromt()"
                        class="icon icon-size-8 ion-help-circled text-danger"
                        [tooltip]="'messages.web.report_page.use_extension'|translate"
                        [tooltipAppendToBody]="true"
                        triggers="hover"
                    ></i>
                </li>
<!--                <li *ngIf="!hasExportableFormats() && hasExtensionPromt()" class="color-white">-->
<!--                    {{'messages.web.report_page.use_extension'|translate}}-->
<!--                </li>-->
                <li *ngIf="hasExportableFormats()">
                    <div class="dropup">
                        <a id="emailReportAs" class="action-link dropdown-toggle">
                            <i class="icon icon-size-10 ion-email"></i>
                            {{'messages.global.email'|translate}}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="emailReportAs">
                            <li *ngFor="let format of exportableFormats | keyvalue">
                                <a (click)="saveReport(format.key, 1)">{{format.value}}</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="hasExportableFormats()">
                    <a class="action-link" (click)="layoutConfigService.leftPanelSubscribeToggle()">
                        <i class="icon icon-size-10 ion-social-rss"></i>
                        {{'messages.global.subscribe'|translate}}
                    </a>
                </li>
                <li>
                    <a
                        class="toggle-expansion wave-ui-body"
                        data-parent="#sidebarMenu"
                        (click)="layoutConfigService.sidebarLeftToggle()"
                    >
                        <i class="icon icon-size-8 ion-android-arrow-back"></i>
                        {{'messages.global.collapse'|translate}}
                    </a>
                </li>
            </ul>
        </div>
        <div id="download">
            <img #canvas>
            <a #downloadLink></a>
        </div>
    </div>
</div><!--/Left sidebar menu expanded-->

<!--Left sidebar menu collapsed-->
<div class="sidebar-left-collapsed">
    <a class="toggle-expansion wave-ui-body" data-parent="#sidebarMenu" (click)="layoutConfigService.sidebarLeftToggle()">
        <i class="icon icon-size-8 ion-android-arrow-down"></i>
        {{'messages.global.expand'|translate}}
    </a>
</div><!--/Left sidebar menu collapsed-->
