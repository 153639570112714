import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';

@Component({
    selector: 'app-emails-lists',
    templateUrl: './emails-lists.component.html',
    styleUrls: ['./emails-lists.component.scss']
})
export class EmailsListsComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    error = false;

    constructor(private formBuilder: FormBuilder, private api: ApiService) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            forbiddenEmails: this.formBuilder.array([]),
            limitedEmails: this.formBuilder.array([]),
        });

        this.api.getEmailsLists().subscribe(data => {
            if (data.forbidden_emails?.length) {
                for (const domain of data.forbidden_emails) {
                    this.addValue('forbiddenEmails', domain);
                }
            }

            if (data.limited_emails?.length) {
                for (const domain of data.limited_emails) {
                    this.addValue('limitedEmails', domain);
                }
            }
        });
    }

    submit() {
        if (this.submitted) {
            return;
        }

        if (this.form.status === 'INVALID') {
            return;
        }

        this.api.updateEmailsLists(this.form.value).subscribe(() => {
            this.error = false;
            this.submitted = false;
        }, (data) => {
            this.error = data.error._global[0];
            this.submitted = false;
        });
    }

    addValue(controlKey: any, value = null) {
        const control = this.form.get(controlKey) as FormArray;

        if (!control) {
            return;
        }

        control.push(this.formBuilder.control(value, [Validators.required, Validators.pattern(/.{2,}\..{2,}/)]));
    }

    removeValue(controlKey: any, index: number) {
        const control = this.form.get(controlKey) as FormArray;

        if (!control) {
            return;
        }

        control.removeAt(index);
    }
}
