<div
    *ngIf="column"
    class="info-row"
>
    <div class="info-label">
        <i
            *ngIf="column.icon"
            class="icon icon-size-10 color-light-green"
            [ngClass]="column.icon"
            style="vertical-align: -1px;"
        ></i>
        {{column.label | translate}}
        <i
            (click)="showForm = true"
            *ngIf="!showForm && security.canEditRow(column.rows)"
            class="icon icon-size-7 ion-edit color-light-green pointer float-right"
            style="vertical-align: 0; margin-left: 5px;"
        ></i>
    </div>
    <div *ngIf="showForm">
        <app-profile-value-form
            (closeForm)="close()"
            (updateProfileSubmit)="close(true)"
            [fields]="security.getFormFields(column.rows)"
            [profile]="profile"
        >

        </app-profile-value-form>
    </div>
    <div *ngIf="!showForm" class="info-items">
        <ng-container *ngFor="let item of column.rows">
            <app-user-profile-row-value
                *ngIf="!item.hidden && security.canSee(item, profile[item.key])"
                [itemValue]="profile[item.key]"
                [key]="item.key"
            >
            </app-user-profile-row-value>
        </ng-container>
        <ng-content></ng-content>
    </div>
</div>
