import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy {

    public news = [];
    public currentPage = 0;
    public lastPage = 9999;
    public busy = false;
    private scrollEl: Element;
    public searchValue = '';
    private pendingRequest;

    searchInputChanged = new Subject<string>();

    constructor(private api: ApiService) {
    }

    ngOnInit(): void {
        this.currentPage = 0;
        this.loadPage();
        this.scrollEl = document.querySelector('.page-center-column');

        if (this.scrollEl) {
            this.scrollEl.addEventListener('scroll', this.onScroll);
        }

        this.searchInputChanged
            .pipe(debounceTime(500))
            .subscribe((value) => {
                if (this.pendingRequest) {
                    this.pendingRequest.unsubscribe();
                }

                this.currentPage = 0;
                this.news = [];
                this.lastPage = 9999;
                this.searchValue = value;
                this.busy = false;

                this.loadPage();
            });
    }

    ngOnDestroy() {
        if (this.scrollEl) {
            this.scrollEl.removeEventListener('scroll', this.onScroll);
        }

        this.searchInputChanged.unsubscribe();
    }

    loadPage() {
        if (this.lastPage <= this.currentPage) {
            return;
        }

        if (this.busy) {
            return;
        }

        this.busy = true;

        this.pendingRequest = this.api.getLatestNews(this.currentPage + 1, this.searchValue).subscribe(response => {
            this.lastPage = parseInt(response.headers.get('last_page'), 0);

            this.news.push(...response.body);
            this.currentPage++;
            this.busy = false;
        }, () => {
            this.busy = false;
        });
    }

    onScroll = (e: any) => {
        const {target} = e;

        if (target.scrollHeight - (target.scrollTop + target.clientHeight) < 100) {
            this.loadPage();
        }
    }
}
