<div *ngIf="errorOccurred">
    <p class="alert alert-danger">Current dashboard content is not working properly, please contact with an administrator</p>
</div>
<div class="text-center" style="margin-top: 20vw;" *ngIf="reportIsLoading">
    <img src="../../../../assets/img/loading.gif" alt="{{'messages.global.loading'|translate}}"/>
    <p>{{'messages.global.loading'|translate}}</p>
</div>
<div *ngIf="!errorOccurred">
    <ng-container *ngFor="let chart of configObject.charts; index as i">
        <app-line-chart *ngIf="chart.type == 'line'" [chartConfig]="chart"></app-line-chart>
        <app-bar-chart *ngIf="chart.type == 'bar'" [chartConfig]="chart"></app-bar-chart>
        <app-row-chart *ngIf="chart.type == 'row'" [chartConfig]="chart"></app-row-chart>
        <app-map-chart *ngIf="chart.type == 'map'" [chartConfig]="chart"></app-map-chart>
        <app-dropdown-chart *ngIf="chart.type == 'dropdown'" [chartConfig]="chart"></app-dropdown-chart>
        <app-number-chart *ngIf="chart.type == 'number'" [chartConfig]="chart"></app-number-chart>
        <app-pie-chart *ngIf="chart.type == 'pie'" [chartConfig]="chart"></app-pie-chart>
        <app-pivot-table-chart *ngIf="chart.type == 'pivot-table'" [chartConfig]="chart"></app-pivot-table-chart>
        <app-table-chart *ngIf="chart.type == 'table'" [chartConfig]="chart"></app-table-chart>
    </ng-container>
</div>
<div class="clearfix"></div>

