<div class="form-group typeahead" style="position: relative">
    <label
        [for]="config.id"
        [style.color]="!isFieldValid? '#a94442' : null"
        class="control-label"
    >{{config.label|translate}}
        <a *ngIf="config.addAction" class="btn btn-xs btn-primary" (click)="config.addAction()">
            <i class="icon icon-size-9 ion-plus-circled"></i>
            {{'messages.global.add_new'|translate}}
        </a>
    </label>
    <input
        #typeaheadInput
        (blur)="checkSelection($event)"
        (input)="filterOptions($event.target.value)"
        [disabled]="disabled"
        [id]="config.id"
        [name]="config.name"
        [ngClass]="{'is-invalid' : !isFieldValid}"
        [placeholder]="(config.placeholder || '') |translate"
        [type]="config.type"
        [value]="value"
        class="form-control"
    />

    <div
        class="typeahead-results"
        *ngIf="filteredOptions?.length"
    >
        <div *ngFor="let option of filteredOptions" (click)="onItemSelect(option)">{{option.stringified}}</div>
    </div>

    <ul *ngIf="config.multiple && selectedItems.length > 0" class="selected-items list-unstyled clearfix ">

        <li *ngFor="let item of selectedItems">

            <span>{{item.stringified}}</span>

            <a (click)="removeItem(item)" class="item-remove ml-1">
                <i class="ion-android-close"></i>
            </a>
        </li>
    </ul>

    <ng-content>
    </ng-content>
</div>
