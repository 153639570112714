import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {AdminSubscriptionsList} from '../../../../models/models';

@Component({
    selector: 'app-scheduled-deliveries',
    templateUrl: './scheduled-deliveries.component.html',
    styleUrls: ['./scheduled-deliveries.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ScheduledDeliveriesComponent implements OnInit {
    public subscriptions: AdminSubscriptionsList[] = [];
    public subscription: AdminSubscriptionsList;
    public showModal = false;

    constructor(private formBuilder: FormBuilder,
                private apiService: ApiService,
                public formLoadingServiceService: FormLoadingServiceService,
    ) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(1);
        this.loadList();
    }

    loadList() {
        this.apiService.getSubscriptions().subscribe(data => {
            this.subscriptions = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    edit(entity: AdminSubscriptionsList) {
        this.subscription = entity;
        this.showModal = true;
    }

    toggleActive(id: number) {
        this.apiService.toggleSubscriptionActive(id).subscribe(() => {
            this.loadList();
        });
    }

    delete(id: number) {
        this.apiService.deleteUsersSubscription(id).subscribe(() => {
            this.loadList();
        });
    }

    closeModal() {
        this.subscription = null;
        this.showModal = false;
        this.loadList();
    }
}
