import {Component, OnInit} from '@angular/core';
import {AdminWorkbookTemplatesList, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';

// noinspection DuplicatedCode
@Component({
    selector: 'app-workbook-templates',
    templateUrl: './workbook-templates.component.html',
    styleUrls: ['./workbook-templates.component.scss']
})
export class WorkbookTemplatesComponent implements OnInit {

    public inactive: boolean;
    public busy: boolean;
    public entities: AdminWorkbookTemplatesList[];
    public selectedIds = [];
    public selectedEntity: AdminWorkbookTemplatesList;
    public addEditModal: boolean;
    private csvFile = 'evo-workbook-templates.csv';
    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'name',
            name: 'messages.admin.workbook_templates_page.form.fields.name.label',
            type: 'string'
        },
        {
            id: 'description',
            name: 'messages.admin.workbook_templates_page.form.fields.description.label',
            type: 'string'
        },
        {
            id: 'is_cache_enabled',
            name: 'messages.admin.xls_page.table.th.cache',
            type: 'boolean'
        }
    ];

    public editEntity: AdminWorkbookTemplatesList | null;

    constructor(private apiService: ApiService, public paginationService: PaginationService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadList() {
        this.apiService.getWorkbookTemplates(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminWorkbookTemplatesList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity(entity: AdminWorkbookTemplatesList = null) {
        this.addEditModal = !this.addEditModal;
        this.editEntity = entity;
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const user of this.entities) {
                this.selectedIds.push(user.id);
                user.checked = true;
            }
        } else {
            for (const user of this.entities) {
                user.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getWorkbookTemplates(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity() {
        this.loadList();
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getWorkbookTemplates(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    closeModal(type: string) {
        this.editEntity = null;
        this.addEditModal = false;
        this.loadList();
    }

    exportAll() {
        this.apiService.exportWorkbookTemplates().subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    exportSelected() {
        this.apiService.exportSelectedWorkbookTemplates(this.selectedIds).subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    deleteSelected() {
        this.apiService.deleteSelectedWorkbookTemplates(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.apiService.getWorkbookTemplates(this.paginationService.pager).subscribe(response => {
                this.entities = response;
            });
        });
    }

    uploadFile(files: FileList) {
        const formData = new FormData();
        formData.append('file', files.item(0), files.item(0).name);
        this.apiService.uploadTemplateFile(formData).subscribe( response => {
            this.loadList();
        });
    }
}
