import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-dynamic-html',
    templateUrl: './dynamic-html.component.html',
    styleUrls: ['./dynamic-html.component.scss']
})
export class DynamicHtmlComponent implements OnInit {

    @Input() html;

    constructor() {
    }

    ngOnInit() {
    }

}
