export const environment = {
    production: true,
    apiURL: 'https://api.evouser.com',
    addinURL: 'https://addin.evouser.com',
    recaptchaKey: '6LeL5A0TAAAAABCBvTZquL5x0rfKriqRvqZa8VI2',
    ga: 'UA-58276748-2',
    addinEnvToken: '6d6e29c3-6c12-407e-a9e6-920ae3254399',
    webAppsDomain: 'https://dev-apps.evouser.com',
    maxUploadVideoSize: 300,
};
