import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AdminFoldersList, FormConfig, FormLists, ProvidedEntity} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

interface FolderForm {
    parent: any;
    displayName: any;
    displayName2: any;
    groups: any;
}

@Component({
    selector: 'app-folder-modal',
    templateUrl: './folder-modal.component.html',
    styleUrls: ['./folder-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class FolderModalComponent implements OnInit {
    @Output() addComponent = new EventEmitter<boolean>();
    @Input() folder: AdminFoldersList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<FolderForm>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'parent',
                label: 'messages.admin.folders_page.form.folder.fields.parent.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.parent.placeholder',
                id: 'folder_parent',
                type: 'typeahead',
                propertyPath: 'parent',
            },
            {
                name: 'displayName',
                label: 'messages.admin.folders_page.form.folder.fields.display_name.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.display_name.label',
                id: 'folder_name',
                type: 'text',
                validators: [Validators.required],
                propertyPath: 'name'
            },
            {
                name: 'displayName2',
                label: 'messages.admin.folders_page.form.folder.fields.display_name2.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.display_name2.label',
                id: 'folder_name_2',
                type: 'text',
                propertyPath: 'display_name'
            },
            {
                name: 'groups',
                label: 'messages.admin.folders_page.form.folder.fields.groups.label',
                placeholder: '',
                id: 'folder_group',
                type: 'typeahead',
                multiple: true
            },
            {
                name: 'global',
                label: 'messages.admin.pages_page.form.page.fields.global.label',
                placeholder: '',
                id: 'global',
                type: 'checkbox'
            },
            {
                name: 'global_all_folders',
                label: 'messages.admin.pages_page.form.page.fields.global_all_folders.label',
                placeholder: '',
                id: 'global_all_folders',
                type: 'checkbox'
            },
            {
                name: 'global_all_pages',
                label: 'messages.admin.pages_page.form.page.fields.global_all_pages.label',
                placeholder: '',
                id: 'global_all_pages',
                type: 'checkbox'
            }
        ],
    };

    lists: FormLists = {};

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(2);

        this.entityProvider.getFolders().subscribe((data: ProvidedEntity[]) => {
            this.lists.parent = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getGroups().subscribe((data: ProvidedEntity[]) => {
            this.lists.groups = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addFolder(data: FolderForm) {

        const request = this.folder
            ? this.apiService.updateFolder(this.folder.id, data)
            : this.apiService.createFolder(data);

        request.subscribe((response) => {
            this.addComponent.emit(true);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }
}
