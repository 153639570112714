import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FrontendModule} from './modules/frontend/frontend.module';
import {AdministrationModule} from './modules/administration/administration.module';
import {ToastrModule} from 'ngx-toastr';
import {GlobalErrorHandler} from './services/global-error-handler';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ReactiveFormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token-interceptor.service';
import {LoginComponent} from './pages/global/login/login.component';
import {RoleAdminGuard} from './guards/role-admin.guard';
import {RoleUserGuard} from './guards/role-user.guard';
import {RegisterComponent} from './pages/global/register/register.component';
import {PasswordResetComponent} from './pages/global/password-reset/password-reset.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {PaginationInterceptor} from './interceptors/pagination-interceptor.service';
import {ErrorInterceptor} from './interceptors/error-interceptor.service';
import {NotPermissionsComponent} from './pages/common/error-pages/not-permissions/not-permissions.component';
import {NgPipesModule} from 'ngx-pipes';
import {CookieService} from 'ngx-cookie-service';
import {CommonAppModule} from './common/common-app.module';
import {TranslationsModule} from './modules/translations/translations.module';
import {IsSuperAdminOrCompanyOwnerGuard} from './guards/is-super-admin-or-company-owner.guard';
import {IsGrantedGuard} from './guards/is-granted.guard';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';
import {RouteChangeInterceptor} from './interceptors/route-change-interceptor.service';
import {PasswordResetFormComponent} from './pages/global/password-reset-form/password-reset-form.component';
import {DashboardChartsRendererModule} from './modules/dashboard-charts-renderer/dashboard-charts-renderer.module';
import {CommonModalsModule} from './modules/common-modals/common-modals.module';
import {SortablejsModule} from 'ngx-sortablejs';
import {ConfirmEmailComponent} from './pages/global/confirm-email/confirm-email.component';
import {OrgChartComponent} from './pages/administration/org-chart/org-chart.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {PrivacyPolicyComponent} from './pages/global/privacy-policy/privacy-policy.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        PasswordResetComponent,
        NotPermissionsComponent,
        PasswordResetFormComponent,
        ConfirmEmailComponent,
        OrgChartComponent,
        PrivacyPolicyComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        LoadingBarHttpClientModule,
        AppRoutingModule,
        FrontendModule,
        AdministrationModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            progressBar: true
        }),
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        NgPipesModule,
        CommonAppModule,
        TranslationsModule,
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        DashboardChartsRendererModule,
        CommonModalsModule,
        SortablejsModule.forRoot({}),
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PaginationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RouteChangeInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        RoleAdminGuard,
        RoleUserGuard,
        CookieService,
        IsSuperAdminOrCompanyOwnerGuard,
        IsGrantedGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
