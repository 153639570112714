import {ElementRef, Injectable} from '@angular/core';
import html2canvas from 'html2canvas';
import {downloadContent} from '../helpers/DownloadContent';

@Injectable({
    providedIn: 'root'
})
export class ScreenshotService {
    private screenshotContent: ElementRef;

    constructor() {
    }

    getScreenshotContent(): ElementRef {
        return this.screenshotContent;
    }

    setScreenshotContent(value: ElementRef) {
        this.screenshotContent = value;
    }

    downloadImage(filename: string, type: string) {
        if (this.screenshotContent === undefined) {
            throw new Error('Screenshot main content is missing.');
        }

        this.setInlineStyles(this.screenshotContent.nativeElement);
        const hideElements = Array.from(
            document.querySelectorAll('app-sidebar-left, app-navbar-top, app-sidebar-right, app-sidebar-left-panel-subscribe, app-comments, .edit-content')
        );

        for (const el of hideElements) {
            el.classList.add('d-none');
        }

        const parentEl = document.querySelector('div.page-center-column') as HTMLElement;
        parentEl.style.marginLeft = '0';
        parentEl.style.maxWidth = 'none';

        html2canvas(document.querySelector('body'), {
            width: parentEl.scrollWidth,
            windowWidth: parentEl.scrollWidth,
            height: parentEl.scrollHeight,
            windowHeight: parentEl.scrollHeight,
            scale: 3
        }).then(canvas => {
            downloadContent(canvas.toDataURL(`image/${type}`), `${filename}.${type}`);
            for (const el of hideElements) {
                el.classList.remove('d-none');
            }

            parentEl.style.marginLeft = null;
            parentEl.style.maxWidth = null;
        }).catch(e => {
            for (const el of hideElements) {
                el.classList.remove('d-none');
            }
            parentEl.style.marginLeft = null;
            parentEl.style.maxWidth = null;
        });
    }

    downloadImageOfContent(el, filename: string, type: string, hideElements = [], cb: (v: string, w: number, h: number) => void = null) {
        const parentEl = document.querySelector('div.page-center-column');

        for (const hEl of hideElements) {
            hEl.classList.add('d-none');
        }

        html2canvas(el, {
            width: parentEl.scrollWidth,
            windowWidth: parentEl.scrollWidth + 150,
            height: parentEl.scrollHeight,
            windowHeight: parentEl.scrollHeight + 150,
            scale: 3
        }).then(canvas => {
            if (cb) {
                cb(canvas.toDataURL(`image/${type}`), parentEl.scrollWidth, parentEl.scrollHeight);
            } else {
                downloadContent(canvas.toDataURL(`image/${type}`), `${filename}.${type}`);
            }

            for (const hEl of hideElements) {
                hEl.classList.remove('d-none');
            }
        }).catch(e => {
            for (const hEl of hideElements) {
                hEl.classList.remove('d-none');
            }
        });
    }

    private setInlineStyles(targetElem: HTMLElement) {
        const svgElems = Array.from(targetElem.getElementsByTagName('svg'));

        for (const svgElement of svgElems) {
            this.recurseElementChildren(svgElement);
        }
    }

    private recurseElementChildren(node: SVGSVGElement | HTMLElement) {
        const transformProperties = [
            'fill',
            'color',
            'font-size',
            'stroke',
            'font'
        ];

        if (!node.style) {
            return;
        }

        const styles = getComputedStyle(node);

        for (const transformProperty of transformProperties) {
            node.style[transformProperty] = styles[transformProperty];
        }

        for (const child of Array.from(node.childNodes)) {
            this.recurseElementChildren(child as SVGSVGElement);
        }
    }
}
