import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class IframeComponent {

    @Input() url;
    @Input() id;

    constructor() {
    }

    onResize() {
        document.getElementById(`iframe-html-${this.id}`).style.height = (document.body.scrollHeight - 150).toString() + 'px';
    }

    onLoad() {
        document.getElementById(`loading-${this.id}`).style.display = 'none';
        const googleIframe = document.getElementById(`iframe-html-google-${this.id}`);
        const iframe = document.getElementById(`iframe-html-${this.id}`);
        if (googleIframe !== null) {
            googleIframe.style.visibility = 'visible';
            googleIframe.style.height = (document.body.scrollHeight - 150).toString() + 'px';
        } else if (iframe !== null) {
            iframe.style.display = 'block';
            iframe.style.height = (document.body.scrollHeight - 150).toString() + 'px';
        }
    }
}
