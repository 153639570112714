import {Injectable} from '@angular/core';
import {InfoRow, ProfileData} from '../models/models';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileSecurityService {
    private profile: ProfileData = null;
    public isUserSubordinate = false;
    public isProfileOwner = false;
    public canSeeWorkingHours = false;
    public canEditDocuments = false;

    constructor(private auth: AuthService) {
    }

    init(profile: ProfileData) {
        this.profile = profile;
        this.isUserSubordinate = profile.isUserSubordinate;

        if (!this.profile || !this.profile.id) {
            this.isProfileOwner = false;
        } else {
            this.isProfileOwner = this.auth.getLoggedUser().id.toString() === this.profile.id.toString();
        }

        this.canSeeWorkingHours = this.isUserSubordinate || this.isProfileOwner;
        this.canEditDocuments = this.isUserSubordinate || this.isProfileOwner;
    }

    canSeeRow(rows: InfoRow[]) {
        if (this.isUserSubordinate || this.isProfileOwner) {
            return true;
        }

        for (const row of rows) {
            if (this.canSee(row, this.profile[row.key])) {
                return true;
            }
        }

        return false;
    }

    canSee(item: InfoRow, value: any) {
        if (!value || (Array.isArray(value) && !value.length)) {
            return false;
        }

        if (item.forManagers && !this.isUserSubordinate) {
            return false;
        }

        if (this.isUserSubordinate || this.isProfileOwner) {
            return true;
        }

        return !item.private;
    }

    canEditRow(rows: InfoRow[]) {
        for (const row of rows) {
            if (this.canEdit(row)) {
                return true;
            }
        }

        return false;
    }

    canEdit(item: InfoRow) {
        if (item.calculated) {
            return false;
        }

        if (this.isUserSubordinate) {
            return true;
        }

        if (item.forManagers) {
            return false;
        }

        return this.isProfileOwner && item.editable;
    }

    getFormFields(rows): InfoRow[] {
        return rows.filter(item => this.canEdit(item));
    }

    isCompanyOwner() {
        return this.auth.isSuperAdmin() || this.auth.isCompanyOwner();
    }
}
