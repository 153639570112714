import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AdminFoldersList, AdminPagesList} from '../../models/models';

@Injectable({
    providedIn: 'root'
})
export class SidebarLeftService {

    private folder$ = new Subject<AdminFoldersList>();
    private page$ = new Subject<AdminPagesList>();

    constructor() {
    }

    get pageSubject() {
        return this.page$;
    }

    get folderSubject() {
        return this.folder$;
    }

    nextPage(page: AdminPagesList) {
        this.page$.next(page);
    }

    nextFolder(value: AdminFoldersList) {
        this.folder$.next(value);
    }
}
