<div class="text-center" *ngIf="loading">
    <img src="../../../../../assets/img/loading.gif" alt="Loading"/>
    <p>Loading...</p>
</div>

<ng-template #control let-form="form" let-errors="errors" let-field="field" let-options="options">
    <app-input-renderer
        [form]="form"
        [field]="field"
        [options]="options"
        [isFieldValid]="((!form.dirty && !form.touched) || !form.errors) && !errors?.length"
    >
    </app-input-renderer>
    <app-input-errors [errors]="errors" [control]="form">
    </app-input-errors>
</ng-template>

<form
    [formGroup]="form"
    (ngSubmit)="submit()"
    autocomplete="off"
    class="form-style-bordered-inputs"
    name="myForm"
    role="form"
    *ngIf="!loading"
>
    <app-input-errors [errors]="errors['_global']">
    </app-input-errors>
    <div *ngFor="let field of formConfig.fields" class="form-group">
        <ng-container *ngIf="!field.hidden">
            <ng-container *ngIf="field.type !== 'collection'">
                <ng-container
                    *ngTemplateOutlet="control; context: {
                         field: field,
                         form: form.controls[field.name],
                         errors: errors[field.name],
                         options: (lists | controlListsValue: field)
                     }"
                >
                </ng-container>
            </ng-container>
            <app-collection
                *ngIf="field.type === 'collection'"
                [form]="form | controlGetForm: field"
                [config]="field"
                [lists]="lists | controlListsValue: field"
                [value]="value | controlValue: field"
                [responseErrors]="errors"
                [inputTemplate]="control"
            >
            </app-collection>
        </ng-container>
    </div>

    <ng-content>
    </ng-content>

    <ng-container *ngIf="!hideButtons">
        <img
            alt="Loading"
            *ngIf="submitted"
            class="pull-left"
            src="../../../../../assets/img/loading.gif"
            style="height: 30px;"
        />

        <button class="btn btn-sm btn-primary" type="submit">
            {{'messages.global.ok'|translate}}
        </button>
        &nbsp;
        <button (click)="close()" class="btn btn-sm btn-default" type="button">
            {{'messages.global.cancel'|translate}}
        </button>
    </ng-container>
</form>
