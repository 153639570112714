<app-form-loading [loading]="loading">
</app-form-loading>

<ng-container *ngIf="course">
    <div class="course-container container-fluid mb-4 position-relative">
        <div class="row">
            <div class="course" [ngClass]="{'col-8': windowWidth > 768, 'col-12': windowWidth <= 768}">
                <h3>{{course.name}}</h3>

                <div class="rate mb-2">
                    <span style="color: #ffbf00">{{course?.rating?.rating || ''}}</span>
                    <rating
                        style="color: #ffbf00"
                        [readonly]="true"
                        [ngModel]="course?.rating?.rating || 0"
                        [max]="5"
                    ></rating>
                    <span class="mr-3">({{course?.rating?.votes || 0}} ratings)</span>
                    <span
                        *ngIf="course?.students?.length"
                    > <i class="ion-android-person mr-2"></i> {{course.students.length}} students</span>
                </div>
                <div *ngIf="course?.custom_fields?.creator">
                    Created By: {{course.custom_fields.creator}}
                </div>
                <!--                <div class="teachers" *ngIf="course?.teachers?.length">-->
                <!--                    Teacher(s): {{mapUsers(course.teachers)}}-->
                <!--                </div>-->
            </div>
        </div>
        <img
            src="/img/enrolled-badge.png"
            alt="enrolled"
            class="enrolled-badge"
            *ngIf="isEnrolled()"
        >
    </div>
    <div class="course-container mb-4" style="padding: 15px;">
        <h4>What you'll learn</h4>
        <div
            class="summary mb-2"
            [innerHTML]="sanitized.bypassSecurityTrustHtml(course.introduction || course.summary)"
        >
        </div>
        <div
            class="mt-4 text-center"
            *ngIf="course?.enroll_methods && course?.enroll_methods.includes('self') && !isEnrolled()"
        >
            <a
                class="btn btn-primary btn-square btn-sm font-weight-bold"
                [href]="'https://university.evouser.com/enrol/index.php?&auto_enroll&id=' + course.id"
            >Enroll me</a>
        </div>
        <div class="mt-4 text-center" *ngIf="isEnrolled()">
            <a
                class="btn btn-primary btn-square btn-sm font-weight-bold"
                [href]="'https://university.evouser.com/course/view.php?id=' + course.id"
            >Continue</a>
        </div>
    </div>
</ng-container>
