import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageComment} from '../../../../../models/models';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';
import {CommentsService} from '../../../../../services/comments.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

    @Input() comment: PageComment;
    @Input() level = 1;
    @Output() deleted = new EventEmitter<PageComment>();
    @Output() updated = new EventEmitter<PageComment>();
    reply = false;
    edit = false;
    baseUrl = '';
    body: SafeHtml = '';
    public location: Location;

    constructor(
        private api: ApiService,
        public auth: AuthService,
        private commentsService: CommentsService,
        private sanitized: DomSanitizer,
        private toastrService: ToastrService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.baseUrl = this.api.apiURL;
        this.location = window.location;
        this.body = this.processText(this.comment.processed_body);
    }

    handleClose(data = null) {
        if (!data) {
            this.reply = false;
            this.edit = false;

            return;
        }

        if (!this.comment.children) {
            this.comment.children = [];
        }

        if (this.reply) {
            this.comment.children.push(data);
        }

        if (this.edit) {
            this.updated.emit(data);
        }

        this.reply = false;
        this.edit = false;
        this.commentsService.replying = false;
    }

    get createdAt() {
        return new Date(this.comment.created_at);
    }

    deleteComment() {
        this.api.deleteComment(this.comment.id).subscribe(() => {
            this.deleted.emit(this.comment);
        });
    }

    handleChildUpdate(data: PageComment, index: number) {
        this.comment.children.splice(index, 1, data);
    }

    handleChildDelete(child: PageComment) {
        if (!Array.isArray(this.comment.children)) {
            return;
        }

        this.comment.children = this.comment.children.filter(item => item !== child);
    }

    handleEditClick(e) {
        e.preventDefault();

        this.commentsService.replying = true;
        this.edit = true;
    }

    handleReplyClick(e) {
        e.preventDefault();

        this.commentsService.replying = true;
        this.reply = true;
    }

    processText(text) {
        return this.sanitized.bypassSecurityTrustHtml(text);
    }

    copyUrl(e: Event, comment: PageComment) {
        e.preventDefault();
        e.stopPropagation();

        const el = document.createElement('textarea');
        el.value = location.origin + location.pathname + '#comment_' + comment.id;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.toastrService.success('Url successfully copied!');
    }

    handleMention(e: Event) {
        e.stopPropagation();
        const target = e.target as Element;

        if (!target.classList.contains('mention')) {
            return;
        }

        // @ts-ignore
        const userId = target.dataset.uid;
        this.router.navigateByUrl('/app/team/' + userId);
    }
}
