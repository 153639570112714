<div class="container-fluid cover-bg-container login-page">
    <div class="panel-centered-wrapper">
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="form-style-labels-inside-inputs panel-centered">

            <a class="no-pointer">
                <img alt="Tools group" class="panel-centered-logo" src="./../../../../assets/img/tg-logo.png">
            </a>

            <div *ngIf="loading" class="text-center"><img alt="loading" src="./../../../../assets/img/loading.gif">
            </div>

            <div *ngIf="!loading">
                <div *ngIf="getGlobalErrors().length" class="alert alert-danger">
                    <button class="close" data-dismiss="alert" type="button">
                        <i class="ace-icon fa fa-times"></i>
                    </button>
                    <strong>
                        <i class="ace-icon fa fa-times"></i>
                        {{ 'messages.login_page.error'|translate }}:
                    </strong>
                    <app-display-input-errors [errorsStrings]="getGlobalErrors()"></app-display-input-errors>

                    <div class="text-center" *ngIf="showResetButton">
                        <div class="btn btn-sm btn-primary btn-square w-100" (click)="resendVerification()">
                            {{'messages.login_page.resend_email'|translate}}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label required" for="login_form_username">
                        {{ 'messages.global.email'|translate }}
                    </label>

                    <input
                        class="form-control"
                        formControlName="username"
                        id="login_form_username"
                        name="username"
                        required="required"
                        type="text"
                    >
                    <div class="focus-highlight"></div>
                </div>
                <app-display-input-errors [errorsStrings]="getErrors('username')"></app-display-input-errors>

                <div class="form-group">
                    <label class="control-label required" for="login_form_password">
                        {{ 'messages.global.password'|translate }}
                    </label>

                    <input
                        class="form-control"
                        formControlName="password"
                        id="login_form_password"
                        name="password"
                        required="required"
                        type="password"
                    >
                    <div class="focus-highlight"></div>
                </div>
                <app-display-input-errors [errorsStrings]="getErrors('password')"></app-display-input-errors>

                <br/>

                <div *ngIf="needCaptcha" class="recaptcha-container margin-bottom-10">
                    <re-captcha [siteKey]="recaptchaKey" formControlName="captcha"></re-captcha>
                </div>
                <div>
                    <app-display-input-errors [errorsStrings]="getErrors('captcha')"></app-display-input-errors>
                </div>

                <a [routerLink]="['/password-reset']" class="forgot-password-link">
                    {{'messages.login_page.forgot_it'|translate}}
                </a>

                <div class="checkbox">
                    <input checked id="remember_me" name="_remember_me" type="checkbox" formControlName="rememberMe"/>
                    <label for="remember_me">
                        <span><span></span></span>
                        {{'messages.login_page.remember_me'|translate}}
                    </label>
                </div>

                <div class="panel-centered-actions">
                    <button class="btn btn-lg btn-primary w-100" type="submit">
                        {{'messages.login_page.sign_in'|translate}}
                    </button>
                    <br>
                    <a [routerLink]="['/register']">{{'messages.login_page.request_to_join'|translate}}</a>
                    <br>
                    <br>
                    <a [routerLink]="['/privacy-policy']">Privacy policy</a>
                </div>
            </div>
        </form>
    </div>
</div>
