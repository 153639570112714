import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WcResourcesInjectorService} from '../../../../../services/wc-resources-injector.service';
import {WebAppSrc} from '../../../../../models/models';
import {environment} from '../../../../../../environments/environment';

@Component({
    selector: 'app-web-component',
    templateUrl: './web-component.component.html',
    styleUrls: ['./web-component.component.scss'],
})
export class WebComponentComponent implements OnInit, OnDestroy {

    public loaded = false;
    public failedLoad = false;
    private src: WebAppSrc = null;

    @Input() content: string;
    @ViewChild('component_container') container;

    constructor(private wcResourcesInjector: WcResourcesInjectorService) {
    }

    ngOnInit(): void {
        this.src = JSON.parse(this.content) as WebAppSrc;
        const {js, css} = this.src;
        /** To avoid BC - attribute added later and can be undefined */
        let attribute = this.src.attribute;
        if (attribute === undefined || attribute === null) {
            attribute = [];
        }

        if (!js?.length) {
            throw Error('No scripts found!');
        }

        const prArray = [];

        for (const {url} of js) {
            prArray.push(this.wcResourcesInjector.addJs(url));
        }

        Promise.all(prArray).then(() => {
            this.loaded = true;

            const el = document.createElement(this.src.tag);
            for (const {value} of attribute) {
                const attr = value.split('=');
                el.setAttribute(attr[0], attr[1]);
            }
            this.container.nativeElement.appendChild(el);
        }).catch(() => {
            this.loaded = false;
            this.failedLoad = true;
        });


        if (!css?.length) {
            return;
        }

        for (const {url} of css) {
            this.wcResourcesInjector.addCss(url);
        }
    }

    ngOnDestroy(): void {
        const destroyEvent = new CustomEvent('destroy-web-app');
        window.dispatchEvent(destroyEvent);
    }

}
