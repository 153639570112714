import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Content, FrontendPage} from '../../../models/models';
import {Subscription} from 'rxjs';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {DashboardService} from '../../../services/dashboard.service';
import {ReportsService} from '../../../services/reports.service';
import {ScreenshotService} from '../../../services/screenshot.service';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {LoadingService} from '../../../services/loading.service';

@Component({
    selector: 'app-public-pages',
    templateUrl: './public-pages.component.html',
    styleUrls: ['./public-pages.component.scss']
})
export class PublicPagesComponent implements OnInit, AfterViewChecked, OnDestroy {

    @ViewChild('dataCount') dataCount: ElementRef;
    public reportIsLoading = true;
    public reportData: FrontendPage;
    public title: string;
    public subtitle: string;
    public width = '100%';
    public height = 'auto';
    public reportSelected = false;
    private subscriptions: Subscription[] = [];
    public editContent: Content = null;
    public shareId: string;

    public hasHandbooks = false;

    constructor(private layoutConfigService: LayoutConfigService,
                private dashboardService: DashboardService,
                public reportsService: ReportsService,
                private screenshotService: ScreenshotService,
                private apiService: ApiService,
                private activatedRoute: ActivatedRoute,
                private loadingService: LoadingService,
                public auth: AuthService
    ) {
    }

    ngAfterViewChecked(): void {
        if (this.dataCount) {
            this.dashboardService.setDataCountElement(this.dataCount.nativeElement);
        }
    }

    async ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.shareId = params.shareId;
        });
        this.reportsService.clearSelectedReport();
        this.loadingService.clearEvents();
        this.fetchReport();
    }

    getPublicReport(shareId: string) {
        this.reportIsLoading = true;
        this.subscriptions.push(this.apiService.getPublicReport(shareId).subscribe(response => {
            this.reportData = response;
            this.reportData.contents = response.contents.filter(item => !item.is_deleted);
            this.reportsService.setSelectedReport(response);
            this.reportIsLoading = false;
            this.title = this.reportData.title;
            this.subtitle = this.reportData.subtitle;

            this.hasHandbooks = response.contents.filter(content => content.type === 'handbook').length > 0;
        }, error => {
            this.reportIsLoading = false;
        }));
    }

    fetchReport() {
        this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
            if (params.shareId) {
                this.reportSelected = true;
                this.getPublicReport(`${params.shareId}`);
            } else {
                this.reportSelected = false;
                this.reportIsLoading = false;
            }
        }));
    }

    resetAll() {
        this.dashboardService.resetAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });

        this.reportsService.clearSelectedReport();
    }

    handleModalClose() {
        this.editContent = null;
        this.fetchReport();
        this.reportsService.reportsSubject.next();
    }

    setEditContent(content: Content) {
        this.editContent = {...content, pages: [this.reportData]};
    }
}
