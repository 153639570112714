<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
        >{{content[field.id] !== null ? content[field.id] : ' -- '}}</p>
        <span *ngIf="field.type === 'list' && !content.remote_name && content.pages.length > 0">
            <!-- @todo What is that?           -->
            <!--            <span>PreprodFolder/Diagnostic</span>-->
            <span *ngFor="let page of content.pages">/{{page.name}}</span>
        </span>
    </div>
    <div class="text-right">
        <a
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_manage"
            (click)="editContent()"
            *ngIf="isSupportedContentType(content)"
        >
            <i class="icon icon-size-9 ion-compose"></i>
            {{'messages.admin.contents_page.manage'|translate}}
        </a>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_toggle_{{content.id}}"
            (click)="toggleActiveContent()"
        >
            <i class="icon icon-size-9 ion-power"></i>
            {{(content.is_deleted ? 'messages.admin.contents_page.activate' : 'messages.admin.contents_page.deactivate')|translate}}

        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_clear_{{content.id}}"
            (click)="clearContentCache()"
        >
            <i class="icon icon-size-9 ion-close-round"></i>
            {{'messages.admin.contents_page.clear_cache'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="content_copy_{{content.id}}"
            (click)="copyContent()"
            *ngIf="isSupportedContentType(content)"
        >
            <i class="icon icon-size-9 ion-ios-copy-outline"></i>
            {{'messages.admin.contents_page.copy_content'|translate}}
        </button>
        <app-modal-confirm>
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="content_delete_{{content.id}}"
                (click)="deleteContent()"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
