import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutConfigService} from '../../services/layout-config.service';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../services/api.service';
import {UserModel, UsersModel} from '../../models/models';
import {LoadingService} from '../../services/loading.service';
import {LocaleService} from '../../services/locale.service';
import {ROLE_CONTENT_MANAGER, ROLE_SUPER_ADMIN} from '../../services/constants';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-navbar-top',
    templateUrl: './navbar-top.component.html',
    styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnInit, OnDestroy {
    users: UsersModel[];
    user: UserModel;
    filteredUsers: UsersModel[] = [];
    @Input() hideDetails = false;
    @Input() publicPage = false;
    @ViewChild('impersonate') impersonateInput;

    private messagesUpdateInterval: any;

    public innerWidth: number;

    constructor(public router: Router,
                public layoutConfigService: LayoutConfigService,
                public authService: AuthService,
                public apiService: ApiService,
                private loadingService: LoadingService,
                private localeService: LocaleService,
                private toastService: ToastrService,
                private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        if (this.hideDetails !== true) {
            this.loadingService.addEvent('getUserDetails');
            this.apiService.getUserDetails().subscribe(response => {
                this.user = response;
                this.layoutConfigService.setMenuLabels(response.menu_labels);
                this.layoutConfigService.setEmailNotifications(response.email_notifications);
                this.authService.setLoggedUser(response);
                this.loadingService.removeEvent('getUserDetails');
                this.localeService.syncLocale();

                if (this.authService.canImpersonate() && !this.authService.isImpersonated()) {
                    this.loadingService.addEvent('getImpersonateList');
                    this.apiService.getImpersonateList().subscribe(usersResponse => {
                        this.users = usersResponse;
                        this.filteredUsers = [...usersResponse];
                        this.loadingService.removeEvent('getImpersonateList');
                    });
                }

                this.switchTheme(response.theme === 'dark');
            });

            this.loadingService.addEvent('getNotifications');
            this.apiService.getNotifications().subscribe(response => {
                this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
                this.loadingService.removeEvent('getNotifications');
            });

            this.messagesUpdateInterval = setInterval(this.updateMessages, 60000);
            this.updateMessages();
        }
    }

    isMobileView(): boolean {
        return this.innerWidth <= 1080;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    impersonateUser(id: number) {
        this.apiService.impersonateUser(id).subscribe(response => {
            this.authService.setImpersonatedUser(response);
            this.toastService.success(this.translateService.instant('messages.global.impersonate_success'), null, {
                timeOut: 1000,
            }).onHidden.subscribe(() => {
                window.location.href = '/app/reports';
            });
        });
    }

    exitImpersonate() {
        this.authService.exitImpersonation();
        this.toastService.success(this.translateService.instant('messages.global.exit_impersonate_success'), null, {
            timeOut: 1000,
        }).onHidden.subscribe(() => {
            window.location.href = '/app/reports';
        });
    }

    updateMessages = () => {
        this.apiService.getUserMessages().subscribe(response => {
            this.layoutConfigService.setNewMessages(response.new_messages);
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.messagesUpdateInterval);
    }

    redirectTo() {
        if (this.authService.isGranted(ROLE_SUPER_ADMIN)) {
            this.router.navigate(['/admin/users']);
            return;
        }

        if (this.authService.isGranted(ROLE_CONTENT_MANAGER)) {
            this.router.navigate(['/admin/companies']);
            return;
        }

        this.router.navigate(['/admin/users']);
    }

    switchTheme(darkTheme = false) {
        const el = document.querySelector('body');
        // Disable temporary dark theme - https://gitlab.evouser.com/portal/evo-frontend-issues/-/issues/2042
        // if (darkTheme) {
        //     if (!el.classList.contains('theme-dark')) {
        //         el.classList.add('theme-dark');
        //     }
        // } else {
        //     el.classList.remove('theme-dark');
        // }
    }

    searchUser(value: string) {
        if (!value) {
            this.filteredUsers = [...this.users];
            return;
        }

        const searchValue = value.toLowerCase().trim();

        this.filteredUsers = this.users
            .filter(item => item.stringified.toLowerCase().includes(searchValue) || item.email.toLocaleLowerCase().includes(searchValue));
    }

    focusImpersonate() {
        setTimeout(() => {
            this.impersonateInput.nativeElement.focus();
        });
    }
}
