import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AdminWorkbookTemplatesList} from '../../../../models/models';

@Component({
    selector: 'app-workbook-template-table-tr-info',
    templateUrl: './workbook-template-table-tr-info.component.html',
    styleUrls: ['./workbook-template-table-tr-info.component.scss']
})
export class WorkbookTemplateTableTrInfoComponent implements OnInit {

    @ViewChild('updateWorkbookTemplateInput') updateWorkbookTemplateInput;
    @Output() openModal = new EventEmitter();
    @Input() workbookTemplate: AdminWorkbookTemplatesList;
    @Output() updateWorkbookTemplate = new EventEmitter();

    constructor(public apiService: ApiService) {
    }

    ngOnInit() {
    }

    updateWorkbookTemplateAction() {
        this.updateWorkbookTemplateInput.nativeElement.click();
    }

    onFileSelected(files: FileList) {
        const formData = new FormData();
        formData.append('file', files.item(0), files.item(0).name);
        this.apiService.updateTemplateFile(formData, this.workbookTemplate.id).subscribe(response => {
            this.updateWorkbookTemplate.emit();
        });
    }

    deleteWorkbook() {
        this.apiService.deleteWorkbookTemplate(this.workbookTemplate.id).subscribe(response => {
            this.updateWorkbookTemplate.emit();
        });
    }

    download() {
        this.apiService.downloadWorkbookTemplate(this.workbookTemplate.id).subscribe((response: { size: number, type: string }) => {
            this.apiService.downloadFileClientSide(response, this.workbookTemplate.name, response.type);
            this.updateWorkbookTemplate.emit();
        });
    }

    editWorkbookTemplate() {
        return this.openModal.emit();
    }

    toggleCache() {
        this.apiService.toggleWorkbookTemplateCache(this.workbookTemplate.id).subscribe(() => {
            this.updateWorkbookTemplate.emit();
        });
    }
}
