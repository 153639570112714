<div class="form-group">
    <label
            class="control-label"
            [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
            [for]="field.id"
    >{{field.label|translate}}</label>
    <input
            [type]="field.type"
            [id]="field.id"
            [name]="field.name"
            [placeholder]="field.placeholder | translate"
            class="form-control"
            [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
            [value]="value"
            (change)="onChange($event.target.value)"
    >
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>

