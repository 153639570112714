import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FieldsService} from '../../services/fields.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DisplayError} from '../../models/models';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [
        FieldsService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true
        }
    ]
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
    public field;
    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() isFieldValid: boolean;
    private currentValue;
    currentErrors: string[];

    constructor(public fieldService: FieldsService) {
    }

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: number | null) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.field = this.fieldService.getUserField(this.fieldName, this.formName);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
}
