import * as d3 from 'd3';
import * as dc from 'dc';
import {Injectable} from '@angular/core';
import {DashboardLocaleService} from './dashboard-locale.service';

@Injectable({
    providedIn: 'root'
})
export class ColumnTypesService {
    private types = d3.map([], (d: any) => {
        return d.name;
    });

    constructor(private locale: DashboardLocaleService) {
        const self = this;
        this.types.set('string', {
            parseFn: (value: any) => {
                return value;
            },
            tickFormat: (d: any) => {
                return d;
            },
            labelFormat: (d: any) => {
                return d.key;
            },
            format: d3.format,
            scale: d3.scaleBand(),
            xUnits: dc.units.ordinal,
            convertViaCtx: (value: any, ctx: any) => {
                if (!ctx.numberLocale) {
                    ctx.numberLocale = 'en-us';
                }
                return value;
            }
        });
        this.types.set('int', {
            tickFormat: (d: any) => {
                return d;
            },
            labelFormat: (d: any) => {
                return d.key;
            },
            format: d3.format,
            scale: d3.scaleLinear(),
            xUnits: dc.units.integers,
            convertViaCtx: (value: any, ctx: any) => {
                if (!ctx.numberLocale) {
                    ctx.numberLocale = 'en-us';
                }
                const localized = this.locale.applyLocale(value, ctx.numberLocale);
                const int = parseInt(localized, 10);
                if (isNaN(int)) {
                    return 0;
                }

                return int;
            }
        });
        this.types.set('float', {
            tickFormat: (d: any) => {
                return d;
            },
            labelFormat: (d: any) => {
                return d.key;
            },
            format: d3.format,
            scale: d3.scaleLinear(),
            xUnits: dc.units.fp.precision(0.1),
            convertViaCtx: (value: any, ctx: any) => {
                if (!ctx.numberLocale) {
                    ctx.numberLocale = 'en-us';
                }
                value = value.replace('%', ''); // TODO remove
                const localized = this.locale.applyLocale(value, ctx.numberLocale);
                const float = parseFloat(localized);
                if (isNaN(float)) {
                    return 0;
                }

                return float;
            }
        });
        this.types.set('percent', {
            tickFormat: (d: any) => {
                return d + '%';
            },
            labelFormat: (d: any) => {
                return d.key + '%';
            },
            format: d3.format,
            scale: d3.scaleLinear(),
            xUnits: dc.units.fp.precision(0.01),
            convertViaCtx(value: any, ctx: any) {
                value = value.replace('%', '');
                if (!ctx.numberLocale) {
                    ctx.numberLocale = 'en-us';
                }
                value = value.replace('%', ''); // TODO remove
                const localized = self.locale.applyLocale(value, ctx.numberLocale);
                const float = parseFloat(localized);
                if (isNaN(float)) {
                    return 0;
                }

                return float;
            }
        });
    }

    public has(name: string): boolean {
        return this.types.has(name);
    }

    public get(name: string) {
        return this.types.get(name);
    }

    public convertValueViaContext(value: any, ctx: any) {
        if (!this.types.has(ctx.type)) {
            if (ctx.type === 'date') {
                ctx.type = 'string';
            } else {
                throw new Error('Unknown data type: \'' + ctx.type + '\'');
            }

        }
        return this.types.get(ctx.type).convertViaCtx(value, ctx);
    }
}
