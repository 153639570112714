<div class="page">
    <div class="page-title">
        <div class="row">
            <div class="col-12 col-sm-1 d-flex align-items-center">
                <h1><i class="icon ion-person-stalker color-light-green"></i> {{'messages.global.team'|translate}} </h1>
            </div>
            <div class="col-12 col-sm-11">
                <div class="action-buttons form-style-bordered-inputs" style="text-align: left">
                    <select
                        (change)="updateCompany($event.target.value)"
                        [(ngModel)]="currentCompany"
                        *ngIf="auth.isSuperAdmin() && companies && companies.length"
                        id="select_company_filter"
                    >
                        <option value="">-- {{'messages.admin.accordion.company'|translate}} --</option>
                        <option
                            *ngFor="let company of companies"
                            [value]="company.id"
                        >{{company.stringified}}</option>
                    </select>
                    &nbsp;
                    <select
                        (change)="updateDepartment($event.target.value)"
                        id="select_department_filter"
                    >
                        <option value="">-- {{'messages.list_values.fields.department'|translate}} --</option>
                        <option
                            *ngFor="let department of departments"
                            [value]="department.id"
                        >{{department.stringified}}</option>
                    </select>
                    &nbsp;
                    <select
                        (change)="updateOrder()"
                        [(ngModel)]="order"
                        id="select_order"
                    >
                        <option [ngValue]="null">-- {{'messages.global.order_by'|translate}} --</option>
                        <ng-container *ngFor="let order of orders">
                            <option [ngValue]="order">{{order.strigified | translate}}
                                ({{('messages.global.' + order.direction)|translate}})
                            </option>
                        </ng-container>
                    </select>
                    &nbsp;
                    <input
                        (input)="executeSearch($event)"
                        [placeholder]="'messages.admin.search'|translate"
                        class="input-search mr-2"
                        id="admin_filters_term"
                        type="text"
                        style="padding: 9px; line-height: inherit;"
                    >
                    <ng-container *ngIf="auth.isEvoEmployee()">
                        <a
                            routerLink="/app/organization-chart" class="mr-2 btn btn-primary btn-sm nav-button"
                        ><i class="icon ion-ios-people"></i> Organization Chart</a>
                        <a
                            routerLink="/app/team/calendar" class="mr-2 btn btn-primary btn-sm nav-button"
                        ><i class="icon ion-calendar"></i> Calendar</a>
                        <a
                            routerLink="/app/team/holidays-requests" class="btn btn-primary btn-sm nav-button"
                        ><i class="icon ion-ios-wineglass"></i> Holidays requests</a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3" *ngIf="profiles?.length">
        <app-team-map [profiles]="profiles">
        </app-team-map>
    </div>

    <div class="team-list">
        <div
            (click)="goToUser(profile.id)"
            *ngFor="let profile of profiles"
            class="person"
            [ngClass]="{birthday: profile.isBirthday}"
        >
            <div class="avatar">
                <span
                    class="user-status"
                    [ngClass]="{'available':profile.isWorking}"
                    [popover]="profile.workingMessage"
                    triggers="hover"
                >
                </span>
                <img
                    [alt]="profile.name"
                    [src]="profile.haveAvatar ? (profile.id|avatarUrl): './../../../../assets/img/no-avatar.png'"
                >
            </div>
            <div class="description word-break">
                <p>
                    <span style="font-weight: bold">{{profile.name}}</span><br>
                    <span style="display: inline-block; padding-top: 5px;">
                        <span *ngIf="profile.department">{{profile.department}}</span>
                        <span *ngIf="profile.department && profile.role">: </span>
                        <span *ngIf="profile.role">{{profile.role}}</span>
                    </span>
                </p>
                <p *ngIf="order && additionalDataKeys.includes(order.column) && profile[order.column]">
                    {{order.strigified|translate}}: {{profile[order.column]}}
                </p>
            </div>
            <img
                *ngIf="profile.isBirthday"
                src="../../../../assets/img/birthdaycake.png"
                alt="birthday_cake"
                style="position: absolute; left: 5px; top: 5px;"
            >
        </div>
    </div>

    <div class="actions-footer-bar">
        <div>
            {{'messages.admin.multi_actions.showing'|translate}}
            <div class="form-group no-background">
                <select (change)="onPaginationChange($event)" class="form-control">
                    <option *ngFor="let paginationOption of pagination.paginationOptions" [value]="paginationOption">
                        {{paginationOption}}
                    </option>
                </select>
            </div>
            {{'messages.admin.multi_actions.per_page'|translate}}
            <div class="table-page-selector">
                <a
                    (click)="gotToFirstPage()"
                    [attr.disabled]="pagination.pager?.current_page === 1 ? 'disabled' :'false'"
                >
                    <<
                </a>
                <a
                    (click)="gotToPreviousPage()"
                    [attr.disabled]="pagination.pager?.current_page === 1 ? 'disabled' :'false'"
                >
                    {{'messages.global.back'|translate}}
                </a>
                {{pagination.pager.first_item_number}}-{{pagination.pager.last_item_number}}
                {{'messages.global.of'|translate}}
                {{pagination.pager.nr_of_items}}
                <a
                    (click)="gotToNextPage()"
                    [attr.disabled]="pagination.pager?.last_item_number === pagination.pager.nr_of_items ? 'disabled' :'false'"
                >
                    {{'messages.global.next'|translate}}
                </a>
                <a
                    (click)="gotToLastPage()"
                    [attr.disabled]="pagination.pager?.last_item_number === pagination.pager.nr_of_items ? 'disabled' :'false'"
                >
                    >>
                </a>
            </div>
        </div>
    </div>
</div>
