import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {CurseSidebarCategory} from '../../../models/models';

@Component({
    selector: 'app-courses-menu',
    templateUrl: './courses-menu.component.html',
    styleUrls: ['./courses-menu.component.scss']
})
export class CoursesMenuComponent implements OnInit {

    public categories: CurseSidebarCategory[] = [];
    public categorySlug = '';
    public courseSlug = '';

    constructor(private api: ApiService) {
    }

    ngOnInit(): void {
        this.api.getCoursesSidebar().subscribe(data => {
            this.categories = data;
        });

        const path = window.location.pathname;

        if (path.includes('/app/courses')) {
            const segments = path.split('/').filter(item => item.length > 0);

            if (segments.length === 4) {
                this.updSlugs(segments[2], segments[3]);
            }
        }
    }

    updSlugs(categorySlug, courseSlug) {
        this.categorySlug = categorySlug;
        this.courseSlug = courseSlug;
    }

    isRootOpened() {
        return (this.categorySlug && this.courseSlug) || window.location.pathname.includes('/app/university');
    }

}
