import {Component, OnInit} from '@angular/core';
import {ProvidedEntity} from '../../../models/models';
import {EntityProviderService} from '../../../services/entity-provider.service';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-portal-n-addin-stats',
    templateUrl: './portal-n-addin-stats.component.html',
    styleUrls: ['./portal-n-addin-stats.component.scss']
})
export class PortalNAddinStatsComponent implements OnInit {

    companies: ProvidedEntity[] = [];
    company: any = null;
    displayStats = true;

    constructor(private entityProvider: EntityProviderService, public auth: AuthService) {
    }

    ngOnInit(): void {
        if (!this.auth.isAdmin()) {
            this.company = this.auth.getLoggedUser().owning_company_id;
            this.displayStats = true;
            return;
        }

        this.entityProvider.getCompanies().subscribe(data => {
            this.companies = data;
            this.company = data?.length ? data[0].id : null;
        });
    }

    reloadComponent() {
        this.displayStats = false;
        setTimeout(() => {
            this.displayStats = true;
        });
    }

}
