<div class="form-group typeahead" style="position: relative">
    <label
        class="control-label"
        [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
        [for]="field.id"
    >{{field.label|translate}}</label>
    <input
        #typeaheadInput
        class="form-control"
        [type]="field.type"
        [id]="field.id"
        [name]="field.name"
        [placeholder]="field.placeholder|translate"
        [disabled]="disabled"
        [value]="value"
        (input)="filterOptions($event.target.value)"
        (blur)="checkSelection($event)"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
    />

    <div
        class="typeahead-results"
        *ngIf="filteredOptions?.length"
    >
        <div *ngFor="let option of filteredOptions" (click)="onItemSelect(option)">{{option.stringified}}</div>
    </div>

    <ul class="selected-items list-unstyled clearfix " *ngIf="multiple && selectedItems.length > 0">
        <li *ngFor="let item of selectedItems">
            <span>{{item.stringified}}</span>
            <a class="item-remove" (click)="removeItem(item)">
                <span class="ion-android-close"></span>
            </a>
        </li>
    </ul>
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
