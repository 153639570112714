<div class="row mt-3 position-relative">
    <div *ngIf="menu?.length && windowWidth <= 768" class="w-100" style="padding: 15px">
        <div class="handbook-info">
            <div *ngIf="menu?.length" class="handbook-navigation">
                <div
                    (click)="menuItem.el.scrollIntoView({behavior: 'smooth'})"
                    *ngFor="let menuItem of menu"
                    [class]="menuItem.tag"
                >
                    {{menuItem.text}}
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{'col-md-8': menu?.length && windowWidth > 768, 'col-12': !menu?.length || windowWidth <= 768 }">
        <div #content [innerHTML]="html|markdown" class="mb-4"></div>
    </div>

    <div *ngIf="menu?.length && windowWidth > 768" class="col-4">
        <div class="handbook-info sticky">
            <div *ngIf="menu?.length" class="handbook-navigation">
                <div
                    (click)="menuItem.el.scrollIntoView({behavior: 'smooth'})"
                    *ngFor="let menuItem of menu"
                    [class]="menuItem.tag"
                >
                    {{menuItem.text}}
                </div>
            </div>
        </div>
    </div>
</div>
