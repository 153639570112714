<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
        >{{email[field.id] !== null ? (email[field.id]|stringified) : ' -- '}}</p>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto" *ngIf="field.type === 'list'"
        >
            <p class="form-control-static" *ngIf="email[field.id].length === 0"> -- </p>
            <ul
                *ngIf="email[field.id].length !== 0"
                style="padding-left: 5px; padding-right: 10px;"
            >
                <li *ngFor="let item of email[field.id]; let index = item">{{item|stringified}}</li>
            </ul>
        </div>
    </div>

    <div class="form-group" *ngIf="email.attachments?.length">
        <label class="control-label float-left">Attachments</label>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto"
        >
            <p class="form-control-static" *ngIf="email.attachments?.length === 0"> -- </p>
            <ul
                *ngIf="email.attachments?.length !== 0"
                style="padding-left: 5px; padding-right: 10px;"
            >
                <li
                    *ngFor="let item of email.attachments"
                ><a [href]="item|sentMailAttachmentUrl" download>{{item|stringified}}</a></li>
            </ul>
        </div>
    </div>

    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="sent_message_html_{{email.id}}"
            (click)="showHtml = true"
            *ngIf="email.html"
        >
            <i class="icon icon-size-9 ion-search"></i>
            HTML
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="sent_message_text_{{email.id}}"
            (click)="showText = true"
            *ngIf="email.text"
        >
            <i class="icon icon-size-9 ion-search"></i>
            Text
        </button>
    </div>
</div>

<app-modal
    *ngIf="showHtml"
    (mClose)="showHtml = false"
    mTitle="HTML"
    [withSubmit]="false"
>
    <iframe
        [src]="htmlUrl| safeResourceUrl"
    ></iframe>
</app-modal>

<app-modal
    (mClose)="showText = false"
    mTitle="Text"
    [withSubmit]="false"
    *ngIf="showText"
>
    <div style="white-space: initial; word-break: break-word;">{{email.text}}</div>
</app-modal>
