import {Component, OnInit} from '@angular/core';
import {AdminSchedulingList, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-scheduling',
    templateUrl: './scheduling.component.html',
    styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

    public inactive: boolean;
    public busy: boolean;
    public entities: AdminSchedulingList[];
    public selectedIds = [];
    public selectedEntity: AdminSchedulingList;
    public addEditModal: boolean;
    private csvFile = 'evo-scheduling.csv';
    public fieldsName: { id: string, name: string, type: string }[] = [
        {
            id: 'type',
            name: 'messages.admin.scheduling_page.table.th.type',
            type: 'string'
        },
        {
            id: 'day_of_delivery_as_tex',
            name: 'messages.admin.scheduling_page.table.th.type',
            type: 'string'
        }
    ];

    public editEntity: AdminSchedulingList | null;

    constructor(private apiService: ApiService,
                public paginationService: PaginationService,
                public toastrService: ToastrService,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadList() {
        this.apiService.getScheduling(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminSchedulingList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity(entity: AdminSchedulingList = null) {
        this.addEditModal = true;
        this.editEntity = entity;
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const user of this.entities) {
                this.selectedIds.push(user.id);
                user.checked = true;
            }
        } else {
            for (const user of this.entities) {
                user.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getScheduling(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity() {
        this.loadList();
        this.editEntity = null;
        this.addEditModal = false;
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getScheduling(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    closeModal(type: string) {
        this.editEntity = null;
        this.addEditModal = false;
        this.loadList();
    }

    exportAll() {
        this.apiService.exportScheduling().subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    exportSelected() {
        this.apiService.exportSelectedScheduling(this.selectedIds).subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    deleteSelected() {
        this.apiService.deleteSelectedScheduling(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.apiService.getScheduling(this.paginationService.pager).subscribe(response => {
                this.entities = response;
            });
        });
    }

    flushRedisCache(e: Event) {
        e.preventDefault();

        this.apiService.flushRedisCache().subscribe();
    }

    indexPages(e: Event) {
        e.preventDefault();

        this.apiService.indexPages().subscribe();
    }
}
