import {Component, OnInit} from '@angular/core';
import {LocaleService} from './services/locale.service';
import {GaRouteTrackerService} from './services/ga-route-tracker.service';
import {AuthService} from './services/auth.service';
import {GlobalErrorService} from './services/global-error.service';
import {setTheme} from 'ngx-bootstrap/utils';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(
        private auth: AuthService,
        private globalErrorService: GlobalErrorService,
        private router: Router,
        localeService: LocaleService,
        gaRouteService: GaRouteTrackerService
    ) {
        gaRouteService.startTrack();
        setTheme('bs4');
    }

    ngOnInit(): void {
        const user = localStorage.getItem('EVO-USER');

        if (!user || window.location.search.includes('logout=1')) {
            this.auth.logout();

            if (window.location.pathname === '/') {
                this.router.navigateByUrl('/login');
            }

            return;
        }

        this.auth.refreshCookie();

        const {theme} = JSON.parse(user);

        if (!theme || theme !== 'dark') {
            return;
        }
        // Disable temporary dark theme - https://gitlab.evouser.com/portal/evo-frontend-issues/-/issues/2042
        // document.querySelector('body').classList.add('theme-dark');
    }

    public showGlobalError() {
        return !this.auth.isAuthenticated() && this.globalErrorService.getError();
    }
}
