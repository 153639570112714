import {Component, Input, OnInit} from '@angular/core';
import {GitIssueNote, ProvidedEntity} from '../../../../../models/models';
import {FormBuilder, FormControl} from '@angular/forms';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';

@Component({
    selector: 'app-issue-note',
    templateUrl: './issue-note.component.html',
    styleUrls: ['./issue-note.component.scss']
})
export class IssueNoteComponent implements OnInit {

    @Input() note: GitIssueNote;
    @Input() issueIid: any;

    public commentControl: FormControl;
    public loading = false;
    public edit = false;
    public currentUserId = null;
    public mentions = [];

    constructor(private formBuilder: FormBuilder, private api: ApiService, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.commentControl = this.formBuilder.control(this.note.body);
        this.currentUserId = this.auth.getLoggedUser().id;
    }

    cancel() {
        this.edit = false;
        this.commentControl = this.formBuilder.control(this.note.body);
    }

    update() {
        if (this.loading) {
            return;
        }

        this.commentControl.markAsTouched();

        if (this.commentControl.status === 'INVALID') {
            return;
        }

        this.loading = true;

        const data = {
            body: this.commentControl.value,
            mentions: []
        };

        this.mentions = this.mentions.map((item => item.id));
        data.mentions = this.mentions.length > 0 ?
            this.mentions.filter((item, index) => this.mentions.indexOf(item) === index ) :
            null;

        this.api.updateCommentForGitIssue(this.issueIid, this.note.id, data).subscribe(() => {
            this.note.body = data.body;
            this.note.updated_at = new Date();

            this.edit = false;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    mentionChangedHandler($event: ProvidedEntity[]) {
        this.mentions = $event;
    }
}
