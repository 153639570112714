import {Component, OnInit} from '@angular/core';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.scss'],
    providers: [FormLoadingServiceService]
})
export class MembershipComponent implements OnInit {
    public groups: any = [];
    public availableGroups = false;
    public showModal = false;

    constructor(private formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.loadList();
    }

    loadList() {
        this.apiService.getProfileGroups().subscribe(data => {
            this.groups = data;
        });

        this.apiService.getAvailableGroups().subscribe(data => {
            this.availableGroups = !!data.length;
        });
    }

    openModal() {
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
        this.loadList();
    }
}
