<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="(user ? 'messages.admin.users_page.form.user.title_edit' : 'messages.admin.users_page.form.user.title_create')|translate"
>
    <app-form
        [formConfig]="formConfig"
        [value]="user"
        [lists]="lists"
        [hideButtons]="true"
        (submitForm)="addUser($event)"
        [loading]="formLoadingServiceService.isLoading()"
    >
    </app-form>
</app-modal>

