import {Component, OnInit, ViewChild} from '@angular/core';
import {WcResourcesInjectorService} from '../../../services/wc-resources-injector.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-plans-page',
    templateUrl: './plans-page.component.html',
    styleUrls: ['./plans-page.component.scss']
})
export class PlansPageComponent implements OnInit {

    public loaded = false;
    public failedLoad = false;

    @ViewChild('component_container') container;

    constructor(private wcResourcesInjector: WcResourcesInjectorService) {
    }

    ngOnInit(): void {
        const url = environment.webAppsDomain + '/our-plans-app/our-plans-app.min.js';

        this.wcResourcesInjector.addJs(url).then(() => {
            this.loaded = true;

            const el = document.createElement('our-plans-app');
            this.container.nativeElement.appendChild(el);
        }).catch(() => {
            this.loaded = false;
            this.failedLoad = true;
        });

    }

}
