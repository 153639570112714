import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class LastSessionTrackerService {

    private sub = null;

    constructor(private router: Router, private auth: AuthService, private api: ApiService) {
    }

    public start() {
        if (!this.auth.getLoggedUser()) {
            this.stop();
            return;
        }

        if (!this.auth.getLoggedUser().remember_session) {
            return;
        }

        this.sub = this.router.events.subscribe((event: RouterEvent) => {
                if (!(event instanceof NavigationEnd)) {
                    return;
                }

                this.api.setLastSession(event.urlAfterRedirects).subscribe();
            }
        );

        this.api.setLastSession(window.location.pathname).subscribe();
    }

    public stop() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
    }
}
