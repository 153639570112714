<app-modal
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [loading]="form?.submitted || formLoadingServiceService.isLoading()"
    [mTitle]="(item ? 'messages.holidays_requests.edit' : 'messages.holidays_requests.add')|translate"
>
    <app-form
        (submitForm)="addRequest($event)"
        [formConfig]="formConfig"
        [hideButtons]="true"
        [lists]="lists"
        [value]="item"
        *ngIf="!formLoadingServiceService.isLoading()"
    >
    </app-form>
</app-modal>
