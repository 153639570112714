<div class="form-group mb-4">
    <div class="mb-3">
        {{config.label|translate}}
    </div>
    <div *ngFor="let control of form.controls; index as i">
        <div [ngClass]="config.collectionRowConfig?.rowClass">
            <div
                *ngFor="let field of config.collectionInputs; index as j"
                [ngClass]="config.collectionRowConfig?.columnsClasses[j]"
            >
                <ng-container
                    *ngTemplateOutlet="inputTemplate; context: {
                         field: field,
                         form: control.controls[field.name],
                         errors: errors,
                         options: (lists | controlListsValue: field)
                     }"
                >
                </ng-container>
            </div>
        </div>
        <div class="text-right">
            <button
                (click)="remove(i)" class="btn btn-xs btn-danger" type="button"
            >
                <i
                    class="icon icon-size-9 ion-close-circled"
                ></i>

                {{'messages.global.delete'|translate}}
            </button>
        </div>
    </div>
    <a (click)="add()" class="btn btn-xs btn-primary">
        <i class="icon icon-size-9 ion-plus-circled"></i>
        {{'messages.global.add_new'|translate}}
    </a>
</div>
