<div class="card card-default margin-bottom-10 p-3">
    <div role="tab" class="card-heading" id="chart{{index}}">
        <div class="card-title layout-table col-100 margin-bottom-5">
            <div class="layout-table-cell col-3 col-md-3">
                <a style="cursor:pointer;padding: 0;" class="action-link action-link-disabled padding0">
                    <i class="ion ion-arrow-up-a"></i>
                </a>
                <a style="cursor:pointer;padding: 0;" class="action-link action-link-disabled padding0">
                    <i class="ion-arrow-down-a"></i>
                </a>
            </div>
            <div class="layout-table-cell col-3 col-md-3">
                <select class="col-px-220 form-control" id="datasource{{index}}" [(ngModel)]="selectChartType" (change)="changeChart()">
                    <option *ngFor='let option of defaultConfig?.chartTemplates; let i = index' [value]="option.id" >{{option.name}}</option>
                </select>
            </div>
            <div class="layout-table-cell col-3 col-md-3 padding-left-7">
                <input type="text" placeholder="Chart Title" class="ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="chartConfig.title">
            </div>
            <div class="layout-table-cell col-3 col-md-3 padding-left-7">
                <input type="text" placeholder="Chart Subtitle" class="ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="chartConfig.subtitle">
            </div>

            <div class="layout-table-cell text-right">
                <a aria-controls="collapseChart1" aria-expanded="false" data-parent="#chart-accordion" data-toggle="collapse" class="collapsed color-dark-grey">
                    <span class=" ion-chevron-down ng-hide" *ngIf="chartConfig.minified" (click)="chartConfig.minified = !chartConfig.minified"></span>
                    <span class=" ion-chevron-left" *ngIf="!chartConfig.minified" (click)="chartConfig.minified = !chartConfig.minified"></span>
                </a>
            </div>

            <div class="layout-table-cell col-3 col-md-3 text-right" (click)="removeChart()">
                <a class="color-dark-grey">
                    <span class=" ion-android-close"></span>
                </a>
            </div>
        </div>

        <div class="row margin-top-10 ng-scope" style="margin-left: 2px;font-weight: bold">
            <div class="col-12 col-md-12 line-height-28 margin-bottom-5">
                <span class="ng-scope">Y data series</span>
            </div>
        </div>

        <div class="row ng-scope">
            <div class="col-12 col-md-12">
                <div class="card card-default margin-top-0 padding-top-8 ng-scope">
                    <div class="card-body position-relative padding-top-5 padding-bottom-0" *ngFor="let serie of chartConfig.series; let i =index;">
                        <div class="row margin-bottom-10">
                            <div class="col-lg-2 col-md-2">
                                <label for="data-column">Data column</label>
                                <select class="form-control" id="data-column{{index}}" [(ngModel)]="chartConfig.series[i].columnName" (change)="updateChart()">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of supportedXColumns; let i = index' [value]="option.name">{{option.name}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2">
                                <label for="grouping-functions">Grouping Functions</label>
                                <select class="form-control" id="grouping-functions{{index}}" [(ngModel)]="chartConfig.series[i].groupBy.groupingFunction" (change)="updateChart()">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of defaultConfig?.groupingFunctions; let i = index' [value]="option.id">{{option.name}}</option>
                                </select>
                            </div>

                            <!--TODO: FIX THIS-->
                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'weighted-sum'" >
                                <label for="weighted-sum">Weighted By</label>
                                <select class="form-control" id="weighted-sum{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].groupBy.weightByColumnName">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'delta-weighted-sum'">
                                <label for="weighted-sum{{index}}">Data column 2</label>
                                <select class="form-control" id="delta-weighted-column-2-{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].columnName2">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>

                            <div class="col-lg-2 col-md-2" *ngIf="chartConfig.series[i].groupBy.groupingFunction == 'delta-weighted-sum'">
                                <label for="weighted-sum{{index}}">Weighted By 2</label>
                                <select class="form-control" id="delta-weighted-by-column-2-{{index}}" (change)="updateChart()" [(ngModel)]="chartConfig.series[i].groupBy.weightByColumnName2">
                                    <option hidden [value]="" selected></option>
                                    <option *ngFor='let option of weightByColumns; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!chartConfig.minified" aria-labelledby="chart" role="tabpanel" class="card-collapse collapse show" id="collapseChart{{index}}">
        <div class="card-body" style="border-top: 0">
            <div class="row">
                <div class="col-12 col-md-12 layout-table">
                    <div class="row">
                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="font-size">Font size</label>
                                <input type="text" class="form-control" [(ngModel)]="chartConfig.fontSize" (change)="updateChart()" id="font-size{{index}}" />
                            </div>
                        </div>
                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="text-align">Text Align</label>
                                <select class="form-control" id="text-align{{index}}" [(ngModel)]="chartConfig.textAlign" (change)="updateChart()">
                                    <option *ngFor='let option of ["left", "center", "right"]; let i = index' [value]="option">{{option}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-12 layout-table">
                    <div class="row">
                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="width{{index}}">
                                    <span><span></span></span>
                                    Width
                                </label>
                                <input type="number" [(ngModel)]="chartConfig.width" (change)="updateChart()" class="form-control" id="width{{index}}"/>
                            </div>
                        </div>
                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="height{{index}}">Height</label>
                                <input type="number" [(ngModel)]="chartConfig.height" (change)="updateChart()" class="form-control" id="height{{index}}"/>
                            </div>
                        </div>

                        <div class="col-4 col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="format{{index}}">Format</label>
                                <input type="text" [(ngModel)]="chartConfig.numberFormat" (change)="updateChart()" class="form-control" id="format{{index}}"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100">
                    <div class="col-lg-12" style="border-top: 1px solid #d7d7d7;margin: 10px 0px 10px 0px;padding: 10px;"></div>
                    <div class="col-lg-6 col-md-7 col-6">
                        <div class="layout-table">
                            <div class="layout-table-cell col-px-110">Colour palette</div>
                            <div 
                                *ngFor="let color of colors;let i = index"
                                data-ng-model="colorPalette"
                                data-ng-repeat="(key, color) in colorPaletteConstants"
                                class="layout-table-cell col-px-20 ng-pristine ng-untouched ng-valid ng-scope ng-not-empty"
                            >
                                <div
                                    [ngStyle]="{backgroundColor: color.value}"
                                    class="color-marker" style="cursor: pointer"
                                    [ngClass]="{'color-marker-big': i <= 1}"
                                    (click)="setColorPalette(color)"
                                ></div>
                            </div>

                            <div class="layout-table">
                                <div class="layout-table-cell col-px-140">First and last series</div>
                                <div class="layout-table-cell">
                                    <div [ngStyle]="{backgroundColor: firstColor}" class="color-marker color-marker-big" (click)="editFirst = true"></div>
                                </div>
                                <div style="position: absolute; z-index: 10;" *ngIf="editFirst">
                                    <color-sketch [color]="firstColor" (onChangeComplete)="firstColor = $event.color.hex"></color-sketch>
                                </div>
                                <div class="layout-table-cell padding-left-7">
                                    <div [ngStyle]="{backgroundColor: secondColor}" class="color-marker color-marker-big" (click)="editSecond = true"></div>
                                </div>
                                <div style="position: absolute; z-index: 10;" *ngIf="editSecond">
                                    <color-sketch [color]="secondColor" (onChangeComplete)="firstColor = $event.color.hex"></color-sketch>
                                </div>
                            </div>
                            <div class="layout-table-cell col-px-140">
                                <span style="font-size: 12px;text-decoration: underline;cursor: pointer" ng-click="chart.paletteLastColor = chart.paletteFirstColor;refreshChart(chart.id)"
                                >Set colors same</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

