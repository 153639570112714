import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { BuilderBarChartComponent } from './elements/charts/builder-bar-chart/builder-bar-chart.component';
import { BuilderLineChartComponent } from './elements/charts/builder-line-chart/builder-line-chart.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import {FormsModule} from '@angular/forms';
import { BuilderNumberChartComponent } from './elements/charts/builder-number-chart/builder-number-chart.component';
import { BuilderRowChartComponent } from './elements/charts/builder-row-chart/builder-row-chart.component';
import { BuilderPieChartComponent } from './elements/charts/builder-pie-chart/builder-pie-chart.component';
import { BuilderDropdownChartComponent } from './elements/charts/builder-dropdown-chart/builder-dropdown-chart.component';
import { BuilderMapChartComponent } from './elements/charts/builder-map-chart/builder-map-chart.component';
import { BuilderTableChartComponent } from './elements/charts/builder-table-chart/builder-table-chart.component';
import { BuilderPivotTableChartComponent } from './elements/charts/builder-pivot-table-chart/builder-pivot-table-chart.component';


@NgModule({
    declarations: [
        BuilderBarChartComponent,
        BuilderLineChartComponent,
        BuilderNumberChartComponent,
        BuilderRowChartComponent,
        BuilderPieChartComponent,
        BuilderDropdownChartComponent,
        BuilderMapChartComponent,
        BuilderTableChartComponent,
        BuilderPivotTableChartComponent,
    ],
    exports: [
        BuilderBarChartComponent,
        BuilderLineChartComponent,
        BuilderBarChartComponent,
        BuilderLineChartComponent,
        BuilderNumberChartComponent,
        BuilderRowChartComponent,
        BuilderPieChartComponent,
        BuilderDropdownChartComponent,
        BuilderMapChartComponent,
        BuilderTableChartComponent,
        BuilderPivotTableChartComponent,
    ]
    ,
    imports: [
        CommonModule,
        ColorSketchModule,
        FormsModule,
    ]
})
export class DashboardBuilderModule {
}
