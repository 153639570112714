import {Pipe, PipeTransform} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';
import qs from 'qs';

@Pipe({
    name: 'apiKey'
})
export class ApiKeyPipe implements PipeTransform {

    constructor(private auth: AuthService) {
    }

    transform(value: string, noCache = false): string {
        const params: { [key: string]: any } = {};

        if (noCache) {
            params._r = Date.now().toString();
        }

        if (!environment.production) {
            params.api_key = this.auth.getApiKey();
        }


        return value + `?${qs.stringify(params)}`;
    }
}
