import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LineChartComponent} from './charts/line-chart/line-chart.component';
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {PieChartComponent} from './charts/pie-chart/pie-chart.component';
import {NumberChartComponent} from './charts/number-chart/number-chart.component';
import {MapChartComponent} from './charts/map-chart/map-chart.component';
import {DropdownChartComponent} from './charts/dropdown-chart/dropdown-chart.component';
import {PivotTableChartComponent} from './charts/pivot-table-chart/pivot-table-chart.component';
import {RowChartComponent} from './charts/row-chart/row-chart.component';
import {TableChartComponent} from './charts/table-chart/table-chart.component';


@NgModule({
    declarations: [
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        NumberChartComponent,
        MapChartComponent,
        DropdownChartComponent,
        PivotTableChartComponent,
        RowChartComponent,
        TableChartComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        NumberChartComponent,
        MapChartComponent,
        DropdownChartComponent,
        PivotTableChartComponent,
        RowChartComponent,
        TableChartComponent,
    ]
})
export class DashboardChartsRendererModule {
}
