import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatchValidator} from '../../../../validators/matchValidators';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {AdminUsersList, FormConfig} from '../../../../models/models';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

interface PasswordForm {
    plainPassword: string;
    repeatPassword: string;
}

@Component({
    selector: 'app-user-change-password-modal',
    templateUrl: './user-change-password-modal.component.html',
    styleUrls: ['./user-change-password-modal.component.scss']
})
export class UserChangePasswordModalComponent implements OnInit {
    @Input() user: AdminUsersList;
    @Output() closeModal = new EventEmitter();
    @Output() changePasswordSubmit = new EventEmitter();
    @ViewChild(FormComponent, {static: true}) form: FormComponent<PasswordForm>;

    public errors;
    public formConfig: FormConfig = {
        fields: [
            {
                name: 'plainPassword',
                label: 'messages.admin.users_page.form.user.fields.password.first.label',
                placeholder: 'messages.admin.users_page.form.user.fields.password.first.label',
                id: 'user_create_password',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required, Validators.minLength(8)],
            },
            {
                name: 'repeatPassword',
                label: 'messages.admin.users_page.form.user.fields.password.second.label',
                placeholder: 'messages.admin.users_page.form.user.fields.password.second.label',
                id: 'user_create_repeatPassword',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required],
            },
        ],
        validators: [MatchValidator('plainPassword', 'repeatPassword')]
    };

    constructor(private formBuilder: FormBuilder,
                private api: ApiService,
                private errorsTransformer: FormErrorsTransformerService) {
    }

    ngOnInit() {
        if (!this.user) {
            throw new Error('User should be set!');
        }
    }

    changePassword(data: PasswordForm) {
        this.api.updateUserPassword(this.user.id, {
            plainPassword: {
                first: data.plainPassword,
                second: data.repeatPassword
            }
        }).subscribe(() => {
            this.changePasswordSubmit.emit();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.closeModal.emit({type: 'password'});
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }
}
