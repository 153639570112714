<div *ngIf="folder" [class]="'folder-container lvl' + lvl">
    <div [ngClass]="{'cursor-pointer': lvl > 0}">
        <i class="ion ion-folder"></i> {{folder?.name}}
    </div>
    <div class="folder-content">
        <div
            [sortablejs]="folder.folders"
            [sortablejsOptions]="folderOptions"
            class="children"
        >
            <div
                class="child"
                *ngFor="let child of folder.folders"
                (mousedown)="$event.stopPropagation()"
                (touchstart)="$event.stopPropagation()"
            >
                <app-folder-tree-node [lvl]="lvl + 1" [folder]="child">
                </app-folder-tree-node>
            </div>
        </div>

        <div
            [sortablejs]="folder.pages"
            [sortablejsOptions]="pagesOptions"
            class="pages-container"
        >
            <div
                *ngFor="let page of folder.pages"
                class="cursor-pointer"
                (mousedown)="$event.stopPropagation()"
                (touchstart)="$event.stopPropagation()"
            >
                <div>
                    <i class="ion ion-document"></i> {{page?.name}}
                </div>
            </div>
        </div>
    </div>
</div>
