import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminContentsList, Content, ContentModel, FormConfig, FormInputConfig} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../form-generator/components/form/form.component';
import * as _ from 'lodash';
import {ContentFormConfig} from '../formConfigs';

@Component({
    selector: 'app-content-type-shiny-modal',
    templateUrl: './content-type-shiny-modal.component.html',
    styleUrls: ['./content-type-shiny-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ContentTypeShinyModalComponent implements OnInit {
    @Output() update = new EventEmitter();
    @Input() content: AdminContentsList | Content;
    public value = null;

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentShiny)
    };

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(1);

        this.apiService.manageContent(this.content.id).subscribe(data => {
            this.value = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    updateContent(data: ContentModel) {
        this.apiService.updateContent(this.content.id, data).subscribe((response) => {
            this.update.emit(true);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }
}
