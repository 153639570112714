import {FormInputConfig} from '../../../models/models';
import {Validators} from '@angular/forms';

const contentsCommon: FormInputConfig[] = [
    {
        name: 'name',
        label: 'messages.admin.contents_page.form.content.fields.name.label',
        placeholder: 'messages.admin.contents_page.form.content.fields.name.label',
        id: 'content_name',
        type: 'text',
        validators: [Validators.required, Validators.maxLength(255)]
    },
    {
        name: 'title',
        label: 'messages.admin.contents_page.form.content.fields.title.label',
        placeholder: 'messages.admin.contents_page.form.content.fields.title.label',
        id: 'content_title',
        type: 'text',
        validators: [Validators.maxLength(255)]
    },
    {
        name: 'subtitle',
        label: 'messages.admin.contents_page.form.content.fields.subtitle.label',
        placeholder: 'messages.admin.contents_page.form.content.fields.subtitle.label',
        id: 'content_subtitle',
        type: 'text',
        validators: [Validators.maxLength(255)]
    },
];

export const ContentFormConfig: { [key: string]: FormInputConfig[] } = {
    contentsFields: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'type',
            label: 'messages.admin.contents_page.form.content.fields.type.label',
            placeholder: 'messages.admin.contents_page.form.content.fields.type.placeholder',
            id: 'content_type',
            type: 'select',
            validators: [Validators.required]
        }
    ],
    contentHtml: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'html',
            label: 'HTML',
            id: 'content_html',
            type: 'html-editor',
            validators: [Validators.required]
        }
    ],
    contentMarkdown: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'html',
            label: 'Markdown',
            id: 'content_markdown',
            type: 'markdown',
            validators: [Validators.required]
        }
    ],
    contentHandbook: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'folder',
            label: 'messages.admin.companies_page.form.company.fields.folder.label',
            id: 'folder',
            type: 'typeahead',
            hidden: true
        },
        {
            name: 'published',
            label: 'Published',
            id: 'published',
            type: 'checkbox'
        },
        {
            name: 'authorVisible',
            label: 'Author visible',
            id: 'authorVisible',
            type: 'checkbox'
        },
        {
            name: 'shortDescription',
            label: 'Short description',
            id: 'shortDescription',
            type: 'textarea',
            propertyPath: 'news.short_description',
            validators: [Validators.maxLength(255)],
            hidden: true
        },
        {
            name: 'html',
            label: 'HTML',
            id: 'content_html',
            type: 'html-editor',
            validators: [Validators.required]
        },
    ],
    contentIframe: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'html',
            label: 'Url',
            id: 'content_html',
            type: 'text',
            validators: [Validators.required, Validators.pattern(/(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*\/?(\?.+)?/)]
        }
    ],
    contentCourse: [
        {
            name: 'name',
            label: 'messages.admin.contents_page.form.content.fields.name.label',
            placeholder: 'messages.admin.contents_page.form.content.fields.name.label',
            id: 'content_name',
            type: 'text',
            validators: [Validators.required, Validators.maxLength(255)]
        },
        {
            name: 'remoteId',
            label: 'messages.admin.contents_page.course',
            id: 'content_remoteId',
            type: 'select',
            listValuesKey: 'courses',
            validators: [Validators.required]
        }
    ],
    contentShiny: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'html',
            label: 'messages.admin.contents_page.form.content.fields.shiny_id.label',
            id: 'content_html',
            type: 'text',
            validators: [Validators.required]
        }
    ],
    contentNetworkShare: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'remoteId',
            label: 'messages.admin.contents_page.form.content.fields.network_share_folder.label',
            id: 'content_remoteId',
            type: 'text',
            validators: [Validators.required]
        }
    ],
    contentDynamicHtml: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'company',
            label: 'messages.admin.pages_page.form.page.fields.company.label',
            id: 'content_company',
            type: 'provided-entity-select',
            validators: [Validators.required]
        },
        {
            name: 'remoteId',
            label: 'messages.admin.pages_page.form.page.fields.datasource.label',
            id: 'content_remoteId',
            type: 'provided-entity-select',
            listValuesKey: 'contents',
            validators: [Validators.required],
        }
    ],
    contentPresentation: [...contentsCommon.map(item => ({...item})),
        {
            name: 'downloadable',
            label: 'Enable  download button',
            id: 'downloadable',
            type: 'checkbox'
        },
    ],
    contentWebApplication: [
        ...contentsCommon.map(item => ({...item})),
        {
            name: 'tag',
            label: 'Web component tag name',
            placeholder: 'my-cool-tag',
            id: 'tag',
            type: 'text',
            validators: [Validators.required]
        },
        {
            name: 'js',
            label: 'JS',
            type: 'collection',
            collectionInputs: [
                {
                    name: 'url',
                    type: 'text',
                    label: 'Url',
                    validators: [Validators.required, Validators.pattern(/(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*\/?(\?.+)?/)]
                },
            ]
        },
        {
            name: 'css',
            label: 'CSS',
            type: 'collection',
            collectionInputs: [
                {
                    name: 'url',
                    type: 'text',
                    label: 'Url',
                    validators: [Validators.required, Validators.pattern(/(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*\/?(\?.+)?/)]
                },
            ]
        },
        {
            name: 'attribute',
            label: 'Attributes',
            type: 'collection',
            collectionInputs: [
                {
                    name: 'value',
                    type: 'text',
                    label: 'Attribute - Value pair',
                    validators: [Validators.required, Validators.pattern(/^[^-]+-?[^-=]*=[^=]*$/)],
                    placeholder: 'ie. version=1.02'
                },
            ]
        }
    ]
};
