<div class="row mt-3 position-relative">
    <div *ngIf="windowWidth <= 768" class="w-100" style="padding: 15px">
        <div class="handbook-info">
            <a
                class="d-flex"
                [routerLink]="'/app/team/'+creator?.id"
                *ngIf="creator"
            >
                <div class="avatar mb-2" [title]="creator.name">
                    <img [src]="creator.id|avatarUrl" [alt]="creator.name">
                </div>
                <div class="ml-2">
                    {{creator.name}}
                </div>
            </a>
            <div>
                {{'messages.global.last_update'|translate}}: {{content.updated_at|date:'yyyy-MM-dd @ HH:mm'}}
            </div>
            <hr>
            <div class="handbook-navigation" *ngIf="menu?.length">
                <div
                    *ngFor="let menuItem of menu"
                    [class]="menuItem.tag"
                    (click)="menuItem.el.scrollIntoView({behavior: 'smooth'})"
                >
                    {{menuItem.text}}
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{'col-8': windowWidth > 768, 'col-12': windowWidth <= 768}">
        <div class="handbook-content" [innerHTML]="text | safeHtml" #handbook></div>
    </div>

    <div class="col-4" *ngIf="windowWidth > 768">
        <div class="handbook-info sticky">
            <span *ngIf="content.author_visible">
                <a
                    class="d-flex"
                    [routerLink]="'/app/team/'+creator?.id"
                    *ngIf="creator"
                >
                    <div class="avatar mb-2" [title]="creator.name">
                        <img [src]="creator.id|avatarUrl" [alt]="creator.name">
                    </div>
                    <div class="ml-2">
                        {{creator.name}}
                    </div>
                </a>
                <div>
                    {{'messages.global.last_update'|translate}}: {{content.updated_at|date:'yyyy-MM-dd @ HH:mm'}}
                    <span *ngIf="content.updated_by">
                    by
                    <a [routerLink]="'/app/team/'+content.updated_by?.id" *ngIf="content.updated_by">
                        {{content.updated_by.stringified}}
                    </a>
                    </span>
                </div>
            </span>
            <hr>
            <div class="handbook-navigation" *ngIf="menu?.length">
                <div
                    *ngFor="let menuItem of menu"
                    [class]="menuItem.tag"
                    (click)="menuItem.el.scrollIntoView({behavior: 'smooth'})"
                >
                    {{menuItem.text}}
                </div>
            </div>

            <div class="handbook-navigation margin-top-25" *ngIf="relatedContents?.length">
                <h6 class="margin-left-5">Related contents</h6>
                <div
                    *ngFor="let relatedContent of relatedContents"
                    [class]="relatedContent.tag"
                    (click)="relatedContent.el.scrollIntoView({behavior: 'smooth'})"
                >
                    {{relatedContent.text}}
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="content?.attachments?.length" class="mt-2 mb-2">
    <div class="mb-2">{{'messages.attachments.label_2'|translate}}:</div>
    <div class="mb-2" *ngFor="let file of content.attachments, index as i">
        <a [href]="file|contentAttachmentUlr" download><i class="icon ion-document-text"></i> {{file.name}}</a>
    </div>
</div>
