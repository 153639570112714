import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminSubscriptionsList} from '../../../../models/models';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-subscrption-table-tr-info',
    templateUrl: './subscrption-table-tr-info.component.html',
    styleUrls: ['./subscrption-table-tr-info.component.scss']
})
export class SubscrptionTableTrInfoComponent implements OnInit {

    @Input() subscription: AdminSubscriptionsList;
    @Output() openModal = new EventEmitter();
    @Output() updated = new EventEmitter();
    @Output() openFollowersModal = new EventEmitter();
    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'sent_at',
            name: 'messages.admin.subscriptions_page.table_column_time_last_sent',
            type: 'string'
        },
        {
            id: 'report',
            name: 'messages.admin.subscriptions_page.table_column_page',
            type: 'list'
        },
        {
            id: 'start_date',
            name: 'messages.admin.subscriptions_page.table_column_date_restrictions',
            type: 'string'
        },
        {
            id: 'nr_of_cc',
            name: 'messages.admin.subscriptions_page.table_column_cc_bcc',
            type: 'string'
        },
        {
            id: 'type',
            name: 'messages.admin.subscriptions_page.table_column_time',
            type: 'string'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        }
    ];

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    editSubscription() {
        return this.openModal.emit();
    }

    toggleActive(event: Event, subscription: AdminSubscriptionsList) {
        event.preventDefault();
        this.apiService.toggleActiveSubscription(this.subscription.id).subscribe(() => {
            this.updated.emit();
        });
    }

    viewCCBCC(event: Event, subscription: AdminSubscriptionsList) {
        event.preventDefault();
        this.openFollowersModal.emit();
    }

    viewLog(event: Event, subscription: AdminSubscriptionsList) {
        event.preventDefault();
    }

    delete(event: Event, subscription: AdminSubscriptionsList) {
        event.preventDefault();
        this.apiService.deleteSubscription(this.subscription.id).subscribe(() => {
            this.updated.emit();
        });
    }
}
