<div id="{{chartId}}" class="d3-chart" [ngClass]="{'new-line': chartConfig.addInNewLine}" style="margin-bottom: 10px" #chart>
    <div *ngIf="!loading">
        <strong style="margin-left: 50px; float: left;">{{chartConfig.title}}</strong>
    </div>
    <div class="clearfix" style="height: 0"></div>
    <span *ngIf="chartConfig.renderFilterDetails && !loading" class="filter" style="float: left; margin-left: 5px;"></span>
    <a class="reset float-left" style="margin-left: 10px; cursor: pointer;visibility: hidden" (click)="resetChart()" #resetButton>Reset</a>
    <div class="clearfix"></div>
    <div *ngIf="!loading" style="margin-left: 50px;">{{chartConfig.subtitle}}</div>
</div>
