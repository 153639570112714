import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {DisplayError, InfoRow, ProfileData, ProfileListsValues} from '../../../../models/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {MapToTypeahead} from '../../../../helpers/MapToTypeahead';
import {TextAreaDirective} from '../../../../common/texarea-directive/text-area.directive';
import {AutoHeight} from '../../../../helpers/AutoHeight';
import * as moment from 'moment';
import {MAX_INT} from '../../../../services/constants';

@Component({
    selector: 'app-profile-value-form',
    templateUrl: './profile-value-form.component.html',
    styleUrls: ['./profile-value-form.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ProfileValueFormComponent implements OnInit {

    @Input() fields: InfoRow[];
    @Input() profile: ProfileData;
    @Input() hideButtons = false;
    @Output() closeForm = new EventEmitter();
    @Output() updateProfileSubmit = new EventEmitter();
    public form: FormGroup;
    public submitted: boolean;
    public errors: DisplayError[];
    public formName = 'expandedUserProfile';
    public listsValues: ProfileListsValues;
    @ViewChildren(TextAreaDirective) textAreas: QueryList<TextAreaDirective>;
    autoHeight = AutoHeight;

    constructor(private formBuilder: FormBuilder,
                private api: ApiService,
                private auth: AuthService,
                private provider: EntityProviderService,
                private errorsTransformer: FormErrorsTransformerService,
                public  formLoadingServiceService: FormLoadingServiceService
    ) {
    }

    ngOnInit() {
        if (!this.fields || !this.fields.length) {
            throw Error('Fields are required');
        }

        this.formLoadingServiceService.init(1);
        this.form = this.formBuilder.group({});

        this.provider.getProfileListsValues(this.profile.id, this.profile.companyId).subscribe(data => {
            this.listsValues = data;

            this.fields.forEach(field => {
                const currentValue = this.profile[field.key];
                const control = this.formBuilder.control(currentValue);

                if (field.type === 'number') {
                    control.setValidators([Validators.max(MAX_INT)]);
                }

                this.form.addControl(field.key, control);

                if (field.key === 'badges') {
                    const badges = currentValue.map(item => item.value);
                    const value = data[field.listValuesKey].filter(item => badges.includes(item.stringified));

                    if (value) {
                        control.setValue(value);
                    }

                    return;
                }

                if (field.type === 'list') {
                    if (field.multiple) {
                        const value = data[field.listValuesKey].filter(item => currentValue.includes(item.stringified));

                        if (value) {
                            control.setValue(value);
                        }
                    } else {
                        const [value] = data[field.listValuesKey].filter(item => item.stringified === currentValue);

                        if (value) {
                            control.setValue(value.id);
                        }
                    }
                }
            });

            this.formLoadingServiceService.eventLoaded();

            setTimeout(() => {
                if (!this.textAreas) {
                    return;
                }

                this.textAreas.forEach(item => {
                    this.autoHeight(item.el.nativeElement, true);
                });
            });

        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

    }

    updateField() {
        if (this.submitted) {
            return;
        }

        this.errors = [];
        const {form} = this;

        if (form.status === 'INVALID') {
            return;
        }

        this.submitted = true;
        const data = {};

        this.fields.forEach(field => {
            let value = form.get(field.key).value;

            if (field.type === 'list' && field.multiple) {
                value = value.map(MapToTypeahead);
            }

            if (field.type === 'date' && value) {
                value = moment(value).format('YYYY-MM-DD');
            }

            data[field.key] = value;
        });

        this.api.updateTeamPerson(this.profile.id, data).subscribe(() => {
            this.updateProfileSubmit.emit();
        }, response => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(form, response);
            this.submitted = false;
        });
    }

    closeModalWindow() {
        return this.closeForm.emit();
    }
}
