<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.contents_page.form.html_type.title_edit'|translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        [value]="value"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="updateContent($event)"
    >
        <div class="mb-4">
            Focus editor and press CTRL+SHIFT+F to enter/exit fullscreen mode <br>
            Alt+0 for more shortcuts
        </div>
    </app-form>
</app-modal>
