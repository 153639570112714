import {ElementRef, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as d3 from 'd3';
import * as dc from 'dc';
import {ChartConfig, ChartMargin, XAxis} from '../models/models';
import {GroupByFunctionsService} from './dashboards/group-by-functions.service';
import {DataholderService} from './dashboards/dataholder.service';
import {ColumnTypesService} from './dashboards/column-types.service';
import {ToastrService} from 'ngx-toastr';

// TODO: Find a better library
declare var Rainbow: any;

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private elRef: ElementRef;
    private dataCount: any;
    private crossFilter: any;
    private crossFilterSubject = new Subject();
    private redrawSubject = new Subject();
    private renderer: Renderer2;
    private readonly options = {
        defaultChartIdPrefix: 'chartDrawer' as string,
        defaultLocale: 'computer' as string,
        dcChartClass: '.dc-chart' as string,
        defaultMargin: {top: 30, right: 50, bottom: 50, left: 50} as ChartMargin,
        defaultColors: d3.scaleOrdinal(d3.schemeCategory10).range(),
        minHeight: 200 as number,
        minWidth: 200 as number,
        minTicks: 5 as number,
        onChartsRendered: () => {
            return 'v';
        }
    };
    private dataCountElement: HTMLElement;

    constructor(private dataholderService: DataholderService,
                private columnTypesService: ColumnTypesService,
                private groupByFunctionsService: GroupByFunctionsService,
                private rendererFactory: RendererFactory2,
                private toastrService: ToastrService) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    setElementRef(elRef: ElementRef) {
        this.elRef = elRef;
    }

    getOptions() {
        return this.options;
    }

    roundNumber(num: number) {
        return new Intl.NumberFormat([], {maximumFractionDigits: 2}).format(num);
    }

    chartTypeOf(chartConfig: ChartConfig, type: string) {
        return chartConfig.templateId === type;
    }

    isOrdinalLineChart(chartType: string, chartConfig: ChartConfig) {
        return chartType.indexOf('line') >= 0 && this.getColumnTypeByName(chartConfig.xAxis.columnName) === 'string';
    }

    getColumnTypeByName(name: string) {
        return _.get(this.dataholderService.getKnownColumns().get(name), 'type', 'string');
    }

    getCrossFilterSubject() {
        return this.crossFilterSubject;
    }

    setCrossFilter(crossFilter: any) {
        this.crossFilter = crossFilter;
        this.crossFilterSubject.next(crossFilter);
    }

    indexGroup(group: any) {
        return {
            all: () => {
                return group.all().map((kv: any, i: any) => {
                    return {
                        key: i,
                        value: kv.value
                    };
                });
            }
        };
    }

    resetAll() {
        dc.filterAll();
        dc.renderAll();
    }

    redrawAll() {
        dc.renderAll();
    }

    destroyAllBuilder(id: string) {
        // tslint:disable-next-line:max-line-length
        d3.select(`#${id} svg, #${id} .reset`).remove();
    }

    destroyAll() {
        dc.chartRegistry.clear();
    }

    clear() {
        this.crossFilter = undefined;
        this.destroyAll();
        this.resetAll();
    }

    setDataCountElement(nativeElement: HTMLElement) {
        this.dataCountElement = nativeElement;
    }

    ordinalFilterHandler(dcChart: any, keys: any, chartConfig: ChartConfig) {
        dcChart.filterHandler((dimension: any, filters: any) => {
            dimension.filter(null);
            if (filters[0]) {
                const low = filters[0][0];
                const high = filters[0][1];
                const lowKey = Math.ceil(low);
                const highKey = Math.floor(high);
                const range = keys.slice(lowKey, highKey + 1);
                if (range.length === 0) { // this means we filter no values so we have to show 0 results
                    dimension.filter([0, 0]);
                } else {
                    dimension.filterFunction(d => {
                        return range.indexOf(d) >= 0;
                    });
                }
            }
            return filters;
        });
    }

    createDataCount(): Subscription {
        return this.getCrossFilterSubject().subscribe((crossFilter: any) => {
            if (this.dataCountElement) {
                // @ts-ignore
                this.dataCount = dc.dataCount(this.dataCountElement);
            }
            if (crossFilter !== undefined) {
                if (this.dataCount) {
                    this.dataCount.dimension(crossFilter)
                        .group(crossFilter.groupAll())
                        .html({
                            // tslint:disable-next-line:max-line-length
                            some: '<strong id="filtered-count">%filter-count</strong> selected out of <strong>%total-count</strong> records' +
                                ' | <a class="reset-all">Reset All</a>',
                            all: 'All records selected. Please click on the graph to apply filters.'
                        });
                }
            }
        });
    }

    createXDimension(crossFilter: any, chartConfig: any) {
        return crossFilter.dimension((d: any) => {
            if (chartConfig.type === 'number') {
                return d;
            } else if (chartConfig.xAxis) {
                return d[chartConfig.xAxis.columnName];
            }
        });
    }

    createXUnits(dcChart: any, chartConfig: ChartConfig) {
        if (dcChart.xUnits) {
            dcChart.xUnits(this.getXUnitsForAxis(chartConfig.xAxis));
        }
    }

    createXScale(chartConfig: ChartConfig) {
        const type = this.getColumnTypeByName(chartConfig.xAxis.columnName);
        return this.columnTypesService.get(type).scale;
    }

    getXUnitsForAxis(xAxis: XAxis) {
        if (!this.dataholderService.getKnownColumns().has(xAxis.columnName)) {
            return this.columnTypesService.get('string').xUnits;
        }
        const column = this.dataholderService.getKnownColumns().get(xAxis.columnName);
        return this.columnTypesService.get(column.type).xUnits;
    }

    createX(dcChart: any, chartConfig: ChartConfig) {
        dcChart.x(this.createXScale(chartConfig));
    }

    sortArrayObject(data: []) {
        return data.sort((a: any, b: any) => {
                const aValue = a.value.finalVal !== undefined ? a.value.finalVal : a.value;
                const bValue = b.value.finalVal !== undefined ? b.value.finalVal : b.value;
                if (isNaN(aValue)) {
                    console.error(`${aValue} is not a number`);
                }
                if (isNaN(bValue)) {
                    console.error(`${bValue} is not a number`);
                }
                if (aValue > bValue) {
                    return -1;
                } else if (aValue < bValue) {
                    return 1;
                }
                return 0;
            }
        );
    }

    createSeries(xDimension: any, chartConfig: ChartConfig): any[] {
        const series = _.cloneDeep(chartConfig.series);
        series.forEach(dataSerie => {
            if (!(dataSerie.groupBy || {}).groupingFunction) {
                throw new Error('data serie group by function is missing');
            }
            const filterGroup = this.groupByFunctionsService.get(dataSerie.groupBy.groupingFunction).fn(xDimension, dataSerie);
            dataSerie.group = filterGroup.group;
            dataSerie.valueAccessor = filterGroup.valueAccessor;
        });
        return series;
    }

    associateDataWithAxis(chartConfig: ChartConfig, series: any[]): ChartConfig {
        if (!chartConfig.yAxis) {
            chartConfig.yAxis = {
                label: '',
                columnName: '',
                tickFormat: '',
                overrideTickFormat: '',
                elastic: false
            };
        }
        chartConfig.yAxis.columnName = series[0].columnName;
        const rightAxisSeries: any = _.filter(series, {useRightYAxis: true});
        if (rightAxisSeries.length > 0) {
            if (!chartConfig.rightYAxis) {
                chartConfig.rightYAxis = {
                    label: '',
                    columnName: '',
                    tickFormat: '',
                    overrideTickFormat: '',
                    elastic: false
                };
            }
            chartConfig.rightYAxis.columnName = rightAxisSeries[0].columnName;
        }
        return chartConfig;
    }

    setDimensions(chartConfig: ChartConfig, dcChart: any) {
        if (chartConfig.autoWidth) {
            // TODO: FIND SOLUTION FOR THIS
            const sidebarWidth = this.elRef.nativeElement.querySelector('.sidebar-left-expanded').width() + 40;
            const body = this.elRef.nativeElement.querySelector('body').width();
            dcChart.width(body - sidebarWidth);
        } else {
            if (chartConfig.width) {
                dcChart.width(chartConfig.width);
            }
        }

        if (chartConfig.height) {
            dcChart.height(chartConfig.height);
        }
    }

    setTitles(dcChart: any, chartConfig: ChartConfig) {
        dcChart.title((d: any) => {
            let value = d.value.finalVal ? d.value.finalVal : d.value;
            if (value.count !== undefined) {
                value = value.count;
            }
            if (value.finalVal !== undefined) {
                value = value.finalVal;
            }
            let title = '';
            const key = d.key;
            if (isNaN(value)) {
                value = 0;
            }
            if (chartConfig.title) {
                title = chartConfig.title + ' / ';
            }

            return title + key + ': ' + this.roundNumber(value);
        });
    }

    getAxisTickFormat(axis: any) {
        try {
            if (!axis) {
                throw new Error('axis is missing');
            }
            const type = this.getColumnTypeByName(axis.columnName);
            const dataType = this.columnTypesService.get(type);
            if (!dataType && axis.tickFormat) {
                return dataType.format(axis.tickFormat);
            }
            if (axis.overrideTickFormat) {
                return (d: any) => {
                    return d3.format(axis.overrideTickFormat)(d);
                };
            }
            return dataType.tickFormat;
        } catch (e) {
            this.toastrService.error('Wrong format, please try again');
        }
    }

    getAxisLabelFormat(axis: any) {
        if (!axis) {
            throw new Error('axis is missing');
        }
        const type = this.getColumnTypeByName(axis.columnName);
        const dataType = this.columnTypesService.get(type);
        if (!dataType && axis.labelFormat) {
            return dataType.format(axis.tickFormat);
        }
        if (axis.overrideTickFormat) {
            return (d: any) => {
                return d3.format(axis.overrideTickFormat)(d);
            };
        }
        return dataType.labelFormat;
    }

    formatAxisText(chart: any, selector: string, settings: any) {
        const axisText = chart.selectAll(selector);
        if (settings) {
            if (settings.dx) {
                axisText.attr('dx', settings.dx);
            }
            if (settings.dy) {
                axisText.attr('dy', settings.dy);
            }
            if (settings.transform) {
                axisText.attr('transform', settings.transform);
            }
        }
    }

    handleXAxisRotate(chart: any, chartConfig: ChartConfig) {
        let selector;
        if (chartConfig.type === 'bar' || chartConfig.type === 'line') {
            selector = '.axis.x text';
        } else {
            selector = '.axis text';
        }
        if (chartConfig.xAxisDegree >= 0) {
            chart
                .selectAll(selector)
                .attr('transform', () => {
                    const x = 0.0546875;
                    const y = 13.09375;
                    return `rotate(${chartConfig.xAxisDegree} ${x} ${y})`;

                })
                .attr('dx', function(this: HTMLElement) {
                    if (chartConfig.xAxisDegree > 0) {
                        return this.innerHTML.length * chartConfig.xAxisLabelOffset;
                    }
                });
        }
    }

    handleDataPoints(chartType: string, chartConfig: ChartConfig, dcChart: any) {
        if (chartType === 'line' && chartConfig.showDataPointsButton) {
            try {
                dcChart
                    .brushOn(false)
                    .renderDataPoints({radius: 5});
            } catch (e) {
                throw new Error('Error occurred: ' + e);
            }
        }
    }

    handleOtherAttributes(chartConfig: ChartConfig, dcChart: any, chartType: string) {
        if (!this.chartTypeOf(chartConfig, 'pie')) {
            dcChart.elasticX(true);
        }

        if (this.chartTypeOf(chartConfig, 'lineChart')) {
            dcChart.xAxisPadding('1%');
            dcChart.yAxisPadding('1%');
        }

        if (chartConfig.xAxis.label) {
            dcChart.xAxisLabel(chartConfig.xAxis.label);
        }
        if (chartConfig.yAxis) {
            if (chartConfig.elastic && dcChart.elasticY) {
                dcChart.elasticY(chartConfig.elastic);

            }
            if (chartConfig.yAxis.label) {
                dcChart.yAxisLabel(chartConfig.yAxis.label);
            }
        }
        if (chartConfig.rightYAxis && dcChart.rightYAxisLabel) {
            if (chartConfig.rightYAxis.label) {
                dcChart.rightYAxisLabel(chartConfig.rightYAxis.label);
            }
            chartConfig.rightYAxis.overrideTickFormat = chartConfig.yAxis.overrideTickFormat;
            dcChart.rightYAxis().tickFormat(this.getAxisTickFormat(chartConfig.rightYAxis));
        }
        if (_.isBoolean(chartConfig.renderHorizontalGridLines) && dcChart.renderHorizontalGridLines) {
            dcChart.renderHorizontalGridLines(chartConfig.renderHorizontalGridLines);
        }
        if (_.isBoolean(chartConfig.renderVerticalGridLines) && dcChart.renderVerticalGridLines) {
            dcChart.renderVerticalGridLines(chartConfig.renderVerticalGridLines);
        }

        if (!this.chartTypeOf(chartConfig, 'pie') && chartConfig.xAxis.tickFormat && !this.isOrdinalLineChart(chartType, chartConfig)) {
            dcChart.xAxis().tickFormat(this.getAxisTickFormat(chartConfig.xAxis));
        } else if (this.chartTypeOf(chartConfig, 'row-string')) {
            dcChart.label(this.getAxisLabelFormat(chartConfig.xAxis));
        }

        if (dcChart.yAxis) {
            dcChart.yAxis().tickFormat(this.getAxisTickFormat(chartConfig.yAxis));
            if (dcChart.height() <= this.options.minHeight) {
                dcChart.yAxis().ticks(this.options.minTicks);
            }
        }
        if (dcChart.width() <= this.options.minWidth) {
            try {
                if (dcChart.xAxis) {
                    dcChart.xAxis().ticks(this.options.minTicks);
                }
            } catch (e) {
                throw new Error('Error occurred: ' + e);
            }
        }
        if (chartConfig.rightYAxis && dcChart.rightYAxis) {
            dcChart.rightYAxis().tickFormat(this.getAxisTickFormat(chartConfig.rightYAxis));
        }
    }

    handleEvents(dcChart: any, chartConfig: ChartConfig, series: Array<any>) {
        dcChart.on('pretransition', (chart: any) => {
            if (chartConfig.type === 'line') {
                d3.select('#zero-line' + chartConfig.id).remove();
                if (series.length > 1) {
                    series.forEach((serie, index) => {
                        dcChart.selectAll('.sub._' + index + ' > .chart-body > .stack-list > .stack._0 > path')
                            .style('stroke-width', series[index].lineWidth + 'px');
                    });
                } else {
                    dcChart.selectAll('.stack._0 > path.line').style('stroke-width', series[0].lineWidth + 'px');
                }
            }
        });
        dcChart.on('renderlet', (chart: any) => {
            d3.select('#zero-line' + chartConfig.id).remove();
            this.formatAxisText(chart, 'g.x text', chartConfig.xAxis.tickLabelRenderSettings);
            chart.svg().select('.chart-body').attr('clip-path', null);
            this.handleXAxisRotate(chart, chartConfig);
            if (chartConfig.type === 'line') {
                const line = d3.line()
                    .x((d: any) => {
                        return dcChart.x()(d.x);
                    })
                    .y((d: any) => {
                        return dcChart.y()(d.y);
                    });
                let path;
                if (series.length < 2) {
                    // @ts-ignore
                    path = line([{x: 0, y: 0}, {x: dcChart.xAxisMax(), y: 0}]);
                } else {
                    // @ts-ignore
                    path = line([{x: 0, y: 0}, {x: 1, y: 0}]).replace('MNaN', 'M0')
                        .replace('LNaN', 'L' + dcChart.xAxisLength());
                }
                dcChart.select('.stack._0').append('path')
                    .attr('d', path)
                    .style('stroke', '#a8a8a8')
                    .style('stroke-width', 0.5)
                    .attr('id', 'zero-line' + chartConfig.id);

            }
        });

        dcChart.on('postRender', this.options.onChartsRendered);
        // dcChart.on("postRender", onChartsRendered);
    }

    setMargins(chartConfig: ChartConfig, dcChart: any) {
        const margin = _.extend({}, this.options.defaultMargin);
        if (!_.isEmpty(chartConfig.margin)) {
            if (chartConfig.margin.top) {
                margin.top = chartConfig.margin.top;
            }
            if (chartConfig.margin.right) {
                margin.right = chartConfig.margin.right;
            }
            if (chartConfig.margin.bottom) {
                margin.bottom = chartConfig.margin.bottom;
            }
            if (chartConfig.margin.left) {
                margin.left = chartConfig.margin.left;
            }
            if (
                !this.chartTypeOf(chartConfig, 'pie') &&
                !this.chartTypeOf(chartConfig, 'map') &&
                !this.chartTypeOf(chartConfig, 'table') &&
                !this.chartTypeOf(chartConfig, 'pivot-table')
            ) {
                dcChart.margins(margin);
            }
        }
        return margin;
    }

    handleCompositeChart(chartConfig: ChartConfig, series: Array<any>, chartType: string, dcChart: any, margins: ChartMargin) {
        const charts = [] as Array<any>;
        series.forEach((serie, index) => {
            const innerChart = dc[chartType + 'Chart'](dcChart)
                .group(serie.group, serie.columnName);

            innerChart.useRightYAxis(serie.useRightYAxis);

            if (serie.color) {
                innerChart.ordinalColors([serie.color]);
            } else {
                this.options.defaultColors[0] = '#4c6c9c';
                this.options.defaultColors[1] = '#f93';
                innerChart.ordinalColors([this.options.defaultColors[index]]);
            }
            if (serie.valueAccessor) {
                innerChart.valueAccessor(serie.valueAccessor);
            }
            innerChart.margins(margins);
            this.handleLineStyle(serie, innerChart);
            if (chartConfig.showDataPointsButton) {
                innerChart.renderDataPoints({radius: 5});
            }
            charts.push(innerChart);
        });
        this.setTitles(dcChart, chartConfig); // This should be called BEFORE .compose!!!
        dcChart
            .brushOn(false)
            ._rangeBandPadding(1) // https://github.com/dc-js/dc.js/issues/662
            .compose(charts);
    }

    handleLineStyle(serie: any, dcChart: any) {
        if (serie.lineStyle === 'Dashed') {
            // @ts-ignore
            dcChart.dashStyle([5]);
        } else if (serie.lineStyle === 'Dotted') {
            // @ts-ignore
            dcChart.dashStyle([1, 2]);
        }
    }

    handleSeries(series: Array<any>, dcChart: any) {
        const chartColors = [] as Array<any>;
        series.forEach((serie, i) => {
            if (i === 0) {
                dcChart.group(series[i].group, series[i].columnName);
            } else {
                dcChart.stack(series[i].group, series[i].columnName);
            }
            if (series[i].color) {
                chartColors.push(series[i].color);
            } else {
                chartColors.push(this.options.defaultColors[i]);
            }
            if (series[i].valueAccessor) {
                dcChart.valueAccessor(series[i].valueAccessor);
            }
            this.handleLineStyle(serie, dcChart);
        });
        if (series.length > 1) {
            dcChart.ordinalColors(['#4c6c9c', '#f93']);
        } else {
            dcChart.ordinalColors(chartColors);
        }
    }

    handleNewLine(chartConfig: ChartConfig, elementId: string) {
        // if (_.isBoolean(chartConfig.addInNewLine)) {
        //     // TODO: FIND SOLUTION FOR THIS
        //     if (chartConfig.addInNewLine) {
        //         if (this.chartTypeOf(chartConfig, 'table') || this.chartTypeOf(chartConfig, 'pivot-table')) {
        //             this.elRef.nativeElement.querySelector(elementId).parent('div').prev('#paging').addClass('new-line');
        //         } else {
        //             this.elRef.nativeElement.querySelector(elementId).addClass('new-line');
        //         }
        //     } else {
        //         if (this.chartTypeOf(chartConfig, 'table') || this.chartTypeOf(chartConfig, 'pivot-table')) {
        //             this.elRef.nativeElement.querySelector(elementId).parent('div').prev('#paging').removeClass('new-line');
        //         } else {
        //             this.elRef.nativeElement.querySelector(elementId).removeClass('new-line');
        //         }
        //     }
        // }
    }

    setChartTitle(chartConfig: ChartConfig, elementId: string, margin: {} & (ChartMargin | any)) {
        if (chartConfig.title) {
            // TODO: FIND SOLUTION FOR THIS
            if (this.chartTypeOf(chartConfig, 'table') || this.chartTypeOf(chartConfig, 'pivot-table')) {
                this.elRef.nativeElement.querySelector(elementId).closest('div').find('.chart-title').remove();
                // tslint:disable-next-line:max-line-length
                this.elRef.nativeElement.querySelector(elementId).closest('div').prepend('<strong class=\'chart-title\' style=\'margin-left:' + margin.left + 'px\'>' + chartConfig.title + '</strong>');
            }
            // else {
            //     // tslint:disable-next-line:max-line-length
            //     d3.select(elementId + ' .chart-title').remove();
            // tslint:disable-next-line:max-line-length
            //     d3.select(elementId).insert('strong', elementId).attr('class', 'chart-title').text(chartConfig.title).style('margin-left', margin.left + 'px').style('float', 'left');
            // tslint:disable-next-line:max-line-length
            //     if (!this.chartTypeOf(chartConfig, 'line-number') && !this.chartTypeOf(chartConfig, 'bar-string') && !this.chartTypeOf(chartConfig, 'row-string')) {
            //         d3.select(elementId).insert('div', elementId).classed('clearfix', true);
            //     }
            // }
        }
    }

    handleOffsets(chartConfig: ChartConfig, dcChart: any) {
        if (chartConfig.labelOffsetX) {
            dcChart.labelOffsetX(chartConfig.labelOffsetX);
        }
        if (chartConfig.labelOffsetY) {
            dcChart.labelOffsetY(chartConfig.labelOffsetY);
        }
    }

    handleLegend(chartConfig: ChartConfig, dcChart: any) {
        if ((chartConfig.showLegend !== false) && !_.isEmpty(chartConfig.legend)) {
            const legend = dc.legend();
            if (chartConfig.legend.x) {
                legend.x(chartConfig.legend.x);
            }
            if (chartConfig.legend.y) {
                legend.y(chartConfig.legend.y);
            }
            if (chartConfig.legend.itemHeight) {
                legend.itemHeight(chartConfig.legend.itemHeight);
            }
            if (chartConfig.legend.gap) {
                legend.gap(chartConfig.legend.gap);
            }
            dcChart.legend(legend);
        }
    }

    appendFilteringDetails(chartType: string, chartConfig: ChartConfig, series: any[], dcChart: any) {
        if (chartConfig.renderFilterDetails) {
            if (this.isOrdinalLineChart(chartType, chartConfig) && series.length < 2) {
                const group = series[0].group;
                dcChart.xAxisKeys = group.all().map(dc.pluck('key')).slice();
                dcChart.filterPrinter((filters: any) => {
                    if (filters.length > 0) {
                        const filter = filters[0];
                        let firstFilter;
                        let secondFilter;
                        let s = '';
                        const range = function findIntegerFromRange(x: number, y: number): any {
                            x = Math.round(x * 10) / 10;
                            y = Math.floor(y * 10) / 10;
                            const ret = [];
                            while (x < y) {
                                // @ts-ignore
                                if (x.toFixed(1) % 1 === 0) {
                                    ret.push(parseInt(x.toFixed(1), 10));
                                }
                                x += 0.1;
                            }
                            return ret;
                        };
                        const filtersIndexes = range(filter[0], filter[1]);
                        if (filtersIndexes.length === 0) {
                            return null;
                        }
                        firstFilter = dcChart.xAxisKeys[filtersIndexes[0]];
                        secondFilter = dcChart.xAxisKeys[filtersIndexes[filtersIndexes.length - 1]];
                        s += 'Filter: ' + firstFilter + ' -> ' + secondFilter;
                        return s;
                    }
                });
            } else {
                dcChart.filterPrinter((filters: any) => {
                    if (filters.length > 0) {
                        const filter = filters[0];
                        let s = '';
                        s += 'Filter: ' + Intl.NumberFormat().format(filter[0]) + ' -> ' + Intl.NumberFormat().format(filter[1]);
                        return s;
                    }
                });
            }
        }
    }

    handleFiltering(chartConfig: ChartConfig, dcChart: any) {
        if (chartConfig.disableFiltering) {
            try {
                dcChart
                    .brushOn(false);
                dcChart.filter = () => {
                };
            } catch (e) {
                throw new Error('Error occurred: ' + e);
            }
        }
    }

    handleTickMarks(chartConfig: ChartConfig) {
        setTimeout(() => {
            const chart = this.elRef.nativeElement;
            // TODO: FIND SOLUTION
            if (chartConfig.type === 'row') {
                if (chartConfig.showXTick) {
                    this.renderer.removeClass(chart, 'remove-x-ticks-row');
                    // chart.removeClass('remove-x-ticks-row');
                } else {
                    this.renderer.addClass(chart, 'remove-x-ticks-row');
                    // chart.addClass('remove-x-ticks-row');
                }
            } else {
                if (chartConfig.showXTick) {
                    this.renderer.removeClass(chart, 'remove-x-ticks');
                    // chart.removeClass('remove-x-ticks');
                } else {
                    this.renderer.addClass(chart, 'remove-x-ticks');
                    // chart.addClass('remove-x-ticks');
                }

                if (chartConfig.showYTick) {
                    this.renderer.removeClass(chart, 'remove-y-ticks');
                    // chart.removeClass('remove-y-ticks');
                } else {
                    this.renderer.addClass(chart, 'remove-y-ticks');
                    // chart.addClass('remove-y-ticks');
                }
            }
        }, 1000);
    }

    handleSorting(chartConfig: ChartConfig, dcChart: any) {
        if (chartConfig.sorting === 'ASC') {
            dcChart.ordering((d: any) => {
                if (d.value.finalVal != null) {
                    return +d.value.finalVal;
                }
                return +d.value;
            });
        } else if (chartConfig.sorting === 'DESC') {
            dcChart.ordering((d: any) => {
                if (d.value.finalVal != null) {
                    return -d.value.finalVal;
                }
                return -d.value;
            });
        } else {
            dcChart.ordering((d: any) => {
                if (chartConfig.overrideSorting != null) {
                    const order = chartConfig.overrideSorting.split('|');
                    const index = order.indexOf(d.key);
                    return index;
                }
                return d.key;
            });
        }
    }

    handleColors(chartType: string, dcChart: any, series: Array<any>, chartConfig: ChartConfig) {
        if (chartType === 'pie' || chartType === 'geoChoroplethChart' || chartType === 'bar' || chartType === 'row') {
            let colorsCount = dcChart.data().length;
            if (chartType === 'pie') {
                colorsCount = series[0].group.all().length;
                if (colorsCount > dcChart.cap()) {
                    colorsCount += 1;
                }
            }
            if (chartType === 'row') {
                colorsCount = dcChart.data().length;
            } else if (chartType === 'bar') {
                try {
                    colorsCount = dcChart.data()[0].values.length;
                } catch (e) {
                    colorsCount = dcChart.data().length;
                }
            } else if (chartType === 'geoChoroplethChart') {
                colorsCount = 50;
            }
            const rainbow = new Rainbow();
            const colors: any = [];
            // START Support backward compatibility
            if (chartConfig.paletteFirstColor === undefined) {
                chartConfig.paletteFirstColor = '#058db5';
            }
            if (chartConfig.paletteLastColor === undefined) {
                chartConfig.paletteLastColor = '#058db5';
            }
            // END Support backward compatibility
            rainbow.setSpectrum(chartConfig.paletteFirstColor, chartConfig.paletteLastColor);
            if (colorsCount <= 1) {
                colorsCount = 2;
            }
            rainbow.setNumberRange(0, colorsCount - 1);
            for (let i = 0; i < colorsCount; i += 1) {
                colors.push('#' + rainbow.colourAt(i));
            }
            let data = dcChart.data();
            if (chartType === 'bar' || chartType === 'pie') {
                data = series[0].group.all();
                data = series[0].group.top(Number.POSITIVE_INFINITY);
                if (chartType === 'pie') {
                    data.push(dcChart.data()[dcChart.data().length - 1]);
                    data = this.sortArrayObject(data);
                }
            }
            dcChart.colorAccessor((d: any): any => {
                if (d === undefined) {
                    console.error('D Value is undefined, potential bug...');
                    return '#ccc';
                }
                const value = d.value ? series[0].valueAccessor(d) : d;
                if (value === undefined) {
                    console.error('Value is undefined, potential bug...');
                }
                // tslint:disable-next-line:only-arrow-functions
                const findWithAttr = function(array: any, attr: any, val: any) {
                    if (val === undefined) {
                        return -2;
                    }
                    for (let i = 0; i < array.length; i += 1) {
                        if (array[i][attr] === val) {
                            return i;
                        }
                    }
                    // last color accessor is the legend color which is the array of all objects and will be black
                    return -1;
                };
                data = this.sortArrayObject(data);
                return findWithAttr(data, 'key', d.key);
            });
            // tslint:disable-next-line:only-arrow-functions
            dcChart.colors(function(colorAccessorValue: any) {
                if (colorAccessorValue === -1) {
                    return '#000000';
                } else if (colorAccessorValue === -2) {
                    return '#ffffff';
                } else if (colors[colorAccessorValue] === undefined) {
                    return colors[colors.length - 1];
                }
                return colors[colorAccessorValue];
            });
        }
    }

    handleResetButton(chartConfig: ChartConfig, elementId: string, dcChart: any) {
        if (_.isBoolean(chartConfig.showResetButton)) {
            if (chartConfig.showResetButton) {
                dcChart.controlsUseVisibility(true);
                const element = d3.select(elementId);
                // TODO: FIND SOLUTION FOR THIS
                if (chartConfig.renderFilterDetails) {
                    if (!chartConfig.title) {
                        // tslint:disable-next-line:max-line-length
                        element.insert('span', elementId).attr('class', 'filter').style('float', 'left').style('margin-left', chartConfig.margin.left + 'px');
                    } else {
                        element.insert('span', elementId).attr('class', 'filter').style('float', 'left').style('margin-left', '5px');
                    }
                    element.insert('a', elementId).attr('class', 'reset').attr('style', 'margin-left: 10px').text('Reset');
                    element.insert('div', elementId).attr('class', 'clearfix');
                } else {
                    element.select('.clearfix').remove();
                    if (!chartConfig.title) {
                        // tslint:disable-next-line:max-line-length
                        element.insert('a', elementId).attr('class', 'reset pull-left').attr('style', 'margin-left: ' + chartConfig.margin.left + 'px').text('Reset');
                    } else {
                        element.insert('a', elementId).attr('class', 'reset pull-left').attr('style', 'margin-left: 10px').text('Reset');
                    }
                    element.insert('div', elementId).classed('clearfix', true);
                }
                dcChart.turnOnControls();
                element.select('a.reset').on('click', () => {
                    dcChart.filterAll();
                    dc.redrawAll();
                });
                element.select('a.reset').style('visibility', 'hidden').style('cursor', 'pointer');
            }
        }
    }

    setChartSubtitle(chartConfig: ChartConfig, elementId: string, margin: {} & (ChartMargin | any)) {
        if (chartConfig.subtitle) {
            // TODO: FIND SOLUTION FOR THIS
            if (this.chartTypeOf(chartConfig, 'table') || this.chartTypeOf(chartConfig, 'pivot-table')) {
                this.elRef.nativeElement.querySelector(elementId).closest('div').find('.chart-subtitle').remove();
                // tslint:disable-next-line:max-line-length
                this.elRef.nativeElement.querySelector(elementId).closest('div').prepend('<div class=\'chart-subtitle\' style=\'margin-left:' + margin.left + 'px\'>' + chartConfig.subtitle + '</div>');
            } else {
                d3.select(elementId).insert('div', elementId).classed('clearfix', true);
                d3.select(elementId).insert('div', elementId).text(chartConfig.subtitle).style('margin-left', margin.left + 'px');
                d3.select(elementId).insert('div', elementId).classed('clearfix', true);
            }
        }
    }

    renderChart(chartType: string, dcChart: any, chartConfig: ChartConfig, series: Array<any>) {
        if (this.isOrdinalLineChart(chartType, chartConfig) && series.length < 2) {
            const group = series[0].group;
            const keys = group.all().map(dc.pluck('key')).slice();
            dcChart
                .x(d3.scaleLinear().domain([-0.5, keys.length - 0.5]))
                .xUnits(dc.units.integers)
                .group(this.indexGroup(group), series[0].columnName)
                .xAxis().tickFormat((i: number) => {
                    return keys[i];
                });
            this.ordinalFilterHandler(dcChart, keys, chartConfig);
        } else {
            if (!this.chartTypeOf(chartConfig, 'bar-string')) {
                dcChart.group(series[0].group, series[0].columnName);
            }
            if (!this.chartTypeOf(chartConfig, 'pie') && !this.chartTypeOf(chartConfig, 'row-string')) {
                this.createX(dcChart, chartConfig);
                this.createXUnits(dcChart, chartConfig);
            }
        }

        this.handleOtherAttributes(chartConfig, dcChart, chartType);
        this.handleEvents(dcChart, chartConfig, series);
        this.handleDataPoints(chartType, chartConfig, dcChart);
        this.handleFiltering(chartConfig, dcChart);
        // if not composite
        if (series.length === 1) {
            this.setTitles(dcChart, chartConfig);
        } else {
            this.handleMaxTicks(dcChart, series, chartConfig);
        }
        this.handleTickMarks(chartConfig);
        this.handleSorting(chartConfig, dcChart);
        if (series.length === 1) {
            this.handleColors(chartType, dcChart, series, chartConfig);
        }
        if (chartConfig.renderLabel) {
            dcChart.renderLabel(chartConfig.renderLabel);
        }

        dcChart.render();
    }

    handleMaxTicks(dcChart: any, series: Array<any>, chartConfig: ChartConfig) {
        const showTicksEvery = chartConfig.xAxis.showTicksEvery || 1;
        const group = series[0].group;
        const keys = group.all().map(dc.pluck('key')).slice();
        keys.forEach((value, i) => {
            if ((i % showTicksEvery) !== 0) {
                delete keys[i];
            }
        });
        dcChart.xAxis().tickValues(keys);
    }
}
