export const AutoHeight = (elem: any, init = false) => {
    elem.style.height = '1px';
    elem.style.overflowY = 'hidden';

    const h = elem.scrollHeight;

    if (h > 128) {
        elem.style.height = '128px';
        elem.style.overflowY = 'auto';

        if (!init) {
            elem.scrollTop = elem.scrollHeight;
        }
    } else {
        elem.style.height = (elem.scrollHeight) + 'px';
    }
};

