import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DisplayError, SelectValue} from '../../models/models';
import {FieldsService} from '../../services/fields.service';

@Component({
    selector: 'app-radio-select',
    templateUrl: './radio-select.component.html',
    styleUrls: ['./radio-select.component.scss'],
    providers: [
        FieldsService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioSelectComponent),
            multi: true
        }
    ]
})
export class RadioSelectComponent implements OnInit, ControlValueAccessor {
    public field;
    private currentValue;
    public currentErrors: string[];

    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() isFieldValid: boolean;
    @Input() options: SelectValue[];
    @Input() disabled: boolean;

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: number | null) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    constructor(public fieldService: FieldsService) {
    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.field = this.fieldService.getUserField(this.fieldName, this.formName);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.onChange(this.value);
    }
}
