<div
    *ngIf="column"
    class="info-row"
>
    <div class="info-label">
        <i
            *ngIf="column.icon"
            [ngClass]="column.icon"
            class="icon icon-size-10 color-light-green"
            style="vertical-align: -1px;"
        ></i>
        <span> {{column.label | translate}}</span>
        <i
            (click)="open()"
            *ngIf="!showForm && (security.isUserSubordinate || security.isProfileOwner)"
            class="icon icon-size-7 ion-edit color-light-green pointer float-right"
            style="vertical-align: 0; margin-left: 5px;"
        ></i>
    </div>

    <ng-container *ngIf="!showForm">
        <app-user-profile-row-value
            *ngIf="profile.timeZone"
            [itemValue]="profile.timeZone"
            key="timeZone"
        >
        </app-user-profile-row-value>
        <app-user-profile-row-value
            *ngIf="profile.timeNow"
            [itemValue]="profile.timeNow"
            key="timeNow"
        >
        </app-user-profile-row-value>

        <ng-container *ngIf="security.isUserSubordinate || security.isProfileOwner">
            <app-user-profile-row-value
                *ngIf="profile.holidaysAllowance"
                [itemValue]="profile.holidaysAllowance"
                [label]="'messages.list_values.fields.holidaysAllowance'|translate"
            >
            </app-user-profile-row-value>

        </ng-container>

        <div class="item-row">
            <span
                class="item-label"
                style="display: inline-block; margin-bottom: 5px"
            >{{'messages.profile_page.work_time.typical_hours'|translate}}
                <i style="font-weight: normal">
                    ({{'messages.profile_page.work_time.time_converted'|translate}})</i>:</span>
            <table class='table table-hover table-bordered'>
                <thead>
                <tr>
                    <th>{{'messages.profile_page.work_time.weekday'|translate}}</th>
                    <th>{{'messages.profile_page.work_time.hours'|translate}} (23:59)</th>
                    <th>{{'messages.profile_page.work_time.when'|translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let day of days">
                    <td>
                        {{('messages.global.days_of_week.' + day)|translate}}
                    </td>
                    <td>
                        <span *ngIf="workingHours[day + 'FromConverted'] && workingHours[day + 'ToConverted']">
                            {{workingHours[day + 'FromConverted']}}-{{workingHours[day + 'ToConverted']}}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="workingHours[day + 'Frequency']">
                            {{('messages.profile_page.work_time.frequency_variants.' + workingHours[day + 'Frequency'])|translate}}
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="info-items">
            <app-user-profile-row-value
                *ngIf="workingHours.unavailableTime"
                [itemValue]="workingHours.unavailableTime"
                [label]="'messages.profile_page.work_time.unavailable_time'|translate"
            >
            </app-user-profile-row-value>
            <app-user-profile-row-value
                *ngIf="workingHours.hoursPerWeek" [itemValue]="workingHours.hoursPerWeek"
                [label]="'messages.profile_page.work_time.hours_per_week'|translate"
            >
            </app-user-profile-row-value>
            <app-user-profile-row-value
                *ngIf="workingHours.workTimePercentage"
                [itemValue]="('messages.profile_page.work_time.working_hours_variants.' + workingHours.workTimePercentage)|translate"
                [label]="'messages.profile_page.work_time.weekly_working_time'|translate"
            >
            </app-user-profile-row-value>
        </div>
    </ng-container>

    <form
        (ngSubmit)="updateWorkingHours()"
        *ngIf="showForm"
        [formGroup]="form"
        class="form-style-bordered-inputs"
    >
        <app-display-input-errors
            [displayErrors]="errors"
            errorKey='_global'
        ></app-display-input-errors>

        <div class="mb-20">
            <app-select
                [customConfig]="{
                id: 'timezone',
                label: 'messages.list_values.fields.timeZone',
                placeholder: '---'
            }"
                [options]="timeZones"
                formControlName="timeZone"
            >
                <app-display-input-errors
                    [displayErrors]="errors"
                    errorKey="timeZone"
                ></app-display-input-errors>
            </app-select>
            <ng-container *ngIf="security.isUserSubordinate">
                <div class="form-group">
                    <label
                        class="control-label"
                    >{{'messages.list_values.fields.holidaysAllowance'|translate}}</label>
                    <input
                        formControlName="holidaysAllowance"
                        name="holidaysAllowance"
                        class="form-control"
                        type="number"
                    />
                    <app-input-errors [control]="form.controls.holidaysAllowance"></app-input-errors>
                    <app-display-input-errors
                        [displayErrors]="errors"
                        errorKey='holidaysAllowance'
                    ></app-display-input-errors>
                </div>
            </ng-container>
        </div>

        <ng-container formGroupName="workingHours">
            <div
                *ngFor="let day of days"
                class="form-group mb-3"
            >
                <h4 class="control-label mb-2">
                    {{('messages.global.days_of_week.' + day)|translate}}
                </h4>
                <div class="time-pickers">
                    <div class="mr-3 flex-column">
                        <label>{{'messages.global.from'|translate}}</label>
                        <timepicker
                            [formControlName]="day + 'From'"
                            [showMeridian]="false"
                            minuteStep="15"
                        ></timepicker>
                    </div>
                    <div class="flex-column mr-3">
                        <label>{{'messages.global.to'|translate}}</label>
                        <timepicker
                            [formControlName]="day + 'To'"
                            [showMeridian]="false"
                            minuteStep="15"
                        ></timepicker>
                    </div>
                    <div class="flex-column">
                        <a
                            (click)="clearDay(day)"
                            class="btn btn-primary btn-sm mt-3"
                        >
                            {{'messages.global.clear'|translate}}
                        </a>
                    </div>
                </div>
                <div class="flex-column">
                    <label>
                        {{'messages.profile_page.work_time.frequency'|translate}}
                    </label>
                    <select
                        [formControlName]="day + 'Frequency'"
                        class="frequency"
                    >
                        <option
                            *ngFor="let frequency of frequencies"
                            [value]="frequency"
                        >
                            {{('messages.profile_page.work_time.frequency_variants.' + frequency)|translate}}
                        </option>

                    </select>
                </div>
                <div *ngIf="day === 'monday'">
                    <a (click)="replicateToAll()" class="btn btn-primary btn-sm" style="margin-right: 5px">
                        {{'messages.profile_page.work_time.replicate_for_all_days'|translate}}
                    </a>
                    <a (click)="replicateToWorkingDays()" class="btn btn-primary btn-sm">
                        {{'messages.profile_page.work_time.replicate_for_weekdays'|translate}}
                    </a>
                </div>
            </div>
            <app-auto-height-textarea
                [config]="{id: 'unavailableTime', label: 'messages.profile_page.work_time.unavailable_time'}"
                formControlName="unavailableTime"
            >

            </app-auto-height-textarea>
            <div class="form-group">
                <label
                    class="control-label"
                >
                    {{'messages.profile_page.work_time.hours_per_week'|translate}}
                </label>
                <input
                    (input)="checkHoursPerWeek($event.target.value)"
                    class="form-control"
                    formControlName="hoursPerWeek"
                    max="100"
                    min="0"
                    type="number"
                />
                <app-display-input-errors
                    [displayErrors]="errors"
                    [errorKey]="'unavailableTime'"
                ></app-display-input-errors>
            </div>
            <div class="form-group">
                <label
                    class="control-label"
                >
                    {{'messages.profile_page.work_time.weekly_working_time'|translate}}
                </label>
                <select
                    class="form-control"
                    formControlName="workTimePercentage"
                >
                    <option value="">---</option>
                    <option
                        *ngFor="let value of workingHoursVariants"
                        [value]="value"
                    >{{('messages.profile_page.work_time.working_hours_variants.' + value)|translate}}</option>
                </select>
                <app-display-input-errors
                    [displayErrors]="errors"
                    [errorKey]="'workTimePercentage'"
                ></app-display-input-errors>
            </div>
        </ng-container>
        <app-form-submited-loading *ngIf="submitted"></app-form-submited-loading>
        <button class="btn btn-sm btn-primary" type="submit">
            {{'messages.global.ok'|translate}}
        </button>
        &nbsp;
        <button (click)="close()" class="btn btn-sm btn-default" type="button">
            {{'messages.global.cancel'|translate}}
        </button>
    </form>
</div>
