import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../../../environments/environment';

const HttpTranslationsFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http, environment.apiURL + '/translations/', '.json');
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslationsFactory,
                deps: [HttpClient],
            },
            isolate: false
        })
    ],
    exports: [
        TranslateModule
    ]
})
export class TranslationsModule {
}
