import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {EntityProviderService} from 'src/app/services/entity-provider.service';
import {AdminUsersList, FormConfig, ProvidedEntity, SelectValue} from '../../../../models/models';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {MatchValidator} from '../../../../validators/matchValidators';
import {ApiService} from '../../../../services/api.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {AuthService} from '../../../../services/auth.service';
import {ROLE_SUPER_ADMIN} from '../../../../services/constants';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {LocaleService} from '../../../../services/locale.service';
import * as _ from 'lodash';

interface UserForm {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    language: string;
    timeZone: string;
    plainPassword?: string;
    repeatPassword?: string;
}

@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class UserModalComponent implements OnInit {
    @Input() modalType;
    @Output() closeModal = new EventEmitter();
    @Input() user: AdminUsersList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<UserForm>;

    private defaultFormConfig: FormConfig = {
        fields: [
            {
                name: 'firstName',
                label: 'messages.admin.users_page.form.user.fields.first_name.label',
                placeholder: 'messages.admin.users_page.form.user.fields.first_name.placeholder',
                id: 'user_create_firstName',
                type: 'text',
                propertyPath: 'first_name',
                validators: [Validators.required],
            },
            {
                name: 'lastName',
                label: 'messages.admin.users_page.form.user.fields.last_name.label',
                placeholder: 'messages.admin.users_page.form.user.fields.last_name.placeholder',
                id: 'user_create_lastName',
                type: 'text',
                propertyPath: 'last_name',
                validators: [Validators.required],
            },
            {
                name: 'email',
                label: 'messages.global.email',
                placeholder: 'messages.global.email',
                id: 'user_create_email',
                type: 'text',
                propertyPath: 'email',
                validators: [Validators.required, Validators.email],
            },
            {
                name: 'company',
                label: 'messages.admin.users_page.form.user.fields.company.label',
                placeholder: 'messages.admin.users_page.form.user.fields.company.placeholder',
                id: 'user_create_company',
                type: 'provided-entity-select',
                propertyPath: 'company.id',
                validators: [Validators.required],
            },
            {
                name: 'language',
                label: 'messages.admin.users_page.form.user.fields.locale.label',
                placeholder: 'messages.admin.users_page.form.user.fields.locale.placeholder',
                id: 'user_create_language',
                type: 'select',
                propertyPath: 'locale',
                validators: [Validators.required],
            },
            {
                name: 'timeZone',
                label: 'messages.admin.users_page.form.user.fields.time_zone.label',
                placeholder: 'messages.admin.users_page.form.user.fields.time_zone.placeholder',
                id: 'user_create_timeZone',
                type: 'select',
                propertyPath: 'time_zone_code',
                validators: [Validators.required],
            },
            {
                name: 'plainPassword',
                label: 'messages.admin.users_page.form.user.fields.password.first.label',
                placeholder: 'messages.admin.users_page.form.user.fields.password.first.label',
                id: 'user_create_password',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required, Validators.minLength(8)],
            },
            {
                name: 'repeatPassword',
                label: 'messages.admin.users_page.form.user.fields.password.second.label',
                placeholder: 'messages.admin.users_page.form.user.fields.password.second.label',
                id: 'user_create_repeatPassword',
                type: 'text',
                inputType: 'password',
                validators: [Validators.required],
            },
        ],
        validators: [MatchValidator('plainPassword', 'repeatPassword')]
    };

    formConfig: FormConfig;
    lists: { [key: string]: any } = {};

    constructor(
        private entityProvider: EntityProviderService,
        private apiService: ApiService,
        private authService: AuthService,
        public  formLoadingServiceService: FormLoadingServiceService,
        private localeService: LocaleService) {
    }

    ngOnInit() {
        this.initForm();

        this.formLoadingServiceService.init(3);
        this.entityProvider.getCompanies().subscribe((data: ProvidedEntity[]) => {
            this.formLoadingServiceService.eventLoaded();
            this.lists.company = data;
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
        this.entityProvider.getLocales().subscribe((data: SelectValue[]) => {
            this.formLoadingServiceService.eventLoaded();
            this.lists.language = data;
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
        this.entityProvider.getTimezones(true).subscribe((data: SelectValue[]) => {
            this.formLoadingServiceService.eventLoaded();
            this.lists.timeZone = data;
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    initForm() {
        const formConfig = _.cloneDeep<FormConfig>(this.defaultFormConfig);

        if (this.user) {
            const removeFields = ['plainPassword', 'repeatPassword'];
            formConfig.fields = formConfig.fields.filter(item => !removeFields.includes(item.name));
            formConfig.validators = [];
        }

        if (!this.withCompanyField()) {
            formConfig.fields = formConfig.fields.filter(item => item.name !== 'company');
        }

        this.formConfig = formConfig;
    }

    addUser(formValue: UserForm) {
        const password = {
            first: formValue.plainPassword,
            second: formValue.repeatPassword
        };

        const data = {
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: undefined,
            company: undefined,
            locale: formValue.language,
            timeZone: formValue.timeZone,
            plain_password: password.first && password.second ? password : undefined
        };

        if (!this.user || !this.user.from_ldap) {
            data.email = formValue.email;
        }

        if (this.withCompanyField()) {
            data.company = formValue.company;
        }

        const request = this.user
            ? this.apiService.updateUser(this.user.id, data)
            : this.apiService.createUser(data);

        request.subscribe((response) => {
            if (this.user && this.user.id === this.authService.getLoggedUser().id) {
                this.authService.refreshUserInfo(() => {
                    this.localeService.setLocale(this.authService.getLoggedUser().locale);
                });
            }

            this.closeModalWindow({...response});
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow(value = null) {
        return this.closeModal.emit({type: this.user ? 'edit' : 'add', value});
    }

    withCompanyField() {
        return this.authService.isGranted(ROLE_SUPER_ADMIN);
    }
}
