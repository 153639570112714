<div class="form-group">
    <label
        [for]="field.id"
        [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
        class="control-label"
    >{{field.label|translate}}</label>
    <textarea
        [(ngModel)]="value"
        [id]="field.id"
        [name]="field.name"
        [placeholder]="field.placeholder|translate"
        class="form-control"
        style="resize: vertical; height: auto"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
    ></textarea>
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
