<div class="message-action mb-4">
    <div *ngIf="withAvatar" class="user-thumbnail d-none d-lg-block">
        <img
            [alt]="sender"
            [src]="avatar || '../../../../../../assets/img/no-avatar.png'"
        >
    </div>
    <div class="message-box form-style-bordered-inputs">
        <div style="position: relative">
            <div
                class="mention-autocomplete"
                style="position: absolute"
                *ngIf="possibleUsers?.length"
                [ngStyle]="caretPosition"
            >
                <div *ngFor="let user of possibleUsers" (click)="insertMention(user)">{{user.name}} ({{user.username}}
                    )
                </div>
            </div>
            <app-auto-height-textarea
                [config]="{id: 'add_comment', placeholder: ('messages.comments.add_comment'|translate) }"
                [formControl]="form.get('body')"
                [isFieldValid]="!form.get('body').dirty || form.get('body').valid"
                (keydown)="checkSubmit($event)"
            >
                <div class="help-block" *ngIf="form.get('body').dirty && form.get('body').invalid">
                    <ul class="list-unstyled">
                        <li>
                            <i class="ion-android-alert"></i>&nbsp;
                            <span>This field is required!</span>
                        </li>
                    </ul>
                </div>
            </app-auto-height-textarea>
        </div>

        <div class="attachments mb-3">
            <div>
                <div *ngFor="let file of existingFiles, index as i">
                    <a [href]="file|commentAttachmentUrl" download><i class="icon ion-document-text"></i> {{file.name}}
                    </a>
                    <button
                        type="button"
                        class="ml-3 btn btn btn-square btn-xs btn-danger"
                        (click)="removeExistingFile(file.id, i)"
                    >
                        <i
                            class="icon ion-ios-trash"
                        ></i> {{'messages.global.delete'|translate}}
                    </button>
                </div>
            </div>

            <div class="attachment form-group" *ngFor="let control of filesForm.controls, index as i">
                <div class="a-label">{{'messages.attachments.new'|translate}}</div>
                <div class="d-flex flex-column flex-sm-row justify-content-between">
                    <div>
                        <label
                            [for]="'att_'+ i"
                            class="mt-2"
                        >
                            <span class="btn btn btn-square btn-primary btn-xs mt-0">
                                {{'messages.attachments.browse'|translate}}...
                            </span>
                            <span class="a-filename ml-5" *ngIf="control?.value">{{control?.value.name}}</span>
                        </label>
                        <input
                            type="file"
                            class="form-control-file d-none"
                            [id]="'att_'+ i"
                            (change)="control.setValue($event.target.files.length ? $event.target.files[0] : null)"
                        >
                        <div class="help-block mt-2" *ngIf="(errors && errors[i]) || control.invalid">
                            <ul class="list-unstyled">
                                <li *ngIf="control.errors.invalid_mime">
                                    <i class="ion-android-alert"></i>&nbsp;
                                    <span>This file type is not allowed!</span>
                                </li>
                                <li *ngIf="errors[i]">
                                    <i class="ion-android-alert"></i>&nbsp;
                                    <span>{{errors[i]}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="mt-2 btn btn btn-square btn-xs btn-danger" (click)="removeFile(i)">
                            <i
                                class="icon ion-ios-trash"
                            ></i> {{'messages.global.delete'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="message-submit">
            <button
                type="button"
                class="btn btn btn-square btn-xs btn-primary float-sm-right mr-3 mr-sm-0"
                *ngIf="existingFiles.length + filesForm.controls.length < 5"
                (click)="addFile()"
            >
                <i
                    class="icon ion-plus-circled mr-1"
                ></i> {{'messages.attachments.add'|translate}}
            </button>

            <button (click)="submit()" class="btn btn btn-square btn-sm btn-primary">
                <span
                    *ngIf="submitted"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"
                ></span>
                {{'messages.comments.comment'|translate}}
            </button>
            <button
                (click)="closeForm.emit()"
                *ngIf="comment || parent"
                class="btn btn btn-square btn-sm btn-warning ml-2"
            >{{'messages.global.cancel'|translate}}</button>
        </div>
    </div>
</div>
