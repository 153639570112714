import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-not-permissions',
    templateUrl: './not-permissions.component.html',
    styleUrls: ['./not-permissions.component.scss']
})
export class NotPermissionsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
