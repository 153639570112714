<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="(message ? 'messages.admin.messages_page.form.message.title_edit' : 'messages.admin.messages_page.form.message.title_create') | translate"
>
    <app-form
        [formConfig]="formConfig"
        [value]="message"
        [lists]="lists"
        [hideButtons]="true"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="addMessage($event)"
    >
    </app-form>
</app-modal>
