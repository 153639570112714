<div class="container-fluid cover-bg-container">
    <div class="panel-centered-wrapper">
        <div class="panel-centered text-center">
            <app-form-loading [loading]="loading" [withText]="false"></app-form-loading>
            <ng-container *ngIf="!loading">
                <h4 class="mb-4">
                    {{ onModeration ? 'Your request is sent to your administrator for approval' : 'Thank you, the account is verified!'}}
                </h4>
                <a [routerLink]="'/login'" class="btn btn-primary" style="color:white !important;">
                    Login page
                </a>
            </ng-container>
        </div>
    </div>
</div>
