import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {UserModel} from '../models/models';
import {CookieService} from 'ngx-cookie-service';
import {ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_HIERARCHY, ROLE_SUPER_ADMIN} from './constants';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    apiKeyName = 'X-AUTH-TOKEN';
    apiKeyExpiresName = 'X-AUTH-TOKEN-EXPIRES';
    apiKeyNameOriginal = 'X-AUTH-TOKEN-ORIGINAL';
    user = 'EVO-USER';
    loggedUserSubject = new Subject<UserModel>();

    redirectCount = 0;

    constructor(private apiService: ApiService,
                private cookieService: CookieService,
    ) {
    }

    public setApiKey(apIKey: string) {
        const date = moment().add(15, 'm').format('x');
        localStorage.setItem(this.apiKeyName, apIKey);
        localStorage.setItem(this.apiKeyExpiresName, date);
        this.cookieService.set(this.apiKeyName, apIKey, 7, '/', null, true, 'None');
    }

    public setExpiredAt(timestamp: string) {
        localStorage.setItem(this.apiKeyExpiresName, timestamp);
        this.cookieService.set(this.apiKeyName, this.getApiKey(), (new Date(parseInt(timestamp, 0))), '/', null, true, 'None');
    }

    public getApiKey() {
        return localStorage.getItem(this.apiKeyName);
    }

    public isAuthenticated() {
        const expired = localStorage.getItem(this.apiKeyExpiresName);

        if (this.getApiKey() === null) {
            return false;
        }

        if (expired === null) {
            return false;
        }

        return (new Date()) < (new Date(parseInt(expired, 0)));
    }

    logout() {
        this.cookieService.set(this.apiKeyName, this.getApiKey(), -1, '/', null, true, 'None');

        localStorage.removeItem('EVO-USER');
        localStorage.removeItem('X-AUTH-TOKEN');
        localStorage.removeItem('X-AUTH-TOKEN-EXPIRES');
        localStorage.removeItem('X-AUTH-TOKEN-ORIGINAL');
        this.cookieService.deleteAll();
        document.querySelector('body').className = 'theme_1';
    }

    refreshCookie() {
        if (!this.cookieService.get(this.apiKeyName)) {
            this.setExpiredAt((Date.now() + (15 * 60 * 100)).toFixed(0));
        }
    }

    setLoggedUser(user: UserModel) {
        localStorage.setItem(this.user, JSON.stringify(user));
        this.loggedUserSubject.next(user);
    }

    setImpersonatedUser(user: any) {
        localStorage.setItem(this.apiKeyNameOriginal, this.getApiKey());
        localStorage.setItem(this.apiKeyName, user.api_key);
    }


    getLoggedUser(): UserModel {
        return JSON.parse(localStorage.getItem(this.user));
    }

    getLoggedUserObservable(): Observable<UserModel> {
        return this.loggedUserSubject;
    }

    getLoggedUserFullName() {
        const loggedUser = this.getLoggedUser();
        if (loggedUser) {
            return `${loggedUser.firstName} ${loggedUser.lastName}`;
        }
    }

    canSeeAdmin() {
        return this.isGranted(ROLE_CONTENT_MANAGER) || (this.getLoggedUser() && this.getLoggedUser().isCompanyOwner);
    }

    canImpersonate() {
        return this.isGranted(ROLE_ADMIN);
    }

    isSuperAdmin(): boolean {
        return this.isGranted(ROLE_SUPER_ADMIN);
    }

    isAdmin(): boolean {
        return this.isGranted(ROLE_ADMIN);
    }

    isContentManager(): boolean {
        return this.isGranted(ROLE_CONTENT_MANAGER);
    }

    isEvoEmployee(): boolean {
        return this.getLoggedUser()?.is_evo_employee;
    }

    isImpersonated() {
        return localStorage.getItem(this.apiKeyNameOriginal) !== null;
    }

    isCompanyOwner() {
        return this.getLoggedUser()?.isCompanyOwner;
    }

    isHr() {
        return this.getLoggedUser()?.is_hr;
    }

    exitImpersonation() {
        const originalApiKey = localStorage.getItem(this.apiKeyNameOriginal);
        localStorage.setItem(this.apiKeyName, originalApiKey);
        localStorage.removeItem(this.apiKeyNameOriginal);
    }

    refreshUserInfo(callback: () => void = null) {
        this.apiService.getUserDetails().subscribe(data => {
            this.setLoggedUser(data);

            if (callback) {
                callback();
            }
        });
    }

    isGranted(requiredRole: string): boolean {
        const requiredRoleIndex = ROLE_HIERARCHY.indexOf(requiredRole);

        if (requiredRoleIndex === -1) {
            throw new Error('Invalid role!');
        }

        const user = this.getLoggedUser();

        if (!user) {
            return false;
        }

        const {roles} = user;

        let maxRoleIndex = 0;

        roles.forEach(role => {
            const index = ROLE_HIERARCHY.indexOf(role);

            if (index > maxRoleIndex) {
                maxRoleIndex = index;
            }
        });

        return maxRoleIndex >= requiredRoleIndex;
    }

    canSeeOnboarding() {
        const loggedUser = this.getLoggedUser();
        return loggedUser.company_plan === 1 || loggedUser.company_plan === 2;
    }
}
