import OrgChart from '@portal/evo-orgchart';
import {ScreenshotService} from '../../../services/screenshot.service';
import {jsPDF} from 'jspdf';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

export class EvoChart {
    private editMode = false;

    constructor(
        private isCompanyOwner: boolean,
        private screenshotService: ScreenshotService,
        private toastrService: ToastrService) {
    }

    private chart: OrgChart;

    init(el, onDrop: (sender, draggedNodeId, droppedNodeId) => void, onEdit: () => void) {
        localStorage.setItem('funcUrl', environment.apiURL + '/api/v1/profile/team/organization/backend');
        OrgChart.templates.ana.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="#1F8EF9" stroke-width="1" stroke="#aeaeae" rx="5" ry="5"></rect>';
        OrgChart.templates.ana.field_0 = '<text width="230" style="font-size: 18px;" fill="#ffffff" x="125" y="95" text-anchor="middle">{val}</text>';
        OrgChart.templates.ana.field_1 = '<text width="130" text-overflow="multiline" style="font-size: 14px;" fill="#ffffff" x="240" y="30" text-anchor="end">{val}</text>';
        OrgChart.templates.ana.field_2 = '<text width="130" text-overflow="multiline" style="font-size: 14px;" fill="#ffffff" x="240" y="50" text-anchor="end">{val}</text>';

        this.chart = new OrgChart(el, {
            enableSearch: true,
            layout: OrgChart.mixed,
            template: 'ana',
            nodeBinding: {
                field_0: 'name',
                field_1: 'department',
                field_2: 'role',
                img_0: 'img'
            },
            menu: this.getMenu(onEdit),
            toolbar: {
                fullScreen: true,
                zoom: true,
                fit: true,
                expandAll: false
            },
        });

        this.chart.on('drop', (sender, draggedNodeId, droppedNodeId) => {
            onDrop(sender, draggedNodeId, droppedNodeId);
        });
    }

    fillData(chartData) {
        this.chart.load(chartData);

        setTimeout(() => {
            this.chart.fit();
        });
    }

    getMenu(onEdit: () => void) {
        const edit = this.isCompanyOwner ? {
            text: () => {
                if (this.editMode === false) {
                    return 'Edit hierarchy';
                } else {
                    return 'Stop editing';
                }
            }, onClick: () => {
                // @ts-ignore
                this.chart.config.enableDragDrop = !this.editMode;
                this.editMode = !this.editMode;
                onEdit();
            }
        } : undefined;

        return {
            edit,
            pdf: {
                text: 'Save as PDF',
                onClick: () => {
                    this.toastrService.warning('Export in progress...');
                    // @ts-ignore
                    this.chart.fit(() => {
                        const hideElements = Array.from(
                            document.querySelectorAll('div[data-id="search-icon"], div[control-export-menu], div.bg-toolbar-container')
                        );

                        this.screenshotService.downloadImageOfContent(
                            document.getElementById('organization-chart'),
                            'test',
                            'png',
                            hideElements,
                            (data, w, h) => {
                                const doc = new jsPDF({
                                    orientation: 'landscape',
                                    format: [w, h],
                                    unit: 'px',
                                });

                                doc.addImage(data, 'PNG', 0, 0, w, h);
                                doc.save('orgchart.pdf');
                            }
                        );
                    });
                }
            },
            png: {
                text: 'Save as PNG',
                onClick: () => {
                    this.toastrService.warning('Export in progress...');
                    // @ts-ignore
                    this.chart.fit(() => {
                        const hideElements = Array.from(
                            document.querySelectorAll('div[data-id="search-icon"], div[control-export-menu], div.bg-toolbar-container')
                        );

                        this.screenshotService.downloadImageOfContent(
                            document.getElementById('organization-chart'),
                            'orgchart',
                            'png',
                            hideElements
                        );
                    });
                }
            },
        };
    }
}
