import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig, SelectGroup, SelectValue} from '../../../../../models/models';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})
export class SelectComponent implements ControlValueAccessor {
    public currentErrors: string[];
    public disabled: boolean;
    public label = null;
    public id = null;
    public placeholder = null;
    public multiple = false;
    public touch: any;
    private currentValue;

    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;
    @Input() options: SelectValue[] | SelectGroup[];
    @Input() withLabel = true;
    @Input() inline = false;

    @Input() set config(value: FormInputConfig) {
        const {label, id, placeholder, multiple} = value;

        this.label = label || null;
        this.id = id || null;
        this.placeholder = placeholder || null;
        this.multiple = !!multiple;
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: any) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
}
