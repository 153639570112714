<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.contents_page.form.web_app_type.title_edit'|translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        [value]="value"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="updateContent($event)"
    >
    </app-form>
</app-modal>
