<div id="sidebar-settings" class="sidebar-right-panel-with-footer">
<!--    // Disable temporary dark theme - https://gitlab.evouser.com/portal/evo-frontend-issues/-/issues/2042 -->
<!--    <app-theme></app-theme>-->
    <app-start-up-settings></app-start-up-settings>
    <app-scheduled-deliveries></app-scheduled-deliveries>
    <app-membership></app-membership>
    <footer>
        <p>© Evo v{{version}}</p>
    </footer>
</div>
