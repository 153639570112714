import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {FormErrorsTransformerService} from '../../../services/form-errors-transformer.service';
import {DisplayError} from '../../../models/models';
import {FieldsService} from '../../../services/fields.service';
import {MatchValidator} from '../../../validators/matchValidators';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    requested = false;
    error: string;
    private errors: DisplayError[] = [];

    needCaptcha = false;
    recaptchaKey: string = environment.recaptchaKey;

    forbiddenList = [];
    limitedList = [];

    showForbidden = false;
    showLimited = false;

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private authService: AuthService,
                private router: Router,
                private loadingService: LoadingService,
                private fromErrors: FormErrorsTransformerService,
                private fieldsService: FieldsService
    ) {
        this.createForm();
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['app/reports']);
        } else {
            this.loadingService.clearEvents();
            this.apiService.getRequestToJoinInfo().subscribe(data => {
                this.needCaptcha = data.need_captcha;
            });

            this.apiService.getEmailsLists().subscribe(data => {
                this.forbiddenList = data.forbidden_emails.map(text => '@' + text);
                this.limitedList = data.limited_emails.map(text => '@' + text);
            });
        }
    }

    createForm() {
        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            passwordRepeat: ['', [Validators.required, Validators.minLength(8)]],
            captcha: [''],
        }, {validators: [MatchValidator('password', 'passwordRepeat')]});
    }

    onSubmit() {
        if (this.isInForbiddenList()) {
            return;
        }

        this.errors = this.fromErrors.getErrors(this.registerForm);

        if (this.registerForm.status === 'INVALID') {
            return;
        }

        const data = {
            firstName: this.registerForm.value.firstName,
            lastName: this.registerForm.value.lastName,
            email: this.registerForm.value.email,
            plainPassword: {
                first: this.registerForm.value.password,
                second: this.registerForm.value.passwordRepeat
            }
        };

        if (this.needCaptcha) {
            data['g-recaptcha-response'] = this.registerForm.value.captcha;
        }

        this.loading = true;
        this.apiService.requestToJoin(data).subscribe(response => {
            this.loading = false;
            this.requested = true;
        }, error => {
            this.loading = false;
            this.errors = this.fromErrors.getErrorsFromResponse(this.registerForm, error);
            this.needCaptcha = error.error.need_captcha;
        });
    }

    formGlobalErrors() {
        return this.fromErrors.getGlobalErrors(this.errors);
    }

    getFieldErrors(field: string) {
        return this.fieldsService.getFieldsErrors(this.errors, field, '');
    }

    isInForbiddenList() {
        const {email} = this.registerForm.value;

        for (const domain of this.forbiddenList.filter(item => !!item)) {
            if (email.includes(domain)) {
                return true;
            }
        }

        return false;
    }

    isInLimitedList() {
        const {email} = this.registerForm.value;

        for (const domain of this.limitedList.filter(item => !!item)) {
            if (email.includes(domain)) {
                return true;
            }
        }

        return false;
    }

    checkEmail() {
        this.showForbidden = this.isInForbiddenList();
        this.showLimited = this.isInLimitedList();
    }
}
