import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    AdminContentsList,
    Content,
    ContentModel,
    FormConfig,
    FormInputConfig,
    FormLists, PresentationModel
} from '../../../../models/models';
import {FormBuilder, FormControl} from '@angular/forms';
import {FormComponent} from '../../../form-generator/components/form/form.component';
import * as _ from 'lodash';
import {ContentFormConfig} from '../formConfigs';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {passOnlyMimetypesValidator} from '../../../../validators/mimeTypesValidators';

@Component({
    selector: 'app-content-type-presentation',
    templateUrl: './content-type-presentation.component.html',
    styleUrls: ['./content-type-presentation.component.scss']
})
export class ContentTypePresentationComponent implements OnInit {

    @Output() update = new EventEmitter<boolean | AdminContentsList>();
    @Input() content: AdminContentsList | Content | null;
    @Input() withFolder = false;
    @Input() page: number;
    public value = null;

    public existingFiles = [];
    public errors = [];
    lists: FormLists = {};

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentPresentation)
    };

    public fileControl: FormControl;

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
                private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        let eventsCount = 0;

        if (this.content) {
            eventsCount++;
        }

        this.fileControl = this.formBuilder.control(null, [
            passOnlyMimetypesValidator(['application/pdf', 'application/vnd.ms-powerpoint'])
        ]);
        this.formLoadingServiceService.init(eventsCount);

        if (!this.content) {
            this.value = {};
            return;
        }

        this.apiService.manageContent(this.content.id).subscribe(data => {
            this.value = data;
            this.value.downloadable = true;
            if (data.html) {
                try {
                    const jsonVal = JSON.parse(data.html);
                    this.value.downloadable = jsonVal.downloadable && jsonVal.downloadable === true;
                } catch (e) {

                }
            }
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.existingFiles = this.content.attachments ? this.content.attachments.map(item => ({...item})) : [];
    }

    async updateContent(data: PresentationModel) {
        if (this.fileControl.status !== 'VALID') {
            this.form.unsubmit();
            return;
        }

        if (this.content) {
            const uploaded = await this.updateAttachments(this.content.id).catch(e => console.log(e));

            if (!uploaded) {
                this.form.unsubmit();
                return;
            }
        }

        if (data.folder && data.folder.id) {
            data.folderId = data.folder.id;
        }

        delete data.folder;

        data.html = `{"downloadable": ${data.downloadable}}`;

        delete data.downloadable;

        const request = this.content
            ? this.apiService.updateContent(this.content.id, data, this.page)
            : this.apiService.createContentForUser(data, 'handbook');

        request.subscribe(async (response: any) => {
            if (!this.content) {
                await this.updateAttachments(response.id).catch(e => console.log(e));
            }

            this.update.emit(response);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }

    async updateAttachments(id) {
        const file = this.fileControl.value;

        if (!(file instanceof File)) {
            return true;
        }

        if (this.existingFiles?.length) {
            await this.apiService
                .deleteContentAttachmentFile(this.existingFiles.map(item => item.id))
                .toPromise()
                .catch(e => console.log(e));
        }

        const form = new FormData();
        form.append('file[]', file, file.name);

        const resp = await this.apiService.uploadContentAttachmentFile(id, form).toPromise().catch((e) => {
            this.errors = e.error.errors;
        });

        return !!resp;
    }
}
