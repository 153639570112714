<div class="sidebar-header">
    <i class="icon icon-size-11 ion-email color-light-green"></i>
    {{'messages.right_side.subscriptions.title'|translate}}
</div>

<div class="sidebar-body">
    <div *ngFor="let subscription of subscriptions" class="delivery-item">
        <span>{{subscription.name}}</span>
        <span *ngIf="subscription.type === 'daily'">
            {{"messages.right_side.subscriptions.daily_at"|translate}}
        </span>
        <span *ngIf="subscription.type === 'weekly'">
            {{"messages.right_side.subscriptions.on"|translate}} {{subscription.day_of_delivery}}
        </span>
        <span>
            {{
            subscription.hour_of_delivery > 10
                ? subscription.hour_of_delivery
                : '0' + subscription.hour_of_delivery.toString()
            }}:{{
            subscription.minute_of_delivery > 10
                ? subscription.minute_of_delivery
                : '0' + subscription.minute_of_delivery.toString()
            }}
        </span>
        <span>/ {{(subscription.is_paused ? 'messages.admin.subscriptions_page.paused' : 'messages.admin.subscriptions_page.active')|translate}}</span>&nbsp;
        <a (click)="toggleActive(subscription.id)">({{
            "messages.global.change"|translate
            }})</a>
        <div class="delivery-item-controls">
            <a [title]="'messages.global.edit'|translate" (click)="edit(subscription)">
                <i class="icon ion-edit"></i>
            </a>
            &nbsp;
            <app-modal-confirm>
                <a (click)="delete(subscription.id)">
                    <i class="icon icon-size-7 ion-trash-a"></i>
                </a>
            </app-modal-confirm>
        </div>
    </div>
</div>

<app-profile-subscription-modal
    *ngIf="showModal"
    [subscription]="subscription"
    (addComponent)="closeModal()"
>
</app-profile-subscription-modal>
