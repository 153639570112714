import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {LoadingService} from '../services/loading.service';
import {HttpCancelService} from '../services/http-cancel.service';
import {GlobalErrorService} from '../services/global-error.service';
import {PingService} from '../services/ping.service';
import {Injectable} from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    lastError: Date = new Date();
    toastSpamLimit = 5000; // 5 secs

    constructor(public router: Router,
                public authService: AuthService,
                public toasterService: ToastrService,
                public loadingService: LoadingService,
                private httpCancelService: HttpCancelService,
                private globalErrorService: GlobalErrorService,
                private pingService: PingService) {
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    this.handleGrowlMessages(response);
                    return of(null);
                }
            }),
            catchError((err: any) => {
                this.handleAuthError(err);
                this.handleException(err);
                this.loadingService.clearEvents();
                return throwError(err);
            }));
    }

    private handleException(err: any | HttpErrorResponse) {
        if (err instanceof HttpErrorResponse) {
            try {
                if (err.status === 0 || err.status >= 500) {
                    this.httpCancelService.cancelPendingRequests();

                    const text = err.status >= 500 ? `The server returned a ${err.statusText}`
                        : 'Seems you are experiencing connection problems, please check your internet connection and try again.';

                    this.toasterService.error(text, 'Oops! An Error Occurred', {
                        timeOut: 9000,
                        toastClass: 'ngx-toastr w-75'
                    });

                    // this.pingService.stop();
                    // this.globalErrorService.setError(err.statusText);
                    // this.globalErrorService.setErrorCode(err.status);
                } else if (err.status === 404) {
                    this.toasterService.error(`${err.url} not found`, null);
                } else if (typeof err.error === 'string') {
                    const now = new Date();
                    if ((now.getTime() - this.lastError.getTime()) > this.toastSpamLimit) {
                        this.toasterService.error(err.error, null, {enableHtml: true});
                    }
                } else {
                    this.handleGrowlMessages(err);
                }
            } catch (e) {
                this.toasterService.error('An error occurred');
            }
            this.lastError = new Date();
            // log error
        }
    }

    private handleAuthError(response: HttpErrorResponse) {
        if (response.status === 401 || response.status === 403) {
            this.authService.logout();
            this.httpCancelService.cancelPendingRequests();

            if (response.status === 401) {
                const queryParams = {
                    redirect: this.router.getCurrentNavigation().finalUrl.toString()
                };

                this.authService.redirectCount++;
                this.router.navigate(['login'], {queryParams});
            } else {
                this.router.navigateByUrl('/login');
            }
        }

        if (response.headers.get('X-FORCE-PASSWORD-CHANGE')) {
            this.httpCancelService.cancelPendingRequests();
            this.router.navigateByUrl(`/required-password-reset`);
        }

        if (response.headers.get('X-USER-DISABLED') || response.headers.get('X-COMPANY-DISABLED')) {
            this.authService.logout();
            this.httpCancelService.cancelPendingRequests();
            this.toasterService.error('Your account is not enabled!', null);
            this.router.navigateByUrl(`/login`);
        }
    }

    private handleGrowlMessages(response: HttpResponse<unknown> | HttpErrorResponse) {
        if (response.headers.has('growl-message')) {
            if (response.headers.get('growl-type') === 'success') {
                this.toasterService.success(
                    response.headers.get('growl-message'),
                    null,
                    {
                        timeOut: parseInt(response.headers.get('growl-ttl'), 10) || 5000
                    });
            } else {
                this.toasterService.error(
                    response.headers.get('growl-message'),
                    null,
                    {
                        timeOut: parseInt(response.headers.get('growl-ttl'), 10) || 5000
                    });
            }

        }
    }
}
