<app-holiday-request-form
    (closeModal)="showModal = false"
    (submitted)="addRequest()"
    *ngIf="showModal"
>
</app-holiday-request-form>

<button class="btn btn-primary btn-sm btn-square mb-4" (click)="back()">{{'messages.global.back'|translate}}</button>

<div class="mb-2" style="display: flex; flex-wrap: wrap; font-size: 1.24rem;">
    <div class="mr-2">{{'messages.holidays_requests.holidays_total'|translate}}: <strong>{{holidaysTotal}}</strong> days</div>
    <div class="mr-2">{{'messages.holidays_requests.holidays_used'|translate}}: <strong>{{holidaysUsed}}</strong> days</div>
    <div>{{'messages.holidays_requests.holidays_remain'|translate}}: <strong>{{holidaysRemain}}</strong> days</div>
</div>

<div class="mb-4 form-style-bordered-inputs d-flex flex-column flex-md-row">
    <div class="mb-2 mr-0 mr-md-4" style="max-width: 300px;">
        <div class="form-group">
            <select
                (change)="handleGroupChange()"
                [(ngModel)]="group"
                class="form-control"
                id="current-company"
                style="min-width: 200px;"
            >
                <option *ngFor="let option of groupOptions" [ngValue]="option.value">{{option.name}}</option>
            </select>
        </div>
    </div>

    <div class="mr-2 mb-2">
        <button (click)="showModal = true" class="btn btn-primary btn-square btn-sm">{{'messages.holidays_requests.add_request'|translate}}</button>
    </div>
</div>
<full-calendar #calendar [options]="calendarOptions" class="mb-4"></full-calendar>

<!--<div *ngIf="loading" style="position: absolute; width: 100%; height: 100%; background-color: #FFFFFF; top: 0; left: 0;display: flex;align-items: center; justify-content: center; z-index: 10;">-->
<!--    <div>Loading...</div>-->
<!--</div>-->
