<app-holiday-request-form
    (closeModal)="showModal = false; editItem = null;"
    (submitted)="loadList(); showModal = false; editItem = null;"
    *ngIf="showModal"
    [item]="editItem"
>
</app-holiday-request-form>

<button class="btn btn-primary btn-sm btn-square mb-4" (click)="back()">{{'messages.global.back'|translate}}</button>

<div class="mb-4" style="display: flex; flex-wrap: wrap; font-size: 1.24rem;">
    <div class="mr-2">{{'messages.holidays_requests.holidays_total'|translate}}: <strong>{{holidaysTotal}} days</strong></div>
    <div class="mr-2">{{'messages.holidays_requests.holidays_used'|translate}}: <strong>{{holidaysUsed}} days</strong></div>
    <div>{{'messages.holidays_requests.holidays_remain'|translate}}: <strong>{{holidaysRemain}} days</strong></div>
</div>

<div class="d-flex form-style-bordered-inputs flex-column flex-md-row flex-wrap">
    <div class="mr-0 mr-md-4 mb-2">
        <button
            (click)="showModal = true"
            class="btn btn-primary btn-square btn-sm"
        >{{'messages.holidays_requests.add_request'|translate}}</button>
    </div>

    <div class="form-group mr-0 mr-md-4">
        <input
            (input)="filterRequests()"
            [(ngModel)]="term"
            class="form-control"
            [placeholder]="'messages.admin.search'|translate"
            style="min-width: 200px"
            type="text"
        >
    </div>

    <div class="mb-4 mr-0 mr-md-4" style="max-width: 300px;">
        <div class="form-group">
            <select
                (change)="filterRequests()"
                [(ngModel)]="requestType"
                class="form-control"
                id="current-company"
                style="min-width: 200px;"
            >
                <option *ngFor="let type of leaveFilterTypes" [ngValue]="type.value">{{type.name}}</option>
            </select>
        </div>
    </div>

    <div class="status-filters flex-column flex-md-row">
        <div
            *ngFor="let type of requestStatuses"
            [ngStyle]="{backgroundColor: type.color}"
            class="form-check mr-2 mb-2"
        >
            <input
                (change)="handleCbClick()"
                (click)="$event.stopPropagation()"
                [(ngModel)]="type.enabled"
                [id]="'cb_'+ type.value"
                class="form-check-input"
                type="checkbox"
            >
            <label [for]="'cb_'+ type.value" class="form-check-label">{{type.name}}</label>
        </div>
    </div>
</div>

<div class="mb-2" style="overflow-x: auto">
    <table class="table" style="min-width: 1000px">
        <thead>
        <tr>
            <th *ngFor="let col of cols" [ngClass]="'col-'+col.id">
                <div
                    (click)="handleThClick(col)"
                    style="cursor: pointer"
                ><i
                    class="icon"
                    *ngIf="col.selected"
                    [ngClass]="{'ion-android-arrow-down': col.order === 'DESC', 'ion-android-arrow-up': col.order === 'ASC'}"
                ></i> {{col.label|translate}}</div>
            </th>
            <!--            <th style="width: 120px"></th>-->
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let request of filteredRequests| slice: (page-1) * perPage : page * perPage">
            <tr>
                <td *ngFor="let col of cols" [ngClass]="'td-'+col.id">
                    <ng-container [ngSwitch]="col.id">
                        <span *ngSwitchCase="'type'">{{typesTranslations[request[col.id]]}}</span>
                        <span
                            *ngSwitchCase="'status'"
                            [ngStyle]="{color: '#fff', backgroundColor: request.status_color}"
                            class="badge"
                        >{{[request[col.id]]}}</span>
                        <span *ngSwitchDefault>{{request[col.id]}}</span>
                    </ng-container>
                </td>
            </tr>
            <tr>
                <td [attr.colspan]="cols.length" class="actions">
                    <div class="d-flex justify-content-end">
                        <div style="width: 100%;padding-right: 1rem;">
                            <span
                                *ngIf="request.checked_by?.id"
                                class="mr-2"
                            >{{'messages.holidays_requests.checked_by'|translate}}
                                : <strong>{{request.checked_by.stringified}}</strong></span>

                            <span class="mr-2">{{'messages.holidays_requests.requested_at'|translate}}
                                : <strong>{{request.created_at}}</strong></span>

                            <span>{{'messages.holidays_requests.last_change'|translate}}
                                : <strong>{{request.updated_at}}</strong></span>
                        </div>
                        <div>
                            <div *ngIf="isHr && request.status == 'requested'" class="mb-2">
                                <app-modal-confirm [confirmMessage]="'messages.global.are_you_sure'|translate">
                                    <button
                                        (click)="markHolidaysRequest(request, 'accept')"
                                        [disabled]="request.loading"
                                        class="btn btn-sm btn-square btn-success mr-2"
                                        type="button"
                                    ><i class="icon ion-checkmark"></i> {{'messages.global.accept'|translate}}
                                    </button>
                                </app-modal-confirm>

                                <app-modal-confirm [confirmMessage]="'messages.global.are_you_sure'|translate">
                                    <button
                                        (click)="markHolidaysRequest(request, 'reject')"
                                        [disabled]="request.loading"
                                        class="btn btn-sm btn-square btn-danger"
                                        type="button"
                                    ><i class="icon ion-close"></i> {{'messages.global.reject'|translate}}
                                    </button>
                                </app-modal-confirm>
                            </div>

                            <div *ngIf="isHr || request.status == 'requested'">
                                <button
                                    (click)="editItem = request; showModal = true;"
                                    [disabled]="request.loading"
                                    class="btn btn-sm btn-square btn-primary mr-2"
                                    type="button"
                                ><i class="icon ion-edit"></i> {{'messages.global.edit'|translate}}
                                </button>
                                <app-modal-confirm>
                                    <button
                                        (click)="deleteRequest(request)"
                                        [disabled]="request.loading"
                                        class="btn btn-sm btn-square btn-danger"
                                        type="button"
                                    ><i class="icon ion-ios-trash"></i> {{'messages.global.delete'|translate}}
                                    </button>
                                </app-modal-confirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>

    <ngb-pagination
        [(page)]="page"
        [collectionSize]="filteredRequests.length"
        [pageSize]="perPage"
        [maxSize]="5"
        *ngIf="perPage < filteredRequests.length"
    ></ngb-pagination>
</div>
