import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../services/loading.service';
import {LoadingEvent} from '../../models/models';
import {Router} from '@angular/router';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {
    width: number;
    public loaded = false;
    step: number;

    constructor(private loadingService: LoadingService, private router: Router) {

    }

    ngOnInit() {
        this.router.events.subscribe((val) => {
            this.width = 10;
        });
        this.loadingService.getEvents().subscribe((events: LoadingEvent[]) => {
            this.loaded = false;
            this.step = 15;
            this.width += this.step;
            if (events.length === 0) {
                this.step = 100;
                this.loaded = true;
            }
        });
    }
}
