import {Injectable} from '@angular/core';
import {Pager} from '../models/models';
import {HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {

    public pager: Pager;

    public paginationOptions = [10, 20, 50, 100];

    constructor() {
        this.pager = this.initPager();
    }

    public responseHasHeaders(response: HttpResponse<any>): boolean {
        return response.headers.has('current_page') &&
            response.headers.has('first_item_number') &&
            response.headers.has('last_item_number') &&
            response.headers.has('last_page') &&
            response.headers.has('nr_of_items') &&
            response.headers.has('nr_of_items_per_page');
    }

    public setPagination(response: HttpResponse<any>): void {
        this.pager.current_page = parseInt(response.headers.get('current_page'), 10) || 1;
        this.pager.first_item_number = parseInt(response.headers.get('first_item_number'), 10);
        this.pager.last_item_number = parseInt(response.headers.get('last_item_number'), 10);
        this.pager.last_page = parseInt(response.headers.get('last_page'), 10);
        this.pager.nr_of_items = parseInt(response.headers.get('nr_of_items'), 10);
        this.pager.nr_of_items_per_page = parseInt(response.headers.get('nr_of_items_per_page'), 10);
    }

    public initPager(): Pager {
        return {
            current_page: 1,
            first_item_number: 0,
            last_item_number: 0,
            last_page: 0,
            nr_of_items: 0,
            nr_of_items_per_page: 10,
            filters: [],
            sorting: {
                column: 'id',
                direction: 'desc'
            }
        };
    }

    public checkSorting(id: any) {
        if (this.pager.sorting.column === id) {
            if (this.pager.sorting.direction === 'asc') {
                this.pager.sorting.direction = 'desc';
            } else {
                this.pager.sorting.direction = 'asc';
            }
        } else {
            this.pager.sorting.column = id;
        }
    }
}
