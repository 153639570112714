<accordion-group
    [isOpen]="isRootOpened()"
>
    <a
        class="lvl-0"
        accordion-heading
    >
        <span>{{'messages.git_boards.menu.management_tools'|translate}}</span>
        <span class="heading-actions">
            <span class="acc-arrow">
                <i class="ion-ios-arrow-down"></i>
            </span>
        </span>
    </a>
    <div
        class="panel-body"
        style="margin-top: 1px; width: 100%"
    >
        <div class="list-item lvl-1" [ngClass]="{selected: location.pathname == '/app/board/new-request'}">
            <a
                routerLink="/app/board/new-request" class="text-break list-item-link"
            >
                {{'messages.git_boards.menu.add_request'|translate}}
            </a>
        </div>
        <div class="list-item lvl-1" [ngClass]="{selected: location.pathname === '/app/board'}">
            <a
                routerLink="/app/board" class="text-break list-item-link"
            >
                {{'messages.git_boards.menu.all_requests'|translate}}
            </a>
        </div>
    </div>
</accordion-group>
