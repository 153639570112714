import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormConfig, FormLists, HolidayRequest, HolidaysRequestModel} from '../../../../models/models';
import {LEAVE_REQUEST_TYPES} from '../../../../services/constants';
import {Validators} from '@angular/forms';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {ApiService} from '../../../../services/api.service';
import {greaterThan, lowerThanValue} from '../../../../validators/matchValidators';
import {AuthService} from '../../../../services/auth.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {daysAllowanceValidator} from '../../../../validators/daysAllowanceValidator';
import * as moment from 'moment';

@Component({
    selector: 'app-holiday-request-form',
    templateUrl: './holiday-request-form.component.html',
    styleUrls: ['./holiday-request-form.component.scss'],
    providers: [FormLoadingServiceService]
})
export class HolidayRequestFormComponent implements OnInit {

    @Input() item: HolidayRequest = null;

    @Output() submitted = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    public lists: FormLists = {
        type: LEAVE_REQUEST_TYPES.map(item => ({...item})),
    };
    public formConfig: FormConfig = {
        fields: [
            {
                name: 'type',
                label: 'Leave type',
                type: 'select',
                validators: [Validators.required]
            },
            {
                name: 'start',
                label: 'From',
                type: 'date',
                format: 'YYYY-MM-DD HH:mm',
                withTime: true,
                validators: [
                    Validators.required,
                ]
            },
            {
                name: 'end',
                label: 'To',
                type: 'date',
                format: 'YYYY-MM-DD HH:mm',
                withTime: true,
                validators: [
                    Validators.required,
                    lowerThanValue(moment().format('YYYY-12-31: 23:59'))
                ]
            },
            {name: 'cause', label: 'Cause (optional)', type: 'textarea'},
        ]
    };

    public loading = false;
    @ViewChild(FormComponent) form: FormComponent<HolidaysRequestModel>;
    private currentYear = moment().format('YYYY');

    constructor(
        private api: ApiService,
        private auth: AuthService,
        public formLoadingServiceService: FormLoadingServiceService) {
    }

    ngOnInit(): void {
        this.formLoadingServiceService.init(1);
        const userId = this.item?.user_id || this.auth.getLoggedUser().id;

        this.api.checkAllowanceForUser(userId).subscribe(data => {
            this.updateFormValidators(data);
            this.formLoadingServiceService.eventLoaded();

            setTimeout(() => {
                const startControl = this.form.form.get('start');
                const endControl = this.form.form.get('end');

                startControl.valueChanges.subscribe(value => {
                    const year = moment(value).format('YYYY');

                    if (this.currentYear === year) {
                        return;
                    }

                    this.currentYear = year;

                    this.api.checkAllowanceForUser(userId, year)
                        .subscribe(allowanceData => {
                            endControl.setValidators([
                                Validators.required,
                                lowerThanValue(`${year}-12-31 23:59`)
                            ]);

                            this.updateFormValidators(allowanceData);
                        });
                });
            });
        });
    }

    addRequest(data: HolidaysRequestModel) {
        const request = this.item?.id
            ? this.api.updateHolidayRequest(this.item.id, data)
            : this.api.createHolidayRequest(data)
        ;

        this.loading = true;

        request.subscribe(resp => {
            this.loading = false;
            this.submitted.emit(resp);
        }, resp => {
            this.loading = false;
            this.form.unsubmit(resp);
        });
    }

    closeModalWindow() {
        this.closeModal.emit();
    }

    private updateFormValidators({holidays_remain}) {
        let allowance = holidays_remain;

        if (this.item?.start.includes(this.currentYear)) {
            allowance += this.item.duration;
        }

        const validators = [
            greaterThan('end', 'start'),
            daysAllowanceValidator('type', 'start', 'end', allowance)
        ];

        this.formConfig.validators = validators;
        this.form?.form.setValidators(validators);
        this.form?.form.updateValueAndValidity();
    }
}
