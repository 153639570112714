import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {EntityProviderService} from '../../services/entity-provider.service';
import {ProvidedEntity} from '../../models/models';

const tableText = '| header | header |\n' +
    '| ------ | ------ |\n' +
    '| cell | cell |\n' +
    '| cell | cell |';

@Component({
    selector: 'app-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrls: ['./markdown-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        }
    ]
})
export class MarkdownEditorComponent implements OnInit, ControlValueAccessor {

    @ViewChild('editor') editor: ElementRef;
    @Input() accept = 'image/*';
    @Input() allowMentions = false;
    @Output() mentionChanged: EventEmitter<ProvidedEntity[]> = new EventEmitter();
    public mentionItems: ProvidedEntity[];
    public selectedMentions: ProvidedEntity[] = [];

    public showEditor = true;
    private currentValue: string;

    constructor(private api: ApiService, private entityProvider: EntityProviderService) {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: string) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.entityProvider.getUserMentions().subscribe(data => {
            this.mentionItems = data;
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setBold() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;
        this.insertText('**' + value + '**');

        if (!value?.length) {
            this.updCaretPosition(start + 2);
            return;
        }

        this.updCaretPosition(start + 4 + value.length);
    }

    setItalic() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('*' + value + '*');

        if (!value?.length) {
            this.updCaretPosition(start + 1);
            return;
        }

        this.updCaretPosition(start + 2 + value.length);
    }

    setQuote() {
        const value = this.getSelectedValue();
        const toReplace = value.replace(/\n/g, '\n> ');
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('> ' + toReplace);
        this.updCaretPosition(start + toReplace.length + 2);
    }

    setCode() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;
        let newValue = '';

        if (value.includes('\n')) {
            newValue = '```\n' + value + '\n```';
        } else {
            newValue = '`' + value + '`';
        }

        this.insertText(newValue);
        this.updCaretPosition(start + newValue.length);
    }

    setUrl() {
        const value = this.getSelectedValue();
        const newValue = '[' + value + '](url)';
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('[' + value + '](url)');

        setTimeout(() => {
            this.editor.nativeElement.focus();
            this.editor.nativeElement.setSelectionRange(start + newValue.length - 4, start + newValue.length - 1);
        });
    }

    setBulletList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map(item => '- ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '- ';
        }

        this.replaceList(toReplace);
    }

    setNumberedList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map((item, index) => (index + 1) + '. ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '1. ';
        }

        this.replaceList(toReplace);
    }

    setAddList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map(item => '- [ ] ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '- [ ] ';
        }

        this.replaceList(toReplace);
    }

    setTable() {
        const value = this.getSelectedValue();
        const toReplace = tableText + value;
        const start = this.editor.nativeElement.selectionStart;

        this.insertText(toReplace);
        this.updCaretPosition(start + toReplace.length);
    }

    handleFileDrop(e: DragEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        if (!e?.dataTransfer?.files?.length) {
            return;
        }

        this.uploadFiles(e.dataTransfer.files);
    }

    handleFileInput(e) {
        if (!e.target?.files) {
            return;
        }

        this.uploadFiles(e.target.files);
    }

    onItemSelected(el) {
        this.selectedMentions.push(el);
        this.mentionChanged.emit(this.selectedMentions);
    }

    private uploadFiles(files: FileList) {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file_' + i, files.item(i), files.item(i).name);
        }

        this.api.uploadFilesToGit(formData).subscribe(data => {
            this.insertText(data.join('\n\n'), false);
        });
    }

    private replaceList(toReplace: string) {
        const start = this.editor.nativeElement.selectionStart;

        if (!(start === 0 || this.value.charAt(start - 1) === '\n')) {
            toReplace = '\n' + toReplace;
        }

        this.insertText(toReplace);
        this.updCaretPosition(start + toReplace.length);
    }

    private getSelectedValue(): string {
        const el = this.editor.nativeElement;

        const start = el.selectionStart;
        const end = el.selectionEnd;

        return this.value.slice(start, end).trim();
    }

    private updCaretPosition(position: number) {
        this.editor.nativeElement.focus();
        this.editor.nativeElement.setSelectionRange(position, position);
    }

    private insertText(value, replace = true) {
        const el = this.editor.nativeElement;
        el.focus();

        const start = el.selectionStart;
        const end = el.selectionEnd;

        const v = this.value;

        if (replace) {
            el.value = v.slice(0, start) + value + v.slice(end);
        } else {
            el.value = v.slice(0, start) + value + v.slice(start + 1);
        }

        el.dispatchEvent(new Event('input'));
    }
}
