import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LayoutConfigService} from '../../services/layout-config.service';
import {ScreenshotService} from '../../services/screenshot.service';
import {ApiService} from '../../services/api.service';
import {
    AdminFoldersList,
    AdminPagesList,
    ReportsModel,
    SidebarLeftMenuModel,
    UserReportsModel
} from '../../models/models';
import {Router} from '@angular/router';
import {ReportsService} from '../../services/reports.service';
import {ToastrService} from 'ngx-toastr';
import {HttpParams} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {
    ROLE_ADMIN,
    ROLE_CONTENT_MANAGER,
    ROLE_SUPER_ADMIN,
    TYPE_IFRAME,
    TYPE_SHINY,
    TYPE_WEB_APPLICATION
} from '../../services/constants';
import {SidebarLeftService} from './sidebar-left.service';
import {Subscription} from 'rxjs';

interface MenuItem {
    label: string;
    children: { label: string; route: string }[];
    isGranted: boolean;
}

@Component({
    selector: 'app-sidebar-left',
    templateUrl: './sidebar-left.component.html',
    styleUrls: ['./sidebar-left.component.css'],
})
export class SidebarLeftComponent implements OnInit, OnDestroy {

    @ViewChild('downloadLink') downloadLink: ElementRef;
    @Input() admin: boolean;
    @Input() menu: MenuItem[];

    public reports: ReportsModel | UserReportsModel | SidebarLeftMenuModel;
    exportableFormats: any;
    currentEditFolder: AdminFoldersList = null;
    currentEditPage: AdminPagesList = null;
    currentMenu: MenuItem[] = [];

    private subscriptions: Subscription[] = [];

    constructor(public layoutConfigService: LayoutConfigService,
                public screenshotService: ScreenshotService,
                public router: Router,
                public apiService: ApiService,
                public reportsService: ReportsService,
                private toastrService: ToastrService,
                public authService: AuthService,
                private sidebarLeftService: SidebarLeftService) {
    }

    ngOnInit() {
        this.reportsService.getExportedFormats().subscribe(formats => {
            this.exportableFormats = formats;
        });
        if (!this.admin) {

            this.apiService.getReports().subscribe(response => {
                this.reportsService.setPagesCount(response.pagesCount);
                this.reportsService.setGroupsCount(response.groupsCount);
                this.reports = response;
            });

            this.subscriptions.push(this.reportsService.reportsSubject.subscribe(() => {
                this.apiService.getReports().subscribe(response => {
                    this.reportsService.setPagesCount(response.pagesCount);
                    this.reportsService.setGroupsCount(response.groupsCount);
                    this.reports = response;
                });
            }));
        }
        this.apiService.getNotifications().subscribe(response => {
            this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
        });

        const {authService: auth} = this;

        const menu = [
            {
                label: 'messages.admin.accordion.company',
                children: [
                    {
                        label: 'Pending Requests',
                        route: 'pending-requests'
                    },
                    {
                        label: 'messages.admin.accordion.users',
                        route: 'users'
                    },
                    {
                        label: 'messages.admin.accordion.groups',
                        route: 'groups'
                    },
                ],
                isGranted: auth.isGranted(ROLE_SUPER_ADMIN) || auth.isCompanyOwner(),
            },
            {
                label: 'messages.admin.accordion.content',
                children: [
                    {
                        label: 'messages.admin.accordion.companies',
                        route: 'companies'
                    },
                    {
                        label: 'messages.admin.accordion.folders',
                        route: 'folders'
                    },
                    {
                        label: 'messages.admin.accordion.pages',
                        route: 'pages'
                    },
                    {
                        label: 'messages.admin.accordion.content',
                        route: 'contents'
                    },
                    {
                        label: 'messages.messaging.emails.sent_emails',
                        route: 'sent-mails'
                    },
                    {
                        label: 'Feed',
                        route: 'feed'
                    },
                ],
                isGranted: auth.isGranted(ROLE_CONTENT_MANAGER),
            },
            {
                label: 'messages.admin.accordion.data',
                children: [
                    {
                        label: 'messages.admin.accordion.workbooks',
                        route: 'workbooks'
                    },
                    {
                        label: 'messages.admin.accordion.workbook_templates',
                        route: 'workbook-templates'
                    },
                    {
                        label: 'messages.admin.accordion.xls_versions',
                        route: 'excel-manager'
                    },
                    {
                        label: 'messages.admin.accordion.scheduling',
                        route: 'scheduling'
                    },
                    {
                        label: 'messages.admin.accordion.messages',
                        route: 'messages'
                    },
                    {
                        label: 'messages.admin.accordion.subscriptions',
                        route: 'subscriptions'
                    },
                ],
                isGranted: auth.isGranted(ROLE_ADMIN),
            },
            {
                label: 'messages.admin.accordion.system',
                children: [
                    {
                        label: 'messages.admin.accordion.admins',
                        route: 'admins'
                    },
                    {
                        label: 'messages.admin.accordion.logs',
                        route: 'logs'
                    }
                ],
                isGranted: auth.isGranted(ROLE_ADMIN),
            }
        ];

        if (auth.isSuperAdmin()) {
            menu[1].children.push({
                label: 'messages.admin.accordion.profiles_lists_values',
                route: 'profiles-lists-values'
            });

            const system = menu[menu.length - 1].children;

            system.push({
                label: 'messages.admin.accordion.emails_domains',
                route: 'emails-domains'
            });

            system.push({
                label: 'Env',
                route: 'backend-env'
            });

            system.push({
                label: 'Oauth clients settings',
                route: 'oauth-clients'
            });
        }

        this.currentMenu = menu.filter(item => item.isGranted);

        this.subscriptions.push(this.sidebarLeftService.pageSubject.subscribe(value => {
            this.currentEditPage = value;
        }));

        this.subscriptions.push(this.sidebarLeftService.folderSubject.subscribe(value => {
            this.currentEditFolder = value;
        }));
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    saveReport(format: any, email: number = 0) {
        this.toastrService.info('Preparing report...', null, {timeOut: 8000});
        const params = new HttpParams()
            .set('type', format)
            .set('to_email', email.toString());
        const report = this.getSelectedReport();

        if (email) {
            this.apiService.emailReport(params, report.id.toString()).subscribe();
        } else {
            this.apiService.saveReport(params, report.id.toString()).subscribe((response: { size: number, type: string }) => {
                this.apiService.downloadFileClientSide(response, `${report.name}.${format}`, response.type);
            });
        }
    }

    hasExtensionPromt() {
        const report = this.reportsService.getSelectedReport();

        if (!report || !report.contents) {
            return false;
        }

        return !!report.contents.filter(item => [TYPE_IFRAME, TYPE_SHINY, TYPE_WEB_APPLICATION].includes(item.type)).length;
    }

    getSelectedReport() {
        return this.reportsService.getSelectedReport();
    }

    closeForm(refresh = false) {
        this.currentEditFolder = null;
        this.currentEditPage = null;

        if (!refresh) {
            return;
        }

        this.apiService.getReports().subscribe(response => {
            this.reportsService.setPagesCount(response.pagesCount);
            this.reportsService.setGroupsCount(response.groupsCount);
            this.reports = response;
        });
    }

    hasExportableFormats() {
        if (!this.exportableFormats) {
            return false;
        }

        return Object.entries(this.exportableFormats).length > 0;
    }
}
