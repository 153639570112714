<div class="form-group radio" *ngFor="let option of options; index as i">
    <label class="form-check-label" [for]="field.id + '_' + i">
        <input
            [id]="field.id + '_' + i"
            [name]="field.name"
            class="ng-untouched ng-valid ng-valid-parse"
            [ngClass]="value === option.value ? 'ng-not-empty' :'ng-dirty'"
            [(ngModel)]="value"
            [disabled]="disabled"
            [value]="option.value"
            type="radio"
        >
        <span><span><span></span></span></span><span>{{option.name}}</span>
    </label>
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
