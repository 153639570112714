import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-form-loading',
    templateUrl: './form-loading.component.html',
    styleUrls: ['./form-loading.component.scss'],
})
export class FormLoadingComponent implements OnInit {

    @Input() loading: boolean;
    @Input() withText = true;

    ngOnInit() {
    }
}
