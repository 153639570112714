import {Component, Input, OnInit} from '@angular/core';
import {FormInputConfig, ProvidedEntity, SelectValue} from '../../../../../models/models';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
    selector: 'app-input-renderer',
    templateUrl: './input-renderer.component.html',
    styleUrls: ['./input-renderer.component.scss']
})
export class InputRendererComponent implements OnInit {

    @Input() field: FormInputConfig;
    @Input() form: AbstractControl | FormControl;
    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;
    @Input() options: ProvidedEntity[] | SelectValue[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
