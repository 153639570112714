import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as dc from 'dc';
import {HttpClient} from '@angular/common/http';
import {DashboardService} from '../../../../services/dashboard.service';
import {ChartConfig} from '../../../../models/models';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnDestroy {

    @ViewChild('chart') private chartContainer: ElementRef;
    @ViewChild('resetButton') private resetButton: ElementRef;
    @Input() private crossFilter: any;
    @Input() public chartConfig: ChartConfig;
    public chartId: string;
    private dcChart;
    private subscriptions = [];
    public loading: boolean;

    constructor(private httpClient: HttpClient,
                private dashboardService: DashboardService,
                private elRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.subscriptions.push(this.dashboardService.getCrossFilterSubject().subscribe((crossFilter: any) => {
                this.dashboardService.setElementRef(this.elRef);
                this.chartId = this.generateRandomId();
                this.dashboardService.destroyAllBuilder( this.chartId);
                let isCompositeChart = false;
                const xDimension = this.dashboardService.createXDimension(crossFilter, this.chartConfig);
                const series = this.dashboardService.createSeries(xDimension, this.chartConfig);
                this.chartConfig = this.dashboardService.associateDataWithAxis(this.chartConfig, series);
                const chartType = this.chartConfig.type;
                if (series.length > 1) {
                    isCompositeChart = true;
                }

                try {
                    const chart = isCompositeChart ?
                        dc.compositeChart(this.chartContainer.nativeElement) :
                        dc.lineChart(this.chartContainer.nativeElement);
                    chart.dimension(xDimension);
                    this.dcChart = chart;
                    // tslint:disable-next-line:max-line-length
                    this.dashboardService.setDimensions(this.chartConfig, chart); // NEED IT HERE!!!! Otherwise on mouseover lines on datapoints will go crazy! https://gitlab.evouser.com/evopricing/evo-frontend-issues/issues/1276
                    // tslint:disable-next-line:max-line-length
                    // this.dashboardService.setTitles(chart, this.chartConfig); // NEED IT HERE BEFORE .compose()! https://github.com/dc-js/dc.js/issues/554
                    const margins = this.dashboardService.setMargins(this.chartConfig, chart);
                    if (isCompositeChart) {
                        this.dashboardService.handleCompositeChart(this.chartConfig, series, chartType, chart, margins);
                    } else {
                        this.dashboardService.handleSeries(series, chart); // Very important for all charts except table and map
                    }
                    // this.dashboardService.handleNewLine(this.chartConfig, `#${this.chartId}`);
                    // this.dashboardService.setChartTitle(this.chartConfig, `#${this.chartId}`, margins);
                    this.dashboardService.handleLegend(this.chartConfig, chart);
                    this.dashboardService.appendFilteringDetails(chartType, this.chartConfig, series, chart);
                    this.dashboardService.handleResetButton(this.chartConfig, `#${this.chartId}`, chart);
                    // tslint:disable-next-line:max-line-length
                    // this.dashboardService.setChartSubtitle(this.chartConfig, `#${this.chartId}`, margins); // Leave here, do not move, reset position will break otherwise
                    this.dashboardService.renderChart(chartType, chart, this.chartConfig, series);
                } catch (ex) {
                    throw ex;
                }

                // TODO: Find a better solution for this (to hide rest button on first load)
                this.renderer.setStyle(this.resetButton.nativeElement, 'visibility', 'hidden');
                this.loading = false;
            })
        );
    }

    generateRandomId() {
        return `${this.dashboardService.getOptions().defaultChartIdPrefix}-${this.chartConfig.id}`;
    }

    resetChart() {
        this.dcChart.filterAll();
        this.dashboardService.redrawAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}




