import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safeResourceUrl'
})
export class SafeResourceUrlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) {
    }

    transform(value: any): any {
        return this.sanitized.bypassSecurityTrustResourceUrl(value);
    }

}
