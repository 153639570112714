import {Component, Input, OnInit} from '@angular/core';
import {DisplayError} from '../../models/models';
import {FieldsService} from '../../services/fields.service';

@Component({
    selector: 'app-display-input-errors',
    templateUrl: './display-input-errors.component.html',
    styleUrls: ['./display-input-errors.component.scss']
})
export class DisplayInputErrorsComponent implements OnInit {

    @Input() errorsStrings: string[];
    @Input() displayErrors: DisplayError[];
    @Input() errorKey = '_global';

    constructor(private fieldsService: FieldsService) {
    }

    ngOnInit() {
    }

    get errors() {
        const {displayErrors, errorKey} = this;

        if (displayErrors && errorKey) {
            return displayErrors.filter(error => error.field === errorKey)
                .map(error => error.message || this.fieldsService.getDefaultMessage(error.type, error.options));
        }

        return this.errorsStrings;
    }
}
