import {Component, ElementRef, Input, NgZone, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserProfileListValue} from '../../../models/models';
import {icon, latLng, latLngBounds, Marker, marker, point, popup, tileLayer} from 'leaflet';
import {Router} from '@angular/router';
import {AvatarUrlPipe} from '../../../pipes/avatar-url.pipe';

@Component({
    selector: 'app-team-map',
    templateUrl: './team-map.component.html',
    styleUrls: ['./team-map.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    providers: [
        AvatarUrlPipe
    ]
})
export class TeamMapComponent {

    @ViewChild('container') container: ElementRef;
    options = null;

    constructor(private ngZone: NgZone, private router: Router, private avatarUrlPipe: AvatarUrlPipe) {
    }

    @Input() set profiles(values: UserProfileListValue[]) {
        this.options = null;

        if (!values) {
            return;
        }

        const mIcon = icon({
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            iconUrl: '/assets/map/marker-icon.png',
            shadowUrl: '/assets/map/marker-shadow.png'
        });

        const makeMarker = (item: UserProfileListValue) => {
            const content = document.createElement('div');
            const role = [item.department, item.role].filter(r => !!r).join(': ');

            content.innerHTML = `
                    <div class="user-popup">
                        <div class="avatar-container">
                            <img src="${this.avatarUrlPipe.transform(item.id)}" alt="${item.name}" class="avatar" />
                        </div>
                        <div class="user-info">
                            <p><strong>${item.name}</strong></p>
                            <p>${role}</p>
                        </div>
                    </div>
            `;

            const link = document.createElement('a');
            link.innerText = 'Profile';
            link.href = '/app/team/' + item.id;
            link.onclick = (e) => {
                e.preventDefault();
                e.stopPropagation();

                this.ngZone.run(() => this.router.navigateByUrl('/app/team/' + item.id)).then();
            };

            content.querySelector('.user-info').append(link);

            const isWorking = document.createElement('span');
            isWorking.className = 'user-status';
            isWorking.title = item.workingMessage;

            if (item.isWorking) {
                isWorking.classList.add('available');
            }

            content.querySelector('.user-info')
                .append(isWorking);

            const pop = popup({
                offset: point(1, -25)
            }).setContent(content);

            return marker([item.geo.lat, item.geo.lon], {
                icon: mIcon,
            }).bindPopup(pop);
        };

        const markers = values.filter(item => !!item.geo).map(makeMarker);

        if (!markers || !markers.length) {
            return;
        }

        this.initOptions(markers);
    }

    initOptions(markers: Marker[]) {
        setTimeout(() => {
            this.options = {
                layers: [
                    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18}),
                    ...markers
                ],
                zoom: 2,
                center: latLng(40, 18),
                maxBounds: latLngBounds(latLng(-100, -200), latLng(100, 200))
            };
        });
    }
}
