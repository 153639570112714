<div class="form-group">
    <label
        class="control-label"
        [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
        [for]="field.id"
    >{{field.label|translate}}</label>
    <select
        [id]="field.id"
        [name]="field.name"
        class="form-control"
        [(ngModel)]="value"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
    >
        <option hidden [value]="''" selected>{{field.placeholder|translate}}</option>
        <option *ngFor='let option of options; let i = index' [value]="option.id">{{option.stringified}}</option>
    </select>
    <app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>
</div>
