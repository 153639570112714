<app-workbook-template-modal
    (closeModal)="closeModal($event)"
    *ngIf="addEditModal === true"
    [workbookTemplate]="editEntity"
></app-workbook-template-modal>
<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.workbook_templates_page.title'|translate"
        [buttonUploadFile]="'messages.admin.workbook_templates_page.add_template_file'|translate"
        search="true"
        (addComponent)="addEntity()"
        (paginateFilterSort)="paginateFilterSort($event)"
        (uploadComponent)="uploadFile($event)"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    class="col-id selected"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                >
                    <a (click)="sortByColumn('id')" style="cursor: pointer">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-status': field.type === 'boolean'}"
                >
                    <a (click)="sortByColumn(field.id)" style="cursor: pointer">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    class="col-10"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                >
                    <a (click)="sortByColumn('created_at')" style="cursor: pointer">
                        {{'messages.global.table_column_created_at'|translate}}
                    </a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id">
                                        <span>
                                            <span></span>
                                        </span>
                        </label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>
                    {{entity.name}}
                    <strong></strong>
                </td>
                <td>{{entity.description}}</td>
                <td class="col-status">
                    <app-status-icon [status]="entity.is_cache_enabled"></app-status-icon>
                </td>
                <td>{{entity.created_at}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="4">
                    <app-workbook-template-table-tr-info
                        [workbookTemplate]="entity"
                        (updateWorkbookTemplate)="updateEntity()"
                        (openModal)="addEntity(entity)"
                    ></app-workbook-template-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>
</div>
