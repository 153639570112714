<div class="form-style-bordered-inputs mb-4" style="max-width: 300px;" *ngIf="auth.isAdmin()">
    <div class="form-group">
        <label for="current-company">Company</label>
        <select class="form-control" id="current-company" [(ngModel)]="company" (change)="reloadComponent()">
            <option *ngFor="let company of companies" [value]="company.id">{{company.stringified}}</option>
        </select>
    </div>
</div>

<app-portal-addin-stats *ngIf="displayStats && company" [companyId]="company"></app-portal-addin-stats>
