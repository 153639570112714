import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../../../../models/models';

const tableText = '| header | header |\n' +
    '| ------ | ------ |\n' +
    '| cell | cell |\n' +
    '| cell | cell |';

@Component({
    selector: 'app-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownComponent),
            multi: true
        }
    ]
})
export class MarkdownComponent implements OnInit, ControlValueAccessor {
    @Input() isFieldValid: boolean;
    @Input() config: FormInputConfig;

    @ViewChild('editor') editor: ElementRef;

    public showEditor = true;
    public touch: any;
    private currentValue: string;

    constructor(private api: ApiService) {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: string) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setBold() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;
        this.insertText('**' + value + '**');

        if (!value?.length) {
            this.updCaretPosition(start + 2);
            return;
        }

        this.updCaretPosition(start + 4 + value.length);
    }

    setItalic() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('*' + value + '*');

        if (!value?.length) {
            this.updCaretPosition(start + 1);
            return;
        }

        this.updCaretPosition(start + 2 + value.length);
    }

    setQuote() {
        const value = this.getSelectedValue();
        const toReplace = value.replace(/\n/g, '\n> ');
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('> ' + toReplace);
        this.updCaretPosition(start + toReplace.length + 2);
    }

    setCode() {
        const value = this.getSelectedValue();
        const start = this.editor.nativeElement.selectionStart;
        let newValue = '';

        if (value.includes('\n')) {
            newValue = '```\n' + value + '\n```';
        } else {
            newValue = '`' + value + '`';
        }

        this.insertText(newValue);
        this.updCaretPosition(start + newValue.length);
    }

    setUrl() {
        const value = this.getSelectedValue();
        const newValue = '[' + value + '](url)';
        const start = this.editor.nativeElement.selectionStart;

        this.insertText('[' + value + '](url)');

        setTimeout(() => {
            this.editor.nativeElement.focus();
            this.editor.nativeElement.setSelectionRange(start + newValue.length - 4, start + newValue.length - 1);
        });
    }

    setBulletList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map(item => '- ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '- ';
        }

        this.replaceList(toReplace);
    }

    setNumberedList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map((item, index) => (index + 1) + '. ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '1. ';
        }

        this.replaceList(toReplace);
    }

    setAddList() {
        const value = this.getSelectedValue();
        let toReplace = value.split('\n')
            .map(item => item.trim())
            .filter(item => item.length)
            .map(item => '- [ ] ' + item)
            .join('\n');

        if (!toReplace.length) {
            toReplace = '- [ ] ';
        }

        this.replaceList(toReplace);
    }

    setTable() {
        const value = this.getSelectedValue();
        const toReplace = tableText + value;
        const start = this.editor.nativeElement.selectionStart;

        this.insertText(toReplace);
        this.updCaretPosition(start + toReplace.length);
    }

    handleFileDrop(e: DragEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        if (!e?.dataTransfer?.files?.length) {
            return;
        }

        this.uploadFiles(e.dataTransfer.files);
    }

    handleFileInput(e) {
        if (!e.target?.files) {
            return;
        }

        this.uploadFiles(e.target.files);
    }

    private uploadFiles(files: FileList) {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file_' + i, files.item(i), files.item(i).name);
        }

        this.api.uploadFileForMarkdown(formData).subscribe(data => {
            this.insertText(data.join('\n\n'), false);
        });
    }

    private replaceList(toReplace: string) {
        const start = this.editor.nativeElement.selectionStart;

        if (!(start === 0 || this.value.charAt(start - 1) === '\n')) {
            toReplace = '\n' + toReplace;
        }

        this.insertText(toReplace);
        this.updCaretPosition(start + toReplace.length);
    }

    private getSelectedValue(): string {
        const el = this.editor.nativeElement;

        const start = el.selectionStart;
        const end = el.selectionEnd;

        return this.value.slice(start, end).trim();
    }

    private updCaretPosition(position: number) {
        this.editor.nativeElement.focus();
        this.editor.nativeElement.setSelectionRange(position, position);
    }

    private insertText(value, replace = true) {
        const el = this.editor.nativeElement;
        el.focus();

        const start = el.selectionStart;
        const end = el.selectionEnd;

        const v = this.value;

        if (replace) {
            el.value = v.slice(0, start) + value + v.slice(end);
        } else {
            el.value = v.slice(0, start) + value + v.slice(start + 1);
        }

        el.dispatchEvent(new Event('input'));
    }
}
