<div class="container-fluid cover-bg-container request-to-join-page">
    <div class="panel-centered-wrapper">

        <form (ngSubmit)="onSubmit()" [formGroup]="registerForm" class="form-style-labels-inside-inputs panel-centered">
            <a class="no-pointer">
                <img alt="Tools group" class="panel-centered-logo" src="./../../../../assets/img/tg-logo.png">
            </a>

            <h1 class="panel-centered-header" *ngIf="token">
                {{'messages.resetting_page.page_title'|translate}}
            </h1>

            <ng-container *ngIf="!requested">

                <div class="alert alert-warning" *ngIf="!token">
                    {{'messages.resetting_page.first_login'|translate}}
                </div>

                <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

                <div class="form-group">
                    <label class="control-label required" for="login_form_password">
                        {{'messages.global.password'|translate}}
                    </label>

                    <input class="form-control" formControlName="password" id="login_form_password" name="password" required="required" type="password">
                    <div class="focus-highlight"></div>
                </div>

                <app-display-input-errors [errorsStrings]="getFieldErrors('password')"></app-display-input-errors>

                <div class="form-group">
                    <label class="control-label required" for="login_form_password_repeat">
                        {{'messages.right_side.account_form.confirm_password'|translate}}
                    </label>

                    <input class="form-control" formControlName="passwordRepeat" id="login_form_password_repeat" name="password" required="required" type="password">
                    <div class="focus-highlight"></div>
                </div>

                <app-display-input-errors [errorsStrings]="getFieldErrors('passwordRepeat')"></app-display-input-errors>

                <div class="panel-centered-actions">

                    <div *ngIf="loading" class="text-center">
                        <img alt="loading" src="./../../../../assets/img/loading.gif" style="height: 50px;">
                    </div>

                    <ng-container *ngIf="!loading">
                        <button class="btn btn-lg btn-primary text-capitalize" type="submit">
                            {{'messages.global.submit'|translate}}
                        </button>

                        <a [routerLink]="'/login'" class="btn btn-lg btn-very-dark-grey text-capitalize" role="button" style="color: #FFFFFF !important" *ngIf="token">
                            {{'messages.request_to_join_page.back'|translate}}
                        </a>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="requested">
                <div class="alert alert-info">
                    {{'messages.resetting_page.success_message'|translate}}
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 text-center">
                        <a [routerLink]="'/login'" class="btn btn-lg btn-very-dark-grey text-capitalize" role="button" style="color: #FFFFFF !important">
                            {{'messages.request_to_join_page.back'|translate}}
                        </a>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
