import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {
    AdminCompaniesList,
    AdminUsersList,
    CompanyModel,
    FormConfig,
    ProvidedEntity,
    SelectValue
} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-company-modal',
    templateUrl: './company-modal.component.html',
    styleUrls: ['./company-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class CompanyModalComponent implements OnInit {
    @Input() company: AdminCompaniesList;
    @Output() addComponent = new EventEmitter();
    @ViewChild(FormComponent, {static: true}) form: FormComponent<CompanyModel>;
    formConfig: FormConfig;
    lists: { [key: string]: any } = {};
    addNewUser = false;
    public companyData: AdminCompaniesList;
    private defaultFormConfig: FormConfig = {
        fields: [
            {
                name: 'folder',
                label: 'messages.admin.companies_page.form.company.fields.folder.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.folder.placeholder',
                id: 'company_folder',
                type: 'typeahead'
            },
            {
                name: 'name',
                label: 'messages.admin.companies_page.form.company.fields.name.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.name.label',
                id: 'company_name',
                type: 'text',
                validators: [Validators.required]
            },
            {
                name: 'address',
                label: 'messages.admin.companies_page.form.company.fields.address.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.address.label',
                id: 'company_address',
                type: 'text'
            },
            {
                name: 'owners',
                label: 'messages.admin.companies_page.form.company.fields.owners.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.owners.label',
                id: 'company_owners',
                type: 'typeahead',
                multiple: true,
                addAction: () => {
                    this.addNewUser = true;
                },
                validators: [Validators.required, Validators.minLength(1)]
            },
            {
                name: 'domains',
                label: 'messages.admin.companies_page.form.company.fields.domains.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.domains.label',
                id: 'company_domains',
                type: 'collection',
                collectionInputs: [
                    {
                        name: 'name',
                        type: 'text',
                        label: 'messages.admin.companies_page.domain',
                        validators: [Validators.required]
                    },
                ]
            },
            {
                name: 'locale',
                label: 'messages.admin.companies_page.form.company.fields.locale.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.locale.placeholder',
                id: 'company_language',
                type: 'select',
            },
            {
                name: 'timeZone',
                label: 'messages.admin.companies_page.form.company.fields.time_zone.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.time_zone.placeholder',
                id: 'company_timeZone',
                type: 'select',
                propertyPath: 'time_zone_code'
            },
            {
                name: 'createRoot',
                label: 'messages.admin.companies_page.create_root_folder',
                id: 'company_createRoot',
                type: 'checkbox'
            },
        ],
    };

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(4);

        this.entityProvider.getFolders({asRoot: true}).subscribe((data: ProvidedEntity[]) => {
            this.lists.folder = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getPossibleOwners(this.company?.id).subscribe((data: ProvidedEntity[]) => {
            this.lists.owners = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getTimezones(true).subscribe((data: SelectValue[]) => {
            this.lists.timeZone = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getLocales().subscribe((data: SelectValue[]) => {
            this.lists.locale = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        const formConfig = _.cloneDeep<FormConfig>(this.defaultFormConfig);

        if (this.company) {
            formConfig.fields = formConfig.fields.filter(item => item.name !== 'createRoot');

            const company = _.cloneDeep(this.company);
            company.domains = company.domains.map(item => ({name: item.stringified}));
            this.companyData = company;
        }

        this.formConfig = formConfig;
    }

    addCompany(data: CompanyModel) {
        const requestData = {...data};
        const {createRoot} = requestData;
        delete requestData.createRoot;

        const request = this.company
            ? this.apiService.updateCompany(this.company.id, requestData)
            : this.apiService.createCompany(requestData, createRoot);

        request.subscribe((response) => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    userAdded(event: { type: string, value?: any }) {
        this.addNewUser = false;

        if (event.type !== 'add' || !event.value) {
            return;
        }

        const {form} = this.form;

        const value = (event.value as AdminUsersList);
        const owners = form.value.owners ? [...form.value.owners] : [];
        owners.push({id: value.id, stringified: value.stringified});

        this.entityProvider.getUsers().subscribe((data: ProvidedEntity[]) => {
            this.lists = {...this.lists, owners: data};
        }, error => {
        });

        form.get('owners').setValue(owners);
    }
}
