import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';
import {AdminGroupRequestsList, AdminUserRequestsList} from '../../../models/models';
import {LayoutConfigService} from '../../../services/layout-config.service';

@Component({
    selector: 'app-pending-requests',
    templateUrl: './pending-requests.component.html',
    styleUrls: ['./pending-requests.component.scss']
})
export class PendingRequestsComponent implements OnInit {

    public sorting: { column: string };
    public busy: boolean;
    public selectedRequestUser;
    public selectedRequestGroup;
    public fieldsNameUsers: { id: string, name: string, type: string }[] = [
        {
            id: 'name',
            name: 'messages.admin.pending_requests_page.table.th.name',
            type: 'string'
        },
        {
            id: 'group',
            name: 'messages.admin.pending_requests_page.table.th.group',
            type: 'string'
        },
        {
            id: 'date',
            name: 'messages.admin.pending_requests_page.table.th.date',
            type: 'string'
        },
        {
            id: 'ip',
            name: 'messages.admin.pending_requests_page.table.th.ip',
            type: 'string'
        }
    ];
    public fieldsNameGroups: { id: string, name: string, type: string }[] = [
        {
            id: 'name',
            name: 'messages.admin.pending_requests_page.table.th.name',
            type: 'string'
        },
        {
            id: 'group',
            name: 'messages.admin.pending_requests_page.table.th.group',
            type: 'string'
        },
        {
            id: 'date',
            name: 'messages.admin.pending_requests_page.table.th.date',
            type: 'string'
        },
        {
            id: 'ip',
            name: 'messages.admin.pending_requests_page.table.th.ip',
            type: 'string'
        }
    ];
    groupRequests: AdminGroupRequestsList[];
    userRequests: AdminUserRequestsList[];

    constructor(private apiService: ApiService,
                public paginationService: PaginationService,
                public layoutConfigService: LayoutConfigService) {
    }

    ngOnInit() {
        this.sorting = {column: 'name'};
        this.busy = false;
        this.loadUserRequestsList();
        this.loadGroupRequestsList();

    }

    loadUserRequestsList() {
        this.apiService.getUserRequests().subscribe(response => {
            this.userRequests = response;
        });
    }

    loadGroupRequestsList() {
        this.apiService.getGroupRequests().subscribe(response => {
            this.groupRequests = response;
        });
    }

    selectRequestUser(id: string) {
        if (this.selectedRequestUser === id) {
            this.selectedRequestUser = null;
        } else {
            this.selectedRequestUser = id;
        }
    }

    selectRequestGroup(id: string) {
        if (this.selectedRequestGroup === id) {
            this.selectedRequestGroup = null;
        } else {
            this.selectedRequestGroup = id;
        }
    }

    acceptGroup() {
        return this.apiService.acceptGroupRequest(this.selectedRequestGroup).subscribe(() => {
            this.loadGroupRequestsList();
            this.apiService.getNotifications().subscribe(response => {
                this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
            });
        });
    }

    denyGroup() {
        return this.apiService.denyGroupRequest(this.selectedRequestGroup).subscribe(() => {
            this.loadGroupRequestsList();
            this.apiService.getNotifications().subscribe(response => {
                this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
            });
        });
    }

    acceptUser() {
        return this.apiService.acceptUserRequest(this.selectedRequestUser).subscribe(() => {
            this.loadUserRequestsList();
            this.apiService.getNotifications().subscribe(response => {
                this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
            });
        });
    }

    denyUser() {
        return this.apiService.denyUserRequest(this.selectedRequestUser).subscribe(() => {
            this.loadUserRequestsList();
            this.apiService.getNotifications().subscribe(response => {
                this.layoutConfigService.setNotificationsCount(response.pending_requests_count);
            });
        });
    }
}
