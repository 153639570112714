import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PING_INTERVAL} from './constants';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PingService {

    constructor(private api: ApiService, private auth: AuthService, private toast: ToastrService) {
    }

    private ping$ = new Subject<boolean>();
    private pingInterval: any;
    private inProgress = false;

    start() {
        if (!this.pingInterval) {
            this.ping();
            this.pingInterval = setInterval(this.ping, PING_INTERVAL);
        }
    }

    stop() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }
    }

    onPing() {
        return this.ping$.asObservable();
    }

    private ping = () => {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;

        this.api.ping().subscribe(data => {
            this.auth.setExpiredAt(moment(data.expired_at).format('x'));
            setTimeout(() => {
                const user = JSON.parse(localStorage.getItem('EVO-USER'));
                if (user.company_id !== data.company_id) {
                    this.toast.warning('Your company was changed, we will reload your webpage in 5 seconds', 'Warning', {timeOut: 5000});
                    setTimeout(() => {
                        window.location.href = 'app/reports';
                    }, 5000);
                }
            }, 5000);
            this.ping$.next(true);
            this.inProgress = false;
        }, error => {
            this.inProgress = false;
            // clearInterval(this.pingInterval);
            // this.pingInterval = null;
        });
    }
}
