<app-form-loading [loading]="loading"></app-form-loading>
<div class="form-style-bordered-inputs dashboard-builder" [ngStyle]="{'display': loading ? 'none' : 'block'}">

    <div class="col-12 col-md-12 padding-top-5 col-min-width-550">
        <div class="page-title">
            <h1>Dashboard builder</h1>
        </div>
        <div class="row" *ngIf="!selectedClientId || !selectedDatasourceId">
            <div class="col-lg-4">
                <p class="alert alert-danger">Please select a client and then a datasource</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="form-group">
                    <label class="control-label dynamic-font" for="client">Client</label>
                    <select class="form-control" id="client" [(ngModel)]="selectedClientId" (change)="setClient()">
                        <option hidden [value]="" selected></option>
                        <option *ngFor='let option of clientsSelect; let i = index' [value]="option.value">{{option.name}}</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="selectedDatasourceId">
                    <label class="control-label" for="internal-name">Internal Name</label>
                    <input id="internal-name" class="form-control" [id]="'internal-name'" [(ngModel)]="internalName"/>
                </div>

                <div class="form-group" *ngIf="selectedDatasourceId">
                    <label class="control-label" for="title" >Title</label>
                    <input id="title" class="form-control" [id]="'title'" [(ngModel)]="contentTitle"/>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" *ngIf="selectedClientId">
                <div class="form-group">
                    <label class="control-label dynamic-font" for="datasource">Datasource</label>
                    <select class="form-control" id="datasource" [(ngModel)]="selectedDatasourceId" (change)="setDatasource()">
                        <option *ngFor='let option of datasourcesSelect; let i = index' [value]="option.id" [selected]="selectedDatasourceId == option.id">{{option.name}}</option>
                    </select>
                </div>

                <div class="form-group " style="margin-bottom: 15px;" *ngIf="selectedDatasourceId">
                    <label class="control-label">&nbsp;</label>
                    <div class="checkbox checkbox-gray">
                        <input type="checkbox" class="ng-pristine" [(ngModel)]="contentEnabled" id="content_is_enabled"/>
                        <label for="content_is_enabled">
                            <span><span></span></span>
                            Activated
                        </label>
                    </div>
                </div>

                <div class="form-group" *ngIf="selectedDatasourceId">
                    <div class="input-group">
                        <div class="form-group">
                            <label class="control-label" [for]="'title'+ selectedDatasourceId">Subtitle</label>
                            <input class="form-control" [id]="'title'+ selectedDatasourceId" [(ngModel)]="contentSubtitle"/>
                        </div>

                        <div class="checkbox checkbox-gray padding-vertical-0 margin-bottom-0" style="padding-top: 17px !important;">
                            <input id="showResetAll" type="checkbox" class="ng-pristine ng-untouched ng-valid ng-not-empty" [(ngModel)]="configObject?.data.showResetAllButton" (change)="updateChart($event)">
                            <label for="showResetAll"><span><span></span></span>Show "reset all"</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="selectedDatasourceId">
            <div class="row">
                <div class="col-lg-2 col-2">
                    <div class="page-title">
                        <h1 class="line-height-28">Charts</h1>
                    </div>
                </div>
                <div class="col-lg-6 col-8 text-right action-buttons">
                    <button class="btn btn-square btn-xs btn-primary" type="button" style="cursor: pointer;" *ngIf="selectedDatasourceId" (click)="openPreviewModal($event)">
                        <span class=" ion-eye" aria-hidden="true"></span>
                        Preview data
                    </button>
                    <button class="btn btn-square btn-xs btn-primary" type="button" style="cursor: pointer;" *ngIf="selectedDatasourceId" (click)="openEditModal($event)">
                        <span class=" ion-eye" aria-hidden="true"></span>
                        Edit config
                    </button>
                    <button class="btn btn-square btn-xs btn-primary" style="cursor: pointer;" type="button" *ngIf="selectedDatasourceId" (click)="downloadConfig($event)">
                        <span class=" ion-eye" aria-hidden="true"></span>
                        Download config
                    </button>
                    <button class="btn btn-square btn-xs btn-primary" type="button" style="cursor: pointer;" *ngIf="selectedDatasourceId" (click)="uploadConfigAction()">
                        <input #uploadFile (change)="onUploadFileSelected($event.target.files) //noinspection UnresolvedVariable" style="display: none" type="file"/>
                        <span class=" ion-eye" aria-hidden="true"></span>
                        Upload config
                    </button>
                    <button class="btn btn-square btn-xs btn-primary" type="button" style="cursor: pointer;" *ngIf="selectedDatasourceId" (click)="openDatasourceModal($event)">
                        <span class=" ion-eye" aria-hidden="true"></span>
                        Load datasource
                    </button>
                    <button class="btn btn-square btn-xs btn-primary" type="button" style="cursor: pointer;" (click)="addNewChart()" *ngIf="selectedDatasourceId">
                        <span class=" ion-stats-bars" aria-hidden="true"></span>
                        Add chart
                    </button>
                    <button id="saveDashboard" type="button" style="cursor: pointer;" *ngIf="selectedDatasourceId && showSaveButton" data-ng-disabled="dashboardSaveDisabled" class="btn btn-square btn-xs btn-primary" (click)="saveDashboard()">
                        <span class=" ion-android-download" aria-hidden="true"></span>
                        Save
                    </button>
                </div>
            </div>

            <div class="row margin-top-10">
                <div aria-multiselectable="true" role="tablist" class="card-group chart-accordion col-8 col-md-8" id="chart-accordion">
                    <ng-container *ngFor="let chart of configObject?.charts;let i = index;">
                        <app-builder-line-chart
                            *ngIf="chart.type=='line'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-line-chart>
                        <app-builder-bar-chart
                            *ngIf="chart.type=='bar'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-bar-chart>
                        <app-builder-row-chart
                            *ngIf="chart.type=='row'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-row-chart>
                        <app-builder-pie-chart
                            *ngIf="chart.type=='pie'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-pie-chart>
                        <app-builder-dropdown-chart
                            *ngIf="chart.type=='select-menu'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-dropdown-chart>
                        <app-builder-map-chart
                            *ngIf="chart.type=='map'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-map-chart>
                        <app-builder-table-chart
                            *ngIf="chart.type=='pivot-table'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-table-chart>
                        <app-builder-table-chart
                            *ngIf="chart.type=='table'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-table-chart>
                        <app-builder-number-chart
                            *ngIf="chart.type=='number'"
                            [defaultConfig]="defaultConfig"
                            [supportedXColumns]="supportedXColumns[chart.templateId]"
                            [supportedYColumns]="supportedYColumns[chart.templateId]"
                            [weightByColumns]="this.weightByColumnNames"
                            [chartConfig]="chart"
                            [index] = "i"
                            (changeChartEmitter)="addNewChart($event)"
                            (updateConfigEmitter)="updateChart($event)"
                            (removeChartEmitter)="removeChart($event)"
                        ></app-builder-number-chart>
                    </ng-container>
                </div>
            </div>

            <div class="dc-data-count dc-chart float-left" style="margin-left: 10px; margin-top: 17px;margin-bottom: 15px;" *ngIf="configObject?.data.showResetAllButton" #dataCount (click)="resetAll()">{{'messages.web.dashboards.records_selected' | translate}}</div>
            <div class="clearfix"></div>
            <ng-container *ngFor="let chart of configObject?.charts; index as i">
                <app-line-chart *ngIf="chart.type == 'line'" [chartConfig]="chart"></app-line-chart>
                <app-bar-chart *ngIf="chart.type == 'bar'" [chartConfig]="chart"></app-bar-chart>
                <app-row-chart *ngIf="chart.type == 'row'" [chartConfig]="chart"></app-row-chart>
                <app-map-chart *ngIf="chart.type == 'map'" [chartConfig]="chart"></app-map-chart>
                <app-dropdown-chart *ngIf="chart.type == 'dropdown'" [chartConfig]="chart"></app-dropdown-chart>
                <app-number-chart *ngIf="chart.type == 'number'" [chartConfig]="chart"></app-number-chart>
                <app-pie-chart *ngIf="chart.type == 'pie'" [chartConfig]="chart"></app-pie-chart>
                <app-pivot-table-chart
                    *ngIf="chart.type == 'pivot-table'"
                    [chartConfig]="chart"
                ></app-pivot-table-chart>
                <app-table-chart *ngIf="chart.type == 'table'" [chartConfig]="chart"></app-table-chart>
            </ng-container>
        </ng-container>


        <ng-container>
            <app-modal
                [mTitle]="'messages.global.confirm'|translate"
                (mClose)="closePreviewModalWindow()"
                [withSubmit]="false"
                *ngIf="showPreviewModal"
            >
                <div class="text-left">
                    <table class="table table-bordered table-responsive text-overflow">
                        <thead>
                        <tr>
                            <th
                                style="text-wrap: none !important;text-overflow: ellipsis !important;overflow: hidden !important;"
                                title="{{column.name}}"
                                *ngFor="let column of configObject?.data.columns"
                            >
                                {{column.name}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of getPreviewData()">
                            <td
                                *ngFor="let column of  configObject?.data.columns"
                                style="text-wrap: none !important;text-overflow: ellipsis !important;overflow: hidden !important;"
                            >
                                    <span
                                        *ngIf="column.type == 'date'"
                                        title="{{ item[column.name] | date:'dd-MM-yyyy' }}"
                                    >{{ item[column.name] | date:'dd-MM-yyyy' }}</span>
                                <span
                                    *ngIf="column.type == 'float'"
                                    title="{{ item[column.name] }}"
                                >{{ item[column.name] | number }}</span>
                                <span
                                    *ngIf="column.type != 'date' && column.type != 'float'"
                                    title="{{ item[column.name] }}"
                                >{{ item[column.name] }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </app-modal>

            <span class="confirm-container">
                <span class="confirm-overlay" (click)="openPreviewModal($event)"></span>
            </span>
        </ng-container>

        <ng-container>
            <app-modal
                [mTitle]="'Copy or modify config'|translate"
                (mClose)="closeEditModalWindow()"
                (mSubmit)="saveConfig()"
                [withSubmit]="true"
                *ngIf="showEditModal"
            >
                <div class="form-style-bordered-inputs">
                    <div class="form-group">
                        <textarea
                            class="form-control"
                            rows="10"
                            style="width: 100%; height: auto"
                            [(ngModel)]="editConfigObject"
                        ></textarea>
                    </div>
                </div>
            </app-modal>

            <span class="confirm-container">
                <span class="confirm-overlay" (click)="openEditModal($event)"></span>
            </span>
        </ng-container>

        <ng-container>
            <app-modal
                [mTitle]="'Copy or modify datasource'|translate"
                (mClose)="closeDatasourceModalWindow()"
                (mSubmit)="setDatasourceFromModal()"
                [withSubmit]="true"
                *ngIf="showDatasourceModal"
            >
                <div class="form-style-bordered-inputs">
                    <div class="form-group">
                        <textarea
                            class="form-control"
                            rows="10"
                            style="width: 100%; height: auto"
                            [(ngModel)]="setDatasourceObject"
                        ></textarea>
                    </div>
                </div>
                <p class="alert alert-danger">This is only for demo purposes, the dashboard save will be disabled and you cannot save it but only see it for testing purposes!!!</p>
            </app-modal>

            <span class="confirm-container">
                <span class="confirm-overlay" (click)="openEditModal($event)"></span>
            </span>
        </ng-container>

    </div>
</div>
