import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminUsersList, FormConfig, UserProfileModel} from '../../../../models/models';
import {FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {AuthService} from '../../../../services/auth.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-user-profile-modal',
    templateUrl: './user-profile-modal.component.html',
    styleUrls: ['./user-profile-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class UserProfileModalComponent implements OnInit {
    @ViewChild(FormComponent, {static: true}) form: FormComponent<UserProfileModel>;

    constructor(private formBuilder: FormBuilder,
                private api: ApiService,
                private provider: EntityProviderService,
                private errorsTransformer: FormErrorsTransformerService,
                private auth: AuthService,
                public  formLoadingServiceService: FormLoadingServiceService
    ) {
    }

    private defaultFormConfig: FormConfig = {
        fields: [
            {name: 'firstName', type: 'text'},
            {name: 'lastName', type: 'text'},
            {name: 'locale', type: 'provided-entity-select', listValuesKey: 'locales'},
            {name: 'linkedInProfile', type: 'text'},
            {name: 'googleAccount', type: 'text'},
            {name: 'gitlabUsername', type: 'text'},
            {name: 'githubAccount', type: 'text'},
            {name: 'country', type: 'provided-entity-select', listValuesKey: 'countries'},
            {name: 'evoEmail', type: 'text'},
            // {name: 'assistant', type: 'textarea'},
            // {name: 'assistantPhone', type: 'textarea'},
            // {name: 'assistantEmail', type: 'textarea'},
            {name: 'emergencyPerson', type: 'text'},
            {name: 'emergencyPersonPhone', type: 'text'},
            {name: 'topicOfExpertise', type: 'textarea'},
            {name: 'capabilityOfSkills', type: 'textarea'},
            {name: 'learningCourses', type: 'textarea'},
            {name: 'studies', type: 'textarea'},
            {name: 'languages', type: 'text'},
            {name: 'webinarsSeminars', type: 'textarea'},
            {name: 'address', type: 'text'},
            {name: 'address2', type: 'text'},
            {name: 'town', type: 'text'},
            {name: 'province', type: 'text'},
            {name: 'zipCode', type: 'text'},
            {name: 'state', type: 'text'},
            {name: 'personalEmail', type: 'text'},
            {name: 'skype', type: 'text'},
            {name: 'phone', type: 'text'},
            {name: 'mobile', type: 'text'},
            {name: 'achievements', type: 'textarea'},
            {name: 'hobbies', type: 'textarea'},
            {name: 'highestDegree', type: 'text'},
            {name: 'degreeScore', type: 'text'},
            {name: 'degreeMaxScore', type: 'text'},
            {name: 'degreeAchievementPlace', type: 'text'},
            {name: 'degreeTown', type: 'text'},
            {name: 'degreeDate', type: 'date', format: 'YYYY-MM-DD'},
            {name: 'joiningDate', type: 'date', format: 'YYYY-MM-DD'},
            {name: 'leaveDate', type: 'date', format: 'YYYY-MM-DD'},
            {name: 'dateOfBirth', type: 'date', format: 'YYYY-MM-DD'},
            {name: 'department', listValuesKey: 'departments', type: 'provided-entity-select'},
            {name: 'role', listValuesKey: 'roles', type: 'provided-entity-select'},
            {name: 'level', listValuesKey: 'levels', type: 'provided-entity-select'},
            {name: 'intensity', listValuesKey: 'intensities', type: 'provided-entity-select'},
            {name: 'dietary', listValuesKey: 'dietaries', type: 'provided-entity-select'},
            {name: 'sourceOfHiring', listValuesKey: 'sourcesOfHiring', type: 'provided-entity-select'},
            {name: 'hiringManager', listValuesKey: 'hiringManagers', type: 'provided-entity-select'},
            {
                name: 'emergencyPersonRelationship',
                listValuesKey: 'emergencyPersonRelationships',
                type: 'provided-entity-select'
            },
            {name: 'commitment', listValuesKey: 'commitments', type: 'provided-entity-select'},
            {name: 'gender', listValuesKey: 'genders', type: 'provided-entity-select'},
            {name: 'maritalStatus', listValuesKey: 'maritalStatuses', type: 'provided-entity-select'},
            {name: 'timeZone', listValuesKey: 'timeZones', type: 'provided-entity-select'},
            {name: 'badges', listValuesKey: 'badges', type: 'typeahead', multiple: true},
            {
                name: 'daysOfLearning',
                type: 'text',
                inputType: 'number',
                validators: [Validators.max(Number.MAX_SAFE_INTEGER)]
            },
            {
                name: 'daysOfDissemination',
                type: 'text',
                inputType: 'number',
                validators: [Validators.max(Number.MAX_SAFE_INTEGER)]
            },
            {
                name: 'budgetSpend',
                type: 'text',
                inputType: 'number',
                validators: [Validators.max(Number.MAX_SAFE_INTEGER)]
            },
            {
                name: 'holidaysAllowance',
                type: 'text',
                inputType: 'number',
               // validators: [Validators.max(Number.MAX_SAFE_INTEGER)]
            },
        ],
    };


    formConfig: FormConfig;
    data = null;
    lists: { [key: string]: any } = {};
    errors: any;

    @Input() user: AdminUsersList;
    @Output() closeModal = new EventEmitter();
    @Output() updateProfileSubmit = new EventEmitter();

    ngOnInit() {
        if (!this.user) {
            throw new Error('User should be set!');
        }

        this.formLoadingServiceService.init(2);

        this.provider.getProfileListsValues(this.user?.id, this.user?.company?.id).subscribe(data => {
            this.lists = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.api.getUserProfile(this.user.id).subscribe(data => {
            this.data = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        const config = _.cloneDeep(this.defaultFormConfig);

        config.fields.forEach(item => {
            item.label = 'messages.list_values.fields.' + item.name;
            let id;

            switch (item.type) {
                case 'text':
                case 'textarea':
                    id = 'text_' + item.name;
                    break;

                case 'date':
                    id = 'date_' + item.name;
                    break;
                case 'provided-entity-select':
                case 'select':
                    id = 'list_' + item.name;
                    break;
                case'typeahead':
                    id = 'typeahead_' + item.name;
                    break;
                default:
                    id = item.name;
            }

            item.id = id;
        });

        this.formConfig = config;
    }

    updateProfile(data: UserProfileModel) {
        this.api.updateUserProfile(this.user.id, data).subscribe(() => {
            this.updateProfileSubmit.emit();

            if (this.user.id.toString() === this.auth.getLoggedUser().id.toString()) {
                this.auth.refreshUserInfo();
            }
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.closeModal.emit({type: 'profile'});
    }
}
