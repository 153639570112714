import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminCompaniesList, FormConfig, FormLists, ProvidedEntity} from "../../../../models/models";
import {FormComponent} from "../../../../modules/form-generator/components/form/form.component";
import {FormBuilder} from "@angular/forms";
import {EntityProviderService} from "../../../../services/entity-provider.service";
import {FormLoadingServiceService} from "../../../../services/form-loading-service.service";
import {ApiService} from "../../../../services/api.service";
import {FormErrorsTransformerService} from "../../../../services/form-errors-transformer.service";

interface ManagersForm {
    hrs: any[];
}

@Component({
  selector: 'app-company-hrs-modal',
  templateUrl: './company-hrs-modal.component.html',
  styleUrls: ['./company-hrs-modal.component.scss']
})
export class CompanyHrsModalComponent implements OnInit {

    @Input() company: AdminCompaniesList;
    @Output() addComponent = new EventEmitter();

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ManagersForm>;

    public errors;
    public formConfig: FormConfig = {
        fields: [
            {
                name: 'hrs',
                label: 'HR\'s',
                placeholder: '',
                id: 'hrs',
                type: 'typeahead',
                multiple: true
            }
        ],
    };

    lists: FormLists = {};

    public users: ProvidedEntity[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
        private errorsTransformer: FormErrorsTransformerService
    ) {
    }

    ngOnInit() {
        const {company} = this;

        if (!company) {
            throw new Error('Company is required!');
        }

        this.formLoadingServiceService.init(1);

        this.entityProvider.getUsers(company.id).subscribe((data: ProvidedEntity[]) => {
            this.lists.hrs = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    updateManagers(data: ManagersForm) {
        const request = this.apiService.updateCompanyHRs(this.company.id, data);

        request.subscribe((response) => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    formGlobalErrors(): string[] {
        return this.errorsTransformer.getGlobalErrors(this.errors);
    }
}
