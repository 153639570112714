<app-modal
    [mTitle]="'messages.right_side.groups.modal_title'|translate"
    (mClose)="closeModalWindow()"
    (mSubmit)="requestGroups()"
    [loading]="submitted"
>
    <app-form-loading [loading]="formLoadingServiceService.isLoading()"></app-form-loading>

    <div *ngIf="!formLoadingServiceService.isLoading()">
        <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

        <form
            name="myForm"
            [formGroup]="form"
            role="form"
            autocomplete="off"
            novalidate="novalidate"
            class="form-style-bordered-inputs"
        >
            <div
                *ngFor="let item of formData.controls; index as i"
                formArrayName="groups"
            >
                <div [formGroupName]="i">
                    <div class="checkbox">
                        <input
                            type="checkbox"
                            [id]="'group_' + i"
                            formControlName="checked"
                        >
                        <label [for]="'group_' + i">
                            <span><span></span></span>
                            {{groups[i].stringified}}
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-modal>
