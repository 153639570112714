import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from '../services/api.service';
import {AuthService} from '../services/auth.service';
import {environment} from '../../environments/environment';

@Pipe({
    name: 'sentMailAttachmentUrl'
})
export class SentMailAttachmentUrlPipe implements PipeTransform {


    constructor(private api: ApiService, private auth: AuthService) {
    }

    transform(file: {id: number}) {
        if (environment.production) {
            return '/attachments/sent-mail/' + file.id;
        }

        return `${this.api.apiURL}/attachments/sent-mail/${file.id}?api_key=${this.auth.getApiKey()}`;
    }
}
