import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';
import {ROLE_ADMIN} from '../../../../services/constants';

@Component({
    selector: 'app-company-table-tr-info',
    templateUrl: './company-table-tr-info.component.html',
    styleUrls: ['./company-table-tr-info.component.scss']
})
export class CompanyTableTrInfoComponent implements OnInit {

    @Input() company: any;
    @Output() openModal = new EventEmitter();
    @Output() openManagersModal = new EventEmitter();
    @Output() openHrsModal = new EventEmitter();
    @Output() updateCompany = new EventEmitter();

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'git_project_id',
            name: 'Git project id',
            type: 'string'
        },
        {
            id: 'time_zone',
            name: 'messages.admin.companies_page.table_column_time_zone',
            type: 'string'
        },
        {
            id: 'locale',
            name: 'messages.admin.companies_page.table_column_language',
            type: 'string'
        },
        {
            id: 'domains',
            name: 'messages.admin.companies_page.table_column_domains',
            type: 'list'
        },
        {
            id: 'owners',
            name: 'messages.admin.companies_page.table_column_owners',
            type: 'list'
        },
        {
            id: 'hrs',
            name: 'HR\'s',
            type: 'list'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        },
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
        if (this.auth.isSuperAdmin()) {
            this.fields.push({
                id: 'managers',
                name: 'messages.admin.companies_page.managers',
                type: 'list'
            });
        }
    }

    isAdmin() {
        return this.auth.isGranted(ROLE_ADMIN);
    }

    editCompany() {
        return this.openModal.emit();
    }

    editCompanyManagers() {
        return this.openManagersModal.emit();
    }

    editCompanyHRs() {
        return this.openHrsModal.emit();
    }

    deleteCompany() {
        this.apiService.deleteCompany(this.company.id).subscribe(response => {
            this.updateCompany.emit();
        });
    }

    toggleActive() {
        this.apiService.toggleActiveCompany(this.company.id).subscribe(response => {
            this.updateCompany.emit();
        });
    }

    toggleLoginCount() {
        this.apiService.toggleShowLoginCount(this.company.id).subscribe(response => {
            this.updateCompany.emit();
        });
    }

    toggleWorkbooksMaintenance() {
        this.apiService.toggleWorkbookMaintenance(this.company.id).subscribe(response => {
            this.updateCompany.emit();
        });
    }

    toggleBoardEnabled() {
        this.apiService.toggleBoardEnabled(this.company.id).subscribe(response => {
            this.updateCompany.emit();
        });
    }
}
