import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss']
})
export class MarkdownComponent implements OnInit, OnDestroy {
    @Input() html;

    @ViewChild('content') content: ElementRef;
    public menu = [];
    public windowWidth = 9999;
    private resizeListener: any;

    constructor() {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.makeMenu();
        });

        this.resizeListener = () => {
            this.windowWidth = window.innerWidth;
        };

        window.addEventListener('resize', this.resizeListener);
        this.windowWidth = window.innerWidth;
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeListener);
    }

    makeMenu() {
        const elements = this.content.nativeElement.querySelectorAll('h1, h2, h3, h4, h5');

        if (!elements || !elements.length) {
            this.menu = [];
            return;
        }

        const data = [];

        for (const el of elements) {
            data.push({
                el,
                text: el.innerText,
                tag: el.tagName
            });
        }

        this.menu = data;
    }
}
