import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileSecurityService} from '../../../../services/profile-security.service';
import {InfoColumn, ProfileData} from '../../../../models/models';

@Component({
    selector: 'app-profile-column-form',
    templateUrl: './profile-column-form.component.html',
    styleUrls: ['./profile-column-form.component.scss']
})
export class ProfileColumnFormComponent implements OnInit {
    @Input() column: InfoColumn;
    @Input() profile: ProfileData;
    @Output() closeForm = new EventEmitter<boolean>();

    public showForm = false;


    constructor(public security: ProfileSecurityService) {
    }

    ngOnInit() {
    }

    close(refresh = false) {
        this.showForm = false;
        this.closeForm.emit(refresh);
    }
}
