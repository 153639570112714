<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="(workbook ? 'messages.admin.workbooks_page.form.workbook.title_edit' : 'messages.admin.workbooks_page.form.workbook.title_create')|translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        [value]="workbook"
        [lists]="lists"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="addWorkbook($event)"
    >
        <ng-container *ngIf="isInited">
            <div class="form-group form-group-inline" style="display: flex; align-items: center">
                <label style="width: 120px">
                    <label class="control-label required">
                        {{'messages.admin.workbooks_page.form.workbook.fields.chart.label'|translate}}
                    </label>
                </label>
                <div class="checkbox checkbox-inline checkbox-gray" style="width: auto">
                    <input
                        (change)="changeStatusChartLink($event.target.checked)"
                        [checked]="isChartLink"
                        id="chart_active"
                        type="checkbox"
                    >
                    <label for="chart_active">
                                        <span>
                                            <span></span>
                                        </span>
                        {{portalUrl}}/
                    </label>
                </div>
                <div style="line-height: 0">
                    <app-input-text
                        formName="workbooksFields"
                        fieldName="chart"
                        [formControl]="form?.form.get('chart')"
                        [withLabel]="false"
                    ></app-input-text>
                </div>
            </div>
            <div class="form-group form-group-inline" style="display: flex; align-items: center">
                <label style="width: 120px">
                    <label class="control-label required">
                        {{'messages.admin.workbooks_page.form.workbook.fields.presentation.label'|translate}}
                    </label>
                </label>
                <div class="checkbox checkbox-inline checkbox-gray" style="width: auto">
                    <input
                        (change)="changeStatusPresentationLink($event.target.checked)"
                        [checked]="isPresentationLink"
                        id="presentation_active"
                        type="checkbox"
                    >
                    <label for="presentation_active">
                                        <span>
                                            <span></span>
                                        </span>
                        {{portalUrl}}/
                    </label>
                </div>
                <div style="line-height: 0">
                    <app-input-text
                        formName="workbooksFields"
                        fieldName="presentation"
                        [formControl]="form?.form.get('presentation')"
                        [withLabel]="false"
                    ></app-input-text>
                </div>
            </div>
        </ng-container>
    </app-form>
</app-modal>

