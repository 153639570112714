import {Component, OnInit} from '@angular/core';
import {LayoutConfigService} from '../../services/layout-config.service';

@Component({
    selector: 'app-sidebar-right',
    templateUrl: './sidebar-right.component.html',
    styleUrls: ['./sidebar-right.component.css']
})
export class SidebarRightComponent implements OnInit {

    constructor(public layoutConfigService: LayoutConfigService) {
    }

    ngOnInit() {
    }

}
