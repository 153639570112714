import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {Calendar} from '@fullcalendar/core';
import * as moment from 'moment';
import {ApiService} from '../../../services/api.service';
import {LEAVE_REQUEST_STATUSES, LEAVE_REQUEST_TYPES} from '../../../services/constants';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {SelectValue} from '../../../models/models';
import {EntityProviderService} from '../../../services/entity-provider.service';

const defaultGroupOptions: SelectValue[] = [
    {name: 'Me', value: 'me'},
    {name: 'My team', value: 'my_team'},
    {name: 'My company', value: 'company'}
];

@Component({
    selector: 'app-work-calendar',
    templateUrl: './work-calendar.component.html',
    styleUrls: ['./work-calendar.component.scss'],
})
export class WorkCalendarComponent implements OnInit, OnDestroy {

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
    };

    public showModal = false;
    public loading = false;
    public holidaysUsed = 0;
    public holidaysRemain = 0;
    public holidaysTotal = 0;
    public group = defaultGroupOptions[0].value;
    public groupOptions: SelectValue[] = [];

    @ViewChild('calendar') calendarComponent: FullCalendarComponent;

    private calendarApi: Calendar;
    private from;
    private to;
    private dateChange$ = new Subject();

    constructor(private api: ApiService, private auth: AuthService, private eProvider: EntityProviderService) {
    }

    ngOnInit(): void {
        this.dateChange$.pipe(debounceTime(1000)).subscribe(({start, end}) => {
            this.loadList(start, end);
        });

        this.eProvider.getDepartments().subscribe(data => {
            this.groupOptions = [
                ...defaultGroupOptions,
                ...data.map(i => ({name: 'Department: ' + i.stringified, value: 'department_' + i.id}))
            ];

            this.group = this.groupOptions[0]?.value;
        });

        setTimeout(() => {
            this.calendarApi = this.calendarComponent.getApi();
            this.calendarApi.on('datesSet', (e) => {
                this.calendarApi.removeAllEvents();
                this.dateChange$.next(e);
            });

            const {activeStart, activeEnd} = this.calendarApi.view;
            this.loadList(activeStart, activeEnd);
        });
    }


    loadList(from: Date, to: Date) {
        this.from = from;
        this.to = to;
        this.calendarApi.removeAllEvents();

        this.loading = true;
        // const centerCol = document.querySelector('div.page-center-column') as HTMLElement;
        // centerCol.style.overflowY = 'hidden';

        this.api.checkAllowanceForUser(this.auth.getLoggedUser().id, this.calendarApi.view.currentStart.getFullYear())
            .subscribe(data => {
                this.holidaysUsed = data.total_holidays_allowance - data.holidays_remain;
                this.holidaysRemain = data.holidays_remain;
                this.holidaysTotal = data.total_holidays_allowance;
            });

        this.api.getHolidaysRequest({
            from,
            to,
            max_results: 100,
            only_possible: true,
            group: this.group
        }).subscribe(data => {
            data.forEach(item => {
                const [status] = LEAVE_REQUEST_STATUSES.filter(i => i.name === item.status);
                const [type] = LEAVE_REQUEST_TYPES.filter(i => i.value === item.type);

                this.calendarApi.addEvent({
                    title: `${item.user_name} (${type?.name})`,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                    color: status?.color || '#999',
                    textColor: '#fff'
                });
            });

            this.loading = false;
            // centerCol.style.overflowY = null;
        }, () => {
            this.loading = false;
            // centerCol.style.overflowY = null;
        });
    }

    addRequest() {
        this.showModal = false;
        this.loadList(this.from, this.to);
        this.closeModalWindow();
    }

    closeModalWindow() {
        this.showModal = false;
    }

    ngOnDestroy() {
        this.dateChange$.complete();
    }

    handleGroupChange() {
        this.loadList(this.from, this.to);
    }

    back() {
        window.history.back();
    }
}
