<div class="row-expansion-body">
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="scheduling_edit_{{scheduling.id}}"
            (click)="editScheduling()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <app-modal-confirm>
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="scheduling_delete_{{scheduling.id}}"
                (click)="delete()"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
