import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './components/form/form.component';
import {AutoHeightTextareaComponent} from './components/inputs/auto-height-textarea/auto-height-textarea.component';
import {DatepickerComponent} from './components/inputs/datepicker/datepicker.component';
import {InputTextComponent} from './components/inputs/input-text/input-text.component';
import {ProvidedEntitySelectComponent} from './components/inputs/provided-entity-select/provided-entity-select.component';
import {SelectComponent} from './components/inputs/select/select.component';
import {TranslationsModule} from '../translations/translations.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TypeaheadInputComponent} from './components/inputs/typeahead-input/typeahead-input.component';
import {InputErrorsComponent} from './components/input-errors/input-errors.component';
import {CollectionComponent} from './components/inputs/collection/collection.component';
import {InputRendererComponent} from './components/inputs/input-renderer/input-renderer.component';
import {CheckboxComponent} from './components/inputs/checkbox/checkbox.component';
import {ControlListsValuePipe} from './pipes/control-lists-value.pipe';
import {ControlValuePipe} from './pipes/control-value.pipe';
import {ControlGetFormPipe} from './pipes/control-get-form.pipe';
import {HtmlEditorComponent} from './components/inputs/html-editor/html-editor.component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {MarkdownComponent} from './components/inputs/markdown/markdown.component';
import {MarkdownPipe} from './pipes/markdown.pipe';
import {GlIconComponent} from './components/common/gl-icon/gl-icon.component';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PopoverModule} from "ngx-bootstrap/popover";

@NgModule({
    declarations: [
        FormComponent,
        AutoHeightTextareaComponent,
        DatepickerComponent,
        InputTextComponent,
        ProvidedEntitySelectComponent,
        SelectComponent,
        TypeaheadInputComponent,
        InputErrorsComponent,
        CollectionComponent,
        InputRendererComponent,
        CheckboxComponent,
        ControlListsValuePipe,
        ControlValuePipe,
        ControlGetFormPipe,
        HtmlEditorComponent,
        MarkdownComponent,
        MarkdownPipe,
        GlIconComponent,
    ],
    exports: [
        FormComponent,
        InputErrorsComponent
    ],
    imports: [
        CommonModule,
        TranslationsModule,
        FormsModule,
        BsDatepickerModule,
        ReactiveFormsModule,
        EditorModule,
        TimepickerModule,
        NgbModule,
        PopoverModule,
    ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ]
})
export class FormGeneratorModule {
}
