<div class="form-group">
    <label
        *ngIf="config?.label"
        [for]="config?.id || ''"
        class="control-label"
    >{{ config?.label|translate}}
    </label>
    <textarea
        #area
        [id]="config?.id || ''"
        [name]="config?.id || ''"
        class="form-control auto-height"
        [ngClass]="{'is-invalid' : !isFieldValid}"
        (input)="autoHeight($event.target)"
        [(ngModel)]="value"
        [placeholder]="config?.placeholder || ''"
    ></textarea>
    <ng-content>

    </ng-content>
</div>
