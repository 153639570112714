import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LayoutConfigService} from '../../services/layout-config.service';
import {ScreenshotService} from '../../services/screenshot.service';
import {ReportsService} from '../../services/reports.service';
import {ApiService} from '../../services/api.service';
import {GlobalErrorService} from '../../services/global-error.service';
import {PingService} from '../../services/ping.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LastSessionTrackerService} from '../../services/last-session-tracker.service';

@Component({
    selector: 'app-root',
    templateUrl: './frontend.component.html',
    styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements OnInit, OnDestroy {
    @ViewChild('screenshotContent') screenshotContent: ElementRef;
    public isUserAuthenticated = false;
    private pingObs: any;
    private routeObs: any;
    public isFramed = false;

    constructor(public layoutConfigService: LayoutConfigService,
                public screenshotService: ScreenshotService,
                public reportsService: ReportsService,
                private api: ApiService,
                public globalErrorService: GlobalErrorService,
                private pingService: PingService,
                private router: Router,
                private lastSessionTracker: LastSessionTrackerService,
                private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.layoutConfigService.hideSidebarRight();
        this.layoutConfigService.hideLeftPanelSubscribe();

        this.pingObs = this.pingService.onPing().subscribe((val) => {
            this.isUserAuthenticated = val;
            setTimeout(() => {
                this.screenshotService.setScreenshotContent(this.screenshotContent);
            });
        });

        this.pingService.start();
        this.lastSessionTracker.start();

        this.routeObs = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.pingService.start();
            }
        });

        this.route.queryParams.subscribe((params) => {
          this.isFramed = params.framed;
        });
    }

    ngOnDestroy(): void {
        this.pingService.stop();
        this.pingObs.unsubscribe();
        this.routeObs.unsubscribe();
        this.lastSessionTracker.stop();
    }
}
