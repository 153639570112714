<div>
    <app-table-title
        class="w-100"
        (paginateFilterSort)="paginateFilterSort($event)"
        [titleTable]="'messages.messaging.emails.sent_emails'|translate"
        search="true"
    ></app-table-title>
    <div class="ng-binding">
        <table
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            class="table table-expandable expand-single"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                    class="col-id selected"
                >
                    <a (click)="sortByColumn('id')" style="cursor: pointer">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-status': field.type === 'boolean', 'col-10': field.id === 'contents_count', 'col-15': field.id === 'folder_path'}"
                >
                    <a (click)="sortByColumn(field.id)" style="cursor: pointer">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'created_at'}"
                    class="col-15"
                >
                    <a (click)="sortByColumn('created_at')" style="cursor: pointer">
                        {{'messages.global.table_column_created_at'|translate}}
                    </a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'created_at' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                (click)="selectEntity(entity)"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                class="clickable collapsed ng-scope"
            >
                <td>{{entity.id}}</td>
                <td>
                    {{entity.subject}}
                </td>
                <td>{{entity.created_at}}</td>
            </tr>
            <tr [ngClass]="{'expanded': selectedEntity?.id === entity.id}" class="row-expansion ng-scope">
                <td></td>
                <td colspan="2">
                    <app-sent-messages-table-tr-info [email]="entity"></app-sent-messages-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        (paginateFilterSort)="paginateFilterSort($event)"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        [withExport]="false"
        [withSelectAll]="false"
    ></app-table-footer>
</div>
