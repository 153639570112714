<div class="form-group">
    <label
        [for]="config.id || ''"
        [style.color]="!isFieldValid ? '#a94442' :null"
        class="control-label"
    >{{ config.label|translate}}</label>
    <editor
        (onBlur)="touch()"
        [(ngModel)]="value"
        [disabled]="disabled"
        [id]="config.id || ''"
        [init]="editorConfig"
        [name]="config.id || ''"
        [ngClass]="{'is-invalid' : !isFieldValid}"
    >
    </editor>

    <input #fileUploader style="display: none" type="file">

    <ng-content>
    </ng-content>
</div>
