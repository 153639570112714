import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Content} from '../../../../../models/models';
import {ApiService} from '../../../../../services/api.service';
import {AuthService} from '../../../../../services/auth.service';

@Component({
    selector: 'app-handbook',
    templateUrl: './handbook.component.html',
    styleUrls: ['./handbook.component.scss']
})
export class HandbookComponent implements OnInit, OnDestroy {
    @Input() content: Content;
    @Input() creator: {
        id: number;
        name: string;
        department: string;
    };

    public text = '';
    public menu = [];
    public relatedContents = [];
    private centerColumn;
    public windowWidth = 9999;
    private resizeListener: any;

    @ViewChild('handbook') handbook: ElementRef;

    constructor(private api: ApiService, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.processText();
        setTimeout(() => {
            if (window.location.hash) {
                const el = document.querySelector('.page-center-column ' + window.location.hash);

                if (el) {
                    el.scrollIntoView();
                }
            }

            this.makeMenu();
            this.makeRelatedContents();
        });

        this.centerColumn = document.querySelector('.page-center-column');

        if (this.centerColumn) {
            this.centerColumn.style.paddingTop = 0;
        }

        this.resizeListener = () => {
            this.windowWidth = window.innerWidth;
        };

        window.addEventListener('resize', this.resizeListener);
        this.windowWidth = window.innerWidth;
    }

    ngOnDestroy() {
        if (this.centerColumn) {
            this.centerColumn.style.paddingTop = null;
            this.centerColumn = null;
        }

        window.removeEventListener('resize', this.resizeListener);
    }

    processText() {
        let text = this.content.ngx_content || this.content.content;

        if (!text) {
            this.text = '';
            return;
        }

        const baseUrl = window.location.pathname;

        const matches = text.match(/href="(#.+)"/g);

        if (matches) {
            for (const match of matches) {
                const data = match.match(/"(#.+)"/);
                text = text.replace(match, `href="${baseUrl}${data[1]}"`);
            }
        }

        this.text = text;
    }

    makeMenu() {
        const elements = this.handbook.nativeElement.querySelectorAll('h1, h2, h3, h4, h5');

        if (!elements || !elements.length) {
            this.menu = [];
            return;
        }

        const data = [];

        for (const el of elements) {
            data.push({
                el,
                text: el.innerText,
                tag: el.tagName
            });
        }

        this.menu = data;
    }

    makeRelatedContents() {
        const elements = this.handbook.nativeElement.querySelectorAll('a');

        if (!elements || !elements.length) {
            this.relatedContents = [];
            return;
        }

        const data = [];

        for (const el of elements) {
            data.push({
                el,
                text: el.innerText,
                href: el.href,
                tag: el.tagName
            });
        }

        this.relatedContents = data;
    }
}
