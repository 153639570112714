import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisplayError, InfoColumn, ProfileData, SelectValue, WorkingHoursModel} from '../../../../models/models';
import {ProfileSecurityService} from '../../../../services/profile-security.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {ApiService} from '../../../../services/api.service';
import * as moment from 'moment';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {MAX_INT} from '../../../../services/constants';

@Component({
    selector: 'app-profile-working-hours-column',
    templateUrl: './profile-working-hours-column.component.html',
    styleUrls: ['./profile-working-hours-column.component.scss']
})
export class ProfileWorkingHoursColumnComponent implements OnInit {
    @Input() column: InfoColumn;
    @Input() profile: ProfileData;
    @Input() workingHours: WorkingHoursModel;
    @Output() closeForm = new EventEmitter<boolean>();

    public form: FormGroup;
    public workingHoursForm: FormGroup;
    public showForm = false;
    public submitted = false;
    public errors: DisplayError[] = [];
    public timeZones: SelectValue[];

    days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    frequencies = ['never', 'rarely', 'sometimes', 'often', 'always'];
    workingHoursVariants = [1, 2, 3, 4, 5];

    constructor(public security: ProfileSecurityService,
                private formBuilder: FormBuilder,
                private errorsTransformer: FormErrorsTransformerService,
                private api: ApiService,
                private provider: EntityProviderService
    ) {
    }

    ngOnInit() {
        this.provider.getTimezones(true).subscribe(data => {
            this.timeZones = data;
        });
    }

    open() {
        const workingHoursForm = this.formBuilder.group({
            unavailableTime: [this.workingHours.unavailableTime],
            hoursPerWeek: [this.workingHours.hoursPerWeek],
            workTimePercentage: [this.workingHours.workTimePercentage],
        });

        this.form = this.formBuilder.group({
            timeZone: [''],
            holidaysAllowance: [
                this.profile.holidaysAllowance,
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(MAX_INT)
                ]
            ],
            workingHours: workingHoursForm,
        });

        this.workingHoursForm = workingHoursForm;

        this.days.forEach(day => {
            const from = !!this.workingHours[`${day}From`]
                ? moment(this.workingHours[`${day}From`], 'HH:mm').toDate()
                : null;

            const to = !!this.workingHours[`${day}To`]
                ? moment(this.workingHours[`${day}To`], 'HH:mm').toDate()
                : null;

            workingHoursForm.addControl(`${day}From`, this.formBuilder.control(from));
            workingHoursForm.addControl(`${day}To`, this.formBuilder.control(to));
            workingHoursForm.addControl(`${day}Frequency`, this.formBuilder.control(this.workingHours[`${day}Frequency`]));
        });

        const [zone] = this.timeZones.filter(item => item.name === this.profile.timeZone);

        if (zone) {
            this.form.get('timeZone').setValue(zone.value);
        }

        this.showForm = true;
    }

    close(refresh = false) {
        this.showForm = false;
        this.closeForm.emit(refresh);
    }

    updateWorkingHours() {
        if (this.submitted) {
            return;
        }

        this.errors = [];
        const {form} = this;

        if (form.status === 'INVALID') {
            return;
        }

        this.submitted = true;

        const {workingHours: wh, ...rest} = form.value;
        const workingHours = {...wh};

        Object.keys(workingHours).forEach(key => {
            if (!!workingHours[key] && (key.search(/From$/) !== -1 || key.search(/To$/) !== -1)) {
                if (isNaN(workingHours[key].getTime())) {
                    workingHours[key] = null;
                    return;
                }

                workingHours[key] = moment(workingHours[key]).format('HH:mm');
            }
        });

        this.api.updateTeamPerson(this.profile.id, {workingHours, ...rest}).subscribe(() => {
            this.submitted = false;
            this.close(true);
        }, response => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(form, response);
            this.submitted = false;
        });
    }

    replicateToAll() {
        const data = this.getMondayData();

        this.days.forEach(day => {
            this.workingHoursForm.get(`${day}From`).setValue(data.from);
            this.workingHoursForm.get(`${day}To`).setValue(data.to);
            this.workingHoursForm.get(`${day}Frequency`).setValue(data.frequency);
        });
    }

    replicateToWorkingDays() {
        const data = this.getMondayData();

        this.days.slice(0, -2).forEach(day => {
            this.workingHoursForm.get(`${day}From`).setValue(data.from);
            this.workingHoursForm.get(`${day}To`).setValue(data.to);
            this.workingHoursForm.get(`${day}Frequency`).setValue(data.frequency);
        });
    }

    checkHoursPerWeek(value) {
        const form = this.workingHoursForm.get('hoursPerWeek');

        if (!form) {
            return;
        }

        if (value > 100) {
            form.setValue(100);

            return;
        }

        if (value < 0) {
            form.setValue(0);
            return;
        }

        if (!value) {
            form.setValue('');
        }
    }

    clearDay(day: string) {
        this.workingHoursForm.get(`${day}From`).setValue(null);
        this.workingHoursForm.get(`${day}To`).setValue(null);
    }

    private getMondayData() {
        const {mondayFrom, mondayTo, mondayFrequency} = this.workingHoursForm.value;

        return {
            from: mondayFrom,
            to: mondayTo,
            frequency: mondayFrequency,
        };
    }
}
