import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ContentModel, FormConfig, FormInputConfig, FormLists} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {ContentFormConfig} from '../../../../modules/common-modals/components/formConfigs';
import * as _ from 'lodash';

@Component({
    selector: 'app-content-modal',
    templateUrl: './content-modal.component.html',
    styleUrls: ['./content-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ContentModalComponent implements OnInit {

    @Output() addComponent = new EventEmitter();
    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentsFields)
    };

    lists: FormLists = {};

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(1);

        this.entityProvider.getContentTypes().subscribe(data => {
            this.lists.type = data;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addContent(data: ContentModel) {
        this.apiService.createContent(data).subscribe((response) => {
            this.addComponent.emit();
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }
}
