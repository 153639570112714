import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        /** TODO: Report error in a file or backend api for logging? */
        console.error(error);
        // this.toastr.error('An error occurred, please try again', 'Error');
    }
}
