<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p class="form-control-static">{{propertyAccessor.getValue(version, field.id) || ' -- '}}</p>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="version_edit"
            (click)="editVersion()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <ng-container *ngIf="!version.is_active">
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="version_toggle"
                (click)="toggleStatus()"
            >
                <i class="icon icon-size-9 ion-ionic"></i>
                {{'messages.admin.xls_page.table.td.toggle_status'|translate}}
            </button>
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="version_toggle_supported"
                (click)="toggleSupported()"
            >
                <i class="icon icon-size-9 ion-power"></i>
                {{'messages.admin.xls_page.toggle_supported_version'|translate}}
            </button>
            <app-modal-confirm>
                <button
                    type="button"
                    class="btn btn-square btn-xs btn-primary margin-left-5"
                    id="version_delete"
                    (click)="delete()"
                >
                    <i class="icon icon-size-9 ion-trash-a"></i>
                    {{'messages.global.delete'|translate}}
                </button>
            </app-modal-confirm>
        </ng-container>
        <input type="file" style="display: none" #uploadFile (change)="uploadVersion($event)">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            *ngIf="!version.filename"
            id="version_upload"
            (click)="uploadFile.click()"
        >
            <i class="icon icon-size-9 ion-upload"></i>
            {{'messages.admin.xls_page.table.td.upload'|translate}}
        </button>
        <a
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            *ngIf="version.filename"
            id="version_download"
            (click)="downloadVersion()"
        >
            <i class="icon icon-size-9 ion-android-download"></i>
            {{'messages.admin.xls_page.table.td.download'|translate}}
        </a>
    </div>
</div>
