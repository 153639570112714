<div class="ng-isolate-scope">
    <form class="form-style-bordered-inputs ng-valid ng-dirty ng-valid-parse" [formGroup]="form">
        <div class="sidebar-header first-section">
            <span class="startup-header-green">
                <i class="icon icon-size-11 ion-settings color-light-green"></i>
                {{'messages.right_side.settings.title' | translate}}
            </span>
        </div>
        <div class="sidebar-body">
            <app-form-loading [loading]="formLoadingServiceService.isLoading()"></app-form-loading>
            <ng-container *ngIf="!formLoadingServiceService.isLoading()">

                <div *ngIf="!authService.isGranted('ROLE_CONTENT_MANAGER')">
                    <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

                    <app-radio-select
                        [errors]="errors"
                        [formName]="formName"
                        [options]="startupOptions"
                        fieldName="startupOption"
                        formControlName="startupOption"
                    >
                    </app-radio-select>
                    <label for="startupFolder" class="sr-only">
                        {{'messages.right_side.settings.folder' | translate}}
                    </label>
                    <ng-container *ngIf="form.get('startupOption').value === false">
                        <select
                            id="startupFolder"
                            class="ng-pristine ng-valid ng-empty ng-touched"
                            formControlName="startupFolder"
                        >
                            <option value=""> {{'messages.right_side.settings.no_item' | translate}}</option>

                            <option
                                *ngFor="let report of reports.sections"
                                [ngValue]="report"
                            >{{report.name}}
                            </option>
                        </select>
                        <select
                            id="startupFolder2"
                            class="ng-pristine ng-valid ng-empty ng-touched"
                            formControlName="startupPage"
                        >
                            <option value="">{{'messages.right_side.settings.choose_one' | translate}}</option>
                            <ng-container
                                *ngIf="form.value.startupFolder"
                            >
                                <option
                                    *ngFor="let page of form.value.startupFolder.pages"
                                    [ngValue]="page"
                                >{{page.name}}
                                </option>
                            </ng-container>

                        </select>
                    </ng-container>
                </div>

                <div *ngIf="authService.isGranted('ROLE_CONTENT_MANAGER')" class="ng-hide">
                    <p class="alert alert-warning">
                        {{'messages.right_side.settings.startup_settings_not_available' | translate}}</p>
                </div>
                <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="menu_labels_switch"
                        [checked]="layoutConfigService.getMenuLabels()"
                        (change)="onMenuLabelChange($event.target.checked)"
                    >
                    <label
                        class="custom-control-label"
                        for="menu_labels_switch"
                    >{{'messages.right_side.settings.menu_labels' | translate}}</label>
                </div>
                <div
                    *ngIf="lastLogin"
                    class="margin-top-25 small ng-binding"
                >{{'messages.global.last_login'| translate}}
                    : {{lastLogin | date:'dd LLLL yyyy, HH:mm z'}}
                </div>
            </ng-container>
        </div>
    </form>
</div>
