<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.pending_requests_page.groups.title'|translate"
        search="false"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th *ngFor="let field of fieldsNameUsers; let index = field" class="col-40">
                    <a>{{field.name|translate}}</a>
                    <span class></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let groupRequest of groupRequests; let index = request">
            <tr class="clickable collapsed ng-scope" (click)="selectRequestGroup(groupRequest.id)">
                <td>{{groupRequest.user.stringified}}</td>
                <td>{{groupRequest.group.name}}</td>
                <td>{{groupRequest.date|date:'dd/MM/yyyy'}}</td>
                <td>{{groupRequest.ip}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedRequestGroup === groupRequest.id}">
                <td colspan="6">
                    <app-request-table-tr-info
                        [request]="groupRequest"
                        (onAcceptAction)="acceptGroup()"
                        (onDenyAction)="denyGroup()"
                    ></app-request-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr>
    <div class="actions text-center">
        <br>
        <div class="row"></div>
    </div>
</div>

<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.pending_requests_page.users.title'|translate"
        search="false"
    ></app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th *ngFor="let field of fieldsNameGroups; let index = field" class="col-40">
                    <a>{{field.name|translate}}</a>
                    <span class></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let userRequest of userRequests; let index = request">
            <tr class="clickable collapsed ng-scope" (click)="selectRequestUser(userRequest.id)">
                <td>{{userRequest.stringified}}</td>
                <td>{{userRequest.email}}</td>
                <td>{{userRequest.createdAt|date:'dd/MM/yyyy'}}</td>
                <td>{{userRequest.ip}}</td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedRequestUser === userRequest.id}">
                <td colspan="6">
                    <app-request-table-tr-info
                        [request]="userRequest"
                        (onAcceptAction)="acceptUser()"
                        (onDenyAction)="denyUser()"
                    ></app-request-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr>
    <div class="actions text-center">
        <br>
        <div class="row"></div>
    </div>
</div>
