import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WcResourcesInjectorService {

    private cssNodes: Set<HTMLLinkElement>;
    private jsNodes: Set<HTMLScriptElement>;

    private currentJs: Set<string>;
    private currentCss: Set<string>;


    constructor() {
        this.currentJs = new Set<string>();
        this.currentCss = new Set<string>();

        this.jsNodes = new Set<HTMLScriptElement>();
        this.cssNodes = new Set<HTMLLinkElement>();
    }

    // You can't redefine custom components, so make refresh to see updated app
    addJs(path: string) {
        localStorage.setItem('API-URL', environment.apiURL);

        return new Promise((resolve, reject) => {
            if (this.currentJs.has(path)) {
                resolve(true);
                return;
            }

            const node = document.createElement('script');
            node.src = path;

            if (!path.includes('?')) {
                node.src += '?_r' + Date.now();
            }

            node.type = 'text/javascript';
            node.onload = () => {
                resolve(true);
            };

            node.onerror = () => {
                reject(false);
            };

            document.getElementsByTagName('head')[0].appendChild(node);
            this.jsNodes.add(node);
            this.currentJs.add(path);
        });
    }

    addCss(path: string) {
        if (this.currentCss.has(path)) {
            return;
        }

        const link = document.createElement('link');

        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = path;

        if (!path.includes('?')) {
            link.href += '?_r' + Date.now();
        }

        document.getElementsByTagName('head')[0].appendChild(link);
        this.cssNodes.add(link);
        this.currentCss.add(path);
    }
}
