import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PaginationService} from '../services/pagination.service';
import {Injectable} from '@angular/core';

@Injectable()
export class PaginationInterceptor implements HttpInterceptor {

    constructor(public paginationService: PaginationService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    if (this.paginationService.responseHasHeaders(response)) {
                        this.paginationService.setPagination(response);
                    }
                }
            }));

    }

}
