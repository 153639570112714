import {Component, OnInit} from '@angular/core';
import {AdminMessagesList, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

    public inactive: boolean;
    public busy: boolean;
    public entities: AdminMessagesList[];
    public selectedIds = [];
    public selectedEntity: AdminMessagesList;
    public addEditModal: boolean;
    private csvFile = 'evo-messsages.csv';
    public fieldsName = [
        {
            id: 'title',
            name: 'messages.admin.messages_page.table_column_title',
            type: 'string'
        },
        {
            id: 'users',
            name: 'messages.admin.messages_page.table_column_to',
            type: 'number'
        }
    ];


    public editEntity: AdminMessagesList | null;

    constructor(private apiService: ApiService, public paginationService: PaginationService) {
    }

    ngOnInit() {
        this.addEditModal = false;
        this.paginationService.pager = this.paginationService.initPager();
        this.paginationService.pager.sorting = {column: 'id', direction: 'desc'};
        this.inactive = false;

        if (this.paginationService.pager.filters) {
            this.paginationService.pager.filters = this.paginationService.pager.filters.filter(el => el.key !== 'hide_inactive');
        }

        this.paginationService.pager.filters.push({key: 'hide_inactive', value: this.inactive ? 'false' : 'true'});
        this.loadList();

        this.busy = false;
    }

    loadList() {
        this.apiService.getAdminMessages(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminMessagesList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    addEntity(entity: AdminMessagesList = null) {
        this.addEditModal = true;
        this.editEntity = entity;
    }

    checkEntity(entity: any) {
        const addedIndex = this.selectedIds.indexOf(entity.id);
        if (addedIndex >= 0) {
            this.selectedIds.splice(addedIndex, 1);
            entity.checked = false;
        } else {
            this.selectedIds.push(entity.id);
            entity.checked = true;
        }
    }

    checkAllEntities(status: boolean) {
        this.selectedIds = [];

        if (status) {
            for (const user of this.entities) {
                this.selectedIds.push(user.id);
                user.checked = true;
            }
        } else {
            for (const user of this.entities) {
                user.checked = false;
            }
        }
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getAdminMessages(pager).subscribe(response => {
            this.entities = response;
        });
    }

    updateEntity() {
        this.editEntity = null;
        this.addEditModal = false;
        this.loadList();
    }

    sortByColumn(id: string) {
        this.paginationService.checkSorting(id);
        this.apiService.getAdminMessages(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    closeModal(type: string) {
        this.editEntity = null;
        this.addEditModal = false;
        this.loadList();
    }

    exportAll() {
        this.apiService.exportMessages().subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    exportSelected() {
        this.apiService.exportSelectedMessages(this.selectedIds).subscribe(response => {
            this.apiService.downloadFileClientSide(response, this.csvFile);
        });
    }

    deleteSelected() {
        this.apiService.deleteSelectedMessages(this.selectedIds).subscribe(() => {
            this.selectedIds = [];
            this.apiService.getAdminMessages(this.paginationService.pager).subscribe(response => {
                this.entities = response;
            });
        });
    }
}
