import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DisplayError} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {FormErrorsTransformerService} from '../../../services/form-errors-transformer.service';
import {FieldsService} from '../../../services/fields.service';
import {MatchValidator} from '../../../validators/matchValidators';

@Component({
    selector: 'app-password-reset-form',
    templateUrl: './password-reset-form.component.html',
    styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    requested = false;
    error: string;
    public token: string | null;
    private errors: DisplayError[] = [];

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private authService: AuthService,
                private router: Router,
                private loadingService: LoadingService,
                private fromErrors: FormErrorsTransformerService,
                private fieldsService: FieldsService,
                private routeSnapshot: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.token = this.routeSnapshot.snapshot.paramMap.get('token') || null;
        this.loadingService.clearEvents();
        this.createForm();
    }

    createForm() {
        this.registerForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            passwordRepeat: ['', [Validators.required, Validators.minLength(8)]],
        }, {validators: [MatchValidator('password', 'passwordRepeat')]});
    }

    onSubmit() {
        if (this.loading) {
            return;
        }

        this.errors = this.fromErrors.getErrors(this.registerForm);

        if (this.registerForm.status === 'INVALID') {
            return;
        }

        const data = {
            plainPassword: {
                first: this.registerForm.value.password,
                second: this.registerForm.value.passwordRepeat
            }
        };

        this.loading = true;

        this.apiService.resetPassword(data, this.token).subscribe(response => {
            this.loading = false;
            this.authService.logout();
            this.requested = true;
        }, error => {
            this.loading = false;
            this.errors = this.fromErrors.getErrorsFromResponse(this.registerForm, error);
        });
    }

    formGlobalErrors() {
        return this.fromErrors.getGlobalErrors(this.errors);
    }

    getFieldErrors(field: string) {
        return this.fieldsService.getFieldsErrors(this.errors, field, '');
    }
}
