<div class="ng-isolate-scope">
    <form class="form-style-bordered-inputs ng-valid ng-dirty ng-valid-parse" [formGroup]="form">
        <div class="sidebar-header first-section">
            <span class="startup-header-green">
                <i class="icon icon-size-11 ion-settings color-light-green"></i>
                {{'messages.themes.theme' | translate}}
            </span>
        </div>
        <div class="sidebar-body">
            <app-radio-select
                formName="themeSettings"
                [options]="themeOptions"
                fieldName="themeSettings"
                formControlName="theme"
            >
            </app-radio-select>
        </div>
    </form>
</div>
