<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
            [innerHTML]="message[field.id] !== null ? message[field.id] : ' -- '"
        ></p>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto" *ngIf="field.type === 'list'"
            [ngStyle]="{'max-width': field.id === 'folders' && '600px'}"
        >
            <p class="form-control-static" *ngIf="message[field.id].length === 0"> -- </p>
            <ul class="comma-separated-list" *ngIf="message[field.id].length !== 0">
                <li *ngFor="let item of message[field.id]; let index = item">{{item.stringified}}</li>
            </ul>
        </div>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="messages_edit_{{message.id}}"
            (click)="editMessage()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <app-modal-confirm>
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="messages_delete_{{message.id}}"
                (click)="delete()"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
