import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ProvidedEntity, UserProfileListValue} from '../../../models/models';
import {PaginationService} from '../../../services/pagination.service';
import {EntityProviderService} from '../../../services/entity-provider.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {Title} from '@angular/platform-browser';
import {DEFAULT_PAGE_TITLE} from '../../../services/constants';

const orders = [
    {column: 'firstName', strigified: 'messages.global.first_name'},
    {column: 'lastName', strigified: 'messages.global.last_name'},
    {column: 'department', strigified: 'messages.list_values.fields.department'},
    {column: 'role', strigified: 'messages.list_values.fields.role'},
    {column: 'level', strigified: 'messages.list_values.fields.level'},
    {column: 'country', strigified: 'messages.list_values.fields.country'},
    {column: 'joiningDate', strigified: 'messages.list_values.fields.tenure'},
];

@Component({
    selector: 'app-users-profiles-list',
    templateUrl: './users-profiles-list.component.html',
    styleUrls: ['./users-profiles-list.component.scss']
})
export class UsersProfilesListComponent implements OnInit, OnDestroy {

    public profiles: UserProfileListValue[] = [];
    public paginationOptions = [100, 50, 20];

    public departments: any = [];
    public orders: { column: string, direction: string, strigified: string }[] = [];
    public additionalDataKeys = ['level', 'country', 'joiningDate'];

    searchInputChanged = new Subject<string>();
    searchSub;
    order: { column: string, direction: string, strigified: string } = null;
    companies: ProvidedEntity[] = [];
    currentCompany: any;

    constructor(
        private api: ApiService,
        public auth: AuthService,
        private provider: EntityProviderService,
        private layoutConfigService: LayoutConfigService,
        private titleService: Title,
        public pagination: PaginationService,
        private entityProvider: EntityProviderService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.pagination.pager = this.pagination.initPager();
        this.pagination.paginationOptions = [...this.paginationOptions];
        this.pagination.pager.sorting = {column: 'id', direction: 'desc'};
        this.pagination.pager.nr_of_items_per_page = this.paginationOptions[0];
        this.titleService.setTitle('Team — Evo');
        this.orders = [];

        orders.forEach(order => {
            this.orders.push({...order, direction: 'asc'});
            this.orders.push({...order, direction: 'desc'});
        });

        const [currentOrder] = this.orders.filter(item => item.column === 'joiningDate' && item.direction === 'desc');

        if (currentOrder) {
            this.order = currentOrder;
            this.pagination.pager.sorting.column = this.order.column;
            this.pagination.pager.sorting.direction = this.order.direction;
        }

        setTimeout(() => {
                if (this.layoutConfigService.sidebarLeft) {
                    this.layoutConfigService.sidebarLeftToggle();
                }
            }
        );

        this.entityProvider.getDepartments().subscribe(data => {
            this.departments = data;
        });

        if (this.auth.isSuperAdmin()) {
            this.provider.getCompanies().subscribe(data => {
                this.companies = data;
                const [company] = data.filter(item => item.id === this.auth.getLoggedUser().company_id);

                this.currentCompany = company ? company.id : null;
                this.updateCompany(company ? company.id : null);
            });
        } else {
            this.loadList();
        }

        this.searchSub = this.searchInputChanged
            .pipe(
                debounceTime(500))
            .subscribe((value) => {
                if (this.pagination.pager.filters) {
                    this.pagination.pager.filters = this.pagination.pager.filters.filter(el => el.key !== 'term');
                }

                if (value) {
                    this.pagination.pager.filters.push({key: 'term', value: value.toString().trim()});
                }

                this.loadList();
            });
    }

    ngOnDestroy(): void {
        if (this.searchSub) {
            this.searchSub.unsubscribe();
        }

        this.titleService.setTitle(DEFAULT_PAGE_TITLE);

        if (!this.layoutConfigService.sidebarLeft) {
            this.layoutConfigService.sidebarLeftToggle();
        }
    }

    loadList() {
        this.api.getTeam(this.pagination.pager).subscribe(data => {
            this.profiles = data.map(profile => {
                const value = {...profile, workingMessage: ''};

                if (!profile.nextWorkingDay) {
                    value.workingMessage = 'Not available';
                    return value;
                }

                if (profile.isOnHoliday) {
                    value.workingMessage = 'On holidays. ';
                }

                value.workingMessage += profile.isWorking ? 'Is working now' : 'Back working ' + profile.nextWorkingDay;

                return value;
            });
        });
    }

    updateDepartment(value) {
        if (this.pagination.pager.filters) {
            this.pagination.pager.filters = this.pagination.pager.filters.filter(el => el.key !== 'department');
        }

        if (value) {
            this.pagination.pager.filters.push({key: 'department', value});
        }

        this.loadList();
    }

    updateCompany(value) {
        if (this.pagination.pager.filters) {
            this.pagination.pager.filters = this.pagination.pager.filters.filter(el => el.key !== 'company');
        }

        if (value) {
            this.pagination.pager.filters.push({key: 'company', value});
        }

        this.loadList();
    }

    updateOrder() {
        this.pagination.pager.sorting.column = 'id';
        this.pagination.pager.sorting.direction = 'desc';

        if (this.order) {
            this.pagination.pager.sorting.column = this.order.column;
            this.pagination.pager.sorting.direction = this.order.direction;
        }

        this.loadList();
    }

    executeSearch(event) {
        this.searchInputChanged.next(event.target.value);
    }

    onPaginationChange(event) {
        this.pagination.pager.nr_of_items_per_page = event.target.value;
        this.loadList();
    }

    gotToNextPage() {
        this.pagination.pager.current_page += 1;
        this.loadList();
    }

    gotToLastPage() {
        this.pagination.pager.current_page = this.pagination.pager.last_page;
        this.loadList();
    }

    gotToPreviousPage() {
        this.pagination.pager.current_page -= 1;
        this.loadList();
    }

    gotToFirstPage() {
        this.pagination.pager.current_page = 1;
        this.loadList();
    }

    goToUser(id) {
        this.router.navigate(['/app/team/' + id]);
    }
}
