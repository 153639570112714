<div class="row-expansion-body">
    <div class="text-left"></div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_accept_{{request.id}}"
            (click)="accept()"
        >
            <i class="icon icon-size-9 ion-checkmark-circled"></i>
            {{'messages.admin.pending_requests_page.table.td.accept'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="users_deny_{{request.id}}"
            (click)="deny()"
        >
            <i class="icon icon-size-9 ion-close-circled"></i>
            {{'messages.admin.pending_requests_page.table.td.deny'|translate}}
        </button>
    </div>
</div>
