import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FieldsService} from '../../services/fields.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DisplayError} from '../../models/models';
import * as moment from 'moment';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        FieldsService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {
    public field: any;
    public value: string;
    public statusError: boolean;
    public errorMessage: any;
    public defaultDateFormat = 'MMMM Do YYYY';

    @Input() submitted: boolean;
    @Input() fieldName: string;
    @Input() formName: string;
    @Input() form: any;
    @Input() customConfig: { id?: any; label?: any, format?: any, useUtc?: boolean };

    public currentErrors: string[];

    @Input() set errors(data: DisplayError[]) {
        this.currentErrors = this.fieldService.getFieldsErrors(data, this.fieldName, this.formName);
    }

    constructor(public fieldService: FieldsService) {
    }

    checkCorrectDate(date: any) {
        if (+date === 0) {
            this.statusError = false;
            this.errorMessage = [];
            return;
        } else if (+date < +new Date()) {
            this.statusError = true;
            this.errorMessage.unshift('Date cannot be set in the past.');
        } else {
            this.statusError = false;
            this.errorMessage = [];
        }
    }

    checkDate(date: any) {
        this.onChange(date);
        this.checkCorrectDate(date);
        if (this.form === undefined) {
            return;
        }
        const start = +this.form.get('startDate').value;
        const end = +this.form.get('endDate').value;
        if (start === 0 || end === 0) {
            this.statusError = false;
            this.errorMessage = [];
        } else if (start > end) {
            this.statusError = true;
            this.errorMessage.push('End date cannot be set in the past');
        }

    }

    onChange: any = () => {
    }

    ngOnInit() {
        this.errorMessage = [];
        this.statusError = false;

        if (!this.customConfig) {
            this.field = this.fieldService.getUserField(this.fieldName, this.formName);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (!obj) {
            this.value = null;
            return;
        }

        let format = this.defaultDateFormat;

        if (this.customConfig && this.customConfig.format) {
            format = this.customConfig.format;
        }

        this.value = moment(obj).format(format);
    }

    get label() {
        if (this.customConfig) {
            return this.customConfig.label;
        }

        return this.field.label;
    }

    get id() {
        if (this.customConfig) {
            return this.customConfig.id;
        }

        return this.field.id;
    }
}

