import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminSubscriptionsList, CompanyModel, FormConfig} from '../../../../models/models';
import {FormBuilder, Validators} from '@angular/forms';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

@Component({
    selector: 'app-followers-modal',
    templateUrl: './followers-modal.component.html',
    styleUrls: ['./followers-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class FollowersModalComponent implements OnInit {

    @Input() subscription: AdminSubscriptionsList;
    @Output() updated = new EventEmitter();

    @ViewChild(FormComponent, {static: true}) form: FormComponent<CompanyModel>;
    formConfig: FormConfig = {
        fields: [
            {
                name: 'followers',
                label: 'CC/BCC',
                type: 'collection',
                collectionInputs: [
                    {
                        name: 'type',
                        type: 'select',
                        label: 'messages.admin.contents_page.form.content.fields.type.label',
                        placeholder: 'messages.admin.contents_page.form.content.fields.type.placeholder',
                        validators: [Validators.required]
                    },
                    {
                        name: 'email',
                        type: 'text',
                        inputType: 'email',
                        label: 'messages.admin.subscriptions_page.form.subscription.fields.email.label',
                        placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.email.placeholder',
                        validators: [Validators.required, Validators.email]
                    },
                ],
                collectionRowConfig: {
                    rowClass: 'row',
                    columnsClasses: ['col-12 col-sm-3', 'col-12 col-sm-9']
                }
            },
        ]
    };
    lists: { [key: string]: any } = {};

    constructor(
        private formBuilder: FormBuilder,
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        const {subscription} = this;

        if (!subscription) {
            throw new Error('Subscription is required!');
        }

        this.formLoadingServiceService.init(1);

        this.entityProvider.getSubscriptionFormConfig().subscribe(data => {
            this.lists.followers = {type: data.typesCC};
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addSubscription(data) {
        const request = this.apiService.updateSubscriptionFollowers(this.subscription.id, data);

        request.subscribe(() => {
            this.closeModalWindow();
        }, error => {
            this.form.unsubmit(error);
        });
    }

    closeModalWindow() {
        return this.updated.emit();
    }
}
