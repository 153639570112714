<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.id === 'sent_at' || field.id === 'created_by'"
        >{{subscription[field.id]}}</p>
        <p class="form-control-static" *ngIf="field.id === 'report'">{{subscription.report.stringified}}</p>
        <p class="form-control-static" *ngIf="field.id === 'start_date'">
            <span *ngIf="!subscription.start_date && !subscription.end_date">
                {{'messages.admin.subscriptions_page.date_restrictions.none'|translate}}
            </span>
            <span *ngIf="subscription.start_date && !subscription.end_date">
                {{'messages.admin.subscriptions_page.date_restrictions.starting'|translate}}
            </span>
            <span *ngIf="!subscription.start_date && subscription.end_date">
                {{'messages.admin.subscriptions_page.date_restrictions.ending'|translate}}
            </span>
            <span *ngIf="subscription.start_date && subscription.end_date"> - </span>
        </p>
        <p class="form-control-static" *ngIf="field.id === 'nr_of_cc'">
            <span>{{subscription.nr_of_cc}} CC,</span>
            <span>{{subscription.nr_of_bcc}} BCC</span>
        </p>
        <p class="form-control-static" *ngIf="field.id === 'type'">
            <span *ngIf="!subscription.is_repeatable">
                {{'messages.admin.subscriptions_page.pending_delivery'|translate}}
            </span>
            <span *ngIf="subscription.is_repeatable">
                <span *ngIf="subscription.type === 'daily'">
                    {{'messages.right_side.subscriptions.daily_at'|translate}}
                </span>
                <span *ngIf="subscription.type === 'weekly'"> {{'messages.right_side.subscriptions.on'|translate}} {{subscription.day_of_delivery}} </span>
                <span> {{subscription.hour_of_delivery}} : {{subscription.minute_of_delivery}} </span>
                <span>CET</span>
            </span>
        </p>
    </div>
    <div class="text-right">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="subscriptions_edit_{{subscription.id}}"
            (click)="editSubscription()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="subscriptions_toggle_{{subscription.id}}"
            (click)="toggleActive($event, subscription)"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.folders_page.toggle_active'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="subscriptions_view_{{subscription.id}}"
            (click)="viewCCBCC($event, subscription)"
        >
            <i class="icon icon-size-9 ion-alert"></i>
            {{'messages.admin.subscriptions_page.view_cc'|translate}}
        </button>
        <a
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="subscriptions_viewLog_{{subscription.id}}"
            [routerLink]="['view_log', subscription.id]"
        >
            <i class="icon icon-size-9 ion-eye"></i>
            {{'messages.admin.subscriptions_page.view_log'|translate}}
        </a>
        <app-modal-confirm>
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="subscriptions_delete_{{subscription.id}}"
                (click)="delete($event, subscription)"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
