import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import * as moment from 'moment';
import {SharedFileInfo} from '../../../../../models/models';

@Component({
    selector: 'app-network-share',
    templateUrl: './network-share.component.html',
    styleUrls: ['./network-share.component.scss']
})
export class NetworkShareComponent implements OnInit {

    @Input() initialFolder;
    public loading = true;
    public path: string[] = [];
    public contents: SharedFileInfo[] | any;

    public sortColumn = 'time';
    public sortAsc = false;

    constructor(public apiService: ApiService) {
    }

    ngOnInit() {
        this.path.push(this.initialFolder);
        this.getContents();
    }

    getContents(path?: string) {
        if (path) {
            this.path.push(path);
        }
        this.loading = true;
        this.apiService.getNetworkShareContents(this.path.join('/')).subscribe((response: any) => {
                this.contents = response.contents;

                if (response.contents && response.contents.length) {
                    this.contents = response.contents.map(item => ({
                        ...item,
                        size: parseInt(item.size, 0)
                    }));
                }

                this.loading = false;
            },
            response => {
                this.contents = response.error.contents;
                this.loading = false;
            });
    }

    showGetParent() {
        return this.path.length !== 1;

    }

    getParent() {
        this.path.pop();
        this.getContents();
    }

    onDoubleClick(content: SharedFileInfo) {
        if (content.isDirectory) {
            this.getContents(content.name);
        } else {
            const path = this.path.join('/') + '/' + content.name;
            this.apiService.downloadNetworkShareFile(path).subscribe(response => {
                this.apiService.downloadFileClientSide(response, content.name);
            });
        }
    }

    formatDate(timestamp: number) {
        return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
    }

    sortBy(key: string) {
        if (this.sortColumn !== key) {
            this.sortColumn = key;
            this.sortAsc = false;
        } else {
            this.sortAsc = !this.sortAsc;
        }
    }

    get sortedItems() {
        if (!this.sortColumn) {
            return this.contents;
        }

        if (!this.contents || !this.contents.length) {
            return [];
        }

        return this.contents.map(item => item).sort((a, b) => {
            const aVal = a[this.sortColumn];
            const bVal = b[this.sortColumn];

            if (aVal > bVal) {
                return this.sortAsc ? 1 : -1;
            }

            if (bVal > aVal) {
                return this.sortAsc ? -1 : 1;
            }

            return 0;
        });
    }
}
