import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {allowMimeByRegex} from '../../../../validators/mimeTypesValidators';
import {AuthService} from '../../../../services/auth.service';
import {maxFileSize} from '../../../../validators/fileSizeValidators';
import {environment} from '../../../../../environments/environment';
import * as moment from 'moment';

@Component({
    selector: 'app-user-video',
    templateUrl: './user-video.component.html',
    styleUrls: ['./user-video.component.scss']
})
export class UserVideoComponent implements OnInit {
    @Input() userId;

    @Input() set videoId(videoId) {
        const prevValue = this.localVideoId;
        this.localVideoId = videoId;

        if (!videoId || prevValue === videoId) {
            return;
        }

        setTimeout(() => {
            if (this.videoService === 'local') {
                this.videoSrc = `${this.api.apiURL}/profile/team/${this.userId}/video?video=${videoId}&api_key=${this.auth.getApiKey()}`;
            }
        });
    }

    @Input() videoService;
    @Input() canEdit = false;

    @Output() updated = new EventEmitter();

    uploadForm: FormGroup = null;
    edit = false;
    submitted = false;
    showModal = false;
    videoSrc = null;
    localVideoId = null;
    uploadEnabled = false;
    disabledMessage = null;

    private service = 'vimeo';

    constructor(private api: ApiService, private builder: FormBuilder, private auth: AuthService) {
    }

    ngOnInit(): void {
        this.uploadForm = this.builder.group({
            name: this.builder.control(null, [Validators.required]),
            file: this.builder.control(null, [
                Validators.required,
                allowMimeByRegex(/^video\/.+$/),
                maxFileSize(environment.maxUploadVideoSize)
            ])
        });

        if (this.service !== 'vimeo') {
            this.uploadEnabled = true;
        }

        this.api.getVideoQuota().subscribe(data => {
            this.uploadEnabled = !data.is_reached;
            this.disabledMessage = 'Vimeo quota is reached, you can upload new video after ' + moment(data.reset_date).format('YYYY-MM-DD HH:mm');
        });
    }

    startEdit() {
        this.edit = true;
        this.uploadForm.reset();
    }

    cancelEdit() {
        this.edit = false;
    }

    handleFileInput(files: FileList) {
        this.uploadForm.get('file').setValue(files.item(0));
        this.uploadForm.get('file').markAsDirty();
    }

    handleFileUpload() {
        if (this.submitted) {
            return;
        }
        const form = this.uploadForm;

        form.markAllAsTouched();

        if (form.invalid) {
            return;
        }

        const data = new FormData();

        data.set('name', form.value.name);
        data.set('type', this.service);
        data.append('file', form.value.file, form.value.file.name);

        this.submitted = true;

        this.api.uploadProfileVideo(this.userId, data).subscribe(resp => {
            this.submitted = false;
            this.edit = false;
            this.updated.emit(true);

        }, e => {
            console.log(e);
            this.submitted = false;
        });
    }
}
