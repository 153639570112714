import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-gl-icon',
    templateUrl: './gl-icon.component.html',
    styleUrls: ['./gl-icon.component.scss']
})
export class GlIconComponent implements OnInit, OnChanges {

    @Input() set icon(value: string) {
        if (!value) {
            this.currentIcon = null;
            return;
        }

        this.currentIcon = this.sanitized.bypassSecurityTrustHtml(`<use href="/img/gitlab-icons.svg#${value}"></use>`);
    }

    @Input() size = 17;
    @Input() rotate = 0;
    @Input() left = 0;
    @Input() top = 0;

    public styles = {};
    public currentIcon: SafeHtml = null;

    constructor(private sanitized: DomSanitizer) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.styles = {
            height: this.size + 'px',
            width: this.size + 'px',
            transform: 'rotate(' + this.rotate + 'deg)',
            top: this.top + 'px',
            left: this.left + 'px'
        };
    }
}
