import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class RoleUserGuard implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {
    }

    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
            const queryParams = {
                redirect: this.router.getCurrentNavigation().finalUrl.toString()
            };

            this.auth.redirectCount++;
            this.router.navigate(['login'], {queryParams});

            return false;
        }

        return true;
    }

}
