import {Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() mTitle = '';
    @Input() loading = false;
    @Input() withSubmit = true;
    @Input() wideVariant = false;
    @Output() mClose = new EventEmitter();
    @Output() mSubmit = new EventEmitter();

    @ContentChild('modalFooter', {static: false}) footer;

    public expanded = false;
    public minHeight: number;

    constructor() {
    }

    onEsc = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            this.mClose.emit();
            document.body.removeEventListener('keyup', this.onEsc);
        }
    }

    ngOnInit(): void {
        document.body.addEventListener('keyup', this.onEsc);
        this.minHeight = window.innerHeight;
    }

    ngOnDestroy(): void {
        document.body.removeEventListener('keyup', this.onEsc);
    }
}
