import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-news-page',
    templateUrl: './news-page.component.html',
    styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {

    public news = null;
    public notFound = false;

    constructor(private api: ApiService, private activeRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.api.getPublicNews(this.activeRoute.snapshot.paramMap.get('slug')).subscribe(data => {
            this.news = data;
        }, () => {
            this.notFound = true;
        });
    }

}
