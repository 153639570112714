<div class="row">
    <div class="col-12">
        <div class="comment-box">
            <p class="title">{{'messages.comments.comments'|translate}}
                <!--                <button class="btn btn-primary btn-xs" (click)="fetchComments()"><i class="icon ion-refresh"></i>-->
                <!--                    {{'messages.comments.refresh'|translate}}-->
                <!--                </button>-->
            </p>


            <app-comment
                *ngFor="let comment of comments; index as i"
                [comment]="comment"
                (deleted)="handleChildDelete($event)"
                (updated)="handleChildUpdate($event, i)"
            >
            </app-comment>

            <app-comment-form
                [baseForm]="true"
                (closeForm)="handleClose($event)"
                [avatar]="auth.getLoggedUser().id | avatarUrl"
                [sender]="auth.getLoggedUserFullName()"
            ></app-comment-form>
        </div>
    </div>
</div>
