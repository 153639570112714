import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminSchedulingList, FormConfig, FormLists, SchedulingModel} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';

@Component({
    selector: 'app-scheduling-modal',
    templateUrl: './scheduling-modal.component.html',
    styleUrls: ['./scheduling-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class SchedulingModalComponent implements OnInit {

    @Output() addComponent = new EventEmitter();
    @Input() scheduling: AdminSchedulingList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<SchedulingModel>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'type',
                label: 'messages.admin.scheduling_page.form.schedule.fields.type.label',
                id: 'schedule_type',
                type: 'select',
                listValuesKey: 'types',
                placeholder: '-- Type --'
            },
            {
                name: 'dayOfDelivery',
                label: 'messages.admin.scheduling_page.form.schedule.fields.day_of_delivery.label',
                placeholder: 'messages.admin.scheduling_page.form.schedule.fields.day_of_delivery.placeholder',
                id: 'schedule_dayOfDelivery',
                type: 'select',
                listValuesKey: 'days',
                propertyPath: 'day_of_delivery'
            },
            {
                name: 'timeOfDelivery',
                label: 'messages.admin.scheduling_page.form.schedule.fields.time_of_delivery.label',
                placeholder: 'messages.admin.scheduling_page.form.schedule.fields.time_of_delivery.placeholder',
                id: 'schedule_timeOfDelivery',
                type: 'select',
                listValuesKey: 'times',
                propertyPath: 'time_of_delivery'
            },
            {
                name: 'customData',
                label: 'messages.admin.scheduling_page.form.schedule.fields.custom_data.label',
                id: 'schedule_customData',
                type: 'textarea',
                propertyPath: 'custom_data'
            }
        ]
    };

    lists: FormLists = {};

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        this.formLoadingServiceService.init(1);

        this.entityProvider.getSchedulingFormConfig().subscribe(data => {
            this.lists.types = data.types;
            this.lists.times = data.times;
            this.lists.days = data.days;
            this.formLoadingServiceService.eventLoaded();
        });
    }

    addSchedule(data: SchedulingModel) {
        const request = this.scheduling
            ? this.apiService.updateScheduling(this.scheduling.id, data)
            : this.apiService.createScheduling(data);

        request.subscribe(response => {
            this.closeModalWindow();
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }
}
