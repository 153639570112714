import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarTopComponent} from './navbar-top.component';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModalsModule} from '../../modules/common-modals/common-modals.module';
import {CommonAppModule} from '../common-app.module';

@NgModule({
    declarations: [
        NavbarTopComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        BsDropdownModule,
        TranslateModule,
        CommonModalsModule,
        CommonAppModule
    ],
    exports: [
        NavbarTopComponent,
    ]
})
export class NavbarTopModule {
}
