import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';
import {AdminSubscriptionLogList, Pager} from '../../../models/models';

@Component({
    selector: 'app-subscription-logs',
    templateUrl: './subscription-logs.component.html',
    styleUrls: ['./subscription-logs.component.scss']
})
export class SubscriptionLogsComponent implements OnInit {

    private subscriptionId: any = null;
    public entities: AdminSubscriptionLogList[] = [];
    public inactive: boolean;
    public busy: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private paginationService: PaginationService) {
    }

    ngOnInit() {
        this.subscriptionId = this.route.snapshot.paramMap.get('id');
        this.loadList();
    }

    private loadList() {
        this.apiService.getAdminSubscriptionLog(this.subscriptionId, this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    paginateFilterSort(pager: Pager) {
        this.apiService.getAdminSubscriptionLog(this.subscriptionId, pager).subscribe(response => {
            this.entities = response;
        });
    }
}
