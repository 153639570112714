<app-modal
    [loading]="submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="addSubscription(null)"
    [mTitle]="(subscription ? 'messages.admin.subscriptions_page.form.subscription.title_edit' : 'messages.admin.subscriptions_page.form.subscription.title_create') | translate"
>
    <form
        name="myForm"
        (ngSubmit)="addSubscription($event)"
        [formGroup]="form"
        role="form"
        autocomplete="off"
        novalidate="novalidate"
        class="form-style-bordered-inputs"
    >
        <app-form-loading [loading]="formLoadingServiceService.isLoading()"></app-form-loading>

        <div *ngIf="!formLoadingServiceService.isLoading()">
            <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

            <app-provided-entity-select
                [submitted]="submitted"
                fieldName="subscriber"
                formControlName="subscriber"
                [formName]="formName"
                [options]="subscribers"
                [errors]="errors"
            ></app-provided-entity-select>
            <app-provided-entity-select
                [submitted]="submitted"
                fieldName="report"
                formControlName="report"
                [formName]="formName"
                [options]="pages"
                [errors]="errors"
            ></app-provided-entity-select>
            <app-input-text
                [submitted]="submitted"
                fieldName="email"
                formControlName="email"
                [formName]="formName"
                [errors]="errors"
            ></app-input-text>
            <div>
                <div class="mb-8">CC/BCC
                    <a class="btn btn-xs btn-primary" (click)="addItem($event)">
                        <i class="icon icon-size-9 ion-plus-circled"></i>
                        {{'messages.global.add_new'|translate}}
                    </a>
                </div>
                <div
                    *ngFor="let item of formData.controls; index as i"
                    formArrayName="followers"
                >
                    <div [formGroupName]="i">
                        <div class="clearfix form-collection-row">
                            <div class="col-xs-4">
                                <app-select
                                    [submitted]="submitted"
                                    fieldName="type"
                                    formControlName="type"
                                    [options]="typesCC"
                                    [formName]="formName"
                                    [withLabel]="false"
                                ></app-select>
                            </div>
                            <div class="col-xs-7">
                                <app-input-text
                                    [submitted]="submitted"
                                    fieldName="email"
                                    formControlName="email"
                                    [formName]="formName"
                                    [withLabel]="false"
                                ></app-input-text>
                            </div>
                            <a
                                class="collection-remove-button padding-horizontal-0"
                                (click)="deleteItem($event, i)"
                            >
                                <span class="icon icon-size-9 ion-minus-circled"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <app-display-input-errors [errorsStrings]="getFollowersErrors()"></app-display-input-errors>
            </div>
            <app-input-text
                [submitted]="submitted"
                fieldName="name"
                formControlName="name"
                [formName]="formName"
                [errors]="errors"
            ></app-input-text>
            <div class="form-group row">
                <div class="col-sm-6 ">
                    <app-select
                        [submitted]="submitted"
                        fieldName="type"
                        formControlName="type"
                        [formName]="formName"
                        [options]="types"
                        [errors]="errors"
                    ></app-select>
                </div>
                <div class="col-sm-6">
                    <app-select
                        [submitted]="submitted"
                        fieldName="format"
                        formControlName="format"
                        [formName]="formName"
                        [options]="formats"
                        [errors]="errors"
                    ></app-select>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <label class="control-label">
                        {{'messages.admin.subscriptions_page.form.subscription.time_of_delivery'|translate}}
                    </label>
                    <div class="row time-selector form-style-labels-inside-inputs-condensed">
                        <div class="col-xs-6 col-sm-3">
                            <app-select
                                [submitted]="submitted"
                                fieldName="hourOfDelivery"
                                formControlName="hourOfDelivery"
                                [formName]="formName"
                                [options]="timeHours"
                                [errors]="errors"
                            ></app-select>
                        </div>
                        <div class="col-xs-6 col-sm-3">
                            <app-select
                                [submitted]="submitted"
                                fieldName="minuteOfDelivery"
                                formControlName="minuteOfDelivery"
                                [formName]="formName"
                                [options]="timeMinutes"
                                [errors]="errors"
                            ></app-select>
                        </div>
                        <div class="col-xs-12 col-sm-6 time-zones">
                            <app-select
                                [submitted]="submitted"
                                fieldName="timeZone"
                                formControlName="timeZone"
                                [formName]="formName"
                                [options]="timezones"
                                [withLabel]="false"
                                [errors]="errors"
                            ></app-select>
                        </div>
                    </div>
                </div>
            </div>
            <app-select
                *ngIf="form.value.type === 'weekly'"
                [submitted]="submitted"
                fieldName="dayOfDelivery"
                formControlName="dayOfDelivery"
                [formName]="formName"
                [options]="days"
                [errors]="errors"
            ></app-select>
            <div class="form-group row">
                <app-datepicker
                    [submitted]="submitted"
                    fieldName="startDate"
                    formControlName="startDate"
                    [formName]="formName"
                    [errors]="errors"
                    class="col-xs-12 col-sm-6"
                ></app-datepicker>
                <app-datepicker
                    [submitted]="submitted"
                    fieldName="endDate"
                    formControlName="endDate"
                    [formName]="formName"
                    [form]="form"
                    [errors]="errors"
                    class="col-xs-12 col-sm-6"
                ></app-datepicker>
            </div>
        </div>
    </form>
</app-modal>
