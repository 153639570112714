<div class="bars">
    <div
        *ngIf="hasVerticalBar"
        [class.bar_has-horizontal]="hasHorizontalBar"
        class="bar bar_vertical"
    >
        <div
            (dragged)="onVertical($event)"
            [style.height.%]="verticalSize"
            [style.top.%]="verticalPosition"
            class="thumb"
            appDraggable="vertical"
        ></div>
    </div>
    <div
        *ngIf="hasHorizontalBar"
        [class.bar_has-vertical]="hasVerticalBar"
        class="bar bar_horizontal"
    >
        <div
            (dragged)="onHorizontal($event)"
            [style.left.%]="horizontalPosition"
            [style.width.%]="horizontalSize"
            class="thumb"
            appDraggable="horizontal"
        ></div>
    </div>
</div>
<div (resize)="onScroll()" #content class="content">
    <ng-content></ng-content>
</div>
