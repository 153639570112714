/* tslint:disable:max-line-length */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdministrationComponent} from './administration.component';
import {NavbarTopModule} from '../../common/navbar-top/navbar-top.module';
import {SidebarLeftModule} from '../../common/sidebar-left/sidebar-left.module';
import {SidebarRightModule} from '../../common/sidebar-right/sidebar-right.module';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from '../../pages/administration/users/users.component';
import {PendingRequestsComponent} from '../../pages/administration/pending-requests/pending-requests.component';
import {GroupsComponent} from '../../pages/administration/groups/groups.component';
import {CompaniesComponent} from '../../pages/administration/companies/companies.component';
import {FoldersComponent} from '../../pages/administration/folders/folders.component';
import {PagesComponent} from '../../pages/administration/pages/pages.component';
import {ContentComponent} from '../../pages/administration/content/content.component';
import {WorkbooksComponent} from '../../pages/administration/workbooks/workbooks.component';
import {WorkbookTemplatesComponent} from '../../pages/administration/workbook-templates/workbook-templates.component';
import {AddinVersionsComponent} from '../../pages/administration/addin-versions/addin-versions.component';
import {SchedulingComponent} from '../../pages/administration/scheduling/scheduling.component';
import {MessagesComponent} from '../../pages/administration/messages/messages.component';
import {SubscriptionsComponent} from '../../pages/administration/subscriptions/subscriptions.component';
import {AdminsComponent} from '../../pages/administration/admins/admins.component';
import {NotFoundComponent} from '../../pages/common/error-pages/not-found/not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDatepickerModule, DatepickerModule} from 'ngx-bootstrap/datepicker';
import {UserModalComponent} from '../../pages/administration/users/user-modal/user-modal.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {UserTableTrInfoComponent} from '../../pages/administration/users/user-table-tr-info/user-table-tr-info.component';
import {GroupModalComponent} from '../../pages/administration/groups/group-modal/group-modal.component';
import {GroupTableTrInfoComponent} from '../../pages/administration/groups/group-table-tr-info/group-table-tr-info.component';
import {RequestTableTrInfoComponent} from '../../pages/administration/pending-requests/request-table-tr-info/request-table-tr-info.component';
import {FolderTableTrInfoComponent} from '../../pages/administration/folders/folder-table-tr-info/folder-table-tr-info.component';
import {CompanyModalComponent} from '../../pages/administration/companies/company-modal/company-modal.component';
import {CompanyTableTrInfoComponent} from '../../pages/administration/companies/company-table-tr-info/company-table-tr-info.component';
import {PageTableTrInfoComponent} from '../../pages/administration/pages/page-table-tr-info/page-table-tr-info.component';
import {ContentModalComponent} from '../../pages/administration/content/content-modal/content-modal.component';
import {ContentTableTrInfoComponent} from '../../pages/administration/content/content-table-tr-info/content-table-tr-info.component';
import {MessageModalComponent} from '../../pages/administration/messages/message-modal/message-modal.component';
import {MessageTableTrInfoComponent} from '../../pages/administration/messages/message-table-tr-info/message-table-tr-info.component';
import {SchedulingModalComponent} from '../../pages/administration/scheduling/scheduling-modal/scheduling-modal.component';
import {SchedulingTableTrInfoComponent} from '../../pages/administration/scheduling/scheduling-table-tr-info/scheduling-table-tr-info.component';
import {WorkbookModalComponent} from '../../pages/administration/workbooks/workbook-modal/workbook-modal.component';
import {SubscriptionModalComponent} from '../../pages/administration/subscriptions/subscription-modal/subscription-modal.component';
import {SubscrptionTableTrInfoComponent} from '../../pages/administration/subscriptions/subscrption-table-tr-info/subscrption-table-tr-info.component';
import {AdminTableTrInfoComponent} from '../../pages/administration/admins/admin-table-tr-info/admin-table-tr-info.component';
import {AddinVersionTableTrInfoComponent} from '../../pages/administration/addin-versions/addin-version-table-tr-info/addin-version-table-tr-info.component';
import {WorkbookTemplateTableTrInfoComponent} from '../../pages/administration/workbook-templates/workbook-table-tr-info/workbook-template-table-tr-info.component';
import {WorkbookTableTrInfoComponent} from '../../pages/administration/workbooks/workbook-table-tr-info/workbook-table-tr-info.component';
import {AddinVersionModalComponent} from '../../pages/administration/addin-versions/addin-version-modal/addin-version-modal.component';
import {LogsComponent} from '../../pages/administration/logs/logs.component';
import {RoleAdminGuard} from '../../guards/role-admin.guard';
import {UserChangePasswordModalComponent} from '../../pages/administration/users/user-change-password-modal/user-change-password-modal.component';
import {UserChangeStartupSettingsModalComponent} from '../../pages/administration/users/user-change-startup-settings-modal/user-change-startup-settings-modal.component';
import {WorkbookTemplateModalComponent} from '../../pages/administration/workbook-templates/workbook-template-modal/workbook-template-modal.component';
import {ContentsFormDirective} from '../../pages/administration/content/edit-modals/contents-form.directive';
import {FollowersModalComponent} from '../../pages/administration/subscriptions/followers-modal/followers-modal.component';
import {SubscriptionLogsComponent} from '../../pages/administration/subscription-logs/subscription-logs.component';
import {CommonAppModule} from '../../common/common-app.module';
import {FolderCopyModalComponent} from '../../pages/administration/folders/folder-copy-modal/folder-copy-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModalsModule} from '../common-modals/common-modals.module';
import {IsGrantedGuard} from '../../guards/is-granted.guard';
import {IsSuperAdminOrCompanyOwnerGuard} from '../../guards/is-super-admin-or-company-owner.guard';
import {ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_SUPER_ADMIN} from '../../services/constants';
import {CompanyManagersModalComponent} from '../../pages/administration/companies/company-managers-modal/company-managers-modal.component';
import {ContentTypeDashboardModalComponent} from '../../pages/administration/content/edit-modals/content-type-dashboard-modal/content-type-dashboard-modal.component';
import {DashboardBuilderModule} from '../dashboard-builder/dashboard-builder.module';
import {DefaultProfileValuesComponent} from '../../pages/administration/default-profile-values/default-profile-values.component';
import {ProfileValuesFormComponent} from './components/profile-values-form/profile-values-form.component';
import {DashboardChartsRendererModule} from '../dashboard-charts-renderer/dashboard-charts-renderer.module';
import {UserProfileModalComponent} from '../../pages/administration/users/user-profile-modal/user-profile-modal.component';
import {FormGeneratorModule} from '../form-generator/form-generator.module';
import {BackendEnvComponent} from '../../pages/administration/backend-env/backend-env.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {FoldersTreeComponent} from '../../pages/administration/folders-tree/folders-tree.component';
import {FolderTreeNodeComponent} from '../../pages/administration/folders-tree/folder-tree-node/folder-tree-node.component';
import {SentMessagesComponent} from '../../pages/administration/sent-messages/sent-messages.component';
import {SentMessagesTableTrInfoComponent} from '../../pages/administration/sent-messages/sent-messages-table-tr-info/sent-messages-table-tr-info.component';
import {NewsComponent} from '../../pages/administration/news/news.component';
import {NewsFormComponent} from '../../pages/administration/news/news-form/news-form.component';
import {NewsTableTrComponent} from '../../pages/administration/news/news-table-tr/news-table-tr.component';
import {EmailsListsComponent} from '../../pages/administration/emails-lists/emails-lists.component';
import {YoutubeSettingsComponent} from '../../pages/administration/youtube-settings/youtube-settings.component';
import {CompanyHrsModalComponent} from '../../pages/administration/companies/company-hrs-modal/company-hrs-modal.component';

const routes: Routes = [
    {
        path: 'admin',
        component: AdministrationComponent,
        canActivate: [RoleAdminGuard],
        children: [
            {
                path: 'pending-requests',
                component: PendingRequestsComponent,
                canActivate: [IsSuperAdminOrCompanyOwnerGuard],
            },
            {
                path: 'users',
                component: UsersComponent,
                canActivate: [IsSuperAdminOrCompanyOwnerGuard],
            },
            {
                path: 'groups',
                component: GroupsComponent,
                canActivate: [IsSuperAdminOrCompanyOwnerGuard]
            },
            {
                path: 'companies',
                component: CompaniesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER},
            },
            {
                path: 'folders',
                component: FoldersComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'pages',
                component: PagesComponent,
                canActivate: [RoleAdminGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'contents',
                component: ContentComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER},
            },
            {
                path: 'contents/manage/:id',
                component: ContentTypeDashboardModalComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER},
            },
            {
                path: 'workbooks',
                component: WorkbooksComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'workbook-templates',
                component: WorkbookTemplatesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'excel-manager',
                component: AddinVersionsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'scheduling',
                component: SchedulingComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'messages',
                component: MessagesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'subscriptions',
                component: SubscriptionsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'subscriptions/view_log/:id',
                component: SubscriptionLogsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'messages',
                component: MessagesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'admins',
                component: AdminsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'logs',
                component: LogsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_ADMIN}
            },
            {
                path: 'profiles-lists-values',
                component: DefaultProfileValuesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_SUPER_ADMIN}
            },
            {
                path: 'backend-env',
                component: BackendEnvComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_SUPER_ADMIN}
            },
            {
                path: 'folders-tree',
                component: FoldersTreeComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'sent-mails',
                component: SentMessagesComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'feed',
                component: NewsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'emails-domains',
                component: EmailsListsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_CONTENT_MANAGER}
            },
            {
                path: 'oauth-clients',
                component: YoutubeSettingsComponent,
                canActivate: [IsGrantedGuard],
                data: {role: ROLE_SUPER_ADMIN}
            },
        ]
    },
    {
        path: '**',
        component: NotFoundComponent
    },
];

@NgModule({
    declarations: [
        AdministrationComponent,
        UsersComponent,
        PendingRequestsComponent,
        GroupsComponent,
        CompaniesComponent,
        FoldersComponent,
        PagesComponent,
        ContentComponent,
        WorkbooksComponent,
        WorkbookTemplatesComponent,
        AddinVersionsComponent,
        SchedulingComponent,
        MessagesComponent,
        SubscriptionsComponent,
        AdminsComponent,
        UserModalComponent,
        UserChangePasswordModalComponent,
        UserChangeStartupSettingsModalComponent,
        UserTableTrInfoComponent,
        GroupModalComponent,
        GroupTableTrInfoComponent,
        RequestTableTrInfoComponent,
        FolderTableTrInfoComponent,
        CompanyModalComponent,
        CompanyTableTrInfoComponent,
        PageTableTrInfoComponent,
        ContentModalComponent,
        ContentTableTrInfoComponent,
        MessageModalComponent,
        MessageTableTrInfoComponent,
        SchedulingModalComponent,
        SchedulingTableTrInfoComponent,
        WorkbookModalComponent,
        WorkbookTemplateModalComponent,
        SubscriptionModalComponent,
        SubscrptionTableTrInfoComponent,
        AdminTableTrInfoComponent,
        AddinVersionModalComponent,
        AddinVersionTableTrInfoComponent,
        WorkbookTemplateTableTrInfoComponent,
        WorkbookTableTrInfoComponent,
        LogsComponent,
        ContentsFormDirective,
        FollowersModalComponent,
        SubscriptionLogsComponent,
        FolderCopyModalComponent,
        CompanyManagersModalComponent,
        ContentTypeDashboardModalComponent,
        DefaultProfileValuesComponent,
        ProfileValuesFormComponent,
        UserProfileModalComponent,
        BackendEnvComponent,
        FoldersTreeComponent,
        FolderTreeNodeComponent,
        SentMessagesComponent,
        SentMessagesTableTrInfoComponent,
        NewsComponent,
        NewsFormComponent,
        NewsTableTrComponent,
        EmailsListsComponent,
        YoutubeSettingsComponent,
        CompanyHrsModalComponent,
    ],
    exports: [
        UsersComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NavbarTopModule,
        SidebarLeftModule,
        SidebarRightModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(),
        FormsModule,
        AccordionModule,
        CommonAppModule,
        TranslateModule,
        CommonModalsModule,
        PopoverModule.forRoot(),
        DashboardBuilderModule,
        DashboardChartsRendererModule,
        FormGeneratorModule,
        SortablejsModule,
    ]
})
export class AdministrationModule {
}
