<div class="row-expansion-body">
    <div *ngFor="let field of fields; let index = field" class="form-group">
        <label class="control-label pull-left">{{field.name|translate}}:</label>
        <p
            *ngIf="field.type === 'string'"
            class="form-control-static"
        >{{workbook[field.id] !== null ? workbook[field.id] : ' -- '}}</p>
        <div
            *ngIf="field.type === 'list'" [ngStyle]="{'max-width': field.id === 'folders' && '600px'}"
            style="overflow: auto"
        >
            <p *ngIf="workbook[field.id].length === 0" class="form-control-static"> -- </p>
            <ul *ngIf="workbook[field.id].length !== 0" class="comma-separated-list">
                <li *ngFor="let item of workbook[field.id]; let index = item">{{item.stringified}}</li>
            </ul>
        </div>
        <p *ngIf="field.type === 'link'" class="form-control-static">
            <span *ngIf="!workbook[field.id]"> -- </span>
            <a *ngIf="workbook[field.id]" href="https://dev.evouser.com/">https://dev.evouser.com/ --</a>
        </p>

        <p *ngIf="field.type === 'entity'" class="form-control-static">
            <span>{{workbook[field.id]?.stringified || '--'}}</span>
        </p>
    </div>
    <div class="text-right">
        <button
            (click)="showOutput()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbook_output"
            type="button"
        >
            <i class="icon icon-size-9 ion-archive"></i>
            {{'messages.admin.workbooks_page.output'|translate}}
        </button>
        <button
            (click)="uploadInput.click()"
            class="btn btn-square btn-xs btn-primary margin-left-5 admin-workbooks-upload-input-file-button help-target"
            id="workbook_input"
            type="button"
        >
            <i *ngIf="workbook.has_input_file" class="icon icon-size-9 ion-checkmark"></i>
            <i *ngIf="!workbook.has_input_file" class="icon icon-size-9 ion-upload"></i>
            {{'messages.admin.workbooks_page.input'|translate}}
            <input
                #uploadInput
                (change)="onFileSelected($event.target.files) //noinspection UnresolvedVariable"
                style="display: none"
                type="file"
            />
        </button>
        <a
            class="btn btn-square btn-xs btn-primary margin-left-5 admin-workbooks-calculate-upload-button help-target"
            id="workbook_calculator"
            (click)="calculateOutput()"
        >
            <i class="icon icon-size-9 ion-calculator"></i>
            {{'messages.admin.workbooks_page.push_to_calculate_output'|translate}}
        </a>
        <button
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbook_toggle"
            type="button"
            (click)="toggleActive()"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.workbooks_page.toggle_active'|translate}}
        </button>
        <button
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbook_toggle_manaers_only"
            type="button"
            (click)="toggleManagersOnly()"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.workbooks_page.toggle_managers_only'|translate}}
        </button>
        <button
            *ngIf="auth.isSuperAdmin()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbook_toggle_under_maintenance"
            type="button"
            (click)="toggleUnderMaintenance()"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.workbooks_page.toggle_under_maintenance'|translate}}
        </button>
        <button
            *ngIf="auth.isSuperAdmin()"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="workbook_toggle_compressed"
            type="button"
            (click)="toggleCompressed()"
        >
            <i class="icon icon-size-9 ion-android-radio-button-on"></i>
            {{'messages.admin.workbooks_page.toggle_compressed'|translate}}
        </button>
        <button
            (click)="editWorkbook()"
            class="btn btn-square btn-xs btn-primary margin-left-5 admin-workbooks-edit-button help-target"
            id="workbook_edit"
            type="button"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>
        <app-modal-confirm>
            <button
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="workbook_delete"
                type="button"
                (click)="delete()"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>

<ng-container *ngIf="showModal">
    <app-modal
        (mClose)="closeModalWindow()"
        [mTitle]="'messages.admin.workbooks_page.preview_output'|translate"
        [withSubmit]="false"
    >
        <p class="word-break">{{output.toString()}}</p>
        <p class="alert alert-info">{{'messages.admin.workbooks_page.preview_message'|translate}}</p>
    </app-modal>
</ng-container>
