<div class="sidebar-right">
    <app-scrollbar>
        <div>
            <span class="sidebar-right-close">
                <a (click)="layoutConfigService.sidebarRightToggle()">
                    <i class="icon icon-size-7 ion-close-circled color-light-green"></i>
                    {{'messages.global.close'|translate}}
                </a>
            </span>

            <app-messages *ngIf="layoutConfigService.showMessagePanel"></app-messages>
            <app-settings *ngIf="layoutConfigService.showSettingsPanel"></app-settings>
        </div>
    </app-scrollbar>
</div>
