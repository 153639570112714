import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {DisplayError} from '../../models/models';
import {AutoHeight} from '../../helpers/AutoHeight';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-auto-height-textarea',
    templateUrl: './auto-height-textarea.component.html',
    styleUrls: ['./auto-height-textarea.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AutoHeightTextareaComponent),
        multi: true
    }]
})
export class AutoHeightTextareaComponent implements OnInit, ControlValueAccessor {
    @ViewChild('area') area;
    @Input() config: { id?: string, placeholder?: string, label?: string } = {};
    @Input() errors: DisplayError[];
    @Input() isFieldValid = true;
    autoHeight = AutoHeight;

    private currentValue = '';

    constructor() {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: any) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    ngOnInit() {
        setTimeout(() => {
            this.resetHeight();
        });
    }

    resetHeight() {
        this.autoHeight(this.area.nativeElement, true);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange: any = () => {
    }
}
