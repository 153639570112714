<div class="navbar-mobile-fake-bar"></div>
<div
    id="page-wrapper"
    class="page-wrapper sidebar-left-toggled public-page"
>
    <app-navbar-top [hideDetails]="true" [publicPage]="true"></app-navbar-top>
    <div class="page-center-column-wrapper">
        <div class="page-center-column" #screenshotContent>
            <!--            <app-fatal-error *ngIf="globalErrorService.getError()"></app-fatal-error>-->
            <div class="text-center" style="margin-top: 20vw;" *ngIf="reportIsLoading">
                <img src="../../../../assets/img/loading.gif" alt="Loading"/>
                <p>{{'messages.global.loading'|translate}}</p>
            </div>

            <app-not-found *ngIf="reportSelected && !reportIsLoading && !reportData"></app-not-found>

            <div *ngIf="reportsService.getPagesCount() > 1 && !reportIsLoading && !reportSelected">
                <h4 class="alert alert-warning">
                    <i class="ion-alert-circled"></i>
                    {{'messages.web.report_page.select_report'|translate}}
                </h4>
            </div>

            <div *ngIf="reportsService.getPagesCount() == 0 && !reportIsLoading && !reportSelected">
                <h4 class="alert alert-danger">
                    <i class="ion-alert-circled"></i>
                    {{(reportsService.getGroupsCount() < 1
                        ? 'messages.web.report_page.no_groups_assigned'
                        : 'messages.web.report_page.no_report_assigned'
                )|translate}}
                </h4>
            </div>

            <div
                [ngStyle]="{'width': width, 'height': height}"
                *ngIf="!reportIsLoading && reportSelected"
                [ngClass]="{'handbook-container': hasHandbooks}"
            >
                <div class="main-page-title" *ngIf="!hasHandbooks && title">{{ title }}</div>
                <div class="main-page-subtitle" *ngIf="subtitle">{{ subtitle }}</div>

                <ng-container *ngFor="let content of reportData?.contents">
                    <ng-container *ngIf="content.title">
                        <h2 class="content-title" *ngIf="content.type=='handbook'">{{ content.title }}</h2>
                        <div class="content-title" *ngIf="content.type!='handbook'">{{ content.title }}</div>
                    </ng-container>

                    <div
                        *ngIf="content.type=='dashboard'"
                        class="dc-data-count dc-chart"
                        style="margin-left: 10px; margin-top: 17px;margin-bottom: 15px;"
                        #dataCount
                        (click)="resetAll()"
                    >{{'messages.web.dashboards.records_selected' | translate}}</div>
                    <div class="clearfix"></div>

                    <div class="content-subtitle" *ngIf="content.subtitle">{{ content.subtitle }}</div>

                    <app-html *ngIf="content.type=='html'" [html]="content.ngx_content">Html</app-html>
                    <app-markdown *ngIf="content.type=='markdown'" [html]="content.ngx_content">Markdown</app-markdown>
                    <app-handbook *ngIf="content.type=='handbook'" [content]="content" [creator]="reportData.creator">
                        Handbook
                    </app-handbook>
                    <app-dashboard *ngIf="content.type=='dashboard'" [content]="content" [shareId]="shareId">Dashboard</app-dashboard>
                    <app-iframe *ngIf="content.type=='shiny'" [id]="content.id" [url]="content.ngx_content">Shiny
                    </app-iframe>
                    <app-dynamic-html *ngIf="content.type=='dynamic-html'" [html]="content.ngx_content">Dynamic Html
                    </app-dynamic-html>

                    <app-iframe *ngIf="content.type=='iframe-html'" [id]="content.id" [url]="content.ngx_content">Iframe
                    </app-iframe>
                    <app-presentation *ngIf="content.type=='presentation'" [id]="content.id">Presentation
                    </app-presentation>
                    <app-network-share *ngIf="content.type=='network-share'" [initialFolder]="content.remote_id">Network
                        Share
                    </app-network-share>

                    <app-course
                        *ngIf="content.type=='course'"
                        [courseId]="content.remote_id"
                        [creator]="reportData.creator"
                    >
                    </app-course>

                    <app-web-component *ngIf="content.type=='web-app'" [content]="content.ngx_content">
                    </app-web-component>

                    <div *ngIf="content.type.indexOf('chart') >= 0" style="margin-top: 10px">
                        <strong style="color: #b22222;">
                            {{'messages.web.report_page.not_supported'|translate}}
                        </strong>
                    </div>
                    <div *ngIf="content.type=='r-table'" style="margin-top: 10px">
                        <strong style="color: #b22222">
                            {{'messages.web.report_page.not_supported'|translate}}
                        </strong>
                    </div>
                    <div *ngIf="content.type=='table'" style="margin-top: 10px">
                        <strong style="color: #b22222">
                            {{'messages.web.report_page.not_supported'|translate}}
                        </strong>
                    </div>
                    <div *ngIf="content.type=='ssrs'" style="margin-top: 10px">
                        <strong style="color: #b22222">
                            {{'messages.web.report_page.not_supported'|translate}}
                        </strong>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-sidebar-right></app-sidebar-right>
