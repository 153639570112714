<app-form-loading [loading]="formLoadingServiceService.isLoading()">
</app-form-loading>
<ng-container *ngIf="!formLoadingServiceService.isLoading()">
    <form
        (ngSubmit)="updateField()"
        [formGroup]="form"
        autocomplete="off"
        class="form-style-bordered-inputs"
        name="myForm"
        novalidate="novalidate"
        role="form"
    >
        <app-display-input-errors
            [displayErrors]="errors"
            [errorKey]="'_global'"
        ></app-display-input-errors>

        <div *ngFor="let field of fields" class="form-group">
            <ng-container *ngIf="field.type === 'string'">
                <label
                    [for]="'text_' + field.key"
                    class="control-label"
                >{{('messages.list_values.fields.' + field.key)|translate}}
                    <i
                        *ngIf="field.info"
                        [popover]="field.info"
                        triggers="hover"
                        class="icon ion-information-circled"
                    ></i>
                </label>
                <textarea
                    [formControlName]="field.key"
                    [id]="'text_' + field.key"
                    [name]="field.key"
                    class="form-control auto-height"
                    appTextArea
                    (input)="autoHeight($event.target)"
                ></textarea>
            </ng-container>

            <ng-container *ngIf="field.type === 'number'">
                <label
                    [for]="'text_' + field.key"
                    class="control-label"
                >{{('messages.list_values.fields.' + field.key)|translate}}
                    <i
                        *ngIf="field.info"
                        [popover]="field.info"
                        triggers="hover"
                        class="icon ion-information-circled"
                    ></i>
                </label>
                <input
                    [formControlName]="field.key"
                    [id]="'number_' + field.key"
                    [name]="field.key"
                    class="form-control"
                    type="number"
                />
            </ng-container>

            <ng-container *ngIf="field.type === 'date'">
                <app-datepicker
                    [customConfig]="{
                                id: ('date_' + field.key),
                                label:('messages.list_values.fields.' + field.key),
                                format: 'YYYY-MM-DD',
                                useUtc: true
                             }"
                    [formControlName]="field.key"
                    [formName]="formName"
                ></app-datepicker>
            </ng-container>

            <ng-container *ngIf="field.type === 'list'">
                <ng-container *ngIf="field.multiple">
                    <app-typeahead-input
                        [data]="listsValues[field.listValuesKey] || []"
                        [fieldName]="field.key"
                        [formControlName]="field.key"
                        [formName]="formName"
                        [multiple]="true"
                    >
                    </app-typeahead-input>
                </ng-container>
                <ng-container *ngIf="!field.multiple">
                    <label
                        [for]="'list_' + field.key"
                        class="control-label"
                    >{{('messages.list_values.fields.' + field.key)|translate}}
                        <i
                            *ngIf="field.info"
                            [popover]="field.info"
                            triggers="hover"
                            class="icon ion-information-circled"
                        ></i>
                    </label>
                    <select
                        [formControlName]="field.key"
                        [id]="'list_' + field.key"
                        [name]="field.key"
                        class="form-control"
                    >
                        <option [value]="''" hidden selected></option>
                        <option
                            *ngFor='let option of listsValues[field.listValuesKey]; let i = index'
                            [value]="option.id"
                        >{{option.stringified}}</option>
                    </select>
                </ng-container>
            </ng-container>
            <app-display-input-errors
                [displayErrors]="errors"
                [errorKey]="field.key"
            ></app-display-input-errors>
            <app-input-errors [control]="form.controls[field.key]"></app-input-errors>
        </div>
        <ng-container *ngIf="!hideButtons">
            <app-form-submited-loading *ngIf="submitted"></app-form-submited-loading>
            <button class="btn btn-sm btn-primary" type="submit">
                {{'messages.global.ok'|translate}}
            </button>
            &nbsp;
            <button (click)="closeModalWindow()" class="btn btn-sm btn-default" type="button">
                {{'messages.global.cancel'|translate}}
            </button>
        </ng-container>
    </form>
</ng-container>
