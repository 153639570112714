import {FormGroup, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export function daysAllowanceValidator(typeControlName, startControlName, endControlName, allowedDays: number): ValidatorFn {
    const syncErrors = (control, startValue: string, hasError = false) => {
        const errors = control.errors ? {...control.errors} : {};

        if (hasError) {
            const [year] = startValue?.toString().split('-');
            //errors.allowance = {days: allowedDays, year};
        } else {
            delete errors.allowance;
        }

        if (Object.keys(errors).length) {
            control.setErrors(errors);
        } else {
            control.setErrors(null);
        }
    };

    return (formGroup: FormGroup): ValidatorFn => {
        const typeControl = formGroup.controls[typeControlName];
        const startControl = formGroup.controls[startControlName];
        const endControl = formGroup.controls[endControlName];

        // paid leave
        if (typeControl?.value?.toString() !== '1') {
            syncErrors(endControl, '', false);
            return;
        }

        const diff = Math.ceil((moment(endControl.value).unix() - moment(startControl.value).unix()) / 60 / 60 / 24);
        syncErrors(endControl, startControl.value, diff > allowedDays);

        return;
    };
}
