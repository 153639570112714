import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {EntityProviderService} from '../../../services/entity-provider.service';
import {ProvidedEntity} from '../../../models/models';

@Component({
    selector: 'app-folders-tree',
    templateUrl: './folders-tree.component.html',
    styleUrls: ['./folders-tree.component.scss']
})
export class FoldersTreeComponent implements OnInit {

    constructor(private api: ApiService, private entityProvider: EntityProviderService) {
    }

    folders: any = null;
    company: any = null;
    companies: ProvidedEntity[] = [];
    loading = false;

    ngOnInit(): void {
        this.entityProvider.getCompanies().subscribe(data => {
            this.companies = data;
            this.company = 'global';
            this.fetchTree(this.company);
        });
    }

    testChange(e) {
        console.log(e);
    }

    fetchTree(companyId = null) {
        this.loading = true;
        this.folders = [];

        this.api.getFoldersTree(companyId).subscribe(data => {
            this.folders = data;
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    handleSubmit() {
        if (this.loading) {
            return;
        }

        this.api.updateFoldersTree({tree: this.folders}).subscribe(() => {
            this.folders = null;

            this.api.getFoldersTree(this.company).subscribe(data => {
                this.folders = data;
                this.loading = false;
            }, (e) => {
                console.log(e);
                this.loading = false;
            });
        });
    }
}
