<app-modal
    [loading]="submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="changeStartupSettings()"
    [mTitle]="'messages.admin.users_page.change_startup_setttings'|translate"
>
    <form
        (ngSubmit)="changeStartupSettings()"
        [formGroup]="form"
        autocomplete="off"
        class="form-style-bordered-inputs"
        name="myForm"
        novalidate="novalidate"
        role="form"
    >
        <app-form-loading [loading]="formLoadingServiceService.isLoading()"></app-form-loading>

        <div *ngIf="!formLoadingServiceService.isLoading()">
            <app-display-input-errors [errorsStrings]="formGlobalErrors()"></app-display-input-errors>

            <app-select
                [errors]="errors"
                formName="userSettings"
                [options]="[rememberLastSession, openThisItem]"
                fieldName="rememberSession"
                formControlName="rememberSession"
            ></app-select>

            <app-provided-entity-select
                *ngIf="form.value.rememberSession === 'false'"
                [errors]="errors"
                [options]="folders"
                formName="userSettings"
                fieldName="favoriteReportPath"
                formControlName="favoriteReportPath"
            ></app-provided-entity-select>
        </div>
    </form>
</app-modal>
