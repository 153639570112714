<div class="form-group" [ngClass]="{inline: inline}">
    <label
        *ngIf="withLabel"
        class="control-label dynamic-font"
        [style.color]="currentErrors && currentErrors.length ? '#a94442' : null"
        [for]="id"
    >{{label|translate}}</label>
    <select
        *ngIf="!multiple"
        [id]="id"
        class="form-control"
        [(ngModel)]="value"
        [disabled]="disabled"
        [style.minHeight]="multiple? '200px': ''"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
    >
        <option hidden [value]="''" selected>{{placeholder|translate}}</option>
        <option *ngFor='let option of options; let i = index' [value]="option.value">{{option.name}}</option>
    </select>

    <select
        *ngIf="multiple"
        [id]="id"
        class="form-control"
        [(ngModel)]="value"
        [disabled]="disabled"
        [style.minHeight]="multiple? '200px': ''"
        [ngClass]="{'is-invalid': currentErrors && currentErrors.length}"
        multiple
    >
        <optgroup *ngFor="let group of optionsGroups" [label]="group.name">
            <option *ngFor="let item of group.values" [value]="item.value">
                {{item.name}}
            </option>
        </optgroup>
    </select>
    <ng-content>
    </ng-content>
</div>

<app-display-input-errors [errorsStrings]="currentErrors"></app-display-input-errors>


