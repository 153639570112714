import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisplayError, FormErrors, ProfileListValue, ProfileListValues} from '../../../../models/models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';

@Component({
    selector: 'app-profile-values-form',
    templateUrl: './profile-values-form.component.html',
    styleUrls: ['./profile-values-form.component.scss']
})
export class ProfileValuesFormComponent implements OnInit {
    @Input() profileListValues: ProfileListValues;
    @Input() loading = false;

    @Output() formCancel = new EventEmitter();
    @Output() formSubmit = new EventEmitter();

    @Input() set errors(data: FormErrors) {
        if (!data) {
            return;
        }

        Object.entries(data).forEach(([key, errors]) => {
            errors.forEach(error => {
                    this.localErrors.push({
                        field: key,
                        type: 'custom',
                        message: error,
                        options: {}
                    });
                }
            );
        });
    }


    public form: FormGroup;
    public localErrors: DisplayError[];

    constructor(private formBuilder: FormBuilder, private errorsTransformer: FormErrorsTransformerService) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({});
        const {form} = this;
        this.localErrors = [];

        Object.keys(this.profileListValues).forEach(key => {
            form.addControl(key, this.formBuilder.array([]));
            const control = form.get(key) as FormArray;

            (this.profileListValues[key] as ProfileListValue[]).forEach(value => {

                const controlConfig = {
                    id: [value.id],
                    value: [value.value, [Validators.required]],
                    icon: undefined,
                };

                if (key === 'badges') {
                    controlConfig.icon = [value.icon];
                }

                control.push(this.formBuilder.group(controlConfig));
            });
        });
    }

    addValue(controlKey: any) {
        const control = this.form.get(controlKey) as FormArray;

        if (!control) {
            return;
        }

        const controlConfig = {
            value: ['', [Validators.required]],
            icon: undefined,
        };

        if (controlKey === 'badge') {
            controlConfig.icon = [''];
        }

        control.push(this.formBuilder.group(controlConfig));
    }

    removeValue(controlKey: any, index: number) {
        const control = this.form.get(controlKey) as FormArray;

        if (!control) {
            return;
        }

        control.removeAt(index);
    }

    submit(event: any) {
        event.preventDefault();

        if (this.loading) {
            return;
        }

        this.localErrors = [];

        Object.keys(this.form.controls).forEach(key => {
            this.localErrors.push(...this.errorsTransformer.getNestedFormErrors(key, this.form.controls[key] as FormArray));
        });

        if (this.localErrors.length) {
            return;
        }

        this.formSubmit.emit(this.form.value);
    }

    handleUpload(input, control) {
        const fr = new FileReader();

        fr.readAsDataURL(input.files[0]);
        fr.onload = () => {
            control.get('icon').setValue(fr.result);
        };
    }

    cancel(event: any) {
        event.preventDefault();
        this.formCancel.emit();
    }
}
