import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CourseDashboardItem} from '../../../models/models';
import * as _ from 'lodash';


@Component({
    selector: 'app-university-dashboard',
    templateUrl: './university-dashboard.component.html',
    styleUrls: ['./university-dashboard.component.scss']
})
export class UniversityDashboardComponent implements OnInit {
    data: CourseDashboardItem[] = null;
    allCourses: CourseDashboardItem[] = null;
    myCourses: CourseDashboardItem[] = null;
    filteredData: CourseDashboardItem[] = null;
    showMy = true;
    showAll = false;
    private searchValue = '';

    constructor(private api: ApiService, public sanitized: DomSanitizer) {
    }

    ngOnInit(): void {
        this.fetchValues().then(() => {
            this.myCourses && this.myCourses.length ? this.selectMy() : this.selectAll();
        });
    }

    mapUsers(users) {
        return users.map(item => item.name).join(', ');
    }

    handleSearch(value: string) {
        if (!this.data) {
            this.filteredData = [];
            this.searchValue = '';
            return;
        }

        if (!value) {
            this.filteredData = this.data;
            this.searchValue = '';
            return;
        }

        const searchString = value.toLowerCase();
        this.searchValue = searchString;

        this.filteredData = this.data.filter(item => item.name.toLowerCase().includes(searchString));
    }

    selectAll() {
        this.data = this.allCourses;
        this.handleSearch(this.searchValue);
        this.showAll = true;
        this.showMy = false;
    }

    selectMy() {
        if (!this.myCourses || !this.myCourses.length) {
            return;
        }

        this.data = this.myCourses;
        this.handleSearch(this.searchValue);
        this.showAll = false;
        this.showMy = true;
    }

    async fetchValues() {
        const [courses, myCourses] = await Promise.all([this.api.getCourses().toPromise(), this.api.getDashboard().toPromise()]);
        this.myCourses = myCourses;

        if (myCourses && myCourses.length) {
            myCourses.forEach(item => {
                const [toReplace] = courses.filter(course => course.id === item.id);
                courses.splice(courses.indexOf(toReplace), 1, _.cloneDeep(item));
            });
        }

        this.allCourses = courses;
        this.data = courses;
        this.handleSearch(this.searchValue);
    }
}
