import {AbstractControl, ValidatorFn} from '@angular/forms';

export function maxFileSize(size: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as File;

        if (!(value instanceof File)) {
            return;
        }

        const valid = value.size <= 1024 * 1024 * size;

        return !valid ? {maxFileSize: {currentValue: (value.size / 1024 / 1024).toFixed(2), maxValue: size}} : null;
    };
}
