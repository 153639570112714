<div class="page-error">
    <div class="text-center">

        <img src="../../../../../assets/img/error404.png" class="mb-4">

        <h1 style="font-size: 2rem; text-transform: none">Oops! An Error Occurred</h1>

        <p>The server returned a "404 Page Not Found".</p>

        <p>Looks like you are looking for a page that simply doesn't exist.</p>

        <p>
            <a
                routerLink="/"
                class="btn btn-lg btn-primary text-capitalize"
                role="button"
            >
                {{'messages.request_to_join_page.back'|translate}}
            </a>
        </p>
    </div>
</div>
