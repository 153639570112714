import {Component, OnInit} from '@angular/core';
import {AdminLogsList, Pager} from '../../../models/models';
import {ApiService} from '../../../services/api.service';
import {PaginationService} from '../../../services/pagination.service';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

    public inactive: boolean;
    public busy: boolean;
    public entities: AdminLogsList[];
    public selectedEntity: AdminLogsList;

    constructor(private api: ApiService, public paginationService: PaginationService) {
    }

    ngOnInit() {
        this.inactive = false;
        this.loadList();
        this.busy = false;
    }

    loadList() {
        this.api.getLogs(this.paginationService.pager).subscribe(response => {
            this.entities = response;
        });
    }

    selectEntity(entity: AdminLogsList) {
        if (this.selectedEntity === entity) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = entity;
        }
    }

    deleteLogs() {
        return this.api.deleteLogs().subscribe(response => {
            this.api.getLogs(this.paginationService.pager).subscribe(resp => {
                this.entities = resp;
            });
        });
    }

    paginateFilterSort(pager: Pager) {
        this.api.getLogs(pager).subscribe(response => {
            this.entities = response;
        });
    }
}
