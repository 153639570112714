import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-youtube-settings',
    templateUrl: './youtube-settings.component.html',
    styleUrls: ['./youtube-settings.component.scss']
})
export class YoutubeSettingsComponent implements OnInit {

    public youtubeUrl: string = null;
    public dailyUrl: string = null;

    constructor(private api: ApiService, private toast: ToastrService) {
    }

    ngOnInit(): void {
        if (window.location.search.includes('youtube_registered=1')) {
            this.toast.success('Youtube account is added successfully!');
        }

        if (window.location.search.includes('daily_registered=1')) {
            this.toast.success('Dailymotion account is added successfully!');
        }

        this.api.getYoutubeRegisterUrl().subscribe(data => {
            this.youtubeUrl = data.redirect_url;
        });

        this.api.getDailymotionRegisterUrl().subscribe(data => {
            this.dailyUrl = data.redirect_url;
        });
    }

}
