<div class="container-fluid p-0 news-container">
    <div class="d-sm-flex align-items-sm-center justify-content-sm-between">
        <h3 class="mb-2">Evo Feed</h3>
        <div>
            <label class="search" style="">
                <input
                    type="text"
                    placeholder="Search"
                    (input)="searchInputChanged.next($event.target.value)"
                >
                <i class="ion ion-search"></i>
            </label>
        </div>
    </div>

    <app-form-loading *ngIf="busy && !news?.length" loading="true">
    </app-form-loading>

    <div class="row" *ngIf="news?.length">
        <div
            class="col-12 col-md-6 col-lg-4 p-0" *ngFor="let item of news; last as l"
            [ngClass]="{'mb-2': !l}"
        >
            <div class="row news-info flex-column">
                <a [routerLink]="'/app/feed/'+item.slug">
                    <div
                        class="p-0 c-image-container"
                        [style.background-image]="'url(' + ('/static-content/news-preview/' + item.id + '.jpg'|apiKey) + ')'"
                    >
                    </div>
                </a>
                <div class="p-3 w-100">
                    <a [routerLink]="'/app/feed/'+item.slug">
                        <h5><strong>{{item.title}}</strong></h5>
                    </a>
                    <div class="summary mb-2">{{item.short_description}}</div>
                </div>

                <div class="row mr-0 ml-0 mt-auto">
                    <div class="col-12 text-center">
                        <a
                            [routerLink]="'/app/feed/'+item.slug"
                            class="btn btn-primary btn-sm"
                            style="min-width: min-content"
                        >{{'messages.news.list.read'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="searchValue && !news?.length && !busy">
        <div class="col-12 text-center mt-5">
            <h5>😞 {{'messages.news.list.nothing_is_found'|translate}}...</h5>
        </div>
    </div>
</div>
