<div *ngIf="boards.length" class="boards d-flex">
    <app-git-column
        (addIssue)="router.navigateByUrl('/app/board/new-request')"
        (loadNext)="loadNext()"
        (reloadClosed)="reloadClosed()"
        *ngFor="let board of boards; first as isFirst"
        [board]="board"
        [busy]="board.name ==='Closed' && busy"
        [withAdd]="isFirst"
    >
    </app-git-column>
</div>
