<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.folders_page.copy_folder' | translate"
>
    <app-form
        [formConfig]="formConfig"
        [lists]="lists"
        [hideButtons]="true"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="addFolder($event)"
    >
    </app-form>
</app-modal>
