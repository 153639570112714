import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LayoutConfigService} from '../../../services/layout-config.service';
import {ScreenshotService} from '../../../services/screenshot.service';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Content, FrontendPage} from '../../../models/models';
import {ReportsService} from '../../../services/reports.service';
import {DashboardService} from '../../../services/dashboard.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    encapsulation : ViewEncapsulation.None
})
export class ReportComponent implements OnInit, AfterViewChecked, OnDestroy {

    @ViewChild('dataCount') dataCount: ElementRef;
    public reportIsLoading = true;
    public reportData: FrontendPage;
    public title: string;
    public subtitle: string;
    public width = '100%';
    public height = 'auto';
    public reportSelected = false;
    private subscriptions: Subscription[] = [];
    public editContent: Content = null;

    public hasHandbooks = false;
    public managerOnlyVisible = true;

    constructor(private layoutConfigService: LayoutConfigService,
                private dashboardService: DashboardService,
                public reportsService: ReportsService,
                private screenshotService: ScreenshotService,
                private apiService: ApiService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                public auth: AuthService
    ) {
    }

    ngAfterViewChecked(): void {
        if (this.dataCount) {
            this.dashboardService.setDataCountElement(this.dataCount.nativeElement);
        }
    }

    async ngOnInit() {
        this.reportsService.clearSelectedReport();

        if (window.location.pathname === '/app/reports') {
            const isOneReport = await this.reportsService.isOneReport();
            const url = this.reportsService.getDefaultReportUrl();

            if (isOneReport && url) {
                this.router.navigateByUrl(`/app/reports/show/${url}`);
                return;
            }
        }

        this.fetchReport();
    }

    getReport(route: string, folderSlug: string) {
        this.reportIsLoading = true;
        const endpoint = folderSlug === 'global-pages' ? this.apiService.getGlobalReportOne(route) : this.apiService.getReportOne(route);
        this.subscriptions.push(endpoint.subscribe(response => {
            this.reportData = response;
            this.reportData.contents = response.contents.filter(item => !item.is_deleted);
            this.reportsService.setSelectedReport(response);
            this.reportIsLoading = false;
            this.title = this.reportData.title;
            this.subtitle = this.reportData.subtitle;

            this.hasHandbooks = response.contents.filter(content => content.type === 'handbook').length > 0;
        }, error => {
            this.reportIsLoading = false;
        }));
    }

    fetchReport() {
        this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
            if (params.pageSlug) {
                this.reportSelected = true;
                this.getReport(`${params.pageSlug}`, `${params.folderSlug}`);
            } else {
                this.reportSelected = false;
                this.reportIsLoading = false;
            }
        }));
    }

    resetAll() {
        this.dashboardService.resetAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });

        this.reportsService.clearSelectedReport();
    }

    handleModalClose() {
        this.editContent = null;
        this.fetchReport();
        this.reportsService.reportsSubject.next();
    }

    setEditContent(content: Content) {
        this.editContent = {...content, pages: [this.reportData]};
    }

    hideManagerOnlyNotification() {
        this.managerOnlyVisible = false;
    }
}
