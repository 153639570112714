import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-builder-row-chart',
  templateUrl: './builder-row-chart.component.html',
  styleUrls: ['./builder-row-chart.component.scss']
})
export class BuilderRowChartComponent implements OnInit {

    @Input() supportedXColumns: any;
    @Input() supportedYColumns: any;
    @Input() defaultConfig: { groupingFunctions: [{ id: string, name: string }], chartTemplates: [{id: number, name: string}] };
    @Input() chartConfig: any;
    @Input() index: number;
    @Input() weightByColumns: [];
    @Output() changeChartEmitter = new EventEmitter<any>();
    public expanded = false;
    selectChartType: any;


    @Output() updateConfigEmitter = new EventEmitter();
    @Output() removeChartEmitter = new EventEmitter();
    public angles = [0, 45, 90];
    public sorting = ['DESC', 'ASC', 'Alphabetically'];
    public colors = [
        {name: 'Green', value: '#31A354',  codes: {first: '#31A354', last: '#BAE4B3'}},
        {name: 'Blue', value: '#2B8CBE', codes: {first: '#2B8CBE', last: '#BDC9E1'}},
        {name: 'Red', value: '#F03B20', codes: {first: '#F03B20', last: '#FECC5C'}},
        {name: 'Orange', value: '#E6550D', codes: {first: '#E6550D', last: '#FDBE85'}},
        {name: 'Yellow', value: '#D95F0E', codes: {first: '#D95F0E', last: '#FED98E'}},
        {name: 'Pink', value: '#DD1C77', codes: {first: '#DD1C77', last: '#DF65B0'}},
        {name: 'Purple', value: '#756BB1', codes: {first: '#756BB1', last: '#CBC9E2'}},
        {name: 'Grey', value: '#636363', codes: {first: '#636363', last: '#CCCCCC'}}
    ];

    public firstColor = '#31A354';
    public secondColor = '#31A354';


    public editFirst = false;
    public editSecond = false;

    constructor() {
    }

    ngOnInit() {
        this.selectChartType = this.chartConfig.templateId;
    }

    changeChart() {
        this.changeChartEmitter.emit({id: 1, index: 1, type: this.selectChartType, title: this.chartConfig.title});
    }

    updateChart() {
        return this.updateConfigEmitter.emit({index: this.index, config: this.chartConfig});
    }

    removeChart() {
        return this.removeChartEmitter.emit( this.index);
    }

    setColorPalette(color: { codes: { last: string; first: string }}) {
        this.chartConfig.paletteFirstColor = color.codes.first;
        this.chartConfig.paletteLastColor = color.codes.last;
        this.updateChart();
    }

    removeSerie(i: number) {
        this.chartConfig.series.splice(i, 1);
        this.updateChart();
    }

    addSerie() {
        this.chartConfig.series.push(
            {
                id: this.chartConfig.series.length + 1,
                columnName: this.supportedXColumns[0].columnName,
                groupBy: {
                    groupingFunction: 'Sum', // this.supportedGroupingFunctionsByColumnName[columnName][0].id,
                    weightByColumnName: 'tool_week'// this.weightByColumnNames[0]
                },
                useRightYAxis: false
            }
        );
        this.updateChart();
    }
}
