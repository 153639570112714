import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../../../../models/models';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements ControlValueAccessor {
    public currentValue: boolean;
    public disabled: boolean;
    public label: string;
    public id: string;
    public touch: any;

    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;

    @Input() set config(value: FormInputConfig) {
        const {label, id} = value;

        this.label = label || null;
        this.id = id || null;
    }

    constructor() {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: any) {
        this.currentValue = !!newValue;
        this.onChange(newValue);
    }

    onChange: any = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
