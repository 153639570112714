import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormInputConfig} from '../../../../../models/models';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AuthService} from '../../../../../services/auth.service';
import {ApiService} from '../../../../../services/api.service';

@Component({
    selector: 'app-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => HtmlEditorComponent),
        multi: true
    }]
})
export class HtmlEditorComponent implements OnInit, ControlValueAccessor {
    @ViewChild('fileUploader') fileUploader;
    @Input() config: FormInputConfig;
    @Input() submitted: boolean;
    @Input() isFieldValid: boolean;

    public disabled = false;
    public editorConfig = {
        base_url: '/tinymce', // Root for resources
        suffix: '.min',        // Suffix to use when loading resources
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'fullscreen | undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        image_advtab: true,
        content_css: '//www.tiny.cloud/css/codepen.min.css',
        importcss_append: true,
        file_picker_types: 'file image media',
        paste_data_images: true,
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table',
        file_picker_callback: undefined,
        images_upload_handler: undefined,
        relative_urls: false,
        convert_urls: false,
        remove_script_host: false
    };
    public touch: any;
    private currentValue = '';

    constructor(private auth: AuthService, private api: ApiService) {
    }

    public get value() {
        return this.currentValue;
    }

    public set value(newValue: any) {
        this.currentValue = newValue;
        this.onChange(newValue);
    }

    ngOnInit() {
        let fileUploadCb = null;

        this.editorConfig.file_picker_callback = (callback, value, meta) => {
            this.fileUploader.nativeElement.removeEventListener('change', fileUploadCb);
            fileUploadCb = (event) => {
                if (!event.target.files) {
                    return;
                }

                const data = new FormData();

                for (const file of event.target.files) {
                    data.append('file', file);
                }

                event.target.files = null;
                event.target.vale = null;

                this.api.uploadFile(data).subscribe((resp) => {
                    callback(resp.location);
                });

                this.fileUploader.nativeElement.removeEventListener('change', fileUploadCb);
            };

            this.fileUploader.nativeElement.click();
            this.fileUploader.nativeElement.addEventListener('change', fileUploadCb);
        };

        this.editorConfig.images_upload_handler = (blobInfo, success, failure) => {
            const data = new FormData();
            data.append('file', blobInfo.blob(), blobInfo.filename());
            this.api.uploadFile(data).subscribe(resp => {
                success(resp.location);
            }, error => {
                failure('Image upload failed!');
            });
        };
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onChange: any = () => {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
