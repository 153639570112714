<app-user-profile-documents-form
    (closeModal)="closeModal(true)"
    *ngIf="showDocumentsFrom"
    [userId]="profileId"
>
</app-user-profile-documents-form>

<app-password-change-modal
    (closeModal)="closeModal()"
    (updateProfileSubmit)="closeModal()"
    *ngIf="profileSecurity.isProfileOwner && showPasswordUpdate"
>
</app-password-change-modal>

<ng-container *ngIf="profile">
    <a class="btn btn-sm btn-primary" routerLink="/app/team">
        {{'messages.global.back'|translate}}
    </a>

    <div class="profile">
        <div class="header d-flex flex-column flex-md-row">
            <div class="avatar mb-4 mb-md-0 mr-0 mr-md-4">
                <img [src]="avatar" alt="avatar">
                <ng-container *ngIf="profileSecurity.isUserSubordinate || profileSecurity.isProfileOwner">
                    <input #uploadFile (change)="uploadAvatar($event)" style="display: none" type="file">
                    <i
                        (click)="uploadFile.click()"
                        *ngIf="profileSecurity.isUserSubordinate || profileSecurity.isProfileOwner"
                        class="icon icon-size-7 ion-edit color-light-green pointer"
                        style="vertical-align: 0; margin-left: 5px;"
                    ></i>
                </ng-container>
            </div>
            <div class="description mb-2 mb-md-0">
                <h1>{{profile.name}}
                    <ng-container *ngIf="profile.tenure">({{profile.tenure}})</ng-container>
                    <img
                        *ngIf="profile.isBirthday"
                        src="../../../../assets/img/birthdaycake.png"
                        alt="birthday_cake"
                    >
                    <ng-container *ngIf="profile.email.indexOf('.local') == -1 else ldapForgotPassword">
                        <br>
                        <button
                            (click)="showPasswordUpdate = true"
                            *ngIf="profileSecurity.isProfileOwner"
                            class="btn btn-sm btn-primary"
                        >
                            {{'messages.admin.users_page.change_password'|translate}}
                        </button>
                    </ng-container>
                    <ng-template #ldapForgotPassword>
                        <br>
                        <button
                            (click)="goToLink('https://reset-t1uvtrf8l9.evouser.com/')"
                            *ngIf="profileSecurity.isProfileOwner"
                            class="btn btn-sm btn-primary"
                        >
                            {{'messages.admin.users_page.change_password'|translate}}
                        </button>
                    </ng-template>
                </h1>

                <p>
                    <span *ngIf="profile.department">{{profile.department}}</span>
                    <span *ngIf="profile.department && profile.role">: </span>
                    <span *ngIf="profile.role">{{profile.role}}</span>
                </p>
                <p>
                    <a *ngIf="profile.email" [href]="'mailto:'+profile.evoEmail">{{profile.evoEmail}}</a>
                    <span *ngIf="profile.email && profile.mobile">: </span>
                    <a *ngIf="profile.mobile" [href]="'tel:'+profile.mobile">{{profile.mobile}}</a>
                </p>
                <p>
                    {{'messages.list_values.fields.timeNow'|translate}}: {{profile.timeNow}}
                </p>
            </div>
            <div class="video-container">
                <app-user-video
                    *ngIf="profile.email.includes('evopricing')"
                    [userId]="profileId"
                    [videoId]="profile.videoId"
                    [videoService]="profile.videoService"
                    [canEdit]="profileSecurity.canEditDocuments"
                    (updated)="closeForm($event)"
                >
                </app-user-video>
            </div>
        </div>
        <div class="info-rows">
            <div class="info-row" *ngIf="auth.isEvoEmployee()">
                <div class="info-label"><i class="icon icon-size-10 color-light-green ion-arrow-graph-up-right"></i> KPI</div>
                <div>
                    <person-kpi-app [attr.user-email]="currentUserEmail"></person-kpi-app>
                </div>
            </div>
            <ng-container *ngFor="let column of infoColumns">
                <app-profile-column-form
                    (closeForm)="closeForm($event)"
                    *ngIf="column.type === 'form' && profileSecurity.canSeeRow(column.rows)"
                    [column]="column"
                    [profile]="profile"
                >
                    <div *ngIf="column.label === 'messages.profile_page.where_i_live'">
                        <iframe
                            *ngIf="profile.geoUrl"
                            [src]="('https://www.openstreetmap.org/export/embed.html?'+profile.geoUrl) | safeResourceUrl"
                            width="100%"
                            height="300"
                            class="border"
                        ></iframe>
                    </div>
                </app-profile-column-form>
                <app-profile-working-hours-column
                    (closeForm)="closeForm($event)"
                    *ngIf="column.type === 'workingHours'"
                    [column]="column"
                    [workingHours]="profile.workingHours"
                    [profile]="profile"
                >
                </app-profile-working-hours-column>
                <div
                    *ngIf="column.type === 'documents' && (profileSecurity.canEditDocuments || profile.documents.length)"
                    class="info-row"
                    style="margin-bottom: 15px"
                >
                    <div class="info-label">
                        <i
                            class="icon icon-size-10 ion-ios-copy-outline color-light-green"
                            style="vertical-align: -1px;"
                        ></i>
                        {{'messages.profile_documents.documents'|translate}}
                        <i
                            (click)="openDocumentsForm()"
                            *ngIf="profileSecurity.canEditDocuments"
                            class="icon icon-size-7 ion-edit color-light-green pointer float-right"
                            style="vertical-align: 0; margin-left: 5px;"
                        ></i>
                    </div>
                    <div *ngFor="let file of profile.documents">
                        <a (click)="downloadDocument(file)">
                            <i class="icon icon-size-7 ion-document color-light-green"></i> {{file.caption}}
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
