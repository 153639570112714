import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-admin-table-tr-info',
    templateUrl: './admin-table-tr-info.component.html',
    styleUrls: ['./admin-table-tr-info.component.scss']
})
export class AdminTableTrInfoComponent implements OnInit {

    @Input() admin: any;
    @Output() openModal = new EventEmitter();
    @Output() updateAdmin = new EventEmitter();

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'owning_company',
            name: 'messages.admin.admins_page.table.th.owner_of',
            type: 'string'
        },
        {
            id: 'groups',
            name: 'messages.admin.admins_page.table.th.groups',
            type: 'list'
        }
    ];

    constructor(private apiService: ApiService, private auth: AuthService) {
    }

    ngOnInit() {
    }

    demoteAdmin() {
        this.apiService.demoteAdmin(this.admin.id).subscribe(response => {
            this.updateAdmin.emit();
        });
    }

    promoteAdmin() {
        this.apiService.promoteAdmin(this.admin.id).subscribe(response => {
            this.updateAdmin.emit();
        });
    }

    canPromote(role: string) {
        if (role === 'super_admin') {
            return false;
        }

        if (this.auth.isSuperAdmin()) {
            return true;
        }

        return role === 'user';
    }

    canDemote(role: string) {
        if (role === 'user') {
            return false;
        }

        if (this.auth.isSuperAdmin()) {
            return true;
        }

        return role === 'content_manager';
    }
}
