import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AuthService} from '../../../../services/auth.service';
import {SentEmail} from '../../../../models/models';

@Component({
    selector: 'app-sent-messages-table-tr-info',
    templateUrl: './sent-messages-table-tr-info.component.html',
    styleUrls: ['./sent-messages-table-tr-info.component.scss']
})
export class SentMessagesTableTrInfoComponent implements OnInit {


    @Input() email: SentEmail;
    @Output() openModal = new EventEmitter();
    @Output() updatePage = new EventEmitter();

    public showHtml = false;
    public showText = false;

    public fields: { id: string, name: string, type: string }[] = [
        {
            id: 'recipients',
            name: 'Recipients',
            type: 'list'
        },
        {
            id: 'cc',
            name: 'CC',
            type: 'list'
        },
        {
            id: 'bcc',
            name: 'BCC',
            type: 'list'
        },
        {
            id: 'created_by',
            name: 'messages.global.table_column_created_by',
            type: 'string'
        }
    ];

    constructor(private apiService: ApiService, public auth: AuthService) {
    }

    ngOnInit() {
    }

    editPage() {
        return this.openModal.emit();
    }

    get htmlUrl() {
        return this.apiService.apiURL + '/sent-emails/' + this.email.id + '/html?api_key=' + this.auth.getApiKey();
    }

    downloadAttachment(id) {

    }
}
