import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {AdminPagesList, FormConfig, FormLists, PageModel, ProvidedEntity} from '../../../../models/models';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {ApiService} from '../../../../services/api.service';
import {FormComponent} from '../../../../modules/form-generator/components/form/form.component';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';

@Component({
    selector: 'app-page-modal',
    templateUrl: './page-modal.component.html',
    styleUrls: ['./page-modal.component.scss'],
    providers: [FormLoadingServiceService]
})
export class PageModalComponent implements OnInit, OnDestroy {

    @Output() addComponent = new EventEmitter();
    @Input() page: AdminPagesList;
    @ViewChild(FormComponent, {static: true}) form: FormComponent<PageModel>;

    public formConfig: FormConfig = {
        fields: [
            {
                name: 'company',
                label: 'messages.admin.pages_page.form.page.fields.company.label',
                id: 'page_Company',
                type: 'provided-entity-select',
                // validators: [Validators.required],
                propertyPath: 'company.id'
            },
            {
                name: 'folder',
                label: 'messages.admin.pages_page.form.page.fields.folder.label',
                id: 'page_Form',
                type: 'provided-entity-select',
                // validators: [Validators.required],
                propertyPath: 'folder.id',
                hidden: true
            },
            {
                name: 'global',
                label: 'messages.admin.pages_page.form.page.fields.global.label',
                placeholder: '',
                id: 'global',
                type: 'checkbox',
                propertyPath: 'is_global'
            },
            {
                name: 'shareEnabled',
                label: 'messages.global.share',
                placeholder: '',
                id: 'page_share_enabled',
                type: 'checkbox',
                propertyPath: 'is_share_enabled'
            },
            {
                name: 'name',
                label: 'messages.admin.pages_page.form.page.fields.name.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.name.label',
                id: 'page_name',
                type: 'text',
                validators: [Validators.required]
            },
            {
                name: 'displayName',
                label: 'messages.admin.folders_page.form.folder.fields.display_name2.label',
                placeholder: 'messages.admin.folders_page.form.folder.fields.display_name2.label',
                id: 'display_name',
                type: 'text',
                propertyPath: 'display_name'
            },
            {
                name: 'title',
                label: 'messages.admin.pages_page.form.page.fields.title.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.title.label',
                id: 'page_title',
                type: 'text'
            },
            {
                name: 'subtitle',
                label: 'messages.admin.pages_page.form.page.fields.subtitle.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.subtitle.label',
                id: 'page_subtitle',
                type: 'text'
            },
            {
                name: 'width',
                label: 'messages.admin.pages_page.form.page.fields.width.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.width.label',
                id: 'page_width',
                type: 'text',
                inputType: 'number'
            },
            {
                name: 'height',
                label: 'messages.admin.pages_page.form.page.fields.height.label',
                placeholder: 'messages.admin.pages_page.form.page.fields.height.label',
                id: 'page_height',
                type: 'text',
                inputType: 'number'
            },
            {
                name: 'url',
                label: 'URL (redirect)',
                placeholder: 'URL',
                id: 'page_url',
                type: 'text',
                inputType: 'text'
            },
            {
                name: 'contents',
                label: 'messages.admin.pages_page.form.page.fields.content.label',
                placeholder: '',
                id: 'page_contents',
                type: 'typeahead',
                multiple: true,
                validators: [Validators.required, Validators.minLength(1)]
            },
            {
                name: 'author',
                label: 'messages.global.author',
                id: 'page_author',
                type: 'typeahead',
                listValuesKey: 'users'
            },
            {
                name: 'departments',
                label: 'messages.list_values.departments',
                placeholder: '',
                id: 'page_departments',
                type: 'typeahead',
                multiple: true,
            },
            {
                name: 'commentsEnabled',
                label: 'messages.comments.comments',
                placeholder: '',
                id: 'page_comments_enabled',
                type: 'checkbox',
                propertyPath: 'is_comments_enabled'
            }
        ],
    };

    lists: FormLists = {};

    private subs$: [Subscription?] = [];

    constructor(
        private entityProvider: EntityProviderService,
        public formLoadingServiceService: FormLoadingServiceService,
        private apiService: ApiService,
        private auth: AuthService
    ) {
    }

    ngOnDestroy(): void {
        for (const sub$ of this.subs$) {
            sub$.unsubscribe();
        }
    }

    ngOnInit() {
        const [folderConfig] = this.formConfig.fields.filter(item => item.name === 'folder');
        const [companyConfig] = this.formConfig.fields.filter(item => item.name === 'company');
        if (!folderConfig) {
            throw new Error('Invalid form config!');
        }

        this.formLoadingServiceService.init(this.page ? 5 : 4);

        this.entityProvider.getCompanies().subscribe((data: ProvidedEntity[]) => {
            this.lists.company = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getContents().subscribe((data: ProvidedEntity[]) => {
            this.lists.contents = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.entityProvider.getProfileListsValues(this.auth.getLoggedUser().id).subscribe(data => {
            this.lists.departments = data.departments;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });


        this.entityProvider.getUsers().subscribe(data => {
            this.lists.users = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        if (this.page) {
            folderConfig.hidden = false;
            if (this.page.is_global) {
                companyConfig.hidden = true;
                this.entityProvider.getFolders({global: true}).subscribe((data) => {
                    this.lists.folder = data;
                    this.formLoadingServiceService.eventLoaded();
                }, error => {
                    this.formLoadingServiceService.eventLoaded();
                    console.log(error);
                });
            } else {
                if (this.page.company.id) {
                    this.entityProvider.getFolders({companyId: this.page.company.id}).subscribe((data) => {
                        this.lists.folder = data;
                        this.formLoadingServiceService.eventLoaded();
                    }, error => {
                        this.formLoadingServiceService.eventLoaded();
                        console.log(error);
                    });
                } else {
                    this.formLoadingServiceService.eventLoaded();
                }
            }
        }


        setTimeout(() => {
            let foldersRequest = null;
            const {form} = this.form;

            let sub$ = form.get('company').valueChanges.subscribe((value) => {
                if (foldersRequest) {
                    foldersRequest.unsubscribe();
                }

                foldersRequest = this.entityProvider.getFolders({companyId: value});

                foldersRequest.subscribe((data: ProvidedEntity[]) => {
                    this.lists = {...this.lists, folder: data};
                    const currentValue = form.value.folder;
                    foldersRequest = null;

                    if (!data.filter(item => item.id === currentValue).length) {
                        form.get('folder').setValue(null);
                    }

                    folderConfig.hidden = !form.value.company || !this.lists.folder || !this.lists.folder.length;

                    this.checkFolders();
                }, error => {
                    this.lists = {...this.lists, folder: []};
                    this.checkFolders();
                });
            });

            this.subs$.push(sub$);

            sub$ = form.get('global').valueChanges.subscribe((value) => {
                if (value === true) {
                    companyConfig.hidden = true;
                    folderConfig.hidden = false;
                    this.entityProvider.getFolders({global: true}).subscribe((data) => {
                        this.lists = {...this.lists, folder: data};
                        this.checkFolders();
                        this.formLoadingServiceService.eventLoaded();
                    }, error => {
                        this.lists = {...this.lists, folder: []};
                        this.checkFolders();
                    });
                } else {
                    companyConfig.hidden = false;
                    folderConfig.hidden = false;
                    form.get('folder').setValue(null);
                    this.entityProvider.getFolders({companyId: this.page.company.id}).subscribe((data) => {
                        this.lists = {...this.lists, folder: data};
                        this.checkFolders();
                        this.formLoadingServiceService.eventLoaded();
                    }, error => {
                        this.formLoadingServiceService.eventLoaded();
                        console.log(error);
                    });
                }
            });

            this.subs$.push(sub$);
        });
    }

    addPage(data: PageModel) {
        const request = this.page
            ? this.apiService.updatePage(this.page.id, data)
            : this.apiService.createPage(data);

        request.subscribe((response) => {
            this.addComponent.emit(true);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.addComponent.emit();
    }

    private checkFolders() {
        if (!this.lists.folder || !this.lists.folder.length) {
            this.form.unsubmit({
                error: {
                    company: ['Folders are missing in this company']
                }
            });
        } else {
            this.form.unsubmit();
        }
    }
}
