import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FormArray, FormBuilder} from '@angular/forms';
import {FormErrors, FormInputConfig, FormLists} from '../../../../../models/models';
import PropertyAccessor from '../../../../../helpers/PropertyAccessor';
import {FormErrorsHandlerService} from '../../../services/form-errors-handler.service';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

    constructor(private formBuilder: FormBuilder, private formErrorsHandler: FormErrorsHandlerService) {
    }

    @Input() submitted: boolean;
    @Input() form: FormArray;
    @Input() config: FormInputConfig;
    @Input() lists: FormLists = {};
    @Input() value: any;
    @Input() inputTemplate: TemplateRef<any>;

    errors: FormErrors = {};

    @Input() set responseErrors(value) {
        this.errors = this.getCollectionErrors(value);
    }

    ngOnInit(): void {
        if (Array.isArray(this.value)) {
            this.value.forEach(item => this.add(item));
        }
    }

    handleAdd(event: any) {
        event.preventDefault();

        this.add();
    }

    add(value: any = null) {
        const group = this.formBuilder.group({});


        this.config.collectionInputs.forEach(field => {
            const currentValue = this.getFieldValue(value, field);
            const control = this.formBuilder.control(currentValue, field.validators);
            // const action = field.disabled ? 'disable' : 'enable';
            // control[action]();

            group.addControl(field.name, control);
        });

        this.form.push(group);
    }

    remove(index: number) {
        this.form.removeAt(index);
    }

    public getListValues(key): [] {
        return this.lists ? (this.lists[key] || []) : [];
    }

    private getFieldValue(value: any, field: FormInputConfig) {
        if (!value) {
            return null;
        }

        return PropertyAccessor.getValue(value, field.propertyPath || field.name);
    }

    public checkErrors() {
        this.errors = this.formErrorsHandler.getNestedErrors(this.form);
    }

    private getCollectionErrors(respErrors: FormErrors) {
        const errors = {};
        const reg = new RegExp('^' + this.config.name + '\.(.+)');

        Object.entries(respErrors).forEach(([key, err]) => {
            const matches = reg.exec(key);
            if (!matches) {
                return;
            }

            errors[matches[1]] = err;
        });

        return errors;
    }
}
