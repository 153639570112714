import {Pipe, PipeTransform} from '@angular/core';
import {FormInputConfig} from '../../../models/models';
import {AbstractControl, FormGroup} from '@angular/forms';

@Pipe({
    name: 'controlGetForm'
})
export class ControlGetFormPipe implements PipeTransform {
    transform(value: FormGroup | AbstractControl, field: FormInputConfig): unknown {
        if (!value) {
            return null;
        }

        return value.get(field.name);
    }
}
