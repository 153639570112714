import {Component, OnInit, ViewChild} from '@angular/core';
import {WcResourcesInjectorService} from '../../../services/wc-resources-injector.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-data-validation-page',
  templateUrl: './data-validation-page.component.html',
  styleUrls: ['./data-validation-page.component.scss']
})
export class DataValidationPageComponent implements OnInit {

    public loaded = false;
    public failedLoad = false;

    @ViewChild('component_container') container;

    constructor(private wcResourcesInjector: WcResourcesInjectorService) {
    }

    ngOnInit(): void {
        const url = environment.webAppsDomain + '/data-validation-app/data-validation-app.min.js';

        this.wcResourcesInjector.addJs(url).then(() => {
            this.loaded = true;

            const el = document.createElement('data-validation-app');
            this.container.nativeElement.appendChild(el);
        }).catch(() => {
            this.loaded = false;
            this.failedLoad = true;
        });

    }

}
