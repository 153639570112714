import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {
    AdminContentsList,
    Content,
    ContentModel,
    FormConfig,
    FormInputConfig,
    FormLists,
    ProvidedEntity
} from '../../../../models/models';
import {FormComponent} from '../../../form-generator/components/form/form.component';
import * as _ from 'lodash';
import {ContentFormConfig} from '../formConfigs';
import {EntityProviderService} from '../../../../services/entity-provider.service';
import {ApiService} from '../../../../services/api.service';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {allowedMimeTypesValidator, passOnlyMimetypesValidator} from '../../../../validators/mimeTypesValidators';
import PropertyAccessor from '../../../../helpers/PropertyAccessor';

@Component({
    selector: 'app-content-type-handbook',
    templateUrl: './content-type-handbook.component.html',
    styleUrls: ['./content-type-handbook.component.scss'],
    providers: [FormLoadingServiceService]
})
export class ContentTypeHandbookComponent implements OnInit, OnDestroy {

    @Output() update = new EventEmitter<boolean | AdminContentsList>();
    @Input() content: AdminContentsList | Content | null;
    @Input() withFolder = false;
    @Input() page: number;
    public value = null;

    public existingFiles = [];
    public errors = [];
    private toDelete = [];
    public filesForm: FormArray;
    lists: FormLists = {};

    @ViewChild(FormComponent, {static: true}) form: FormComponent<ContentModel>;

    public formConfig: FormConfig = {
        fields: _.cloneDeep<FormInputConfig[]>(ContentFormConfig.contentHandbook)
    };

    public fileControl: FormControl;
    private publishedSub = null;

    constructor(private entityProvider: EntityProviderService,
                public formLoadingServiceService: FormLoadingServiceService,
                private apiService: ApiService,
                private formBuilder: FormBuilder,
                private auth: AuthService) {
    }

    ngOnInit() {
        let eventsCount = 1;

        if (this.content) {
            eventsCount++;
        }

        this.fileControl = this.formBuilder.control(null, [passOnlyMimetypesValidator(['image/png', 'image/jpeg'])]);
        this.formLoadingServiceService.init(eventsCount);

        this.entityProvider.getFolders().subscribe((data: ProvidedEntity[]) => {
            if (this.withFolder) {
                this.form.get('folder').hidden = false;
                this.form.form.get('folder').setValidators([Validators.required]);
            }

            this.lists.folder = data;
            this.formLoadingServiceService.eventLoaded();
        }, error => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.filesForm = this.formBuilder.array([]);

        setTimeout(() => {
            const descField = this.form.get('shortDescription');

            if (descField) {
                this.publishedSub = this.form.form.get('published').valueChanges.subscribe(value => {
                    descField.hidden = !value;
                });
            }
        });

        if (!this.content) {
            this.value = {};
            return;
        }

        this.apiService.manageContent(this.content.id).subscribe(data => {
            this.value = data;

            if (this.withFolder) {
                const folder = PropertyAccessor.getValue(this.content, 'pages.0.folder');

                if (folder) {
                    data.folder = {
                        id: folder.id,
                        stringified: folder.stringified || folder.display_name2 || folder.display_name
                    };
                }
            }

            const descField = this.form.get('shortDescription');

            if (descField) {
                descField.hidden = !this.content.published;
            }

            this.value.news = this.content.news;
            this.formLoadingServiceService.eventLoaded();
        }, () => {
            this.formLoadingServiceService.eventLoaded();
        });

        this.existingFiles = this.content.attachments ? this.content.attachments.map(item => ({...item})) : [];
    }

    ngOnDestroy() {
        if (this.publishedSub) {
            this.publishedSub.unsubscribe();
        }
    }

    async updateContent(data: ContentModel) {
        if (this.filesForm.status !== 'VALID') {
            this.form.unsubmit();
            return;
        }

        if (this.content) {
            const uploaded = await this.updateAttachments(this.content.id).catch(e => console.log(e));

            if (!uploaded) {
                this.form.unsubmit();
                return;
            }
        }

        if (data.folder && data.folder.id) {
            data.folderId = data.folder.id;
        }

        delete data.folder;

        const request = this.content
            ? this.apiService.updateContent(this.content.id, data, this.page)
            : this.apiService.createContentForUser(data, 'handbook');

        request.subscribe(async (response: any) => {
            if (!this.content) {
                await this.updateAttachments(response.id).catch(e => console.log(e));
            }

            if (this.fileControl.value && response.news?.id) {
                const formData = new FormData();
                formData.append('file', this.fileControl.value, this.fileControl.value.name);
                await this.apiService.uploadNewsPreview(response.news.id, formData).toPromise().catch(() => {
                });
            }

            this.update.emit(response);
        }, (response) => {
            this.form.unsubmit(response);
        });
    }

    closeModalWindow() {
        return this.update.emit(false);
    }

    addFile() {
        this.filesForm.push(this.formBuilder.control([], [allowedMimeTypesValidator()]));
    }

    async updateAttachments(id) {
        if (this.toDelete.length) {
            await this.apiService.deleteContentAttachmentFile(this.toDelete).toPromise().catch(e => console.log(e));
        }

        const files = this.filesForm.value.filter(item => item instanceof File);

        if (!files.length) {
            return true;
        }

        const form = new FormData();

        for (const item of files) {
            form.append('file[]', item, item.name);
        }

        const resp = await this.apiService.uploadContentAttachmentFile(id, form).toPromise().catch((e) => {
            this.errors = e.error.errors;
        });

        return !!resp;
    }

    removeFile(i) {
        this.errors = [];
        this.filesForm.removeAt(i);
    }

    removeExistingFile(id, i) {
        this.toDelete.push(id);
        this.existingFiles.splice(i, 1);
    }
}
