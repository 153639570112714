<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.users_page.change_password' | translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        (submitForm)="updateSubmit($event)"
    >
    </app-form>
</app-modal>
