import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReportsService} from '../../../../../services/reports.service';
import {ApiService} from '../../../../../services/api.service';
import {PageComment, UserForMention} from '../../../../../models/models';
import {AutoHeightTextareaComponent} from '../../../../../common/auto-height-textarea/auto-height-textarea.component';
import {AuthService} from '../../../../../services/auth.service';
import {allowedMimeTypesValidator} from '../../../../../validators/mimeTypesValidators';
import {getCaretCoordinates, getCaretWord} from '../../../../../helpers/CarretHelpers';
import {CommentsService} from '../../../../../services/comments.service';

@Component({
    selector: 'app-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements OnInit, OnDestroy {
    @ViewChild(AutoHeightTextareaComponent) textArea: AutoHeightTextareaComponent;

    form: FormGroup;
    submitted = false;
    @Input() comment: PageComment;
    @Input() parent = null;
    @Input() avatar = null;
    @Input() sender = null;
    @Input() withAvatar = true;
    @Input() baseForm = false;
    @Output() closeForm = new EventEmitter();

    public existingFiles = [];
    public errors = [];
    private toDelete = [];
    public filesForm: FormArray;
    public caretPosition = null;

    private currentWord;
    public possibleUsers: UserForMention[] = [];
    private subscriptions = [];
    private tabEventListener = null;

    constructor(private formBuilder: FormBuilder,
                private reportsService: ReportsService,
                private api: ApiService,
                private auth: AuthService,
                private commentsService: CommentsService
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            body: ['', Validators.required],
            parent: [this.parent],
            page: [this.reportsService.getSelectedReport().id],
        });

        const {comment} = this;

        if (comment) {
            this.form.get('body').setValue(comment.body);
            this.existingFiles = this.comment.attachments && this.comment.attachments.length
                ? this.comment.attachments.map(item => ({...item}))
                : [];
        }

        this.filesForm = this.formBuilder.array([]);
        this.commentsService.refreshUsers();

        this.subscribeToUpdates();

        setTimeout(() => {
            const el = this.textArea.area.nativeElement;

            this.tabEventListener = el.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.key !== 'Tab') {
                    return;
                }

                e.preventDefault();
                e.stopPropagation();

                if (this.currentWord && this.possibleUsers.length) {
                    this.insertMention(this.possibleUsers[0]);
                }
            });
        });
    }

    subscribeToUpdates() {
        const sub = this.form.get('body').valueChanges.subscribe((value) => {
            if (!value) {
                this.possibleUsers = [];
                this.currentWord = null;
                this.caretPosition = null;
            }

            const el = this.textArea.area.nativeElement;
            const coords = getCaretCoordinates(el);


            this.caretPosition = {bottom: (coords.top + coords.height) + 'px', left: coords.left + 'px', zIndex: 100};
            this.currentWord = getCaretWord(value, el.selectionEnd);

            if (!this.currentWord || this.currentWord.word[0] !== '@') {
                this.possibleUsers = [];
                return;
            }

            const currentWord = this.currentWord.word.substring(1).toLowerCase();
            const [user] = this.commentsService.users.filter(item => item.username === currentWord);

            if (user) {
                return;
            }

            this.possibleUsers = this.commentsService.users
                .filter(item => item.name.toLowerCase().includes(currentWord) || item.username.toLowerCase().includes(currentWord));
        });

        this.subscriptions.push(sub);
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }

        if (this.tabEventListener) {
            this.textArea.area.nativeElement.removeEventListener(this.tabEventListener);
            this.tabEventListener = null;
        }
    }

    submit() {
        if (this.submitted) {
            return;
        }

        if (this.form.status !== 'VALID') {
            this.form.get('body').markAsDirty();
            return;
        }

        if (this.filesForm.status !== 'VALID') {
            return;
        }

        this.submitted = true;
        const data = this.comment ? {body: this.form.value.body} : {...this.form.value};

        const request = this.comment
            ? this.api.updateComment(this.comment.id, data)
            : this.api.addComment(data);

        request.subscribe(async (response: PageComment) => {
            const res = await this.updateAttachments(response.id).catch(e => console.log(e));
            this.submitted = false;

            if (!res) {
                return;
            }

            const comment = await this.api.getComment(response.id).toPromise();

            this.closeForm.emit(comment);

            if (!this.baseForm) {
                return;
            }

            this.form.setControl('body', this.formBuilder.control('', [Validators.required]));
            this.form.reset();
            this.form.get('page').setValue(this.reportsService.getSelectedReport().id);
            this.filesForm.clear();

            this.currentWord = null;
            this.possibleUsers = [];
            this.caretPosition = null;


            for (const sub of this.subscriptions) {
                sub.unsubscribe();
            }

            this.subscribeToUpdates();

            setTimeout(() => this.textArea.resetHeight());
        }, (response) => {
            this.submitted = false;
        });
    }

    checkSubmit(e: KeyboardEvent) {
        if (e.key === 'Enter' && e.ctrlKey) {
            this.submit();
        }
    }

    addFile() {
        this.filesForm.push(this.formBuilder.control([], allowedMimeTypesValidator()));
    }

    async updateAttachments(commentId) {
        if (this.toDelete.length) {
            await this.api.deleteCommentAttachmentFile(this.toDelete).toPromise().catch(e => console.log(e));
        }

        const files = this.filesForm.value.filter(item => item instanceof File);

        if (!files.length) {
            return true;
        }

        const form = new FormData();

        for (const item of files) {
            form.append('file[]', item, item.name);
        }

        const resp = await this.api.uploadCommentAttachmentFile(commentId, form).toPromise().catch((e) => {
            this.errors = e.error.errors;
        });

        return !!resp;
    }

    removeFile(i) {
        this.errors = [];
        this.filesForm.removeAt(i);
    }

    removeExistingFile(id, i) {
        this.toDelete.push(id);
        this.existingFiles.splice(i, 1);
    }

    insertMention(user: UserForMention) {
        const message = this.form.value.body;
        const insValue = `@${user.username} `;

        let result = message.substring(0, this.currentWord.start) + insValue;

        if (this.currentWord.start !== this.currentWord.end) {
            result += message.substring(this.currentWord.end);
        }

        this.form.get('body').setValue(result);
        this.possibleUsers = [];

        setTimeout(() => {
            this.textArea.area.nativeElement.focus();
            this.textArea.area.nativeElement.selectionEnd = this.currentWord.start + insValue.length;

            this.currentWord = null;
        });
    }
}
