import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {SelectValue} from '../../../../../models/models';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from '../../../../../services/dashboard.service';
import {DataholderService} from '../../../../../services/dashboards/dataholder.service';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {JsonPipe} from '@angular/common';
import {HttpResponse} from '@angular/common/http';
import * as JSZip from 'jszip';

@Component({
    selector: 'app-content-type-dashboard-modal',
    templateUrl: './content-type-dashboard-modal.component.html',
    styleUrls: ['./content-type-dashboard-modal.component.scss'],
    providers: [JsonPipe]
})
export class ContentTypeDashboardModalComponent implements OnInit, OnDestroy, AfterViewChecked {

    @ViewChild('uploadFile') uploadFile;
    @ViewChild('dataCount') dataCount: ElementRef;
    public loading = true;
    public clientsSelect: SelectValue[] = [];
    public internalName: string;
    public contentTitle: string;
    public contentSubtitle: string;
    // public charts = [];
    public data: { columns: [{ name: string, type: any }], showResetAllButton: boolean };
    public configObject: { data: any, charts: any };
    public datasourcesSelect: [{ id: string, name: string }];
    public selectedClientId: string;
    private subscriptions: Subscription[] = [];
    public errorOccurred: boolean;
    public contentEnabled: boolean;
    public id: number;
    public selectedDatasourceId: string;
    // public defaultConfig: {templates: [], groupingFunction: []};
    public defaultConfig: any;
    public showResetAll: boolean;
    private supportedGroupingFunctionsByColumnName = [];
    public weightByColumnNames = [];
    public showPreviewModal: boolean;
    public supportedXColumns = [];
    public supportedYColumns = [];
    public sorting = ['DESC', 'ASC', 'Alphabetically'];
    // tslint:disable-next-line:only-arrow-functions
    private idGenerator = (function() {
        let counter = 0;
        return {
            nextId() {
                return ++counter;
            },
            current() {
                return counter;
            },
            init(value) {
                counter = value;
            }
        };
    }());
    showEditModal = false;
    showDatasourceModal = false;
    editConfigObject: string;
    setDatasourceObject = '';
    showSaveButton = true;

    constructor(private apiService: ApiService,
                private routeSnapshot: ActivatedRoute,
                private dashboardService: DashboardService,
                private dataholderService: DataholderService,
                private toastrService: ToastrService,
                private jsonPipe: JsonPipe) {
    }

    ngOnInit() {
        this.dataholderService.enabled = true;
        this.apiService.getDashboardDefaultConfig().subscribe(res => {
            this.defaultConfig = res;
        });
        this.apiService.manageDashboardContent(this.routeSnapshot.snapshot.paramMap.get('id')).subscribe(res => {
            this.id = res.id;
            this.internalName = res.name;
            this.contentTitle = res.title;
            this.contentSubtitle = res.subtitle;
            this.configObject = JSON.parse(res.config);
            this.contentEnabled = !res.isDeleted;
            this.selectedDatasourceId = res.remote_id;
            this.selectedClientId = res.remote_company_id;
            if (this.selectedDatasourceId && this.selectedClientId) {
                this.setSelectedClient(this.selectedClientId);
                if (this.configObject) {
                    this.data = this.configObject.data;
                    this.showResetAll = this.configObject.data.showResetAllButton;
                }

                // tslint:disable-next-line:max-line-length
                this.getContent(res.remote_id).then((response: { data: string, company: string, hash: string }) => {
                    this.loading = false;
                    try {
                        this.dashboardService.destroyAll();
                        if (!this.dataholderService.enabled) {
                            return;
                        }
                        const remoteData = JSON.parse(response.data);
                        this.configObject.data.columns = remoteData.columns;
                        this.data = this.configObject.data;
                        if (this.configObject) {
                            this.dataholderService.init(remoteData.data, this.configObject.data.columns, res.config_hash, response.hash);
                        }
                        if (this.configObject.data.showResetAllButton) {
                            this.subscriptions.push(this.dashboardService.createDataCount());
                        }
                        this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
                        this.initSupportedGroupingFunctionsByColumnName();
                        this.initWeightByColumnNames();
                        this.initSupportedColumns();
                    } catch (e) {
                        this.errorOccurred = true;
                        throw (e);
                    }
                });
            } else {
                this.loading = false;
            }
        }, () => {
            this.loading = false;
        });

        this.apiService.getCompaniesHavingDashboardDatasource().subscribe(res => {
            res.forEach(el => {
                this.clientsSelect.push({name: el.name, value: el.id});
            });
        });
    }

    ngAfterViewChecked(): void {
        if (this.dataCount) {
            this.dashboardService.setDataCountElement(this.dataCount.nativeElement);
        }
    }

    setSelectedClient(id: string) {
        this.selectedClientId = id;
        this.apiService.getDataSources(this.selectedClientId).subscribe((response: [{ id: string, name: string }]) => {
            this.datasourcesSelect = response;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });

        this.dataholderService.clear();
        this.dataholderService.enabled = false;
        this.dashboardService.clear();
    }

    resetAll() {
        this.dashboardService.resetAll();
    }

    saveDashboard() {
        this.apiService.updateDashboardContent(this.id, {
            id: this.id,
            title: this.contentTitle,
            subtitle: this.contentSubtitle,
            name: this.internalName,
            isDeleted: true,
            config: JSON.stringify(this.configObject),
            remote_id: this.selectedDatasourceId
        }).subscribe();
    }

    setDatasourceFromModal() {
        this.dashboardService.destroyAll();
        if (!this.dataholderService.enabled) {
            return;
        }

        const remoteData = JSON.parse(this.setDatasourceObject);
        if (this.configObject) {
            this.dataholderService
                .init(remoteData.data, this.configObject.data.columns, JSON.stringify(this.configObject), '');
        }
        this.subscriptions.push(this.dashboardService.createDataCount());
        this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
        this.data = JSON.parse(this.setDatasourceObject);
        this.initSupportedGroupingFunctionsByColumnName();
        this.initWeightByColumnNames();
        this.initSupportedColumns();
        this.showDatasourceModal = false;
        this.showSaveButton = false;
    } catch(e) {
        this.errorOccurred = true;
        throw (e);
    }

    setDatasource() {
        this.loading = true;
        if (this.configObject === null) {
            this.configObject = {data: [], charts: []};
        }
        this.configObject.charts = [];
        this.getContent(this.selectedDatasourceId).then((response: { data: string, company: string, hash: string }) => {
            try {
                this.dashboardService.destroyAll();
                if (!this.dataholderService.enabled) {
                    return;
                }

                const remoteData = JSON.parse(response.data);
                if (this.configObject) {
                    this.dataholderService
                        .init(remoteData.data, this.configObject.data.columns, JSON.stringify(this.configObject), response.hash);
                }
                this.subscriptions.push(this.dashboardService.createDataCount());
                this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
                this.setSelectedClient(response.company);
                this.data = JSON.parse(response.data);
                this.initSupportedGroupingFunctionsByColumnName();
                this.initWeightByColumnNames();
                this.initSupportedColumns();
                this.loading = false;
            } catch (e) {
                this.errorOccurred = true;
                throw (e);
            }
        });
    }

    setClient() {
        this.apiService.getDataSources(this.selectedClientId).subscribe((response: [{ id: string, name: string }]) => {
            this.datasourcesSelect = response;
        });
    }

    addNewChart($event?) {
        let id = null;
        let type = null;
        let title = null;
        let index = null;
        if ($event) {
            id = $event.id;
            index = $event.index;
            type = $event.type;
            title = $event.title;
        }
        let newChart;
        if (id === null) {
            newChart = _.cloneDeep(
                this.getChartTemplateById(
                    'line-number'
                ).template
            );
        } else {
            newChart = _.cloneDeep(
                this.getChartTemplateById(
                    type
                ).template
            );
        }
        this.refreshIdGenerator();
        if (id) {
            newChart.id = id;
        } else {
            newChart.id = this.idGenerator.nextId();
        }
        const supportedColumns = this.getSupportedXColumns(newChart);
        if (supportedColumns.length > 0) {
            if (newChart.xAxis) {
                newChart.xAxis.columnName = supportedColumns[0].name;
            }
        }

        this.addNewChartSerie(newChart, true);

        if (title !== null) {
            newChart.title = title;
        }
        if (index === null) {
            this.configObject.charts.push(newChart);
        } else {
            this.configObject.charts[index] = newChart;
        }
        setTimeout(() => {
            this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
        }, 200);
    }

    private getChartTemplateById(id) {
        if (this.defaultConfig) {
            return _.find(this.defaultConfig.chartTemplates, {id});
        }
    }

    public getSupportedXColumns(chart) {
        // return this.supportedXColumns[chart.templateId];
        const supportedXColumns = [];
        const chartTemplate = this.getChartTemplateById(chart.templateId);
        if (chartTemplate) {
            const supportedXColumnTypes = chartTemplate.supportedDataTypes.xAxis;

            for (const column of this.data.columns) {
                if (_.includes(supportedXColumnTypes, column.type)) {
                    supportedXColumns.push(column);
                }
            }
        }

        return supportedXColumns;
    }

    public getSupportedYColumns(chart) {
        // return this.supportedYColumns[chart.templateId];
        const supportedYColumns = [];
        const chartTemplate = this.getChartTemplateById(chart.templateId);
        if (chartTemplate) {
            const supportedYColumnTypes = chartTemplate.supportedDataTypes.yAxis;

            for (const column of this.data.columns) {
                if (_.includes(supportedYColumnTypes, column.type)) {
                    supportedYColumns.push(column);
                }
            }
        }

        return supportedYColumns;
    }

    private initSupportedGroupingFunctionsByColumnName() {
        for (const column of this.data.columns) {
            const columnName = column.name;
            this.supportedGroupingFunctionsByColumnName[columnName] = this.getSupportedGroupingFunctions(columnName);
        }
    }

    private initWeightByColumnNames() {
        for (const column of this.data.columns) {
            if (column.type === 'int' || column.type === 'float') {
                this.weightByColumnNames.push(column.name);
            }
        }
    }

    private getSupportedGroupingFunctions(columnName) {
        const supportedGroupingFunctions = [];

        const columnType = this.getColumnByName(columnName).type;
        for (const groupingFunction of this.defaultConfig.groupingFunctions) {
            if (_.includes(groupingFunction.supportedDataTypes, columnType)) {
                supportedGroupingFunctions.push(groupingFunction);
            }
        }

        return supportedGroupingFunctions;
    }

    private getColumnByName(name) {
        return _.find(this.data.columns, {name});
    }

    private addNewChartSerie(chart, doNotNotifyService) {
        const availableYColumns = this.getSupportedYColumns(chart);
        // this.supportedYColumns[chart.id] = this.getSupportedXColumns(chart);
        // this.supportedYColumns[chart.id] = availableYColumns;
        if (availableYColumns.length > 0) {
            const columnName = availableYColumns[0].name;
            chart.series.push(
                {
                    id: this.idGenerator.nextId(),
                    columnName,
                    groupBy: {
                        groupingFunction: this.supportedGroupingFunctionsByColumnName[columnName][0].id,
                        weightByColumnName: this.weightByColumnNames[0]
                    },
                    useRightYAxis: false
                }
            );
            // if (!doNotNotifyService) {
            //     $scope.refreshChart(chart.id);
            // }
        }
    }

    private refreshIdGenerator() {
        try {
            if (this.configObject.charts[this.configObject.charts.length - 1]) {
                const lastId = this.configObject.charts[this.configObject.charts.length - 1].id;
                this.idGenerator.init(lastId);
            }
        } catch (e) {
            console.error('Error occurred: ' + e);
        }
    }

    closePreviewModalWindow() {
        this.showPreviewModal = false;
    }

    openPreviewModal($event: MouseEvent) {
        $event.preventDefault();
        this.showPreviewModal = true;
    }

    removeChart(index) {
        this.configObject.charts.splice(index, 1);
    }

    updateChart($event: any) {
        const index = $event.index;
        const config = $event.config;
        this.configObject.charts.splice(index, 1, config);
        this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
    }

    getPreviewData(): string[] {
        return this.dataholderService.getData().slice(0, 30);
    }

    closeEditModalWindow() {
        this.showEditModal = false;
    }

    closeDatasourceModalWindow() {
        this.showDatasourceModal = false;
    }

    openEditModal($event: MouseEvent) {
        this.editConfigObject = this.jsonPipe.transform(this.configObject);
        this.showEditModal = true;
    }

    openDatasourceModal($event: MouseEvent) {
        this.showDatasourceModal = true;
    }

    saveConfig() {
        try {
            this.configObject = JSON.parse(this.editConfigObject);
            this.editConfigObject = null;
            setTimeout(() => {
                this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
            }, 500);
            this.closeEditModalWindow();
        } catch (e) {
            this.toastrService.error('JSON is not valid, please try again');

        }
    }

    downloadConfig($event: MouseEvent) {
        // tslint:disable-next-line:max-line-length
        this.apiService.downloadDashboardConfig(this.routeSnapshot.snapshot.paramMap.get('id')).subscribe((response: HttpResponse<Blob>) => {
            this.apiService.downloadFileClientSide(response, 'config.json', 'application/json');
        });
    }

    uploadConfigAction() {
        this.uploadFile.nativeElement.click();
    }

    onUploadFileSelected(files: any) {
        this.uploadFile.nativeElement.click();
        const formData = new FormData();
        formData.append('file', files.item(0), files.item(0).name);
        // tslint:disable-next-line:max-line-length
        this.apiService.uploadDashboardConfig(this.routeSnapshot.snapshot.paramMap.get('id'), formData).subscribe((response: any) => {
                this.configObject = JSON.parse(response.config);
                setTimeout(() => {
                    this.dashboardService.setCrossFilter(this.dataholderService.getCrossFilter());
                }, 500);
                this.toastrService.success('Uploaded successfully');
            },
            () => {
                this.toastrService.error('An error occurred, olease try again');
            });
    }

    private initSupportedColumns() {
        for (const template of this.defaultConfig.chartTemplates) {
            const supportedXColumns = [];

            const supportedXColumnTypes = template.supportedDataTypes.xAxis;

            for (const column of this.data.columns) {
                if (_.includes(supportedXColumnTypes, column.type)) {
                    supportedXColumns.push(column);
                }
            }
            this.supportedXColumns[template.id] = supportedXColumns;

            const supportedYColumns = [];
            const supportedYColumnTypes = template.supportedDataTypes.yAxis;
            for (const column of this.data.columns) {
                if (_.includes(supportedYColumnTypes, column.type)) {
                    supportedYColumns.push(column);
                }
            }
            this.supportedYColumns[template.id] = supportedYColumns;
        }
    }

    private getContent(id) {
        return new Promise((resolve, reject) => {
            this.apiService.getRemoteDataSource(id).subscribe((data: { company: string }) => {
                this.apiService.getRemoteDataSourceZip(this.selectedDatasourceId).subscribe((response: Blob) => {
                    JSZip.loadAsync(response).then(async (res) => {
                        if (!res.files.data || !res.files.hash) {
                            this.errorOccurred = true;
                            reject('Invalid ZIP!');
                        }

                        const txt = await res.files.data.async('text');
                        const hash = await res.files.hash.async('text');

                        if (!txt || !hash) {
                            this.errorOccurred = true;
                            reject('Invalid ZIP!');
                        }

                        resolve({...data, data: txt, hash});
                    });
                });
            });
        });
    }
}
