import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DisplayError, ProfileSettingsModel, SelectValue, UserReportsModel} from '../../../../models/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {FormErrorsTransformerService} from '../../../../services/form-errors-transformer.service';
import {AuthService} from '../../../../services/auth.service';
import {FormLoadingServiceService} from '../../../../services/form-loading-service.service';
import {LayoutConfigService} from '../../../../services/layout-config.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-start-up-settings',
    templateUrl: './start-up-settings.component.html',
    styleUrls: ['./start-up-settings.component.scss'],
    providers: [FormLoadingServiceService]
})
export class StartUpSettingsComponent implements OnInit, OnDestroy {

    @Output() addComponent = new EventEmitter();

    public startupOptions: SelectValue[] = [
        {name: 'Remember last session', value: true},
        {name: 'Open this item', value: false}
    ];

    public reports: any = [];
    public lastLogin: string;

    public errors: DisplayError[] = [];

    public formName = 'startupSettings';
    public status: boolean;
    public submitted: boolean;
    public form: FormGroup;
    private subscriptions: Subscription[] = [];

    constructor(public layoutConfigService: LayoutConfigService,
                private formBuilder: FormBuilder,
                private apiService: ApiService,
                private errorsTransformer: FormErrorsTransformerService,
                public authService: AuthService,
                public formLoadingServiceService: FormLoadingServiceService,
    ) {
    }

    ngOnInit() {
        this.status = false;
        this.submitted = false;

        this.form = this.formBuilder.group({
            startupOption: [true, [Validators.required]],
            startupFolder: [''],
            startupPage: [''],
        });

        this.formLoadingServiceService.init(2);

        this.apiService.getProfileReports().subscribe(reports => {
            this.reports = reports;
            this.formLoadingServiceService.eventLoaded();

            this.apiService.getProfileSettings().subscribe(data => {
                this.setFormData(data, reports);
                this.subscriptions.push(this.form.valueChanges.subscribe(() => {
                    this.updateSettings();
                }));
                this.formLoadingServiceService.eventLoaded();
            }, () => {
                this.formLoadingServiceService.eventLoaded();
                this.subscriptions.push(this.form.valueChanges.subscribe(() => {
                    this.updateSettings();
                }));
            });
        }, () => {
            this.formLoadingServiceService.eventLoaded();
            this.formLoadingServiceService.eventLoaded();
            this.subscriptions.push(this.form.valueChanges.subscribe(() => {
                this.updateSettings();
            }));
        });

        const loggedUser = this.authService.getLoggedUser();
        if (loggedUser) {
            this.lastLogin = loggedUser.last_login;
        } else {
            this.authService.getLoggedUserObservable().subscribe(user => {
                this.lastLogin = user.last_login;
            });
        }
    }

    setFormData(data: ProfileSettingsModel, reports: UserReportsModel) {
        this.form.get('startupOption').setValue(data.remember_session);

        if (!data.favorite_report) {
            return;
        }

        const [reportPath] = reports.sections.filter(item => item.id === data.favorite_report_path.id);

        if (!reportPath) {
            return;
        }

        this.form.get('startupFolder').setValue(reportPath);

        if (!data.favorite_report) {
            return;
        }

        const [report] = reportPath.pages.filter(item => item.id === data.favorite_report.id);

        if (report) {
            this.form.get('startupPage').setValue(report);
        }
    }

    onMenuLabelChange(value: boolean) {
        this.layoutConfigService.setMenuLabels(value);
        this.apiService.updateMenuLabels(value).subscribe();
    }

    updateSettings() {
        this.submitted = true;
        this.errors = this.errorsTransformer.getErrors(this.form);
        console.log(this.errors);

        if (this.form.status !== 'VALID') {
            return;
        }

        const {
            startupOption,
            startupFolder,
            startupPage
        } = this.form.value;

        const request = this.apiService.updateProfileSettings({
            remember_session: startupOption,
            favorite_report_path: startupFolder.id,
            favorite_report: startupPage.id
        });

        request.subscribe((response) => {
        }, (response) => {
            this.errors = this.errorsTransformer.getErrorsFromResponse(this.form, response);
        });
    }

    formGlobalErrors(): string[] {
        return this.errors.map(item => item.message);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
