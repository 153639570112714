import {Injectable} from '@angular/core';
import {DisplayError} from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class FieldsService {
    public field: {};
    private fields = {
        companiesFields: [
            {
                name: 'folder',
                label: 'messages.admin.companies_page.form.company.fields.folder.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.folder.placeholder',
                id: 'company_folder',
                error: [''],
                type: 'text'
            },
            {
                name: 'name',
                label: 'messages.admin.companies_page.form.company.fields.name.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.name.label',
                id: 'company_name',
                error: ['Enter company name'],
                type: 'text'
            },
            {
                name: 'address',
                label: 'messages.admin.companies_page.form.company.fields.address.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.address.label',
                id: 'company_address',
                error: [''],
                type: 'text'
            },
            {
                name: 'owners',
                label: 'messages.admin.companies_page.form.company.fields.owners.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.owners.label',
                id: 'company_owners',
                error: [''],
                type: 'text'
            },
            {
                name: 'domains',
                label: 'messages.admin.companies_page.form.company.fields.domains.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.domains.label',
                id: 'company_domains',
                error: [''],
                type: 'text'
            },
            {
                name: 'language',
                label: 'messages.admin.companies_page.form.company.fields.locale.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.locale.placeholder',
                id: 'company_language',
                error: [''],
                type: ''
            },
            {
                name: 'timeZone',
                label: 'messages.admin.companies_page.form.company.fields.time_zone.label',
                placeholder: 'messages.admin.companies_page.form.company.fields.time_zone.placeholder',
                id: 'company_timeZone',
                error: [''],
                type: ''
            },
            {
                name: 'managers',
                label: 'messages.admin.companies_page.managers',
                placeholder: '',
                id: 'company_managers',
                error: [''],
                type: ''
            }
        ],
        workbooksFields: [
            {
                name: 'chart',
                label: 'messages.admin.workbooks_page.form.workbook.fields.chart.label',
                placeholder: '',
                id: 'workbook_chart',
                error: [''],
                type: 'text'
            },
            {
                name: 'presentation',
                label: 'messages.admin.workbooks_page.form.workbook.fields.presentation.label',
                placeholder: '',
                id: 'workbook_presentation',
                error: [''],
                type: 'text'
            }
        ],
        subscriptionsFields: [
            {
                name: 'subscriber',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.subscriber.label',
                placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.subscriber.placeholder',
                id: 'subscription_subscriber',
                error: ['Select at least one subscriber'],
                type: ''
            },
            {
                name: 'report',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.page.label',
                placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.page.placeholder',
                id: 'subscription_report',
                error: ['Choose page'],
                type: ''
            },
            {
                name: 'email',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.email.label',
                placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.email.placeholder',
                id: 'subscription_email',
                error: ['Enter email'],
                type: 'email'
            },
            {
                name: 'name',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.subject.label',
                placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.subject.placeholder',
                id: 'subscription_name',
                error: ['Enter name'],
                type: 'text'
            },
            {
                name: 'type',
                label: 'messages.admin.contents_page.form.content.fields.type.label',
                placeholder: 'messages.admin.contents_page.form.content.fields.type.placeholder',
                id: 'subscription_type',
                error: [''],
                type: ''
            },
            {
                name: 'format',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.format.label',
                id: 'subscription_format',
                error: ['Enter format'],
                type: ''
            },
            {
                name: 'hourOfDelivery',
                label: 'H:',
                id: 'subscription_hourOfDelivery',
                error: [''],
                type: ''
            },
            {
                name: 'minuteOfDelivery',
                label: 'M:',
                id: 'subscription_minuteOfDelivery',
                error: [''],
                type: ''
            },
            {
                name: 'timeZone',
                placeholder: 'messages.admin.subscriptions_page.form.subscription.fields.time_zone.placeholder',
                label: '',
                id: 'subscription_timeZone',
                error: [''],
                type: ''
            },
            {
                name: 'dayOfDelivery',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.day_of_delivery.label',
                id: 'subscription_dayOfDelivery',
                error: [''],
                type: ''
            },
            {
                name: 'startDate',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.start_date.label',
                placeholder: 'message.admin.subscriptions_page.form.subscription.fields.start_date.placeholder',
                id: 'subscription_startDate',
                error: [''],
                type: ''
            },
            {
                name: 'endDate',
                label: 'messages.admin.subscriptions_page.form.subscription.fields.end_date.label',
                placeholder: 'message.admin.subscriptions_page.form.subscription.fields.end_date.placeholder',
                id: 'subscription_endDate',
                error: [],
                type: ''
            }
        ],
        userSettings: [
            {
                name: 'rememberSession',
                label: 'messages.admin.users_page.form.settings.fields.remember_session.label',
                placeholder: 'messages.admin.users_page.form.settings.fields.remember_session.placeholder',
                id: 'settings_rememberSession',
                error: [],
                type: ''
            },
            {
                name: 'favoriteReportPath',
                label: 'messages.admin.users_page.form.settings.fields.favorite_folder.label',
                id: 'select_favoriteReportPath',
                error: [],
                type: ''
            }
        ],
        profile: [
            {
                name: 'firstName',
                label: 'messages.global.first_name',
                placeholder: 'messages.global.first_name',
                id: 'profile_firstName',
                error: ['Enter first name'],
                type: 'text'
            },
            {
                name: 'lastName',
                label: 'messages.global.last_name',
                placeholder: 'messages.global.last_name',
                id: 'profile_lastName',
                error: ['Enter last name'],
                type: 'text'
            },
            {
                name: 'email',
                label: 'messages.global.email',
                placeholder: 'messages.global.email',
                id: 'profile_email',
                error: ['Enter your email'],
                type: 'email'
            },
            {
                name: 'phone',
                label: 'messages.global.phone',
                placeholder: 'messages.global.phone',
                id: 'profile_phone',
                error: [],
                type: 'text'
            },
            {
                name: 'mobile',
                label: 'messages.global.mobile',
                placeholder: 'messages.global.mobile',
                id: 'profile_mobile',
                error: [],
                type: 'text'
            },
            {
                name: 'address',
                label: 'messages.global.address',
                placeholder: 'messages.global.address',
                id: 'profile_address',
                error: [''],
                type: 'text'
            },
            {
                name: 'currentPassword',
                label: 'messages.right_side.account_form.password',
                placeholder: '',
                id: 'profile_currentPassword',
                error: [],
                type: 'password'
            },
            {
                name: 'password',
                label: 'messages.right_side.account_form.new_password',
                placeholder: '',
                id: 'profile_password',
                error: [],
                type: 'password'
            },
            {
                name: 'repeatPassword',
                label: 'messages.right_side.account_form.confirm_password',
                placeholder: '',
                id: 'profile_repeatPassword',
                error: [''],
                type: 'password'
            },
            {
                name: 'language',
                label: 'messages.right_side.account_form.form.profile_type.fields.language.label',
                id: 'profile_language',
                error: [],
                type: ''
            },
            {
                name: 'timeZone',
                label: 'messages.global.time_zone',
                id: 'profile_timeZone',
                error: [],
                type: ''
            }
        ],
        startupSettings: [
            {
                name: 'startupOption',
                label: '',
                id: 'startupOption',
                error: [],
                type: ''
            },
            {
                name: 'startupFolder',
                errors: {
                    required: 'Select folder',
                }
            },
            {
                name: 'startupPage',
                errors: {
                    required: 'Select page',
                }
            }
        ],
        themeSettings: [
            {
                name: 'themeSettings',
                label: '',
                id: 'themeSettings',
                error: [],
                type: ''
            }
        ],
        version: [
            {
                name: 'description',
                label: '',
                id: 'version_description',
                error: [],
                type: ''
            },
            {
                name: 'version',
                label: '',
                id: 'version_version',
                error: [],
                type: ''
            }
        ],
        expandedUserProfile: [
            {
                name: 'badges',
                label: 'messages.list_values.badges',
                id: 'badges',
                placeholder: '',
            }
        ]
    };

    private defaultErrors = {
        required: 'This field is required',
        mustMatch: 'This field must match',
        email: 'You need to provide a valid email',
        minlength: 'Field length is less then {{requiredLength}} chars',
        pattern: 'The field does not match the required format'
    };

    constructor() {
    }

    getUserField(fieldName: string, formName: string) {
        if (!this.fields[formName]) {
            return;
        }

        this.field = this.fields[formName].filter(el => {
            if (el.name === fieldName) {
                return el;
            }
        });

        return this.field[0];
    }

    getFieldsErrors(data: DisplayError[], fieldName: string, formName: string) {
        const errors = [];

        if (!data) {
            return errors;
        }

        for (const error of data) {
            if (error.field !== fieldName) {
                continue;
            }

            if (error.type === 'custom') {
                errors.push(error.message);

                continue;
            }

            errors.push(this.getErrorMessage(fieldName, formName, error.type, error.options));
        }

        return errors;
    }

    public getDefaultMessage(error: string, options: any) {
        let message = this.defaultErrors[error] || error;

        Object.entries(options).forEach(([key, value]) => {
                message = message.replace(`{{${key}}}`, value.toString());
            }
        );

        return message;
    }

    private getErrorMessage(fieldName: string, formName: string, error: string, options: any): string {
        const field = this.getUserField(fieldName, formName);
        const defaultErrorMessage = this.defaultErrors[error] || error;
        let message = field && field.errors && field.errors[error] ? field.errors[error] : defaultErrorMessage;

        Object.entries(options).forEach(([key, value]) => {
                message = message.replace(`{{${key}}}`, value.toString());
            }
        );

        return message;
    }

    public getDefaultErrorMessage(error, options) {
        let message = this.defaultErrors[error] || error;

        Object.entries(options).forEach(([key, value]) => {
                message = message.replace(`{{${key}}}`, value.toString());
            }
        );

        return message;
    }
}
