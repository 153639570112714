<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.admins_page.title'|translate"
        search="true"
        (paginateFilterSort)="paginateFilterSort($event)"
    ></app-table-title>
    <div class="ng-binding">
        <table
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            class="table table-expandable expand-single"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-checkbox"></th>
                <th
                    class="col-id selected"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === 'id'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn('id')">ID</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === 'id' && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
                <th
                    *ngFor="let field of fieldsName; let index = field"
                    [ngClass]="{'selected': paginationService.pager.sorting.column === field.id, 'col-15': field.id === 'company_name', 'col-20': field.id === 'email', 'col-25': field.id === 'role'}"
                >
                    <a style="cursor: pointer" (click)="sortByColumn(field.id)">{{field.name|translate}}</a>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'desc'"
                        class="sort sort-desc"
                    ></span>
                    <span
                        *ngIf="paginationService.pager.sorting.column === field.id && paginationService.pager.sorting.direction === 'asc'"
                        class="sort sort-asc"
                    ></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity?.id === entity.id}"
                (click)="selectEntity(entity)"
            >
                <td class="col-checkbox" (click)="$event.stopPropagation()">
                    <div class="checkbox checkbox-gray no-label">
                        <input
                            type="checkbox"
                            [id]="'ck_' + entity.id"
                            [checked]="entity.checked"
                            (change)="checkEntity(entity)"
                        >
                        <label [for]="'ck_' + entity.id">
                                        <span>
                                            <span></span>
                                        </span>
                        </label>
                    </div>
                </td>
                <td>{{entity.id}}</td>
                <td>
                    {{entity.stringified}}
                </td>
                <td>{{entity.company.stringified}}</td>
                <td>{{entity.email}}</td>
                <td>
                    <span *ngIf="entity.role=='super_admin'">{{'messages.admin.admins_page.table.td.role_super_admin'|translate}}</span>
                    <span *ngIf="entity.role=='admin'">{{'messages.admin.admins_page.table.td.role_admin'|translate}}</span>
                    <span *ngIf="entity.role=='content_manager'">{{'messages.admin.admins_page.table.td.role_content_manager'|translate}}</span>
                    <span *ngIf="entity.role=='user'">{{'messages.admin.admins_page.table.td.role_user'|translate}}</span>
                </td>
            </tr>
            <tr class="row-expansion ng-scope" [ngClass]="{'expanded': selectedEntity?.id === entity.id}">
                <td></td>
                <td></td>
                <td colspan="4">
                    <app-admin-table-tr-info
                        [admin]="entity"
                        (updateAdmin)="updateEntity()"
                    ></app-admin-table-tr-info>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <app-table-footer
        class="w-100"
        [elements]="entities"
        [showInactiveUsersButton]="false"
        (exportAll)="exportAll()"
        [selectedIds]="selectedIds"
        (paginateFilterSort)="paginateFilterSort($event)"
        (checkAllElements)="checkAllEntities($event)"
        (exportSelected)="exportSelected()"
        (deleteSelected)="deleteSelected()"
    ></app-table-footer>
</div>
