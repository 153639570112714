import {AbstractControl, ValidatorFn} from '@angular/forms';

const BANNED_MIMES = [
    'application/javascript',
    'text/php',
    'text/x-php',
    'application/php',
    'application/x-php',
    'application/x-httpd-php',
    'application/x-httpd-php-source',
    'application/octet-stream'
];


export function allowedMimeTypesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as File;

        if (!(value instanceof File)) {
            return;
        }

        return BANNED_MIMES.includes(value.type) ? {invalid_mime: {value: value.type}} : null;
    };
}

export function passOnlyMimetypesValidator(types: string[] = []): ValidatorFn {
    if (!types?.length) {
        throw new Error('Empty mimes in validator config!');
    }

    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as File;

        if (!(value instanceof File)) {
            return;
        }

        return !types.includes(value.type) ? {invalid_mime: {value: value.type}} : null;
    };
}

export function allowMimeByRegex(reg: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value as File;

        if (!(value instanceof File)) {
            return;
        }

        return !reg.exec(value.type) ? {invalid_mime: {value: value.type}} : null;
    };
}
