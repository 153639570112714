<div class="row-expansion-body">
    <div class="form-group" *ngFor="let field of fields; let index = field">
        <label class="control-label float-left">{{field.name|translate}}:</label>
        <p
            class="form-control-static"
            *ngIf="field.type !== 'list'"
        >{{company[field.id] !== null ? company[field.id] : ' -- '}}</p>
        <div
            style="max-height: 120px; max-width: 200px;overflow: auto" *ngIf="field.type === 'list'"
            [ngStyle]="{'max-width': field.id === 'folders' && '600px'}"
        >
            <p class="form-control-static" *ngIf="company[field.id].length === 0"> -- </p>
            <ul class="comma-separated-list" *ngIf="company[field.id].length !== 0">
                <li *ngFor="let item of company[field.id]; let index = item">{{item.stringified}}</li>
            </ul>
        </div>
    </div>
    <div class="text-right" *ngIf="isAdmin()">
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_toggle_{{company.id}}"
            (click)="toggleActive()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.folders_page.toggle_active'|translate}}
        </button>
        <button
            *ngIf="auth.isSuperAdmin()"
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_toggle_board_enabled_{{company.id}}"
            (click)="toggleBoardEnabled()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.companies_page.toggle_board_enabled'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_toggle_show_{{company.id}}"
            (click)="toggleLoginCount()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.companies_page.show_login_stats'|translate}}
        </button>
        <button
            *ngIf="auth.isSuperAdmin()"
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_toggle_maintenance_{{company.id}}"
            (click)="toggleWorkbooksMaintenance()"
        >
            <i class="icon icon-size-9 ion-ionic"></i>
            {{'messages.admin.companies_page.toggle_maintenance'|translate}}
        </button>
        <button
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_edit_{{company.id}}"
            (click)="editCompany()"
        >
            <i class="icon icon-size-9 ion-edit"></i>
            {{'messages.global.edit'|translate}}
        </button>

        <button
            *ngIf="auth.isSuperAdmin()"
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_edit_hrs_{{company.id}}"
            (click)="editCompanyHRs()"
        >
            <i class="icon icon-size-9 ion-person-stalker"></i>
            Edit HRs
        </button>
        <button
            *ngIf="auth.isSuperAdmin()"
            type="button"
            class="btn btn-square btn-xs btn-primary margin-left-5"
            id="company_edit_managers_{{company.id}}"
            (click)="editCompanyManagers()"
        >
            <i class="icon icon-size-9 ion-person-stalker"></i>
            {{'messages.admin.companies_page.edit_managers'|translate}}
        </button>
        <app-modal-confirm *ngIf="auth.isSuperAdmin()">
            <button
                type="button"
                class="btn btn-square btn-xs btn-primary margin-left-5"
                id="company_delete_{{company.id}}"
                (click)="deleteCompany()"
            >
                <i class="icon icon-size-9 ion-trash-a"></i>
                {{'messages.global.delete'|translate}}
            </button>
        </app-modal-confirm>
    </div>
</div>
