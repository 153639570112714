<div>
    <app-table-title
        class="w-100"
        [titleTable]="'messages.admin.logs_page.title'|translate"
        search="true"
        [deleteButton]="'messages.admin.logs_page.delete_log'|translate"
        (deleteComponent)="deleteLogs()"
        (paginateFilterSort)="paginateFilterSort($event)"
    >
    </app-table-title>
    <div class="ng-binding">
        <table
            class="table table-expandable expand-single"
            [ngStyle]="{'opacity': busy ? '0.5' : '1'}"
            style="opacity: 1;border-collapse: unset;"
        >
            <thead>
            <tr>
                <th class="col-10">
                    <a>{{'messages.admin.logs_page.table.th.time'|translate}}</a>
                    <span class></span>
                </th>
                <th>
                    <a>{{'messages.admin.logs_page.table.th.error'|translate}}</a>
                    <span class></span>
                </th>
            </tr>
            </thead>
            <tbody *ngFor="let entity of entities">
            <tr
                class="clickable collapsed ng-scope"
                [ngClass]="{'selected': selectedEntity === entity}"
                (click)="selectEntity(entity)"
            >
                <td>
                    <span>{{entity.day}} </span>
                    <span>@{{entity.time}}</span>
                </td>
                <td>{{entity.error}}</td>
            </tr>
            <tr class="row-expansion expanded" [ngClass]="{'expanded': selectedEntity === entity}">
                <td colspan="2">
                    <div class="row-expansion-body" style="overflow: auto">
                        <div class="form-group">
                            <p class="form-control-static">
                                {{entity.error}}
                            </p>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr>
</div>
