import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-request-table-tr-info',
    templateUrl: './request-table-tr-info.component.html',
    styleUrls: ['./request-table-tr-info.component.scss']
})
export class RequestTableTrInfoComponent implements OnInit {

    @Input() request: any;
    @Output() onAcceptAction = new EventEmitter();
    @Output() onDenyAction = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    accept() {
        return this.onAcceptAction.emit();
    }

    deny() {
        return this.onDenyAction.emit();
    }
}
