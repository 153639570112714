<div class="container-fluid cover-bg-container forgot-password-page">
    <div class="panel-centered-wrapper">
        <form (ngSubmit)="onSubmit()" [formGroup]="passwordResetForm" class="form-style-labels-inside-inputs panel-centered">
            <a class="no-pointer">
                <img alt="Tools group" class="panel-centered-logo" src="./../../../../assets/img/tg-logo.png">
            </a>

            <div *ngIf="loading" class="text-center">
                <img alt="loading" src="./../../../../assets/img/loading.gif">
            </div>

            <div *ngIf="!loading && !success">
                <app-display-input-errors [displayErrors]="errors"></app-display-input-errors>

                <p>{{'messages.resetting_page.enter_email_address_to_reset_password'|translate}}</p>

                <div class="form-group">
                    <label class="control-label required" for="login_form_email">
                        {{'messages.global.email'|translate}}
                    </label>

                    <input class="form-control" formControlName="email" id="login_form_email" name="email" required="required" type="email">
                    <div class="focus-highlight"></div>
                </div>
                <app-display-input-errors [displayErrors]="errors" errorKey="email"></app-display-input-errors>

                <div class="panel-centered-actions">
                    <button class="btn btn-lg btn-primary text-capitalize w-100" type="submit">
                        {{'messages.global.submit'|translate}}
                    </button>
                    <div class="margin-top-25">
                        <a [routerLink]="'/login'" class="w-100 text-secondary">
                            {{'messages.request_to_join_page.back'|translate}}
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="success">
                <p class="text-emphasise confirmation-msg" style="display: block">
                    {{'messages.check_email_page.success_message'|translate}}
                </p>

                <div class="text-center">
                    <a [routerLink]="'/login'" class="btn btn-primary w-100 text-white">
                        {{'messages.resetting_page.go_back'|translate}}
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
