class PropertyAccessor {
    public getValue(object: any, path: string) {
        if (!object) {
            return null;
        }

        const segments = path.split('.');
        let value: any = object;

        try {

            for (const segment of segments) {
                if (!value[segment]) {
                    value = value[segment];
                    break;
                }

                value = value[segment];
            }
        } catch (e) {
            return null;
        }

        return value;
    }

    public hasValue(object: any, path: string) {
        if (!object) {
            return null;
        }

        const segments = path.split('.');
        let value: any = object;

        try {
            for (const segment of segments) {
                if (!value) {
                    return false;
                }

                if (!value.hasOwnProperty(segment)) {
                    return false;
                }

                value = value[segment];
            }

        } catch (e) {
            return false;
        }

        return true;
    }
}

export default new PropertyAccessor();
