<app-modal
    [loading]="form?.submitted"
    (mClose)="closeModalWindow()"
    (mSubmit)="form?.submit()"
    [mTitle]="'messages.admin.contents_page.form.presentation_type.title_edit'|translate"
>
    <app-form
        [formConfig]="formConfig"
        [hideButtons]="true"
        [value]="value"
        [loading]="formLoadingServiceService.isLoading()"
        (submitForm)="updateContent($event)"
        [lists]="lists"
    >
        <div class="attachments">
            <div class="mb-4">
                <div *ngFor="let file of existingFiles, index as i">
                    <a [href]="file|contentAttachmentUlr" download><i class="icon ion-document-text"></i> {{file.name}}
                    </a>
                </div>
            </div>

            <div class="form-group">
                <label for="uploadFile">{{'messages.attachments.new'|translate}}
                </label>
                <input
                    type="file"
                    class="form-control-file"
                    id="uploadFile"
                    accept="application/pdf,application/vnd.ms-powerpoint"
                    (change)="fileControl.setValue($event.target.files.length ? $event.target.files[0] : null)"
                >
                <div class="help-block mt-2" *ngIf="errors[0] || fileControl.invalid">
                    <ul class="list-unstyled">
                        <li *ngIf="fileControl.errors.invalid_mime">
                            <i class="ion-android-alert"></i>&nbsp;
                            <span>This file type is not allowed!!</span>
                        </li>
                        <li *ngIf="errors[0]">
                            <i class="ion-android-alert"></i>&nbsp;
                            <span>{{errors[0]}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </app-form>
</app-modal>
