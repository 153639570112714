import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GitBoard, GitBoardIssue} from '../../../../models/models';
import {SortableOptions} from 'sortablejs';
import {ApiService} from '../../../../services/api.service';

const emptyLabels = ['Open', 'Closed'];

@Component({
    selector: 'app-git-column',
    templateUrl: './git-column.component.html',
    styleUrls: ['./git-column.component.scss']
})
export class GitColumnComponent implements OnInit {

    @Input() board: GitBoard;
    @Input() busy = false;
    @Input() withAdd = false;
    @Output() loadNext = new EventEmitter();
    @Output() addIssue = new EventEmitter();
    @Output() reloadClosed = new EventEmitter();

    public separator = ';';

    public sortableOptions: SortableOptions = {
        group: {
            name: 'issues'
        },
        fallbackOnBody: true,
        swapThreshold: 0.2,
        sort: false,
        onAdd: (e) => {
            this.busy = true;
            const issue = this.board.issues[e.newIndex];

            if (!issue) {
                return;
            }

            const previousLabel = e.from.dataset.boardName;
            let labels = e.item.dataset.labels?.split(this.separator).filter(item => item !== previousLabel);
            if (labels === undefined) {
                labels = [];
            }
            const newLabel = emptyLabels.includes(this.board.name) ? '' : this.board.name;

            if (newLabel && !labels.includes(newLabel)) {
                labels.push(newLabel);
            }

            const data = {
                labels,
                state_event: undefined
            };

            if (this.board.name === 'Open') {
                data.state_event = 'reopen';
            }

            if (this.board.name === 'Closed') {
                data.state_event = 'close';
            }

            if (issue.state === 'closed' && this.board.name !== 'Closed') {
                data.state_event = 'reopen';
            }

            this.board.issues_count++;

            this.api.updateGitIssue(issue.iid, data).subscribe(res => {
                if (e.from.dataset.boardName === 'Closed') {
                    this.reloadClosed.emit();
                    this.busy = false;
                    return;
                }

                this.board.issues[e.newIndex] = res as GitBoardIssue;
                this.busy = false;
            });
        },
        onRemove: (e) => {
            this.board.issues_count--;
        },
    };

    constructor(private api: ApiService) {
    }

    ngOnInit(): void {
    }


    checkLoadNext(e) {
        if (this.board.name !== 'Closed') {
            return;
        }

        const {target} = e;

        if (target.scrollHeight - (target.scrollTop + target.clientHeight) < 100) {
            this.loadNext.emit();
        }
    }

}
